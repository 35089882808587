import React, { useCallback, useEffect, useState } from "react";
import ClearIcon from "@material-ui/icons/Clear";
import ReactDOM from "react-dom";
import { CustomAlert } from "./style";

const getToastRoot = () => {
  let toastRoot = document.getElementById("toast-root");

  if (!toastRoot) {
    toastRoot = document.createElement("");
    document.body.append(toastRoot);
  }

  return toastRoot;
};

const useAlert = () => {
  const [alertOpen, setAlertOpen] = useState(false);
  const [severity, setSeverity] = useState(null);
  const [message, setMessage] = useState(null);
  const [durationInMs, setDurationInMs] = useState(6000);

  const showAlert = useCallback(({ severity: s, message: m, durationInMs: p = 10000 }) => {
    setAlertOpen(true);
    setSeverity(s);
    setMessage(m);
    // eslint-disable-next-line no-unused-expressions
    p && setDurationInMs(p);
  }, []);

  const hideAlert = useCallback(() => {
    setAlertOpen(false);
    setSeverity(null);
    setMessage(null);
  }, []);

  const RenderAlert = ({ customStyles, float }) => (
    <CustomAlert severity={severity || "info"} style={{ display: alertOpen ? "flex" : "none", ...customStyles }}>
      {message}
      {float && <ClearIcon onClick={hideAlert} />}
    </CustomAlert>
  );

  const Toast = ({ customStyles, float }) => {
    useEffect(() => {
      if (durationInMs) {
        const timer = setTimeout(() => {
          setAlertOpen(false);
        }, durationInMs);
        return () => clearTimeout(timer);
      }
    }, []);

    if (!float) {
      return <RenderAlert />;
    }

    const content = (
      <div
        style={{
          background: "#ffffff",
          position: "fixed",
          top: "20px",
          right: "10px",
          zIndex: 2000000,
          ...customStyles,
        }}
      >
        <RenderAlert float />
      </div>
    );

    return ReactDOM.createPortal(content, getToastRoot());
  };

  return {
    Toast,
    showAlert,
    hideAlert,
  };
};

export default useAlert;
