import React from "react";
import { Box, Container } from "@material-ui/core";
import { useParams } from "react-router-dom";
import WatermarkOne from "../../../../../assets/watermark11.png";
import WatermarkTwo from "../../../../../assets/watermarkBL.png";
import LoadingIcon from "../../../../../assets/QR_Loading.gif";
import ErrorIcon from "../../../../../assets/QR_Error.gif";
import Logo from "../../../../../assets/deltaLogo.png";
import { ReactComponent as Checkmark } from "../../../../../assets/verify_cert_checkmark.svg";
import { VerifyWatermark1, VerifyWatermark2, GreenTitle, Title, StyledTableTitle } from "./style";
import { format } from "date-fns/esm";
import { formatCurrency } from "../../../../../util/formatCurrency";
import { useFetchCertificateDetails } from "../../../../../hooks/queries/useCertificate";

const VerifyCertificate = () => {
  const { id } = useParams();

  const { data, isLoading, isSuccess, isError } = useFetchCertificateDetails({ id })

  return (
    <Container maxWidth="sm">
      <VerifyWatermark1>
        <img src={WatermarkOne} alt="Prunedge Technologies logo" />
      </VerifyWatermark1>
      <VerifyWatermark2>
        <img src={WatermarkTwo} alt="Prunedge Technologies logo" />
      </VerifyWatermark2>
      <Box pt={5} px={4}>
        <Box mb={5} display="flex" justifyContent="space-between">
          <Box style={{ flex: 1, display: "grid", placeItems: "center" }}>
            <img src={Logo} alt="Prunedge Technologies logo" />
          </Box>

          <Box mb={2} mt="8px">
            <StyledTableTitle>Date issued</StyledTableTitle>
            <StyledTableTitle fontWeight={700}>{data && format(new Date(), "dd/MMMM/yyyy")}</StyledTableTitle>
          </Box>
        </Box>

        <Title color="#DA1414" fontWeight={600} fontSize={14}>
          PRUNEDGE TECHNOLOGIES
        </Title>
        <Title fontWeight={700}>PRUNEDGE TECHNOLOGIES PROCUREMENT COMMISSION</Title>
        <Box display="flex" justifyContent="center" my={5}>
          {isLoading && <img width={200} height={200} src={LoadingIcon} alt="verifying certificate" />}
          {isError && <img width={200} height={200} src={ErrorIcon} alt="Failed to verify certificate" />}
          {isSuccess && <Checkmark />}
        </Box>

        {isSuccess && (
          <>
            <GreenTitle>CERTIFICATE OF NO OBJECTION FOR THE AWARD OF CONTRACT</GreenTitle>
            <Title>Issued to <strong> {data?.contractor || 'Not Applicable'}</strong></Title>
            <Box mb={2} mt="8px">
              <StyledTableTitle>Project description</StyledTableTitle>
              <StyledTableTitle fontSize="14px" fontWeight={700} >{data?.projectDescription || "Not Applicable"}</StyledTableTitle>
            </Box>
            <Box mb={2} mt="8px">
              <StyledTableTitle>Project cost as Requested</StyledTableTitle>
              <StyledTableTitle fontSize="14px" fontWeight={700} >{formatCurrency(data?.quotation || 0, true)}</StyledTableTitle>
            </Box>
            <Box mb={2} mt="8px">
              <StyledTableTitle>Reviewed Total Project Cost</StyledTableTitle>
              <StyledTableTitle fontSize="14px" fontWeight={700}>{formatCurrency(data?.reviewedQuotation || 0, true)}</StyledTableTitle>
            </Box>
            <Box mb={2} mt="8px">
              <StyledTableTitle>Cost Reduction</StyledTableTitle>
              <StyledTableTitle fontSize="14px" fontWeight={700}>{formatCurrency(data?.costReduction || 0, true)}</StyledTableTitle>
            </Box>
            <Box mb={2} mt="8px">
              <StyledTableTitle>client ministry/department/agency</StyledTableTitle>
              <StyledTableTitle fontSize="14px" fontWeight={700}>{data?.ministry || "Not Applicable"}</StyledTableTitle>
            </Box>
            <Box mb={2} mt="8px">
              <StyledTableTitle>Head/Sub-head/appropriation Code</StyledTableTitle>
              <StyledTableTitle fontSize="14px" fontWeight={700}>{`${data?.headCode || ""}/${data?.subHeadCode || ""}/${data?.appropriationCode || ""}`}</StyledTableTitle>
            </Box>
            {/* </>
            )} */}
          </>
        )}
      </Box>
    </Container>
  );
};

export default VerifyCertificate;
