import baseAxiosMethod from "./baseAxiosMethod";
import { handleAxiosError } from "./handleApiError";

const receiptOfResponseApi = {

}


receiptOfResponseApi.getInterestedVendors = async (key, { procurementId, Status, PageNumber, PageSize }) => {
 let response;
  try{
    if (Status === 8){
      response = await baseAxiosMethod(`procurements/${procurementId}/shortlistedVendor`, {
        params: {     
          PageNumber,
          PageSize
        }
   })
  }
  else{
    response = await baseAxiosMethod(`procurements/${procurementId}/interestedVendor`, {
      params: {
        Status,
        PageNumber,
        PageSize
      }
    })
   
  }
    const { data, meta: { pagination } } = response.data

    return { interestedVendorsData: data, pagination }
  }catch (e) {
    throw new Error(handleAxiosError(e))
  }
}

receiptOfResponseApi.getSpecificVendor = async (key, { procurementId, vendorId }) => {
  try{
    const response = await baseAxiosMethod(`procurements/${procurementId}/interestedVendor/${vendorId}`)

    return response.data.data
  }catch (e) {
    throw new Error(handleAxiosError(e))
  }
}

receiptOfResponseApi.approveVendor = async ({ activityId, vendors }) => {
  try{
    await baseAxiosMethod.post(`procurements/approveInterestedVendor/${activityId}`, vendors)
  } catch (e) {
    throw new Error(handleAxiosError(e))
  }
}
receiptOfResponseApi.rejectVendor = async ({ activityId, vendors }) => {
  try{
    await baseAxiosMethod.post(`procurements/rejectInterestedVendor/${activityId}`, vendors)
  } catch (e) {
    throw new Error(handleAxiosError(e))
  }
}

export default receiptOfResponseApi
