import React, { useState, useRef, useContext } from "react";
import Box from "@material-ui/core/Box";
import { Banner, Title, Text, UploadButton, SubmitButton, FileContainer } from "./style";
import FileProgress from "../../../../../components/FileProgress/FileProgress";
import { useMutation } from "react-query";
import paymentAPI from "../../../../../redux/api/paymentAPI";
import ToastContext from "../../../../../util/toastContext";

const contact = {
  phone: "081234567891",
  email: "support@prunedge.com",
};

export default function OfflinePayment({ onPaymentComplete, paymentInfo = {}, tenderId }) {
  const { showAlert } = useContext(ToastContext);

  const { paymentStatus = "PENDING" } = paymentInfo;
  const [file, setFile] = useState(null);

  const [uploadPaymentProof, { isLoading, isSuccess }] = useMutation(paymentAPI.uploadBidPaymentProof, {
    onSuccess: () => {
      showAlert({
        severity: "success",
        message: "Successfully uploaded proof of payment",
      });
    },
    onError: (error) => {
      showAlert({
        severity: "error",
        message: error.message,
      });
    },
  });

  const handleSelectFile = (e) => {
    if (e.target.files && e.target.files[0]) setFile(e.target.files[0]);
  };

  const handleCancel = () => setFile(null);

  const handleSend = async () => {
    await uploadPaymentProof({
      tenderId,
      file,
    });
  };

  const handleEyesClick = () => {
    if (file) {
      const url = URL.createObjectURL(file);
      window.open(url, "_blank");
    }
  };

  const inputRef = useRef();

  const renderUploadProof = () => (
    <React.Fragment>
      <Box flex={1}>
        <Title>Offline Payment Instruction</Title>
        <Text>Upload proof of payment</Text>
      </Box>
      {file && (
        <FileContainer spaced>
          <FileProgress fileName={file.name} fileSize={file.size} progress={100} hasCancel onCancel={handleCancel} />
        </FileContainer>
      )}
      <UploadButton disabled={isLoading} onClick={() => inputRef.current.click()}>
        Upload Proof of Payment
      </UploadButton>
      <input disabled={isLoading} ref={inputRef} type="file" style={{ display: "none" }} onChange={handleSelectFile} />
    </React.Fragment>
  );

  const renderVerificationMessage = () => (
    <React.Fragment>
      <Title>Request for payment verification sent</Title>
      <Text bottomSpacing="15px">
        Please wait while we verify your payment. If your payment has not been verified within the next <b>24 hours</b> please
        contact our support center
      </Text>
      <Text>{contact.phone}</Text>
      <Text bottomSpacing="20px">{contact.email}</Text>
      {!!file && (
        <FileContainer>
          <FileProgress fileName={file.name} fileSize={file.size} hasEyes onClickEyes={handleEyesClick} />
        </FileContainer>
      )}
    </React.Fragment>
  );

  return (
    <Box mb={3.75}>
      <Box mb={paymentStatus === "PENDING" ? 3.75 : 0}>
        <Banner>
          {paymentStatus === "PENDING" && renderUploadProof()}
          {(paymentStatus === "AWAITING VERIFICATION" || isSuccess) && renderVerificationMessage()}
        </Banner>
      </Box>
      {paymentStatus === "PENDING" && !isSuccess && (
        <SubmitButton onClick={handleSend} disabled={!file || isLoading}>
          {isLoading ? "Please wait..." : "Send for Verification"}
        </SubmitButton>
      )}
    </Box>
  );
}
