import React, { useState } from "react";
import { ContentWrapper } from "../../../../Administration/Staff/style";
import { Box, TableCell } from "@material-ui/core";
import { GeneralTitlePage } from "../../../../../../components/AddGeneralPlan/style";
import Breadcrumb from "../../../../../Common/PageElements/Breadcrumb";
import TopStatisticsBar from "../../../../../../components/TopStatisticsBar";
import { useParams } from "react-router-dom";
import { CardLabel } from "../../../../PlanDetails/style";
import { CardNumberText } from "../../../../../../components/AdminCard/style";
import SearchTable from "../../../../../../components/SearchTable";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";
import { ModdedAvatar, TableCellEmail } from "../../ReceiptOfResponse/VendorsTable/style";
import { formatCurrency } from "../../../../../../util/formatCurrency";
import IconButton from "@material-ui/core/IconButton";
import { ReactComponent as RightIcon } from "../../../../../../assets/right_icon.svg";
import PaymentDrawer from "./PaymentDrawer";
import receiptOfBiddersResponseApi from "../../../../../../redux/api/receiptOfBiddersResponseApi";
import { queryCache, useQuery } from "react-query";

const ManualPayments = () => {
  const { activityId, procurementId } = useParams();

  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10
  });

  const [activeVendor, setActiveVendor] = useState(null)  
  const statistics = useQuery({
    queryKey: ['paymentStatistics', {
      TenderId: procurementId
    }],
    queryFn: receiptOfBiddersResponseApi.getPaymentStats
  })

  const payments = useQuery({
    queryKey: [
      'payments',
      {
        PageNumber: pagination.pageNumber,
        PageSize: pagination.pageSize,
        TenderId: procurementId
      }
    ],
    queryFn: receiptOfBiddersResponseApi.getPayments
  })

  const renderLeft = () => {
    return (
      <Box>
        <CardLabel>Total</CardLabel>
        <CardNumberText>
          {statistics.data?.totalVendors}
        </CardNumberText>
      </Box>
    );
  };

  const renderCenter = () => {
    return (
      <>
        <Box>
          <CardLabel color='#FF8D26'>Pending Review</CardLabel>
          <CardNumberText>
            {statistics.data?.pending}
          </CardNumberText>
        </Box>
        <Box ml={6.5}>
          <CardLabel color='#219653'>
            Evaluated
          </CardLabel>
          <CardNumberText>
            {statistics.data?.verified}
          </CardNumberText>
        </Box>
      </>
    );
  };

  const updatePagination = (key) => value => {
    setPagination(prevPagination => ({
      ...prevPagination,
      [key]: value
    }));
  };

  const handleApprove = () => {
    setActiveVendor(null)
    queryCache.removeQueries(['paymentStatistics'])
    statistics.refetch()
  }

  return (
    <ContentWrapper>
      <Box my={3}>
        <GeneralTitlePage>Manual Payments</GeneralTitlePage>
        <Breadcrumb
          values={[
            {
              url: "/dashboard/overview",
              title: "Home"
            },
            {
              url: `/admin/procurement/plans`,
              title: "Plans"
            },
            {
              url: `/admin/procurement/plan-details/${procurementId}`,
              title: "Plan Details"
            },
            {
              url: `/admin/procurement/activity/receiptofbiddersresponse/${activityId}/${procurementId}`,
              title: "Receipt of bidders response"
            },
            {
              url: `#`,
              title: "Manual Payments"
            }
          ]}
        />
      </Box>
      <TopStatisticsBar
        hasApproveStatus={false}
        centerContent={renderCenter()}
        leftContent={renderLeft()}
        isLoading={statistics.isLoading}
      />
      <SearchTable
        columns={["NAME", "PAYMENT", ""]}
        page={pagination.pageNumber}
        setPage={updatePagination('pageNumber')}
        rowsPerPage={pagination.pageSize}
        setRowsPerPage={updatePagination('pageSize')}
        loading={payments.isLoading}
        totalNumberOfPages={payments.data?.pagination?.totalEntries}
        empty={payments.data?.vendors?.length === 0}
        emptyMessage='No Vendors Found'
      >
        {
          payments.data?.vendors?.map((vendor) => {
            const {name, id, amount, email} = vendor
       
            return (
              <TableRow key={id}>
                <TableCell>
                  <Grid spacing={1} container wrap='nowrap'>
                    <Box mr={2}>
                      <ModdedAvatar size={34} color='#50B83C'>
                        {name?.slice(0, 2)}
                      </ModdedAvatar>
                    </Box>
                    <Grid
                      container
                      direction='column'
                      spacing={1}
                    >
                  <span>
                    {name}
                  </span>
                      <TableCellEmail>
                        {email}
                      </TableCellEmail>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell>
                  {formatCurrency(amount, true, true)}
                </TableCell>
                <TableCell align='right'>
                  <IconButton onClick={() => setActiveVendor(vendor)}>
                    <RightIcon fill='#637381' width={18}/>
                  </IconButton>
                </TableCell>
              </TableRow>
            )
          })
        }
      </SearchTable>
      <PaymentDrawer
        open={Boolean(activeVendor)}
        handleClose={() => setActiveVendor(null)}
        handleApprove={handleApprove}
        {...activeVendor}
      />
    </ContentWrapper>
  );
};

export default ManualPayments;
