import styled from "styled-components";
import { theme } from "../../util/theme";

export const DateRangeWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  margin-left: 8px;

  .MuiFormControl-root {
    height: 49px;
    width: 47%;
    border: 1px solid #cbd4da;
    margin-right: 3px;
    border-radius: 3px;
    background-color: ${theme.color.white};
  }
  .MuiInputBase-input {
    font-size: 14px;
    margin-top: 4px;
    margin-left: -3px;
    color: #637381;
  }
  .MuiButtonBase-root {
    bottom: 8px;
  }
  .MuiFormControl-root:hover {
    border: 1px solid ${theme.color.primary};
  }
  .MuiFormLabel-root.Mui-focused {
    color: ${theme.color.primary};
  }
  .MuiFormControl-root > label {
    padding-left: 16px;
    padding-top: 8px;
  }
  .MuiFormControl-root > div {
    padding-left: 16px;
    padding-right: 12px;
  }
  .MuiInput-underline::before {
    border-bottom: none;
  }
  .MuiInput-underline::after {
    border-bottom: none;
  }
  .MuiFormControl-marginNormal {
    margin-top: 10px;
    margin-bottom: 8px;
  }
  .MuiInput-underline:hover:not(.Mui-disabled)::before {
    border-bottom: none;
  }
}
`