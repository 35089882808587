import React from "react";
import Grid from "@material-ui/core/Grid";
import { ContentWrapper } from "../../Admin/Procurement/style";
import { PageTitle } from "../../Common/style";
import SearchTable, { StyledTableCell } from "../../../components/SearchTable";
import {
  TenderText,
  TenderSubHeaderContainer,
  TenderContainer,
  BidButton,
  TenderInfoContainer,
  TenderInfoTitle,
  TenderInfoLabel,
  TenderStyledTableRow,
} from "./style";

import { tenderBidders } from "./mock";
import withVendorDashboard from "../HOC/withVendorDashboard";

const TableBody = () => (
  tenderBidders.map((row, index) => (
    <TenderStyledTableRow key={`${row.id}_${index}`}>
      <StyledTableCell>
        {row.name}
      </StyledTableCell>
      <StyledTableCell>{row.dateBid}</StyledTableCell>
      <StyledTableCell>{row.bidStatus}</StyledTableCell>
    </TenderStyledTableRow>
  ))
);

const TenderDetails = () => {
  const marginBottom = "24px";
  return (
    <ContentWrapper>
      <TenderSubHeaderContainer>
        <div>
          <TenderContainer>
            <PageTitle>Completion of resettlement scheme for the earth quake at igbara oke</PageTitle>
          </TenderContainer>
          <TenderText>Tender</TenderText>
        </div>
        <BidButton>Bid</BidButton>
      </TenderSubHeaderContainer>
      <TenderInfoContainer>
        <Grid container style={{ marginBottom }}>
          <Grid item xs={4}>
            <TenderInfoTitle>Tender ID</TenderInfoTitle>
            <TenderInfoLabel>DSP-099344-KY</TenderInfoLabel>
          </Grid>
          <Grid item xs={4}>
            <TenderInfoTitle>Date Awarded</TenderInfoTitle>
            <TenderInfoLabel>05/09/2020</TenderInfoLabel>
          </Grid>
          <Grid item xs={4}>
            <TenderInfoTitle>Procurement Category</TenderInfoTitle>
            <TenderInfoLabel>Building Houses</TenderInfoLabel>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={4}>
            <TenderInfoTitle>Tender Description</TenderInfoTitle>
            <TenderInfoLabel style={{ width: "316px" }}>We are to complete the settlement for the igbara oke
            scheme due to the earthquake that occurred recently
            </TenderInfoLabel>
          </Grid>
          <Grid item xs={4}>
            <TenderInfoTitle>Expiry Date</TenderInfoTitle>
            <TenderInfoLabel>05/09/2020</TenderInfoLabel>
          </Grid>
          <Grid item xs={4}>
            <TenderInfoTitle>Value</TenderInfoTitle>
            <TenderInfoLabel>N25,000,000.00</TenderInfoLabel>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={4} />
          <Grid item xs={4}>
            <TenderInfoTitle>Ministry </TenderInfoTitle>
            <TenderInfoLabel>Ministry of Works</TenderInfoLabel>
          </Grid>
          <Grid item xs={4}>
            <TenderInfoTitle>Tender Status</TenderInfoTitle>
            <TenderInfoLabel>Published</TenderInfoLabel>
          </Grid>
        </Grid>
      </TenderInfoContainer>
      <SearchTable
      rows={tenderBidders}
      style={{ minHeight: "100px" }}
      columns={[
        "BIDDER",
        "DATE OF BID",
        "BID STATUS",
      ]}
      >
        <TableBody />
      </SearchTable>
    </ContentWrapper>
  );
};

export default withVendorDashboard(TenderDetails);
