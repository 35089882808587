import styled from "styled-components";

import { Typography } from "@material-ui/core";
import { CheckEntryCheckCircleSharpIcon } from "../../pages/Admin/CreateProcurement/style";

export const TimeLineContainer = styled.div`
  margin-top: 15px !important;
  box-sizing: border-box;
  border: 1px solid #c9d4db;
  border-radius: 3px;
  background-color: #e5e9ec;
  box-shadow: 0 2px 4px 0 #c9d4db;
  padding-bottom: 33px;

  .react-calendar-timeline .rct-dateHeader-primary {
    background-color: #ffffff;
    color: #000000;
  }

  .react-calendar-timeline .rct-dateHeader {
    background-color: #ffffff;
    border: none;
  }

  .react-calendar-timeline .rct-item .rct-item-content {
    width: 100%;
  }

  .react-calendar-timeline .rct-calendar-header {
    border: 1px solid #e4e4e4;
  }

  .react-calendar-timeline .rct-header-root {
    background-color: #ffffff;
    border: 1px solid #e4e4e4;
    box-shadow: 0px 0px 2px #bbb;
  }

  .react-calendar-timeline .rct-sidebar .rct-sidebar-row {
    border: 1px solid #e4e4e4;
    display: flex;
    background-color: #ffffff;
  }

  .rct-items .rct-item {
    height: 30px !important;
    border: none !important;
  }
`;

export const Group = styled.div`
  display: grid;
  grid-template-columns: 24px auto;
  grid-column-gap: 20px;
  padding: 30px 20px;
`;

export const DoneIcon = styled(CheckEntryCheckCircleSharpIcon)`
  color: rgba(3, 173, 235, 0.1);
  font-size: 24px !important;
`;

export const InProgressIcon = styled.div`
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  background-color: rgba(108, 207, 129, 0.1);
`;

export const NotDoneIcon = styled.div`
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  background-color: rgba(244, 147, 66, 0.1);
`;

export const MileStoneText = styled(Typography)`
  color: #212b36;
  font-size: 16px !important;
  font-weight: 600 !important;
  letter-spacing: 0;
  line-height: 24px !important;
  display: flex;

  align-items: flex-end;
  padding-left: 15px;
`;

export const ItemWrapper = styled.div`
  border-radius: 15px;
  overflow: hidden;
`;

export const Item = styled.div`
  height: 30px;
  background-color: ${(props) => props.bgColor};
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-size: 14px;
`;

export const ItemIcon = styled.div`
  width: 20px;
  height: 20px;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) => (props.right ? "margin-left: auto" : "margin-right: auto")};
  border-radius: 50%;
`;
