import React, { useState, useEffect } from "react";
import { Menu, MenuItem } from "@material-ui/core";
import { useQuery } from "react-query";
import { UserName, UserArrowDropDownIcon } from "../../../components/AdminDashboardNav/style";
import adminAPI from "../../../redux/api/adminAPI";
import YearPicker from "../../../components/ControlledYearPicker";
import { useRoles } from "../../../hooks/useUserDetails";

const OverviewFilter = ({ showMinistryFilter, setfilterParams }) => {
  const { user } = useRoles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedMinistry, setSelectedMinistry] = useState({});
  const [selectedYear, setSelectedYear] = useState(null);

  useEffect(() => {
    setfilterParams({
      MinistryId: selectedMinistry.id,
      Year: selectedYear,
    });
    // eslint-disable-next-line
  }, [selectedMinistry, selectedYear]);

  const { data: ministries } = useQuery({
    queryFn: adminAPI.getMinistriesAxios,
    queryKey: [
      "ministries",
      {
        pageSize: 20,
      },
    ],
    config: {
      enabled: showMinistryFilter,
    },
  });

  const open = Boolean(anchorEl);
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div
        style={{
          cursor: showMinistryFilter && "pointer",
          display: "flex",
          alignItems: "center",
          background: "#fff",
          padding: "5px 10px",
          marginRight: 20,
        }}
        aria-controls="menu-appbar"
        onClick={showMinistryFilter ? (e) => handleMenu(e) : null}
      >
        <UserName>
          {!showMinistryFilter ? user.ministry.name : !!selectedMinistry.name ? selectedMinistry.name : "Ministry"}
        </UserName>

        {showMinistryFilter && <UserArrowDropDownIcon />}
      </div>
      <div
        style={{ cursor: "pointer", display: "flex", alignItems: "center", background: "#fff", padding: "5px 10px" }}
        aria-controls="menu-appbar"
      >
        <YearPicker handleChange={(year) => setSelectedYear(new Date(year).getFullYear())} />
      </div>

      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        onClose={handleClose}
      >
        <div
          onClick={() => {
            setSelectedMinistry({});
            handleClose();
          }}
        >
          <span
            style={{
              display: "inline-block",
              backgroundColor: "rgba(0, 176, 241, 0.13)",
              color: "#00B0F1",
              borderRadius: "5px",
              margin: "15px 0 5px 15px",
              padding: 5,
              fontSize: 12,
              cursor: "pointer",
            }}
          >
            Clear filter
          </span>
        </div>
        {ministries?.data?.map((ministry) => (
          <MenuItem
            key={ministry.id}
            onClick={() => {
              setSelectedMinistry(ministry);
              handleClose();
            }}
          >
            {ministry.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default OverviewFilter;
