import React, { useContext, useState } from "react";
import Box from "@material-ui/core/Box";
import { Title } from "../style";
import { PaymentGatewayList, PaymentGatewayItem, PaymentGatewayIcon, PaymentGatewayName, LoadingWrapper } from "./style";
import { useMutation } from "react-query";
import paymentAPI from "../../../../../redux/api/paymentAPI";
import { useLocation } from "react-router-dom";
import ToastContext from "../../../../../util/toastContext";
import CircularProgress from "@material-ui/core/CircularProgress";

const paymentGateways = [
  {
    id: "express-pay",
    icon: require("../../../../../assets/payment-gateways/express-pay.png"),
    name: "Express Pay",
  },
];

export default function OnlinePayment({ onPaymentComplete, tenderId }) {
  const { pathname } = useLocation();
  const { showAlert } = useContext(ToastContext);
  const [chosenGateway, setChosenGateway] = useState(null);
  const [initializePayment, { isLoading }] = useMutation(paymentAPI.initiateBidPayment, {
    onSuccess(paymentPayload) {
      const body = {
        publicKey: paymentPayload.publicKey,
        transactionId: paymentPayload.transactionId,
        amount: paymentPayload.amount,
        currency: paymentPayload.currency,
        country: paymentPayload.country,
        email: paymentPayload.email,
        phoneNumber: paymentPayload.phoneNumber,
        firstName: paymentPayload.firstName,
        lastName: paymentPayload.lastName,
        hash: paymentPayload.hash,
        callbackUrl: paymentPayload.callbackUrl,
        logoURL: paymentPayload.logoUrl,
      };

      window.xpressPayonlineSetup(body);
    },
    onError(error) {
      showAlert({
        severity: "error",
        message: error.message,
        durationInMs: 3000,
      });
    },
  });

  const callbackUrl = `${window.location.origin}${pathname}/complete-payment`;

  const selectPaymentGateway = async (id) => {
    setChosenGateway(id);
    await initializePayment({
      tenderId,
      callbackUrl,
    });
  };

  return (
    <Box mb={3.75}>
      <Title secondary bottomSpacing="15px">
        Select Payment Gateway
      </Title>
      <PaymentGatewayList>
        {paymentGateways.map((gateway) => (
          <PaymentGatewayItem disabled={isLoading} key={gateway.id} onClick={() => selectPaymentGateway(gateway.id)}>
            <PaymentGatewayIcon src={gateway.icon} alt={gateway.name} />
            <PaymentGatewayName>{gateway.name}</PaymentGatewayName>
            {isLoading && <LoadingWrapper>{chosenGateway === gateway.id && <CircularProgress size={32} />}</LoadingWrapper>}
          </PaymentGatewayItem>
        ))}
      </PaymentGatewayList>
    </Box>
  );
}
