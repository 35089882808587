import { Box, Grid } from "@material-ui/core";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import moment from "moment";

import Breadcrumb from "../../../Common/PageElements/Breadcrumb";
import { PageTitle, PageWrapper } from "../../../Common/style";
import withAdminDashboard from "../../HOC/withAdminDashboard";
import { ApproveButton, RejectButton, VendorCardContainer } from "../style";
import VendorRegistrationCategory from "../VendorRegistrationCategory";
import { AllFileText, CompanyCardLabel, CompanyCardTitle, CompanyCardValue, CompanySubtitle } from "./style";
import { Divider } from "../../../Vendor/BidDetails/NewBidInfo/style";
import FileProgress from "../../../../components/FileProgress/FileProgress";
import vendorsAPI from "../../../../redux/api/vendorsAPI";
import Loader from "../../../../components/Common/Loader";
import { coreCompetencies, organizationTypes } from "../../../../components/Forms/GeneralCompanyDetails/mock";
import procurementAPI from "../../../../redux/api/procurementAPI";
import { Roles } from "../../../../components/AccessControl/UserRoles";
import AccessControl from "../../../../components/AccessControl";
import ApproveVerifyVendorModal from "./ApproveVerifyVendorModal";
import RejectVendorModal from "./RejectVendorModal";

const VendorVerify = () => {
  const [showApprove, setShowApprove] = useState(false);
  const [showNeedForAmendment, setShowNeedForAmendment] = useState(false);
  const { vendorId, vendorProfileId } = useParams();
  const vendorDetailsQueryKey = ["companyDetails", { vendorId }];
  const { data: vendorDetails, isLoading } = useQuery(vendorDetailsQueryKey, vendorsAPI.getVendorsCompanyDetails);
  const { data: vendorDoc, isLoading: docIsLoading } = useQuery(
    ["vendorProfileDoc", { vendorId }],
    vendorsAPI.getVendorProfileDoc
  );

  const { data: invoiceHistory } = useQuery(["invoiceHistory", { vendorProfileId }], procurementAPI.getPaymentHistory);
  const { data: vendorPaymentProof } = useQuery(["invoiceHistory", { vendorId }], procurementAPI.getVendorPaymentProof);

  const RenderFormChild = ({ cellSpan = 6, title, value }) => {
    return (
      <Grid item xs={cellSpan}>
        <CompanyCardLabel>{title}</CompanyCardLabel>
        <CompanyCardValue>{value}</CompanyCardValue>
      </Grid>
    );
  };

  const redirectToFileUrl = (url) => {
    window.open(url, "_blank");
  };

  return (
    <PageWrapper>
      <Box mb="15px">
        <PageTitle>Vendor Verify</PageTitle>
        <Breadcrumb
          values={[
            { url: "/admin/vendorsList/vendors", title: "Administration" },
            { url: `/admin/vendorsList/${vendorId}`, title: "Vendors" },
          ]}
        />
      </Box>
      <Box>
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <Box mb="20px">
              <VendorCardContainer>
                {isLoading && <Loader />}
                {!isLoading && (
                  <Box>
                    <PageTitle>{vendorDetails?.companyName || "Vendor Company Name"}</PageTitle>
                    <CompanySubtitle>{`Registration Date: ${
                      (vendorDetails?.incorporationDate && moment(vendorDetails?.incorporationDate).format("Do MMM, YYYY")) ||
                      "N/A"
                    }`}</CompanySubtitle>
                  </Box>
                )}
              </VendorCardContainer>
            </Box>
            <Box>
              <VendorCardContainer>
                <Box>
                  {(isLoading || docIsLoading) && <Loader />}
                  {(!isLoading || !docIsLoading) && (
                    <Box>
                      <Box>
                        <CompanyCardTitle>Company Type</CompanyCardTitle>
                        <Grid container>
                          <RenderFormChild
                            title="Area of Core Competence"
                            value={
                              coreCompetencies.find(({ value }) => Number(value) === vendorDetails?.coreCompetency)?.label ||
                              "N/A"
                            }
                          />
                          <RenderFormChild
                            title="Type of Organization"
                            value={
                              organizationTypes.find(({ value }) => Number(value) === vendorDetails?.organizationType)?.label ||
                              "N/A"
                            }
                          />
                          <RenderFormChild title="Business Name" value={vendorDetails?.companyName || "N/A"} />
                          <RenderFormChild
                            title="Date of Registration/Renewal"
                            value={
                              (vendorDetails?.incorporationDate &&
                                moment(vendorDetails?.incorporationDate).format("DD/MM/YYYY")) ||
                              "N/A"
                            }
                          />
                          <RenderFormChild title="Registration Number" value={vendorDetails?.cacRegistrationNumber || "N/A"} />
                          <RenderFormChild
                            title="Capital Contribution by Partners"
                            value={vendorDetails?.authorizedShareCapital || "N/A"}
                          />
                        </Grid>
                      </Box>

                      <Divider />
                      <Box>
                        <CompanyCardTitle>Business Contact Information</CompanyCardTitle>
                        <Grid container>
                          <RenderFormChild cellSpan={12} title="Address 1" value={vendorDetails?.addressLine1 || "N/A"} />
                          <RenderFormChild cellSpan={12} title="Address 2" value={vendorDetails?.addressLine2 || "N/A"} />
                          <RenderFormChild cellSpan={6} title="City" value={vendorDetails?.city || "N/A"} />
                          <RenderFormChild cellSpan={6} title="Country" value={vendorDetails?.country || "N/A"} />
                          <RenderFormChild cellSpan={6} title="State" value={vendorDetails?.state || "N/A"} />
                        </Grid>
                      </Box>

                      <Divider />

                      <Box>
                        <CompanyCardTitle>Correspondence Address (if different from above)</CompanyCardTitle>
                        <Grid container>
                          <RenderFormChild
                            cellSpan={12}
                            title="Address 1"
                            value={vendorDetails?.vendorCorrespondence?.address1 || "N/A"}
                          />
                          <RenderFormChild
                            cellSpan={12}
                            title="Address 2"
                            value={vendorDetails?.vendorCorrespondence?.address2 || "N/A"}
                          />
                          <RenderFormChild cellSpan={6} title="City" value={vendorDetails?.vendorCorrespondence?.city || "N/A"} />
                          <RenderFormChild
                            cellSpan={6}
                            title="Country"
                            value={vendorDetails?.vendorCorrespondence?.country || "N/A"}
                          />
                          <RenderFormChild
                            cellSpan={6}
                            title="State"
                            value={vendorDetails?.vendorCorrespondence?.state || "N/A"}
                          />
                        </Grid>
                      </Box>

                      <Divider />

                      <Box>
                        <CompanyCardTitle>Contact Person</CompanyCardTitle>
                        <Grid container>
                          <RenderFormChild cellSpan={12} title="Name" value={vendorDetails?.vendorContact?.firstName || "N/A"} />
                          <RenderFormChild
                            cellSpan={6}
                            title="Telephone Number"
                            value={vendorDetails?.vendorContact?.phoneNumber || "N/A"}
                          />
                          <RenderFormChild
                            cellSpan={6}
                            title="Email Address"
                            value={vendorDetails?.vendorContact?.email || "N/A"}
                          />
                          <RenderFormChild cellSpan={6} title="Website" value={vendorDetails?.website || "N/A"} />
                          <RenderFormChild
                            cellSpan={6}
                            title="Position"
                            value={vendorDetails?.vendorContact?.position || "N/A"}
                          />
                        </Grid>
                      </Box>
                      <AllFileText>All Files</AllFileText>
                      <Box mt="20px">
                        <Grid container spacing={3}>
                          {vendorDoc?.map((eachDoc, index) => (
                            <Grid key={index} item xs={6}>
                              <FileProgress
                                hasEyes
                                progress={100}
                                fileName={eachDoc.name}
                                fileSize={eachDoc?.file?.bytes}
                                onClickEyes={() => redirectToFileUrl(eachDoc?.file.url)}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </Box>
                      <AccessControl allowedRoles={Roles.CONTRACTORS_REG_BOARD}>
                        {vendorDetails?.status?.toLowerCase() === "pending" && (
                          <Box mt="40px" mb="20px">
                            <ApproveButton onClick={() => setShowApprove(true)} style={{ minWidth: "150px" }}>
                              Approve
                            </ApproveButton>
                            <RejectButton onClick={() => setShowNeedForAmendment(true)} style={{ minWidth: "150px" }}>
                              Reject
                            </RejectButton>
                          </Box>
                        )}
                      </AccessControl>
                    </Box>
                  )}
                </Box>
              </VendorCardContainer>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <VendorCardContainer>
              <VendorRegistrationCategory />
            </VendorCardContainer>

            <Box mt="20px">
              <VendorCardContainer>
                <VendorRegistrationCategory
                  lastPayment={invoiceHistory?.paymentDate}
                  expiryDate={invoiceHistory?.paymentExpirationDate}
                  cardTitle="Manual Payment"
                  showDate={false}
                  showPaymentReceipt={true}
                  receipts={vendorPaymentProof}
                />
              </VendorCardContainer>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <ApproveVerifyVendorModal
        vendorDetails={vendorDetails}
        vendorDetailsQueryKey={vendorDetailsQueryKey}
        showApprove={showApprove}
        setShowApprove={setShowApprove}
      />
      <RejectVendorModal
        vendorDetails={vendorDetails}
        vendorDetailsQueryKey={vendorDetailsQueryKey}
        showNeedForAmendment={showNeedForAmendment}
        setShowNeedForAmendment={setShowNeedForAmendment}
      />
    </PageWrapper>
  );
};

export default withAdminDashboard(VendorVerify);
