import React from "react";
import jwt from "jwt-decode";
import { Redirect } from "react-router-dom";

export const RedirectHelper = (loginRoute, directedRoute, history) => {
  const token = localStorage.getItem("token");
  if (token) {
    if (jwt(token).exp < Date.now() / 1000) {
      localStorage.removeItem("token");
      return <Redirect to={loginRoute} />;
    }
    history.push(directedRoute);
  }
};

export const convertDocumentsToObject = (documents = []) => {
  return documents.reduce(
    (currDocuments, file) => {
      const moddedFile = {
        inDb: true,
        id: file.id,
        name: file.file.original_filename,
        size: file.file.bytes,
        downloadUrl: file.file.url,
      };

      if (file.status === "MANDATORY") {
        return {
          ...currDocuments,
          mandatory: currDocuments.mandatory.concat(moddedFile),
          removed: [],
        };
      }
      return {
        ...currDocuments,
        supporting: currDocuments.supporting.concat(moddedFile),
        removed: [],
      };
    },
    {
      mandatory: [],
      supporting: [],
    }
  );
}

export const openFileUrlInNewWindow = (url) => {
  window.open(url, 'blank')
}
