/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  EmptyState,
  DirectorDetailsContainer,
  DirectorDetailsContentWrapper,
  AvatarContainer,
  DirectorDetailsContent,
  DirectorTitle,
  DirectorAddress,
  ControlContainer,
  AddControlContainer,
} from "./style";
import { ReactComponent as EmptyStateIcon } from "../../../assets/emptyList.svg";
import DirectorDetailsDrawer from "../../DirectorDetailsDrawer";
import { Wrapper } from "../Common/style";
import NavigationButtons from "../../NavigationButtons";
import { getDirectorDetails, deleteDirectorDetails } from "../../../redux/reducer/vendorReducer";
import withVendorRegLoader from "../../../pages/Common/HOC/withVendorRegLoader";

const DirectorsDetails = () => {
  const [openModal, setOpenModal] = useState(false);
  const [directorDetails, setdirectorDetails] = useState([]);
  const [directorDetail, setdirectorDetail] = useState(null);
  const [passportFileNames, setpassportFileNames] = useState([]);
  const [idFilesNames, setidFilesNames] = useState([]);
  const [certificateFilesNames, setcertificateFilesNames] = useState([]);
  const { directorDetails: directorDetailList } = useSelector((state) => state.vendor);
  const { user: { UserId: userId } } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const toggleDrawer = () => setOpenModal((previous) => !previous);

  const handleEdit = (id) => {
    const [filterDetails] = directorDetailList.filter((detail) => detail.id === id);
    setdirectorDetail(filterDetails);

    const passportPhoto = JSON.parse(filterDetails.passportPhoto);
    const idsPhoto = JSON.parse(filterDetails.identificationFile);
    passportPhoto.name = `${passportPhoto.original_filename}.${passportPhoto.format}`;
    idsPhoto.name = `${idsPhoto.original_filename}.${idsPhoto.format}`;

    setpassportFileNames([passportPhoto]);
    setidFilesNames([idsPhoto]);

    const certificateNames = [];
    filterDetails.certifications && filterDetails.certifications.map((eachCert) => {
      const certificate = JSON.parse(eachCert.file);
      const name = `${certificate.original_filename}.${certificate.format}`;
      certificateNames.push({ name });
    });

    setcertificateFilesNames(certificateNames);
    return setOpenModal((previous) => !previous);
  };

  const handleDeleteDirectorDetails = (id) => {
    dispatch(deleteDirectorDetails({ id: userId, directorId: id }));
  };

  const DirectorDetail = () => {
    return (
      <div>
      {directorDetailList && (directorDetailList.length > 0) && (
        <div>
          { directorDetailList.map((detail) => <DirectorDetailsContainer key={`${detail.id}`}>
            <DirectorDetailsContentWrapper>
              <AvatarContainer />
              <DirectorDetailsContent>
                <DirectorTitle>{detail.firstName}</DirectorTitle>
                <DirectorAddress>{detail.addressLine1}</DirectorAddress>
      <DirectorAddress>{detail.phoneNumber} / {detail.certifications.length + 2} Attachments</DirectorAddress>
                <ControlContainer>
                  <span onClick={() => handleEdit(detail.id)}>Edit</span>
                  <span onClick={() => handleDeleteDirectorDetails(detail.id)}>Delete</span>
                </ControlContainer>
              </DirectorDetailsContent>
            </DirectorDetailsContentWrapper>
          </DirectorDetailsContainer>)}
          <AddControlContainer onClick={() => toggleDrawer()}>
            Add New
      </AddControlContainer>
        </div>
      )}
      {directorDetailList && (directorDetailList.length < 1) && (<EmptyState>
        <EmptyStateIcon />
        <p>You have no Director/Partner</p>
        <span>You have no Director/Partner in your record.
        <span onClick={() => toggleDrawer()}> Click here</span> to create.
      </span>
      </EmptyState>)}
    </div>
    )
  }

  return (
    <Wrapper>
      
    {withVendorRegLoader(DirectorDetail)({
      title: "Directors/Partners Details",
      description: "Please list each owner, proprietor, partner, officer and shareholder",
      apis: [getDirectorDetails(userId)],
      userId: userId,
      conditioners: ["directorDetails"]
    })}
      <DirectorDetailsDrawer
        isOpen={openModal}
        toggleDrawer={toggleDrawer}
        passportFileNames={passportFileNames}
        setpassportFileNames={setpassportFileNames}
        idFilesNames={idFilesNames}
        setidFilesNames={setidFilesNames}
        certificateFilesNames={certificateFilesNames}
        setcertificateFilesNames={setcertificateFilesNames}
        directorDetails={directorDetails}
        setdirectorDetails={setdirectorDetails}
        setdirectorDetail={setdirectorDetail}
        directorDetail={directorDetail}
      />
      <NavigationButtons />
    </Wrapper>
  );
};

export default DirectorsDetails;
