import React from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { Navbar } from "../../../components";
import { FormSectionWrapper, ProgressSectionWrapper, Wrapper } from "../Authenticated/Registration/style";
import FormHomepage from "../../../components/Forms/FormHomepage";
import { DocumentWrapper, FolderIcon, Text, CardIcon, ForwardIcon, WrapContent, WrapPaymentContent } from "./style";

const SignUp = () => {
  const HomeFormExtras = () => (
    <>
      <a
        href="https://res.cloudinary.com/prunedge/image/upload/v1623752257/E-Procurement/Documents/Delta_e-Procurement_User_Manual_-_Vendors_1_asdyn0.pdf"
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: "inherit", textDecoration: "none" }}
      >
        <DocumentWrapper>
          <WrapContent>
            <FolderIcon />
            <Text> Download User Guide </Text>
          </WrapContent>
          <ForwardIcon iconcolor="#9c69de" />
        </DocumentWrapper>
      </a>
      <DocumentWrapper>
        <WrapPaymentContent>
          <CardIcon />
          <Text> Payment Details </Text>
        </WrapPaymentContent>
        <ForwardIcon iconcolor="#eec200" />
      </DocumentWrapper>
    </>
  );

  return (
    <>
      <Navbar />
      <Container>
        <Wrapper>
          <Grid container justify="space-between">
            <Grid item xs={6} style={{ position: "relative" }}>
              <FormSectionWrapper>
                <FormHomepage />
              </FormSectionWrapper>
            </Grid>
            <Grid item>
              <ProgressSectionWrapper>
                <HomeFormExtras />
              </ProgressSectionWrapper>
            </Grid>
          </Grid>
        </Wrapper>
      </Container>
    </>
  );
};

export default SignUp;
