import styled from "styled-components";
import { Typography } from "@material-ui/core";
import { TableStatusContainer } from "../../Common/style";
import { TagColorScheme } from "../../../util/constants";
import { Link } from "react-router-dom";

export const UserPlaceHolder = styled.div`
  border-radius: 50%;
  background-color: green;
  height: 20px;
  width: 20px;
  color: #ffffff;
  font-size: 14px !important;
  font-weight: 500 !important;
  letter-spacing: 0;
  line-height: 20px !important;
  text-align: center !important;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px !important;
`;

export const ActiveStatus = styled(Typography)`
  border: 2px solid #ffffff;
  border-radius: 100px;
  background-color: ${(props) => TagColorScheme[props.status]?.bgColor};
  color: ${(props) => TagColorScheme[props.status]?.textColor};
  font-size: 13px !important;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  padding: 0 10px;
  display: inline-block;
`;

export const ProjectProgressContainer = styled.div`
  box-sizing: border-box;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 #c9d4db;
  margin-left: 23px !important;
  padding: 20px !important;
`;

export const ProjectDocumentContainer = styled.div`
  box-sizing: border-box;
  border: 1px solid #cbd4da;
  border-radius: 3px;
  background-color: #f4fcff;
  padding: 20.5px;
  margin-top: 19.5px;
`;

export const EmptyProjectMilestone = styled.div`
  min-height: 462px;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 #c9d4db;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const CommentHeaderContainer = styled(Typography)`
  box-sizing: border-box;
  height: 46px;
  border: 1px solid #cbd4da;
  background-color: #e5e9ec;
  color: #454f5b;
  font-size: 16px !important;
  font-weight: bold !important;
  letter-spacing: 0;
  line-height: 22px !important;
  margin-top: 20px !important;
  display: flex;
  align-items: center;
  padding: 12px 21px;
`;

export const CalendarContainer = styled.div`
  > div {
    margin-top: 0 !important;
  }
`;

export const InvoiceTableHeader = styled.div`
  .MuiTableHead-root th {
    background-color: #fff !important;
  }

  > div {
    margin-top: 0 !important;
  }
`;

export const InvoiceTableStatus = styled(TableStatusContainer)`
  display: inline-block;
  width: 80px;
`;

export const MilestoneDate = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  > div {
    margin-left: 10px;
  }
  p {
    margin-top: 0;
  }
`;

export const TitleLink = styled(Link)`
  color: #445571;
  text-decoration: none;
`
