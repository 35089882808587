import { Box, Grid } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import AccessControl from "../../../../components/AccessControl";
import { Roles } from "../../../../components/AccessControl/UserRoles";
import procurementAPI from "../../../../redux/api/procurementAPI";
import { formatCurrency } from "../../../../util/formatCurrency";
import { PageTitle } from "../../../Common/style";
import {
  DocFileIcon,
  DocumentUploadContainer,
  DocumentUploadInfo,
  DocumentUploadTitle,
} from "../../../Vendor/BidDetails/BidInfo/style";
import {
  BudgetValue,
  CloseButton,
  ContractDetail,
  ContractDetails,
  ContractDetailTitle,
  ContractDetailValue,
  ModalButtonWrapper,
  Notice,
  NoticeContent,
  NoticeDivider,
  PlanDetailsDialog,
  PlanDetailsDialogContent,
} from "./style";

const FullGeneralPlanDetails = ({ toggleModal, isOpen }) => {
  const { id } = useParams();

  const { data: ministryGeneralPlan } = useQuery(["ministryGenPlan", id], procurementAPI.getMinistryGeneralPlans, {
    enabled: id,
  });

  const { data: getMinistryGeneralPlansDocument, ...generalPlanQuery } = useQuery(
    ["ministryGenPlanDocument", id],
    procurementAPI.getMinistryGeneralPlansDocument,
    {
      enabled: id,
    }
  );

  const ministry = ministryGeneralPlan?.ministry;

  return (
    <AccessControl allowedRoles={[Roles.DSPPC, Roles.PROCUREMENT_OFFICER]}>
      <PlanDetailsDialog fullWidth={true} maxWidth={"md"} onClose={() => toggleModal(false)} open={isOpen}>
        <PlanDetailsDialogContent>
          <ModalButtonWrapper>
            <div></div>
            <CloseButton style={{ float: "right" }} onClick={() => toggleModal(false)}>
              Close
            </CloseButton>
          </ModalButtonWrapper>

          <PageTitle style={{ marginTop: "20px" }}>{ministry && ministry.name}</PageTitle>

          <div style={{ width: "100%" }}>
            <Notice style={{ marginTop: "20px" }}>
              <NoticeContent>{ministryGeneralPlan?.description || "N/A"}</NoticeContent>
            </Notice>

            {!generalPlanQuery?.isLoading && (
              <Grid container spacing={2} style={{ marginTop: "20px", marginBottom: "20px" }}>
                {getMinistryGeneralPlansDocument?.map((eachDoc) => (
                  <Grid item xs={4} key={eachDoc.name}>
                    <DocumentUploadContainer>
                      <Box display="flex" alignItems="center">
                        <DocFileIcon />
                        <Box>
                          <DocumentUploadTitle>{eachDoc.name}</DocumentUploadTitle>
                          <DocumentUploadInfo>735kb - 4 seconds left</DocumentUploadInfo>
                        </Box>
                      </Box>
                      <VisibilityIcon />
                    </DocumentUploadContainer>
                  </Grid>
                ))}
              </Grid>
            )}

            <NoticeDivider style={{ marginTop: "20px" }} />
            <ContractDetail style={{ marginTop: "20px" }}>
              <ContractDetailTitle>Budget</ContractDetailTitle>
              <BudgetValue>{formatCurrency(ministryGeneralPlan?.budget, true) || "N/A"}</BudgetValue>
            </ContractDetail>
            <NoticeDivider />

            <ContractDetails>
              <Grid container>
                <Grid item xs={12}>
                  <ContractDetailTitle style={{ marginBottom: "20px" }}>Contact Information</ContractDetailTitle>
                </Grid>

                <Grid item xs={4}>
                  <ContractDetail>
                    <ContractDetailTitle>Title</ContractDetailTitle>
                    <ContractDetailValue>{ministryGeneralPlan?.title || "N/A"}</ContractDetailValue>
                  </ContractDetail>
                </Grid>

                <Grid item xs={4}>
                  <ContractDetail>
                    <ContractDetailTitle>Position</ContractDetailTitle>
                    <ContractDetailValue>{ministryGeneralPlan?.name || "N/A"}</ContractDetailValue>
                  </ContractDetail>
                </Grid>

                <Grid item xs={4}>
                  <ContractDetail>
                    <ContractDetailTitle>Country</ContractDetailTitle>
                    <ContractDetailValue>{ministryGeneralPlan?.country || "N/A"}</ContractDetailValue>
                  </ContractDetail>
                </Grid>

                <Grid item xs={4}>
                  <ContractDetail>
                    <ContractDetailTitle>Phone number</ContractDetailTitle>
                    <ContractDetailValue>{ministryGeneralPlan?.phoneNumber || "N/A"}</ContractDetailValue>
                  </ContractDetail>
                </Grid>
                <Grid item xs={4}>
                  <ContractDetail>
                    <ContractDetailTitle>Email address</ContractDetailTitle>
                    <ContractDetailValue>{ministryGeneralPlan?.email || "N/A"}</ContractDetailValue>
                  </ContractDetail>
                </Grid>

                <Grid item xs={4}></Grid>

                <Grid item xs={12}>
                  <ContractDetailTitle style={{ marginTop: "20px", marginBottom: "20px" }}>
                    Additional Information
                  </ContractDetailTitle>
                </Grid>

                <Grid item xs={4}>
                  <ContractDetail>
                    <ContractDetailTitle>Fax</ContractDetailTitle>
                    <ContractDetailValue>{ministryGeneralPlan?.fax || "N/A"}</ContractDetailValue>
                  </ContractDetail>
                </Grid>
                <Grid item xs={4}>
                  <ContractDetail>
                    <ContractDetailTitle>URL</ContractDetailTitle>
                    <ContractDetailValue>{ministryGeneralPlan?.website || "N/A"}</ContractDetailValue>
                  </ContractDetail>
                </Grid>
                <Grid item xs={4}></Grid>

                <Grid item>
                  <ContractDetail>
                    <ContractDetailTitle>Address</ContractDetailTitle>
                    <ContractDetailValue>{ministryGeneralPlan?.address || "N/A"}</ContractDetailValue>
                  </ContractDetail>
                </Grid>
              </Grid>
            </ContractDetails>
          </div>
        </PlanDetailsDialogContent>
      </PlanDetailsDialog>
    </AccessControl>
  );
};

export default FullGeneralPlanDetails;
