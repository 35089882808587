import React from "react";
import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import moment from "moment";

import { ContractDetailTabText } from "./style";
import { formatCurrency } from "../../../../util/formatCurrency";

const TenderDetailsTab = () => {
  const {
    adminContractDetails: { tenderDetails },
  } = useSelector((state) => state.admin);

  const details = [
    { title: "Ministry Name", value: tenderDetails.ministryName },
    { title: "Tender Name", value: tenderDetails.tenderName || "Not available" },
    { title: "Tender package number", value: tenderDetails.packageNumber || "Not available" },
    { title: "Procurement method", value: tenderDetails.procurementMethod || "Not available" },
    { title: "Procurement category", value: tenderDetails.procurementCategory || "Not available" },

    { title: "Tendering stage", value: tenderDetails.tenderingStage },
    { title: "Review method", value: tenderDetails.reviewMethod },
    { title: "Tender application fee", value: <>&#8358;{formatCurrency(tenderDetails.fee)}</> || "Not available" },
    { title: "Date published", value: tenderDetails.publishedDate ? moment(tenderDetails.publishedDate).format("DD, MMMM, yyyy") : "Not Available" },
    { title: "Submission deadline", value: tenderDetails.deadline ? moment(tenderDetails.deadline).format("DD, MMMM, yyyy") : "Not available" },
    { title: "Tender status {awarded, in progress, completed}", value: tenderDetails.status || "Not available" },
  ];

  return details.map((detail, i) => (
    <Grid key={i} style={{ borderBottom: "1px solid #CBD4DA" }} container>
      <Grid item xs={5}>
        <ContractDetailTabText>{detail.title}</ContractDetailTabText>
      </Grid>
      <Grid item sm style={{ borderLeft: "1px solid #CBD4DA" }}>
        <ContractDetailTabText>{detail.value}</ContractDetailTabText>
      </Grid>
    </Grid>
  ));
};

export default TenderDetailsTab;
