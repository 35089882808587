import React from "react";

import { DetailItemLabel, DetailItemText, DetailItemWrapper } from "./style";

function DetailItem({ label, text, ...props }) {
  return (
    <DetailItemWrapper {...props}>
      <DetailItemLabel className="detail-item-label">{label}</DetailItemLabel>
      <DetailItemText className="detail-item-text">{text || "N/A"}</DetailItemText>
    </DetailItemWrapper>
  );
}

export default DetailItem;
