import React, { useEffect, useContext } from "react";
import vendorsAPI from "../../../../redux/api/vendorsAPI";
import ToastContext from "../../../../util/toastContext";
import SearchTable, { StyledTableCell } from "../../../../components/SearchTable";
import TableEmptyContent from "../../../Common/TableEmptyContent";
import { HoverTableStyle } from "../../../../util/theme";
import { UserPlaceHolder, StyledLink } from "./style";
import Box from "@material-ui/core/Box";
import { useQuery } from "react-query";

export default function BiddersTable({ procurementId }) {
  const { isLoading, isError, data = [], error } = useQuery(
    ["getVendorProcurements", procurementId],
    vendorsAPI.getVendorProcurements,
    {
      enabled: !!procurementId,
    }
  );

  const { showAlert } = useContext(ToastContext);

  const renderLoader = () => {
    return (
      <TableEmptyContent noColSpan={4} textDisplayed="Loading...">
        {" "}
      </TableEmptyContent>
    );
  };

  useEffect(() => {
    if (isError) {
      showAlert({
        severity: "error",
        message: error.message,
        durationInMs: 3000,
      });
    }
  }, [isError, error, showAlert]);

  const renderTableBody = () => {
    if (isLoading || !procurementId) return renderLoader();
    if (data.length === 0) return <TableEmptyContent noColSpan={4} textDisplayed="Bidders Information is not Available" />;
    return data.map((eachBidder) => (
      <HoverTableStyle key={eachBidder.companyName}>
        <StyledTableCell>
          <Box display="flex" alignItems="center">
            <UserPlaceHolder>OO</UserPlaceHolder>
            {eachBidder.companyName}
          </Box>
        </StyledTableCell>
        <StyledTableCell>{eachBidder.cacRegistrationNumber || "N/A"}</StyledTableCell>
        <StyledTableCell>
          {eachBidder.city || "N/A"}, {eachBidder.state || "N/A"}
        </StyledTableCell>
        <StyledTableCell>
          {eachBidder.website ? (
            <StyledLink href={eachBidder.website} target="_blank" rel="noreferer noopener">
              {eachBidder.website}
            </StyledLink>
          ) : (
            "N/A"
          )}
        </StyledTableCell>
      </HoverTableStyle>
    ));
  };

  return (
    <SearchTable
      rows={data.length}
      tableHeaderText={`Showing ${data.length} Result`}
      columns={["BIDDER", "RC NUMBER", "LOCATION", "URL"]}
    >
      {renderTableBody()}
    </SearchTable>
  );
}
