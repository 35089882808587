/* eslint-disable no-unused-expressions */
import withAdminDashboard from "../../../HOC/withAdminDashboard";
import withActivityLayout from "../../../../../components/Common/HOC/withActivityLayout";
import procurementAPI from "../../../../../redux/api/procurementAPI";

const ContractPublication = () => (
  withActivityLayout(() => null)({
    title: "Publication of Contracts",
    postAPI: procurementAPI.savePlanDocument,
    getAPI: procurementAPI.getPlanDocuments,
    extraCrumbs: [
      {
        url: "#",
        title: "Publication of Contracts",
      },
    ]
  })
);

export default withAdminDashboard(ContractPublication);
