import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import { theme } from "../../../util/theme";
import InputField from "../../InputField";

export const FormWrapper = styled.div`
  min-height: 545px;
`;

export const FormContent = styled.div`
  box-sizing: border-box;
  height: 195px;
  border: 1px solid #ebebeb;
  border-radius: 3px;
  background-color: #f8f8f9;
  margin-top: 25px !important;
  margin-left: 23.5px !important;
  margin-right: 23.5px !important;
`;

export const RoleInputField = styled(InputField)`
  border: 1px solid #c9d4db;
  border-radius: 3px;
  background-color: #ffffff !important;
  height: 49px !important;
`;

export const PermissionList = styled.div`
  padding: 10px 0 0 20px;
`;

export const PermissionListItemContainer = styled.div`
  ${""}
  padding-left: 20px;
  display: flex;
  align-items: center;
  position: relative;

  .MuiCheckbox-colorSecondary.Mui-checked {
    color: ${theme.color.primary};
  }

  .MuiMenu-paper {
    box-shadow: 0 0 5px #e4e4e4;
  }
`;

export const PermissionListItemTitle = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const CircularShape = styled.div`
  border-radius: 50%;
`;

export const PermissionName = styled(Typography)`
  font-size: 14px !important;
  color: #212b36 !important;
  float: left;
`;

export const PermissionSelectionContainer = styled.div`
  display: flex;
`;

export const PermissionCheckBox = styled(Checkbox)`
  margin-right: 10px !important;
  border-radius: 50%;
`;

export const PermissionContainerHolder = styled.div`
  margin-top: 15px;
  background-color: #f8f8f9;
  min-width: 609px;
  overflow-x: hidden;
`;

export const ErrorContainer = styled.div`
  padding-left: 20px;
  position: relative;
  top: -10px;
`;
