import React, { useState, useMemo, useEffect, useCallback } from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { useHistory } from "react-router-dom";
import { Navbar, ProgressSection, FormsSection } from "../../../../components";
import { FormSectionWrapper, ProgressSectionWrapper, Wrapper, CloseButton, NavMargin } from "./style";
import FormContext from "../../../../util/context";
import { Forms } from "../../../../components/FormsSection";
import ToastContext from "../../../../util/toastContext";
import useAlert from "../../../../hooks/useAlert";
import { useLocation } from "react-router-dom";
import { useMutation } from "react-query";
import vendorsAPI from "../../../../redux/api/vendorsAPI";
import UserProfileTab from "../../../../components/AdminDashboardNav/UserProfileTab";

const genCompletedForm = (stage) => {
  if (stage === null) return [];
  return Array.from({ length: stage }).map((_, index) => index);
};

const VendorRegistration = () => {
  const { search } = useLocation();
  const urlParamStage = useMemo(() => {
    const params = new URLSearchParams(search);
    const stage = params.get("stage");
    return stage ? parseInt(stage) - 1 : null;
  }, [search]);

  const [activeForm, setActiveForm] = useState(urlParamStage ?? 0);
  const [completedForm, addCompletedForm] = useState(() => genCompletedForm(urlParamStage));
  const { showAlert, Toast } = useAlert();
  const history = useHistory();

  const [updateRegStage, updateStageQuery] = useMutation(vendorsAPI.updateVendorRegistrationStage, {
    throwOnError: true,
  });

  useEffect(() => {
    if (updateStageQuery.isError) {
      showAlert({
        severity: "error",
        message: updateStageQuery.error.message,
      });
    }
  }, [updateStageQuery.isError, updateStageQuery.error, showAlert]);

  const updateStage = useCallback(
    async (stage, cb) => {
      // this function exists so that forms only show
      // success and proceed if the reg stage was successfully updated
      try {
        await updateRegStage(stage);
        cb();
      } catch (e) {
        // the effect will show the error
      }
    },
    [updateRegStage]
  );

  return (
    <ToastContext.Provider value={{ showAlert, Toast }}>
      <FormContext.Provider
        value={{
          activeForm,
          completedForm,
          setActiveForm,
          addCompletedForm,
          sectionSize: Object.keys(Forms).length,
          updateStage,
          updateStageQuery,
        }}
      >
        <Navbar
          rightContent={
            <>
              <NavMargin>
                <UserProfileTab />
              </NavMargin>
              <CloseButton
                variant="outlined"
                color="default"
                onClick={() => {
                  history.push("/vendor/overview");
                }}
              >
                Back to dashboard
              </CloseButton>
            </>
          }
        />
        <Container>
          <Wrapper>
            <Toast customStyles={{ zIndex: "9999" }} float />
            <Grid container justify="space-between">
              <Grid item xs={6} style={{ position: "relative" }}>
                <FormSectionWrapper>
                  <FormsSection />
                </FormSectionWrapper>
              </Grid>
              <Grid item>
                <ProgressSectionWrapper>
                  <ProgressSection />
                </ProgressSectionWrapper>
              </Grid>
            </Grid>
          </Wrapper>
        </Container>
      </FormContext.Provider>
    </ToastContext.Provider>
  );
};

export default VendorRegistration;
