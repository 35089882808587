/* eslint-disable no-unused-expressions */
/* eslint-disable no-undef */
export const sectionedDocs = ({
  mandatory, supporting, removed, type
}) => {
  const formData = new window.FormData();
  
  mandatory.length && mandatory.forEach((file) => {
    if (file instanceof File) {
      if (type === "datasheet") {
        return formData.append("Documents.MandatoryDocument", file);
      }
      return formData.append("MandatoryDocument", file);
    }
  });

  supporting.length && supporting.forEach((file) => {
    if (file instanceof File) {
      if (type === "datasheet") {
        formData.append("Documents.SupportingDocument", file);
      }
      return formData.append("SupportingDocument", file);
    }
  });

  removed?.length && removed.forEach((docId) => {
    if (type === "datasheet") {
      return formData.append("Documents.RemovedDocuments", docId);
    }
    return formData.append("RemovedDocuments", docId);
  });

  return formData;
};
