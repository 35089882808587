import { Box, Grid } from "@material-ui/core";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import ListAltIcon from "@material-ui/icons/ListAlt";
import moment from "moment";
import { GeneralTitlePage } from "../../../../../components/AddGeneralPlan/style";
import Breadcrumb from "../../../../Common/PageElements/Breadcrumb";
import { ContentWrapper } from "../../../Administration/Staff/style";

import {
  BorderFrame,
  EdgeRectangle,
  ProjectDivider,
  ProjectLabel,
  ProjectMonitorCardContainer,
  ProjectProcureSubTitle,
  ProjectProcureTitle,
  ProjectSpacer,
  ProjectViewMore,
  ProjectProcureTitleNoStyle,
  NoMilestoneContainer,
} from "./style";
import CalendarTimeLine from "../../../../../components/CalendarTimeline";
import ProjectProgressBar from "./ProjectProgressBar";
import DocumentList from "./DocumentList";
import MediaDocumentSection from "./MediaDocSection";
import DocumentUploadField from "../../../../../components/DocumentUploadField";
import { Dropdown } from "../../../../../components";
import { SaveButton } from "../../../../../components/Forms/Common/style";
import { BackButton } from "../../../../../components/NavigationButtons/style";
import { useMutation, useQuery } from "react-query";
import procurementAPI from "../../../../../redux/api/procurementAPI";
import { formatCurrency } from "../../../../../util/formatCurrency";
import Loader from "../../../../../components/Common/Loader";
import transformMileStonesToCalendarTime from "../../../../../util/transforms/calendartime";
import RenderComponent from "../../../../../components/RenderComponent";

const ProjectMonitoring = () => {
  const [isOpen, toggleModal] = useState(false);
  const { activityId, procurementId } = useParams();
  const history = useHistory();
  const [uploadDocs, setUploadDocs] = useState([]);
  const { data: project, isLoading } = useQuery(
    ["adminProjectDetails", { tenderId: procurementId }],
    procurementAPI.getProjectDetails
  );
  const [uploadProjectCompletionProof, uploadProjectResp] = useMutation(procurementAPI.uploadProjectCompletionProof);

  const { data: projectMilestones, isLoading: projectMilestoneLoading } = useQuery(
    ["adminProjectDocument", { projectId: project?.id }],
    procurementAPI.getProjectMilestone,
    {
      enabled: project?.id,
    }
  );

  const { data: projectCompletionProof, isLoading: projectCompletionIsLoading } = useQuery(
    ["projectCompletionDoc", { projectId: project?.id }],
    procurementAPI.getProjectCompletionProof,
    {
      enabled: project?.id,
    }
  );

  const { data: projectDocumentList, isLoading: projectDocListLoading } = useQuery(
    ["adminProjectDocumentList", { tenderId: procurementId }],
    procurementAPI.getProjectDocumentList
  );

  const handleClickMilestone = (event) => {
    history.push(
      `/admin/procurement/activity/contractsupervisionandmanagement/${activityId}/${procurementId}/milestone/${project?.id}/${event.target.id}`
    );
  };

  const handleAddFile = (file) => {
    setUploadDocs([...uploadDocs, ...file]);
  };

  const handleRemoveFile = (file) => {
    const filteredFile = uploadDocs.filter((x) => x.name !== file.name);
    setUploadDocs(filteredFile);
  };

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("Status", 3);
    uploadDocs.map((eachDoc) => formData.append("Documents", eachDoc));
    uploadProjectCompletionProof({ formData, projectId: project?.id });
  };

  const MilestoneSection = () => {
    const { groups, items } = transformMileStonesToCalendarTime({ data: projectMilestones });
    return <CalendarTimeLine groups={groups} items={items} title="Milestones" handleMilestoneClick={handleClickMilestone} />;
  };

  return (
    <ContentWrapper>
      <Box mt="20px">
        <GeneralTitlePage>Project Monitoring</GeneralTitlePage>
        <Breadcrumb
          values={[
            {
              url: "/admin/procurement/plans",
              title: "Home",
            },
            {
              url: `/admin/procurement/plans/${procurementId}`,
              title: "Plan",
            },
            {
              url: `/admin/procurement/plan-details/${procurementId}`,
              title: "Delta Irrigation Agricultural Improvement",
            },
          ]}
        />
      </Box>

      <Box marginTop="15px">
        <ProjectMonitorCardContainer>
          {isLoading && <Loader />}
          <Box display={isLoading ? "none" : "block"}>
            <Box display="flex">
              <Box width="100%">
                <BorderFrame>
                  <Box paddingLeft="20px" paddingBottom="20px">
                    <Box display="flex">
                      <Box paddingTop="14px">
                        <ProjectProcureTitle>Project Information</ProjectProcureTitle>
                        <ProjectProcureSubTitle>{project?.ministry}</ProjectProcureSubTitle>
                      </Box>
                      <ProjectSpacer />
                      <Box>
                        <EdgeRectangle>
                          <ProjectLabel>Date Published</ProjectLabel>
                          <ProjectProcureSubTitle>
                            {(project?.ministry && moment(project?.publishedDate).format("DD/MM/YYYY")) || "N/A"}
                          </ProjectProcureSubTitle>
                        </EdgeRectangle>
                      </Box>
                    </Box>
                    <Box marginTop="17px" paddingRight="20px">
                      <ProjectProcureTitle>{project?.name || "N/A"}</ProjectProcureTitle>
                      <Box marginTop="20px" marginBottom="10px">
                        <ProjectLabel>Tender Description</ProjectLabel>
                      </Box>
                      <Box marginBottom="20px">
                        <ProjectProcureSubTitle>{project?.description || "N/A"}</ProjectProcureSubTitle>
                      </Box>
                      <ProjectDivider />
                      <Box marginTop="20px">
                        <Grid>
                          <Grid container>
                            <Grid item xs={6}>
                              <ProjectLabel>Budget</ProjectLabel>
                              <ProjectProcureTitle>
                                {(project?.budget && formatCurrency(project?.budget, true)) || "N/A"}
                              </ProjectProcureTitle>
                            </Grid>
                            <Grid item xs={6}>
                              <ProjectLabel>Procurement ID</ProjectLabel>
                              <ProjectProcureTitle>{project?.code || "N/A"}</ProjectProcureTitle>
                            </Grid>
                          </Grid>
                          <Box my="20px">
                            <ProjectDivider />
                          </Box>
                          <Grid container>
                            <Grid item xs={6}>
                              <ProjectLabel>Procurement Category</ProjectLabel>
                              <ProjectProcureTitle>{project?.procurementCategory || "N/A"}</ProjectProcureTitle>
                            </Grid>
                            <Grid item xs={6}>
                              <ProjectLabel>Procurement Method</ProjectLabel>
                              <ProjectProcureTitle>{project?.procurementMethod || "N/A"}</ProjectProcureTitle>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Box>
                  <Box pt="20px" pl="23px" display={isOpen ? "none" : "block"}>
                    <ProjectViewMore onClick={() => toggleModal((prev) => !prev)}>View More</ProjectViewMore>
                  </Box>
                </BorderFrame>
              </Box>

              <RenderComponent condition={project?.percentageCompleted !== 100}>
                <ProjectProgressBar
                  percentageCompleted={project?.percentageCompleted}
                  totalTask={project?.totalMilestone}
                  CompletedTask={project?.totalCompletedMilestone}
                  pendingTask={project?.totalPendingMilestone}
                />
              </RenderComponent>
            </Box>
            {/* Display this when to show the list of document exchange */}
            <Box display={isOpen ? "block" : "none"}>
              <Box pt="20px">
                <ProjectViewMore onClick={() => toggleModal((prev) => !prev)}>View Less</ProjectViewMore>
              </Box>
              <Box mt="40px" mb="15px">
                <ProjectProcureTitle>Documents</ProjectProcureTitle>
              </Box>
              <ProjectProcureSubTitle>List of all documents exchanged...</ProjectProcureSubTitle>
              <Box mt="20px">
                {projectDocListLoading && <Loader />}
                {!projectDocListLoading && <DocumentList documents={projectDocumentList} />}
              </Box>
            </Box>

            <Box width="100%" mt="23px">
              <MediaDocumentSection
                isLoading={projectCompletionIsLoading}
                title="Project Completion Documents"
                MediaFilesDoc={projectCompletionProof}
              />
            </Box>
          </Box>
        </ProjectMonitorCardContainer>

        <RenderComponent condition={project?.percentageCompleted !== 100}>
          <Box mt="20px">
            {projectMilestoneLoading && <Loader />}
            {!projectMilestoneLoading && projectMilestones?.length < 1 && (
              <ProjectMonitorCardContainer>
                <NoMilestoneContainer>
                  <ListAltIcon fontSize="60px" />
                  <ProjectProcureTitle>Project Miles</ProjectProcureTitle>
                  <ProjectProcureTitleNoStyle styled={{ width: "344px", fontWeight: "normal" }}>
                    You currently have no project milestones or task, use the button below to create one.
                  </ProjectProcureTitleNoStyle>
                </NoMilestoneContainer>
              </ProjectMonitorCardContainer>
            )}
            {!projectMilestoneLoading && projectMilestones?.length > 0 && <MilestoneSection />}
          </Box>
        </RenderComponent>

        {/* Display this section when to upload project completion proof*/}
        <RenderComponent condition={projectCompletionProof?.length < 1}>
          <Box mt="20px">
            <ProjectMonitorCardContainer>
              <Box mb="20px">
                <Dropdown label="Status" values={[{ label: "Completed", value: 1 }]} style={{ margin: 0, width: "300px" }} />
              </Box>
              <BorderFrame>
                <Box p="20px">
                  <Box mb="20px">
                    <ProjectProcureTitle> Upload Project Completion Proof</ProjectProcureTitle>
                  </Box>
                  <DocumentUploadField removeFile={handleRemoveFile} addFiles={handleAddFile} files={uploadDocs} />
                </Box>
              </BorderFrame>
              <Box mt="20px" display="flex" justifyContent="flex-end" width="100%">
                <BackButton disabled={uploadProjectResp.isLoading}>Cancel</BackButton>
                <SaveButton disabled={uploadProjectResp.isLoading} onClick={handleSubmit}>
                  Submit
                </SaveButton>
              </Box>
            </ProjectMonitorCardContainer>
          </Box>
        </RenderComponent>
      </Box>
    </ContentWrapper>
  );
};

export default ProjectMonitoring;
