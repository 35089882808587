import styled from "styled-components";
import { Box, Typography } from "@material-ui/core";
import { Editor } from "react-draft-wysiwyg";
import Avatar from "@material-ui/core/Avatar";
import SendIcon from "@material-ui/icons/Send";
import AttachFileOutlinedIcon from "@material-ui/icons/AttachFileOutlined";
import EmojiEmotionsOutlinedIcon from "@material-ui/icons/EmojiEmotionsOutlined";

import { BreadcrumbLabel } from "../../pages/Admin/CreateProcurement/style";
import { PageTab, theme } from "../../util/theme";
import { PageTitle } from "../../pages/Common/style";
import InputField from "../InputField";
import LoadingButton from "../LoadingButton/LoadingButton";

export const GeneralContentWrapper = styled.div`
  padding: 15px 0;
  padding-right: 25px;
`;

export const GeneralPlanLink = styled(BreadcrumbLabel)`
  color: ${theme.color.primary} !important;
`;

export const GeneralTitlePage = styled(PageTitle)`
  margin: 0 !important;
  margin-bottom: 5px !important;
`;

export const PlanCardContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #C9D4DB !important;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 #C9D4DB !important;
  margin-right: 25px !important;
`;

export const CheckEntriesContainer = styled.div`
  box-sizing: border-box;
  height: 399px;
  min-width: 269px;
  border: 1px solid #C9D4DB;
  border-radius: 3px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 4px 0 #C9D4DB;
  padding: 20px;
`;

export const GeneralPlanTab = styled(PageTab)`
  color: #5F7483;
  font-size: 16px !important;
  font-weight: 600 !important;
  letter-spacing: 0;
  line-height: 24px !important;
  text-transform: none !important;
`;

export const GeneralTabContainer = styled.div`
  box-sizing: border-box;
  border: 1px solid #C9D4DB !important;
  border-radius: 3px 3px 0 0 !important;
  background-color: #E4E9EC;
`;

export const GeneralTitleLabel = styled(Typography)`
  color: #1E2B37;
  font-size: 17px !important;
  font-weight: 600 !important;
  letter-spacing: 0;
  line-height: 24px !important;
  margin: 20px 0 15px !important;
  padding-left: 20.5px !important;
`;

export const GeneralCardContentWrapper = styled.div`
  box-sizing: border-box;
  border: 1px solid #C9D4DB !important;
  background-color: #FCFCFD !important;
  padding: 19.5px 20.5px 13.5px !important;

  .general-editor-style {
    height: 274px !important;
    background-color: #ffffff;
  }

  .general-editor-container-style {
    width: 100% !important;
  }
`;

export const GeneralEditor = styled(Editor)``;

export const WordCountText = styled(Typography)`
  color: #5F7483;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: right;
  margin: 5px 0 10px !important;
`;

export const GeneralInputField = styled(InputField)`
  background-color: #ffffff;
`;

export const GeneralButtonContainer = styled(Box)`
  padding: 35px 20.5px 40px !important;
`;

export const ReviewContainer = styled(GeneralCardContentWrapper)`
  padding-top: 69px !important;
`;

export const AdminAvatar = styled(Avatar)`
  margin-right: 15px !important;
  background-color: ${theme.color.primary} !important;
`;

export const AdminText = styled(Typography)`
  font-size: 14px !important;
  font-weight: bold !important;
  letter-spacing: 0;
  line-height: 14px !important;
`;
export const CommentText = styled(Typography)`
  font-size: 14px !important;
  letter-spacing: 0;
  line-height: 14px !important;
  margin-top: 5px !important;
`;

export const AdminTextTime = styled(AdminText)`
  font-size: 10px !important;
  color: #919191;
  font-weight: normal !important;
`;

export const ReviewTitleLabel = styled(Typography)`
  color: #424F5C;
  font-size: 16px !important;
  font-weight: bold !important;
  letter-spacing: 0;
  line-height: 22px !important;
  margin: 10px 0 !important;
`;

export const CommentBox = styled(Box)`
  box-sizing: border-box;
  border: 1px solid #C9D4DB;
  border-radius: 4px;
  background-color: #FFFFFF;
  margin-top: 29px !important;
  padding-top: 5px !important;

  .MuiFilledInput-root {
    border: none !important;
  }
`;

export const CommentIconContainer = styled(Box)`
  height: 50px;
  background-color: #F8F8F9;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const CommentSendIcon = styled(SendIcon)`
  background-color: ${theme.color.primary} !important;
  color: #fff;
  padding: 6px !important;
  border-radius: 4px !important;
  margin-right: 13px !important;
`;

export const CommentAttachIcon = styled(AttachFileOutlinedIcon)`
  color: #C9D4DB;
  padding: 10px !important;
  border-radius: 4px !important;
  margin-right: 13px !important;
  margin-right: 10.5px;
`;

export const CommentEmojiIcon = styled(EmojiEmotionsOutlinedIcon)`
  color: #C9D4DB;
  padding: 10px !important;
  border-radius: 4px !important;
  margin-right: 13px !important;
  margin-right: 10.5px;
`;

export const SelectRow = styled.div`
    padding: 8px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 16px;
`;

export const NextButton = styled(LoadingButton)`
  border: 1px solid #ebebeb;
  background-color: ${theme.color.primary};
  color: #fff !important;
  margin-left: 20px !important;
`;
