import React from "react";
import ForgotPassword from "../../../components/ForgotPassword";

const VendorForgotPassword = () => (
    <ForgotPassword
      loginLink={"/login"}
      passwordSentLink={"/vendor/password-sent"}
    />
);

export default VendorForgotPassword;
