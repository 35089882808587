import { add } from "date-fns";

const generateFakeDocuments = (length = 4) =>
  Array.from({ length }, (_, index) => ({
    id: index,
    name: `document-${index}.pdf`,
    size: 3000,
    url: "https://eb.things.io",
  }));

export const planDetails = {
  name: "Delta Irrigated Agriculture Improvement",
  ministry: "MOA",
  procurementMethod: "RFB - Request For Bid",
};

export const contracts = [
  {
    id: 18145,
    ref: "Min-Agric-01",
    signed: false,
    approved: false,
    planName: "Construction of New Delta Seaport",
    signedDate: null,
    vendor: {
      name: "Ezubike Assurance Plc",
      id: 8764923,
    },
  },
  {
    id: 18146,
    ref: "Min-Agric-01",
    signed: false,
    approved: true,
    planName: "Construction of New Delta Seaport",
    signedDate: null,
    vendor: {
      name: "Ezubike Assurance Plc",
      id: 8764923,
    },
  },
  {
    id: 18147,
    ref: "Min-Agric-01",
    signed: true,
    approved: true,
    planName: "Construction of New Delta Seaport",
    description: "Construction of North Main Canal and on-canal Structure Section 093",
    scope: "Construction of North Main Canal and on-canal Structure Section 093",
    signedDate: new Date(),
    vendor: {
      name: "Ezubike Assurance Plc",
      id: 8764923,
      score: 82,
      signedPrice: 200000,
    },
    startDate: new Date(),
    endDate: add(new Date(), { months: 6 }),
    minimumScore: 0,
    noticeVersionNo: 1,
    documents: {
      mandatory: generateFakeDocuments(),
      supporting: generateFakeDocuments(),
    },
  },
];

export const certificateOfNoObjectionRow = [
  {
    id: "EPC001",
    name: "Obichuks and Sons Ventures",
    approval: "Approved",
    status: "active",
    registration_category: "Class B",
    class: "A",
    state: "Delta",
    country: "Nigeria",
    url: "www.obiandsons.verylongemail.com",
    email: "uzor_nnamdi@gmail.com",
    amount: 2349999000,
    tax: "yes",
    dsir: "yes",
    memo: "yes",
    profile: "yes",
    pastJobs: "yes",
    remark: "recomended",
  },
  {
    id: "EPC001",
    name: "Obichuks and Sons Ventures",
    approval: "Approved",
    status: "active",
    registration_category: "Class B",
    class: "A",
    state: "Delta",
    country: "Nigeria",
    url: "www.obiandsons.verylongemail.com",
    email: "uzor_nnamdi@gmail.com",
    amount: 2349999000,
    tax: "yes",
    dsir: "yes",
    memo: "yes",
    profile: "yes",
    pastJobs: "yes",
    remark: "recomended",
  },
  {
    id: "EPC001",
    name: "Obichuks and Sons Ventures",
    approval: "Approved",
    status: "active",
    registration_category: "Class B",
    class: "A",
    state: "Delta",
    country: "Nigeria",
    url: "www.obiandsons.verylongemail.com",
    email: "uzor_nnamdi@gmail.com",
    amount: 2349999000,
    tax: "yes",
    dsir: "yes",
    memo: "yes",
    profile: "yes",
    pastJobs: "yes",
    remark: "recomended",
  },
  {
    id: "EPC001",
    name: "Obichuks and Sons Ventures",
    approval: "Approved",
    status: "active",
    registration_category: "Class B",
    class: "A",
    state: "Delta",
    country: "Nigeria",
    url: "www.obiandsons.verylongemail.com",
    email: "uzor_nnamdi@gmail.com",
    amount: 2349999000,
    tax: "yes",
    dsir: "yes",
    memo: "yes",
    profile: "yes",
    pastJobs: "yes",
    remark: "recomended",
  },
  {
    id: "EPC001",
    name: "Obichuks and Sons Ventures",
    approval: "Approved",
    status: "active",
    registration_category: "Class B",
    class: "A",
    state: "Delta",
    country: "Nigeria",
    url: "www.obiandsons.verylongemail.com",
    email: "uzor_nnamdi@gmail.com",
    amount: 2349999000,
    tax: "yes",
    dsir: "yes",
    memo: "yes",
    profile: "yes",
    pastJobs: "yes",
    remark: "recomended",
  },
  {
    id: "EPC001",
    name: "Obichuks and Sons Ventures",
    approval: "Approved",
    status: "active",
    registration_category: "Class B",
    class: "A",
    state: "Delta",
    country: "Nigeria",
    url: "www.obiandsons.verylongemail.com",
    email: "uzor_nnamdi@gmail.com",
    amount: 2349999000,
    tax: "yes",
    dsir: "yes",
    memo: "yes",
    profile: "yes",
    pastJobs: "yes",
    remark: "recomended",
  },
  {
    id: "EPC001",
    name: "Obichuks and Sons Ventures",
    approval: "Approved",
    status: "active",
    registration_category: "Class B",
    class: "A",
    state: "Delta",
    country: "Nigeria",
    url: "www.obiandsons.verylongemail.com",
    email: "uzor_nnamdi@gmail.com",
    amount: 2349999000,
    tax: "yes",
    dsir: "yes",
    memo: "yes",
    profile: "yes",
    pastJobs: "yes",
    remark: "recomended",
  },
];
