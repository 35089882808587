import React, { useState } from "react";
import { Box, Popover, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { ChevronRightIcon, FileDetails, FileName, Icon, SaveIcon, Wrapper } from "./style";
import fileIcon from "../../assets/file.svg";
import { toHumanReadableFileSize } from "../../util/toHumanReadableFileSize";

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: "4px 8px",
    boxShadow: "0 1px 1px rgba(0,0,0,0.4)",
  },
}));

function FileDownload({ name, size, url, lighttheme = false, lightTheme = false }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  let nameArray = name.split(".");
  let textIsLong = nameArray[0].length > 20;
  nameArray[0] = textIsLong ? `${nameArray[0].substring(0, 20)}....` : nameArray[0];

  return (
    <Wrapper
      style={{ textDecoration: "none" }}
      lightTheme={lighttheme || lightTheme}
      component="a"
      href={url}
      download={name}
      target="_blank"
      rel="noreferrer noopener"
    >
      <Box display="flex" alignItems="center" width="100%">
        <Icon src={fileIcon} />
        <Box display="flex" flexDirection="column" flex="1" marginRight="15px">
          {textIsLong ? (
            <FileName
              aria-owns={open ? "mouse-over-popover" : undefined}
              aria-haspopup="true"
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
            >
              {nameArray.join()}
            </FileName>
          ) : (
            <FileName>{nameArray.join()}</FileName>
          )}

          <FileDetails>{toHumanReadableFileSize(size, true)}</FileDetails>
        </Box>
        {lighttheme || lightTheme ? <SaveIcon /> : <ChevronRightIcon />}
      </Box>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography style={{ fontSize: 12 }}>{name}</Typography>
      </Popover>
    </Wrapper>
  );
}

export default FileDownload;
