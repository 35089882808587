import React from 'react'
import { Banner, BannerText } from "./style";

function ReviewModeBanner() {
    return (
        <Banner>
            <BannerText>Review Mode</BannerText>
        </Banner>
    )
}

export default ReviewModeBanner
