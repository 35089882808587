import { differenceInCalendarMonths } from "date-fns";

export const transformContractStatus = (key) => {
  // const isUploaded = key?.isUploaded;
  // const signatoryStatus = key?.signatureStatus;
  const contractStatus = key?.status;

  if (contractStatus === "PENDING") {
    return "ASSIGNED";
  }
  if (contractStatus === "UNSIGNED") {
    return "UNSIGNED";
  }
  if (contractStatus === "SIGNED") {
    return "SIGNED";
  }
  if (contractStatus === "ACCEPTED") {
    return "ACCEPTED";
  }
  if (contractStatus === "REJECTED") {
    return "REJECTED";
  } else {
    return contractStatus;
  }
};

export const designatedPath = (key, history, contractId) => {
  const currentStatus = transformContractStatus(key);
  // acceptance page when contractStatus = assigned
  // rejection page when contractStatus = rejected and contractStatus = expired
  // contract signing when isuploaded = true and contractStatus = Accepted
  // contract status page when isuploaded = true and contractStatus = signed
  if (currentStatus === "ASSIGNED") {
    return history.push(`/vendor/contracts/${contractId}/letter-of-acceptance`);
  }
  if (currentStatus === "REJECTED" || currentStatus === "EXPIRED" || currentStatus === "CLOSED") {
    return history.push(`/vendor/contracts/${contractId}/rejected`);
  }
  if (currentStatus === "UNSIGNED") {
    return history.push(`/vendor/contracts/${contractId}/contract-signing`);
  }
  if (currentStatus === "SIGNED") {
    return history.push(`/vendor/contracts/${contractId}/signed`);
  }
  if (currentStatus === "ACCEPTED") {
    return history.push(`/vendor/contracts/${contractId}/accepted`);
  }
  return null;
};

export const convertDuration = (endDate, startDate) => {
  const month = differenceInCalendarMonths(new Date(endDate), new Date(startDate));
  if (month > 1) {
    return month ? `${month} Months` : "N/A";
  } else {
    return month ? `${month} Month` : "N/A";
  }
};

export const transformFileDownload = (doc) => {
  return doc?.map((eachDoc) => ({
    name: eachDoc?.name,
    size: eachDoc?.file?.bytes,
    url: eachDoc?.file?.secure_url,
  }));
};
