import { FormControlLabel, Typography } from "@material-ui/core";
import styled from "styled-components";
import { theme } from "../../../../../../util/theme";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";

export const EvaluationCard = styled.div`
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #C9D4DB;
  box-shadow: 0px 2px 4px #C9D4DB;
  border-radius: 3px;
  padding-top: 20px !important;
  padding-bottom: 35px !important;

  .MuiRadio-colorPrimary {
    color: ${theme.color.primary};
  }

  .MuiRadio-colorPrimary.Mui-checked {
    color: ${theme.color.primary};
  }
`;

export const EvalFormControlContainer = styled(FormControlLabel)`
  color: ${({ active }) => (active === "true" ? "#03ADEB" : "#000000")}
`;

export const EvaTitleText = styled(Typography)`
  font-size: 16px !important;
  line-height: 24px !important;
  color: #1E2B37;
  ${props => !props.noPadding ? 'padding-left: 20px;' : 'padding-left: 0px;'} 
`;

export const EvaSubTitleText = styled(Typography)`
  font-size: 14px !important;
  line-height: 20px  !important;
  color: #637381;
  ${props => !props.noPadding ? 'padding-left: 20px;' : 'padding-left: 0px;'} 
`;

export const EvaStyledContainer = styled.div`
  background: #FCFCFD;
  border: 1px solid #C9D4DB;
  padding: 20px !important;

  .MuiFilledInput-input {
    padding: 27px 12px 10px !important;
  }
`;

export const FileGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin: 20px 0;
`

export const WarningModalTitle = styled.h3`
  font-weight: bold;
  font-size: 24px; 
  line-height: 33px;
  color: #3F5673;
  margin-bottom: 10px;
`

export const WarningModalDescription = styled.p`
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #3F5673;
  margin-bottom: 24px;
`

export const WarningModalExistingVendor = styled.div`
  background: rgba(3, 173, 235, 0.12);
  border-radius: 3px;
  padding: 20px;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 30px;
`

export const WarningModalLink = styled(Link)`
  font-size: 14px;
  text-decoration-line: underline;
  color: #03ADEB;
`

export const WarningModalButton = styled(Button)`
  &.MuiButtonBase-root{
    background-color: #2D9CDB;
    color: white;
    padding: 10px 30px;
  }
`

