import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { GeneralTitlePage } from "../SpecificProcurementNotice/style";
import Breadcrumb from "../../../../Common/PageElements/Breadcrumb";
import { Box } from "@material-ui/core";
import { ResponseStatsLabel, ResponseStatsValue, ViewAllLink, ViewAllLinks } from "./style";
import VendorsTable from "./VendorsTable";
import TopStatisticsBar from "../../../../../components/TopStatisticsBar";
import { useQuery } from "react-query";
import Loader from "../../../../../components/Common/Loader";
import receiptOfResponseApi from "../../../../../redux/api/receiptOfResponseApi";

export const convertVendors = (vendors = [], totalCount) => {
  return vendors.map(vendor => ({
    name: vendor.companyName,
    email: vendor.email,
    documents: {
      count: vendor.documentsUploaded,
      total: totalCount
    },
    REGID: vendor.registrationPlan.grade,
    id: vendor.userId
  }))
}

const ReceiptOfResponse = () => {
  const { procurementId = "", activityId } = useParams();

  const baseUrl = `/admin/procurement/activity/receiptofresponses/${activityId}/${procurementId}`;

  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10
  });

  const vendors = useQuery({
    queryKey: [
      "interestedVendors",
      {
        procurementId,
        Status: 7,
        PageNumber: pagination.pageNumber,
        PageSize: pagination.pageSize
      }
    ],
    queryFn: receiptOfResponseApi.getInterestedVendors
  });

  const breadcrumbLinks = [
    {
      url: "/admin/procurement/plans",
      title: "Home",
      forwardSlash: true
    },
    {
      url: `/admin/procurement/plan-details/${procurementId}`,
      title: "Activities List"
    },
    {
      url: baseUrl,
      title: "Receipt of Response"
    }
  ];

  const stats = vendors.data?.interestedVendorsData?.totalResponse || {}

  const renderLeftContent = () => {
    const total = stats.shortlisted + stats.pending + stats.rejected

    return (
      <>
        <ResponseStatsLabel color='#5F7483'>
          Total Response
        </ResponseStatsLabel>
        <ResponseStatsValue>
          {isNaN(total) ? 0 : total}
        </ResponseStatsValue>
      </>
    )
  };

  const renderCenterContent = () => {
    return (
      <>
        <Box mr={6}>
          <ResponseStatsLabel color='#FF8D26'>
            Pending Review
          </ResponseStatsLabel>
          <ResponseStatsValue>
            {stats.pending || 0}
          </ResponseStatsValue>
        </Box>
        <Box mr={6}>
          <ResponseStatsLabel color='#219653'>
            Approved
          </ResponseStatsLabel>
          <ResponseStatsValue>
            {stats.shortlisted || 0}
          </ResponseStatsValue>
        </Box>
        <Box mr='auto'>
          <ResponseStatsLabel color='#EB5757'>
            Rejected
          </ResponseStatsLabel>
          <ResponseStatsValue>
            {stats.rejected || 0}
          </ResponseStatsValue>
        </Box>
        <ViewAllLinks>
          <ViewAllLink to={`${baseUrl}/vendors/accepted`}>
            View all shortlisted
          </ViewAllLink>
          <ViewAllLink to={`${baseUrl}/vendors/rejected`}>
            View all rejected
          </ViewAllLink>
        </ViewAllLinks>
      </>
    )
  };

  const { interestedVendorsData, pagination: apiPagination } = vendors.data || {}

  return (
    <Box pb={10}>
      <Box mb={4} mt={2}>
        <GeneralTitlePage>Receipt of Response</GeneralTitlePage>
        <Breadcrumb values={breadcrumbLinks}/>
      </Box>
      {
        vendors.isLoading ? (
          <Loader feedback='Fetching details'/>
        ) : (
          <>
            <TopStatisticsBar
              leftContent={renderLeftContent()}
              centerContent={renderCenterContent()}
              status={stats.pending > 0 ? 'pending' : 'approved'}
            />
            <VendorsTable
              pagination={pagination}
              setPagination={setPagination}
              vendors={convertVendors(interestedVendorsData?.interestedVendors, interestedVendorsData?.totalRequiredDocuments)}
              totalCount={apiPagination.totalEntries}
            />
          </>
        )
      }
    </Box>
  );
};

export default ReceiptOfResponse;
