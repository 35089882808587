import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import { lightGreen, deepOrange } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: lightGreen[500],
  },
}));

export default function FallBackAvatar({ imageUrl, name }) {
  const classes = useStyles();

  return (
    <Avatar alt={name} src={imageUrl} className={classes.orange}>
      {name
        ? name
          .split(" ")
          .map((value, index, array) => (index === 0 || index + 1 === array.length ? value[0] : null))
          .join("")
        : ""}
    </Avatar>
  );
}
