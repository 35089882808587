import styled from "styled-components";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core";

export const CommentInputWrapper = styled.div`
  border: 1px solid #C9D4DB;
  border-radius: 4px;
  background-color: #FFFFFF;
  position: relative;

  .text-input {
    min-height: 75px;
    width: 100%;
    padding: 0 5px;
    box-sizing: border-box;
    font-size: 14px;
  }
  
  .grid {
    background-color: #F8F8F9;
    height: 50px;
    padding: 0 14px 0 8px
  }
`;

export const ActionButtons = styled.div`
  display: flex;
  
  button {
    min-width: unset;
  }
  
  svg{
    width: 20px;
    height: 20px;
  }
`;

export const SendButton = withStyles(() => ({
  root: {
    color: "white",
    backgroundColor: "#00B0F1",
    minWidth: "unset",
    padding: "10px 15px",

    "&:hover": {
      backgroundColor: "#0098ce",
    },

    "&:disabled": {
      filter: "brightness(50%)",
    },
  },
}))(Button);

export const SendButtonIcon = styled.img`
  width: 20px;
  height: 20px;
`;
