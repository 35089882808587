import styled from "styled-components";
import withStyles from "@material-ui/core/styles/withStyles";
import ButtonBase from "@material-ui/core/ButtonBase";
import { ReactComponent as ChevronRight } from "../../assets/chevron_right.svg";
import { ReactComponent as Save } from "../../assets/save.svg";

export const Wrapper = withStyles(() => ({
  root: {
    minHeight: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 10px",
    border: "1px solid #DFE1E5",
    borderRadius: 3,
    backgroundColor: (props) => (props.lightTheme ? "#fff" : "#F5F6F8"),
    overflow: "hidden",
    width: "100%",
    boxSizing: "border-box",
  },
}))(ButtonBase);

export const Icon = styled.img`
  width: 14px;
  margin-right: 10px;
`;

export const FileName = styled.div`
  color: #151c2f;
  font-size: 12px;
  font-weight: 500;
`;

export const FileDetails = styled.div`
  color: rgba(107, 119, 140, 0.59);
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0;
`;

export const ChevronRightIcon = styled(ChevronRight)`
  height: 12px;
  color: #919eab;
`;

export const SaveIcon = styled(Save)`
  height: 16px;
  color: #919eab;
`;
