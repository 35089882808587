import React from "react";
import NotificationsPageComponent from "../../../components/NotificationsPageComponent/NotificationsPageComponent";
import withAdminDashboard from "../HOC/withAdminDashboard";

const AdminNotification = () => {
  return (
    <NotificationsPageComponent type='admin'/>
  );
};

export default withAdminDashboard(AdminNotification);
