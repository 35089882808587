import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ProjectMonitoring from '..';
import withAdminDashboard from '../../../../HOC/withAdminDashboard';
import ProjectInvoice from '../ProjectInvoice';
import ProjectMilestone from '../ProjectMilestone';
import ProjectMilestoneTask from '../ProjectMilestoneTask';

const baseUrl = "/admin/procurement/activity/:activityName/:activityId/:procurementId";

const ContractSupervisionWrapper = () => {
  return <Switch>
    <Route path={baseUrl} component={ProjectMonitoring} exact />
    <Route path={`${baseUrl}/milestone/:projectId/:milestoneId`} component={ProjectMilestone} exact />
    <Route path={`${baseUrl}/milestone/:projectId/:milestoneId/invoice`} component={ProjectInvoice} exact />
    <Route path={`${baseUrl}/milestone/:projectId/:milestoneId/:taskId`} component={ProjectMilestoneTask} exact />
  </Switch>;
}


export default withAdminDashboard(ContractSupervisionWrapper);