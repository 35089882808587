import React, { useState } from "react";
import AdminSearchFilter from "../../../../components/AdminSearchFilter";
import { SearchButton } from "../../../../components/Forms/Common/style";
import InputField from "../../../../components/InputField";
import RolePageDrawer from "../../../../components/RolePageDrawer";
import Breadcrumb from "../../../Common/PageElements/Breadcrumb";
import { AddButton, CirclePlusIconWrapper, PageTitle, TitleContainer } from "../../../Common/style";
import withAdminDashboard from "../../HOC/withAdminDashboard";
import { ContentWrapper } from "../../Procurement/style";
import CheckersCardSection from "./CheckCardSection";
import { cardInfoList } from "./mock";

const Checkers = () => {
  const [openModal, setOpenModal] = useState(false);

  const toggleDrawer = () => {
    setOpenModal(!openModal);
  };

  const breadCrumbValues = [
    { title: "Home", url: "#", forwardSlash: true },
    { title: "Settings", url: "admin/settings" },
  ];

  return (
    <ContentWrapper>
      <TitleContainer>
        <div>
          <PageTitle>Checker</PageTitle>
          <Breadcrumb values={breadCrumbValues} />
        </div>
        <AddButton onClick={() => toggleDrawer()}>
          <CirclePlusIconWrapper /> Add New
        </AddButton>
      </TitleContainer>
      <AdminSearchFilter normalSearch>
        <div
          style={{
            display: "flex",
          }}
        >
          <InputField type="text" label="Search" defaultValue="Completion of settlement scheme" required />
          <SearchButton>Search</SearchButton>
        </div>
      </AdminSearchFilter>
      <br />

      <CheckersCardSection checkersCardInfo={cardInfoList} />

      <RolePageDrawer toggleDrawer={toggleDrawer} openModal={openModal} isChecker={true} />
    </ContentWrapper>
  );
};

export default withAdminDashboard(Checkers);
