import React from "react";

import CustomStage from "../../../../../components/CustomStage";

const CustomStageView = () => {
    //@todo, the pageTitle should be changed and gotten from the url
    return <CustomStage pageTitle="Custom Stage" />
}

export default React.memo(CustomStageView);
