import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./reducer/userReducer";
import vendorReducer from "./reducer/vendorReducer";
import adminReducer from "./reducer/adminReducer";
import commentReducer from "./reducer/commentReducer";

export default configureStore({
  reducer: {
    user: userReducer,
    vendor: vendorReducer,
    admin: adminReducer,
    comment: commentReducer,
  },
});
