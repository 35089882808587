import React from "react";
import Drawer from "@material-ui/core/Drawer";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import FileDownload from "../../../../../components/FileDownload";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Title } from "../../style";
import { CloseButton } from "./style";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: 400,
    padding: theme.spacing(2, 3),
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
  },
}));

export default function DocumentModal({ selectedCategory, onClose }) {
  const { paper } = useStyles();
  const open = !!selectedCategory;

  const { documents = [], title = "" } = selectedCategory ?? {};

  return (
    <Drawer anchor="right" open={open} onClose={onClose} classes={{ paper }}>
      <Box mb={2}>
        <Title>{title}</Title>
      </Box>
      <Box mb={2} flex={1} overflow="hidden auto">
        <Grid container spacing={2}>
          {documents.map((document) => (
            <Grid item xs={12} key={document?.name}>
              <FileDownload name={document?.name} size={document?.file?.bytes} url={document?.file?.secure_url} lightTheme />
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <CloseButton onClick={onClose}>Close</CloseButton>
      </Box>
    </Drawer>
  );
}
