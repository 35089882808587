import React, { useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useMutation, useQueryCache } from "react-query";
import jwt from "jwt-decode";
import { updateUser } from "../../redux/reducer/userReducer";
import { logoutFn } from "../../util/logout";
import vendorsAPI from "../../redux/api/vendorsAPI"

const withVendorAuthentication = (WrappedComponent) => () => {
  const token = localStorage.getItem("token");
  const cache = useQueryCache();
  const isAdmin = localStorage.getItem("isAdmin") === "true";
  const userName = Boolean(localStorage.getItem("userName"));
  const dispatch = useDispatch();
  const history = useHistory();
  const timeToIdle = 1200000; // 20 minutes in milliseconds
  const timeToRetrieveNewTokens = 600000; //  10 minutes in milliseconds

  const [renewVendorAccessTokens] = useMutation(vendorsAPI.checkAccessToken);

  useEffect(() => {
    // Measures time user has been inactive on the platform and logs them outs when timeToIdle elapses
    let timer;

    window.addEventListener("load", resetTimer, true);
    const events = ["mousedown", "mousemove", "keydown", "scroll", "touchstart"];
    events.forEach(function (name) {
      document.addEventListener(name, resetTimer, true);
    });

    function handleLogout() {
      logoutFn(dispatch);
      cache.clear();
      history.push("/login");
    }

    function resetTimer() {
      clearTimeout(timer);
      timer = setTimeout(handleLogout, timeToIdle);
    }
    return () => clearTimeout(timer);
    // eslint-disable-next-line
  }, []);

  useEffect(()=>{ 
     // This performs a background check every 10 minutes to retrieve a new access and refresh token with an extended expiry date from the backend, in order to prolong the user's access to the application endpoints.   
    let timer = setInterval(async() => {
      await renewVendorAccessTokens();    
    }, timeToRetrieveNewTokens);

    return ()=> clearInterval(timer)
   // eslint-disable-next-line 
  }, [])


  if (!token || token === "undefined") {
    return <Redirect to={"/login"} />;
  }

  if (jwt(token).exp < Date.now() / 1000) {
    localStorage.removeItem("token");
    return <Redirect to={"/login"} />;
  }

  if (jwt(token).exp > Date.now() / 1000 && !userName) {
    localStorage.removeItem("token");
    return <Redirect to={"/login"} />;
  }

  if (isAdmin) {
    return <Redirect to={"/admin/dashboard/overview"} />;
  }

  dispatch(updateUser(jwt(token)));

  return <WrappedComponent />;
};

export default withVendorAuthentication;
