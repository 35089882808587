import React from "react";
import { CardContainer, CardTitle, CardPdfIcon, CardDate } from "./style";

const GridCard = ({ title, date, style }) => (
  <CardContainer style={style}>
    <CardPdfIcon iconcolor="#274472" />
    <CardTitle>{title}</CardTitle>
    {!!date && <CardDate>{date}</CardDate>}
  </CardContainer>
);

export default GridCard;
