export const team = [
    {
        id: 1,
        name: "Okeze Joseph Duke",
        position: "Director General"
    },
    {
        id: 2,
        name: "Ogbogu William Nwabueze",
        position: "Director, Legal Department"
    },
    {
        id: 3,
        name: "Erikume Gaius",
        position: "Director, Administration and Finance Department"
    },
    {
        id: 4,
        name: "Okekeri Obas",
        position: "Director, Department of Technical Assessment and Evaluation Department"
    },
    {
        id: 5,
        name: "Omene O. E",
        position: "Director , Department of Price Intelligence, Research and Statistics"
    }
]