/* eslint-disable no-unused-expressions */
import withAdminDashboard from "../../../HOC/withAdminDashboard";
import withActivityLayout from "../../../../../components/Common/HOC/withActivityLayout";

const BidSubmission = () => (
  withActivityLayout(() => null)({
    dataSheet: true,
    title: "Bid Submission/Opening/Minutes",
    extraCrumbs: [
      {
        url: "#",
        title: "Bid Submission/Opening/Minutes",
      },
    ]
  })
);

export default withAdminDashboard(BidSubmission);
