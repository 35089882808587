import styled from "styled-components";
import { Typography } from "@material-ui/core";
import DescriptionIcon from "@material-ui/icons/Description";
import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt";

export const BidInfoTitle = styled(Typography)`
  color: #1e2b37;
  font-size: 16px !important;
  font-weight: 600 !important;
  letter-spacing: 0;
  line-height: 24px !important;
  margin-bottom: 20px !important;
`;

export const ProcurementInfoTitle = styled(Typography)`
  color: #3f5673 !important;
  font-size: 14px !important;
  letter-spacing: 0;
  line-height: 16px !important;
  font-weight: 700 !important;
`;

export const ProcurementInfoValue = styled(Typography)`
  color: #637381;
  font-size: 14px !important;
  font-weight: 600 !important;
  letter-spacing: 0;
  line-height: 20px !important;
`;

export const DocumentUploadTitle = styled(Typography)`
  color: ##31373d !important;
  font-size: 10px !important;
  font-weight: 600 !important;
  letter-spacing: 0;
  line-height: 14px !important;
`;

export const DocumentUploadInfo = styled(Typography)`
  color: rgba(107, 119, 140, 0.59);
  font-size: 6px !important;
  font-weight: 500 !important;
  letter-spacing: 0 !important;
  line-height: 10px !important;
`;

export const DocumentUploadContainer = styled.div`
  min-height: 40px;
  border-radius: 3px 0 0 3px !important;
  background-color: #ffffff;
  padding: 12px 5px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e4e4e4;
`;

export const BiddingDocumentContainer = styled.div`
  box-sizing: border-box;
  border: 1px solid #cbd4da;
  border-radius: 3px;
  background-color: #f4fcff;
  padding: 20px;
`;

export const ProcurementInformationContainer = styled.div`
  box-sizing: border-box;
  border: 1px solid #cbd4da;
  border-radius: 3px;
  background-color: #ffffff;
  padding: 20px;
  margin-bottom: 20px;
`;

export const InfoContainer = styled.div`
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 #cbd4da;
  padding: 20px;
  margin-bottom: 30px;
`;

export const EnvelopeText = styled(Typography)`
  height: 22px;
  width: 98px;
  border-radius: 11px;
  background-color: #d6efd2;
  color: #108043;
  font-size: 12px !important;
  font-weight: 600 !important;
  letter-spacing: 0;
  line-height: 16px !important;
  padding: 2px;
  justify-content: center;
  display: flex;
  align-items: center;
  margin-left: 21px !important;
`;

export const DocFileIcon = styled(DescriptionIcon)`
  font-size: 24px !important;
  margin-right: 4px !important;
  color: #31373d !important;
`;

export const DocDownIcon = styled(SystemUpdateAltIcon)`
  font-size: 24px !important;
  margin-right: 18px !important;
`;
