import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import LogoutIcon from "@material-ui/icons/ExitToApp";

import {
  MenuItemWrapper, MenuItem, LogoutWrapper, CustomMenuItemWrapper,
} from "./style";
import { menus } from "./mock";

const Sidebar = () => {
  const { pathname } = useLocation();
  const history = useHistory();

  const CustomMenuItem = ({
    to, title, icon,
  }) => (
    <>
      <CustomMenuItemWrapper active={(pathname.includes(title)).toString()}>
        {icon}
        <MenuItem to={to} active={(pathname.includes(title)).toString()}>
          {title}
        </MenuItem>
      </CustomMenuItemWrapper>
    </>
  );

  return (
    <>
      <MenuItemWrapper>
        {menus.map((menu, i) => (
          <CustomMenuItem {...menu} key={`${menu.title}-${i}`} />
        ))}
      </MenuItemWrapper>
      <LogoutWrapper onClick={() => {
        localStorage.removeItem("token");
        localStorage.removeItem("email");
        return history.push("/login");
      }}>
        <LogoutIcon />
        <MenuItem to="#">Logout</MenuItem>
      </LogoutWrapper>
    </>
  );
};

export default Sidebar;
