import React, { useEffect, useMemo, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { PageTitle, PageWrapper } from "../../pages/Common/style";
import { Divider, LoaderWrapper, PageGrid } from "./style";
import Notification from "../Notification/Notification";
import Filter from "../Filter/Filter";
import { PageChangeButton } from "../Filter/style";
import { ReactComponent as LeftIcon } from "../../assets/left.svg";
import { ReactComponent as RightIcon } from "../../assets/right.svg";
import useNotifications from "../../hooks/useNotifications";
import { CircularProgress } from "@material-ui/core";

const defaultFilters = {
  admin: [
    {
      id: "Special_procurement_notice_admin",
      title: "Special Procurement Notice",
      checked: true
    },
    {
      id: "Bid_Invitation_admin",
      title: "Bid Invitation",
      checked: true
    },
    {
      id: "Bid_Evaluation_Report_admin",
      title: "Bid Evaluation Report",
      checked: true
    },
    {
      id: "Letter_of_Acceptance_admin",
      title: "Letter of acceptance",
      checked: true
    },
    {
      id: "Contract_Signing_admin",
      title: "Contract Signing",
      checked: true
    },
    {
      id: "Public_Award_Notice_admin",
      title: "Public Award Notice",
      checked: true
    },
    {
      id: "Bid_Submission",
      title: "Bid Submission",
      checked: true
    },
    {
      id: 10,
      title: "Others",
      checked: true
    }
  ],
  vendor: [
    {
      id: "Special_procurement_notice",
      title: "Special Procurement Notice",
      checked: true
    },
    {
      id: "Bid_Invitation",
      title: "Bid Invitation",
      checked: true
    },
    {
      id: "Bid_Evaluation_Report",
      title: "Bid Evaluation Report",
      checked: true
    },
    {
      id: "Letter_of_Acceptance",
      title: "Letter of acceptance",
      checked: true
    },
    {
      id: "Contract_Signing",
      title: "Contract Signing",
      checked: true
    },
    {
      id: "Public_Award_Notice",
      title: "Public Award Notice",
      checked: true
    },
    {
      id: "Proof_of_Payment",
      title: "Proof of Payment",
      checked: true
    },
    {
      id: "Contract_offer_acceptance",
      title: "Contract offer acceptance",
      checked: true
    },
    {
      id: "Contract_offer_rejection",
      title: "Contract offer rejection",
      checked: true
    },
    {
      id: "Bid_Evaluation_Report",
      title: "Bid Evaluation Report",
      checked: true,
    },
    {
      id: "Others",
      title: "Others",
      checked: true
    }
  ]
};

const NotificationsPageComponent = ({ type }) => {
  const [filterOptions, setFilterOptions] = useState(defaultFilters[type]);

  const [page, setPage] = useState(1);

  const activeFilters = useMemo(() => {
    return filterOptions.filter(({ checked }) => checked).map(({ id }) => id);
  }, [filterOptions]);

  const { notificationsQuery, markNotificationsAsRead } = useNotifications({
    pageNumber: page,
    pageSize: 10,
    classId: activeFilters
  });

  const { data, isLoading } = notificationsQuery;

  const { pagination = {}, notifications = [] } = data || {};

  useEffect(() => {
    //Mark as read on request success
    if (notificationsQuery.isSuccess) {
      markNotificationsAsRead();
    }
  }, [notificationsQuery.isSuccess, markNotificationsAsRead]);

  const onToggleFilter = (id) => {
    setFilterOptions(
      filterOptions.map((filter) => {
        if (filter.id === id) {
          filter.checked = !filter.checked;
        }

        return filter;
      })
    );
  };

  const onUnselectAll = () => {
    setFilterOptions(prevOptions => {
      return prevOptions.map((filter) => {
        filter.checked = false;

        return filter;
      });
    }
    );
  };

  const onSelectAll = () => {
    setFilterOptions(prevOptions => {
      return prevOptions.map((filter) => {
        filter.checked = true;

        return filter;
      });
    }
    );
  };

  const onChangePage = (type) => () => {
    if (type === "minus") {
      setPage(Math.max(page - 1, 0));
    } else {
      setPage(Math.min(page + 1, pagination?.totalPages));
    }
  };

  return (
    <PageWrapper>
      <PageTitle marginBottom={30}>
        Notifications
      </PageTitle>
      <PageGrid>
        <div>
          {
            isLoading ? (
              <LoaderWrapper>
                <CircularProgress />
              </LoaderWrapper>
            ) : (
                notifications.map((notification, index) => (
                  <>
                    <Notification
                      {...notification}
                    />
                    {index !== (notifications.length - 1) ? <Divider /> : null}
                  </>
                ))
              )
          }
          <Grid
            container
            justify="space-between"
            style={{ marginTop: 30 }}
          >
            <PageChangeButton
              disabled={page === 1}
              onClick={onChangePage("minus")}
            >
              <LeftIcon />
            </PageChangeButton>
            <PageChangeButton
              disabled={page === pagination?.totalPages}
              onClick={onChangePage("page")}
            >
              <RightIcon />
            </PageChangeButton>
          </Grid>
        </div>
        <Filter
          filterOptions={filterOptions}
          onToggleFilter={onToggleFilter}
          onSelectAll={onSelectAll}
          onUnselectAll={onUnselectAll}
        />
      </PageGrid>
    </PageWrapper>
  );
};

export default NotificationsPageComponent;
