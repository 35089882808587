import styled from "styled-components";

export const DetailItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DetailItemLabel = styled.p`
  color: #5f7483;
  font-size: 13px;
  line-height: 16px;
`;

export const DetailItemText = styled.p`
  color: #5f7483;
  font-family: "Nunito Sans";
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
`;
