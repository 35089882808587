import styled from "styled-components";
import { Typography } from "@material-ui/core";
import { InfoContainer } from "../BidDetails/BidInfo/style";
import { CancelButton } from "../../../components/Forms/Common/style";

export const PlaceBidInfoContainer = styled(InfoContainer)`
  margin-bottom: 10px !important;
`;

export const PlaceBidFileUploadContainer = styled.div`
  box-sizing: border-box;
  border: 1px solid #CBD4DA;
  border-radius: 3px;
  background-color: #FFFFFF;
  padding-top: 25.5px !important;
  margin-top: 20px !important;
`;

export const PlaceBidFileUploadTitle = styled(Typography)`
  color: #212B36;
  font-size: 16px !important;
  font-weight: 600 !important;
  letter-spacing: 0;
  line-height: 24px !important;
  padding-left: 20px !important;
  margin-bottom: 20px !important;
`;

export const NewCancelButton = styled(CancelButton)`
  color: #919EAB !important;
  border: 1px solid #919EAB !important;
`;
