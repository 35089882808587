import React, { useEffect, useState } from "react";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";

import {
  ContractNumber,
  NotificationBody,
  NotificationCard,
  NotificationCardWrapper,
  NotificationFooter,
  NotificationHeader,
  NotificationHeaderTitle,
  NotificationMessage,
  NotificationNumber,
  NotificationTime,
} from "./style";
import { Link } from "react-router-dom";
import { getNotificationLink } from "../../../util/getNotificationLink";
import { formatDistanceToNow } from "date-fns";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import InfiniteScroll from "react-infinite-scroll-component";

const generateNotification = ({ body, actionText, actionId, notificationClass, secondaryId }) => {
  const textIndex = body.indexOf(actionText);

  if (textIndex === -1 || notificationClass === "other") {
    return <NotificationMessage>{body}</NotificationMessage>;
  }

  const startText = body.slice(0, textIndex);

  const endText = body.slice(textIndex + actionText?.length + 1);

  return (
    <NotificationMessage>
      {startText}{" "}
     
      <Link to={getNotificationLink(notificationClass, actionId, secondaryId)}>
        <ContractNumber>{actionText}</ContractNumber>
      </Link>
      {" " + endText}
    </NotificationMessage>
  );
};

const NotificationPanel = ({
  notifications = [],
  handleNotificationPanelClose,
  totalEntries = 0,
  loading,
  increasePage,
  hasMore,
  isSuccess,
}) => {
  const [currentNotification, setCurrentNotifications] = useState([]);

  useEffect(() => {
    if (isSuccess) {
      setCurrentNotifications(notifications);
    }
  }, [isSuccess, notifications]);

  const isAdmin = localStorage.getItem("isAdmin") === "true"; //TODO: Use useUserDetails hook
  //   console.log(
  //     "currentNotification ",
  //     formatDistanceToNow(
  //       moment(
  //         // notification.createdAt
  //         "2020-11-04T09:32:13.0587936"
  //       ).toDate()
  //     ),
  //     moment(
  //       // notification.createdAt
  //       "2020-11-04T09:32:13.0587936"
  //     ).toDate(),
  //     currentNotification
  //   );

  return (
    <div style={{ width: "315px" }}>
      <NotificationHeader>
        <div>
          <NotificationHeaderTitle>Notifications</NotificationHeaderTitle>
          <NotificationNumber>{totalEntries} new</NotificationNumber>
        </div>
        <IconButton aria-label="show new notifications" onClick={handleNotificationPanelClose}>
          <CloseIcon />
        </IconButton>
      </NotificationHeader>
      <NotificationBody id={"iBeKing"}>
        <InfiniteScroll
          next={increasePage}
          hasMore={hasMore}
          loader={null}
          dataLength={100}
          scrollableTarget={"iBeKing"}
          scrollThreshold={1}
        >
          {currentNotification.map((notification) => (          
            <NotificationCardWrapper key={notification}>            
              <NotificationCard>
                <div className={"child"}>
                  {generateNotification(notification)}
                  <NotificationTime>
                    {formatDistanceToNow(
                      moment(
                        notification.createdAt
                        //                         "2020-11-04T08:06:13.0587936"
                      ).toDate()
                    )}
                  </NotificationTime>
                </div>
              </NotificationCard>
            </NotificationCardWrapper>
          ))}
          {loading && (
            <Box pt={2} pb={2} width={"100%"} boxSizing={"border-box"}>
              <Grid container justify={"center"}>
                <CircularProgress size={20} />
              </Grid>
            </Box>
          )}
        </InfiniteScroll>
      </NotificationBody>
      <Link to={`/${isAdmin ? "admin" : "vendor"}/notifications`} style={{ textDecoration: "none" }}>
        <NotificationFooter>See all incoming activities</NotificationFooter>
      </Link>
    </div>
  );
};

export default NotificationPanel;
