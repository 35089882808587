import { Grid, TableRow, TableCell } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import { InputField } from "../../../components";
import AdminSearchFilter from "../../../components/AdminSearchFilter";
import Datepicker from "../../../components/DatePicker";
import SearchTable, { StyledTableCell } from "../../../components/SearchTable";
import { HoverTableStyle } from "../../../util/theme";
import {
  PageContainer,
  PageTitle,
  PageTitleContainer,
  SearchButton,
  StyleTableCellDesc,
  StyleTableCellId
} from "../../Common/style";
import TableEmptyContent from "../../Common/TableEmptyContent";
import withVendorDashboard from "../HOC/withVendorDashboard";
import { useQuery } from "react-query";
import vendorsAPI from "../../../redux/api/vendorsAPI";
import Loader from "../../../components/Common/Loader";
import { format } from "date-fns";

const TransactionTableBody = ({ history, data }) => data.map((transaction) => (
  <HoverTableStyle key={transaction.id} onClick={() => history.push(`/vendor/transactions/${transaction.mileStoneId}/${transaction.id}`)}>
    <StyledTableCell>
      <StyleTableCellId>{transaction.generatedId} </StyleTableCellId>
      <StyleTableCellDesc> {transaction.title} </StyleTableCellDesc>
    </StyledTableCell>
    <StyledTableCell>{format(new Date(transaction.transactionDate), "dd, LLL, yyyy")}</StyledTableCell>
    <StyledTableCell>{transaction.value}</StyledTableCell>
  </HoverTableStyle>
));

const Transactions = () => {
  const history = useHistory();
  const transaction = useQuery("vendorTransaction", vendorsAPI.getVendorTransaction);

  return (
    <PageContainer>
      <PageTitleContainer>
        <PageTitle>Transactions</PageTitle>
      </PageTitleContainer>

      <AdminSearchFilter normalSearch={true} title="Search">
        <Grid container direction="row" justify="space-between" alignItems="center">
          <Grid container direction="row" style={{ width: "auto" }} spacing={2}>
            <Grid item>
              <InputField label="Transaction ID" placeholder="Transaction ID" style={{ margin: "0", marginRight: "20px" }} />
            </Grid>
            <Grid item>
              <Datepicker label="Transaction Date" style={{ margin: "0"}} />
            </Grid>
          </Grid>
          <Grid item>
            <SearchButton>Search</SearchButton>
          </Grid>
        </Grid>
      </AdminSearchFilter>

      <SearchTable rows={transaction?.data?.length} columns={["TRANSACTION ID", "TRANSACTION DATE", "VALUE"]}>
        {transaction?.isLoading && (
          <TableRow>
            <TableCell colSpan={5}  >
              <Loader />
            </TableCell>
          </TableRow>
        )}
        {!transaction?.isLoading && transaction?.data?.length > 0 && <TransactionTableBody data={transaction?.data} history={history} />}
        {!transaction?.isLoading && transaction?.data?.length === 0 && (
          <TableEmptyContent noColSpan={5} textDisplayed="There are currently no transactions" />
        )}
        {transaction?.isError && (
          <TableEmptyContent noColSpan={5} textDisplayed="Please check your network, and retry" />
        )}
      </SearchTable>
    </PageContainer>
  );
};

export default withVendorDashboard(Transactions);
