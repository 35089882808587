import React from 'react'
import { Box, Modal } from '@material-ui/core'
import { ModalBody, ModalDescription, ModalTitle, UpdateButton } from './style'
import { CancelButton } from '../../../../../components/Forms/Common/style'

const CertificationDialog = ({
  open,
  onClose,
  onAction,
  loading,
}) => {
  return (
    <Modal open={open} onClose={onClose}>
      <ModalBody>
        <ModalTitle>Generate Certificate</ModalTitle>
        <ModalDescription>Are you sure you want to generate the Certificate of No Objection for Award of Contract</ModalDescription>
        <Box style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
          <UpdateButton
            disabled={loading}
            onClick={onAction}
          >
            {loading ? 'Generating...' : 'Confirm'}
          </UpdateButton>
          <CancelButton onClick={onClose} style={{
            marginLeft: "1rem",
          }}>
            Cancel
          </CancelButton>
        </Box>
      </ModalBody>
    </Modal>
  )
}

export default CertificationDialog
