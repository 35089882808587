import React, { useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { Box, Grid } from "@material-ui/core";
import { EventNote as EventNoteIcon, Done as DoneIcon } from "@material-ui/icons";

import { GeneralTitlePage } from "../../../../../../components/AddGeneralPlan/style";
import Breadcrumb from "../../../../../Common/PageElements/Breadcrumb";
import { PageWrapper } from "../../../../../Common/style";
import { ProjectLabel, ProjectDivider, ProjectMonitorCardContainer, ProjectProcureSubTitle, ProjectProcureTitle } from "../style";
import Dropdown from "../../../../../../components/DropdownField/index";
import TaskCompletedIcon from "../../../../../../assets/project_approval.svg";
import MediaDocumentSection from "../MediaDocSection";
import { useQuery, useMutation, queryCache } from "react-query";
import procurementAPI from "../../../../../../redux/api/procurementAPI";
import { formatCurrency } from "../../../../../../util/formatCurrency";
import moment from "moment";
import Loader from "../../../../../../components/Common/Loader";
import { MarkCompleteButton } from "../../../../../Vendor/TaskDetails/style";
import vendorsAPI from "../../../../../../redux/api/vendorsAPI";
import ToastContext from "../../../../../../util/toastContext";

const ProjectMilestoneTask = () => {
  const { showAlert } = useContext(ToastContext);
  const { activityId, procurementId, milestoneId, taskId, projectId } = useParams();
  const { data: milestoneTaskDetails, isLoading: milestoneTaskDetailsIsLoading } = useQuery(
    ["milestoneTaskDetails", { milestoneId, taskId }],
    procurementAPI.getProjectMilestoneTaskDetails
  );
  const { data: taskMediaFiles } = useQuery(["taskMediaFiles", { taskId }], procurementAPI.getMediaFiles);

  const [markTaskAsCompleted, markTaskAsCompletedStatus] = useMutation(vendorsAPI.markTaskAsCompleted, {
    onSuccess: (data) => {
      queryCache.setQueryData(["milestoneTaskDetails", { milestoneId, taskId }], data);
    },
  });

  useEffect(() => {
    if (markTaskAsCompletedStatus.status === "error") {
      showAlert({
        severity: "error",
        message: markTaskAsCompletedStatus.message,
      });
    } else if (markTaskAsCompletedStatus.status === "success") {
      showAlert({
        severity: "success",
        message: "Task has been submitted successflly",
      });
    }
  }, [markTaskAsCompletedStatus, showAlert]);

  const handlemarkTaskAsCompleted = () => {
    markTaskAsCompleted({
      milestoneId,
      taskId,
    });
  };

  return (
    <PageWrapper>
      <GeneralTitlePage>{milestoneTaskDetails?.title || "Task"}</GeneralTitlePage>
      <Breadcrumb
        values={[
          {
            url: "/admin/procurement/plans",
            title: "Home",
          },
          {
            url: `/admin/procurement/plans/${activityId}`,
            title: "Plan",
          },
          {
            url: `/admin/procurement/plan-details/${activityId}`,
            title: "Delta Irrigation Agricultural Improvement",
          },
          {
            url: `/admin/procurement/activity/contractsupervisionandmanagement/${activityId}/${procurementId}/`,
            title: "Monitoring",
          },
          {
            url: `/admin/procurement/activity/contractsupervisionandmanagement/${activityId}/${procurementId}/milestone/${projectId}/${milestoneId}`,
            title: "Milestone",
          },
        ]}
      />
      <Box pt="20px">
        <ProjectMonitorCardContainer>
          {milestoneTaskDetailsIsLoading && <Loader />}
          {!milestoneTaskDetailsIsLoading && (
            <Box>
              <Dropdown
                label="Status"
                style={{ width: "250px", margin: 0 }}
                values={[{ label: milestoneTaskDetails?.status.toLowerCase(), value: 1 }]}
              />
              <Box pt="24px" pb="20px" display="flex" justifyContent="space-between">
                <ProjectProcureTitle>{milestoneTaskDetails?.title || "N/A"}</ProjectProcureTitle>
                {milestoneTaskDetails?.status.toLowerCase() === "underreview" ? (
                  <MarkCompleteButton
                    startIcon={<DoneIcon color="#03ADEB" />}
                    loading={markTaskAsCompletedStatus.isLoading}
                    color="#DCF6FF"
                    onClick={handlemarkTaskAsCompleted}
                  >
                    Mark as completed
                  </MarkCompleteButton>
                ) : milestoneTaskDetails?.status.toLowerCase() === "done" ? (
                  <img src={TaskCompletedIcon} alt="task_completed" />
                ) : null}
              </Box>

              <Box pb="10px">
                <ProjectLabel>Tender Description</ProjectLabel>
              </Box>
              <ProjectProcureSubTitle>{milestoneTaskDetails?.description || "N/A"}</ProjectProcureSubTitle>

              <Box pt="20px" pb="20px">
                <ProjectDivider />
              </Box>

              <Box>
                <ProjectLabel>Value</ProjectLabel>
                <ProjectProcureTitle>
                  {(milestoneTaskDetails?.estimatedValue && formatCurrency(milestoneTaskDetails?.estimatedValue, true)) || "N/A"}
                </ProjectProcureTitle>
              </Box>

              <Box pt="20px" pb="20px">
                <ProjectDivider />
              </Box>
              <Box pb="20px">
                <Grid container>
                  <Grid item xs={6}>
                    <Box display="flex">
                      <EventNoteIcon />
                      <Box ml="12px">
                        <ProjectLabel>Start Date</ProjectLabel>
                        <ProjectProcureSubTitle>
                          {(milestoneTaskDetails?.startDate && moment(milestoneTaskDetails?.startDate).format("Do MMMM, YYYY")) ||
                            "N/A"}
                        </ProjectProcureSubTitle>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box display="flex">
                      <EventNoteIcon />
                      <Box ml="12px">
                        <ProjectLabel>End Date</ProjectLabel>
                        <ProjectProcureSubTitle>
                          {(milestoneTaskDetails?.endDate && moment(milestoneTaskDetails?.endDate).format("Do MMMM, YYYY")) ||
                            "N/A"}
                        </ProjectProcureSubTitle>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <MediaDocumentSection MediaFilesDoc={taskMediaFiles} title="Media Files" />
            </Box>
          )}
        </ProjectMonitorCardContainer>
      </Box>
    </PageWrapper>
  );
};

export default ProjectMilestoneTask;
