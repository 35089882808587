import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 40px 20px;
  margin: 0 ${(props) => (props.noOffset ? "0" : "-20px")};
  background: ${(props) => (props.variant === "vendor" ? "#fff" : "#fcfcfc")};
`;

export const Container = styled.div`
  box-sizing: border-box;
  border: 1px solid #cbd4da;
  border-radius: 3px;
  background-color: ${(props) => (props.variant === "vendor" ? "#FCFCFD" : "#f4fcff")};
  padding: 20px;
`;
