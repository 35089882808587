import React from "react";
import { useParams } from "react-router-dom";
import ReviewCommentsSection from "../../ReviewCommentsSection";
import { CardContentWrapper } from "../../../util/theme";
import { CancelButton } from "../../Forms/Common/style";
import LoadingButton from "../../LoadingButton/LoadingButton";

const CustomStageReviewSection = ({ onPrev }) => {
  const { activityId } = useParams();

  return (
    <div>
      <ReviewCommentsSection objectId={activityId}/>
      <CardContentWrapper style={{ padding: "35px 25px" }}>
        <CancelButton onClick={onPrev}>Back</CancelButton>
        <LoadingButton style={{ minWidth: "119px" }} disabled>Send For Approval</LoadingButton>
      </CardContentWrapper>
    </div>
  );
};

export default CustomStageReviewSection;
