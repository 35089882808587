/* eslint-disable */
import { Box, TableRow, TableCell } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import moment from "moment";
import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import SearchTable, { StyledTableCell, StyledTableRow } from "../../../components/SearchTable";
import useAlert from "../../../hooks/useAlert";
import vendorsAPI from "../../../redux/api/vendorsAPI";
import { getVendorRegStage } from "../../../util/getVendorRegStage";
import { PageTitle } from "../../Common/style";
import TableEmptyContent from "../../Common/TableEmptyContent";
import withVendorDashboard from "../HOC/withVendorDashboard";
import { sectionMock } from "./mock";
import {
  CompanyProfileInfo,
  ContractTableContainer,
  ContractTableStatus,
  HoverVendorText,
  OverviewCardContainer,
  OverviewCardDesc,
  OverviewCardFlag,
  OverviewCardTitle,
  OverviewCardValue,
  OverviewFlexContainer,
  OverviewTableCellDesc,
  OverviewTableCellId,
  OverviewTableHeaderContainer,
  OverviewWrapper,
  RecentBidText,
  SectionInfoCard,
  SectionInfoCardLabel,
  SectionInfoCardTitle,
  SectionMarkCompleteIcon,
  VendorRegistrationStatusContainer,
  VendorStatusTitle,
  ViewAllText,
} from "./style";
import useVendorDetailsRegistrationStatus from "../../../hooks/useVendorRegistrationStatus";
import { formatCurrency } from "../../../util/formatCurrency";
import { HoverTableStyle } from "../../../util/theme";
import { designatedPath } from "../../../util/transforms/contract";

const VendorOverview = () => {
  const history = useHistory();
  const { isRegistrationComplete } = useVendorDetailsRegistrationStatus();
  const { data: aVendorSummary = [], isLoading: isSummaryLoading, error: isSummaryError } = useQuery(
    "VendorSummary",
    vendorsAPI.getSummary
  );

  const {
    vendorRecentContracts = [],
    vendorContractSummary = {},
    vendorProjectSummary = {},
    vendorBidSummary = {},
    vendorRecentBids = [],
    vendorRecentProjects = [],
  } = aVendorSummary;

  const { showAlert } = useAlert();

  useEffect(() => {
    if (isSummaryError) {
      showAlert({
        severity: "error",
        message: isSummaryError?.message,
        durationInMs: 3000,
      });
    }
  }, [isSummaryError]);

  const ContractTable = () =>
    vendorRecentContracts?.map((eachContract) => {
      const contractUrl = () => designatedPath(eachContract, history, eachContract.id);
      return (
        <HoverTableStyle key={eachContract.id} onClick={contractUrl}>
          <StyledTableCell>
            <OverviewTableCellId>{eachContract.referenceId}</OverviewTableCellId>
            <OverviewTableCellDesc>{eachContract.title}</OverviewTableCellDesc>
          </StyledTableCell>
          <StyledTableCell>
            <ContractTableStatus flagcolor={(eachContract.signatureStatus === "Signed").toString()}>
              {eachContract.signatureStatus}
            </ContractTableStatus>
          </StyledTableCell>
        </HoverTableStyle>
      );
    });

  const BidsTable = () =>
    vendorRecentBids?.map((eachBid) => {
      return (
        <HoverTableStyle key={eachBid?.id} onClick={() => history.push(`/vendor/bids/${eachBid.tender.id}`)}>
          <StyledTableCell>
            <OverviewTableCellId>{eachBid?.tender?.packageNumber || "N/A"}</OverviewTableCellId>
            <OverviewTableCellDesc>{eachBid?.tender?.name || "N/A"}</OverviewTableCellDesc>
          </StyledTableCell>
          <StyledTableCell>{moment(eachBid.expiryDate).format("DD, MMMM, yyyy")}</StyledTableCell>
          <StyledTableCell>{formatCurrency(eachBid?.tender?.budget, true) || "N/A"}</StyledTableCell>
        </HoverTableStyle>
      );
    });

  const ProjectsTable = () =>
    vendorRecentProjects?.map((eachProject) => {
      // console.log("eachContract eachContract ", eachProject);
      return (
        <HoverTableStyle key={eachProject.id} onClick={() => history.push(`/vendor/projects/${eachProject.id}`)}>
          <StyledTableCell>
            <OverviewTableCellId>{eachProject.title}</OverviewTableCellId>
            <OverviewTableCellDesc>{eachProject.description}</OverviewTableCellDesc>
          </StyledTableCell>
          <StyledTableCell>{moment(eachProject.endDate).format("DD, MMMM, yyyy")}</StyledTableCell>
          <StyledTableCell>{formatCurrency(eachProject.estimatedValue, true) || "N/A"}</StyledTableCell>
        </HoverTableStyle>
      );
    });

  const firstName = localStorage.getItem("firstName");
  const vendorRegStage = getVendorRegStage();

  const goToStage = (stage) => {
    if (stage > vendorRegStage + 1) {
      showAlert({
        severity: "error",
        message: "Please complete previous stages first",
      });
    } else {
      history.push({
        pathname: "/vendor-registration",
        search: `?stage=${stage}`,
      });
    }
  };

  return (
    <OverviewWrapper>
      <PageTitle>Vendor Overview </PageTitle>
      {isRegistrationComplete === false || vendorRegStage < 7 ? (
        <VendorRegistrationStatusContainer>
          <VendorStatusTitle>Hello, {firstName}</VendorStatusTitle>
          <CompanyProfileInfo>
            Your company profile is in-complete. Kindly use the section below to complete your profile
          </CompanyProfileInfo>
          <Box display="flex" marginTop="10px">
            {sectionMock.map((eachSection) => (
              <SectionInfoCard
                isCompleted={vendorRegStage >= eachSection.id}
                key={eachSection.id}
                onClick={() => goToStage(eachSection.id)}
              >
                <Box display="flex" alignItems="center">
                  <Box width="100%">
                    <SectionInfoCardTitle>{eachSection.title}</SectionInfoCardTitle>
                    <SectionInfoCardLabel>{eachSection.label}</SectionInfoCardLabel>
                  </Box>
                  <Box>
                    <SectionMarkCompleteIcon completedsection={eachSection.completed.toString()} />
                  </Box>
                  <HoverVendorText locked={eachSection.id > vendorRegStage + 1}>Click to continue</HoverVendorText>
                </Box>
              </SectionInfoCard>
            ))}
          </Box>
        </VendorRegistrationStatusContainer>
      ) : null}

      <OverviewCardContainer>
        <Box minWidth="150px">
          {isSummaryLoading ? (
            <Skeleton width={230} height={150} variant={"rect"} />
          ) : (
            <>
              <OverviewCardTitle>Total Projects</OverviewCardTitle>
              <OverviewCardValue>{vendorProjectSummary.total || 0}</OverviewCardValue>
              <OverviewCardDesc>All active and completed projects</OverviewCardDesc>
              <Box display="flex" justifyContent="space-between">
                <OverviewCardFlag textcolor="#00823C" bgcolor="#B0E7AE">
                  {vendorProjectSummary.active || 0} Active
                </OverviewCardFlag>
                <OverviewCardFlag textcolor="#00B0F1" bgcolor="rgba(0,176,241,0.13)">
                  {vendorProjectSummary.completed || 0} Completed
                </OverviewCardFlag>
              </Box>
            </>
          )}
        </Box>
        <Box minWidth="200px">
          {isSummaryLoading ? (
            <Skeleton width={230} height={150} variant={"rect"} />
          ) : (
            <>
              <OverviewCardTitle>Total Bids</OverviewCardTitle>
              <OverviewCardValue>{vendorBidSummary.total || 0}</OverviewCardValue>
              <OverviewCardDesc>All approved and rejected bids</OverviewCardDesc>
              <Box display="flex" justifyContent="space-between">
                <OverviewCardFlag textcolor="#00823C" bgcolor="#B0E7AE">
                  {vendorBidSummary.approved || 0} Approved
                </OverviewCardFlag>
                <OverviewCardFlag textcolor="#DD5E47" bgcolor="#FFC8BE">
                  {vendorBidSummary.rejected || 0} Rejected
                </OverviewCardFlag>
              </Box>
            </>
          )}
        </Box>
        <Box minWidth="200px">
          {isSummaryLoading ? (
            <Skeleton width={230} height={150} variant={"rect"} />
          ) : (
            <>
              <OverviewCardTitle>Total Contracts</OverviewCardTitle>
              <OverviewCardValue>{vendorContractSummary.total || 0}</OverviewCardValue>
              <OverviewCardDesc>All signed and unsigned contracts</OverviewCardDesc>
              <Box display="flex" justifyContent="space-between">
                <OverviewCardFlag textcolor="#03ADEB" bgcolor="#C1EEFF">
                  {vendorContractSummary.signed || 0} Signed
                </OverviewCardFlag>
                <OverviewCardFlag textcolor="#637381" bgcolor="#CBD4DA">
                  {vendorContractSummary.unsigned || 0} Unsigned
                </OverviewCardFlag>
              </Box>
            </>
          )}
        </Box>
      </OverviewCardContainer>

      <OverviewFlexContainer>
        <div style={{ width: "100%" }}>
          <ContractTableContainer>
            <OverviewTableHeaderContainer>
              <Box>
                <RecentBidText>Recent Bids</RecentBidText>
              </Box>
              <Box>
                <ViewAllText onClick={() => history.push("/vendor/bids")}>View All</ViewAllText>
              </Box>
            </OverviewTableHeaderContainer>
            <SearchTable
              showPadding={false}
              showTableHeader={false}
              loading={isSummaryLoading}
              columns={["REF ID/TITLE", "Expiry Date", "VALUE"]}
            >
              {!isSummaryLoading && vendorRecentBids.length > 0 && <BidsTable />}
              {!isSummaryLoading && vendorRecentBids?.length === 0 && (
                <TableEmptyContent
                  noColSpan={3}
                  textDisplayed="You currently have no bids yet"
                  style={{ alignItems: "center" }}
                />
              )}
            </SearchTable>
          </ContractTableContainer>
        </div>

        <ContractTableContainer style={{ marginLeft: "20px" }}>
          <OverviewTableHeaderContainer>
            <Box>
              <RecentBidText>Recent Contracts</RecentBidText>
            </Box>
            <Box>
              <ViewAllText onClick={() => history.push("/vendor/contracts")}>View All</ViewAllText>
            </Box>
          </OverviewTableHeaderContainer>
          <SearchTable
            showPadding={false}
            showTableHeader={false}
            columns={["REF ID/TITLE", "STATUS"]}
            loading={isSummaryLoading}
          >
            {!isSummaryLoading && vendorRecentContracts.length > 0 && <ContractTable />}
            {!isSummaryLoading && vendorRecentContracts?.length === 0 && (
              <TableEmptyContent
                noColSpan={3}
                textDisplayed="You currently have no contracts yet"
                style={{ alignItems: "center" }}
              />
            )}
          </SearchTable>
        </ContractTableContainer>
      </OverviewFlexContainer>

      <Box marginTop="20px" marginBottom="20px">
        <OverviewTableHeaderContainer>
          <Box>
            <RecentBidText>Recent Project</RecentBidText>
          </Box>
          <Box>
            <ViewAllText onClick={() => history.push("/vendor/projects")}>View All</ViewAllText>
          </Box>
        </OverviewTableHeaderContainer>
        <SearchTable
          showPadding={false}
          showTableHeader={false}
          headerTitle="Recent Projects"
          loading={isSummaryLoading}
          columns={["REF ID/TITLE", "Expiry Date", "VALUE"]}
        >
          {!isSummaryLoading && vendorRecentProjects?.length > 0 && <ProjectsTable />}
          {!isSummaryLoading && vendorRecentProjects?.length === 0 && (
            <TableEmptyContent noColSpan={5} textDisplayed="You currently have no project yet" style={{ alignItems: "center" }} />
          )}
        </SearchTable>
      </Box>
    </OverviewWrapper>
  );
};

export default withVendorDashboard(VendorOverview);
