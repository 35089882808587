import React, { useEffect, useState } from "react";
import { Grid, Box } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useMutation } from "react-query";
import {
  saveGeneralCompanyDetails,
  getGeneralCompanyDetails,
  getCountries,
  getStates,
} from "../../../redux/reducer/vendorReducer";
import Dropdown from "../../DropdownField";
import InputField from "../../InputField";
import NavigationButtons from "../../NavigationButtons";
import { ErrorContainer } from "../Common/style";
import { TitleText } from "./style";
import { coreCompetencies, organizationTypes } from "./mock";
import withVendorRegLoader from "../../../pages/Common/HOC/withVendorRegLoader";
import useAlert from "../../../hooks/useAlert";
import FileUploadSection from "../../FileUploadSection";
import usersAPI from "../../../redux/api/usersAPI";
import { sortStates } from "../../../util/sortStates";

const Form = ({ dispatch, userId, ...rest }) => {
  const width = "100%";
  const margin = "15px 0 0 0";
  const marginTop = "15px";

  const [filesNames, setfilesNames] = useState([]);
  const { companyDetails, countries, states, loading } = useSelector((state) => state.vendor);

  useEffect(() => {
    if (filesNames.length > 1) {
      setfilesNames([filesNames[filesNames.length - 1]]);
    }
  }, [filesNames]);

  const { register, handleSubmit, errors, reset } = useForm();

  const { showAlert, Toast } = useAlert();

  const [uploadProfileImage, uploadProfileImageQuery] = useMutation(usersAPI.uploadProfileImage, {
    onError: (error) => {
      showAlert({
        message: error.message,
        severity: "error",
      });
    },
  });

  useEffect(() => {
    reset({
      ...companyDetails,
    });
  }, [dispatch, userId, companyDetails, reset]);

  const submitGeneralCompanyInfo = (data, cb) => {
    const ordered = {};
    Object.keys(data)
      .sort()
      .forEach((key) => {
        ordered[key] = data[key];
      });

    if (JSON.stringify(ordered) === JSON.stringify(companyDetails)) return cb();
    dispatch(saveGeneralCompanyDetails({ data, userId })).then((res) => {
      if (res.error) {
        showAlert({ severity: "error", message: res.payload });
        return;
      }
      showAlert({ severity: "success", message: "General Information Saved Successfully" });
      cb();
    });
  }

  const onSubmit = (cb) => (data) => {
    if (filesNames[0]) {
      const profileImageData = new FormData();
      profileImageData.append("photo", filesNames[0]);
      uploadProfileImage({ data: profileImageData, userId }).then((el) => {
        showAlert({
          message: "Your profile image was updated successfully",
          severity: "success",
        });
        submitGeneralCompanyInfo(data, cb);
      });
      return;
    };
    submitGeneralCompanyInfo(data, cb);
  };

  const onRenderError = (error) => <ErrorContainer>{error && error.message}</ErrorContainer>;

  return (
    <form>
      <Toast customStyles={{ marginTop: "20px" }} />
      <Box>
        <TitleText>Company Type</TitleText>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Dropdown
              name="coreCompetency"
              label="Area of Competence"
              values={coreCompetencies}
              // style={{ width, margin: 0, marginTop }}
              style={{ width, margin }}
              inputRef={register({
                required: "Please choose an area of Competence",
                validate: (value) => Number(value) > 0 || "Please choose an area of competence",
              })}
            />
            {onRenderError(errors.coreCompetency)}
          </Grid>
          <Grid item xs={6}>
            <Dropdown
              name="organizationType"
              label="Type of Organization"
              values={organizationTypes}
              // style={{ width, margin: 0, marginTop }}
              style={{ width, margin }}
              inputRef={register({
                required: "Please choose the type of organization",
                validate: (value) => Number(value) > 0 || "Please choose the type of organization",
              })}
            />
            {onRenderError(errors.organizationType)}
          </Grid>
        </Grid>
        <InputField
          type="text"
          label="Business Name"
          name="companyName"
          style={{ width, margin, padding: 0 }}
          inputRef={register({
            required: "Please supply your company name",
            validate: (value) => value.length > 0 || "Please supply your company name",
          })}
        />
        {onRenderError(errors.companyName)}
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <InputField
              type="date"
              label="Date of Registration / Renewal"
              name="incorporationDate"
              style={{ width, margin, padding: 0 }}
              defaultValue={new Date().toISOString().substring(0, 10)}
              inputRef={register({
                required: "Please supply a valid date",
              })}
            />
            {onRenderError(errors.incorporationDate)}
          </Grid>
          <Grid item xs={6}>
            <InputField
              type="text"
              label="CAC Registration Number"
              name="cacRegistrationNumber"
              style={{ width, margin, padding: 0 }}
              inputRef={register({
                required: "Please supply your cac registration number",
                validate: (value) => value.length > 0 || "Please supply your cac registration number",
              })}
            />
            {onRenderError(errors.cacRegistrationNumber)}
          </Grid>
        </Grid>
        <InputField
          type="capital-contribution"
          label="Capital Contribution by Partner (optional)"
          name="authorizedShareCapital"
          style={{ width, margin, padding: 0 }}
        />
        {onRenderError(errors.authorizedShareCapital)}
      </Box>

      <Box>
        <TitleText>Business Contact Information</TitleText>
        <InputField
          type="text"
          label="Address 1"
          name="addressLine1"
          style={{ width, margin: "0", marginTop, padding: 0 }}
          inputRef={register({
            required: "Please supply your address 1",
            validate: (value) => value.length > 0 || "Please supply your address 1",
          })}
        />
        {onRenderError(errors.addressLine1)}
        <InputField
          type="text"
          label="Address 2"
          name="addressLine2"
          style={{ width, margin, padding: 0 }}
          inputRef={register()}
        />
        {onRenderError(errors.addressLine2)}
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Dropdown
              name="country"
              label="Country"
              values={countries}
              style={{ width, margin, padding: 0 }}
              inputRef={register({
                required: "Please select your country",
                validate: (value) => value.length > 0 || "Please select your country",
              })}
            />
            {onRenderError(errors.country)}
          </Grid>
          <Grid item xs={4}>
            <Dropdown
              name="state"
              label="State"
              values={[{ label: "Select state", value: "" }, ...sortStates(states)]}
              style={{ width, margin, padding: 0 }}
              inputRef={register({
                required: "Please select your state",
                validate: (value) => value.length > 0 || "Please select your state",
              })}
            />
            {onRenderError(errors.state)}
          </Grid>
          <Grid item xs={4}>
            <InputField
              type="text"
              label="City"
              name="city"
              style={{ width, margin, padding: 0 }}
              inputRef={register({
                validate: (value) => value.length > 0 || "Please supply your city",
              })}
            />
            {onRenderError(errors.city)}
          </Grid>
        </Grid>
      </Box>

      <Box>
        <TitleText>Correspondence Address</TitleText>
        <InputField
          type="text"
          label="Address 1"
          name="correspondenceAddress1"
          style={{ width, margin, padding: 0 }}
          inputRef={register({
            required: "Please supply your correspondence address 1",
            validate: (value) => value.length > 0 || "Please supply your correspondence address 1",
          })}
        />
        {onRenderError(errors.correspondenceAddress1)}
        <InputField
          type="text"
          label="Address 2"
          name="correspondenceAddress2"
          style={{ width, margin, padding: 0 }}
          inputRef={register()}
        />
        {onRenderError(errors.correspondenceAddress2)}
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Dropdown
              name="correspondenceCountry"
              label="Country"
              values={countries}
              style={{ width, margin, padding: 0 }}
              inputRef={register({
                required: "Please select your correspondence country",
                validate: (value) => value.length > 0 || "Please select your correspondence country",
              })}
            />
            {onRenderError(errors.correspondenceCountry)}
          </Grid>
          <Grid item xs={4}>
            <Dropdown
              name="correspondenceState"
              label="State"
              values={[{ label: "Select state", value: "" }, ...sortStates(states)]}
              style={{ width, margin, padding: 0 }}
              inputRef={register({
                required: "Please select the correspondence state",
                validate: (value) => value.length > 0 || "Please supply the correspondence state",
              })}
            />
            {onRenderError(errors.correspondenceState)}
          </Grid>
          <Grid item xs={4}>
            <InputField
              name="correspondenceCity"
              label="City"
              style={{ width, margin, padding: 0 }}
              inputRef={register({
                required: "Please select the correspondence city",
                validate: (value) => value.length > 0 || "Please supply the correspondence city",
              })}
            />
            {onRenderError(errors.correspondenceCity)}
          </Grid>
        </Grid>
      </Box>

      <Box>
        <TitleText>Contact Person</TitleText>
        <Grid container spacing={2}>
          <Grid item xs={12} style={{ marginTop: 20 }}>
            <FileUploadSection setfilesNames={setfilesNames} filesNames={filesNames} />
          </Grid>
          <Grid item xs={6}>
            <InputField
              type="text"
              label="First Name"
              name="contactFirstName"
              style={{ width, margin, padding: 0 }}
              inputRef={register({
                required: "Please supply your contact first name",
                validate: (value) => value.length > 0 || "Please supply your contact first name",
              })}
            />
            {onRenderError(errors.contactFirstName)}
          </Grid>
          <Grid item xs={6}>
            <InputField
              type="text"
              label="Last Name"
              name="contactLastName"
              style={{ width, margin, padding: 0 }}
              inputRef={register({
                required: "Please supply your contact last name",
                validate: (value) => value.length > 0 || "Please supply your contact last name",
              })}
            />
            {onRenderError(errors.contactLastName)}
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <InputField
              type="phone"
              label="Phone Number"
              name="contactPhoneNumber"
              style={{ width, margin, padding: 0 }}
              inputRef={register({
                required: "Please supply your contact phone number",
                validate: (value) => value.length > 0 || "Please supply your contact phone number",
              })}
            />
            {onRenderError(errors.contactPhoneNumber)}
          </Grid>
          <Grid item xs={6}>
            <InputField
              type="email"
              label="Email Address"
              name="contactEmail"
              style={{ width, margin, padding: 0 }}
              inputRef={register({
                required: "Please supply your contact email address",
                validate: (value) => value.length > 0 || "Please supply your contact email address",
              })}
            />
            {onRenderError(errors.contactEmail)}
          </Grid>
        </Grid>
        <InputField type="text" label="Website URL (optional)" name="website" style={{ width, margin, padding: 0 }} />
        {onRenderError(errors.website)}
        <InputField
          type="text"
          label="Position"
          name="contactPosition"
          style={{ width, margin, padding: 0 }}
          inputRef={register({
            required: "Please supply contact position",
            validate: (value) => value.length > 0 || "Please supply contact position",
          })}
        />
        {onRenderError(errors.contactPosition)}
      </Box>
      <NavigationButtons
        value={loading === "pending" || uploadProfileImageQuery.isLoading ? "Please wait..." : "Save And Next"}
        nextActionHandler={(cb) => handleSubmit(onSubmit(cb))}
      />
    </form>
  );
};

const GeneralCompanyDetails = () => {
  const {
    user: { UserId },
  } = useSelector((state) => state.user);

  return withVendorRegLoader(Form)({
    title: "General Company Details",
    description: "Fill in the fields below",
    apis: [getCountries(), getStates(), getGeneralCompanyDetails(UserId)],
    userId: UserId,
    conditioners: ["countries", "states", "companyDetails"],
  });
};

export default GeneralCompanyDetails;
