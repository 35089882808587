import React, { useContext, useState } from "react";
import { Box, Modal, Button } from "@material-ui/core";
import { useDropzone } from "react-dropzone";
import { queryCache, useMutation } from "react-query";

import { ApprovalBanner, ModalBody, ModalButtons, ModalDescription, ModalTitle } from "../../CreateProcurement/style";
import procurementAPI from "../../../../redux/api/procurementAPI";
import LoadingButton from "../../../../components/LoadingButton/LoadingButton";
import { DropzoneArea } from "../../../../components/DocumentUploadField/style";
import FileProgress from "../../../../components/FileProgress/FileProgress";
import ToastContext from "../../../../util/toastContext";

import { Overlay, Title, Subtitle } from "./style";
import { ReactComponent as SaveIcon } from "../../../../assets/save.svg";
import { UploadButton } from "../../Procurement/Activitiies/CertificateOfNoObjection/style";

export default function ApprovalMemo({ procurementId, memoFileQueryKey, isProcurementOfficer }) {
  const { showAlert } = useContext(ToastContext);
  const [showApprove, setShowApprove] = useState(false);
  const [file, setFile] = useState(null);

  const [uploadProcurementApprovalMemo, uploadProcurementApprovalMemoQuery] = useMutation(
    procurementAPI.uploadProcurementApprovalMemo,
    {
      onSuccess: () => {
        //Refetch query
        queryCache.removeQueries(memoFileQueryKey);

        showAlert({
          message: "Approval memo has been uploaded successfully",
          severity: "success",
        });
        setShowApprove(false);
      },
      onError: (error) => {
        showAlert({
          message: error.message,
          severity: "error",
        });
      },
    }
  );

  const handleApprove = async () => {
    try {
      uploadProcurementApprovalMemo({
        procurementPlanId: procurementId,
        file,
      });
    } catch (e) {
      showAlert({
        message: e.message,
        severity: "error",
      });
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (files) => setFile(files[0]),
    multiple: false,
  });

  const handleShowUploadFile = () => {
    setShowApprove(true);
  };

  return (
    <>
      <Overlay>
        <Title>Approval Memo</Title>
        {isProcurementOfficer && <Subtitle>To proceed to the milestone phases, click the</Subtitle>}
        <Subtitle>
          {isProcurementOfficer
            ? "upload button below to upload an approval memo"
            : "Waiting for Procurement Officer to upload memo document"}
        </Subtitle>
        {isProcurementOfficer && (
          <UploadButton
            variant="contained"
            style={{ marginTop: 20 }}
            onClick={handleShowUploadFile}
            endIcon={
              <SaveIcon
                width="19px"
                fill="white"
                style={{
                  transform: "rotate(180deg)",
                }}
              />
            }
          >
            Click here to upload
          </UploadButton>
        )}
      </Overlay>
      <Modal open={showApprove} onClose={() => setShowApprove(false)}>
        <ModalBody>
          <ModalTitle>Upload Memo?</ModalTitle>
          <ModalDescription>
            You agree to have thoroughly gone through the draft. Scan and upload the signed approval memo for the Tender
          </ModalDescription>
          <ApprovalBanner>Upload Approval Memo Here.</ApprovalBanner>
          <Box mb={2.5}>
            {file ? (
              <FileProgress progress={100} fileName={file.name} fileSize={file.size} />
            ) : (
              <DropzoneArea {...getRootProps()}>
                <input {...getInputProps()} />
                <div>
                  <p>Upload or drop file here</p>
                </div>
              </DropzoneArea>
            )}
          </Box>
          <ModalButtons>
            <LoadingButton
              style={{
                marginLeft: "0",
              }}
              color="#3BD278"
              disabled={!file}
              onClick={handleApprove}
              loading={uploadProcurementApprovalMemoQuery.isLoading}
            >
              Yes, I approve.
            </LoadingButton>
            <Button onClick={() => setShowApprove(false)} variant="outlined">
              Cancel
            </Button>
          </ModalButtons>
        </ModalBody>
      </Modal>
    </>
  );
}
