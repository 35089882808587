import React from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { ReactQueryDevtools } from "react-query-devtools";
import { ReactQueryConfigProvider } from "react-query";
import NotFound from "./pages/404";
import ForgotPassword from "./pages/Admin/ForgotPassword";
import AdminPasswordReset from "./pages/Admin/PasswordReset";
import ProfileSetup from "./pages/Admin/ProfileSetup";
import Login from "./pages/Vendor/Login";
import SignUp from "./pages/Vendor/SignUp";
import VendorForgotPassword from "./pages/Vendor/ForgotPassword";
import NewPassword from "./pages/Common/NewPasswordComponent";
import VendorPasswordReset from "./pages/Vendor/PasswordReset";
import AccountVerificationComponent from "./pages/Vendor/AccountVerificationComponent";
import ConfirmEmail from "./pages/Common/ConfirmEmail";
import About from "./pages/Homepage/About";
import TenderDetail from "./pages/Vendor/TenderDetails";
import Resources from "./pages/Homepage/Resources";
import Landing from "./pages/Homepage/Landing";
import TenderList from "./pages/Homepage/TenderList";
import AwardedContracts from "./pages/Homepage/AwardedContracts";
import NewsAndBulletins from "./pages/Homepage/NewsAndBulletins";
import SingleTenderDetails from "./pages/Homepage/TenderDetails";
import Contact from "./pages/Homepage/Contact";
import AdminAuthenticatedRoute from "./pages/Admin/index";
import withAuthentication from "./components/Common/withAuthentication";
import VendorAuthenticatedRoute from "./pages/Vendor";
import NewsPage from "./pages/Homepage/NewsPage";
import AwardedContractDetails from "./pages/Homepage/ContractDetail.jsx";
import withVendorAuthentication from "./components/Common/withVendorAuthentication";
import VerifyCertificate from "./pages/Admin/Procurement/Activitiies/CertificateOfNoObjection/VerifyCertificate";

const queryConfig = {
  queries: {
    refetchOnWindowFocus: false,
    retry: 1,
  },
  mutations: {
    throwOnError: false,
  },
};

function App() {
  return (
    <ReactQueryConfigProvider config={queryConfig}>
      <Router>
        <div className="App">
          <Switch>
            <Route exact path="/">
              <Landing />
            </Route>
            <Route exact path="/login">
              <Login />
            </Route>
            <Route exact path="/verify-account">
              <AccountVerificationComponent />
            </Route>
            <Route exact path="/reset-password">
              <NewPassword />
            </Route>
            <Route exact path="/create-account">
              <SignUp />
            </Route>
            <Route exact path="/vendor/forgot-password">
              <VendorForgotPassword />
            </Route>
            <Route exact path="/admin/forgot-password">
              <ForgotPassword />
            </Route>
            <Route exact path="/admin/password-sent">
              <AdminPasswordReset />
            </Route>
            <Route exact path="/profile-setup">
              <ProfileSetup />
            </Route>
            <Route exact path="/vendor/password-sent">
              <VendorPasswordReset />
            </Route>
            <Route exact path="/vendor/tenders/:id">
              <TenderDetail />
            </Route>
            <Route exact path="/resources">
              <Resources />
            </Route>
            <Route exact path="/ocds-portal/tenders-list">
              <TenderList />
            </Route>
            <Route exact path="/ocds-portal/tenders-list/:id">
              <SingleTenderDetails />
            </Route>
            <Route exact path="/ocds-portal/awarded-contracts">
              <AwardedContracts />
            </Route>
            <Route exact path="/ocds-portal/awarded-contracts/:id">
              <AwardedContractDetails />
            </Route>
            <Route exact path="/ocds-portal">
            <Redirect to="/ocds-portal/awarded-contracts"/>
            </Route>
            <Route exact path="/about">
              <About />
            </Route>
            <Route exact path="/contact">
              <Contact />
            </Route>
            <Route exact path="/news-and-bulletins">
              <NewsAndBulletins />
            </Route>
            <Route exact path="/news/:id">
              <NewsPage />
            </Route>
            <Route exact path="/verify-cno/:id">
              <VerifyCertificate/>
            </Route>
            <Route exact path="/vendors/confirm-email" component={ConfirmEmail} />
            {AdminAuthenticatedRoute.map((route, index) => (
              <Route exact={route.exact} key={index} path={route.path} component={withAuthentication(route.component)} />
            ))}
            {VendorAuthenticatedRoute.map((route, index) => (
              <Route exact key={index} path={route.path} component={withVendorAuthentication(route.component)} />
            ))}
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </div>
      </Router>
      <ReactQueryDevtools initialIsOpen={false} />
    </ReactQueryConfigProvider>
  );
}

export default App;
