import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";

const tagColors = {
  blue: "#CCEFFC",
  yellow: "#FFE2C5",
  grey: "#DFE3E8",
  green: "#50B83C",
  dark_green: "#219653",
  grey_green: "#D6EFD2",
  grey_dark: "#CBD4DA",
};

const textColors = {
  blue: "#00B0F1",
  yellow: "#FF8D26",
  grey: "#454F5B",
  green: "#F4FBF3",
  dark_green: "#FFFFFF",
  grey_green: "#50B83C",
  grey_dark: "#637381",
};

// This is a Tag component whose variants can be specified my modifying props.
const Tag = (props) => {
  const { text, color, rounded, size } = props;
  const classes = useStyles({ text, color, rounded, size });
  return <span className={`${classes.Tag} ${size}`}>{text}</span>;
};

Tag.propTypes = {
  text: PropTypes.string,
  color: PropTypes.oneOf(["blue", "yellow", "grey", "green", "dark_green", "red", "grey_dark", "grey_green"]),
  rounded: PropTypes.bool,
  size: PropTypes.oneOf(["large", "small"]),
};

Tag.defaultProps = {
  rounded: false,
  size: "large",
};

const useStyles = makeStyles({
  Tag: {
    width: "auto",
    height: "auto",
    textAlign: "center",
    color: (props) => textColors[props.color],
    backgroundColor: (props) => tagColors[props.color],
    borderRadius: (props) => (props.rounded ? 10 : 3),
    "&.small": {
      fontSize: 13,
      padding: "3px 8px",
    },
    "&.large": {
      fontSize: 16,
      padding: "5px 20px",
    },
  },
});

export default Tag;
