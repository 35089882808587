import React from "react";
import HomepageNavBar from "../../../components/HomepageHeader";
import HomepageFooter from "../../../components/HomepageFooter";

const withHeaderFooterHOC = (WrapComponent) => () => (
  <div>
    <HomepageNavBar />
    <WrapComponent />
    <HomepageFooter />
  </div>
);

export default withHeaderFooterHOC;
