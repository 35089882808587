import React from "react";
import { MinistryInputField, FormWrapper } from "./style";
import SingleInviteTab, { FORM_TYPES } from "../Common/SingleTabForm";
import { postMinistries } from "../../redux/reducer/userReducer";

const AddNewMinistry = ({ handleCancel }) => {
  const Form = ({ formIndex, handleChange, validateInput, errors, generalError }) => (
    <FormWrapper>
      {generalError[formIndex] && <span>{generalError[formIndex]}</span>}
      <MinistryInputField
        type="text"
        label="Name"
        id={`name-${formIndex}`}
        name={"name"}
        onChange={(e) => handleChange(e, formIndex)}
        style={{ width: "auto" }}
        onBlur={() =>
          validateInput(formIndex, "name", (value, errorCb) => {
            if (value) {
              return errorCb(null);
            }
            errorCb("Ministry name is not valid");
          })
        }
      />
      {errors[formIndex]?.name && <span>{errors[formIndex]?.name}</span>}
      <div style={{ display: "flex", width: "100%" }}>
        <div style={{ width: "50%" }}>
          <MinistryInputField
            type="text"
            label="Code"
            id={`code-${formIndex}`}
            onChange={(e) => handleChange(e, formIndex)}
            name={"code"}
            onBlur={() =>
              validateInput(formIndex, "code", (value, errorCb) => {
                if (value) {
                  return errorCb(null);
                }
                errorCb("Code is not valid");
              })
            }
          />

          {errors[formIndex]?.code && <span>{errors[formIndex]?.code}</span>}
        </div>
        <div style={{ width: "50%" }}>
          <MinistryInputField
            type="email"
            label="Email"
            id={`email-${formIndex}`}
            onChange={(e) => handleChange(e, formIndex)}
            name={"email"}
            onBlur={() =>
              validateInput(formIndex, "email", (value, errorCb) => {
                if (/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(value)) {
                  return errorCb(null);
                }
                errorCb("Email is not valid");
              })
            }
          />
          {errors[formIndex]?.email && <span>{errors[formIndex]?.email}</span>}
        </div>
      </div>
    </FormWrapper>
  );

  return (
    <SingleInviteTab
      defaultValues={{ name: "", email: "", code: "" }}
      submitKey="ministries"
      singleInviteAPI={postMinistries}
      multipleInviteAPI={postMinistries}
      handleCancel={handleCancel}
      formType={FORM_TYPES.ministry}
    >
      <Form />
    </SingleInviteTab>
  );
};

export default AddNewMinistry;
