import React from "react";
import { Typography } from "@material-ui/core";
import moment from "moment";
import Timeline, { DateHeader, SidebarHeader, TimelineHeaders } from "react-calendar-timeline";
import PropTypes from "prop-types";
import {
  DoneIcon,
  Group,
  Item,
  ItemIcon,
  ItemWrapper,
  MileStoneText,
  NotDoneIcon,
  TimeLineContainer,
  InProgressIcon,
} from "./style";
import { ReactComponent as LeftIcon } from "../../assets/left.svg";
import { ReactComponent as RightIcon } from "../../assets/right.svg";

const transformGroup = (group) => ({
  ...group,
  height: 85,
});

const CalendarTimeLine = ({ groups, items, title = "Milestones", handleMilestoneClick }) => {
  const ChooseStatus = {
    done: <DoneIcon />,
    inProgress: <InProgressIcon />,
    notDone: <NotDoneIcon />,
  };

  const SideBarGroup = ({ group }) => (
    <Group display="flex" alignItems="center" id={group.id} onClick={handleMilestoneClick} style={{ cursor: "pointer" }}>
      {ChooseStatus[group.status]}
      <Typography id={group.id}>{group.title}</Typography>
    </Group>
  );

  const itemBgs = {
    pending: "#F49342",
    inProgress: "#6CCF81",
    done: "#03ADEB",
  };

  const itemRenderer = ({ item, itemContext, getItemProps, getResizeProps }) => {
    const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();

    return (
      <ItemWrapper {...getItemProps(item.itemProps)}>
        {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : ""}
        <Item
          className="rct-item-content"
          style={{ maxHeight: `${itemContext.dimensions.height}`, ...item.itemProps.style }}
          bgColor={itemBgs[item.type]}
        >
          <ItemIcon left>
            <LeftIcon width={10} fill={"white"} />
          </ItemIcon>
          {itemContext.title}
          <ItemIcon right>
            <RightIcon width={10} fill={"white"} />
          </ItemIcon>
        </Item>
        {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : ""}
      </ItemWrapper>
    );
  };

  return (
    <TimeLineContainer marginTop="15px">
      <Timeline
        sidebarWidth={300}
        groups={groups.map(transformGroup)}
        items={items}
        groupRenderer={({ group }) => <SideBarGroup group={group} />}
        itemRenderer={itemRenderer}
        defaultTimeStart={moment().subtract(10, "days")}
        defaultTimeEnd={moment().add(15, "days")}
        lineHeight={150}
      >
        <TimelineHeaders>
          <SidebarHeader>{({ getRootProps }) => <MileStoneText {...getRootProps()}>{title}</MileStoneText>}</SidebarHeader>
          <DateHeader unit="primaryHeader" />
          <DateHeader />
        </TimelineHeaders>
      </Timeline>
    </TimeLineContainer>
  );
};

CalendarTimeLine.propTypes = {
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      status: PropTypes.oneOf(["done", "notDone"]),
    })
  ).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      group: PropTypes.string,
      title: PropTypes.string,
      start_time: PropTypes.number,
      end_time: PropTypes.number,
      type: PropTypes.oneOf(["done", "inProgress", "pending"]),
    })
  ),
};

export default CalendarTimeLine;
