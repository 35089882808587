import React from "react";
import { useHistory } from "react-router-dom";
import { Dropdown, InputField } from "../../../../components";
import DatePicker from "../../../../components/DatePicker";
import SearchTable, { StyledTableCell } from "../../../../components/SearchTable";
import VendorSearchFilter from "../../../../components/VendorSearchFilter";
import {
  ContentWrapper,
  TitleContainer,
} from "../../../Admin/Procurement/style";
import { PageTitle } from "../../../Common/style";
import { tableRows } from "./mock";
import { VendorTableStyle as TenderStyleTableRow } from "../../../Admin/Vendors/style";
import withVendorDashboard from "../../HOC/withVendorDashboard";

const TableBody = () => {
  const history = useHistory();
  return tableRows.map((row, index) => (
    <TenderStyleTableRow
      key={`${row.refId}_${index}`}
      onClick={() => history.push(`/vendor/tenders/${row.refId}`)}
    >
      <StyledTableCell>
        <div>
          <p>{row.refId}</p>
          <span>{row.title}</span>
        </div>
      </StyledTableCell>
      <StyledTableCell>{row.category}</StyledTableCell>
      <StyledTableCell>{row.ministry}</StyledTableCell>
      <StyledTableCell>{row.expiryDate}</StyledTableCell>
      <StyledTableCell>{row.value}</StyledTableCell>
    </TenderStyleTableRow>
  ));
};

const VendorTenders = () => {
  const width = "100%";
  const height = "49px";

  return (
    <ContentWrapper>
      <TitleContainer>
        <PageTitle>Tenders</PageTitle>
      </TitleContainer>

      <VendorSearchFilter>
        <div style={{ display: "flex" }}>
          <InputField
            style={{ width, height }}
            type="text"
            label="Search ID, Title"
            defaultValue="Completion of resettlement scheme"
            required
          />
          <Dropdown
            style={{ width, height }}
            name="procurement category"
            label="By Procurement Category"
            values={[{ label: "All", value: 1 }]}
          />
          <Dropdown
            style={{ width, height }}
            name="Ministry"
            label="By Ministry"
            values={[{ label: "All", value: 1 }]}
          />
          <DatePicker label="Expiry Date" />
        </div>
      </VendorSearchFilter>
      <SearchTable
        rows={tableRows}
        columns={[
          "ref id/title",
          "category",
          "ministry",
          "expiry date",
          "value",
        ]}
      >
        <TableBody />
      </SearchTable>
    </ContentWrapper>
  );
};

export default withVendorDashboard(VendorTenders);
