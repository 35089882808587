import React from "react";
import {
  IdentificationContainer,
} from "../Forms/Common/style";
import FileUploadField from "../FileUploadField";
import { FormSubtitle } from "../../util/theme";

const FileUploadSection = ({
  title, description = false, setfilesNames, filesNames, setDescription, singleFileUpload = true,
}) => (
  <IdentificationContainer>
    <FormSubtitle style={{ fontWeight: 800, color: "#454F5B" }}>{title}</FormSubtitle>
    <FileUploadField
      description={description}
      setFilesNames={setfilesNames}
      filesNames={filesNames}
      setDescription={setDescription}
      singleFileUpload={singleFileUpload}
      multiple={true}
    />
  </IdentificationContainer>
);

export default FileUploadSection;
