import React from "react";
import { CardWrapper, CheckerDescription, CheckerDetails, CheckerName, CheckerTitle } from "./style";

const CheckerCard = ({ title, name, description }) => (
  <CardWrapper>
    <CheckerDetails>
      <CheckerTitle>{title}</CheckerTitle>
      <CheckerName>{name}</CheckerName>
    </CheckerDetails>
    <CheckerDescription>{description}</CheckerDescription>
  </CardWrapper>
);

export default CheckerCard;
