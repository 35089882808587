import React from "react";
import { StyledTableCell, StyledTableRow } from "../../../../../components/SearchTable";
import EmptyTableBody from "../../../../../components/EmptyTableBody";
import { EmptyTableSubTitle } from "../../../../../components/EmptyTableBody/style";
import { AddButton, CirclePlusIconWrapper } from "../../../../Common/style";
import { formatCurrency } from "../../../../../util/formatCurrency";

const MinistryTable = ({ toggleDrawer, ministries = [] }) => {
  return ministries.length ? (
    ministries.map((ministry, index) => (
      <StyledTableRow key={`${ministry.name}_${index}`}>
        <StyledTableCell component="th" scope="row">
          <div>
            <p style={{ color: "#1D2125" }}>{ministry.name}</p>
            <span style={{ color: "#637381" }}>{ministry.email}</span>
          </div>
        </StyledTableCell>
        <StyledTableCell>{ministry.code}</StyledTableCell>
        <StyledTableCell>{ministry.totalBids}</StyledTableCell>
        <StyledTableCell>{formatCurrency(ministry.estimatedValue, true)}</StyledTableCell>
      </StyledTableRow>
    ))
  ) : (
    <EmptyTableBody>
      <EmptyTableSubTitle>Get started adding your ministry</EmptyTableSubTitle>
      <span style={{ width: "75%", marginBottom: "12px" }}>
        Add and update your ministry via CSV upload or manually add them one at a time
      </span>
      <div>
        <AddButton
          onClick={() => toggleDrawer()}
          style={{
            width: "133px",
            justifyContent: "center",
          }}
        >
          <CirclePlusIconWrapper />
          <span style={{ fontSize: "14px" }}> Add New</span>
        </AddButton>
      </div>
    </EmptyTableBody>
  );
};

export default MinistryTable;
