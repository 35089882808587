import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import Dropdown from "../DropdownField";
import { useQuery } from "react-query";
import adminAPI from "../../redux/api/adminAPI";
import CircularProgress from "@material-ui/core/CircularProgress";
import { CircularWrapper } from "./style";

const MinistryDropdown = ({ api, useReactQuery = true, onChange, style }) => {
  const dispatch = useDispatch();

  const { data, isLoading } = useQuery({
    queryFn: adminAPI.getMinistriesAxios,
    queryKey: [
      "ministries",
      {
        pageSize: 0,
        pageNumber: null,
      },
    ],
  });

  const ministryOptions = useMemo(() => {
    let options = [
      {
        id: "",
        name: "All",
      },
    ];

    if (data?.data) {
      options = options.concat(data.data);
    }

    return options.map(({ id, name }) => ({
      label: name,
      value: id,
    }));
  }, [data]);

  const _onChange = (value) => {
    if (useReactQuery) {
      onChange(value);
    } else {
      dispatch(api({ ministryId: value }));
    }
  };

  if (isLoading) {
    return (
      <CircularWrapper display={"flex"} alignItems={"center"} height={"56px"}>
        <CircularProgress size={20} />
        <span style={{ marginLeft: "10px" }}>Loading Ministries</span>
      </CircularWrapper>
    );
  }

  return <Dropdown name="ministry" style={style} label="Ministry" values={ministryOptions} onChange={_onChange} />;
};

export default MinistryDropdown;
