export const bidsMock = [
  {
    id: "PR-0129",
    desc: "Completion Of Resettlement Scheme At Agbowa….",
    expiryDate: "Jul 23, 2020",
    value: "N23,000,000.00",
  },
  {
    id: "PR-0128",
    desc: "Completion Of Resettlement Scheme At Agbowa….",
    expiryDate: "Jul 23, 2020",
    value: "N23,000,000.00",
  },
  {
    id: "PR-0127",
    desc: "Completion Of Resettlement Scheme At Agbowa….",
    expiryDate: "Jul 23, 2020",
    value: "N23,000,000.00",
  },
  {
    id: "PR-0126",
    desc: "Completion Of Resettlement Scheme At Agbowa….",
    expiryDate: "Jul 23, 2020",
    value: "N23,000,000.00",
  },
  {
    id: "PR-0125",
    desc: "Completion Of Resettlement Scheme At Agbowa….",
    expiryDate: "Jul 23, 2020",
    value: "N23,000,000.00",
  },
];
export const contractMock = [
  {
    id: "#CON-2832389",
    desc: "Completion Of At Agbowa…",
    flag: "Unsigned",
  },
  {
    id: "#CON-2832379",
    desc: "Completion Of At Agbowa…",
    flag: "Signed",
  },
  {
    id: "#CON-2832369",
    desc: "Completion Of At Agbowa…",
    flag: "Signed",
  },
  {
    id: "#CON-2832359",
    desc: "Completion Of At Agbowa…",
    flag: "Signed",
  },
  {
    id: "#CON-2832349",
    desc: "Completion Of At Agbowa…",
    flag: "Unsigned",
  },
];

export const sectionMock = [
  {
    id: 1,
    title: "Section one",
    label: "General Information",
    completed: false,
  },
  {
    id: 2,
    title: "Section two",
    label: "Director Details",
    completed: false,
  },
  {
    id: 3,
    title: "Section three",
    label: "Area of core competence",
    completed: false,
  },
  {
    id: 4,
    title: "Section four",
    label: "Registration Categories",
    completed: false,
  },
  {
    id: 5,
    title: "Section five",
    label: "Document uploads",
    completed: false,
  },
  {
    id: 6,
    title: "Section six",
    label: "Attestation",
    completed: false,
  },
  {
    id: 7,
    title: "Section seven",
    label: "Verification",
    completed: false,
  },
];
