import React from "react";
import { Box } from "@material-ui/core";
import InputField from "../../../InputField";
import { GeneralCardContentWrapper, GeneralTitleLabel } from "../../../AddGeneralPlan/style";
import DatePicker from "../../../DatePicker";

const DataSheet = ({ formValues, updateFormValue, setFormValues, isDisabled, datasheetConfig }) => (
  <>
    <GeneralTitleLabel>Datasheet</GeneralTitleLabel>
    <GeneralCardContentWrapper>
      <Box display="flex" justifyContent="space-between">
        <DatePicker
          label={datasheetConfig.label}
          id={datasheetConfig.itemKey}
          value={formValues[datasheetConfig.itemKey]}
          handleChange={(value) => {
            setFormValues({ ...formValues, [datasheetConfig.itemKey]: value })
          }}
          disabled={isDisabled}
        />
        <InputField
          type="text"
          label="Hours"
          style={{ width: "100%" }}
          value={formValues.hours}
          onChange={updateFormValue("hours")}
          disabled={isDisabled}
        />
        <InputField
          type="text"
          label="Minutes"
          style={{ width: "100%" }}
          value={formValues.minutes}
          onChange={updateFormValue("minutes")}
          disabled={isDisabled}
        />
      </Box>
    </GeneralCardContentWrapper>
  </>
);

export default DataSheet;
