import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Box } from "@material-ui/core";
import { GeneralTitlePage } from "../../SpecificProcurementNotice/style";
import Breadcrumb from "../../../../../Common/PageElements/Breadcrumb";
import {
  EmptyState,
  EmptyStateDescription,
  EmptyStateHeader,
  ModdedAvatar,
  TableCellEmail,
  TableTop,
  TableWrapper
} from "../VendorsTable/style";
import { AcceptedTableHeader, RejectedTableHeader, StyledTableCell } from "./style";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { ReactComponent as RightIcon } from "../../../../../../assets/right_icon.svg";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import { useQuery } from "react-query";
import receiptOfResponseApi from "../../../../../../redux/api/receiptOfResponseApi";
import Loader from "../../../../../../components/Common/Loader";
import { convertVendors } from "../index";

const titleMapping = {
  accepted: "Shortlisted Vendors",
  rejected: "Rejected Vendors"
};

const statusMapping = {
  accepted: 8,
  rejected: 3
};

const VendorsCategorized = () => {
  const { procurementId = "", type, activityId } = useParams();

  const baseUrl = `/admin/procurement/activity/receiptofresponses/${activityId}/${procurementId}`;

  const breadcrumbLinks = [
    {
      url: "/admin/procurement/plans",
      title: "Home",
      forwardSlash: true
    },
    {
      url: `/admin/procurement/plan-details/${procurementId}`,
      title: "Activities List"
    },
    {
      url: baseUrl,
      title: "Receipt of Response"
    },
    {
      url: "#",
      title: titleMapping[type]
    }
  ];

  const rejected = type === "rejected";

  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
  });

  const vendors = useQuery({
    queryKey: [
      "interestedVendors",
      {
        procurementId,
        PageNumber: pagination.pageNumber,
        Status: statusMapping[type],
        PageSize: pagination.pageSize
      }
    ],
    queryFn: receiptOfResponseApi.getInterestedVendors
  });

  const onChangePage = (event, value) => {
    setPagination({
      ...pagination,
      pageNumber: value + 1
    })
  }

  const onChangeRowsPerPage = (event) => {
    setPagination({
      pageNumber: 1,
      pageSize: parseInt(event.target.value)
    })
  }

  const history = useHistory()

  const redirectUser = (id, name) => {
    history.push(`/admin/procurement/activity/receiptofresponses/${activityId}/${procurementId}/vendorsDetails/${id}/${name}`)
  }

  const { interestedVendorsData, pagination: apiPagination } = vendors.data || {}

  const renderHeader = () => {
    if(rejected){
      return (
        <RejectedTableHeader>
          <StyledTableCell>
            NAME
          </StyledTableCell>
          <StyledTableCell>
            REGISTRATION CLASS
          </StyledTableCell>
          <StyledTableCell align='right'>
            ACTION
          </StyledTableCell>
        </RejectedTableHeader>
      )
    }

    return (
      <AcceptedTableHeader>
        <StyledTableCell>
          NAME
        </StyledTableCell>
        <StyledTableCell>
          REGISTRATION CLASS
        </StyledTableCell>
        <StyledTableCell align='right'>
          ACTION
        </StyledTableCell>
      </AcceptedTableHeader>
    )
  }

  return (
    <Box pb={10}>
      <Box mb={4} mt={2}>
        <GeneralTitlePage>{titleMapping[type]}</GeneralTitlePage>
        <Breadcrumb values={breadcrumbLinks}/>
      </Box>
      {
        vendors.isLoading ? (
          <Loader feedback='Fetching details'/>
        ) : (
          <TableWrapper>
        <TableTop>
          <span>
            Showing {interestedVendorsData?.interestedVendors.length} results
          </span>
        </TableTop>
        {
          interestedVendorsData?.interestedVendors?.length > 0 ? (
            <Table>
              {renderHeader()}
              {
                convertVendors(interestedVendorsData?.interestedVendors, interestedVendorsData?.totalRequiredDocuments)?.map(({ name, email, REGID, id }) => (
                  <TableRow key={id}>
                    <TableCell>
                      <Grid spacing={1} container wrap='nowrap'>
                        <Box mr={2}>
                          <ModdedAvatar size={34} color='#50B83C'>
                            {name?.slice(0, 2)}
                          </ModdedAvatar>
                        </Box>
                        <Grid
                          container
                          direction='column'
                          spacing={1}
                        >
                      <span>
                        {name}
                      </span>
                          <TableCellEmail>
                            {email}
                          </TableCellEmail>
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell>
                      {REGID}
                    </TableCell>
                    <TableCell align='right'>
                      <IconButton onClick={() => redirectUser(id, name)}>
                        <RightIcon fill='#637381' width={18}/>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              }
            </Table>
          ) : (
            <EmptyState>
              <EmptyStateHeader>
                All caught up!
              </EmptyStateHeader>
              <EmptyStateDescription>
                You do not have any {type} vendors
              </EmptyStateDescription>
            </EmptyState>
          )
        }
        <Box pt={3}>
          <Grid container justify='flex-end'>
            <TablePagination
              count={apiPagination.totalEntries}
              rowsPerPage={pagination.pageSize}
              page={pagination.pageNumber - 1}
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
            />
          </Grid>
        </Box>
      </TableWrapper>
        )
      }
    </Box>
  );
};

export default VendorsCategorized;
