import React from "react";
import { ErrorContainer } from "../../util/theme";

const ErrorRenderer = ({ errors, name, message }) => {
  if(errors[name]){
    return (
      <ErrorContainer>
        {message}
      </ErrorContainer>
    );
  }

  return null
};

export default ErrorRenderer;
