import React from "react";
import { Grid, Box, Typography, Tooltip } from "@material-ui/core";
import { useSelector } from "react-redux";
import moment from "moment";

import { ContractDetailTabText } from "./style";
import { formatCurrency } from "../../../util/formatCurrency";

const AwardedCompanyTab = () => {
  const {
    adminContractDetails: { awardedCompany = {}, contractDetails = {}, tenderDetails = {}, bidders = [] },
  } = useSelector((state) => state.admin);

  const details = [
    { title: "Date of award", value: contractDetails.awardDate ? moment(contractDetails.awardDate).format("DD MMM, yyyy") : "Not Available" },
    { title: "Project Title", value: contractDetails.contractName || "Not available", maxLength: 120 },
    { title: "Project description", value: tenderDetails.description || "Not available", maxLength: 120 },
    { title: "Budget Description	", value: contractDetails.budgetDescription || "Not available", maxLength: 120 },
    // { title: "Award Acceptance Date ", value: contractDetails.acceptanceDate ? moment(contractDetails.acceptanceDate).format("DD, MMMM, yyyy") : "Not available" },
    { title: "Award Remark/Criteria	", value: bidders.filter((bid) => bid.status === "Recommended")[0]?.comment || "Not Available" },

    { title: "Contract Sum", value: formatCurrency(contractDetails.contractAmount, true) || "Not available" },
    { title: "Contractor", value: awardedCompany.companyName || "Not available" },
  ];

  return (
    <>
      <Box style={{ background: "#e4e9ec", padding: 20 }}>
        <Typography variant="h6" style={{ color: "#5F7483" }}>
          Award Details
        </Typography>
        <Typography style={{ color: "#5F7483" }}>Current Procurement Stage : {tenderDetails?.procurementStage || 'Implementation Stage'}</Typography>
      </Box>
      <Box>
        {details.map((detail, i) => (
          <Grid key={i} style={{ borderBottom: "1px solid #CBD4DA" }} container>
            <Grid item xs={3}>
              <ContractDetailTabText style={{ background: "#e4e9ec", boxShadow: "0px -1px 0px #C9D4DB" }}>
                {detail.title}
              </ContractDetailTabText>
            </Grid>
            <Grid item sm style={{ borderLeft: "1px solid #CBD4DA" }}>
              {detail.maxLength && detail.length > detail.maxLength ? (
                <Tooltip title={detail.value} aria-label="add">
                  <ContractDetailTabText>
                    {detail.value.length > detail.maxLength ? `${detail.value.substr(0, detail.maxLength)}...` : detail.value}
                  </ContractDetailTabText>
                </Tooltip>
              ) : (
                <ContractDetailTabText>{detail.value}</ContractDetailTabText>
              )}
            </Grid>
          </Grid>
        ))}
      </Box>
    </>
  );
};

export default AwardedCompanyTab;
