import styled from "styled-components";
import { FormTitle } from "../../../util/theme";

export const Wrapper = styled.div`
  display: block;
  text-align: left;
  width: 100%;
`;

export const TitleText = styled.p`
  margin: 20px 0 5px 0;
  height: 22px;
  color: #445571;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
`;

export const RegisterFormTitle = styled(FormTitle)`
  margin-bottom: 0 !important;
`;
