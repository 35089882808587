import styled from "styled-components";
import ButtonBase from "@material-ui/core/ButtonBase";
import { ReactComponent as MobileChevronIcon } from "../../../../../../assets/mobile_chevron.svg";
import { ReactComponent as PolarisCalendarIcon } from "../../../../../../assets/calendar.svg";
import { ContractStatus, ContractSignatureStatus } from "../../../../../../util/constants";

export const ContractListWrapper = styled.div`
  min-height: 622px;
`;

export const ContractListItem = styled(ButtonBase)`
  &.MuiButtonBase-root {
    box-sizing: border-box;
    width: 100%;
    border: 1px solid #e4e9ec;
    border-radius: 3px 3px 0 0;
    background-color: #fcfcfd;
    padding: 16px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:not(:last-of-type) {
      margin-bottom: 20px;
    }
  }
`;

export const ContractListItemDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContractStatusDot = styled.span`
  height: 10px;
  width: 10px;
  margin-right: 10px;
  border-radius: 50%;
  background-color: ${(props) => {
    switch (props.status) {
      case ContractStatus.Accepted:
        return "#6CCF81";
      case ContractStatus.Pending:
        return "#f49342";
      case ContractStatus.Rejected:
        return "red";
      default:
      // noop, impossible situation
    }
  }};
`;

const signatureStatusColorMixin = (props) => {
  switch (props.signatureStatus) {
    case ContractSignatureStatus.Signed:
      return "#3BD278";
    case ContractSignatureStatus.Unsigned:
      return "#FF8D26";
    default:
    // noop, impossible situation
  }
};

export const ContractStatusBadge = styled.span`
  border: 1px solid ${signatureStatusColorMixin};
  border-radius: 3px;
  color: ${signatureStatusColorMixin};
  margin-left: 5px;
  padding: 2.5px 10.5px;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
`;

export const ContractPrimaryText = styled.span`
  display: inline-flex;
  align-items: center;
  color: #1e2b37;
  font-size: 14px;
  line-height: 20px;
`;

export const ContractSecondaryText = styled.span`
  display: inline-flex;
  align-items: center;
  color: #5f7483;
  font-size: 14px;
  line-height: 20px;
`;

export const TextLine = styled.div`
  margin-bottom: ${(props) => (props.spaced ? "5px" : "3px")};
  display: flex;
  align-items: center;
`;

export const TextDivider = styled.span`
  display: inline-block;
`;

export const ForwardIcon = styled(MobileChevronIcon)`
  transform: rotateY(180deg);
  color: #919eab;
  height: 20px;
`;

export const CalendarIcon = styled(PolarisCalendarIcon)`
  width: 15px;
`;
