import React from "react";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { NotificationDescription, NotificationTime, NotificationWrapper } from "./style";
import { getNotificationLink } from "../../util/getNotificationLink";

export const notificationTypes = {
  Others: 10,
  Special_procurement_notice: "Special_procurement_notice",
  Bid_Evaluation_Report: "Bid_Evaluation",
  Bid_Evaluation: "Bid_Evaluation",
  Letter_of_Acceptance: "Letter_of_Acceptance",
  Contract_Signing: "Contract_Signing",
  Public_Award_Notice: "Public_Award_Notice",
  Proof_of_Payment: "Proof_of_Payment",
  Contract_offer_acceptance: "Contract_offer_acceptance",
  Contract_offer_rejection: "Contract_offer_rejection",
  Bid_Submission: "Bid_Submission",
  Special_procurement_notice_admin: "Special_procurement_notice_admin",
  Bid_Invitation_admin: "Bid_Invitation_admin",
  Bid_Evaluation_Report_admin: "Bid_Evaluation_Report_admin",
  Letter_of_Acceptance_admin: "Letter_of_Acceptance_admin",
  Contract_Signing_admin: "Contract_Signing_admin",
  Public_Award_Notice_admin: "Public_Award_Notice_admin",
  Tender_Activity: "Tender_Activity",
  Annual_Procurement_Plan: "Annual_Procurement_Plan",
  Annual_Procurement_Plan_Ammend: "Annual_Procurement_Plan_Ammend",
  Tender_Review: "Tender_Review", 
};

const generateBody = ({ body, actionText, actionId, secondaryId, notificationClass }) => {
  const textIndex = body.indexOf(actionText);

  if (textIndex === -1 || notificationClass === "other") {
    return <NotificationDescription>{body}</NotificationDescription>;
  }

  const startText = body.slice(0, textIndex);

  const endText = body.slice(textIndex + actionText?.length + 1);

  return (
    <NotificationDescription> 
      {startText} <Link to={getNotificationLink(notificationClass, actionId, secondaryId)}>{actionText}</Link>
      {" " + endText}
    </NotificationDescription>
  );
};

const Notification = ({ actionId, actionText, body, createdAt, notificationClass, secondaryId }) => {
  const getTimeFromNow = (dateString) => { 
    const distanceFromNow= moment(dateString).add(1, 'h').fromNow();   
    return `${distanceFromNow}`;
  };

  return(

  <NotificationWrapper>  
    <Grid container direction="column" justify="space-between">
      {generateBody({
        body,
        actionText,
        actionId,
        notificationClass,
        secondaryId
      })}
       <NotificationTime>{getTimeFromNow(createdAt)}</NotificationTime>
    </Grid>
  </NotificationWrapper>
)};

Notification.propTypes = {
  actionId: PropTypes.string.isRequired,
  actionText: PropTypes.string.isRequired,
  notificationClass: PropTypes.oneOf(Object.values(notificationTypes)).isRequired,
  body: PropTypes.string,
  createdAt: PropTypes.string.isRequired,
};

export default Notification;
