/* eslint-disable no-unused-expressions */
import withAdminDashboard from "../../../HOC/withAdminDashboard";
import withActivityLayout from "../../../../../components/Common/HOC/withActivityLayout";
import procurementAPI from "../../../../../redux/api/procurementAPI";

const ApprovalPayment = () => (
  withActivityLayout(() => null)({
    title: "Approval of Payment",
    postAPI: procurementAPI.savePlanDocument,
    getAPI: procurementAPI.getPlanDocuments,
    extraCrumbs: [
      {
        url: "#",
        title: "Approval of Payment",
      },
    ]
  })
);

export default withAdminDashboard(ApprovalPayment);
