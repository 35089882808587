import styled from "styled-components";
import { FormTitle } from "../../util/theme";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  padding: 89px 0;
  width: 84%;
  left: 15%;

  svg {
    width: auto;
  }

  p {
    color: #212b36;
    font-weight: 600;
    margin: 10px 0 5px;
  }

  span {
    font-size: 17px;
    text-align: center;
  }
`;

export const EmptyTableSubTitle = styled(FormTitle)`
  font-size: 17px !important;
`;
