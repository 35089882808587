import React from "react";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";

import {
  NewsUpdateImageContentLarge,
  NewsDateLabel,
  NewsCaptionLarge,
  LandingComment,
  RoleContentCardFooter,
} from "../../pages/Homepage/Landing/style";
import { mainNews } from "../../pages/Homepage/NewsAndBulletins/mock";

const NewsHeadlinesCard = ({ news }) => {
  const main = !!news ? news : mainNews;
  return (
    <div>
      <NewsUpdateImageContentLarge url={main.imgUrl} />
      <Grid item xs={10}>
        <NewsDateLabel>{main.date}</NewsDateLabel>
        <NewsCaptionLarge>{main.title}</NewsCaptionLarge>
        <LandingComment>{main.synopsis}</LandingComment>

        <RoleContentCardFooter>
          <Link to={`/news/${main.id}`}>Read More </Link>
        </RoleContentCardFooter>
      </Grid>
    </div>
  );
};

export default NewsHeadlinesCard;
