import styled from "styled-components";
import withStyles from "@material-ui/core/styles/withStyles";
import Avatar from "@material-ui/core/Avatar";

export const ChatMessageAvatar = withStyles(() => ({
  root: {
    border: "1px solid #c9d4db",
    marginRight: 15,
  },
  colorDefault: {
    backgroundColor: "#fff",
    color: "#424f5c;",
  },
  rounded: {
    borderRadius: 4,
  },
}))(Avatar);

export const ChatMessageAuthor = styled.span`
  font-size: 14px;
  line-height: 19px;
  color: #424f5c;
  font-weight: bold;
  margin-right: 20px;
`;

export const ChatMessageTime = styled.span`
  font-size: 10px;
  line-height: 14px;
  color: #919191;
`;

export const ChatMessageText = styled.p`
  color: #424f5c;
  font-size: 14px;
  line-height: 19px;
`;
