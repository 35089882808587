import styled from "styled-components";
import { Typography } from "@material-ui/core";

export const Wrapper = styled.div`
  display: block;
  text-align: left;
  width: 100%;
`;

export const TitleText = styled.p`
  margin: 15px 0 0 0;
  height: 22px;
  color: #445571;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
`;

export const ErrorContainer = styled(Typography)`
  margin: 0;
  font-size: 10px !important;
  color: red;
`;
