import { CircularProgress, Grid, makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { queryCache, useMutation, useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import { InputField } from "../../../../components";
import DatePicker from "../../../../components/DatePicker";
import LoadingButton from "../../../../components/LoadingButton/LoadingButton";
import vendorsAPI from "../../../../redux/api/vendorsAPI";
import ToastContext from "../../../../util/toastContext";
import InvoicePreviewModal from "../InvoicePreview";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import GridFileUpload from "../../Transactions/TransactionGridFileUpload";
import Button from "@material-ui/core/Button";

import { ReactComponent as GreenCheck } from "../../../../assets/green-check-2.svg";

const InvoiceTab = ({ milestone = {}, milestoneTasks }) => {
  const { projectId, milestoneId } = useParams();
  const classes = useStyles();
  const { showAlert } = useContext(ToastContext);
  const [isPreviewInvoiceModalVisible, setIsPreviewInvoiceModal] = useState(false);
  const [fileNames, setFilenames] = useState([]);
  const [dueDate, setDueDate] = useState(new Date().toString());
  const { data: project } = useQuery(["project", projectId], vendorsAPI.getProjectDetails);
  const { isLoading: invoiceIsLoading, data: invoice } = useQuery(
    ["milestoneInvoices", milestoneId],
    vendorsAPI.getMilestoneInvoices
  );
  const [createInvoice, { error, isLoading }] = useMutation(vendorsAPI.createInvoice, {
    onSuccess: (data) => {
      queryCache.setQueryData(["milestoneInvoices", milestoneId], data);
      setIsPreviewInvoiceModal(false);
      showAlert({
        severity: "success",
        message: "Invoice sent successfully",
      });
    },
    onError: () => {
      showAlert({
        severity: "error",
        message: error?.message,
      });
    },
  });

  const handleCreateInvoice = () => {
    createInvoice({
      milestoneId,
      data: {
        dueDate: new Date(dueDate).toISOString(),
        fileNames: fileNames || []
      },
    });
  };

  const renderInvoiceView = () => {
    return (
      <Grid
        container
        direction="column"
        alignItems="center"
        className={classes.container}
        style={{ paddingTop: 80, paddingBottom: 60 }}
      >
        <GreenCheck/>
        <Typography className={classes.sentTitle}>Invoice Sent</Typography>
        <Typography className={classes.description}>
          These information would be used to generate the invoice for this milestone
        </Typography>
        <Link
          to={`/vendor/projects/${projectId}/milestone/${milestoneId}/invoice/${invoice.id}`}
          className={classes.link}
        >
          View Details
        </Link>
      </Grid>
    );
  };

  const renderCreateInvoiceView = () => {
    return (
      <div className={classes.container}>
        <Box p={1}>
          <Typography className={classes.title}>Add Invoice Details</Typography>
          <Typography>These information would be used to generate the invoice for this milestone</Typography>
        </Box>
        <Grid container>
          <InputField
            label="Milestone name"
            value={milestone.title}
            style={{
              width: '100%',
              margin: '8px 0 0 0',
            }}
            disabled
          />
          <DatePicker
            label="Due Date"
            value={dueDate}
            handleChange={setDueDate}
            style={{
              width: '100%',
            }}
          />
        </Grid>
        <Box p={1}>
          <GridFileUpload noMargin filesNames={fileNames} setFilesNames={setFilenames} multiple />
        </Box>
        <Box p={1}  mt={2}>
          <Grid container>
            <Button
              variant='outlined'
              onClick={() => setIsPreviewInvoiceModal(true)}
            >
              Preview
            </Button>
            <LoadingButton type="submit" loading={isLoading} onClick={handleCreateInvoice}>
              Send Invoice
            </LoadingButton>
          </Grid>
        </Box>
        <InvoicePreviewModal
          open={isPreviewInvoiceModalVisible}
          onRequestClose={() => setIsPreviewInvoiceModal(false)}
          milestone={milestone}
          milestoneTasks={milestoneTasks}
          documents={fileNames}
          dueDate={dueDate}
          project={project}
          onCreateInvoice={handleCreateInvoice}
          isCreatingInvoice={isLoading}
        />
      </div>
    );
  };

  if (invoiceIsLoading) {
    return (
      <center style={{ marginTop: 20 }}>
        <CircularProgress />
      </center>
    );
  } else if (!invoice) {
    return (
      <Paper>
        {renderCreateInvoiceView()}
      </Paper>
    );
  }
  return (
    <Paper>
      {renderInvoiceView()}
    </Paper>
  );
};

const useStyles = makeStyles({
  container: {
    padding: "40px 20px",
    boxShadow: "0px 2px 4px #C9D4DB",
    borderRadius: 3,
  },
  title: {
    fontSize: 20,
  },
  sentTitle: {
    fontSize: 20,
    color: '#27AE60',
    margin: '25px 0 30px',
  },
  description: {
    textAlign: 'center',
    width: '70%',
    marginBottom: 10
  },
  link: {
    color: '#2D9CDB'
  },
  cancel: {
    marginRight: 26,
    border: "1px solid #42526E !important",
    color: "#42526E !important",
  },
});

InvoiceTab.propTypes = {
  milestone: PropTypes.object,
};

export default React.memo(InvoiceTab);
