import React, { useContext, useEffect, useMemo } from "react";
import { Box } from "@material-ui/core";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import withAdminDashboard from "../../../HOC/withAdminDashboard";
import {
  BreadCrumbWrapper,
  CardContent,
  CardLabel,
  ContentWrapper,
  StatusCircleSuccess
} from "../../../PlanDetails/style";
import { PageTitle } from "../../../../Common/style";
import Breadcrumb from "../../../../Common/PageElements/Breadcrumb";
import {
  CardBodyLastSection,
  CardBodySection,
  CardContainerSection,
  CardHeaderSection,
  ProgressCardTitle,
  ProgressCardWrapper
} from "./style";
import {
  CircularProgressTextLabel,
  CircularProgressTextValue
} from "../../../../../components/CircularProgressBar/style";
import CalendarTimeLine from "../../../../../components/CalendarTimeline";
import procurementAPI from "../../../../../redux/api/procurementAPI";
import Loader from "../../../../../components/Common/Loader";
import { format, parseISO } from "date-fns";
import { formatCurrency } from "../../../../../util/formatCurrency";
import ToastContext from "../../../../../util/toastContext";
import { ApprovalButton } from "../../../../../components/Forms/Common/style";

const timelineConfig = {
  group: {
    height: 100,
  },
  item: {
    style: {
      backgroundColor: "#03ADEB",
      color: "white",
      textAlign: "center",
      height: "30px",
      borderRadius: "16px",
      width: "440px",
      padding: "3px 0",
    },
  },
};

const milestoneStatus = {
  DONE: "done",
  INPROGRESS: "inProgress",
  PENDING: "notDone",
};

const milestoneType = {
  DONE: "done",
  INPROGRESS: "inProgress",
  PENDING: "pending",
};

const milestoneStatusText = {
  DONE: "done",
  INPROGRESS: "In progress",
  PENDING: "Pending",
};

const _formatDate = (dateString) => format(parseISO(dateString), "dd, MMMM, yyyy");

const PlanningTracking = () => {
  const history = useHistory();
  const { procurementId, activityId } = useParams();
  const { status, error, data } = useQuery(
    ["getPlanningAndTrackingInformation", procurementId],
    procurementAPI.getPlanningAndTrackingInformation
  );
  const { manager = {}, contract = {}, milestones = [] } = data ?? {};
  const planningGroupList = useMemo(() => {
    return milestones.map((milestone) => ({
      id: milestone.id,
      title: milestone.title,
      height: timelineConfig.group.height,
      status: milestoneStatus[milestone.status],
    }));
  }, [milestones]);
  const planningItems = useMemo(() => {
    return milestones.map((milestone) => ({
      id: `pi-${milestone.id}`,
      group: milestone.id,
      title: milestoneStatusText[milestone.status],
      type: milestoneType[milestone.status],
      start_time: parseISO(milestone.startDate),
      end_time: parseISO(milestone.endDate),
      itemProps: {
        style: {},
      },
    }));
  }, [milestones]);
  const completedPercentage = useMemo(() => {
    const total = milestones.length;
    const completed = milestones.filter((milestone) => milestone.status === "DONE").length;

    if (total === 0) return 0;

    return ((completed / total) * 100).toPrecision(3);
  }, [milestones]);
  // victor:
  // N/B: Might replace this with value from backend
  // This is me taking a guess at what the field means in the meantime
  // paymentStatus === 1 for PAID invoices
  const currentExpenditure = useMemo(
    () =>
      milestones
        .map((m) => m.milestoneInvoice)
        .filter((i) => !!i && i.paymentStatus === 1)
        .reduce((sum, i) => sum + i.price, 0),
    [milestones]
  );

  const { showAlert } = useContext(ToastContext);

  const BreadLinks = [
    { url: "/admin/procurement/plans", title: "Home" },
    {  url: `/admin/procurement/plan-details/${procurementId}`,
      title: "Activities List",
      forwardSlash: true,
   },
    { url: "#", title: "Planning and Tracking" },
  ];


  const [approveActivity, approveActivityQueryResponse] = useMutation(procurementAPI.approveActivity, {
    throwOnError: true,
  });

  const handleApproval = async () => {
    try {
      await approveActivity({
        procurementPlanId: procurementId,
        procurementPlanActivityId: activityId,
      });

      showAlert({
        severity: "success",
        message: "Successfully approved stage",
        durationInMs: 3000
      }) ;   
      setTimeout(function(){ history.push(`/admin/procurement/plan-details/${procurementId}`)}, 3000);
     
    }catch (e) {
      showAlert({
        severity: "error",
        message: e.message,
        durationInMs: 3000,
      });
    }
  };

  useEffect(() => {
    if (status === "error")
      showAlert({
        severity: "error",
        message: error.message,
      });
  }, [status, error, showAlert]);

  if (status === "loading")
    return (
      <ContentWrapper>
        <Loader />
      </ContentWrapper>
    );

  if (status === "error") return <ContentWrapper />;

  return (
    <ContentWrapper>
      <PageTitle>Planning And Tracking</PageTitle>
      <BreadCrumbWrapper>
        <Breadcrumb values={BreadLinks} />
      </BreadCrumbWrapper>

      <Box display="flex">
        <CardContainerSection>
          <CardHeaderSection>
            <Box width="100%">
              <CardLabel>Contract ID</CardLabel>
              <CardContent>{contract.contractNumber}</CardContent>
            </Box>
            <Box width="100%">
              <CardLabel>Status</CardLabel>
              <CardLabel>
                <StatusCircleSuccess marginRight={4} />
                Cleared
              </CardLabel>
            </Box>
            <Box width="100%" />
          </CardHeaderSection>
          <Box display="flex">
            <CardBodySection>
              <Box width="100%">
                <CardLabel>Start Date</CardLabel>
                <CardContent>{_formatDate(contract.startDate)}</CardContent>
              </Box>
              <Box width="100%" marginTop="17px">
                <CardLabel>Manager</CardLabel>
                <CardContent>
                  {manager?.firstName} {manager?.lastName}
                </CardContent>
              </Box>
            </CardBodySection>
            <CardBodySection>
              <Box width="100%">
                <CardLabel>End Date</CardLabel>
                <CardContent>{_formatDate(contract.endDate)}</CardContent>
              </Box>
              <Box width="100%" marginTop="17px">
                <CardLabel>Procurement Method</CardLabel>
                <CardContent>
                  {contract.procurementPlan?.procurementMethod?.name} ({contract.procurementPlan?.procurementMethod?.code})
                </CardContent>
              </Box>
            </CardBodySection>
            <CardBodyLastSection>
              <Box width="100%">
                <CardLabel>Contract Amount</CardLabel>
                <CardContent>{formatCurrency(contract.estimatedValue, true)}</CardContent>
              </Box>
              <Box width="100%" marginTop="17px">
                <CardLabel>Current Expenditure</CardLabel>
                <CardContent>{formatCurrency(currentExpenditure, true)}</CardContent>
              </Box>
            </CardBodyLastSection>
          </Box>
        </CardContainerSection>
        <ProgressCardWrapper>
          <ProgressCardTitle>Project Progress</ProgressCardTitle>
          <Box width="100%" display="flex" justifyContent="center">
            <Box height="100px" width="100px">
              <CircularProgressbarWithChildren value={completedPercentage}>
                <CircularProgressTextValue>{completedPercentage}%</CircularProgressTextValue>
                <CircularProgressTextLabel>Completed</CircularProgressTextLabel>
              </CircularProgressbarWithChildren>
            </Box>
          </Box>
        </ProgressCardWrapper>
      </Box>

      <CalendarTimeLine groups={planningGroupList} items={planningItems} />
      <div style={{ paddingTop: "50px" }}>
        <ApprovalButton disabled={approveActivityQueryResponse.isLoading} onClick={handleApproval} loading={approveActivityQueryResponse.isLoading} style={{ margin: "0 !important" }}>
          Approve
        </ApprovalButton>
      </div>
    </ContentWrapper>
  );
};

export default withAdminDashboard(PlanningTracking);
