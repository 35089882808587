import Staff from "./Administration/Staff";
import Settings from "./Administration/Settings";
import Vendors from "./Vendors";
import Contracts from "./Contracts";
import GeneralPlanDetails from "./Procurement/GeneralPlanDetails";
import GeneralPlans from "./Procurement/GeneralPlans";
import Tender from "./Procurement/Tender";
import CreateProcurement from "./CreateProcurement";
import Ministry from "./Administration/Ministry";
import Roles from "./Settings/Roles";
import Users from "./Settings/Users";
import Checkers from "./Settings/Checkers";
import CheckerDetails from "./Settings/Checkers/CheckerDetails";
import PlanDetails from "./PlanDetails/PlanDetails";
import VendorDetails from "./VendorDetails/index";
import AddGeneralPlan from "../../components/AddGeneralPlan";
import NewVendors from "./NewVendors";
import SingleContractDetails from "./Contracts/SingleContractDetails";
import ActivityPageWrapper from "./ActivityPageWrapper";
import AdminOverview from "./Overview/Overview";
import AdminNotification from "./Notifications/AdminNotification";
import Permission from "./Administration/Permission";
import MOJContractDetails from "./Procurement/GeneralPlanDetails/MOJContractDetails";
import VendorVerify from "./VendorDetails/VendorVerify";

const AdminAuthenticatedRoute = [
  {
    path: "/admin/dashboard/overview",
    component: AdminOverview,
  },
  {
    path: "/admin/administration/staffs",
    component: Staff,
  },
  {
    path: "/admin/administration/settings",
    component: Settings,
  },
  {
    path: "/admin/vendorsList/vendors",
    component: Vendors,
    exact: true,
  },
  {
    path: "/admin/vendorsList/:vendorId",
    component: VendorDetails,
    exact: true,
  },
  {
    path: "/admin/contractsList/contracts",
    component: Contracts,
    exact: true,
  },
  {
    path: "/admin/vendorsList/vendors/pending",
    component: NewVendors,
    exact: true,
  },
  {
    path: "/admin/procurement/plans/:id",
    component: GeneralPlanDetails,
    exact: true,
  },
  {
    path: "/admin/procurement/plans",
    component: GeneralPlans,
    exact: true,
  },
  {
    path: "/admin/procurement/plans/notice/:id",
    component: AddGeneralPlan,
    exact: true,
  },
  {
    path: "/admin/procurement/tender",
    component: Tender,
  },
  {
    path: "/admin/procurement/plans/:id/create",
    component: CreateProcurement,
    exact: false,
  },
  {
    path: "/admin/administration/MDAs",
    component: Ministry,
  },
  {
    path: "/admin/notifications",
    component: AdminNotification,
  },
  {
    path: "/admin/settings/roles",
    component: Roles,
    exact: true,
  },
  {
    path: "/admin/settings/users",
    component: Users,
    exact: true,
  },
  {
    path: "/admin/settings/checkers",
    component: Checkers,
    exact: true,
  },
  {
    path: "/admin/settings/checkers/single",
    component: CheckerDetails,
    exact: true,
  },
  {
    path: "/admin/procurement/plan-details/:procurementId",
    component: PlanDetails,
  },
  {
    path: "/admin/contracts/:id",
    component: SingleContractDetails,
  },
  {
    path: "/admin/procurement/activity/:activityName/:activityId/:procurementId",
    component: ActivityPageWrapper,
  },
  {
    path: "/admin/administration/permissions",
    component: Permission,
  },
  {
    path: "/admin/procurement/:procurementId/documents",
    component: MOJContractDetails,
  },
  {
    path: "/admin/vendors/:vendorId/:vendorProfileId/verify-vendor",
    component: VendorVerify,
  },
];

export default AdminAuthenticatedRoute;
