import React from "react";
import { Route, Switch } from "react-router-dom";
import withAdminDashboard from "../../../HOC/withAdminDashboard";
import BidderResponseReceipt from "./BidderResponseReceipt";
import EvaluatedVendors from "./EvaluatedVendors";
import VendorEvaluation from "./VendorEvaluation";
import ManualPayments from "./ManualPayments/ManualPayments";

const baseUrl = "/admin/procurement/activity/:activityName/:activityId/:procurementId";

const BidderResponseReceiptWrapper = () => {
  return <div>
    <Switch>
      <Route path={baseUrl} component={BidderResponseReceipt} exact />
      <Route path={`${baseUrl}/evaluation/:vendorId`} component={VendorEvaluation} exact />
      <Route path={`${baseUrl}/evaluated/vendor`} component={EvaluatedVendors} exact />
      <Route path={`${baseUrl}/payments`} component={ManualPayments} exact />
    </Switch>
  </div>;
}

export default withAdminDashboard(BidderResponseReceiptWrapper);
