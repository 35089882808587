import React from "react";
import {
  CuteModal,
  InnerModalBox,
  MainText,
  ModalHeader,
  ModalWrapper,
  ModalProceedBtn,
  CuteGrid,
  ModalCloseBtn,
} from "../style";
import { ReactComponent as CheckIcon } from "../../../../assets/check_mark.svg";
import { Box } from "@material-ui/core";

const ExpressInterestModal = ({ numOfUploadedDocs, numOfRequiredDocs, open, setModal, isLoading, handleOnClick }) => {
  return (
    <CuteModal open={open}>
      <ModalWrapper>
        <ModalHeader>Expression of interest</ModalHeader>
        <MainText mt="30px">Proceed to express interest in the contract</MainText>
        <InnerModalBox>
          <CuteGrid width="550px">
            <div>
              <MainText>{numOfRequiredDocs} Required Documents</MainText>
              <MainText mt="10px">{numOfUploadedDocs} Uploaded Documents</MainText>
            </div>
            <div>
              <CheckIcon />
            </div>
          </CuteGrid>
        </InnerModalBox>
        <MainText mt="30px"></MainText>
        <Box display="flex" justifyContent="flex-start">
          <ModalProceedBtn disabled={isLoading} onClick={handleOnClick}>
            {isLoading ? "Please waiting...." : "Submit"}
          </ModalProceedBtn>
          <ModalCloseBtn disabled={isLoading} onClick={() => setModal(false)}>
            Close
          </ModalCloseBtn>
        </Box>
      </ModalWrapper>
    </CuteModal>
  );
};

export default ExpressInterestModal;
