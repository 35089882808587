import styled from "styled-components";
import Grid from "@material-ui/core/Grid";

export const Wrapper = styled(Grid)`
  min-height: 100vh;
`;

export const SideNavSection = styled.div`
  background: #ffffff;
  position: fixed;
  height: 100vh;
  min-width: 250px;
  left: 0;
  top: 0;
  overflow-x: hidden;
  z-index: 100;
`;

export const Caption = styled.div`
  color: #000000;
  font-size: 21px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 28px;
  padding: 28.5px;
  padding-top: 16px;
  padding-bottom: 17px;
  border-bottom: 1px solid #d5d8da;
  margin-bottom: 28px;
  cursor: pointer;
`;
