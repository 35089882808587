import styled from "styled-components";
import { FormTitle, FormSubtitle } from "../../util/theme";

export const CardLabelWrapper = styled(FormTitle)`
  margin: 0 0 10px 0 !important;
  font-size: 15px !important;
  font-weight: 601 !important;
`;

export const CardNumberText = styled(FormTitle)`
  margin: 20px 0 0 0 !important;
  font-size: 15px !important;
  letter-spacing: 0;
  line-height: 32px !important;
  font-weight: 600 !important;
`;

export const CardInfoText = styled(FormSubtitle)`
  margin: 25px 0 10px 0 !important;
  font-size: 12px !important;
  letter-spacing: 0 !important;
  line-height: 16px !important;
`;

export const CardTitleText = styled(FormSubtitle)`
  margin: 0 0 10px 0 !important;
  font-size: 12px !important;
  letter-spacing: 0 !important;
  line-height: 1px !important;
`;

export const DescriptionSection = styled.div`
  margin: 0;
`;
