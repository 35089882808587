import React from "react";
import { Wrapper, Title, Subtitle } from "./style";
import Box from "@material-ui/core/Box";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";

export default function BidStatus({ status }) {
  return (
    <Wrapper status={status}>
      <Box display="flex" flexDirection="column" flex={1}>
        {status === "PROCESSING" && (
          <React.Fragment>
            <Title>Processing</Title>
            <Subtitle>Please wait. Your documents are currrently being evaluated</Subtitle>
          </React.Fragment>
        )}
        {status === "SHORTLISTED" && (
          <React.Fragment>
            <Title>You have been shortlisted</Title>
            <Subtitle>Kindly wait for the invitation to tender</Subtitle>
          </React.Fragment>
        )}
      </Box>
      <div className="icon-wrapper">
        <CheckRoundedIcon />
      </div>
    </Wrapper>
  );
}
