import styled from "styled-components";

import { Typography } from "@material-ui/core";
import { CardSection } from "../../../PlanDetails/style";
import { CheckEntryCheckCircleSharpIcon } from "../../../CreateProcurement/style";

export const TrackingCardSection = styled(CardSection)`
  box-sizing: border-box;
  border-radius: 3px;
  background-color: #F8F8F9;
`;

export const CardHeaderSection = styled.div`
  box-sizing: border-box;
  border: 1px solid #C9D4DB;
  border-radius: 3px;
  background-color: #E4E9EC;
  padding: 11px 21px !important;
  display: flex;
`;

export const CardBodySection = styled.div`
  box-sizing: border-box;
  border-radius: 3px;
  background-color: #FFFFFF;
  padding: 20px !important;
  width: 90% !important;
`;
export const CardBodyLastSection = styled.div`
  box-sizing: border-box;
  border: 1px solid #C9D4DB;
  padding: 20px !important;
  background-color: #F8F8F9;
  width: 100% !important;
`;

export const CardContainerSection = styled.div`
  box-sizing: border-box;
  border: 1px solid #C9D4DB;
  border-radius: 3px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 4px 0 #C9D4DB;
  width: 100% !important;
`;

export const ProgressCardWrapper = styled.div`
  box-sizing: border-box;
  border-radius: 3px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 4px 0 #C9D4DB;
  min-width: 268px;
  margin-left: 23px !important;
  padding: 20px !important;
`;

export const ProgressCardTitle = styled(Typography)`
  color: #1E2B37;
  font-size: 16px !important;
  font-weight: 600 !important;
  letter-spacing: 0;
  line-height: 24px !important;
  margin-bottom: 24px !important;
`;

export const TimeLineContainer = styled.div`
  margin-top: 15px !important;
  box-sizing: border-box;
  border: 1px solid #C9D4DB;
  border-radius: 3px;
  background-color: #E5E9EC;
  box-shadow: 0 2px 4px 0 #C9D4DB;
  padding-bottom: 33px;

  .react-calendar-timeline .rct-dateHeader-primary {
    background-color: #ffffff;
    color: #000000;
  }

  .react-calendar-timeline .rct-dateHeader {
    background-color: #ffffff;
    border: none;
  }

  .react-calendar-timeline .rct-item .rct-item-content {
    width: 100%;
  }

  .react-calendar-timeline .rct-calendar-header {
    border: 1px solid #e4e4e4;
  }

  .react-calendar-timeline .rct-header-root {
    background-color: #ffffff;
    border: 1px solid #e4e4e4;
    box-shadow: 0px 0px 2px #bbb;
  }

  .react-calendar-timeline .rct-sidebar .rct-sidebar-row {
    border: 1px solid #e4e4e4;
    display: flex;
    background-color: #ffffff;
  }

  .rct-items .rct-item {
    height: 30px !important;
    border: none !important;
  }
`;

export const DoneIcon = styled(CheckEntryCheckCircleSharpIcon)`
  color: rgba(3,173,235,0.1);
  font-size: 24px !important;
`;

export const NotDoneIcon = styled.div`
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  background-color: rgba(244,147,66,0.1);
`;

export const MileStoneText = styled(Typography)`
  color: #212B36;
  font-size: 16px !important;
  font-weight: 600 !important;
  letter-spacing: 0;
  line-height: 24px !important;
  display: flex;
  align-items: flex-end;
  padding-left: 15px;
`;
