import React from "react";
import { Grid, Container } from "@material-ui/core";
import {
  SectionWrapper,
  Section1,
  QuoteWrapper,
  Quote,
  Image,
  VisionOrMissionIcon,
  VisionOrMissionTitle,
  VisionOrMissionDescription,
  VisionAndMission,
  VisionOrMission,
  MemberInfo,
  MemberImage,
  MemberCard,
  MemberName,
  MemberRole,
  PeopleSection,
  SectionTitle,
  SectionSubtitle,
  // Members,
  PeopleSectionsContent,
  PeopleSections,
  OpenQuoteIcon,
  CloseQuoteIcon,
} from "./style";
import Hero from "../../../components/Hero";
import withHeaderFooterHOC from "../HOC/withHeaderFooterHOC";
import { team } from "./mock";

const About = () => (
  <div>
    <Hero
      title="About Us"
      desc="Are you interested in bidding for a contract for your organization or for an individual? Click the link below to register"
    />
    <SectionWrapper>
      <Container>
        <Section1>
          <QuoteWrapper>
            <OpenQuoteIcon>“</OpenQuoteIcon>
            <Quote>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quis, labore fugiat
              libero tempora facere possimus sapiente aperiam, et commodi assumenda in.
              Vero minus nobis mollitia dolore incidunt non magnam nisi, maxime autem quis error quod inventore vel id dicta,
              molestiae iste tempora sit, voluptate nihil cumque omnis! Ullam ea inventore laboriosam ad beatae
              tempora ipsa provident corporis fuga fugiat deserunt consectetur esse praesentium, officiis iusto
              corrupti! Nobis neque minima ratione repudiandae voluptas dolorum enim! Deserunt optio tenetur nulla minima,
              placeat dolorem laborum assumenda aspernatur nostrum. Itaque quibusdam pariatur maiores hic excepturi.
              Eaque saepe quia porro odio ratione minus. Ad, expedita.
            </Quote>
            <CloseQuoteIcon>“</CloseQuoteIcon>
          </QuoteWrapper>
          <Image></Image>
        </Section1>
      </Container>
    </SectionWrapper>
    <Container>
      <SectionWrapper>
        <VisionAndMission>
          <Grid container>
            <Grid item xs={12} sm={6} md={6}>
              <VisionOrMission>
                <VisionOrMissionIcon />
                <VisionOrMissionTitle>Vision</VisionOrMissionTitle>
                <VisionOrMissionDescription>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit. Exercitationem at earum quasi recusandae magnam? Eaque illo maiores eos voluptatem ex!
                </VisionOrMissionDescription>
              </VisionOrMission>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <VisionOrMission>
                <VisionOrMissionIcon />
                <VisionOrMissionTitle>Mission</VisionOrMissionTitle>
                <VisionOrMissionDescription>
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                  Corrupti quo nam deserunt ipsum, provident voluptate quibusdam libero adipisci.
                  Dolorum harum tempora veniam adipisci fugiat impedit inventore architecto? A, perferendis? Commodi.
                </VisionOrMissionDescription>
              </VisionOrMission>
            </Grid>
          </Grid>
        </VisionAndMission>
      </SectionWrapper>
    </Container>
    <Container>
      <SectionWrapper>
        <PeopleSections>
          <SectionTitle>Our People</SectionTitle>
          <hr />
          <PeopleSectionsContent>
            <PeopleSection>
              <SectionSubtitle>Management Board</SectionSubtitle>
              <Grid container spacing={3}>
                {team.map((member) => (
                  <MemberCard item xs={6} sm={4} md={3} key={member.id}>
                    <MemberImage></MemberImage>
                    <MemberInfo>
                      <MemberName>
                        <strong>{member.name}</strong>
                      </MemberName>
                      <MemberRole>{member.position}</MemberRole>
                    </MemberInfo>
                  </MemberCard>
                ))}
              </Grid>
            </PeopleSection>
          </PeopleSectionsContent>
        </PeopleSections>
      </SectionWrapper>
    </Container>
  </div>
);

export default withHeaderFooterHOC(About);
