import React from "react";
import { ApproveStatusWrapper } from "./style";
import { ReactComponent as CheckIcon } from "../../assets/checkIcon.svg";
import { ReactComponent as PendingIcon } from "../../assets/loading.svg";

const svgProps = {
  width: 34,
  height: 34,
  fill: 'white'
}

const ApproveStatus = ({ status, ...rest }) => {
  const isPending = status === 'pending';

  return (
    <ApproveStatusWrapper pending={isPending.toString()} {...rest}>
      {
        isPending ? <PendingIcon {...svgProps}/> : <CheckIcon {...svgProps}/>
      }
    </ApproveStatusWrapper>
  );
};

export default ApproveStatus;
