import React from "react";
import { Wrapper } from "./style";

const EmptyTableBody = ({ children }) => (
    <div style={{ minHeight: "50vh", display: "flex", alignItems: "center" }}>
      <Wrapper>
        {children}
      </Wrapper>
    </div>
);

export default EmptyTableBody;
