import React, { useMemo } from "react";
import { GeneralEditor, WordCountText } from "../pages/Admin/Procurement/Activitiies/SpecificProcurementNotice/style";
import { ErrorContainer } from "./Forms/Common/style";

const getWordCount = (editorState) => {
  const plainText = editorState?.getCurrentContent()?.getPlainText("");
  const regex = /(?:\r\n|\r|\n)/g; // new line, carriage return, line feed
  const cleanString = plainText?.replace(regex, " ").trim(); // replace above characters w/ space
  const wordArray = cleanString?.match(/\S+/g); // matches words according to whitespace
  return wordArray ? wordArray?.length : 0;
};

const DraftEditor = ({ isDisabled, editorState, editorError, setEditorState, ...props }) => {
  const wordCount = useMemo(() => {
    return getWordCount(editorState);
  }, [editorState]);
  const onRenderError = (error) => <ErrorContainer style={{ paddingLeft: 8 }}>{error && error.message}</ErrorContainer>;

  return (
    <>
      <GeneralEditor
        editorState={editorState}
        toolbarClassName="toolbarClassName"
        wrapperClassName="general-editor-container-style"
        editorClassName="general-editor-style"
        onEditorStateChange={setEditorState}
        wrapperStyle={{ border: editorError === true && "1px solid red" }}
        readOnly={isDisabled}
        {...props}
      />
      <WordCountText>
        {editorError === true ? (
          onRenderError({
            message: "Description is required",
          })
        ) : (
          <> Word count: {`${wordCount}`}</>
        )}
      </WordCountText>
    </>
  );
};

export default DraftEditor;
