import React, { useContext } from "react";
import Box from "@material-ui/core/Box";
import { useQuery } from "react-query";

import { useParams } from "react-router-dom";
import { PageContainer, PageTitle } from "../../Common/style";
import Breadcrumb from "../../Common/PageElements/Breadcrumb";
import BidInfoCard from "./NewBidInfo";
import vendorsAPI from "../../../redux/api/vendorsAPI";
import withVendorDashboard from "../HOC/withVendorDashboard";
import ToastContext from "../../../util/toastContext";
import Skeleton from "@material-ui/lab/Skeleton";
import procurementAPI from "../../../redux/api/procurementAPI";
import BidDocuments from "./BidDocuments";
import BiddersTable from "./BiddersTable";
import BidStatus from "./BidStatus";
import BidReport from "./BidReport";

const VendorBidDetails = () => {
  const { showAlert } = useContext(ToastContext);
  const { id } = useParams();

  const onError = (error) => {
    showAlert({
      severity: "error",
      message: error.message,
      durationInMs: 3000,
    });
  };

  const { status: bidQueryStatus, data: bid = {}, isLoading: isLoadingBid } = useQuery(
    ["getSingleBid", id],
    vendorsAPI?.getSingleBid,
    {
      enabled: !!id,
      onError,
    }
  );

  const { status: procurementQueryStatus, data: procurementPlan = {} } = useQuery(
    ["getProcurementPlan", bid?.tenderId],
    procurementAPI.getProcurementPlanAxios,
    {
      enabled: !!bid?.tenderId,
      onError,
    }
  );

  const BreadcrumbLinks = [
    { url: "/vendor/bids", title: "Bids", forwardSlash: true },
    { url: "/vendor/bids", title: `${bid.packageNumber}`, forwardSlash: false },
  ];

  return (
    <PageContainer>
      <Box display="flex" justifyContent="space-between" marginBottom="35px">
        {!isLoadingBid && (
          <Box>
            <PageTitle>{bid?.packageNumber}</PageTitle>
            <Breadcrumb values={BreadcrumbLinks} />
          </Box>
        )}

        {isLoadingBid && <Skeleton width="200px" height="100px" />}
      </Box>

      {["PROCESSING", "SHORTLISTED"].includes(bid?.bidStatus) && <BidStatus status={bid?.bidStatus} />}

      <BidInfoCard bidQueryStatus={bidQueryStatus} bid={bid} tenderId={id} />

      {["NOTSTARTED", "PROCESSING"].includes(bid?.bidStatus) && (
        <BidDocuments
          procurementQueryStatus={procurementQueryStatus}
          procurementPlan={procurementPlan}
          bidQueryStatus={bidQueryStatus}
          bid={bid}
          tenderId={id}
        />
      )}
      {bidQueryStatus === "success" ? (
        ["SHORTLISTED", "NOTRECOMMENDED", "EVALUATED", "REJECTED", "RECOMMENDED"].includes(bid?.bidStatus) ? (
          <BidReport tenderId={id} bid={bid} />
        ) : (
          <BiddersTable procurementId={id} />
        )
      ) : null}
    </PageContainer>
  );
};

export default withVendorDashboard(VendorBidDetails);
