import React, { useState } from "react";
import { Tab } from "@material-ui/core";

import { CardContentWrapper, PageTabs } from "../../../../util/theme";
import { PlanCardContainer, TabContainer } from "../../Procurement/Activitiies/SpecificProcurementNotice/style";
import ContractDetailTab from "./ContractDetailTab";
import TenderDetailsTab from "./TenderDetailsTab";
import AwardedCompanyTab from "./AwardedCompanyTab";
import ContactBiddersTab from "./ContactBiddersTab";
import EvaluatorsTab from "./EvaluatorsTab";

const ContractDetailsCardTable = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <CardContentWrapper>
      <PlanCardContainer>
        <TabContainer>
          <PageTabs value={value} onChange={handleChange}>
            <Tab label="Contract Details" activetab={(value === 0).toString()} />
            <Tab label="Tender Details" activetab={(value === 1).toString()} />
            <Tab label="Awarded Company" activetab={(value === 2).toString()} />
            <Tab label="Bidders" activetab={(value === 3).toString()} />
            <Tab label="Evaluators" activetab={(value === 4).toString()} />
          </PageTabs>
        </TabContainer>
        {value === 0 && <ContractDetailTab />}
        {value === 1 && <TenderDetailsTab />}
        {value === 2 && <AwardedCompanyTab />}
        {value === 3 && (
          <div style={{ marginTop: 20 }}>
            <ContactBiddersTab />
          </div>
        )}
        {value === 4 && (
          <div style={{ marginTop: 20 }}>
            <EvaluatorsTab />
          </div>
        )}
      </PlanCardContainer>
    </CardContentWrapper>
  );
};

export default ContractDetailsCardTable;
