import styled from "styled-components";
import { Box } from "@material-ui/core";

export const DocumentUploadContainer = styled(Box)`
  display: grid;
  grid-template-columns: auto auto;

  > div:first-child {
    grid-column: 1/ span 3;
  }

  > div {
    margin-right: ${props => props.noMargin ? '0' : '20px'};
    margin-left: 0;
    margin-top: 0;
  }
`;

export const GeneralCardContentWrapper = styled.div``;

