import React from "react";
import { Link } from "react-router-dom";

import withHeaderFooterHOC from "../HOC/withHeaderFooterHOC";
import Hero from "../../../components/Hero";
import NewsSection from "./NewsUpdateSection";
import { VendorRegistrationButton } from "../Landing/style";

const NewsAndBulletins = () => {
  const linkStyle = { color: "#fff", textDecoration: "none" };
  return (
    <div>
      <Hero
        title="News & Bulletins"
        desc={
          <>
            <div>
              Are you interested in bidding for a contract for your organization or for an individual? Click the link below to
              register
            </div>
            <div style={{ marginTop: 20 }}>
              <VendorRegistrationButton>
                <Link to="/create-account" style={linkStyle}>
                  Vendor Registration
                </Link>
              </VendorRegistrationButton>
            </div>
          </>
        }
      />
      <NewsSection />
    </div>
  );
};

export default withHeaderFooterHOC(NewsAndBulletins);
