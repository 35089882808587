import styled from "styled-components";
import { FormTitle, theme } from "../../util/theme";
import { CancelButton } from "../Forms/Common/style";

export const DashboardDrawerWrapper = styled.div`
  width: 609px;

  .MuiTab-root {
    min-width: 80px;
    text-transform: none;
  }

  .MuiTabs-root {
    padding: 0 12px;
  }

  .MuiTabs-indicator {
    background-color: ${theme.color.primary};
  }

  .MuiTabs-Fixed {
    padding: 0 7px !important;
  }
`;

export const DashboardFormTitle = styled(FormTitle)`
  font-size: 24px !important;
  font-weight: bold !important;
  padding-top: 25.5px !important;
  padding-left: 25.5px !important;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 25.5px !important;
  margin-bottom: 31px;
`;

export const DashboardCancelButton = styled(CancelButton)`
  box-sizing: border-box;
  height: 41px;
  width: 94px;
  border: 1px solid #637381 !important;
  background-color: #ffffff !important;
  color: ${theme.color.secondary} !important;
`;

export const TabDivider = styled.hr`
  border: 1px solid #dfe3e8;
  margin-bottom: 10px;
`;
