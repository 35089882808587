export const truncateValue = (value) => {
  if (value < 1000001) return `${value / 1000000}M`;
  if (value < 1000000001) return `${value / 1000000000}B`;
  if (value < 1000000000001) return `${value / 1000000000}T`;
  if (value < 1000000000000001) return `${value / 1000000000000}MT`;
};

export const truncateString = (str) => String(str).charAt(String(str).length - 1);

export const separateNumByComma = (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

/**
 * Takes a number and formats it, appending a unit according to it's size
 * @param {Number} num The number to format
 * @param {Number} digits The number of digits to allow after the decimal. Default is 3.
 * @example formatNumber(1234, 1) => 1.2k
 * @example formatNumber(299792458, 1)) => 299.8M
 * @see https://stackoverflow.com/a/9462382
 */
export function formatNumber(num, digits = 3) {
  var si = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "B" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "Qd" },
    { value: 1e18, symbol: "Qt" },
  ];
  // used to remove trailing zeroes
  var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
}