import { Grid } from "@material-ui/core";
import React from "react";
import DocumentDownload from "../../../../components/DocumentDownload";

const ProcurementDocSection = ({ documents }) => (
  <Grid container spacing={2}>
    {documents.length === 0 && <div style={{ textAlign: "center", width: "100%" }}>No documents available</div>}
    {documents.map((document) => (
      <Grid item xs={4} key={document.name}>
        <DocumentDownload file={document} />
      </Grid>
    ))}
  </Grid>
);

export default ProcurementDocSection;
