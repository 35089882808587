import React from "react";
import { CardContentWrapper, CardStatus, DefaultWidthCardWrapper, StatusContainer } from "../../util/theme";
import EditIcon from "../../assets/edit.svg";
import {
  CardInfoText,
  CardInfoTitle,
  CardLabelWrapper,
  DescriptionSection,
  PermissionCheckBox,
  PermissionList,
  PermissionListItemContainer,
  PermissionName
} from "./style";

const PermissionListItem = (pendingInfo) => (
  <PermissionListItemContainer key={`${pendingInfo.name}_${pendingInfo.id}`}>
    <PermissionCheckBox name={`${pendingInfo.name}_${pendingInfo.id}`} />
    <div>
      <PermissionName>{pendingInfo.name}</PermissionName>
    </div>
  </PermissionListItemContainer>
);

const SimpleCard = ({
  title, description, statuses, descriptionTitle, listData, showIcon, children,
}) => (
  <DefaultWidthCardWrapper>
    <CardContentWrapper>
      <DescriptionSection>
        <CardLabelWrapper>{title}</CardLabelWrapper>
        <CardInfoTitle>{descriptionTitle}</CardInfoTitle>
        <CardInfoText>{description}</CardInfoText>
        {children}
      </DescriptionSection>
      {showIcon ? <img src={EditIcon} height="18" width="18" alt="icon" /> : ""}
    </CardContentWrapper>
    {listData ? <PermissionList>{listData.map((elem) => PermissionListItem(elem))}</PermissionList> : ""}
    {statuses ? (
      <CardStatus>
        {statuses.map(({ text, bgColor, textColor }) => (
          <StatusContainer key={text} bgcolor={bgColor} textcolor={textColor}>
            {text}
          </StatusContainer>
        ))}{" "}
      </CardStatus>
    ) : (
      ""
    )}
  </DefaultWidthCardWrapper>
);

export default SimpleCard;
