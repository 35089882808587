import React from "react";
import { IconButton, Grid } from "@material-ui/core";
import { HighlightOffOutlined } from "@material-ui/icons";

import { SelectedVendorWrapper } from "./style";

export default function SelectedVendor({ vendor, onChange, showCancelBtn }) {
  return (
    <Grid item xs={12} sm={9}>
      <SelectedVendorWrapper>
        <div>
          <h5>{vendor?.companyName}</h5>
          <span>{vendor?.registrationClass}</span>
        </div>
        {showCancelBtn && (
          <IconButton aria-label="remove" onClick={() => onChange(vendor)}>
            <HighlightOffOutlined style={{ color: "#EB5757" }} />
          </IconButton>
        )}
      </SelectedVendorWrapper>
    </Grid>
  );
}
