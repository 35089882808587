import styled from "styled-components";
import ButtonBase from "@material-ui/core/ButtonBase";
import MaterialDrawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import { withStyles } from "@material-ui/styles";
import { SaveButton as BaseSaveButton } from "../../../../../components/Forms/Common/style";

export const Drawer = withStyles(() => ({
  paper: {
    width: 485,
    padding: 25,
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
  },
}))(MaterialDrawer);

export const DrawerTitle = styled.p`
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  color: #3f5673;

  margin-bottom: 30px;
  flex: 0;
`;

export const DrawerForm = styled.form`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const DrawerFormRow = styled.div`
  margin-bottom: 20px;
`;

export const DrawerActions = styled(Box).attrs(() => ({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  flex: 0,
  marginTop: "auto",
}))``;

export const CancelButton = withStyles(() => ({
  root: {
    border: "1px solid #8E9FAC",
    borderRadius: 3,
    padding: "10px 25px",
    fontWeight: 600,
    fontSize: 14,
    lineHeight: "20px",
    textAlign: "center",
    color: "#8E9FAC",
  },
  disabled: {
    opacity: 0.75,
    cursor: "not-allowed",
  },
}))(ButtonBase);

export const SaveButton = styled(BaseSaveButton)`
  &.MuiButtonBase-root {
    min-width: 120px;
    border-radius: 3px;
  }
`;
