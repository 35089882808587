import React, { useState, useRef, useEffect } from "react";
import { Grid } from "@material-ui/core";

import { CustomInputBase, CustomSearch, IconWrapper, Nav } from "./style";
import { ReactComponent as SearchIcon } from "../../assets/search.svg";
import AdminNavNotifications from "./AdminNavNotifications/AdminNavNotifications";
import UserProfileTab from "./UserProfileTab";
import SearchResultPopper from "./SearchResultPopper";
import {useRoles} from "../../hooks/useUserDetails";
import {Roles} from "../AccessControl/UserRoles"


const Navbar = () => {
  const { role } = useRoles()
  const [openSearchResultPopper, setOpenSearchResultPopper] = useState(false);
  const [searchText, setSearchText] = useState("");
  const notificationAnchorRef = useRef(null);

  useEffect(() => {
    if (searchText.length > 0) {    
      setOpenSearchResultPopper(true);
    }
  }, [searchText]);

  return (
    <Nav container alignItems="center">
      <Grid item xs={6}>
      {role !== Roles.VENDOR && (
        <div>
        <CustomSearch ref={notificationAnchorRef}>
          <IconWrapper>
            <SearchIcon />
          </IconWrapper>
          <CustomInputBase
            value={searchText}
            placeholder="Search"
            onChange={(event) => setSearchText(event.target.value)}
            inputProps={{ "aria-label": "search admin dashboard" }}
          />
        </CustomSearch>
        <SearchResultPopper
          notificationAnchorRef={notificationAnchorRef}
          openSearchResultPopper={openSearchResultPopper}
          setOpenSearchResultPopper={setOpenSearchResultPopper}
          searchText ={searchText}
        /></div>)}
      </Grid>

      <Grid item xs={6} container justify="flex-end" alignItems="center">
        <div style={{ marginRight: "22px" }}>
          <AdminNavNotifications />
        </div>
        <UserProfileTab />
      </Grid>
    </Nav>
  );
};

export default Navbar;
