import { Chip, IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import React from "react";
import { useHistory } from "react-router-dom";
import EmptyTableBody from "../../../../components/EmptyTableBody";
import { EmptyTableSubTitle } from "../../../../components/EmptyTableBody/style";
import { StyledTableCell } from "../../../../components/SearchTable";
import { formatCurrency } from "../../../../util/formatCurrency";
import { HoverTableStyle } from "../../../../util/theme";

const TableBody = ({ generalPlans = [], deletePlan, showDelete }) => {
  const history = useHistory();

  const handleClick = (e, plan) => {
    e.stopPropagation();
    history.push(`/admin/procurement/plans/${plan.id}`);
    // if (!!plan && plan.status !== "In Progress") {
    //   return history.push(`/admin/procurement/plans/notice/${plan.id}`);
    // } else {
    //   return history.push(`/admin/procurement/plans/${plan.id}`);
    // }
  };

  const pickStatus = (status) => {
    switch (status) {
      case "Completed":
        return <Chip label={status} style={{ height: "24px", color: "#414F3E", background: "#BBE5B3" }} />;
      case "In Progress":
        return <Chip label={status} style={{ height: "24px", color: "#FF8D26", background: "#FCEBDB" }} />;
      case "Under Review":
        return <Chip label={status} style={{ height: "24px", color: "#FF8D26", background: "#FCEBDB" }} />;
      default:
        return <Chip label={status} style={{ width: "100px", height: "24px", color: "#C9D4DB", background: "#414F3E" }} />;
    }
  };

  return generalPlans.length ? (
    generalPlans.map((row, index) => (
      <HoverTableStyle key={`${row.id}_${index}`} onClick={(e) => handleClick(e, row)}>
        <StyledTableCell>
          <div>
            <span>{row.name}</span>
          </div>
        </StyledTableCell>
        <StyledTableCell>{row.ministry?.code}</StyledTableCell>
        <StyledTableCell>{pickStatus(row.status)}</StyledTableCell>
        <StyledTableCell>{row.tenders}</StyledTableCell>
        <StyledTableCell>N{formatCurrency(row.amount)}</StyledTableCell>
        {showDelete && (
          <StyledTableCell>
            {(row.status === "Draft" || row.status === "Under Review")  && (
              <IconButton aria-label="Delete procurement" onClick={(event) => deletePlan(event, row)}>
                <DeleteIcon
                  style={{
                    color: "#ccc",
                    cursor: "pointer",
                  }}
                />
              </IconButton>
            )}
          </StyledTableCell>
        )}
      </HoverTableStyle>
    ))
  ) : (
    <EmptyTableBody>
      <EmptyTableSubTitle>Get started adding your ministry</EmptyTableSubTitle>
      <span style={{ width: "75%", marginBottom: "12px" }}>
        Add and update your ministry via CSV upload or manually add them one at a time
      </span>
    </EmptyTableBody>
  );
};

export default TableBody;
