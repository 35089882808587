import React, { useContext, useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";
import AdminCard from "../../../components/AdminCard";
import AdminSearchFilter from "../../../components/AdminSearchFilter";
import Dropdown from "../../../components/DropdownField";
import {
  CuteModal,
  ModalWrapper,
  ModalHeader,
  ModalCloseBtn,
  ModalRejectBtn,
} from "../../Vendor/Procurement/style";
import SearchTable from "../../../components/SearchTable";
import { Roles } from "../../../components/AccessControl/UserRoles";
import { getVendorsSummary } from "../../../redux/reducer/adminReducer";
import { CardWrapper, theme } from "../../../util/theme";
import withLoader from "../../Common/HOC/withLoader";
import { ContentWrapper, TitleContainer } from "../Administration/Staff/style";
import withAdminDashboard from "../HOC/withAdminDashboard";
import { VendorTitlePage, VendorViewAllTextContainer, VendorViewAllText } from "./style";
import VendorTable from "./VendorsTable";
import { useMutation, useQuery } from "react-query";
import adminAPI from "../../../redux/api/adminAPI";
import ToastContext from "../../../util/toastContext";
import { ContractDropDownField, ContractInputField } from "../Contracts/style";
import Breadcrumb from "../../Common/PageElements/Breadcrumb";
import { MainText } from "../Procurement/GeneralPlanDetails/style";
import vendorsAPI from "../../../redux/api/vendorsAPI";
import { useRoles } from "../../../hooks/useUserDetails";


const VendorCardSummary = () => {
  const { vendorsSummary } = useSelector((state) => state.admin);
  const {
    color: {
      tags: { approved, rejected, pending, blacklist },
    },
  } = theme;


  return (
    <AdminCard
      title={"Total Vendors"}
      count={(vendorsSummary && vendorsSummary.total) || 0}
      description={""}
      statuses={[
        { text: `${vendorsSummary?.activeTotal} Approved`, bgColor: approved.background, textColor: approved.text },
        {
          text: `${vendorsSummary?.pendingTotal} Pending`,
          bgColor: pending.background,
          textColor: pending.text,
        },
        {
          text: `${vendorsSummary?.rejectedTotal} Rejected`,
          bgColor: rejected.background,
          textColor: rejected.text,
        },
        {
          text: `${vendorsSummary?.blacklistedTotal} Blacklisted`,
          bgColor: blacklist.background,
          textColor: blacklist.text,
        },
      ]}
    />
  );
};

const Vendors = () => {
  const [filters, setFilters] = useState({
    Search: null,
    SearchBy: "RegisterPlanTitle",
    Status: null,
  });
  const [switchPendingVendor, setSwitchPendingVendor] = useState(false);
  const [vendorToRemove, setVendorToRemove] = useState(null);

  const { role } = useRoles();
  const isDSPPC = Roles.DSPPC === role;


  const updateFilters = (key) => (value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value === "All" ? null : value,
    }));
  };

  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
  });

  const { data = {}, isLoading, error = {}, isError, refetch } = useQuery({
    queryFn: adminAPI.getVendors,
    queryKey: [
      "vendors",
      {
        pageSize: pagination.pageSize,
        pageNumber: pagination.pageNumber,
        Search: filters.Search,
        SearchBy: filters.SearchBy,
        Status: filters.Status,
      },
    ],
  });


  const [deleteVendor, deleteVendorQueryResponse] = useMutation(vendorsAPI.deleteVendor, {
    onSuccess: () => {
      showAlert({
        severity: "success",
        message: "Vendor was deleted successfully",
      });
      refetch()
      setVendorToRemove(null);
    },
    onError: (error) => {
      showAlert({
        severity: "error",
        message: error.message,
      });
    },
  });

  const handleDeleteVendor = (e, id) => {
   e.stopPropagation()
   deleteVendor(id)
  }

  const updatePagination = (key) => (value) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      [key]: value,
    }));
  };

  const { showAlert } = useContext(ToastContext);

  useEffect(() => {
    if (isError) {
      showAlert({
        severity: "error",
        message: error?.message,
        durationInMs: 3000,
      });
    }
    //eslint-disable-next-line
  }, [showAlert]);
  const GeneralPlanLinks = [{ url: "/admin/dashboard/overview", title: "Home" }];

  return (
    <ContentWrapper>
      <TitleContainer>
        <Box>
          <VendorTitlePage>Vendors</VendorTitlePage>
          {/* <GeneralBreadCrumbLabel>Administration/Vendors</GeneralBreadCrumbLabel> */}
          <Breadcrumb values={GeneralPlanLinks} />
        </Box>
      </TitleContainer>
      <CardWrapper>
        {withLoader(VendorCardSummary)({
          apis: [getVendorsSummary()],
          conditioners: ["vendorsSummary"],
          stateName: "admin",
        })}
      </CardWrapper>

      <VendorViewAllTextContainer>
        <VendorViewAllText
          onClick={() => {
            !switchPendingVendor &&
              setFilters((prevFilters) => ({
                ...prevFilters,
                Status: 1,
              }));
            switchPendingVendor &&
              setFilters((prevFilters) => ({
                ...prevFilters,
                Status: null,
              }));
            setSwitchPendingVendor(!switchPendingVendor);
          }}
        >
          {switchPendingVendor ? "Back to vendor List" : "View all pending"}
        </VendorViewAllText>
      </VendorViewAllTextContainer>

      <AdminSearchFilter normalSearch={true}>
        <div style={{ display: "flex" }}>
          <ContractDropDownField
            name="search-by"
            label="Search by"
            style={{ width: "100%" }}
            values={[
              { label: "Registration Plan", value: "RegisterPlanTitle" },
              { label: "Company Name", value: "CompanyName" },
            ]}
            onChange={updateFilters("SearchBy")}
          />
          <ContractInputField
            type="text"
            label="Search"
            style={{ width: "100%" }}
            value={filters.Search}
            setValue={updateFilters("Search")}
          />
          <Dropdown
            name="approval"
            label="Approval Status"
            values={[
              { label: "All", value: null },
              { label: "Pending", value: 1 },
              { label: "Approved", value: 2 },
              { label: "Rejected", value: 3 },
              { label: "Blacklisted", value: 4 },
            ]}
            onChange={updateFilters("Status")}
            style={{ width: "100%" }}
            required
          />
        </div>
      </AdminSearchFilter>
      <SearchTable
        rows={data.data}
        columns={["name", "status", "reg. category", "location", "url", isDSPPC && ""]}
        page={pagination.pageNumber}
        setPage={updatePagination("pageNumber")}
        rowsPerPage={pagination.pageSize}
        setRowsPerPage={updatePagination("pageSize")}
        loading={isLoading}
        totalNumberOfPages={data?.pagination?.totalEntries}
      >
        <VendorTable vendors={data.data} setVendorToRemove={setVendorToRemove} isDSPPC={isDSPPC}/>
      </SearchTable>
      <CuteModal open={Boolean(vendorToRemove)}>
        <ModalWrapper>
          <ModalHeader>Delete vendor</ModalHeader>
          <MainText mt="30px">{`Proceed to Delete this vendor. You cannot undo this action`}</MainText>
          <MainText mt="30px"></MainText>
          <Box display="flex" justifyContent="flex-start">
            <ModalRejectBtn
              disabled={deleteVendorQueryResponse.isLoading}
              onClick={(event) => {
                handleDeleteVendor(event, vendorToRemove.id);
              }}
            >
              {deleteVendorQueryResponse.isLoading ? "Please wait..." : "Delete"}
            </ModalRejectBtn>
            <ModalCloseBtn onClick={() => setVendorToRemove(null)}>Close</ModalCloseBtn>
          </Box>
        </ModalWrapper>
      </CuteModal>
    </ContentWrapper>
  );
};

export default withAdminDashboard(Vendors);
