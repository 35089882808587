import React, { useContext } from "react";
import { Drawer, Box } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useQuery } from "react-query";

import { Title } from "../../pages/Vendor/BidDetails/style";
import { CloseButton } from "../../pages/Vendor/BidDetails/NewBidInfo/DocumentCategories/style";
import usersAPI from "../../redux/api/usersAPI";
import VendorProfile from "./VendorProfile";
import ToastContext from "../../util/toastContext";
import StaffProfile from "./StaffProfile";

export default function ProfileDrawer({ onClose, open, user }) {
  const { paper } = useStyles();
  const { showAlert } = useContext(ToastContext);

  const { data: userData = {} } = useQuery("getUserInfo", usersAPI.getUser, {
    enabled: !Boolean(user) && open,
    onError: (error) => {
      showAlert({
        message: error.message,
        severity: "error",
      });
    },
  });

  return (
    <Drawer style={{ width: 500 }} anchor="right" open={open} onClose={onClose} classes={{ paper }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Title>Profile</Title>
        <CloseButton onClick={onClose}>Close</CloseButton>
      </Box>
      {!!userData.role || !!user ? (
        userData.role?.toLowerCase() === "vendor" ? (
          <VendorProfile vendor={Boolean(user) ? user : userData} />
        ) : (
          <StaffProfile staff={Boolean(user) ? user : userData} />
        )
      ) : null}
    </Drawer>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    width: 400,
    padding: theme.spacing(2, 3),
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
  },
}));
