import React from "react";
import styled, { css } from "styled-components";
import withStyles from "@material-ui/styles/withStyles";
import Box from "@material-ui/core/Box";
import DetailItem from "../../../../components/DetailItem";
import MaterialRadio from "@material-ui/core/Radio";
import MaterialFormControlLabel from "@material-ui/core/FormControlLabel";

export const SectionContainer = styled.div`
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 #cbd4da;
  padding: 20px;
  margin-bottom: 30px;
  position: relative;
`;

export const Title = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => (props.secondary ? "#3F5673" : "#1e2b37")};
  margin-bottom: ${(props) => props.bottomSpacing ?? 0};
`;

export const SectionHeader = styled(Box).attrs((props) => ({
  display: "flex",
  justifyContent: "space-between",
}))``;

export const SectionDateInfo = styled.div`
  background: #f2f2f2;
  border: 1px solid #c9d4db;
  border-radius: 0px 0px 0px 3px;
  padding: 20px 24px;

  display: flex;
  align-items: center;

  ${(props) =>
    props.offsetMargin
      ? css`
          margin-top: -20px;
          margin-right: -20px;
        `
      : null}

  .date {
    display: flex;
    flex-direction: column;
  }

  .expiry-info {
    margin-left: 24px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    color: #eb5757;
  }
`;

export const NoteDetail = styled(DetailItem)`
  margin-bottom: 40px;
  .detail-item-label {
    margin-bottom: 10px;
  }
  .detail-item-text {
    font-weight: normal;
  }
`;

export const Radio = withStyles({
  root: {
    color: (props) => (props.disabled ? "#BDBDBD" : "#C4CDD5"),
    "&$checked": {
      color: (props) => (props.disabled ? "#BDBDBD" : "#2D9CDB"),
    },
  },
  checked: {
    color: (props) => (props.disabled ? "#BDBDBD" : "#2D9CDB"),
  },
})((props) => <MaterialRadio color="default" {...props} />);

export const FormControlLabel = withStyles({
  label: {
    color: (props) => (props.active ? "#2D9CDB" : "#5F7483"),
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: 16,
    lineHeight: 1.375,
  },
  disabled: {
    color: "#BDBDBD",
  },
})(MaterialFormControlLabel);
