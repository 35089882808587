import React from "react";
import NotificationsPageComponent from "../../../components/NotificationsPageComponent/NotificationsPageComponent";
import withAdminDashboard from "../../Admin/HOC/withAdminDashboard";

const VendorNotifications = () => {
  return (
    <NotificationsPageComponent type='vendor'/>
  );
};


export default withAdminDashboard(VendorNotifications);
