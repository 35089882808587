import { Typography } from "@material-ui/core";
import styled from "styled-components";

export const CompanySubtitle = styled(Typography)`
  font-style: normal;
  font-weight: normal;
  font-size: 13px !important;
  line-height: 16px !important;
  color: #5f7483;
`;

export const CompanyCardTitle = styled(Typography)`
  font-style: normal;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 22px;
  color: #3f5673;
  margin-bottom: 10px !important;
`;

export const CompanyCardLabel = styled(Typography)`
  font-style: normal;
  font-weight: bold !important;
  font-size: 16px !important;
  line-height: 22px;
  color: #3f5673;
`;

export const CompanyCardValue = styled(CompanyCardLabel)`
  font-weight: normal !important;
  margin-bottom: 10px !important;
`;

export const AllFileText = styled(Typography)`
  background: rgba(0, 176, 241, 0.0715144);
  border-radius: 3px;
  padding: 15px !important;
  margin-top: 20px !important;
`;

export const PaymentReceiptText = styled(Typography)`
  font-style: normal;
  font-weight: normal;
  font-size: 13px !important;
  line-height: 16px;
  color: #5f7483;
  margin-bottom: 10px !important;
`;