import styled from "styled-components";

export const CheckLinkWrapper = styled.div`
  width: 394px;
  margin-bottom: 20px;
  border-radius: 3px;
  height: 97px;
  background-color: rgba(3, 173, 235, 0.07);
  color: #919eab;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CheckJunk = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 18px !important;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
`;
