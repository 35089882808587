import React, { useState } from 'react'
import { Modal, Box, Grid } from '@material-ui/core';
import { InputField } from '../../../../../../components';
import  DropdownField  from '../../../../../../components/DropdownField'
import { makeStyles } from '@material-ui/styles';
import { ModalCancelButton, ModalActionButton, ModalBody, TitleText, Close, FormWrapper} from './style';
import { useMutation } from 'react-query';
import contractsAPI from '../../../../../../redux/api/contractAPI';
import { useParams } from "react-router-dom";
import { InputWrapper } from '../../../../../../components/InputField/style';

const useStyles = makeStyles({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

const AddContractModal = ({ open, setModal, showAlert, setContracts }) => {
  const [createNewContract, { isLoading }] = useMutation(contractsAPI.createContract, {
    onSuccess: () => {
      showAlert({
        severity: "success",
        message: "Contract Created Successfully",
      });
    },
    onError: () => {
      showAlert({ severity: "error", message: "Something went wrong" });
    },
  });
  const { procurementId } = useParams();

    const onCreateContract = async () => {
      await createNewContract({...formInput, procurementId});
      setModal(false);
      setContracts([formInput]);
    }
    const [formInput, setFormInput] = useState({
      description: "",
      evaluationCurrency: "",
      duration: "",
      type: "",
      });

      const updateFormValue = (input) => (value) => {
        setFormInput({
          ...formInput,
          [input]: value,
        });
      };
      const updateInputValue = (input) => (e) => {
        setFormInput({
          ...formInput,
          [input]: e.target.value,
        });
      };
    const style = useStyles();
    const durationType = ["Day", "Week", "Month", "Year"];
    const currency = ["Naira", "Dollar", "Euro"];
    return (
        <Modal open={open} className={style.modal}>
            <ModalBody>
            <Box display="flex" justifyContent="space-between">
                <TitleText>Create Contract</TitleText>
                <Close onClick={() => setModal(false)} />
            </Box>
            <FormWrapper>
                <InputField
                label="Description"
                name="description"
                value={formInput.description}
                onChange={updateInputValue("description")}
                multiline
                rowsMax={4}
                style={{background: "#ffffff" }}
               />
            </FormWrapper>
            <InputWrapper>
         
            <Grid container justifyContent="space-around" > 
            <Grid item xs={4}>
                <DropdownField
                label="Duration"
                name="duration"
                value={formInput.duration}
                onChange={updateFormValue("duration")}
                style={{ maxWidth: "150px", background: "#ffffff" }}
                values={[...Array(24)].map((a, i) => ({ label: i + 1, value: i + 1 }))}
              />
            </Grid>
              <Grid item xs={4}>
              <DropdownField
                name="type"
                label="Type"
                value={formInput.type}
                onChange={updateFormValue("type")}
                style={{ maxWidth: "150px", background: "#ffffff" }}
                values={durationType.map((d) => ({ label: d, value: d }))}
              />
              </Grid>
               <Grid item xs={4}>
               <DropdownField
                name="currency"
                label="Evaluated currency"
                value={formInput.evaluationCurrency}
                onChange={updateFormValue("evaluationCurrency")}
                style={{ maxWidth: "200px", background: "#ffffff" }}
                values={currency.map((c) => ({ label: c, value: c }))}
              />
               </Grid>
               </Grid>
               
            <Box display="flex" justifyContent="space-between" alignItems="center" marginTop="30px">
                <ModalCancelButton
                onClick={() => setModal(false)}
                >Cancel</ModalCancelButton>
                <ModalActionButton onClick={onCreateContract}>
                  {isLoading ? "Please wait..." : "Create Contract"}
                </ModalActionButton>
            </Box>

            </InputWrapper>
        </ModalBody>
        </Modal>
    )
}

export default AddContractModal;
