import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { coreCompetencies, organizationTypes } from "../../../components/Forms/GeneralCompanyDetails/mock";
import { BusinessAddressContainer, StatusButton, RCText, TextLabel, ValueText } from "./style";
import { FlexContainer } from "../../Homepage/Landing/style";
import { Box, Grid } from "@material-ui/core";
import { SaveButton } from "../../../components/Forms/Common/style";
import { useHistory, useParams } from "react-router-dom";

const VendorCompanyDetails = () => {
  const { companyDetails } = useSelector((state) => state.vendor);
  const { coreCompetency, organizationType } = Object.keys(companyDetails).length && companyDetails;
  const history = useHistory();
  const { vendorId } = useParams();

  return (
    <Box>
      <Grid container>
        <Grid item xs={2}>
          <Box display="flex" flexDirection="column" justifyContent="center" height="100%">
            <Box>
              <StatusButton status={companyDetails.status}>{companyDetails.status}</StatusButton>
              <RCText>{companyDetails.cacRegistrationNumber}</RCText>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={9}>
          <FlexContainer>
            <div>
              <TextLabel>Area of Competence</TextLabel>
              <ValueText>{coreCompetency && coreCompetencies.find((c) => Number(c.value) === coreCompetency).label}</ValueText>
            </div>
            <div>
              <TextLabel>Type of Organization</TextLabel>
              <ValueText>
                {organizationType && organizationTypes.find((o) => Number(o.value) === organizationType).label}
              </ValueText>
            </div>
            <div>
              <TextLabel>Date of Registration</TextLabel>
              <ValueText>
                {(companyDetails?.incorporationDate && moment(companyDetails?.incorporationDate).format("Do MMM, YYYY")) || "N/A"}
              </ValueText>
              {/* <ValueText>{new Date(companyDetails.incorporationDate).toLocaleDateString()}</ValueText> */}
            </div>
            <div>
              <TextLabel>Capital Contribution By Partners</TextLabel>
              <ValueText>NGN {companyDetails.authorizedShareCapital}</ValueText>
            </div>
          </FlexContainer>
          <Box display="flex" justifyContent="space-between" mt="20px">
            <BusinessAddressContainer>
              <TextLabel>Business Address</TextLabel>

              <ValueText>
                {companyDetails.correspondenceAddress1}, {companyDetails.correspondenceAddress2},{" "}
                {companyDetails.correspondenceCity}, {companyDetails.correspondenceState} State,{" "}
                {companyDetails.correspondenceCountry}
              </ValueText>
            </BusinessAddressContainer>
            <Box height="50px">
              <SaveButton onClick={() => history.push(`/admin/vendors/${vendorId}/${companyDetails?.id}/verify-vendor`)}>Verify Vendor</SaveButton>
            </Box>
          </Box>
        </Grid>
      </Grid>     
    </Box>
  );
};

export default VendorCompanyDetails;
