import React, { useState, useEffect, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import GridList from "@material-ui/core/GridList";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "react-query";
import { Skeleton } from "@material-ui/lab";

import { getGeneralCompanyDetails, getDocuments, getVendorRegistrationPlan } from "../../../redux/reducer/vendorReducer";
import ConfirmVerificationModal from "../ConfirmVerificationModal";
import { FormTitle } from "../GeneralCompanyDetails/style";
import {
  VerificationDescription,
  VerificationLabel,
  VerificationText,
  VerificationDivider,
  VerificationFormTitle,
  VerificationFileUploadField,
  VerificationDocumentList,
  UserProfileImage,
} from "./style";
import NavigationButtons from "../../NavigationButtons";
import { coreCompetencies, organizationTypes, countries, states } from "../GeneralCompanyDetails/mock";
import usersAPI from "../../../redux/api/usersAPI";
import ToastContext from "../../../util/toastContext";
import NoImage from "../../../assets/no_image.png";

const Verification = () => {
  const { showAlert } = useContext(ToastContext);
  const {
    user: { UserId },
  } = useSelector((state) => state.user);

  const { data: vendorProfileData, isLoading } = useQuery(["getVendorProfile", { userId: UserId }], usersAPI.getVendorProfile, {
    enabled: !!UserId,
    onError: (error) => {
      showAlert({
        message: error.message,
        severity: "error",
      });
    },
  });

  const dispatch = useDispatch();

  const [isOpen, toggleModal] = useState(false);
  const { companyDetails, vendorDocuments } = useSelector((state) => state.vendor);
  useEffect(() => {
    const request = async () => {
      await dispatch(getGeneralCompanyDetails(UserId));
      await dispatch(getDocuments(UserId));
      await dispatch(getVendorRegistrationPlan(UserId));
    };
    request();
  }, [UserId, dispatch]);

  const {
    website,
    addressLine1,
    addressLine2,
    city,
    state,
    country,
    companyName,
    incorporationDate,
    cacRegistrationNumber,
    authorizedShareCapital,
    coreCompetency,
    organizationType,
    contactFirstName,
    contactLastName,
    contactEmail,
    contactPhoneNumber,
    contactPosition,
    correspondenceAddress1,
    correspondenceAddress2,
    correspondenceCity,
    correspondenceState,
    correspondenceCountry,
  } = Object.keys(companyDetails).length && companyDetails;

  const handleModal = (cb) => () => {
    toggleModal(!isOpen);
    // cb()
  };

  return (
    <div>
      <div>
        <FormTitle>Preview</FormTitle>
        <VerificationDescription>...</VerificationDescription>

        <VerificationFormTitle>Company Type</VerificationFormTitle>

        <Grid container>
          <Grid item xs={6}>
            <VerificationLabel>Area of Core Competence</VerificationLabel>
            <VerificationText>
              {coreCompetency && coreCompetencies.find((c) => Number(c.value) === coreCompetency).label}
            </VerificationText>
          </Grid>

          <Grid item xs={6}>
            <VerificationLabel>Type of Organization</VerificationLabel>
            <VerificationText>
              {organizationType && organizationTypes.find((o) => Number(o.value) === organizationType).label}
            </VerificationText>
          </Grid>

          <Grid item xs={6}>
            <VerificationLabel>Business Name</VerificationLabel>
            <VerificationText>{companyName && companyName}</VerificationText>
          </Grid>

          <Grid item xs={6}>
            <VerificationLabel>Date of Registration/Renewal</VerificationLabel>
            <VerificationText>
              {incorporationDate &&
                new Intl.DateTimeFormat("en-GB", {
                  month: "long",
                  day: "numeric",
                  year: "numeric",
                }).format(new Date(incorporationDate))}
            </VerificationText>
          </Grid>

          <Grid item xs={6}>
            <VerificationLabel>Registration Number</VerificationLabel>
            <VerificationText>{cacRegistrationNumber && cacRegistrationNumber}</VerificationText>
          </Grid>

          <Grid item xs={6}>
            <VerificationLabel>Capital Contribution by Partners</VerificationLabel>
            <VerificationText>{authorizedShareCapital && authorizedShareCapital}</VerificationText>
          </Grid>
        </Grid>

        <VerificationDivider style={{ marginTop: "10px" }} />

        <VerificationFormTitle>Business Contact Information</VerificationFormTitle>
        <Grid>
          <VerificationLabel>Address 1</VerificationLabel>
          <VerificationText>{addressLine1 && addressLine1}</VerificationText>
          <VerificationLabel>Address 2</VerificationLabel>
          <VerificationText>{addressLine2 && addressLine2}</VerificationText>
          <Grid container>
            <Grid item xs={6}>
              <VerificationLabel>City</VerificationLabel>
              <VerificationText>{city && city}</VerificationText>
            </Grid>
            <Grid item xs={6}>
              <VerificationLabel>Country</VerificationLabel>
              <VerificationText>{country && countries[country]}</VerificationText>
            </Grid>
          </Grid>
          <VerificationLabel>State</VerificationLabel>
          <VerificationText>{state && states[state]}</VerificationText>
        </Grid>

        <VerificationDivider />

        <VerificationFormTitle>Corresponding Address (If different from above)</VerificationFormTitle>
        <Grid>
          <VerificationLabel>Address 1</VerificationLabel>
          <VerificationText>{correspondenceAddress1 && correspondenceAddress1}</VerificationText>
          <VerificationLabel>Address 2</VerificationLabel>
          <VerificationText>{correspondenceAddress2 && correspondenceAddress2}</VerificationText>
          <Grid container>
            <Grid item xs={6}>
              <VerificationLabel>City</VerificationLabel>
              <VerificationText>{correspondenceCity && correspondenceCity}</VerificationText>
            </Grid>
            <Grid item xs={6}>
              <VerificationLabel>Country</VerificationLabel>
              <VerificationText>{correspondenceCountry && correspondenceCountry}</VerificationText>
            </Grid>
          </Grid>
          <VerificationLabel>State</VerificationLabel>
          <VerificationText>{correspondenceState && correspondenceState}</VerificationText>
        </Grid>

        <VerificationDivider />

        <VerificationFormTitle>Contact Person</VerificationFormTitle>
        <Grid>
          <UserProfileImage>
            {isLoading ? (
              <Skeleton height={150} width={150} variant="rect" col />
            ) : (
              <img src={vendorProfileData?.profilePicture?.url || NoImage} alt="User" />
            )}
          </UserProfileImage>
          <VerificationLabel>Name</VerificationLabel>
          <VerificationText>
            {contactFirstName && contactFirstName} {contactLastName && contactLastName}
          </VerificationText>
          <Grid container>
            <Grid item xs={6}>
              <VerificationLabel>Telephone Number</VerificationLabel>
              <VerificationText>{contactPhoneNumber && contactPhoneNumber}</VerificationText>
            </Grid>
            <Grid item xs={6}>
              <VerificationLabel>Email Address</VerificationLabel>
              <VerificationText>{contactEmail && contactEmail}</VerificationText>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6}>
              <VerificationLabel>Website</VerificationLabel>
              <VerificationText>{website && website}</VerificationText>
            </Grid>
            <Grid item xs={6}>
              <VerificationLabel>Position</VerificationLabel>
              <VerificationText>{contactPosition && contactPosition}</VerificationText>
            </Grid>
          </Grid>
        </Grid>
        <VerificationDescription>All Files</VerificationDescription>
        <GridList spacing={24} style={{ marginBottom: "30px" }}>
          {vendorDocuments.length &&
            vendorDocuments.map((doc, index) => (
              <VerificationDocumentList key={index}>
                <VerificationFileUploadField filesNames={[doc]} readOnly={true} />
              </VerificationDocumentList>
            ))}
        </GridList>
      </div>
      <ConfirmVerificationModal isOpen={isOpen} toggleModal={toggleModal} vendorProfileData={vendorProfileData || {}} />
      <NavigationButtons nextActionHandler={(cb) => handleModal(cb)} />
    </div>
  );
};

export default Verification;
