import styled, { css } from "styled-components";
import Typography from "@material-ui/core/Typography";
import ErrorSharpIcon from "@material-ui/icons/ErrorSharp";
import CheckCircleSharpIcon from "@material-ui/icons/CheckCircleSharp";

export const CardWrapper = styled.div`
  border: 1px solid #c9d4db;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 #c9d4db;
`;

export const CardHeader = styled.div`
  border-bottom: 1px solid #c9d4db;
  border-radius: 3px 3px 0 0;
  background-color: #e4e9ec;
  padding: ${(props) => (props.noPadding ? 0 : "10.5px 21.5px 8.5px")};
`;

export const CardTitle = styled.h5`
  color: ${(props) => (props.noBackground ? "#1E2B37" : "#5F7483")};
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;

  ${(props) => {
    if (props.noBackground && !props.noMargin) {
      return css`
        margin-bottom: 20px;
      `;
    }
  }}
`;

export const CardBody = styled.div`
  ${(props) => {
    if (props.noHeader) {
      return css`
        padding: 20px 25px;
      `;
    }
    return css`
      padding: 20px;
    `;
  }}
`;

export const CheckEntryWrapper = styled.div`
  height: 194px;
`;

export const CheckEntryErrorIcon = styled(ErrorSharpIcon)`
  color: #de3618;
  margin-right: 10px;
`;

export const CheckEntryCheckCircleSharpIcon = styled(CheckCircleSharpIcon)`
  color: rgba(11, 176, 45, 0.6);
  margin-right: 10px;
`;

export const CheckEntryText = styled(Typography)`
  color: #212b36 !important;
  font-size: 14px !important;
  letter-spacing: 0 !important;
  line-height: 20px !important;
`;

export const CheckEntryContentWrapper = styled.div`
  display: flex;
  margin-bottom: 15px;
`;
