/* eslint-disable no-shadow */
import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import NativeSelect from "@material-ui/core/NativeSelect";
import { DropwdownWrapper } from "./styled";

const ControlledDropdown = ({
                              value, onChange, options, name, label, style, inputProps = {}, disabled, ...restProps
                            }) => (
  <DropwdownWrapper style={style} disabled={disabled}>
    <div>
      <InputLabel htmlFor={name} shrink>
        {label}
      </InputLabel>
      <NativeSelect
        value={value}
        onChange={onChange}
        inputProps={{
          id: name,
          name,
            ...inputProps,
            style: {
              textTransform: "none",
            },
          }}
          {...restProps}
        >
          {options.map(({ label, value }, i) => (
            <option value={value} key={`${value}-${i}`}>
              {label}
            </option>
          ))}
        </NativeSelect>
      </div>
    </DropwdownWrapper>
);

export default ControlledDropdown;
