import React from 'react'
import PropTypes from 'prop-types'
import {  withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { ProgressText } from "./style";

const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 6,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
      
      backgroundColor: '#1a90ff',
    },
  }))(LinearProgress);
  

//  This is a progress bar component that shows the completion value in percentage  
function LinearProgressBar(props) {
  const { percentageOfCompletion } = props;
    return (
        <div>
         <ProgressText>Progress : {percentageOfCompletion}% completed </ProgressText> 
         <BorderLinearProgress variant="determinate" value={percentageOfCompletion} size={40}
        thickness={4}/>    
        </div>
    )
}

LinearProgressBar.propTypes = {
  percentageOfCompletion: PropTypes.number // This should be a number without a percentage symbol.
}

export default LinearProgressBar

