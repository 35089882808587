import styled from "styled-components";
import InputField from "../../../components/InputField/index";
import Dropdown from "../../../components/DropdownField/index";

export const ContractInputField = styled(InputField)`
  width: 100%;
`;

export const ContractDropDownField = styled(Dropdown)`
  width: 100%;
`;

export const EmptyContractTableBodyWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const FiltersGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 10px;
  align-items: center;
`

export const ClearButton = styled.button`
  background-color: transparent;
  color: #03ADEB;
  font-size: 16px;
  text-decoration: underline;
  margin-left: auto;
  border: none;
  text-align: right;
  width: 100%;
  cursor: pointer;
`;
