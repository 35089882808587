export const transformGeneralPlanStatus = (key) => {
  const generalPlanStatus = key?.status;

  if (generalPlanStatus === "COMPLETED") {
    return "Completed";
  }
  if (generalPlanStatus === "UNDERREVIEW") {
    return "Under Review";
  }
  if (generalPlanStatus === "INPROGRESS") {
    return "In Progress";
  }
  if (generalPlanStatus === "DRAFT") {
    return "Draft";
  } else {
    return generalPlanStatus;
  }
};
