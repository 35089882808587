import React, { useState } from "react";
import { Grid, Popover, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  CancelButton,
  UploadProgress,
  UploadProgressBody,
  UploadProgressFileDetails,
  UploadProgressFileName,
  UploadProgressIcon,
  UploadProgressIndicator,
} from "./style";
import fileIcon from "../../assets/file.svg";
import { ReactComponent as CancelIcon } from "../../assets/cancel.svg";
import { ReactComponent as EyesIcon } from "../../assets/eyes.svg";

import { toHumanReadableFileSize } from "../../util/toHumanReadableFileSize";

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: "4px 8px",
    boxShadow: "0 1px 1px rgba(0,0,0,0.4)",
  },
}));

const FileProgress = ({ fileName, fileSize, progress, hasError = false, hasCancel = false, onCancel, hasEyes, onClickEyes }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  let nameArray = fileName?.split(".");
  let textIsLong = !!nameArray && nameArray[0].length > 15;
  if (!!fileName) {
    nameArray[0] = textIsLong ? `${nameArray[0].substring(0, 15)}....` : nameArray[0];
  }

  return (
    <UploadProgress>
      <UploadProgressIndicator width={hasError ? 100 : progress} hasError={hasError} />
      <UploadProgressBody>
        <UploadProgressIcon src={fileIcon} />
        <Grid container direction="column">
          {textIsLong ? (
            <UploadProgressFileName
              aria-owns={open ? "mouse-over-popover" : undefined}
              aria-haspopup="true"
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
            >
              {!!nameArray ? nameArray.join() : fileName}
            </UploadProgressFileName>
          ) : (
            <UploadProgressFileName>{!!nameArray ? nameArray.join() : fileName}</UploadProgressFileName>
          )}
          <UploadProgressFileDetails>{fileSize && toHumanReadableFileSize(fileSize, true)}</UploadProgressFileDetails>
        </Grid>
      </UploadProgressBody>
      {hasCancel && (
        <CancelButton onClick={onCancel}>
          <CancelIcon width={12} />
        </CancelButton>
      )}
      {hasEyes && (
        <CancelButton onClick={onClickEyes}>
          <EyesIcon width={12} />
        </CancelButton>
      )}
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography style={{ fontSize: 12 }}>{fileName}</Typography>
      </Popover>
    </UploadProgress>
  );
};

FileProgress.propTypes = {
  fileName: PropTypes.string.isRequired,
  fileSize: PropTypes.number.isRequired,
  progress: PropTypes.number.isRequired,
  hasError: PropTypes.bool,
  hasCancel: PropTypes.bool,
  onCancel: PropTypes.func,
};

export default FileProgress;
