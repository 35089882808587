import styled from "styled-components";
import Button from "@material-ui/core/Button";

export const UploadProgress = styled.div`
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  border: 1px solid #dfe1e5;
  border-radius: 3px;
  background-color: #fafbfc;
  overflow: hidden;
  width: 100%;
  position: relative;
  box-sizing: border-box;
`;
export const UploadProgressIcon = styled.img`
  width: 14px;
  margin-right: 10px;
`;

export const UploadProgressBody = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const UploadProgressFileName = styled.div`
  color: #151c2f;
  font-size: 12px;
  font-weight: 500;
`;

export const UploadProgressFileDetails = styled.div`
  color: rgba(107, 119, 140, 0.59);
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0;
`;

export const UploadProgressIndicator = styled.div`
  height: 100%;
  width: ${(props) => `${props.width}%`};
  transition: 300ms;
  background-color: #f5f6f8;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  border-bottom: 2px solid ${(props) => (props.hasError ? "#F21500" : "#57d9a3")};
  box-sizing: border-box;
`;

export const CancelButton = styled(Button)`
  min-width: unset !important;
`;
