import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Breadcrumb from "../../../Common/PageElements/Breadcrumb";
import { PageTitle } from "../../../Common/style";
import { ContentWrapper, TitleContainer } from "../../Administration/Staff/style";
import withAdminDashboard from "../../HOC/withAdminDashboard";
import { getAdminContractDetails } from "../../../../redux/reducer/adminReducer";
import Loader from "../../../../components/Common/Loader";
import ContractDetailsCardTable from "./ContractDetailsCardTable";

const SingleContractDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAdminContractDetails(id));
  }, [dispatch, id]);

  const { adminContractDetails } = useSelector((state) => state.admin);

  const BreadcrumbLinks = [
    { url: "/admin/dashboard/overview", title: "Home", forwardSlash: true },
    { url: "/admin/contractsList/contracts", title: "Contracts", forwardSlash: true },
    { url: "#", title: adminContractDetails.procurementPlan?.packageNumber, forwardSlash: true },
  ];

  return !!Object.keys(adminContractDetails).length > 0 ? (
    <ContentWrapper>
      <TitleContainer>
        <div>
          <PageTitle>{adminContractDetails.tenderDetails?.packageNumber || "N/A"}</PageTitle>
          <Breadcrumb values={BreadcrumbLinks} />
        </div>
      </TitleContainer>

      <ContractDetailsCardTable />
    </ContentWrapper>
  ) : (
    <Loader />
  );
};

export default withAdminDashboard(SingleContractDetails);
