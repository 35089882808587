import styled from "styled-components";
import withStyles from "@material-ui/styles/withStyles";
import { Link as RouterLink } from "react-router-dom";
import MaterialDivider from "@material-ui/core/Divider";
import BaseDetailItem from "../../../../components/DetailItem";

export const Link = styled(RouterLink)`
  text-decoration: none;
  font-size: 15px;
  line-height: 16px;
  color: #00b0f1;
`;

export const DetailItem = styled(BaseDetailItem)`
  .detail-item-label {
    margin-bottom: ${(props) => props.labelSpacing ?? 0};
  }
`;

export const TenderDescription = styled.span`
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
  color: #637381;
`;

export const Bold = styled.span`
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #212b36;
`;

export const Divider = withStyles({
  root: {
    backgroundColor: "#CBD4DA",
    marginTop: 20,
    marginBottom: 20,
  },
})(MaterialDivider);
