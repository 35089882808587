import React, { useState, useContext } from "react";
import { ReactComponent as FolderIcon } from "../../../../../assets/folder.svg";
import Grid from "@material-ui/core/Grid";
import { CategoryTitle, CategoryItem, Wrapper } from "./style";
import DocumentModal from "./DocumentModal";
import { useQuery } from "react-query";
import procurementAPI from "../../../../../redux/api/procurementAPI";
import ToastContext from "../../../../../util/toastContext";
import Loader from "../../../../../components/Common/Loader";

const plural = (count, singularForm) => (count === 1 ? singularForm : `${singularForm}s`);

export default function DocumentCategories({ tenderId }) {
  const { showAlert } = useContext(ToastContext);
  const { data: categories, isLoading, isSuccess } = useQuery(
    ["getExchangedDocuments", tenderId],
    procurementAPI.getExchangedDocuments,
    {
      onError(error) {
        showAlert({
          severity: "error",
          message: error.message,
          durationInMs: 3000,
        });
      },
    }
  );
  const [selectedCategory, setSelectedCategory] = useState(null);
  return (
    <Wrapper>
      {isLoading && <Loader />}
      {isSuccess && (
        <React.Fragment>
          <Grid container spacing={4}>
            {categories.map((category) => (
              <Grid item xs={2} key={category.title}>
                <CategoryItem onClick={() => setSelectedCategory(category)}>
                  <FolderIcon />
                  <p className="file-count">
                    {category.count} {plural(category.count, "File")}
                  </p>
                </CategoryItem>
                <CategoryTitle>{category.title}</CategoryTitle>
              </Grid>
            ))}
          </Grid>
          <DocumentModal selectedCategory={selectedCategory} onClose={() => setSelectedCategory(null)} />
        </React.Fragment>
      )}
    </Wrapper>
  );
}
