import React, {useEffect, useContext} from "react";
import { useHistory} from "react-router-dom";
import {useQuery} from "react-query"
import { ClickAwayListener, Grow, MenuList, Paper, Popper, Box, CircularProgress  } from "@material-ui/core";
import InfiniteScroll from "react-infinite-scroll-component";

import ToastContext from "../../util/toastContext";
import { SearchResults, SearchResult, LoaderContainer } from "./style";
import { NotificationBody } from "../Notification/style";
import adminAPI from "../../redux/api/adminAPI";

const getSearchTypeTheme = (type) => {
  let searchTypeTheme = {};
  switch (type.toLowerCase()) {
    case "app":
      searchTypeTheme.textColor = "#fff";
      searchTypeTheme.bgColor = "#00B0F1";
      break;
    case "ministry":
    case "tender":
    case "staff":
    case "vendor":
    case "contract":
    default:
      searchTypeTheme.textColor = "#fff";
      searchTypeTheme.bgColor = "#5f7483";
      break;
  }
  return searchTypeTheme;
};

const searchResults = [
  {
    type: "app",
    text: "Delta irrigated Agricultural Improvement",
  },
  {
    type: "ministry",
    text: "Delta irrigated Agricultural Improvement",
  },
  {
    type: "tender",
    text: "Delta irrigated Agricultural Improvement",
  },
  {
    type: "staff",
    text: "Delta irrigated Agricultural Improvement",
  },
  {
    type: "vendor",
    text: "Delta irrigated Agricultural Improvement",
  },
  {
    type: "contract",
    text: "Delta irrigated Agricultural Improvement",
  },
  {
    type: "app",
    text: "Delta irrigated Agricultural Improvement",
  },
  {
    type: "ministry",
    text: "Delta irrigated Agricultural Improvement",
  },
  {
    type: "tender",
    text: "Delta irrigated Agricultural Improvement",
  },
  {
    type: "staff",
    text: "Delta irrigated Agricultural Improvement",
  },
  {
    type: "vendor",
    text: "Delta irrigated Agricultural Improvement",
  },
  {
    type: "contract",
    text: "Delta irrigated Agricultural Improvement",
  },
];

const mapCategoryToURL ={
  APP:  "/admin/procurement/plans/:itemId" ,
  CONTRACT: "/admin/contracts/:itemId",
  VENDOR: "/admin/vendorsList/:itemId",
  TENDER: "/admin/procurement/plan-details/:itemId"
}

const SearchResultPopper = ({ openSearchResultPopper, notificationAnchorRef, setOpenSearchResultPopper, searchText="" }) => {
  const { showAlert } = useContext(ToastContext);
  const history = useHistory();
  const { error, data = [], isLoading} = useQuery(
    ["getSearchResults", searchText],
    adminAPI.getSearchResults
  );

  const searchResultWidth = !!notificationAnchorRef.current ? notificationAnchorRef.current.offsetWidth : null;

  useEffect(() => {
    if (!!error) showAlert({ severity: "error", message: error.message });
    // eslint-disable-next-line
  }, [error]);

  const toggleSearchResultPopper = () => {
    //Mark notifications as read on close
    setOpenSearchResultPopper((prevOpenSearchResultPopper) => !prevOpenSearchResultPopper);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenSearchResultPopper(false);
    }
  }

  const handleSelection =(category, id)=>{
    const pageUrl = mapCategoryToURL[category]?.replace(":itemId", id).trim()
    history.push(pageUrl)
  }

  function renderLoader () {
    return <LoaderContainer ><CircularProgress size ={60} /></LoaderContainer>
  }

  function formatName (name){
    if (name.length > 50){
      return name.substring(0, 50) + "..."
    }
    return name
  }

  return (
    <Popper
      open={openSearchResultPopper}
      anchorEl={notificationAnchorRef.current}
      role={undefined}
      transition
      disablePortal
      placement="bottom-start"
      style={{ zIndex: "100", width: searchResultWidth, overflow: "auto" }}
    >    
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <Paper>
            <ClickAwayListener onClickAway={toggleSearchResultPopper}>
              <SearchResults>            
                <span>Results</span>
                <MenuList autoFocusItem={openSearchResultPopper} onKeyUp={handleListKeyDown}>
                  <NotificationBody id={"iBeKing"}>
                    <InfiniteScroll
                      loader={null}
                      height={"300px"}
                      dataLength={searchResults.length}
                      scrollableTarget={"iBeKing"}
                      scrollThreshold={1}
                      style={{overflow: "auto"}}
                    >
                      {isLoading ? renderLoader() :
                      data?.data?.map((result, i) => (
                        <SearchResult key={i} typeTheme={getSearchTypeTheme(result.category)} onClick={()=>handleSelection(result.category,result.id)}>
                          <Box display="flex" justifyContent="space-between" flexDirection="row" >
                            <Box display="flex" flexDirection="row" alignItems="center">
                              <span className="searchType">{result.category}</span>
                              <p className="searchText">{formatName (result.name)}</p>
                            </Box>
                            <Box flexDirection="row">
                              <span className="select">Select</span>
                              <span className="arrow">&#8629;</span>
                            </Box>
                          </Box>
                        </SearchResult>
                      ))}
                    </InfiniteScroll>
                  </NotificationBody>
                </MenuList>              
              </SearchResults>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

export default SearchResultPopper;
