/* eslint-disable */
import React, { Fragment } from "react";
import { Description, AddNew } from "../Common/style";
import { DocumentWrapper, Divider, EmptyState } from "./style";
import UploadedDocuments from "./UploadedDocuments";
import { ReactComponent as EmptyFolder } from "../../../assets/empty_folder.svg";

const DocumentGroups = ({
  documentGroups, documents, toggleDrawer, handleDelete,
}) => (
  documentGroups.length
    ? (
      documentGroups.map(({ title, id }, i) => (
      <Fragment key={`${id}-${i}`}>
        <DocumentWrapper>
          <Description>
            {title}
          </Description>
          {
            documents[id]?.length
              ? (<UploadedDocuments
                documents={documents}
                title={id}
                handleDelete={handleDelete}
              />)
              : (<EmptyState>
                  <EmptyFolder />
                  No document uploaded yet
                </EmptyState>)
          }
          <AddNew
            onClick={() => toggleDrawer(id)}
            padding='20px 10px !important'
          >
            Add New
          </AddNew>
        </DocumentWrapper>
        {i !== documentGroups.length - 1 && <Divider />}
      </Fragment>
      ))
    )
    : null
);

export default DocumentGroups;
