import styled from "styled-components";

export const Wrapper = styled.div`
  margin-bottom: ${(props) => props.bottomSpacing ?? "40px"};
  padding: 15px;
  width: 548px;
  display: flex;
  align-items: center;
  border: 1px solid #219653;
  border-radius: 3px;
  font-size: 16px;
  line-height: 20px;
  color: #424f5c;
`;

export const IconWrapper = styled.div`
  width: 40px;
  height: 40px;
  background: #219653;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  color: white;
`;
