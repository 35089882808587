import { makeStyles } from "@material-ui/styles";
import moment from "moment";
import React from "react";
import { useHistory } from "react-router-dom";
import { Tooltip, Box } from "@material-ui/core";

import EmptyTableBody from "../../../../components/EmptyTableBody";
import { StyledTableCell } from "../../../../components/SearchTable";
import { StyledTableRow, theme } from "../../../../util/theme";
import { EmptyTableSubTitle } from "./style";

const useStyles = makeStyles({
  contractNo: {
    fontSize: 14,
  },
  ocid: {
    fontSize: 14,
    color: `${theme.color.primary}`,
    fontWeight: 600,
    textTransform: "uppercase",
  },
});

// const StyledTableRow = withStyles((styleTheme) => ({

//   root: {  cursor: "pointer",
//     "&:nth-of-type(odd)": {
//       backgroundColor: styleTheme.palette.action.hover,
//     },
//   },
// }))(TableRow);

const textOverFlowStyle = { width: 200, textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" };

const CustomContractTable = ({ contracts = [], urlLink }) => {
  const classes = useStyles();
  const history = useHistory();

  return contracts.length ? (
    contracts.map((eachContract) => {
      const link = !!urlLink ? `${urlLink}/${eachContract.id}` : `/admin/contracts/${eachContract.id}`;
      return (
        <StyledTableRow key={eachContract.id} onClick={() => history.push(link)} style={{ backgroundColor: "#fff" }}>
          <StyledTableCell
            style={{
              position: "sticky",
              left: 0,
              bottom: 0,
              right: 0,
              top: 0,
              width: 200,
              backgroundColor: "#fff",
              paddingRight: 0,
            }}
          >
            <Box display="flex" alignItems="center" style={{ width: 200, height: "100%",  borderRight: "2px solid #E4E9EC", }}>
            <p  className={classes.ocid}>
              {eachContract?.referenceId}
            </p>
            </Box>
          </StyledTableCell>
          <StyledTableCell>
            <Tooltip title={eachContract.title} aria-label="add">
              <p className={classes.contractNo} style={textOverFlowStyle}>
                {eachContract.title}
              </p>
            </Tooltip>
          </StyledTableCell>
          <StyledTableCell>
            <Tooltip title={eachContract.budgetDescription} aria-label="add">
              <p style={textOverFlowStyle}>{eachContract.budgetDescription}</p>
            </Tooltip>
          </StyledTableCell>
          <StyledTableCell>
            <Tooltip title={eachContract.ministry || ""} aria-label="add">
              <p style={textOverFlowStyle}> {eachContract?.ministry}</p>
            </Tooltip>
          </StyledTableCell>
          <StyledTableCell>
            <p style={{ width: 100 }}> {eachContract?.procurement?.category?.name || "N/A"}</p>
          </StyledTableCell>
          <StyledTableCell>
            <Tooltip title={eachContract.contractor?.companyName || ""} aria-label="add">
              <p style={textOverFlowStyle}> {eachContract?.contractor?.companyName || "N/A"}</p>
            </Tooltip>
          </StyledTableCell>
          <StyledTableCell>
            <p style={{ width: 100 }}> {moment(eachContract.startDate || eachContract.dateAwarded).format("DD MMM, YYYY")}</p>
          </StyledTableCell>
          <StyledTableCell>
            <p style={{ width: 100 }}> {moment(eachContract.endDate || eachContract.expiryDate).format("DD MMM, YYYY")}</p>
          </StyledTableCell>
          <StyledTableCell align="right">&#8358;{eachContract?.estimatedValue?.toLocaleString() || "N/A"}</StyledTableCell>
        </StyledTableRow>
      );
    })
  ) : (
    <EmptyTableBody>
      <EmptyTableSubTitle>There are currently no contracts</EmptyTableSubTitle>
    </EmptyTableBody>
  );
};

export default CustomContractTable;
