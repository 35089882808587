/* eslint-disable no-unused-expressions */
import { createSlice } from "@reduxjs/toolkit";
import { fulfilled, pending, rejected } from "./baseReducer";
import baseThunkMethod from "./baseThunkMethod";

export const postAttestation = baseThunkMethod("postAttestation");
export const saveGeneralCompanyDetails = baseThunkMethod("saveGeneralCompanyDetails");
export const getGeneralCompanyDetails = baseThunkMethod("getGeneralCompanyDetails");
export const getCountries = baseThunkMethod("getCountries");
export const getStates = baseThunkMethod("getStates");
export const getProducts = baseThunkMethod("productsNServices");
export const getVendorProducts = baseThunkMethod("vendorProductsNServices");
export const postVendorServices = baseThunkMethod("postVendorServices");
export const getRegistrationPlans = baseThunkMethod("registrationPlans");
export const getAttestation = baseThunkMethod("getAttestation");
export const addDirectorDetails = baseThunkMethod("vendors/add-director-details");
export const getDirectorDetails = baseThunkMethod("getDirectorDetails");
export const getVendorRegistrationPlan = baseThunkMethod("getVendorRegistrationPlan");
export const postVendorRegistrationPlan = baseThunkMethod("postVendorRegistrationPlan");
export const postDocuments = baseThunkMethod("postDocuments");
export const getDocumentGroups = baseThunkMethod("getDocumentGroups");
export const getDocuments = baseThunkMethod("getDocuments");
export const deleteDocuments = baseThunkMethod("deleteDocuments");
export const completeProfile = baseThunkMethod("completeProfile");
export const deleteDirectorDetails = baseThunkMethod("deleteDirectorDetails");
export const editDirectorDetails = baseThunkMethod("editDirectorDetails");
export const getBidSummary = baseThunkMethod("getBidSummary");
export const approveVendor = baseThunkMethod("approveVendor");
export const rejectVendor = baseThunkMethod("rejectVendor");

export const vendorSlice = createSlice({
  name: "vendor",
  initialState: {
    loading: "idle",
    loaded: [],
    companyDetails: {},
    attestation: [{}],
    countries: [{}],
    states: [{}],
    products: [],
    vendorProducts: [],
    registrationPlans: [],
    directorDetails: [],
    selectedRegPlan: {},
    vendorDocuments: [],
    documentsGroups: [],
    bidSummary: {},
    completed: null,
    error: null,
    message: "",
  },
  extraReducers: {
    [saveGeneralCompanyDetails.pending]: pending(),
    [saveGeneralCompanyDetails.fulfilled]: fulfilled("companyDetails"),
    [saveGeneralCompanyDetails.rejected]: rejected("companyDetails"),
    [postAttestation.pending]: pending(),
    [postAttestation.fulfilled]: fulfilled("attestation"),
    [postAttestation.rejected]: rejected("attestation"),
    [getGeneralCompanyDetails.pending]: pending(),
    [getGeneralCompanyDetails.fulfilled]: (state, action) => {
      state.loading = "idle";
      state.error = null;

      const { vendorCorrespondence, vendorContact } = action.payload.data;
      const {
        address1, address2, city, country,
      } = vendorCorrespondence || [];

      state.companyDetails = {
        ...action.payload.data,
        contactFirstName: vendorContact?.firstName,
        contactLastName: vendorContact?.lastName,
        contactEmail: vendorContact?.email,
        contactPhoneNumber: vendorContact?.phoneNumber,
        contactPosition: vendorContact?.position,
        correspondenceAddress1: address1,
        correspondenceAddress2: address2,
        correspondenceCountry: country,
        correspondenceState: vendorCorrespondence?.state,
        correspondenceCity: city,
      };
      delete state.companyDetails?.vendorCorrespondence;
      delete state.companyDetails?.vendorContact;
      delete state.companyDetails?.updatedAt;
      delete state.companyDetails?.companyPhoneNumber;
      delete state.companyDetails?.registrationPlanId;
      delete state.companyDetails?.userId;
      delete state.companyDetails?.createdAt;
      delete state.companyDetails?.incorporationDate;

      !state.loaded.includes("companyDetails") && state.loaded.push("companyDetails");
    },
    [getGeneralCompanyDetails.rejected]: rejected("companyDetails"),
    [getCountries.pending]: pending("countries"),
    [getCountries.fulfilled]: (state, action) => {
      state.loading = "idle";
      state.error = null;
      const newCountries = action.payload.data.map((country) => ({
        label: country.name,
        value: country.name,
      }));
      state.countries = newCountries;
      !state.loaded.includes("countries") && state.loaded.push("countries");
    },
    [getCountries.rejected]: rejected("countries"),

    [getStates.pending]: pending(),
    [getStates.fulfilled]: (state, action) => {
      state.loading = "idle";
      state.error = null;
      const statesArray = action.payload.data.map((eachState) => ({
        label: eachState.name,
        value: eachState.name,
      }));
      state.states = statesArray;
      !state.loaded.includes("states") && state.loaded.push("states");
    },
    [getStates.rejected]: rejected("states"),
    [getProducts.pending]: pending(),
    [getProducts.fulfilled]: (state, action) => {
      state.loading = "idle";
      state.error = null;
      state.products = action.payload.data.reduce((acc, val) => {
        acc[val.businessCategory.title] = acc[val.businessCategory.title] || [];
        acc[val.businessCategory.title].push(val);
        return acc;
      }, {});
      !state.loaded.includes("products") && state.loaded.push("products");
    },
    [getProducts.rejected]: rejected("products"),
    [getVendorProducts.pending]: pending(),
    [getVendorProducts.fulfilled]: (state, action) => {
      state.loading = "idle";
      state.error = null;
      state.vendorProducts = action.payload.data.map((product) => product.id);
      !state.loaded.includes("vendorProducts") && state.loaded.push("vendorProducts");
    },
    [getVendorProducts.rejected]: rejected("vendorProducts"),
    [postVendorServices.pending]: () => {},
    [postVendorServices.fulfilled]: (state, action) => {
      state.loading = "idle";
      state.error = null;
      if (action.meta.arg.data.remove && action.meta.arg.data.remove.length > 0) {
        state.vendorProducts = state.vendorProducts.filter((service) => !action.meta.arg.data.remove.includes(service));
      }
      if (action.meta.arg.data.add && action.meta.arg.data.add.length > 0) {
        state.vendorProducts = [...new Set(state.vendorProducts.concat(action.meta.arg.data.add))];
      }
    },
    [postVendorServices.rejected]: (state, action) => {
      state.loading = "idle";
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [getRegistrationPlans.pending]: pending(),
    [getRegistrationPlans.fulfilled]: fulfilled("registrationPlans"),
    [getRegistrationPlans.rejected]: rejected("registrationPlans"),
    [getAttestation.pending]: pending(),
    [getAttestation.fulfilled]: fulfilled("attestation"),
    [getAttestation.rejected]: rejected("attestation"),
    [addDirectorDetails.pending]: pending(),
    [addDirectorDetails.fulfilled]: (state, action) => {
      state.loading = "idle";
      state.directorDetails = [action.payload.data, ...state.directorDetails];
    },
    [addDirectorDetails.rejected]: rejected("directorDetails"),
    [getDirectorDetails.pending]: pending(),
    [getDirectorDetails.fulfilled]: fulfilled("directorDetails"),
    [getDirectorDetails.rejected]: rejected("directorDetails"),
    [postVendorRegistrationPlan.pending]: () => {},
    [postVendorRegistrationPlan.fulfilled]: (state, action) => {
      state.loading = "idle";
      state.selectedRegPlan = action.payload.data.registrationPlan;
    },
    [postVendorRegistrationPlan.rejected]: rejected("selectedRegPlan"),
    [getVendorRegistrationPlan.pending]: pending(),
    [getVendorRegistrationPlan.fulfilled]: (state, action) => {
      state.loading = "idle";
      state.selectedRegPlan = action.payload.data.length > 0 ? action.payload.data[0].registrationPlan : {};
      !state.loaded.includes("selectedRegPlan") && state.loaded.push("selectedRegPlan");
    },
    [getVendorRegistrationPlan.rejected]: rejected("selectedRegPlan"),
    [getDocumentGroups.pending]: pending(),
    [getDocumentGroups.fulfilled]: fulfilled("documentsGroups"),
    [getDocumentGroups.rejected]: rejected("documentsGroups"),
    [postDocuments.pending]: () => {},
    [postDocuments.fulfilled]: (state, action) => {
      state.loading = "idle";
      state.vendorDocuments = [...state.vendorDocuments, action.payload.data];
    },
    [postDocuments.rejected]: rejected("vendorDocuments"),
    [getDocuments.pending]: pending(),
    [getDocuments.fulfilled]: fulfilled("vendorDocuments"),
    [getDocuments.rejected]: rejected("vendorDocuments"),
    [deleteDocuments.pending]: (state, action) => {
      state.loading = `deleting ${action.meta.arg.id}`;
    },
    [deleteDocuments.fulfilled]: (state, action) => {
      state.loading = "idle";
      state.vendorDocuments = state.vendorDocuments.filter((document) => document.id !== action.meta.arg.id);
    },
    [deleteDocuments.rejected]: rejected("vendorDocuments"),
    [completeProfile.pending]: pending(),
    [completeProfile.fulfilled]: fulfilled("completed"),
    [completeProfile.rejected]: rejected("completed"),
    [deleteDirectorDetails.pending]: pending(),
    [deleteDirectorDetails.fulfilled]: (state, action) => {
      state.loading = "idle";
      state.error = null;
      if (action.meta.arg && action.meta.arg.directorId) {
        const filteredReducer = state.directorDetails.filter((elem) => elem.id !== action.meta.arg.directorId);
        state.directorDetails = filteredReducer;
      }
    },
    [deleteDirectorDetails.rejected]: rejected("directorDetails"),
    [editDirectorDetails.pending]: pending(),
    [editDirectorDetails.fulfilled]: (state, action) => {
      state.loading = "idle";
      state.error = null;
      const directorDetails = state.directorDetails.map((details) => {
        if (details.id === action.payload.data.id) {
          return action.payload.data;
        }
        return details;
      });
      state.directorDetails = directorDetails;
    },
    [editDirectorDetails.rejected]: rejected("directorDetails"),
    [getBidSummary.pending]: pending(),
    [getBidSummary.fulfilled]: fulfilled("bidSummary"),
    [getBidSummary.rejected]: rejected("bidSummary"),
    [approveVendor.fulfilled]: (state, action) => {
      state.loading = "idle";
      state.error = null;
      state.companyDetails = {
        ...state.companyDetails,
        status: "APPROVED",
      }
    },
    [rejectVendor.fulfilled]: (state, action) => {
      state.loading = "idle";
      state.error = null;
      state.companyDetails = {
        ...state.companyDetails,
        status: "DISAPPROVED",
      }
    }
  },
});

export default vendorSlice.reducer;
