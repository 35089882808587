import { Box, Dialog } from "@material-ui/core";
import React from "react";
import { ApprovalModalContainer, ApprovalModalDocInfo, CancelModalBtn, ModalApprovalText } from "../style";
import FileUploadField from "../../../../../../../components/FileUploadField";
import LoadingButton from "../../../../../../../components/LoadingButton/LoadingButton";

const ApprovalModal = (
  {
    filesNames,
    style,
    setFilesNames,
    handleClose,
    open,
    btnText,
    cardTitle,
    docInfo,
    buttonColor = '#3BD278',
    onClickButton,
    isLoading = false
  }) => {
  return (
    <Dialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <ApprovalModalContainer>
        <ModalApprovalText>{cardTitle}</ModalApprovalText>
        <ApprovalModalDocInfo>{docInfo}</ApprovalModalDocInfo>
        <FileUploadField filesNames={filesNames} uploadText="Upload or drop file here" setFilesNames={setFilesNames} />
        <Box display="flex" marginTop="30px">
          <Box mr={2}>
            <LoadingButton
              color={buttonColor}
              loading={isLoading}
              onClick={onClickButton}
              disabled={filesNames.length === 0}
            >
              {btnText}
            </LoadingButton>
          </Box>
          <CancelModalBtn onClick={handleClose} style={style}>Cancel</CancelModalBtn>
        </Box>
      </ApprovalModalContainer>
    </Dialog>
  );
};

export default ApprovalModal;
