import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { getHours, getMinutes } from "date-fns";
import { Box } from "@material-ui/core";
import ReviewCommentsSection from "../../../ReviewCommentsSection";
import Document from "./Document";
import DataSheet from "./DataSheet";
import { CardContentWrapper, PageTabs } from "../../../../util/theme";
import {
  GeneralContentWrapper,
  GeneralPlanTab,
  GeneralTabContainer,
  GeneralTitlePage,
  PlanCardContainer,
} from "../../../AddGeneralPlan/style";
import { ApprovalButton, CancelButton, SaveButton } from "../../../Forms/Common/style";
import CheckEntryCard from "../../../CheckEntryCard";
import procurementAPI from "../../../../redux/api/procurementAPI";
import ToastContext from "../../../../util/toastContext";
import Breadcrumb from "../../../../pages/Common/PageElements/Breadcrumb";
import { Roles } from "../../../AccessControl/UserRoles";
import { useRoles } from "../../../../hooks/useUserDetails";
import LoadingButton from "../../../LoadingButton/LoadingButton";

const defaultDatasheetConfig = {
  label: "Deadline for Submission",
  itemKey: "submissionDeadline",
};

const withActivityLayout = (WrappedComponent) => ({
  dataSheet,
  postAPI,
  getAPI,
  extraCrumbs,
  title,
  datasheetConfig = defaultDatasheetConfig,
}) => {
  const tabsLength = dataSheet ? 3 : 2;

  //Special hooks
  const { activityId = "", procurementId } = useParams();
  const history = useHistory();

  //State
  const [documents, setDocuments] = useState({ mandatory: [], supporting: [], removed: [] });
  const [currentStep, setCurrentStep] = useState(0);
  const [formValues, setFormValues] = useState({
    [datasheetConfig.itemKey]: null,
    hours: null,
    minutes: null,
  });

  const { showAlert } = useContext(ToastContext);

  //API calls
  const documentDataSheetQuery = useQuery(["getDocumentDataSheet", activityId], getAPI || procurementAPI.getDocumentDataSheet);

  const [postDocumentDataSheet, postDocumentResponse] = useMutation(postAPI || procurementAPI.postDocumentDataSheet, {
    throwOnError: true,
  });

  const [approveActivity, approveActivityQueryResponse] = useMutation(procurementAPI.approveActivity, {
    throwOnError: true,
  });

  //Access control config
  const { role, isSuccess: getUserRolesSuccess } = useRoles();
  const isDisabled = role !== Roles.PROCUREMENT_OFFICER;
  const isMaker = role === Roles.PROCUREMENT_OFFICER;
  const isChecker = [Roles.COMMISSIONER, Roles.PERMANENT_SECRETARY].includes(role);

  //Effect
  useEffect(() => {
    const data = dataSheet ? documentDataSheetQuery.data?.documents : documentDataSheetQuery.data;

    if (documentDataSheetQuery.isSuccess && data?.length) {
      const uploadedDocuments = data?.reduce(
        (currDocuments, file) => {
          const moddedFile = {
            inDb: true,
            id: file.id,
            name: file.file.original_filename,
            size: file.file.bytes,
            downloadUrl: file.file.url,
          };

          if (file.procurementDocumentStatus === "MANDATORY") {
            return {
              ...currDocuments,
              mandatory: currDocuments.mandatory.concat(moddedFile),
              removed: [],
            };
          }
          return {
            ...currDocuments,
            supporting: currDocuments.supporting.concat(moddedFile),
            removed: [],
          };
        },
        {
          mandatory: [],
          supporting: [],
        }
      );

      if (dataSheet) {
        const deadline = new Date(documentDataSheetQuery.data?.datasheet?.[datasheetConfig.itemKey]);
        const hours = getHours(deadline);
        const minutes = getMinutes(deadline);

        setFormValues({
          ...formValues,
          [datasheetConfig.itemKey]: new Date(deadline),
          hours,
          minutes,
        });
      }
      setDocuments(uploadedDocuments);
    }
    if (documentDataSheetQuery.isError) {
      showAlert({
        severity: "error",
        message: documentDataSheetQuery.error.message,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentDataSheetQuery.data, documentDataSheetQuery.isSuccess]);

  //Helper functions
  const updateFormValue = useCallback(
    (field) => (event) => {
      setFormValues({
        ...formValues,
        [field]: event.target.value,
      });
    },
    [formValues]
  );

  useEffect(() => {
    if (!getUserRolesSuccess) {
      return showAlert({
        severity: "error",
        message: "Network Error. Kindly Refresh",
      });
    }
  }, [getUserRolesSuccess, showAlert]);

  //Event handlers
  const handleMoveNext = () => setCurrentStep(Math.min(currentStep + 1, tabsLength - 1));

  const handleMovePrev = () => {
    setCurrentStep((currentStep) => {
      return Math.max(currentStep - 1, 0);
    });
  };

  const handleSendForApproval = async () => {
    const postParams = {
      ...documents,
      activityId,
    };

    if (dataSheet) {
      const dateobject = new Date(`${formValues[datasheetConfig.itemKey]}`);
      postParams[datasheetConfig.itemKey] = dateobject.toISOString();
    }

    try {
      await postDocumentDataSheet(postParams);

      showAlert({
        severity: "success",
        message: "document uploaded successfully",
      });

      postDocumentResponse.reset();

      handleMoveNext();
    } catch (e) {
      showAlert({
        severity: "error",
        message: e.message,
      });
    }
  };

  const handleApproval = async () => {
    try {
      await approveActivity({
        procurementPlanId: procurementId,
        procurementPlanActivityId: activityId,
      });

      showAlert({
        severity: "success",
        message: "Successfully approved stage",
        durationInMs: 3000
      }) ;   
      setTimeout(function(){ history.push(`/admin/procurement/plan-details/${procurementId}`)}, 3000);
     
    }catch (e) {
      showAlert({
        severity: "error",
        message: e.message,
        durationInMs: 3000,
      });
    }
  };

  const onClickMaker = () => {
    if (dataSheet) {
      if (currentStep === 1) {
        handleSendForApproval();
      } else {
        handleMoveNext();
      }
    } else {
      handleSendForApproval();
    }
  };

  //Render functions
  const renderButtonsByRoles = () => {
    if (isMaker) {
      return (
        <Box marginLeft={"auto"}>
          <LoadingButton disabled={loadingState} onClick={onClickMaker} loading={postDocumentResponse.isLoading}>
            Next
          </LoadingButton>
        </Box>
      );
    }

    if (isChecker) {
      return (
        <Box marginLeft={"auto"} display={"flex"}>
          {currentStep !== tabsLength - 1 && (
            <SaveButton disabled={loadingState} onClick={handleMoveNext}>
              Next
            </SaveButton>
          )}
          <ApprovalButton disabled={loadingState} onClick={handleApproval} loading={approveActivityQueryResponse.isLoading}>
            Approve
          </ApprovalButton>
        </Box>
      );
    }
  };

  const Layout = useMemo(() => {
    const currentLayout = {
      0: <Document documents={documents} setDocuments={setDocuments} loading={documentDataSheetQuery.isLoading} />,
      1: <ReviewCommentsSection objectId={activityId} />,
    };

    if (dataSheet) {
      currentLayout[1] = <DataSheet {...{ formValues, setFormValues, updateFormValue, isDisabled, datasheetConfig }} />;
      currentLayout[2] = <ReviewCommentsSection objectId={activityId} />;
    }

    return currentLayout;
  }, [
    activityId,
    dataSheet,
    documentDataSheetQuery.isLoading,
    documents,
    formValues,
    isDisabled,
    updateFormValue,
    datasheetConfig,
  ]);

  const completedForm = useMemo(() => {
    if (currentStep === 0) {
      return [];
    } else if (currentStep === 1) {
      return [0];
    } else if (currentStep >= 2) {
      return [0, 1];
    }
  }, [currentStep]);

  const loadingState =
    documentDataSheetQuery.isLoading || postDocumentResponse.isLoading || approveActivityQueryResponse.isLoading;

  return (
    <GeneralContentWrapper>
    <GeneralTitlePage>{title}</GeneralTitlePage>
    <Breadcrumb values={
      [
        {
          url: "/admin/procurement/plans",
          title: "Home",
          forwardSlash: true,
        },
        {
          url: `/admin/procurement/plan-details/${procurementId}`,
          title: "Activities List",
          forwardSlash: true,
        },
        ...extraCrumbs
      ]
    } />
    <GeneralContentWrapper></GeneralContentWrapper>
      <CardContentWrapper>
        <PlanCardContainer>
          <GeneralTabContainer>
            <PageTabs value={currentStep}>
              <GeneralPlanTab label="Documents" activetab={(currentStep === 0).toString()} />
              {dataSheet && <GeneralPlanTab label="Datasheet" activetab={(currentStep === 1).toString()} />}
              <GeneralPlanTab label="Review" activetab={(currentStep === (!dataSheet ? 1 : 2)).toString()} />
            </PageTabs>
          </GeneralTabContainer>
          {getUserRolesSuccess && Layout[currentStep]}
          <CardContentWrapper style={{ padding: "35px 25px", display: "flex" }}>
            {currentStep > 0 && (
              <CancelButton disabled={currentStep === 0} onClick={handleMovePrev}>
                Back
              </CancelButton>
            )}
            {documents.mandatory.length > 0 && renderButtonsByRoles()}
          </CardContentWrapper>
        </PlanCardContainer>
        <CheckEntryCard
          cardTitle="Check Entries"
          textValues={tabsLength === 2 ? ["Document"] : ["Document", "Datasheet"]}
          completedForm={completedForm}
        />
      </CardContentWrapper>
    </GeneralContentWrapper>
  );
};

export default withActivityLayout;
