import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import { useQueryCache } from "react-query";

import {
  MenuItemWrapper, MenuItem, LogoutWrapper, CustomMenuItemWrapper, MenuChildrenWrapper,
} from "./style";
import { menus } from "./mock";
import { logoutFn } from "../../util/logout";

const Sidebar = () => {
  const cache = useQueryCache();
  const { pathname } = useLocation();
  const history = useHistory();
  const [active, setActive] = useState("");
  const dispatch = useDispatch();
 

  useEffect(() => {
    const page = pathname.split("/");

    menus.forEach((menu) => {
      if (menu.children.includes(page[page.length - 1])) {
        return setActive(menu.title);
      }
    });
  }, [pathname]);

  const CustomMenuItem = ({
    to, title, children, icon,
  }) => (
    <>
      <CustomMenuItemWrapper active={((active === title) || pathname.includes(`${title}/`)).toString()}>
        {icon}
        <MenuItem to={children.length ? `${to}/${children[0]}` : to} onClick={() => setActive(title)} active={(active === title).toString()}>
          {title}
        </MenuItem>
      </CustomMenuItemWrapper>
      {children.length > 0 && (active === title ? (
        <MenuChildrenWrapper>
          {children.map((child, i) => (
            <MenuItem to={`${to}/${child}`} active={pathname.includes(child).toString()} key={`${child}-${i}`}>
              {child}
            </MenuItem>
          ))}
        </MenuChildrenWrapper>
      ) : null)}
    </>
  );

  return (
    <>
      <MenuItemWrapper>
        {menus.map((menu, i) => (
          <CustomMenuItem {...menu} key={`${menu.title}-${i}`} />
        ))}
      </MenuItemWrapper>
      <LogoutWrapper onClick={() => {
        logoutFn(dispatch);
        cache.clear();
        return history.push("/login");
      }}> 
        <LogoutIcon />
        <MenuItem to="#">Logout</MenuItem>
      </LogoutWrapper>
    </>
  );
};

export default Sidebar;
