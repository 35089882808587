import styled from "styled-components";
import ButtonBase from "@material-ui/core/ButtonBase";
import { ContractStatus } from "../../../../../../../util/constants";
import LoadingButton from "../../../../../../../components/LoadingButton/LoadingButton";


export const SummaryCardWrapper = styled.div`
  margin-bottom: 30px;
`;

export const ContractFormCardWrapper = styled.div`
  .MuiTab-root {
    text-transform: none;
    color: #5f7483;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }

  .MuiTabs-root {
    padding: 0;
  }

  .MuiTabs-indicator {
    background-color: #00b0f1;
  }
`;

export const ContractStatusInfo = styled.p`
  display: inline-flex;
  align-items: center;
  color: ${(props) => {
    switch (props.status) {
      case ContractStatus.Accepted:
        return "#3bd278";
      case ContractStatus.Pending:
        return "#ff8d26";
      case ContractStatus.Rejected:
        return "red";
      default:
      // noop, impossible situation
    }
  }};
`;

export const ContractStatusInfoDot = styled.span`
  margin-right: 4px;
  box-sizing: content-box;
  border-radius: 50px;
  width: 10px;
  height: 10px;
  background-color: currentColor;
`;

export const ContractStatusInfoText = styled.span`
  color: inherit;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
`;

export const FormSectionTitle = styled.h5`
  background-color: #fff;
  padding: 20px 20px 15px;
  color: #1e2b37;
  font-family: "SF Pro Text", sans-serif;
  font-size: 17px;
  font-weight: 600;
  line-height: 24px;
`;

export const FormSectionContent = styled.div`
  padding: 20px;
  border-top: 1px solid #c9d4db;
  border-bottom: 1px solid #c9d4db;
  background-color: #fcfcfd;
`;

export const TabActions = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 35px 25px 40px;
`;

export const TabNextButton = styled(ButtonBase)`
  &.MuiButtonBase-root {
    border: 1px solid #ebebeb;
    border-radius: 3px;
    background-color: ${(props) => (props.disabled ? "#c9d4db" : "#00b0f1")};
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
    padding: 10px 22px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
  }
`;


export const ApproveButton = styled(LoadingButton)`
  border: 1px solid #3BD278 !important;
  background-color: #3BD278 !important;
  color: #fff !important;
  margin-left: 20px !important;
`;

export const TabBackButton = styled(ButtonBase)`
  &.MuiButtonBase-root {
    border: 1px solid #8e9fac;
    border-radius: 3px;
    background-color: #ffffff;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
    padding: 10px 20px;
    color: #8e9fac;   
  }
`;

export const ChatArea = styled.div`
  padding: 20px;
  border-bottom: 1px solid #c9d4db;
  background-color: rgba(228, 233, 236, 0.17);
`;
