import React from "react";
import { ReactComponent as AdministrationIcon } from "../../assets/building.svg";
import { ReactComponent as ProcurementIcon } from "../../assets/speaker.svg";
import { ReactComponent as ContractIcon } from "../../assets/contract.svg";
import { ReactComponent as VendorIcon } from "../../assets/people.svg";
import { ReactComponent as OverviewIcon } from "../../assets/dashboard.svg";
import { ReactComponent as SettingsIcon } from "../../assets/settings.svg";

export const menus = [
  {
    title: "overview",
    to: "/admin/dashboard",
    children: ["overview"],
    icon: <OverviewIcon />,
  },
  {
    title: "procurement",
    to: "/admin/procurement",
    children: ["plans"],
    icon: <ProcurementIcon />,
  },
  {
    title: "vendors",
    to: "/admin/vendorsList",
    children: ["vendors"],
    icon: <VendorIcon />,
  },
  {
    title: "contracts",
    to: "/admin/contractsList",
    children: ["contracts"],
    icon: <ContractIcon />,
  },
  {
    title: "administration",
    to: "/admin/administration",
    children: ["staffs", "MDAs", "permissions", 'settings'],
    icon: <AdministrationIcon />,
  },
  {
    title: "settings",
    to: "/admin/settings",
    children: ["roles", "users", "checkers"],
    icon: <SettingsIcon />,
  },
];
