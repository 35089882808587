import styled from "styled-components";

export const ResponseStats = styled.div`
  display: grid;
  grid-template-columns: ${props => props.hasApproveStatus ? '220px auto 105px' : '220px auto' };
  height: 100px;
  background: #FAFBFC;
  border: 1px solid #C9D4DB;
  box-shadow: 0 2px 4px #C9D4DB;
  border-radius: 3px;
  margin-bottom: ${props => props.hasMargin ? '30px' : '0'};
`

export const TotalResponse = styled.div`
  background-color: white;
  padding-left: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-right: 1px solid #C9D4DB;
`
