import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import { theme } from "../../../util/theme";

export const RegisterButton = styled(Button)`
  box-sizing: border-box;
  height: 75px;
  width: 100%;
  border-radius: 3px;
  background-color: #ffffff;
  cursor: pointer;
  text-align: start;
  border: 1px solid ${theme.color.primary} !important;
  padding: 15.5px !important;

  .MuiButton-label {
    display: flex;
    justify-content: space-between;
  }
`;

export const RegisterNowText = styled(Typography)`
  color: #445571 !important;
  font-size: 16px !important;
  font-weight: bold !important;
  letter-spacing: 0;
  line-height: 22px;
`;

export const RegisterNowWrapper = styled.div`
  color: #445571;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
`;

export const ContractVendor = styled.div`
  color: #637381 !important;
  font-size: 16px !important;
  letter-spacing: 0;
  line-height: 22px !important;
  font-weight: 400;
`;
