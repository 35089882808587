import styled from "styled-components";
import { theme, FormTitle as Title } from "../../../util/theme";

export const Wrapper = styled.div`
  display: block;
  text-align: left;
  width: 100%;
`;

export const TitleText = styled.p`
  margin: 20px 0 0 0;
  height: 22px;
  color: ${theme.color.secondary};
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
`;

export const FormTitle = styled(Title)`
  margin-bottom: 4px !important;
`;
