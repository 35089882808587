import moment from "moment";
import React from "react";
import { ActivityText, ActivityTextContainer, ActivityTimeText } from "./style";

const ActivitiesDetails = ({ allActivities }) =>
  allActivities.map((eachActivity) => {
    return (
      <ActivityTextContainer key={eachActivity.id}>
        <ActivityText>{eachActivity.eventType}</ActivityText>
        <ActivityTimeText>{moment(eachActivity.createdAt).fromNow()}</ActivityTimeText>
      </ActivityTextContainer>
    );
  });

export default ActivitiesDetails;
