import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Wrapper } from "./style";
import TextField from '@material-ui/core/TextField';

const SearchInputField = (props) => {
  const { searchValue, options, id, renderOption, getOptionLabel, onInputChange, isLoading, ...inputProps } = props;

  return (
    <Wrapper>
      <Autocomplete
        noOptionsText="No search result"
        onInputChange={onInputChange}
        value={searchValue}
        clearOnBlur={false}
        renderOption={renderOption}
        id="combo-box-demo"
        options={options}
        renderInput={(params) => <TextField {...params} label={options.length ? "Click to view options" : "No option available"} variant="outlined" />}
        getOptionLabel={(option) => option.companyName || option}
        {...inputProps}
      />
    </Wrapper>
  );
};

export default SearchInputField;
