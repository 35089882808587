import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import { ReactComponent as ForwardArrowIcon } from "../../../assets/forward_arrow.svg";
import { theme } from "../../../util/theme";
import LoadingButton from "../../LoadingButton/LoadingButton";

export const Wrapper = styled.div`
  display: block;
  text-align: left;
  color: #637381;

  .MuiTypography-colorTextSecondary {
    font-size: 1rem !important;
  }
`;

export const CircularLoader = styled(CircularProgress)`
  width: 150px !important;
  height: 150px !important;
  color: ${theme.color.primary} !important;
`;

export const Description = styled.div`
  min-width: 550px;
  border-radius: 3px;
  background-color: rgba(3, 173, 235, 0.07);
  color: #03adeb;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
  display: flex;
  align-items: center;
  padding: 10px 15px;
`;

export const ForwardIcon = styled(ForwardArrowIcon)`
  width: 20px;
  fill: ${({ iconcolor }) => iconcolor};
  height: 40px;
`;

export const ErrorContainer = styled(Typography)`
  margin: 0;
  font-size: 10px !important;
  color: red;
`;

export const SecondaryWrapper = styled.div`
  padding: 30px 20px;
  box-sizing: border-box;
  border: 1px solid #e5e9ec;
  border-radius: 3px;
  background-color: #fcfcfd;
  text-align: left;
  line-height: 22px;
`;

export const DefaultButton = styled(Button)`
  box-sizing: border-box;
  height: 41px;
  min-width: 94px;
  border: 1px solid #03adeb !important;
  border-radius: 3px;
  color: ${theme.color.primary} !important;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
  text-transform: none !important;
`;

export const CancelButton = styled(DefaultButton)`  
  background-color: #ffffff !important; 
`;

export const SaveButton = styled(LoadingButton)`
  border: 1px solid #ebebeb;
  background-color: ${theme.color.primary} !important;
  color: #fff !important;
  margin-left: 20px !important;
`;

export const ApprovalButton = styled(LoadingButton)`
  border: 1px solid #3BD278 !important;
  color: #fff !important;
  margin-left: 20px !important;
  background-color: ${(props) => (props.disabled ? "#c9d4db !important" : "#3BD278")};
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
`;

export const IdentificationContainer = styled.div`
  box-sizing: border-box;
  border: 1px solid #ebebeb;
  border-radius: 3px;
  background-color: #f9fafb;
  padding: 20.5px 15.5px;
  margin-bottom: 15px;
`;

export const AddNew = styled(InputLabel)`
  font-size: 14px !important;
  color: ${theme.color.primary} !important;
  padding: ${({ padding }) => padding || "20px 0 !important"};
  text-align: right;
  cursor: pointer;
  width: 65px;
  margin-left: auto;
`;

export const SearchButton = styled(LoadingButton)`
  border-radius: 3px; 
  background-color: ${(props) => (props.disabled ? "#c9d4db !important" : "#00b0f1")};
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
  margin-left: 10px !important;
  margin-bottom: ${(props) => props.noBottom ? `` : `15px !important`};
`;

export const DangerButton = styled(LoadingButton)`
    background-color: #de3618 !important;

    &:disabled,
    &[disabled]{
    background-color: #c9d4db !important;
    cursor: not-allowed !important;
  }
`;
