import React from "react";
import { Container } from "@material-ui/core";
import { Foot, RightContent, LeftContent } from "./style";

function Footer() {
  return (
    <Container>
      <Foot>
        <RightContent>
          &copy; Copyright 2022 Prunedge Technologies. All rights reserved
        </RightContent>
        <LeftContent>Powered by: Prunedge Technologies</LeftContent>
      </Foot>
    </Container>
  );
}

export default Footer;
