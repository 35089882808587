import React, { useState } from "react";
import { Box, Modal, Button, TextField } from "@material-ui/core";
import { useQuery } from "react-query";

import { ModalBody, ModalButtons, ModalTitle } from "../../Admin/CreateProcurement/style";
import LoadingButton from "../../../components/LoadingButton/LoadingButton";
import contractsAPI from "../../../redux/api/contractAPI";
import useAlert from "../../../hooks/useAlert";

export default function DownloadJSONModal({ open, onClose, totalData }) {
  const { showAlert } = useAlert();
  const [dataCount, setDataCount] = useState(20);
  const [makeRequest, setMakeRequest] = useState(false);

  const { isLoading } = useQuery(
    [
      "awardedContract",
      {
        pageSize: dataCount,
        pageNumber: 1,
      },
    ],
    contractsAPI.getAwardedContract,
    {
      enabled: makeRequest,
      onSuccess: (data) => {
        setMakeRequest(false);
        handleDownloadJson(data);
      },
      onError: (error) => {
        setMakeRequest(false);
        showAlert({
          message: error.message,
          severity: "error",
        });
      },
    }
  );

  const handleDownloadJson = (apiData) => {
    var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(apiData?.data));
    var downloadAnchorNode = document.createElement("a");
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", `awarded-contract-${Date.now()}.json`);
    document.body.appendChild(downloadAnchorNode);
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
    onClose();
  };

  const handleChange = (event) => {
    if (event.target.value <= totalData && event.target.value > 0) setDataCount(event.target.value);
  };

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <ModalBody>
          <ModalTitle>Download JSON</ModalTitle>
          <Box mb={5}>
            <TextField
              label="Total data"
              type="number"
              defaultValue={dataCount}
              value={dataCount}
              onChange={handleChange}
              helperText={`Total data can't be more than ${totalData}`}
              variant="outlined"
              fullWidth
              InputProps={{
                inputProps: { type: "number", max: totalData, min: 0 },
              }}
            />
          </Box>
          <ModalButtons>
            <LoadingButton
              style={{
                marginLeft: "0",
              }}
              onClick={() => setMakeRequest(true)}
              loading={isLoading}
            >
              Download
            </LoadingButton>
            <Button onClick={onClose} variant="outlined">
              Cancel
            </Button>
          </ModalButtons>
        </ModalBody>
      </Modal>
    </>
  );
}
