import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { format } from "date-fns";
import { ChatMessageAuthor, ChatMessageAvatar, ChatMessageText, ChatMessageTime } from "./styles";

function ChatMessage({ children, status, avatar, author, date, sent = false, ...props }) {
  return (
    <Box display="flex" alignItems="flex-start" {...props}>
      <ChatMessageAvatar variant="rounded" src={avatar} alt={author}>
        {!avatar ? author.substr(0, 1) : null}
      </ChatMessageAvatar>
      <Box display="flex" flexDirection="column">
        <Box component="p" display="flex" alignItems="center" mb={0.625}>
          <ChatMessageAuthor>{author}</ChatMessageAuthor>
          <ChatMessageTime>
            <Typography variant="srOnly">{sent ? "Sent at" : "Received at"} </Typography>
            {!!status ? status : format(new Date(date), "H:mm a")}
          </ChatMessageTime>
        </Box>
        <ChatMessageText>{children}</ChatMessageText>
      </Box>
    </Box>
  );
}

export default ChatMessage;
