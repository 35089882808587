import { Typography } from "@material-ui/core";
import styled from "styled-components";

export const ProjectMonitorCardContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 4px #cbd4da;
  border-radius: 3px;
  padding: 20px !important;
`;

export const BorderFrame = styled.div`
  background: #ffffff;
  border: 1px solid #c9d4db;
  border-radius: 3px;
  height: 100%;
`;

export const ProjectProcureTitle = styled(Typography)`
  font-size: 16px !important;
  line-height: 24px !important;
  font-weight: 700 !important;
  color: ${({ textcolor }) => (textcolor ? textcolor : "#1e2b37")} !important;
`;
export const ProjectProcureTitleNoStyle = styled(ProjectProcureTitle)`
  font-size: 16px !important;
  line-height: 24px !important;
  font-weight: normal !important;
  width: 344px;
  margin: auto !important;
`;

export const ProjectProcureSubTitle = styled(Typography)`
  font-style: normal;
  font-weight: 600;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #5f7483;
`;

export const ProjectLabel = styled(Typography)`
  font-style: normal;
  font-weight: normal;
  font-size: 13px !important;
  line-height: 16px !important;
  color: #5f7483;
`;

export const ProjectViewMore = styled(Typography)`
  font-size: 15px !important;
  line-height: 16px;
  color: #00b0f1;
  cursor: pointer;
`;

export const ProjectDivider = styled.hr`
  border: 1px solid #cbd4da;
`;

export const ProjectSpacer = styled.div`
  flex: 1;
`;

export const EdgeRectangle = styled.div`
  background: #f2f2f2;
  border: 1px solid #c9d4db;
  border-radius: 0px 0px 0px 3px;
  width: 140px;
  height: 76px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const NoMilestoneContainer = styled.div`
  height: 294px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const InvoiceCardText = styled(Typography)`
  font-style: normal;
  font-weight: bold !important;
  font-size: 20px !important;
  line-height: 27px !important;
  text-align: center;
  color: #3f5673;
`;

export const InvoiceViewText = styled(Typography)`
  font-family: SFProText;
  font-size: 15px;
  line-height: 16px;
  text-align: center;
  text-decoration-line: underline;
  color: #00b0f1;
  cursor: pointer;
`;

export const MediaContainer = styled.div`
  background: #f4fcff;
  border: 1px solid #cbd4da;
  border-radius: 3px;
  padding: 20px !important;
`;

export const InvoiceText = styled(Typography)`
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  color: #3f5673;
`;

export const InvoiceBgContainer = styled.div`
  background: #e4e9ec;
`;

export const InvoiceCardTitle = styled(Typography)`
  font-style: normal;
  font-weight: bold !important;
  font-size: 14px !important;
  line-height: 19px !important;
  color: #3f5673;
`;

export const InvoiceStatusText = styled(Typography)`
  background: ${({ paid }) =>( paid === "true" ? "#D6EFD2" : "#FFE2C5")};
  border: 2px solid #ffffff;
  border-radius: 100px;
  color: ${({ paid }) => (paid === "true" ? "#50B83C": "#BF6712")};
  padding: 3px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 79px;
  font-size: 13px !important;
`;
