import Registration from "./Authenticated/Registration";
import Tenders from "./Authenticated/Tenders";
import VendorOverview from "./Overview";
import VendorBid from "./Bid";
import VendorBidDetails from "./BidDetails";
import VendorPlaceBid from "./PlaceBid";
import BidReport from "./BidReport";
import VendorContract from "./Contract";
import LetterOfAcceptance from "./Contract/LetterOfAcceptance";
import ContractSigning from "./Contract/ContractSigning";
import SignedContractDoc from "./Contract/SignedContract";
import Projects from "./Projects";
import RejectedContract from "./Contract/RejectedContract";
import ProjectInfo from "./ProjectInfo";
import TransactionDetails from "./Transactions/TransactionDetails";
import Transactions from "./Transactions";
import MilestoneDetails from "./MilestoneDetails";
import TaskDetails from "./TaskDetails";
import SpecialNotice from "./Procurement";
import SingleProcurement from "./Procurement/SingleProcurement";
import ExpressInterest from "./Procurement/ExpressInterest";
import InvoiceDetails from "./InvoiceDetails";
import ProjectReport from "./ProjectReport";
import PaymentStatus from "./PaymentStatus";
import VendorNotifications from "./VendorNotifications/VendorNotifications";
import CompleteBidPayment from "./CompleteBidPayment";
import LetterAccepted from "./Contract/LetterOfAcceptance/LetterAccepted";
import ProjectDetails from "./ProjectDetails";

const VendorAuthenticatedRoute = [
  {
    path: "/vendor-registration",
    component: Registration,
  },
  {
    path: "/vendor/tenders",
    component: Tenders,
  },
  {
    path: "/vendor/overview",
    component: VendorOverview,
  },
  {
    path: "/vendor/notifications",
    component: VendorNotifications,
  },
  {
    path: "/vendor/bids",
    component: VendorBid,
  },
  {
    path: "/vendor/bids/:id",
    component: VendorBidDetails,
  },
  {
    path: "/vendor/bids/:id/place",
    component: VendorPlaceBid,
  },
  {
    path: "/vendor/bids/:procurementId/:id/report",
    component: BidReport,
  },
  {
    path: "/vendor/bids/:id/complete-payment",
    component: CompleteBidPayment,
  },
  {
    path: "/vendor/contracts",
    component: VendorContract,
  },
  {
    path: "/vendor/contracts/:contractId/letter-of-acceptance",
    component: LetterOfAcceptance,
  },
  {
    path: "/vendor/contracts/:contractId/accepted",
    component: LetterAccepted,
  },
  {
    path: "/vendor/contracts/:contractId/contract-signing",
    component: ContractSigning,
  },
  {
    path: "/vendor/contracts/:contractId/signed",
    component: SignedContractDoc,
  },
  {
    path: "/vendor/projects",
    component: Projects,
  },
  {
    path: "/vendor/contracts/:contractId/rejected",
    component: RejectedContract,
  },
  {
    path: "/vendor/projects/:projectId",
    component: ProjectInfo,
    exact: true,
  },
  {
    path: "/vendor/transactions/:milestoneId/:invoiceId",
    component: TransactionDetails,
  },
  {
    path: "/vendor/projects/:projectId/milestone/:milestoneId",
    component: MilestoneDetails,
  },
  {
    path: "/vendor/projects/:projectId/milestone/:milestoneId/task/:taskId",
    component: TaskDetails,
  },
  {
    path: "/vendor/transactions",
    component: Transactions,
  },
  {
    path: "/vendor/procurement",
    component: SpecialNotice,
  },
  {
    path: "/vendor/procurement/:id",
    component: SingleProcurement,
  },
  {
    path: "/vendor/procurement/:id/express-interest",
    component: ExpressInterest,
  },
  {
    path: "/vendor/projects/:projectId/milestone/:milestoneId/invoice/:invoiceId",
    component: InvoiceDetails,
  },
  {
    path: "/vendor/projects/:projectId/report",
    component: ProjectReport,
  },
  {
    path: "/vendor/projects/:projectId/details",
    component: ProjectDetails,
  },
  {
    path: "/vendor-registration/payment-status",
    component: PaymentStatus,
  },
];

export default VendorAuthenticatedRoute;
