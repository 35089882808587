import styled from "styled-components";
import { Link } from "react-router-dom";
import { StyledTableRow } from "../../../components/SearchTable";
import { theme } from "../../../util/theme";
import { PageTitle } from "../../Common/style";
import { SaveButton } from "../../../components/Forms/Common/style";

export const VendorTableStyle = styled(StyledTableRow)`
  &:hover {
    border-left: 5px solid transparent;
    border-left-color: ${theme.color.primary};
    cursor: pointer;
  }
`;

export const Url = styled.a`
  display: block;
  height: 16px;
  width: 108px;
  color: #03adeb;
  font-size: 12px !important;
  letter-spacing: 0;
  line-height: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
`;

export const VendorViewAllTextContainer = styled.div`
  margin-top: 15px !important;
`;

export const VendorViewAllText = styled(Link)`
  margin-top: 15px !important;
  color: #00B0F1 !important;
  font-size: 12px !important;
  text-align: left;
`;

export const VendorTitlePage = styled(PageTitle)`
  margin-bottom: 5px !important;
`;

export const VendorSearchButton = styled(SaveButton)`
  margin-left: 10px !important;
  width: 89px !important;
`;
