import styled from "styled-components";

export const Wrapper = styled.div`
  margin-bottom: 30px;
  padding: 20px;
  background-color: ${({ status }) => {
    if (status === "PROCESSING") return "#F2994A";
    if (status === "SHORTLISTED") return "#6FCF97";
  }};
  border-radius: 3px;
  font-size: 14px;
  line-height: 21px;
  color: #212b36;
  display: flex;
  align-items: center;

  .icon-wrapper {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: ${({ status }) => {
      if (status === "PROCESSING") return "#F2F2F2";
      if (status === "SHORTLISTED") return "#219653";
    }};
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #fff;
  }
`;

export const Title = styled.p`
  margin-bottom: 5px;
  font-weight: bold;
`;

export const Subtitle = styled.p``;
