import styled from "styled-components";
import { Link } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { StyledTableRow } from "../../../components/SearchTable";
import { theme } from "../../../util/theme";
import { PageTitle } from "../../Common/style";

export const VendorTableStyle = styled(StyledTableRow)`
  &:hover {
    border-left: 5px solid transparent;
    border-left-color: ${theme.color.primary};
    cursor: pointer;
  }
`;

export const Url = styled.a`
  display: block;
  height: 16px;
  width: 108px;
  color: #03adeb;
  font-size: 12px !important;
  letter-spacing: 0;
  line-height: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
`;

export const VendorViewAllTextContainer = styled.div`
  margin-top: 15px !important;
`;

export const VendorViewAllText = styled(Link)`
  margin-top: 15px !important;
  color: #00B0F1 !important;
  font-size: 12px !important;
  text-align: left;
`;
export const VendorTitlePage = styled(PageTitle)`
  margin-bottom: 5px !important;
`;

export const NewVendorCard = styled.div`
  box-sizing: border-box;
  height: 102px;
  width: 268px;
  border: 1px solid #C9D4DB;
  border-radius: 3px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 4px 0 #C9D4DB;
  padding: 20.5px !important;
`;

export const NewVendorCardStatus = styled(Typography)`
  border-radius: 3px;
  background-color: #FEF1C9;
  color: #FF8D26 !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  letter-spacing: 0;
  line-height: 16px !important;
  width: 80px;
  padding: 3px 9px;
`;
export const NewVendorCardValue = styled(Typography)`
  color: #1E2B37;
  font-size: 26px !important;
  font-weight: 600 !important;
  letter-spacing: 0;
  line-height: 35px !important;
`;
