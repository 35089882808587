import { handleAxiosError } from "./handleApiError";
import baseAxiosMethod from "./baseAxiosMethod";
import format from "date-fns/format";

const awardOfContractApi = {};

awardOfContractApi.getRecommendedVendor = async (key, { procurementId }) => {
  try {
    const response = await baseAxiosMethod(`procurements/${procurementId}/recommendedVendor`);

    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

awardOfContractApi.uploadLetterOfIntent = async ({ deadline, file, procurementId }) => {
  try {
    const fd = new FormData();

    fd.set("Deadline", format(new Date(deadline), "yyyy-MM-dd"));
    fd.set("File", file);

    await baseAxiosMethod.post(`procurements/${procurementId}/letterOfIntentDocument`, fd);
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

awardOfContractApi.updateDeadline = async ({ deadline, activityId }) => {
  try {
   const params = {
    submissionDeadline: deadline,
    activityId
   }

    await baseAxiosMethod.patch(`procurements/${activityId}/contract-document`, params);
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

export default awardOfContractApi;
