/* eslint-disable no-nested-ternary */
import React, { useState, useContext, useEffect } from "react";
import { MenuItem } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";

import {
  PendingContainer,
  PendingInfo,
  PendingValue,
  PendingInfoContainer,
  PendingButtonContainer,
  PendingButton,
  PendingList,
  PendingListItemContainer,
  PendingSelectionContainer,
  InviteName,
  PendingCheckBox,
  DotIconElement,
  MenuList,
  LoadMoreText
} from "./style";
import ToastContext from "../../util/toastContext";
import { resendInvite, revokeInvite } from "../../redux/reducer/adminReducer";
import { getPendingStaffInvite } from '../../redux/reducer/adminReducer';

const PendingInvite = ({ RenderButton, handleCancel }) => {
  const [checkBoxesState, setCheckBoxesState] = useState({});
  const dispatch = useDispatch();
  const [loadMore, setLoadingMore] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeItem, setActiveItem] = useState(null);
  const [loading, setLoading] = useState(false);
  const { pendingStaff } = useSelector((state) => state.admin);
  const { showAlert } = useContext(ToastContext);

  const handleMenu = (event, i) => {
    setAnchorEl(event.currentTarget);
    setActiveItem(i);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setActiveItem(null);
  };

  const handleLoadMoreClick = () => {
    setLoadingMore((prev) => prev + 10);
  }

  useEffect(()=> {
    const getNewPendingInvite = () => {
      dispatch(getPendingStaffInvite({
        PageNumber: 1,
        PageSize: loadMore
      }));
    }
    getNewPendingInvite();
  }, [loadMore, dispatch])


  const handleInvite = (email, multiple = false, action = "resend") => {
    setLoading(true);

    const inviteEmails = !multiple
      ? action === "resend" ? [{ email }] : { emails: [email] }
      : action === "resend"
        ? Object.keys(checkBoxesState).map((item) => ({ email: item }))
        : { emails: Object.keys(checkBoxesState) };

    const request = action === "resend"
      ? resendInvite(inviteEmails)
      : revokeInvite(inviteEmails);

    dispatch(request)
      .then((res) => {
        setAnchorEl(null);
        setActiveItem(null);
        setLoading(false);

        if (res.payload.success) {
          setLoading(false);
          showAlert({ severity: "success", message: res.payload.message });
          return handleCancel();
        }
        setLoading(false);
        showAlert({ severity: "error", message: res.payload });
      });
  };

  const handleChange = (e) => {
    setCheckBoxesState({ ...checkBoxesState, [e.target.name]: e.target.checked });
  };

  const numberOfCheckBoxSelected = Object.values(checkBoxesState).filter((val) => val === true).length;

  const PendingListItem = (pendingInfo, i) => (
    <PendingListItemContainer key={`${pendingInfo.id}`}>
      <PendingSelectionContainer>
        <PendingCheckBox name={`${pendingInfo.email}`} onChange={handleChange} />
        <div>
          <InviteName>{pendingInfo.email}</InviteName>
          {/* <InviteRole>{pendingInfo.userType}</InviteRole> */}
        </div>
      </PendingSelectionContainer>
      <DotIconElement onClick={(e) => handleMenu(e, i)} />
      <MenuList
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={activeItem === i}
        onClose={handleClose}>
        {loading && i === activeItem
          ? <MenuItem>Please Wait...</MenuItem>
          : (
            <div>
              <MenuItem onClick={() => handleInvite(pendingInfo.email)}>Resend Invite</MenuItem>
              <MenuItem onClick={() => handleInvite(pendingInfo.email, false, "revoke")}>Revoke Invite</MenuItem>
            </div>
          )
        }
      </MenuList>
    </PendingListItemContainer>
  );

  return (
    <>
      <PendingContainer>
        <PendingInfoContainer>
          <PendingInfo>Pending Invite</PendingInfo>
          <PendingValue>{pendingStaff.totalPendingList || 0}</PendingValue>
        </PendingInfoContainer>

        {numberOfCheckBoxSelected > 1 && (
          <PendingButtonContainer>
            <PendingButton onClick={() => handleInvite(checkBoxesState, true, "revoke")}>Revoke Invite</PendingButton>
            <PendingButton onClick={() => handleInvite(checkBoxesState, true)}>Resend Invite</PendingButton>
          </PendingButtonContainer>
        )}
      </PendingContainer>

      <PendingList>{
      pendingStaff && pendingStaff?.pendingList && pendingStaff?.pendingList.map((elem, i) => PendingListItem(elem, i))}
      {(pendingStaff?.pendingList?.length < pendingStaff.totalPendingList) && (<LoadMoreText onClick={handleLoadMoreClick}>Load more...</LoadMoreText>)}
      </PendingList>
      <RenderButton cancelText="Close" hideSaveBtn={true} TextLabel="Send Invite" />
    </>
  );
};

export default PendingInvite;
