/* eslint-disable no-undef */
import baseAxiosMethod from "./baseAxiosMethod";
import { handleAxiosError } from "./handleApiError";
import { sectionedDocs } from "../../util/sectionedDocs";

const contractAwardDecisionApi = {
  getDecisionDetails: async (key, id) => {
    try {
      const response = await baseAxiosMethod(`procurements/${id}/contractAwardDecisionDocument`);
      return response.data.data;
    } catch (e) {
      throw new Error(handleAxiosError(e));
    }
  },

  submitContract: async ({
    activityId, mandatory, supporting, removed, issuedDate,
  }) => {
    const formData = sectionedDocs({ mandatory, supporting, removed, type: 'datasheet' });

    formData.append("IssuedDate", new Date(issuedDate).toISOString());

    try {
      const response = await baseAxiosMethod.post(`procurements/${activityId}/contractAwardDecisionDocument`, formData);
      return response.data.data;
    } catch (e) {
      throw new Error(handleAxiosError(e));
    }
  },
};

export default contractAwardDecisionApi;
