import React, { useContext } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { useParams } from "react-router-dom";
import { Box, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { queryCache, useMutation } from "react-query";
import { useForm } from "react-hook-form";
import { MilestoneCloseIcon, MilestoneTitleText, ModalContent } from "./style";
import { Dropdown, InputField } from "../../../../components";
import { ErrorContainer, SearchButton } from "../../../../components/Forms/Common/style";
import vendorsAPI from "../../../../redux/api/vendorsAPI";
import ToastContext from "../../../../util/toastContext";
import Dialog from "@material-ui/core/Dialog";
import { CancelButton } from "../../ProjectInfo/MilestoneModal/style";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const EditModal = ({ open = false, handleClose, task }) => {
  const classes = useStyles();
  const { showAlert } = useContext(ToastContext);
  const { taskId, milestoneId } = useParams();
  const { register, handleSubmit, errors, getValues } = useForm();
  const [editTask, { status, error, isLoading }] = useMutation(vendorsAPI.editTask, {
    onSuccess: data => {
      queryCache.setQueryData(["task", milestoneId, taskId], data)
      showAlert({
        severity: "success",
        message: "Task edited successfully"
      })
      handleClose();
    }
  });

  const handleEditTask = (data) => {
    editTask({
      milestoneId,
      taskId,
      data
    })
  }

  const renderError = (error) => (
    <ErrorContainer> {error && error.message} </ErrorContainer>
  )

  return (
    <Dialog
      open={open}
      className={classes.modal}
    >
      <ModalContent>
        <Box display="flex" justifyContent="space-between">
          <MilestoneTitleText>Edit Task</MilestoneTitleText>
          <MilestoneCloseIcon onClick={handleClose} />
        </Box>
        <Typography style={{ color: "red" }}>{status === "error" && error.message}</Typography>
        <form onSubmit={handleSubmit(handleEditTask)}>
          <InputField
            label="Task title"
            name="title"
            defaultValue={task?.title}
            style={{ width: "100%", margin: 0, marginTop: "24px" }}
            inputRef={register({
              required: "Please supply task title",
              validate: (value) => value.length > 3 || "Please supply task title",
            })}
          />
          {renderError(errors.title)}
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <InputField
                label="Start date"
                defaultValue={moment(task?.startDate).format("YYYY-MM-DD")}
                type="date"
                style={{ width: "100%", margin: 0, marginTop: "24px" }}
                name="startDate"
                inputRef={register({
                  required: "Your date must be greater than today",
                  validate: (value) => new Date(value) >= new Date() || "Your date must be greater than today",
                })}
              />
              {renderError(errors.startDate)}
            </Grid>
            <Grid item xs={6}>
              <InputField
                label="End date"
                type="date"
                name="endDate"
                defaultValue={moment(task?.endDate).format("YYYY-MM-DD")}
                style={{ width: "100%", margin: 0, marginTop: "24px" }}
                inputRef={register({
                  required: "Please supply task title",
                  validate: (value) => new Date(value) >= new Date(getValues("startDate")) || "Please supply task title",
                })}
              />
              {renderError(errors.endDate)}
            </Grid>
            <Grid item xs={6}>
              <Dropdown
                values={[{ label: "Naira", value: 0 }]}
                label="Evaluation Currency"
                style={{ width: "100%", margin: 0 }}
              />
            </Grid>
            <Grid item xs={6}>
              <InputField
                label="Value"
                defaultValue={task?.estimatedValue?.toLocaleString()}
                style={{ width: "100%", margin: 0 }}
                name="estimatedValue"
                inputRef={register({
                  required: "Please supply a number",
                  min: 0,
                  validate: (value) => value > 3 || "Please supply a number",
                })}
              />
              {renderError(errors.estimatedValue)}
            </Grid>
          </Grid>
          <InputField
            label="Task Description"
            multiline={true}
            name="description"
            defaultValue={task?.description}
            style={{ width: "100%", margin: 0, marginTop: "20px" }}
            inputRef={register({
              required: "Please supply task description",
              validate: (value) => value.length > 3 || "Please supply task description",
            })}
          />
          {renderError(errors.description)}
          <Box display="flex" justifyContent="flex-end" alignItems="center" marginTop="30px">
            <CancelButton
              disabled={isLoading}
              variant='outlined'
              onClick={handleClose}
            >
              Cancel
            </CancelButton>
            <SearchButton
              type="submit"
              disabled={isLoading}
              loading={isLoading}
              noBottom
            >
              Update task
            </SearchButton>
          </Box>
        </form>
      </ModalContent>
    </Dialog>
  );
};

EditModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  task: PropTypes.object
}

export default React.memo(EditModal);
