import { makeStyles } from "@material-ui/styles";
import moment from "moment";
import React from "react";
import { useHistory } from "react-router-dom";
import { Tooltip } from "@material-ui/core";

import EmptyTableBody from "../../../../components/EmptyTableBody";
import { StyledTableCell } from "../../../../components/SearchTable";
import { HoverTableStyle } from "../../../../util/theme";
import { TableStatusContainer } from "../../../Common/style";
import { EmptyTableSubTitle } from "./style";

const useStyles = makeStyles({
  contractNo: {
    fontSize: 14,
    color: "#00B0F1",
    textTransform: "uppercase",
  },
});

const pickSignature = (signature) => {
  switch (signature) {
    case "UNSIGNED":
      return (
        <TableStatusContainer textcolor="#BF6712" bgcolor="#FFE2C5">
          {signature?.toLowerCase()}
        </TableStatusContainer>
      );
    default:
      return (
        <TableStatusContainer textcolor="#00B0F1" bgcolor="rgba(0,176,241,0.2)">
          {signature}
        </TableStatusContainer>
      );
  }
};

const ContractTable = ({ contracts = [], urlLink }) => {
  const classes = useStyles();
  const history = useHistory();

  return contracts.length ? (
    contracts.map((eachContract) => {
      const link = !!urlLink ? `${urlLink}/${eachContract.id}` : `/admin/contracts/${eachContract.id}`;
      return (
        <HoverTableStyle key={eachContract.id} onClick={() => history.push(link)}>
          <StyledTableCell>{eachContract?.referenceId}</StyledTableCell>
          <StyledTableCell>
            <p style={{ maxWidth: 600 }}>
              <Tooltip title={eachContract.title} aria-label="add">
                <p className={classes.contractNo}>
                  {eachContract.title?.length > 50 ? eachContract.title.substring(0, 50) + "..." : eachContract.title}
                </p>
              </Tooltip>
              <p>{eachContract.description}</p>
            </p>
          </StyledTableCell>
          <StyledTableCell>{eachContract?.ministry}</StyledTableCell>
          <StyledTableCell>{eachContract?.contractor?.companyName || "N/A"}</StyledTableCell>
          <StyledTableCell>{pickSignature(eachContract.status)}</StyledTableCell>
          <StyledTableCell>{moment(eachContract.startDate).format("DD MMM, YYYY")}</StyledTableCell>
          <StyledTableCell>{moment(eachContract.endDate).format("DD MMM, YYYY")}</StyledTableCell>
          <StyledTableCell>&#8358;{eachContract?.estimatedValue?.toLocaleString() || "N/A"}</StyledTableCell>
        </HoverTableStyle>
      );
    })
  ) : (
    <EmptyTableBody>
      <EmptyTableSubTitle>There are currently no contracts</EmptyTableSubTitle>
    </EmptyTableBody>
  );
};

export default ContractTable;
