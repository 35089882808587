import styled from "styled-components";

export const Foot = styled.div`
  box-sizing: border-box;
  display: flex;
  height: 19px;
  width: 1120px;
  margin-bottom: 23.5px;
  justify-content: space-between;
`;

export const RightContent = styled.div`
  height: 19px;
  width: 343px;
  color: #919eab;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
`;

export const LeftContent = styled.div`
  height: 19px;
  width: auto;
  color: #919eab;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
  text-align: right;
`;
