import { createAsyncThunk } from "@reduxjs/toolkit";
import usersAPI from "../api/usersAPI";
import handleApiError, { handleApiErrorModified } from "../api/handleApiError";
import vendorsAPI from "../api/vendorsAPI";
import adminAPI from "../api/adminAPI";
import contractsAPI from "../api/contractAPI";
import commentApi from "../api/commentApi";
import procurementAPI from "../api/procurementAPI";

const Api = {
  "users/login": usersAPI.login,
  "users/signup": usersAPI.signup,
  "users/resendInvite": usersAPI.resendInvite,
  getUserByToken: usersAPI.getUserByToken,
  postAttestation: vendorsAPI.postAttestation,
  saveGeneralCompanyDetails: vendorsAPI.saveGeneralCompanyDetails,
  confirmEmail: usersAPI.confirmEmail,
  forgotPassword: usersAPI.forgotPassword,
  resetPassword: usersAPI.resetPassword,
  getGeneralCompanyDetails: vendorsAPI.getGeneralCompanyDetails,
  getCountries: vendorsAPI.getCountries,
  getStates: vendorsAPI.getStates,
  productsNServices: vendorsAPI.getProducts,
  vendorProductsNServices: vendorsAPI.getVendorProducts,
  postVendorServices: vendorsAPI.postVendorServices,
  registrationPlans: vendorsAPI.getRegistrationPlans,
  getAttestation: vendorsAPI.getAttestation,
  "vendors/add-director-details": vendorsAPI.addDirectorDetails,
  getDirectorDetails: vendorsAPI.getDirectorDetails,
  getVendorRegistrationPlan: vendorsAPI.getVendorRegistrationPlan,
  postVendorRegistrationPlan: vendorsAPI.postVendorRegistrationPlan,
  postDocuments: vendorsAPI.postDocuments,
  getDocumentGroups: vendorsAPI.getDocumentGroups,
  getDocuments: vendorsAPI.getDocuments,
  deleteDocuments: vendorsAPI.deleteDocuments,
  completeProfile: vendorsAPI.completeProfile,
  deleteDirectorDetails: vendorsAPI.deleteDirectorDetails,
  editDirectorDetails: vendorsAPI.editDirectorDetails,
  getStaffSummary: adminAPI.getStaffSummary,
  userRoles: usersAPI.getUserRoles,
  getStaff: adminAPI.getStaff,
  getMinistries: adminAPI.getMinistries,
  getPendingStaffInvite: adminAPI.getPendingStaffInvite,
  inviteStaff: adminAPI.inviteStaff,
  inviteStaffs: adminAPI.inviteStaffs,
  postMinistries: usersAPI.postMinistries,
  getVendors: adminAPI.getVendors,
  getPendingVendors: adminAPI.getPendingVendors,
  getVendorsSummary: adminAPI.getVendorsSummary,
  getContracts: contractsAPI.getContracts,
  getComments: commentApi.getComments,
  addComment: commentApi.addComment,
  getContractsSummary: contractsAPI.getContractsSummary,
  savePlanDocument: procurementAPI.savePlanDocument,
  updateStaffStatus: adminAPI.updateStaffStatus,

  createProcurementPlan: procurementAPI.createProcurementPlan,
  getProcurementPlan: procurementAPI.getProcurementPlan,

  getProcurementCategories: procurementAPI.getProcurementCategories,
  getProcessTypes: procurementAPI.getProcessTypes,
  getProcurementMethods: procurementAPI.getProcurementMethods,
  getQualificationMethods: procurementAPI.getQualificationMethods,
  getReviewMethods: procurementAPI.getReviewMethods,

  getPackageNumber: procurementAPI.getPackageNumber,

  createActivities: procurementAPI.createActivities,
  getPresetStages: procurementAPI.getPresetStages,

  resendInvite: adminAPI.resendInvite,
  revokeInvite: adminAPI.revokeInvite,
  getSingleContract: contractsAPI.getSingleContract,
  getAdminContractDetails: contractsAPI.getAdminContractDetails,
  getGeneralPlans: procurementAPI.getGeneralPlans,
  getMinistryGeneralPlan: procurementAPI.getMinistryGeneralPlan,
  getGeneralPlansSummary: procurementAPI.getGeneralPlansSummary,
  getBidSummary: vendorsAPI.getVendorBidSummary,
  approveVendor: vendorsAPI.approveVendor,
  rejectVendor: vendorsAPI.rejectVendor,
};

export const mockThunk = (actionName) =>
  createAsyncThunk(actionName, async (data, { rejectWithValue }) => {
    try {
      const responseJSON = await Api[actionName](data);

      if (!responseJSON.success) {
        const errorMsg = handleApiError(responseJSON, responseJSON.status);
        throw errorMsg;
      }

      return responseJSON;
    } catch (error) {
      return rejectWithValue(error.message || error);
    }
  });

const baseThunkMethod = (actionName) => {
  return createAsyncThunk(actionName, async (data, { rejectWithValue }) => {
    try {
      const responseJSON = await Api[actionName](data);
      const response = await responseJSON.json();
      if (!response.success) {
        const errorMsg = handleApiError(response, responseJSON.status);
        throw errorMsg;
      }
      return response;
    } catch (error) {
      return rejectWithValue(error.message || error);
    }
  });
};

export const modifiedBaseThunkMethod = (actionName) => {
  return createAsyncThunk(actionName, async (data, { rejectWithValue }) => {
    try {
      const responseJSON = await Api[actionName](data);
      const response = await responseJSON.json();
      if (!response.success) {
        const errorMsg = handleApiErrorModified(response, responseJSON.status);
        throw new Error(JSON.stringify(errorMsg));
      }
      return response;
    } catch (error) {
      return rejectWithValue(error.message || error);
    }
  });
};

export default baseThunkMethod;
