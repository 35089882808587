import { Box } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import AdminSearchFilter from "../../../components/AdminSearchFilter";
import Breadcrumb from "../../Common/PageElements/Breadcrumb";
import EmptyTableBody from "../../../components/EmptyTableBody";
import { EmptyTableSubTitle } from "../../../components/EmptyTableBody/style";
import SearchTable, { StyledTableCell } from "../../../components/SearchTable";
import { getVendorsSummary } from "../../../redux/reducer/adminReducer";
import { getStates } from "../../../redux/reducer/vendorReducer";
import withLoader from "../../Common/HOC/withLoader";
import { ContentWrapper, TitleContainer } from "../Administration/Staff/style";
import withAdminDashboard from "../HOC/withAdminDashboard";
import {
  NewVendorCard,
  NewVendorCardStatus,
  NewVendorCardValue,
  Url,
  VendorTableStyle,
  VendorTitlePage,
  VendorViewAllText,
  VendorViewAllTextContainer
} from "./style";
import { useQuery } from "react-query";
import { ContractDropDownField, ContractInputField } from "../Contracts/style";
import adminAPI from "../../../redux/api/adminAPI";
import ToastContext from "../../../util/toastContext";

const VendorCardSummary = () => {
  const { vendorsSummary } = useSelector((state) => state.admin);

  return (
    <NewVendorCard>
      <NewVendorCardValue>{`${(vendorsSummary && vendorsSummary.total) || 0}`}</NewVendorCardValue>
      <NewVendorCardStatus>{`${(vendorsSummary && vendorsSummary.pendingTotal) || 0} Pending`}</NewVendorCardStatus>
    </NewVendorCard>
  );
};

const BreadcrumbsLink = [
  {
    url: "/admin/vendorsList/vendors", title: "Administration", forwardSlash: true
  },
  {
    url: "/admin/vendorsList/vendors", title: "Vendors", forwardSlash: true
  },
  {
    url: "/admin/vendorsList/vendors/pending", title: "Pending", forwardSlash: false
  }
];

const TableBody = ({ pendingVendors = [] }) => {
  const history = useHistory();

  return pendingVendors.length ? (
    pendingVendors.map((row) => (
      <VendorTableStyle key={`${row.id}`} onClick={() => history.push(`/admin/vendors/${row.id}`)}>
        <StyledTableCell component="th" scope="row">
          <p>
            {row.firstName} {row.lastName}
          </p>
        </StyledTableCell>
        <StyledTableCell>
          {row.vendorProfile && row.vendorProfile.registrationPlan && row.vendorProfile.registrationPlan.title}
        </StyledTableCell>
        <StyledTableCell>
          {row.state || "Delta"}, {row.country || "Nigeria"}
        </StyledTableCell>
        <StyledTableCell>
          <Url href="#">{row.vendorProfile && row.vendorProfile.website}</Url>
        </StyledTableCell>
      </VendorTableStyle>
    ))
  ) : (
    <EmptyTableBody>
      <EmptyTableSubTitle>No project yet</EmptyTableSubTitle>
    </EmptyTableBody>
  );
};

const NewVendors = () => {
  const dispatch = useDispatch();
  const { states } = useSelector((state) => state.vendor);
  const { pendingVendor } = useSelector((state) => state.admin);

  useEffect(() => {
    const fetchStates = async () => {
      await dispatch(getStates());
    };
    if (!Object.keys(states[0]).length) {
      fetchStates();
    }
  }, [dispatch, states]);

  const [filters, setFilters] = useState({
    SearchBy: "Register ID",
    Search: "",
  });

  const updateFilters = (key) => (value) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [key]: value
    }));
  };

  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10
  });

  const { data = {}, isLoading, error = {}, isError } = useQuery({
    queryFn: adminAPI.getPendingVendors,
    queryKey: [
      "pendingVendors",
      {
        pageSize: pagination.pageSize,
        pageNumber: pagination.pageNumber,
        Search: filters.Search,
        SearchBy: filters.SearchBy,
        Date: filters.Date,
        Status: filters.Status
      }
    ]
  });

  const updatePagination = (key) => value => {
    setPagination(prevPagination => ({
      ...prevPagination,
      [key]: value
    }));
  };

  const { showAlert } = useContext(ToastContext);

  useEffect(() => {
    if(isError){
      showAlert({
        severity: "error",
        message: error?.message,
        durationInMs: 3000
      });
    }
    //eslint-disable-next-line
  }, [showAlert]);

  return (
    <ContentWrapper>
      <TitleContainer>
        <Box>
          <VendorTitlePage>New Vendors</VendorTitlePage>
          <Breadcrumb values={BreadcrumbsLink}/>
        </Box>
      </TitleContainer>
      {withLoader(VendorCardSummary)({
        apis: [getVendorsSummary()],
        conditioners: ["vendorsSummary"],
        stateName: "admin"
      })}

      <VendorViewAllTextContainer>
        <VendorViewAllText to="/admin/vendors">Back to all vendors</VendorViewAllText>
      </VendorViewAllTextContainer>

      <AdminSearchFilter normalSearch={true}>
        <div style={{ display: "flex" }}>
          <ContractDropDownField
            name="search-by"
            label="Search by"
            style={{ width: "100%" }}
            values={[
              {
                label: "Register ID",
                value: "RegisterId"
              },
              {
                label: "Name",
                value: "Name",
              }
            ]}
            onChange={updateFilters("SearchBy")}
          />
          <ContractInputField
            type="text"
            label="Search"
            defaultValue="Completion of resettlement scheme"
            style={{ width: "100%" }}
            value={filters.Search}
            setValue={updateFilters("Search")}
          />
        </div>
      </AdminSearchFilter>
      <SearchTable
        rows={pendingVendor}
        columns={["name", "reg. category", "location", "url"]}
        page={pagination.pageNumber}
        setPage={updatePagination("pageNumber")}
        rowsPerPage={pagination.pageSize}
        setRowsPerPage={updatePagination("pageSize")}
        loading={isLoading}
        totalNumberOfPages={data?.pagination?.totalEntries}
      >
        <TableBody pendingVendors={data.data}/>
      </SearchTable>
    </ContentWrapper>
  );
};

export default withAdminDashboard(NewVendors);
