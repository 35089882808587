import React from "react";
import { Box, Divider } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import InputField from "../InputField";
import { SaveButton } from "../Forms/Common/style";

export default function ProfileFormField({ user, onSubmitForm, isLoading }) {
  const { register, handleSubmit } = useForm();

  const { email, firstName, lastName, phone, role, ministry, id, website } = user;

  const {
    user: { UserId: userId },
  } = useSelector((state) => state.user);

  const isAuthorized = userId === id;

  const onSubmit = (data) => {
    const formData = new FormData();

    Object.keys(data).forEach((el) => {
      if (el === "currentPassword") {
        // Did this to avoid cases where the browser autofill current password input
        if (data.newPassword === "") {
          formData.append("currentPassword", "");
        }
      } else if (!!data[el]) {
        formData.append(el, data[el]);
      }
    });

    onSubmitForm(formData);
  };

  const width = "100%";

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <InputField
        type="text"
        label="First name"
        name="firstName"
        value={firstName}
        disabled={!isAuthorized && !firstName}
        readOnly={isAuthorized === false}
        style={{ width, margin: 0, marginBottom: 15, padding: 0 }}
        inputRef={register({
          required: "Password is required",
        })}
      />
      <InputField
        type="text"
        label="Last name"
        name="lastName"
        value={lastName}
        disabled={!isAuthorized && !lastName}
        readOnly={isAuthorized === false}
        style={{ width, margin: 0, marginBottom: 15, padding: 0 }}
        inputRef={register({
          required: "Password is required",
        })}
      />
      <InputField
        type="text"
        label="Phone number"
        name="phoneNumber"
        readOnly={isAuthorized === false}
        disabled={!isAuthorized && !phone}
        value={phone}
        style={{ width, margin: 0, marginBottom: 15, padding: 0 }}
        inputRef={register}
      />
      <InputField
        type="email"
        label="Email address"
        value={email}
        disabled={isAuthorized && !email}
        name="email"
        readOnly={isAuthorized === false}
        style={{ width, margin: 0, marginBottom: 15, padding: 0 }}
        inputRef={register({
          required: "Invalid email address",
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
            message: "Invalid email address",
          },
        })}
      />
      {user.role === "Vendor" && (
        <InputField
          type="text"
          label="Website URL"
          disabled={!isAuthorized && !website}
          readOnly={isAuthorized === false}
          name="website"
          style={{ width, margin: 0, marginBottom: 15, padding: 0 }}
          inputRef={register}
        />
      )}
      <InputField
        type="text"
        label="Position"
        value={role}
        name="text"
        disabled={!isAuthorized && !role}
        readOnly={isAuthorized === false}
        style={{ width, margin: 0, marginBottom: 20, padding: 0 }}
        inputRef={register}
      />
      {user.role !== "Vendor" && (
        <InputField
          type="text"
          label="Ministry"
          name="ministry"
          value={ministry?.name}
          style={{ width, margin: 0, marginBottom: 15, padding: 0 }}
          inputRef={register}
        />
      )}
      {isAuthorized && (
        <>
          <Divider
            style={{
              marginRight: -20,
              marginLeft: -20,
              marginBottom: 20,
            }}
          />
          <InputField
            type="password"
            label="Current Password"
            name="currentPassword"
            style={{ width, margin: 0, marginBottom: 15, padding: 0 }}
            inputRef={register}
          />
          <InputField
            type="password"
            label="New Password"
            name="newPassword"
            style={{ width, margin: 0, marginBottom: 15, padding: 0 }}
            inputRef={register}
          />
          <InputField
            type="password"
            label="Confirm New Password"
            name="confirmNewPassword"
            style={{ width, margin: 0, marginBottom: 15, padding: 0 }}
            inputRef={register}
          />
          <Box display="flex" justifyContent="flex-end">
            <SaveButton loading={isLoading} type="submit">
              Save
            </SaveButton>
          </Box>
        </>
      )}
    </form>
  );
}
