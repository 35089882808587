import styled from "styled-components";

export const FilterWrapper = styled.div`
  padding: 20px 70px 40px 20px;
  background-color: white;
  border: 1px solid #C9D4DB;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 #C9D4DB;
  height: fit-content;
`;

export const FilterTitle = styled.div`
  color: #1E2B37;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 17px;
`;

export const FilterRows = styled.div`
  display: grid;
  grid-row-gap: 23px;
  margin-bottom: 24px;
`;

export const FilterInput = styled.button`
  display: grid;
  grid-column-gap: 8px;
  grid-template-columns: 16px auto;
  background-color: transparent;
  border: none;
  text-align: left;
  cursor: pointer;
  
  &:focus {
    outline: 1px solid #5C6AC4;
  }
`;

export const FilterCheckbox = styled.span`
  height: 16px;
  width: 16px;
  border: ${(props) => `1px solid ${props.checked ? "#5C6AC4" : "#212B36"}`};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
`;

export const ToggleAllButtons = styled.button`
  color: #1E2B37;
  font-size: 14px;
  font-weight: 600;
  background-color: transparent;
  border: none;
  text-align: left;

  &:focus {
    outline: 1px solid #5C6AC4;
  }
`;

export const ToggleAllDivider = styled(ToggleAllButtons)`
  margin: 0 5px;
  width: 2px;
  height: 18px;
  border-radius: 2px;
  background-color: #1E2B37;
  padding: 0;
`;

export const PageChangeButton = styled.button`
  background-color: #F8F8F9;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  border: none;
  color: #919EAB;
  cursor: pointer;
  
  &:disabled {
    background-color: #E4E9EC;
    color: #637381;
    cursor: not-allowed;
  }
  
  svg{
    width: 12px;
    top: 2px;
    position: relative;
  }
`;
