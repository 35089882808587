export const accordionContent = [
  {
    title: "Are the Procurement officers under the Civil Service Commission?",
    description: "No comment",
  },
  {
    title: "Can the Delta State Public Procurement Agency cancel a contract that has been awarded by an MDA?",
    description: "No comment",
  },
  {
    title: "Does the Law still have room for Direct Labour in the System?",
    description: "No comment",
  },
  {
    title: "Explain what is Lateral Conversion",
    description: "No comment",
  },
  {
    title: "Explain what you mean by Lowest Evaluated Responsive Bidder",
    description:
      "The bidder who offers the lowest priced bid among bidders who are qualified to perform the contract and whose bids meet the specifications in the bid document is the lowest evaluated responsive bidder.",
  },
  {
    title: "How can the Agency ensure that there is no conflict of interest in the award of contracts by the MDAs?",
    description: "No comment",
  },
  {
    title: "How do we ensure that there is value for Money under the new Public Procurement regime?",
    description: "No comment",
  },
  {
    title: "How do you intend to fill the gaps at the Directors Level?",
    description: "No comment",
  },
  {
    title: "How does the law take care of Emergencies?",
    description: "No comment",
  },
  {
    title: "How is the whole system expected to function under the new Procurement regime?",
    description: "No comment",
  },
];

export const solutionGuide = [
  {
    title: "Admin Guide",
    url:
      "https://res.cloudinary.com/prunedge/image/upload/v1623752259/E-Procurement/Documents/Delta_State_e-Procurement_User_Manual_-_Admin_Users_1_1_cgiltn.pdf",
    date: "June 2021",
  },
  {
    title: "Vendors Guide",
    url:
      "https://res.cloudinary.com/prunedge/image/upload/v1623752257/E-Procurement/Documents/Delta_e-Procurement_User_Manual_-_Vendors_1_asdyn0.pdf",
    date: "June 2021",
  },
  {
    title: "Guidelines For Registration As A Contractor With Delta State Government",
    url: "https://res.cloudinary.com/prunedge/image/upload/v1624436306/Modupe/Vendors_Registration_xwebav.pdf",
    date: "June 2021",
  },
];

export const registeredAwards = [
  {
    title: "Complex Time Based",
    url: "https://deltaeprocurement.blob.core.windows.net/webresources/Delta-State-RFP-Complex-Time-Based.pdf",
    date: "June 2020",
  },
  {
    title: "SBD for Procurement of Goods",
    url: "https://deltaeprocurement.blob.core.windows.net/webresources/Delta-State-SBD-for-Procurement-of-Goods-2.pdf",
    date: "June 2020",
  },
  {
    title: "SBD for Works",
    url: "https://deltaeprocurement.blob.core.windows.net/webresources/Delta-State-SBD-for-Works.pdf",
    date: "June 2020",
  },
  {
    title: "Administrative Structure and Control",
    url: "https://deltaeprocurement.blob.core.windows.net/webresources/PART-111-ADMINISTRATIVE-STRUCTURE-AND-CONTROL.pdf",
    date: "June 2020",
  },
  {
    title: "PART I - Establishment of the Delta State Council On Public Procurement",
    url:
      "https://deltaeprocurement.blob.core.windows.net/webresources/PART-I-ESTABLISHMENT-OF-THE-DELTA-STATE-COUNCIL-ON-PUBLIC-PROCUREMENT.pdf",
    date: "June 2020",
  },
  {
    title: "PART II - PUBLIC PROCUREMENT COMMISSION",
    url:
      "https://deltaeprocurement.blob.core.windows.net/webresources/PART-II-ESTABLISHMENT-OF-THE-PUBLIC-PROCUREMENT-COMMISSION%20-%20Copy.pdf",
    date: "June 2020",
  },
  {
    title: "PART IV - SCORE OF APPLICATION",
    url: "https://deltaeprocurement.blob.core.windows.net/webresources/PART-IV-SCORE-OFAPPLICATION.pdf",
    date: "June 2020",
  },
  {
    title: "PART IX - MISCELLANEOUS",
    url: "https://deltaeprocurement.blob.core.windows.net/webresources/PART-IX-MISCELLANEOUS-1.pdf",
    date: "June 2020",
  },
];

export const annualProcurementPlans = [
  {
    title: "Ministry of Energy 2022 Updated Annual Procurement Plan 2022",
    url:
      "https://res.cloudinary.com/prunedge/image/upload/v1656649411/E-Procurement/MINISTRY_OF_ENERGY_2022_PROCUREMENT_PLAN_1_qbkdji_nbgb6w.pdf",
    date: "June 2020",
  },
  {
    title: "Ministry of Energy 2022 Procurement Plan",
    url:
      "https://res.cloudinary.com/prunedge/raw/upload/v1647870197/Delta%20State%20Seal/MINISTRY_OF_ENERGY_2022_PROCUREMENT_PLAN_1_qbkdji.docx",
    date: "June 2020",
  },
  {
    title: "Proposed Annual Procurement Plan for Ministry of Health 2022",
    url: "https://res.cloudinary.com/prunedge/raw/upload/v1647870208/Delta%20State%20Seal/Proposed_Annual_Procurement_Plan_for_Min._of_Health_2022_1_eaenfl.xlsx",
    date: "June 2020",
  },
  {
    title: "2022 Annual Procurement Plan From For Ministry of Works",
    url: "https://res.cloudinary.com/prunedge/raw/upload/v1647870235/Delta%20State%20Seal/2022_ANNUAL_PROCUREMENT_PLAN_FROM_FOR_Ministry_of_Works_m63pjx.xlsx",
    date: "June 2020",
  },
  {
    title: "2022 Annual Procurement Plan Basic and Secondary Education",
    url: "https://res.cloudinary.com/prunedge/raw/upload/v1647870248/Delta%20State%20Seal/2022_Annual_Procurement_Plan_Basic_and_Secondary_Edi_ation_y6y0my.xlsx",
    date: "June 2020",
  },
];

export const relatedCircular = [
  {
    title: "PART IX - PROCUREMENT OF CONSULTANCY SERVICES",
    url: "https://deltaeprocurement.blob.core.windows.net/webresources/PART-IX-PROCUREMENT-OFCONSULTANCY-SERVICES.pdf",
    date: "26 May, 2020",
  },
  {
    title: "PART V - FUNDAMENTAL PRINCIPLES FOR PROCUREMENT",
    url: "https://deltaeprocurement.blob.core.windows.net/webresources/PART-V-FUNDAMENTAL-PRINCIPLES-FOR-PROCUREMENT.pdf",
    date: "26 May, 2020",
  },
  {
    title: "PART VI - ORGANIZATION  OF PROCUREMENT.pdf",
    url: "https://deltaeprocurement.blob.core.windows.net/webresources/PART-VI-ORGANIZATION-OF-PROCUREMENT.pdf",
    date: "26 May, 2020",
  },
  {
    title: "PART VIII - SPECIAL AND RESTRICTED METHOD OF PROCEREMENT.pdf",
    url:
      "https://deltaeprocurement.blob.core.windows.net/webresources/PART-VIII-SPECIAL-AND-RESTRICTED-METHOD-OF-PROCEREMENT.pdf",
    date: "26 May, 2020",
  },
  {
    title: "PART Vll - PROCUREMENT METHODS.pdf",
    url: "https://deltaeprocurement.blob.core.windows.net/webresources/PART-Vll-PROCUREMENT-METHODS.pdf",
    date: "26 May, 2020",
  },
  {
    title: "PART X - PROCUREMENT SURVEILLANCE AND REVIEW.pdf",
    url: "https://deltaeprocurement.blob.core.windows.net/webresources/PART-X-PROCUREMENT-SURVEILLANCE-AND-REVIEW.pdf",
    date: "26 May, 2020",
  },
  {
    title: "PART XI - DISPOSAL OF PUBLIC PROPERTY.pdf",
    url: "https://deltaeprocurement.blob.core.windows.net/webresources/PART-XI-DISPOSAL-OF-PUBLIC-PROPERTY.pdf",
    date: "26 May, 2020",
  },
  {
    title: "PART XII - CODE OF CONDUCT.pdf",
    url: "https://deltaeprocurement.blob.core.windows.net/webresources/PART-XII-CODE-OF-CONDUCT.pdf",
    date: "26 May, 2020",
  },
];
