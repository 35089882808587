import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import { FormSubtitle, FormTitle, theme } from "../../util/theme";

export const CardWrapper = styled(FormTitle)`
  border-radius: 3px !important;
  background-color: #ffffff !important;
  box-shadow: 0 2px 4px 0 #cbd4da !important;
  padding: 20px;
`;

export const CardLabelWrapper = styled(FormTitle)`
  margin: 0 0 10px 0 !important;
  font-size: 14px !important;
  font-weight: 601 !important;
`;

export const CardNumberText = styled(FormTitle)`
  margin: 0 !important;
  font-size: 26px !important;
  letter-spacing: 0;
  line-height: 32px !important;
  font-weight: 600 !important;
  margin-bottom: 10px !important;
`;

export const CardInfoText = styled(FormSubtitle)`
  margin: 0 0 10px 0 !important;
  font-size: 14px !important;
  letter-spacing: 0 !important;
  line-height: 16px !important;
`;

export const CardInfoTitle = styled.div`
  margin: 20px 0 7px 0 !important;
  font-size: 13px !important;
  letter-spacing: 0 !important;
  line-height: 16px !important;
  color: #637381;
  font-weight: 600 !important;
`;

export const DescriptionSection = styled.div`
  margin: 0;
`;

export const CardIconContainer = styled.div`
  border-radius: 50%;
  height: 45px;
  width: 45px;
  display: flex;
  background-color: rgba(3, 173, 235, 0.13);
  align-items: center;
  justify-content: center;
`;

export const PermissionList = styled.div`
  ${""}
  max-height: 450px;
  overflow: auto;
`;

export const PermissionListItemContainer = styled.div`
  ${""}
  ${""}
  display: flex;
  align-items: center;
  position: relative;

  .MuiCheckbox-colorSecondary.Mui-checked {
    color: ${theme.color.primary};
  }

  .MuiMenu-paper {
    box-shadow: 0 0 5px #e4e4e4;
  }
`;

export const PermissionListItemTitle = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const CircularShape = styled.div`
  border-radius: 50%;
`;

export const PermissionName = styled(Typography)`
  font-size: 14px !important;
  color: #212b36 !important;
  float: left;
`;

export const PermissionSelectionContainer = styled.div`
  display: flex;
`;

export const PermissionCheckBox = styled(Checkbox)`
  margin-right: 10px !important;
  border-radius: 50%;
`;
