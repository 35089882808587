import React, { useContext } from "react";
import { Grid } from "@material-ui/core";

import { CustomizedCardWrapper, SectionCardTitle, SectionCardLabel, SectionTimeLineWrapper, ReviewModeWrapper } from "./style";
import FormContext from "../../util/context";

const CustomizedCard = ({ title, label, onClickFn }, index) => {
  const { activeForm } = useContext(FormContext);

  return (
    <CustomizedCardWrapper
      activeForm={(activeForm === index).toString()}
      style={{ cursor: !!onClickFn && "pointer" }}
      onClick={!!onClickFn && onClickFn}
      key={title}
    >
      <SectionCardTitle>{title}</SectionCardTitle>
      <SectionCardLabel>{label}</SectionCardLabel>
    </CustomizedCardWrapper>
  );
};

const SectionTimeLine = ({ sectionCardDetails, isReviewMode }) => {
  return (
    <>
      {isReviewMode ? (
        <Grid container spacing={3}>
          <Grid item xs={9}>
            <ReviewModeWrapper>
              <h3>Review mode</h3>
              <SectionTimeLineWrapper marginBottom="0">
                {sectionCardDetails.map((value, index) => CustomizedCard(value, index))}
              </SectionTimeLineWrapper>
            </ReviewModeWrapper>
          </Grid>
        </Grid>
      ) : (
        <SectionTimeLineWrapper marginBottom="20px">
          {sectionCardDetails.map((value, index) => CustomizedCard(value, index))}
        </SectionTimeLineWrapper>
      )}
    </>
  );
};

export default SectionTimeLine;
