import React, { useState, useContext } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import ServicesByCategory from "./ServicesByCategory";
import NavigationButtons from "../../NavigationButtons";
import { getProducts, getVendorProducts, postVendorServices } from "../../../redux/reducer/vendorReducer";
import withVendorRegLoader from "../../../pages/Common/HOC/withVendorRegLoader";
import ToastContext from "../../../util/toastContext";

const ensureUserHasServices = (numCurrentServices, numToAdd, numToRemove) => ((numCurrentServices + numToAdd - numToRemove) > 0);

const Form = ({
  dispatch, userId,
}) => {
  const {
    products,
    vendorProducts,
  } = useSelector((state) => state.vendor);
  const [loading, setLoading] = useState(false);
  const { showAlert } = useContext(ToastContext);

  const { register, handleSubmit } = useForm();
  const onSubmit = (cb) => (data) => {
    setLoading(true);
    const servicesToAdd = Object.entries(data)
      .filter((field) => field[1] && !vendorProducts.includes(field[0]))
      .map((field) => field[0]);
    const servicesToRemove = Object.entries(data)
      .filter((field) => !field[1] && vendorProducts.includes(field[0]))
      .map((field) => field[0]);

    if (servicesToAdd.length === 0 && servicesToRemove.length === 0) {
      setLoading(false);
      return cb();
    }

    if (!ensureUserHasServices(vendorProducts.length, servicesToAdd.length, servicesToRemove.length)) {
      setLoading(false);
      showAlert({ severity: "error", message: "Please select some products" });
      return;
    }

    const payload = {};
    if (servicesToAdd.length) {
      payload.add = servicesToAdd;
    }
    if (servicesToRemove.length) {
      payload.remove = servicesToRemove;
    }

    dispatch(postVendorServices({ data: payload, userId }))
      .then((res) => {
        if (res.error) {
          return showAlert({ severity: "error", message: res.payload });
        }
        showAlert({ severity: "success", message: "Selected Products and Service Saved" });
        cb();
      });
  };

  return (
    <form>
      {Object.entries(products).map((service) => (
        <ServicesByCategory
          key={`services_${service[0]}`}
          service={service}
          vendorProductsIdsPerCategory={vendorProducts.length > 0
            ? vendorProducts.filter((id) => service[1].some((i) => i.id === id)) : []}
          register={register}
        />
      ))}
      <NavigationButtons
        value={loading ? "Saving..." : null}
        disabled={loading}
        nextActionHandler={(cb) => handleSubmit(onSubmit(cb))} />
    </form>
  );
};

const ProductsNServices = () => {
  const { user } = useSelector((state) => state.user);

  return withVendorRegLoader(Form)({
    title: "Product and Service Offered",
    description: "Please choose the commodity category below that best describes your products and services",
    apis: [getProducts(), getVendorProducts(user.UserId)],
    conditioners: ["vendorProducts", "products"],
    userId: user.UserId,
  });
};

export default ProductsNServices;
