import React, { useRef, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { Picker } from "emoji-mart";
import {
  ActionButtons, CommentInputWrapper, SendButton, SendButtonIcon,
} from "./style";
import { ReactComponent as AttachmentIcon } from "../../assets/attachment.svg";
import { ReactComponent as SmileyIcon } from "../../assets/smiley.svg";
import sendIcon from "../../assets/send.svg";
import "emoji-mart/css/emoji-mart.css";

const CommentInput = ({
  value, onChange, onSubmit, onUploadFile,
}) => {
  const [emojiPickerActive, setEmojiPickerActive] = useState(false);
  const inputRef = useRef();

  return (
    <CommentInputWrapper>
      <TextField
        id="outlined-textarea"
        multiline
        InputProps={{
          disableUnderline: true,
        }}
        className="text-input"
        onChange={(e) => onChange(e.target.value)}
        value={value}
      />
      <Grid className="grid" container justify="space-between" alignItems="center">
        <ActionButtons>
          <Button onClick={() => inputRef.current.click()}>
            <input
              type="file"
              style={{ display: "none" }}
              ref={inputRef}
              onChange={({ target }) => onUploadFile([...target.files].pop())}
            />
            <AttachmentIcon />
          </Button>
          <Button onClick={() => setEmojiPickerActive(!emojiPickerActive)}>
            <SmileyIcon />
            {emojiPickerActive && (
              <ClickAwayListener onClickAway={() => setEmojiPickerActive(false)}>
                <div onClick={(e) => e.stopPropagation()}>
                  <Picker
                    onSelect={(emoji) => onChange(`${value} ${emoji.native}`)}
                    style={{
                      position: "absolute",
                      bottom: "30px",
                      left: "20px",
                    }}
                  />
                </div>
              </ClickAwayListener>
            )}
          </Button>
        </ActionButtons>
        <SendButton color="primary" onClick={onSubmit}>
          <SendButtonIcon src={sendIcon} />
        </SendButton>
      </Grid>
    </CommentInputWrapper>
  );
};

export default CommentInput;
