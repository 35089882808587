import Box from "@material-ui/core/Box";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import React, { Fragment, useContext, useState } from "react";
import { useMutation, useQuery, queryCache } from "react-query";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import AccessControl from "../../../../components/AccessControl";
import { Roles } from "../../../../components/AccessControl/UserRoles";
import AdminSearchFilter from "../../../../components/AdminSearchFilter";
import Dropdown from "../../../../components/DropdownField";
import InputField from "../../../../components/InputField";
import MinistryDropdown from "../../../../components/MinistryDropdown";
import SearchTable from "../../../../components/SearchTable";
import TopStatisticsBar from "../../../../components/TopStatisticsBar";
import generalPlansAPI from "../../../../redux/api/generalPlansAPI";
import { setProcurementPlan } from "../../../../redux/reducer/adminReducer";
import { transformGeneralPlanStatus } from "../../../../util/transforms/generalPlan";
import Breadcrumb from "../../../Common/PageElements/Breadcrumb";
import { PageTitle } from "../../../Common/style";
import withAdminDashboard from "../../HOC/withAdminDashboard";
import { ResponseStatsLabel, ResponseStatsValue } from "../Activitiies/ReceiptOfResponse/style";
import { AddButton, CirclePlusIconWrapper, ContentWrapper, TitleContainer } from "../style";
import InformationAccordion from "./InformationAccordion";
import SendForApprovalDialog from "./SendForApprovalDialog";
import { ButtonContainer, DisabledButton, SendForApprovalButton } from "./style";
import SummaryCardsComponent from "./SummaryCardsComponent";
import TableBody from "./TableBody";
import Grid from "@material-ui/core/Grid";
import {
  ApprovedTag,
  NeedsAmendmentText,
  ReviewBudget,
  ReviewDetails,
  ReviewDivider,
  ReviewLabel,
  ReviewText,
  ReviewTitle,
  ReviewWrapper,
} from "../../CreateProcurement/style";
import LoadingButton from "../../../../components/LoadingButton/LoadingButton";
import Skeleton from "@material-ui/lab/Skeleton";
import Paper from "@material-ui/core/Paper";
import { formatCurrency } from "../../../../util/formatCurrency";
import format from "date-fns/format";
import ToastContext from "../../../../util/toastContext";
import { CuteModal, ModalWrapper, ModalHeader, MainText, ModalRejectBtn, ModalCloseBtn } from "../../../Vendor/Procurement/style";

const GeneralPlanDetails = () => {
  const { showAlert } = useContext(ToastContext);
  const { id } = useParams();
  const [tenderToRemove, setTenderToRemove] = useState({
    data: null,
    type: "Delete",
  });
  const summaryQuery = useQuery({
    queryKey: ["plansSummary", id],
    queryFn: generalPlansAPI.getPlansSummary,
  });

  const { data: tenderSummaryData = {} } = useQuery({
    queryKey: ["tenderSummaryQuery ", id],
    queryFn: generalPlansAPI.tenderSummaryQuery,
  });

  const dispatch = useDispatch();

  const history = useHistory();

  const GeneralPlanDetailsLink = [
    {
      url: "/admin/dashboard/overview",
      title: "Home",
      forwardSlash: true,
    },
    {
      url: "/admin/procurement/plans",
      title: "Procurement Plans",
      forwardSlash: true,
    },
    {
      url: `/admin/procurement/plans/${id}`,
      title: "Plan",
      forwardSlash: false,
    },
  ];

  const [filters, setFilters] = useState({
    Stage: null,
    Search: "",
    Status: null,
    MinistryId: null,
    Category: null,
  });

  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
  });

  const updateFilters = (key) => (value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value || null,
    }));
  };

  const updatePagination = (key) => (value) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      [key]: value,
    }));
  };

  const filterPlans = (category) => {
    if (category === filters.Category) {
      setFilters({
        ...filters,
        Category: null,
      });
    } else {
      setFilters({
        ...filters,
        Category: category || null,
      });
    }
  };

  const generalPlanQueryKey = ["generalPlans", id, { ...filters, ...pagination }];

  const generalPlanQuery = useQuery({
    queryKey: generalPlanQueryKey,
    queryFn: generalPlansAPI.getProcurementPlans,
  });

  const hasTenders = generalPlanQuery.data?.data?.length !== 0;
  const [isOpen, toggleModal] = useState(false);

  const generalPlanDetails = useQuery({
    queryKey: ["generalPlanDetails", id],
    queryFn: generalPlansAPI.getGeneralPlansDetails,
    config: {
      staleTime: 900 * 1000,
    },
  });

  const renderLeftContent = () => (
    <>
      <ResponseStatsLabel color="#5F7483">Total Tenders</ResponseStatsLabel>
      <ResponseStatsValue>{tenderSummaryData.total}</ResponseStatsValue>
    </>
  );

  const renderCenterContent = () => (
    <>
      <Box mr="auto">
        <ResponseStatsLabel color="#03ADEB">Under Review</ResponseStatsLabel>
        <ResponseStatsValue>{tenderSummaryData.underReview}</ResponseStatsValue>
      </Box>
      <Box mr="auto">
        <ResponseStatsLabel color="#FF8D26">Draft</ResponseStatsLabel>
        <ResponseStatsValue>{tenderSummaryData.draft}</ResponseStatsValue>
      </Box>
      <Box mr="auto">
        <ResponseStatsLabel color="#50B83C">Approved</ResponseStatsLabel>
        <ResponseStatsValue>{tenderSummaryData.approved}</ResponseStatsValue>
      </Box>
    </>
  );

  const [approveProcurementPlan, approveProcurementPlanQuery] = useMutation(generalPlansAPI.approvePlan, {
    throwOnError: true,
    onSuccess: () => {
      generalPlanDetails.refetch()
    }
  });

  const [needsAmendment, needsAmendmentQuery] = useMutation(generalPlansAPI.needsAmendment, {
    throwOnError: true,
  });

  const [deleteTender, deleteTenderQueryResponse] = useMutation(generalPlansAPI.deleteGeneralPlansTender, {
    onSuccess: () => {
      showAlert({
        severity: "success",
        message: "Tender was deleted successfully",
      });
      queryCache.removeQueries(generalPlanQueryKey);
      setTenderToRemove({ data: null, type: "Delete" });
    },
    onError: (error) => {
      showAlert({
        severity: "error",
        message: error.message,
      });
    },
  });

  const [archiveTender, archiveTenderQueryResponse] = useMutation(generalPlansAPI.archiveGeneralPlansTender, {
    onSuccess: () => {
      showAlert({
        severity: "success",
        message: "Tender was archived successfully",
      });
      queryCache.removeQueries(generalPlanQueryKey);
      setTenderToRemove({ data: null, type: "Delete" });
    },
    onError: (error) => {
      showAlert({
        severity: "error",
        message: error.message,
      });
    },
  });

  const handleApprove = async () => {
    try {
      await approveProcurementPlan({
        id,
      });

      showAlert({
        message: "Plan successfully approved",
        severity: "success",
        durationInMs: 3000,
      });
    } catch (e) {
      showAlert({
        message: e.message,
        severity: "error",
      });
    }
  };

  const handleNeedsAmendment = async () => {
    try {
      await needsAmendment({
        id,
      });
    } catch (e) {
      showAlert({
        message: e.message,
        severity: "error",
      });
    }
  };

  const renderBottomLeftReview = () => {
    if (generalPlanDetails.data?.status === "APPROVED") {
      return (
        <Grid container wrap="nowrap" alignItems="stretch">
          <Box mr={1.5}>
            <ApprovedTag>Approved</ApprovedTag>
          </Box>
        </Grid>
      );
    }

    if (needsAmendmentQuery.isSuccess) {
      return <NeedsAmendmentText>Sent back for amendment</NeedsAmendmentText>;
    } else {
      return (
        <Grid container>
          <Box mr={1.5}>
            <LoadingButton
              onClick={handleNeedsAmendment}
              loading={needsAmendmentQuery.isLoading}
              disabled={generalPlanDetails?.data?.status === "DRAFT" || generalPlanDetails?.data?.status === "INPROGRESS"}
            >
              Need Amendment
            </LoadingButton>
          </Box>
          <LoadingButton
            disabled={generalPlanDetails?.data?.status === "DRAFT" || generalPlanDetails?.data?.status === "INPROGRESS"}
            color="#3BD278"
            onClick={handleApprove}
            loading={approveProcurementPlanQuery.isLoading}
          >
            Approve and Continue
          </LoadingButton>
        </Grid>
      );
    }
  };

  const renderReviewBanner = () => {
    if (generalPlanDetails.isLoading) {
      return (
        <Box mb={3.75}>
          <Skeleton variant="rect" height="200px" width="100%" />
        </Box>
      );
    }

    return (
      <ReviewWrapper>
        <ReviewTitle>You are in Review mode</ReviewTitle>
        <Paper>
          <Box p={2.5}>
            <Grid container justify="space-between">
              <div>
                <ReviewText>{generalPlanDetails.data?.name}</ReviewText>
                <ReviewLabel>{generalPlanDetails.data?.description}</ReviewLabel>
              </div>
              <div>
                <ReviewLabel>Budget</ReviewLabel>
                <ReviewBudget>{formatCurrency(generalPlanDetails.data?.budget, true, true)}</ReviewBudget>
              </div>
            </Grid>
            <ReviewDivider />
            <Grid container justify="space-between">
              <ReviewDetails>
                <div>
                  <ReviewLabel>Ministry</ReviewLabel>
                  <ReviewText>{generalPlanDetails.data?.ministry?.name}</ReviewText>
                </div>
                <div>
                  <ReviewLabel>Procuring Year</ReviewLabel>
                  <ReviewText>{generalPlanDetails.data?.year}</ReviewText>
                </div>
                <div>
                  <ReviewLabel>Title</ReviewLabel>
                  <ReviewText>{generalPlanDetails.data?.title}</ReviewText>
                </div>
              </ReviewDetails>
              <div>
                <Box mb={2.5}>
                  <div>
                    <ReviewLabel rightAligned>Date Received</ReviewLabel>
                    <ReviewText rightAligned>
                      {generalPlanDetails.data?.createdAt &&
                        format(new Date(generalPlanDetails.data?.createdAt), "dd MMMM, yyyy")}
                    </ReviewText>
                  </div>
                </Box>
                {renderBottomLeftReview()}
              </div>
            </Grid>
          </Box>
        </Paper>
      </ReviewWrapper>
    );
  };

  const handleDeleteGeneralPlanTender = (event, tenderId) => {
    event.preventDefault();
    deleteTender(tenderId);
  };

  const handleArchiveGeneralPlanTender = (event, tenderId) => {
    event.preventDefault();
    archiveTender(tenderId);
  };

  return (
    <ContentWrapper>
      <TitleContainer>
        <div>
          <PageTitle>{generalPlanDetails?.data?.ministry?.name}</PageTitle>
          <Breadcrumb values={GeneralPlanDetailsLink} />
        </div>
        <AccessControl allowedRoles={[Roles.PROCUREMENT_OFFICER]}>
          <ButtonContainer>
            {transformGeneralPlanStatus(generalPlanDetails?.data) === "Draft" && (
              <AddButton
                onClick={() => {
                  dispatch(setProcurementPlan({}));
                  history.push(`/admin/procurement/plans/${id}/create/keydetails/new`);
                }}
              >
                <CirclePlusIconWrapper /> Add Tender
              </AddButton>
            )}
            {hasTenders && transformGeneralPlanStatus(generalPlanDetails?.data) === "Draft" && (
              <SendForApprovalButton onClick={toggleModal}>
                <CheckOutlinedIcon style={{ marginRight: "10px" }} />
                Send For Approval
              </SendForApprovalButton>
            )}
            {!hasTenders && (
              <DisabledButton disabled={true}>
                <CheckOutlinedIcon style={{ marginRight: "10px" }} />
                Send For Approval
              </DisabledButton>
            )}
          </ButtonContainer>
        </AccessControl>
      </TitleContainer>

      <AccessControl allowedRoles={[Roles.DSPPC]}>{renderReviewBanner()}</AccessControl>

      <AccessControl allowedRoles={[Roles.DSPPC, Roles.MINISTRY_OF_JUSTICE]}>
        <TopStatisticsBar
          status="pending"
          hasApproveStatus={true}
          leftContent={renderLeftContent()}
          centerContent={renderCenterContent()}
        />
      </AccessControl>

      <InformationAccordion
        hasTenders={hasTenders}
        planDetails={generalPlanDetails.data}
        isLoading={generalPlanDetails.isLoading}
      />
      <SummaryCardsComponent
        filterPlans={filterPlans}
        activeCategory={filters.Category}
        loading={summaryQuery.isLoading}
        summary={summaryQuery?.data}
      />
      <Fragment>
        <AdminSearchFilter normalSearch>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <InputField type="text" label="Search name" value={filters.Search} setValue={updateFilters("Search")} required />
            <AccessControl allowedRoles={[Roles.DSPPC]}>
              <MinistryDropdown onChange={updateFilters("ministryId")} useReactQuery />
            </AccessControl>
            <Dropdown
              name="status"
              label="Status"
              onChange={updateFilters("Status")}
              values={[
                {
                  label: "All",
                  value: null,
                },
                {
                  label: "Approved",
                  value: "APPROVED",
                },
                {
                  label: "In Review",
                  value: "INREVIEW",
                },
                {
                  label: "Draft",
                  value: "DRAFT",
                },
              ]}
            />
            <Dropdown
              name="stage"
              label="Stage"
              onChange={updateFilters("Stage")}
              values={[
                {
                  label: "All",
                  value: null,
                },
                {
                  label: "Ongoing",
                  value: "ONGOING",
                },
                {
                  label: "Completed",
                  value: "COMPLETED",
                },
              ]}
            />
          </div>
        </AdminSearchFilter>
        <SearchTable
          rows={generalPlanQuery.data}
          columns={["package no/name", "category", "method", "status", "stage", "budget", ""]}
          loading={generalPlanQuery.isLoading}
          page={pagination.pageNumber}
          setPage={updatePagination("pageNumber")}
          rowsPerPage={pagination.pageSize}
          setRowsPerPage={updatePagination("pageSize")}
          totalNumberOfPages={generalPlanQuery.data?.pagination?.totalEntries}
        >
          <TableBody
            setTenderToRemove={setTenderToRemove}
            filtered={generalPlanQuery.data?.data}
            history={history}
            id={id}
            planDetails={generalPlanDetails.data}
          />
        </SearchTable>
      </Fragment>
      <SendForApprovalDialog isOpen={isOpen} toggleModal={toggleModal} />
      <CuteModal open={Boolean(tenderToRemove?.data)}>
        <ModalWrapper>
          <ModalHeader>{`${tenderToRemove.type} "${tenderToRemove?.data?.name}" tender`}</ModalHeader>
          <MainText mt="30px">{`Proceed to ${tenderToRemove.type.toLowerCase()} this tender. You cannot undo this action`}</MainText>
          <MainText mt="30px"></MainText>
          <Box display="flex" justifyContent="flex-start">
            <ModalRejectBtn
              disabled={deleteTenderQueryResponse.isLoading || archiveTenderQueryResponse.isLoading}
              onClick={(event) => {
                tenderToRemove?.type === "Delete"
                  ? handleDeleteGeneralPlanTender(event, tenderToRemove?.data?.id)
                  : handleArchiveGeneralPlanTender(event, tenderToRemove?.data?.id);
              }}
            >
              {deleteTenderQueryResponse.isLoading || archiveTenderQueryResponse.isLoading
                ? "Please wait..."
                : `${tenderToRemove.type}`}
            </ModalRejectBtn>
            <ModalCloseBtn onClick={() => setTenderToRemove({ data: null, type: "Delete" })}>Close</ModalCloseBtn>
          </Box>
        </ModalWrapper>
      </CuteModal>
    </ContentWrapper>
  );
};

export default withAdminDashboard(GeneralPlanDetails);
