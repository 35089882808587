import React, { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import procurementAPI from "../../../../../redux/api/procurementAPI";
import UploadDocumentTab from "../../../../../components/Common/HOC/withActivityLayout/Document";

const DocumentTab = forwardRef(({ setTabIndex, showAlert, isChecker }, ref) => {
  const { activityId = "" } = useParams();
  const [documents, setDocuments] = useState({ mandatory: [], supporting: [], removed: [] });
  const [postDocument, postResponse] = useMutation(procurementAPI.savePlanDocument);
  const getDocuments = useQuery(["getDocuments", activityId], procurementAPI.getProcurementPlanDocument);
  
  useEffect(() => {
    if (getDocuments.isSuccess && getDocuments.data?.length) {
      const uploadedDocuments = getDocuments.data?.reduce((currDocuments, file) => {
        const moddedFile = {
          inDb: true,
          id: file.id,
          name: file.file.original_filename,
        };

        if (file.procurementDocumentStatus === "MANDATORY") {
          return {
            ...currDocuments,
            mandatory: currDocuments.mandatory.concat(moddedFile),
            removed: [],
          };
        }
        return {
          ...currDocuments,
          supporting: currDocuments.supporting.concat(moddedFile),
          removed: [],
        };
      }, {
        mandatory: [],
        supporting: [],
      });

      setDocuments(uploadedDocuments);
    }

    if (getDocuments.isError || postResponse.isError) {
      showAlert({
        severity: "error",
        message: getDocuments?.error?.message || postResponse?.error?.message,
      });
    }
    if (postResponse.isSuccess) {
      setTabIndex(1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postResponse.isSuccess, getDocuments.isSuccess, getDocuments.isError, postResponse.isError]);

  useImperativeHandle(ref, () => ({ 
    async handleSubmit() {
      if (isChecker){
        setTabIndex(1)
    }
    else{
      await postDocument({
        ...documents,
        activityId,
      });
     }
    },
    goBack() {
      return
    }
  }));

  return (
    <UploadDocumentTab
      documents={documents}
      setDocuments={setDocuments}
      loading={getDocuments.isLoading}
    />
  )
});

export default DocumentTab;
