import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import InputBase from "@material-ui/core/InputBase";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { MenuItem } from "@material-ui/core";

export const Nav = styled(Grid)`
  box-sizing: border-box;
  height: 62px;
  width: 100%;
  border: 1px solid #cbd4da;
  background-color: #f4f6f8;
  padding: 0 25px;
`;

export const CustomSearch = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 42px;
  max-width: 461px;
  background-color: #ffffff;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 #cbd4da;
`;

export const CustomInputBase = styled(InputBase)`
  width: 100%;
  padding-right: 10px;

  input {
    height: 22px;
    width: 100%;
    color: #454f5b;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
  }
`;

export const IconWrapper = styled.div`
  padding: 10px;
  width: 20px;
  height: 20px;
`;

export const ProfileInfoWrapper = styled(Grid)`
  box-sizing: border-box;
  height: 43px;
  background-color: #fff;
  padding: 0 5px 0 10px;
  cursor: pointer;
  min-width: 196px !important;
  border: 1px solid #ebebeb;
  border-radius: 5px;
`;

export const ProfilePicWrapper = styled.div`
  height: 24px;
  width: 24px;
  background-color: #de3618;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  text-transform: uppercase;
`;

export const ProfilePic = styled.p`
  height: 16px;
  width: 18px;
  color: #fff;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
`;

export const UserName = styled.p`
  height: 12px;
  color: #212b36;
  letter-spacing: 0;
  line-height: 12px !important;
  text-align: right;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
`;

export const UserRole = styled.p`
  height: 12px;
  color: #637381;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 12px;
  text-align: right;
`;
export const UserInfo = styled.div``;

export const UserArrowDropDownIcon = styled(ArrowDropDownIcon)`
  color: #919eab;
`;

export const UserNotificationBell = styled(NotificationsIcon)`
  color: #919eab;
`;

export const SearchResults = styled.div`
  padding: 10px;
  > span {
    font-size: 10px;
    color: #424f5c;
    padding: 10px;
  }
`;

export const SearchResult = styled(MenuItem)`
  padding: 10px;
  margin: 10px 0;
  width: 100%;
  > div {
    width: 100%;
  }
  .searchType {
    background: ${(props) => props.typeTheme.bgColor};
    border-radius: 3px;
    color: ${(props) => props.typeTheme.textColor};
    text-transform: uppercase;
    font-weight: 600;
    font-size: 9px;
    margin-right: 20px;
    padding: 2px 5px;
    display: flex;
    align-items: center;
  }
  .searchText {
    font-size: 10px;
    color: #424f5c;
  }
  .select {
    font-size: 10px;
    color: #8e9fac;
  }
  .arrow {
    border: 1px solid #ffffff;
    color: #fff;
    background: #8e9fac;
    font-weight: bold;
    padding: 0 5px;
    border-radius: 5px;
    margin-left: 10px;
  }
`;

export const LoaderContainer = styled.div`
  height: 100%;
  width: 100%; 
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
 
`;
