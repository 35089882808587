import React from "react";

import { DocumentUploadContainer, GeneralCardContentWrapper } from "./style";
import FileUploadField from "../FileUploadField";

const GridFileUpload = ({
  filesNames,
  setFilesNames,
  noMargin = false
}) => (
  <GeneralCardContentWrapper>
    <DocumentUploadContainer noMargin={noMargin}>
      <FileUploadField
        filesNames={filesNames}
        setFilesNames={setFilesNames}
        multiple={true}
      />
    </DocumentUploadContainer>
  </GeneralCardContentWrapper>
);

export default GridFileUpload;
