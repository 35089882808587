import React, { useContext, useMemo, useState } from "react";
import {
  ActionsRow,
  ButtonsRow,
  DocumentStatusIcon,
  EmptyState,
  EmptyStateDescription,
  EmptyStateHeader,
  ModdedAvatar,
  ModdedTableHead,
  TableCellEmail,
  TableTop,
  TableWrapper
} from "./style";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { ReactComponent as CheckIcon } from "../../../../../../assets/checkIcon.svg";
import { ReactComponent as CancelIcon } from "../../../../../../assets/cancel.svg";
import { ReactComponent as RightIcon } from "../../../../../../assets/right_icon.svg";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import Box from "@material-ui/core/Box";
import TablePagination from "@material-ui/core/TablePagination";
import { useHistory, useParams } from "react-router-dom";
import useApproveVendor from "../hooks/useApproveVendor";
import LoadingButton from "../../../../../../components/LoadingButton/LoadingButton";
import ToastContext from "../../../../../../util/toastContext";
import useRejectVendor from "../hooks/useRejectVendor";
import { useRoles } from "../../../../../../hooks/useUserDetails";
import { Roles } from "../../../../../../components/AccessControl/UserRoles";
import AccessControl from "../../../../../../components/AccessControl";


const EnhancedTableHead = ({ numRows, numSelected, onToggleCheckAll }) => {
  return (
    <>
      <ModdedTableHead>
        <TableCell padding="checkbox">
          <Checkbox
            checked={numRows === numSelected}
            onChange={onToggleCheckAll}
            indeterminate={numSelected > 0 && numSelected < numRows}
          />
        </TableCell>
        <TableCell>
          NAME
        </TableCell>
        <TableCell>
          REGISTRATION CLASS
        </TableCell>
        <TableCell>
          DOCUMENTS
        </TableCell>
        <TableCell align='right'>
          ACTION
        </TableCell>
      </ModdedTableHead>
    </>
  );
};

const EnhancedTableRow = ({ checked, name, email, REGID, documents, onToggleCheck, id, approveVendor, isLoading, rejectVendor, allowedRoles}) => {
  const {role} = useRoles();
  const { procurementId = "", activityId } = useParams();
   const documentStatusColor = useMemo(() => {
    if (documents.count === documents.total) {
      return "#219653";
    } else if (documents.count === 0) {
      return "#F2F2F2";
    } else {
      return "#F2994A";
    }
  }, [documents]);

  const history = useHistory();

  const redirectUser = () => {
    history.push(`/admin/procurement/activity/receiptofresponses/${activityId}/${procurementId}/vendorsDetails/${id}/${name}`);
  };

  return (
    <TableRow>
      <TableCell padding="checkbox">
        <Checkbox checked={checked} onChange={onToggleCheck}/>
      </TableCell>
      <TableCell>
        <Grid spacing={1} container wrap='nowrap'>
          <Box mr={2}>
            <ModdedAvatar size={34} color='#50B83C'>
              {name?.slice(0, 2)}
            </ModdedAvatar>
          </Box>
          <Grid
            container
            direction='column'
            spacing={1}
          >
            <span>
              {name}
            </span>
            <TableCellEmail>
              {email}
            </TableCellEmail>
          </Grid>
        </Grid>
      </TableCell>
      <TableCell>
        {REGID}
      </TableCell>
      <TableCell>
        <Grid container>
          <span>
            Uploaded {documents.count} of {documents.total}
          </span>
          <DocumentStatusIcon size={20} color={documentStatusColor}>
            <CheckIcon fill='white' width={7}/>
          </DocumentStatusIcon>
        </Grid>
      </TableCell>
      <TableCell>
        { allowedRoles.includes(role) ? (
        <ActionsRow>  
          <IconButton onClick={approveVendor}>
            <CheckIcon fill='#219653' width={18}/>
          </IconButton>
          <IconButton onClick={rejectVendor}>
            <CancelIcon fill='#EB5757' width={18}/>
          </IconButton>      
          <IconButton onClick={redirectUser} style={{float: "right"}}>
            <RightIcon fill='#637381' width={18}/>
          </IconButton>
        </ActionsRow>
        ):(
        <IconButton onClick={redirectUser} style={{float: "right"}}>
            <RightIcon fill='#637381' width={18}/>
        </IconButton>
        )}
      </TableCell>
    </TableRow>
  );
};

const VendorsTable = ({ pagination, setPagination, vendors, totalCount }) => {
  const allowedRoles = [Roles.PROCUREMENT_OFFICER];
  const [checked, setChecked] = useState(new Set());

  const { approve, isLoading: approveIsLoading } = useApproveVendor({ queryKey: ["interestedVendors"] });
  const { reject, isLoading: rejectIsLoading } = useRejectVendor({ queryKey: ["interestedVendors"] });

  const { hideAlert, showAlert } = useContext(ToastContext);

  const onCheckRow = (idx) => () => {
    const currentChecked = new Set(checked);

    if (currentChecked.has(idx)) {
      currentChecked.delete(idx);
    } else {
      currentChecked.add(idx);
    }

    setChecked(currentChecked);
  };

  const onToggleCheckAll = () => {
    if (vendors.length === checked.size) {
      setChecked(new Set());
    } else {
      setChecked(new Set(vendors.map(({id}) => id)));
    }
  };

  const onChangePage = (event, value) => {
    setChecked(new Set());
    setPagination({
      ...pagination,
      pageNumber: value + 1
    });
  };

  const onChangeRowsPerPage = (event) => {
    setChecked(new Set());
    setPagination({
      pageNumber: 1,
      pageSize: parseInt(event.target.value)
    });
  };

  const approveRow = (id) => () => {
    showAlert({
      severity: "info",
      message: "Approving vendor",
      durationInMs: 3000
    });

    approve([id], hideAlert);
  };

  const rejectRow = (id) => () => {
    showAlert({
      severity: "info",
      message: "Rejecting vendor",
      durationInMs: 3000
    });

    reject([id], hideAlert);
  };

  const onClickApprove = () => {
    approve([...checked]);
  };

  const onClickReject = () => {
    reject([...checked])
  }

  return (
    <>
      <TableTop>
        <span>
          Showing {vendors.length} results
        </span>
        {
          (checked.size > 0) && (
            <AccessControl allowedRoles={allowedRoles}>
            <ButtonsRow>
              <Box mr={3}>                
                <LoadingButton
                  color='#219653'
                  variant="contained"
                  onClick={onClickApprove}
                  loading={approveIsLoading}
                >
                  Approve
                </LoadingButton>              
              </Box>              
              <LoadingButton
                color='#EB5757'
                variant="contained"
                onClick={onClickReject}
                loading={rejectIsLoading}
              >
                Reject
              </LoadingButton>             
            </ButtonsRow>
            </AccessControl>
          )
        }
      </TableTop>
      <TableWrapper>
        {
          vendors.length > 0 ? (
            <Table>
              <EnhancedTableHead
                numSelected={checked.size}
                numRows={vendors.length}
                onToggleCheckAll={onToggleCheckAll}
              />
              {
                vendors.map((data) => (
                  <EnhancedTableRow
                    key={data.id}
                    {...data}
                    checked={checked.has(data.id)}
                    onToggleCheck={onCheckRow(data.id)}
                    approveVendor={approveRow(data.id)}
                    rejectVendor={rejectRow(data.id)}
                    allowedRoles= {allowedRoles}
                  />
                ))
              }
            </Table>
          ) : (
            <EmptyState>
              <EmptyStateHeader>
                All caught up!
              </EmptyStateHeader>
              <EmptyStateDescription>
                You do not have any pending Expression of Interest to attend to at the moment
              </EmptyStateDescription>
            </EmptyState>
          )
        }
        <Box py={3}>
          <Grid container justify='flex-end'>
            <TablePagination
              count={totalCount}
              rowsPerPage={pagination.pageSize}
              page={pagination.pageNumber - 1}
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
            />
          </Grid>
        </Box>
      </TableWrapper>
    </>
  );
};

export default VendorsTable;
