import React from "react";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import { TimelineWrapper, Item } from "./style";
import { ReactComponent as CheckIcon } from "../../assets/checkIcon.svg";
import { FormSubtitle } from "../../util/theme";

const CustomTimeline = ({ timelineValues, completed, activeTimeline }) => (
    <TimelineWrapper>
      {
        timelineValues.map(({ title, subtitle }, i) => (
          <Item
            key={`${title}-${i}`}
            completed={(completed.includes(i)) ? "true" : undefined}
            active={(i === activeTimeline) ? "true" : undefined}
          >
            <TimelineSeparator>
              <TimelineDot variant="outlined">
                <CheckIcon />
              </TimelineDot>
              {timelineValues.length - 1 !== i && <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent>
              <FormSubtitle style={{cursor: "pointer"}} onClick={() => {
                window.location.href = `${window.location.origin}/vendor-registration?stage=${i + 1}`
              }}>
                {title}
              </FormSubtitle>
              {i === activeTimeline && <p>{subtitle}</p>}
            </TimelineContent>
          </Item>
        ))
      }
    </TimelineWrapper>
)

export default CustomTimeline;
