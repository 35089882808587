import React from "react";
import { Wrapper, Divider, Title, Subtitle, DetailItem, Tag } from "./style";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { formatCurrency } from "../../../../../util/formatCurrency";

const labels = {
  NOTRECOMMENDED: "Not Recommended",
};

const reasonTitles = {
  NOTRECOMMENDED: "no recommendation",
  EVALUATED: "evaluation without recommendation",
  REJECTED: "rejection",
  RECOMMENDED: "recommendation",
};

const getTagLabel = (type) => {
  if (labels[type]) return labels[type];
  const typeLowercase = type.toLowerCase();
  return type.substr(0, 1) + typeLowercase.substr(1);
};

const getReasonTitle = (type) => reasonTitles[type];

export default function ReportItem({ report, ...props }) {
  const renderDetail = (label, text, span = 4) => (
    <Grid item xs={span}>
      <DetailItem label={label} text={text} />
    </Grid>
  );

  const reportType = report.type.toUpperCase();

  return (
    <Wrapper type={reportType} {...props}>
      <Box display="flex" justifyContent="space-between" alignItems="flex-start">
        <Box>
          <Title>{report.vendorProfile.companyName}</Title>
          <Subtitle>Registry ID: {report.vendorProfile.cacRegistrationNumber ?? "N/A"}</Subtitle>
        </Box>
        <Tag type={reportType}>{getTagLabel(reportType)}</Tag>
      </Box>
      <Divider />
      <Grid container spacing={2}>
        {renderDetail("Amount", formatCurrency(report.amount ?? 0, true))}
        {/*
{renderDetail("Bid Opening Price", formatCurrency(report.bidPrice ?? 0, true))}
{renderDetail("Evaluated Price", formatCurrency(report.evaluatedPrice ?? 0, true))} 
*/}
      </Grid>
      <Divider />
      <Grid container>{renderDetail(`Reason for ${getReasonTitle(reportType)}`, report.reason, 6)}</Grid>
    </Wrapper>
  );
}
