import styled from "styled-components";

export const HeroBackground = styled.section`
  background-image: url("https://cdn.telanganatoday.com/wp-content/uploads/2020/05/STUDENTS.jpg");
  background-repeat: no-repeat;
  background-size: cover;
`;
export const HeroContent = styled.div`
  height: 100%;
  background-color: rgba(0, 0, 0, 0.71);
  display: flex;
  flex-direction: column;
  justify-content: center;
  h1 {
    width: 600px;
    color: #ffffff;
    font-size: 40px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 54px;
  }
  hr {
    box-sizing: border-box;
    height: 1px;
    width: 72px;
    border: 4px solid #ffffff;
    margin: 30px 0;
  }
  p {
    width: 482px;
    color: #ffffff;
    font-family: "Nunito Sans";
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 22px;
  }
`;

export const HeroCover = styled.section`
  background-image: url("https://i0.pickpik.com/photos/783/162/361/pedestrians-crossing-road-street-preview.jpg");
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const HeroImage = styled.section`
  background-color: rgba(0, 0, 0, 0.71);
  height: 100%;

  .MuiContainer-root {
    height: 100%;
  }
`;

export const HeroContentWrapper = styled.div`
  height: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 0;
`;

export const WrapperTitle = styled.div`
  width: ${(props) => `${props.width}px` || `600px`};
  color: #ffffff;
  font-size: 40px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 54px;
`;

export const TitleBorder1 = styled.div`
  box-sizing: border-box;
  height: 1px;
  width: 72px;
  border: 2px solid #ffffff;
  margin-top: ${(props) => `${props.size === "large" ? 60 : 30}px`};

  margin-bottom: 30px;
`;

export const WrapperDescription = styled.p`
  max-width: 482px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
`;
