import { Box, Grid, TableCell, TableRow } from "@material-ui/core";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import EventNoteIcon from "@material-ui/icons/EventNote";
import Skeleton from "@material-ui/lab/Skeleton";
import moment from "moment";
import { GeneralTitlePage } from "../../../../../../components/AddGeneralPlan/style";
import Breadcrumb from "../../../../../Common/PageElements/Breadcrumb";
import { PageWrapper } from "../../../../../Common/style";
import ProjectProgressBar from "../ProjectProgressBar";
import {
  InvoiceCardText,
  InvoiceViewText,
  ProjectDivider,
  ProjectLabel,
  ProjectMonitorCardContainer,
  ProjectProcureSubTitle,
  ProjectProcureTitle,
  ProjectProcureTitleNoStyle,
} from "../style";
import SearchTable, { StyledTableCell } from "../../../../../../components/SearchTable";
import ReviewCommentsSection from "../../../../../../components/ReviewCommentsSection";
import InvoiceBox from "../../../../../../assets/invoice_box.svg";
import InvoiceReceipt from "../../../../../../assets/invoice_chart.svg";
import ProjectApproval from "../../../../../../assets/project_approval.svg";
import ProjectPending from "../../../../../../assets/project_pending.svg";
import { HoverTableStyle } from "../../../../../../util/theme";
import { useQuery } from "react-query";
import procurementAPI from "../../../../../../redux/api/procurementAPI";
import { formatCurrency } from "../../../../../../util/formatCurrency";
import Loader from "../../../../../../components/Common/Loader";

const TableBody = ({ milestoneTask, history, activityId, procurementId, milestoneId, projectId }) =>
  milestoneTask?.map((eachTask, index) => (
    <HoverTableStyle
      key={index}
      onClick={() =>
        history.push(
          `/admin/procurement/activity/contractsupervisionandmanagement/${activityId}/${procurementId}/milestone/${projectId}/${milestoneId}/${eachTask.id}`
        )
      }
    >
      <StyledTableCell>{eachTask?.title || "N/A"}</StyledTableCell>
      <StyledTableCell>{(eachTask?.estimatedValue && formatCurrency(eachTask?.estimatedValue, true)) || 0}</StyledTableCell>
      <StyledTableCell>{(eachTask?.startDate && moment(eachTask?.startDate).format("DD/MM/YYYY")) || "N/A"}</StyledTableCell>
      <StyledTableCell>{(eachTask?.endDate && moment(eachTask?.endDate).format("DD/MM/YYYY")) || "N/A"}</StyledTableCell>
      <StyledTableCell>
        {eachTask?.status?.toLowerCase() === "pending" ? (
          <img src={ProjectPending} alt="checked" />
        ) : (
          <img src={ProjectApproval} alt="checked" />
        )}
      </StyledTableCell>
    </HoverTableStyle>
  ));

const ProjectMilestone = () => {
  const { activityId, procurementId, milestoneId, projectId } = useParams();
  const history = useHistory();

  const { data: milestoneDetails, isLoading: milestoneDetailIsLoading } = useQuery(
    ["milestoneDetails", { projectId, milestoneId }],
    procurementAPI.getProjectMilestoneDetails
  );

  const { data: milestoneTasks, isLoading: milestoneTasksIsLoading } = useQuery(
    ["milestoneTasks", { projectId, milestoneId }],
    procurementAPI.getProjectMilestoneTask
  );

  const { data: milestoneInvoice, isLoading: milestoneInvoiceIsLoading } = useQuery(
    ["milestoneInvoice", { milestoneId }],
    procurementAPI.getProjectMilestoneInvoice
  );

  const handleInvoicePreview = () => {
    history.push(
      `/admin/procurement/activity/contractsupervisionandmanagement/${activityId}/${procurementId}/milestone/${projectId}/${milestoneId}/invoice`
    );
  };

  return (
    <PageWrapper>
      {milestoneDetailIsLoading && <Skeleton height="100px" width="200px" />}
      {!milestoneDetailIsLoading && (
        <Box>
          <GeneralTitlePage>{milestoneDetails?.title || "N/A"}</GeneralTitlePage>
          <Breadcrumb
            values={[
              {
                url: "/admin/procurement/plans",
                title: "Home",
              },
              {
                url: `/admin/procurement/plans/${activityId}`,
                title: "Plan",
              },
              {
                url: `/admin/procurement/plan-details/${activityId}`,
                title: "Delta Irrigation Agricultural Improvement",
              },
              {
                url: `/admin/procurement/activity/contractsupervisionandmanagement/${activityId}/${procurementId}/`,
                title: "Monitoring",
              },
            ]}
          />
        </Box>
      )}

      <Box pt="20px">
        <ProjectMonitorCardContainer>
          {milestoneDetailIsLoading && (
            <Box width="100%" height="300px">
              <Loader />
            </Box>
          )}
          {!milestoneDetailIsLoading && (
            <Box display="flex">
              <Box pl="20px" width="100%">
                <Box pb="20px">
                  <ProjectProcureTitle>{milestoneDetails?.title || "N/A"}</ProjectProcureTitle>
                </Box>

                <Box pb="10px">
                  <ProjectLabel>Tender Description</ProjectLabel>
                </Box>
                <ProjectProcureSubTitle>{milestoneDetails?.description || "N/A"}</ProjectProcureSubTitle>

                <Box pt="28px" pb="20px">
                  <ProjectDivider />
                </Box>

                <Box>
                  <ProjectLabel>Value</ProjectLabel>
                  <ProjectProcureTitle>
                    {(milestoneDetails?.estimatedValue && formatCurrency(milestoneDetails?.estimatedValue, true)) || "N/A"}
                  </ProjectProcureTitle>
                </Box>

                <Box pt="28px" pb="20px">
                  <ProjectDivider />
                </Box>
                <Box>
                  <Grid container>
                    <Grid item xs={6}>
                      <Box display="flex">
                        <EventNoteIcon />
                        <Box ml="12px">
                          <ProjectLabel>Start Date</ProjectLabel>
                          <ProjectProcureSubTitle>
                            {(milestoneDetails?.startDate && moment(milestoneDetails?.startDate).format("DD/MM/YYYY")) || "N/A"}
                          </ProjectProcureSubTitle>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box display="flex">
                        <EventNoteIcon />
                        <Box ml="12px">
                          <ProjectLabel>End Date</ProjectLabel>
                          <ProjectProcureSubTitle>
                            {(milestoneDetails?.endDate && moment(milestoneDetails?.endDate).format("DD/MM/YYYY")) || "N/A"}
                          </ProjectProcureSubTitle>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              <ProjectProgressBar
                CompletedTask={milestoneDetails?.totalCompletedTask || 0}
                pendingTask={milestoneDetails?.totalPendingTask || 0}
                percentageCompleted={milestoneDetails?.percentageCompleted || 0}
                totalTask={milestoneDetails?.totalTask || 0}
                marginSet="20px"
                textTitle="Total Tasks"
              />
            </Box>
          )}
        </ProjectMonitorCardContainer>

        <Box display="flex" mt="21px">
          <Box width="100%">
            <SearchTable
              showPadding={false}
              showTableHeader={false}
              loading={milestoneTasksIsLoading}
              columns={["TASK NAME", "VALUE", "START DATE", "END DATE", ""]}
            >
              {milestoneTasks?.length < 1 && (
                <TableRow style={{ height: "400px" }}>
                  <TableCell colSpan={4}>
                    <ProjectProcureTitle>No task(s)</ProjectProcureTitle>
                    <ProjectProcureTitleNoStyle>This vendor currently has no ongoing task</ProjectProcureTitleNoStyle>
                  </TableCell>
                </TableRow>
              )}

              {milestoneTasks?.length > 0 && (
                <TableBody
                  milestoneTask={milestoneTasks}
                  milestoneId={milestoneId}
                  history={history}
                  activityId={activityId}
                  procurementId={procurementId}
                  projectId={projectId}
                />
              )}
            </SearchTable>

            <Box mt="20px">
              <ReviewCommentsSection objectId={milestoneId} />
            </Box>
          </Box>

          <Box pl="21px" width="40%">
            <ProjectMonitorCardContainer
              style={{
                minHeight: "250px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Box display={milestoneInvoiceIsLoading ? "block" : "none"}>
                <Loader />
              </Box>
              {!milestoneInvoiceIsLoading && (
                <Box>
                  {
                    <Box display={milestoneInvoice ? "none" : "block"}>
                      <Box mb="20px" textAlign="center">
                        <img src={InvoiceBox} alt="box_image" />
                      </Box>
                      <InvoiceCardText>No Invoice for this milestone</InvoiceCardText>
                    </Box>
                  }

                  <Box display={milestoneInvoice ? "block" : "none"}>
                    <Box mb="20px" textAlign="center">
                      <img src={InvoiceReceipt} alt="box_image" />
                    </Box>
                    <InvoiceCardText>You have an invoice</InvoiceCardText>
                    <InvoiceViewText onClick={handleInvoicePreview}>View more</InvoiceViewText>
                  </Box>
                </Box>
              )}
            </ProjectMonitorCardContainer>
          </Box>
        </Box>
      </Box>
    </PageWrapper>
  );
};

export default ProjectMilestone;
