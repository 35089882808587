import React from "react";
import { MainText, MainTitle, InfoCardWrapper, HL, CuteGrid } from "../../Procurement/style";
import Loader from "../../../../components/Common/Loader";
import { separateNumByComma } from "../../../../util/truncateValue";
import { useQuery } from "react-query";
import procurementAPI from "../../../../redux/api/procurementAPI";

const ContractInfo = ({ id, publishDate, deadline }) => {
  const { data: tenderDetails, isLoading } = useQuery(["tenderDetails", id], procurementAPI.getTenderDetails, { enabled: !!id });
  return (
    <div>
      <InfoCardWrapper>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <MainTitle>Procurement Information</MainTitle>
            <MainText mt="5px" fontSize="14px">
              {tenderDetails?.ministry}
            </MainText>
            <MainTitle mt="43px">{tenderDetails?.name}</MainTitle>
            <MainText mt="20px" fontSize="13px">
              Tender Description
            </MainText>
            <MainText mt="10px" fontSize="14px">
              {tenderDetails?.description}
            </MainText>
            <HL mt="20px" />
            <MainText mt="20px" fontSize="13px">
              Budget
            </MainText>
            <MainTitle>N{!!tenderDetails?.budget && separateNumByComma(tenderDetails?.budget)}</MainTitle>
            <HL mt="20px" />
            <CuteGrid>
              <div>
                <MainText mt="20px" fontSize="13px">
                  Procurement Category
                </MainText>
                <MainText fw="600" fontSize="14px">
                  {tenderDetails?.procurementCategory}
                </MainText>
              </div>
              <div>
                <MainText mt="20px" fontSize="13px">
                  Procurement Method
                </MainText>
                <MainText fw="600" fontSize="14px">
                  {tenderDetails?.procurementMethod}
                </MainText>
              </div>
              <div>
                <MainText mt="20px" fontSize="13px">
                  Tendering Method
                </MainText>
                <MainText fw="600" fontSize="14px">
                  {tenderDetails?.reviewMethod}
                </MainText>
              </div>
            </CuteGrid>
            <HL mt="20px" />
            <MainText mt="20px" fontSize="13px">
              procurement ID
            </MainText>
            <MainTitle>{tenderDetails?.packageNumber}</MainTitle>
            <div className={!deadline ? "topRightCard oneCard" : "topRightCard"}>
              <CuteGrid>
                {publishDate && (
                  <div>
                    <MainText mt="20px" mr="10px" fontSize="13px">
                      Date Published
                    </MainText>
                    <MainText fw="600" fontSize="14px">
                      {publishDate}
                    </MainText>
                  </div>
                )}
                {deadline?.length > 0 && (
                  <div>
                    <MainText mt="20px" fontSize="13px">
                      Expiry Date
                    </MainText>
                    <MainText fw="600" fontSize="14px">
                      {Array.isArray(deadline) ? deadline[0] : deadline}
                    </MainText>
                  </div>
                )}
              </CuteGrid>
            </div>
          </>
        )}
      </InfoCardWrapper>
      <MainText mt="20px" fontSize="15px" color="#00B0F1" fw="bold">
        View more
      </MainText>
    </div>
  );
};

export default ContractInfo;
