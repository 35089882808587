import React, { useState } from "react";
// import { Grid, Box } from '@material-ui/core';
// import FileDownload from '../../../../components/FileDownload';
// import { transformFileDownload } from '../../../../util/transforms/contract';
// import vendorsAPI from '../../../../redux/api/vendorsAPI';
import { useQuery } from "react-query";
import Loader from "../../../../components/Common/Loader";
import procurementAPI from "../../../../redux/api/procurementAPI";
// import { MainTitle, TopItems } from '../../../Admin/Contracts/SingleContractDetails/style';
import { EmptyDocText } from "../../Procurement/style";
import { ReactComponent as FolderIcon } from "../../../../assets/folder.svg";
import { FileBoard, CuteGrid, FileCard, FileText } from "../../Procurement/style";
import DocumentModal from "../../BidDetails/NewBidInfo/DocumentCategories/DocumentModal";

const AcceptanceDocSection = ({ procurementId }) => {
  const [selectedDocumentFolder, setSelectedDocumentFolder] = useState(null);
  const { data: documents = [] } = useQuery(["exchangedDocuments", procurementId], procurementAPI.getExchangedDocuments, {
    enabled: procurementId,
  });

  return (
    <div>
      {documents.length ? (
        <FileBoard>
          <CuteGrid start>
            {documents.map((doc) => (
              <div style={{ cursor: "pointer" }} key={doc.id} onClick={() => setSelectedDocumentFolder(doc)}>
                <FileCard>
                  <FolderIcon />
                  <FileText mt="15px" color="#00B0F1" fontSize="13px">
                    {doc.count} files
                  </FileText>
                </FileCard>
                <FileText mt="20px" fontSize="14px" wd="77%">
                  {doc.title}
                </FileText>
              </div>
            ))}
          </CuteGrid>
        </FileBoard>
      ) : (
        <EmptyDocText>No Documents</EmptyDocText>
      )}
      {documents?.isLoading && <Loader />}
      <DocumentModal selectedCategory={selectedDocumentFolder} onClose={() => setSelectedDocumentFolder(null)} />
    </div>
  );
};

export default AcceptanceDocSection;
