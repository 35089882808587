import styled from "styled-components";

export const MainWrapper = styled.div`
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 #cbd4da;
  padding: 20.5px !important;
  margin-top: ${(props) => props.mt}
`;

export const AcceptanceWrapper = styled.div`
  height: 40px;
  border-radius: 3px;
  background-color: #b5f4c2;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
