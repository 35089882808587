import styled from "styled-components";

export const NewsCardWrapper = styled.div`
  padding-left: 2px;
`;

export const RelatedPosts = styled.div`
  font-size: 20px;
  color: #1c4576;
  margin-bottom: 20px;
  font-weight: bold;
`;
