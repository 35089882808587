export const tenderLists = [
  {
    id: 1,
    service: "Goods",
    status: "Open Tender",
    description: "The development of the oke-osa local government area link road and 10 overhead bridge",
    openOrAwardedDate: "22 Feb,2020",
    closeOrExpiryDate: "12 Dec, 2020",
    category: "category A",
    ministry: "Ministry of Agric",
  },
  {
    id: 2,
    service: "Goods",
    status: "Open Tender",
    description: "The development of the oke-osa local government area link road and 10 overhead bridge",
    openOrAwardedDate: "22 Feb,2020",
    closeOrExpiryDate: "12 Dec, 2020",
    category: "category A",
    ministry: "Ministry of Agric",
  },
  {
    id: 3,
    service: "Goods",
    status: "Open Tender",
    description: "The development of the oke-osa local government area link road and 10 overhead bridge",
    openOrAwardedDate: "22 Feb,2020",
    closeOrExpiryDate: "12 Dec, 2020",
    category: "category A",
    ministry: "Ministry of Agric",
  },
  {
    id: 4,
    service: "Goods",
    status: "Open Tender",
    description: "The development of the oke-osa local government area link road and 10 overhead bridge",
    openOrAwardedDate: "22 Feb,2020",
    closeOrExpiryDate: "12 Dec, 2020",
    category: "category A",
    ministry: "Ministry of Agric",
  },
  {
    id: 5,
    service: "Goods",
    status: "Open Tender",
    description: "The development of the oke-osa local government area link road and 10 overhead bridge",
    openOrAwardedDate: "22 Feb,2020",
    closeOrExpiryDate: "12 Dec, 2020",
    category: "category A",
    ministry: "Ministry of Agric",
  },
  {
    id: 6,
    service: "Goods",
    status: "Open Tender",
    description: "The development of the oke-osa local government area link road and 10 overhead bridge",
    openOrAwardedDate: "22 Feb,2020",
    closeOrExpiryDate: "12 Dec, 2020",
    category: "category A",
    ministry: "Ministry of Agric",
  },
  {
    id: 7,
    service: "Goods",
    status: "Open Tender",
    description: "The development of the oke-osa local government area link road and 10 overhead bridge",
    openOrAwardedDate: "22 Feb,2020",
    closeOrExpiryDate: "12 Dec, 2020",
    category: "category A",
    ministry: "Ministry of Agric",
  },
  {
    id: 8,
    service: "Goods",
    status: "Open Tender",
    description: "The development of the oke-osa local government area link road and 10 overhead bridge",
    openOrAwardedDate: "22 Feb,2020",
    closeOrExpiryDate: "12 Dec, 2020",
    category: "category A",
    ministry: "Ministry of Agric",
  },
  {
    id: 9,
    service: "Goods",
    status: "Open Tender",
    description: "The development of the oke-osa local government area link road and 10 overhead bridge",
    openOrAwardedDate: "22 Feb,2020",
    closeOrExpiryDate: "12 Dec, 2020",
    category: "category A",
    ministry: "Ministry of Agric",
  },
  {
    id: 10,
    service: "Goods",
    status: "Open Tender",
    description: "The development of the oke-osa local government area link road and 10 overhead bridge",
    openOrAwardedDate: "22 Feb,2020",
    closeOrExpiryDate: "12 Dec, 2020",
    category: "category A",
    ministry: "Ministry of Agric",
  },
  {
    id: 11,
    service: "Goods",
    status: "Open Tender",
    description: "The development of the oke-osa local government area link road and 10 overhead bridge",
    openOrAwardedDate: "22 Feb,2020",
    closeOrExpiryDate: "12 Dec, 2020",
    category: "category A",
    ministry: "Ministry of Agric",
  },
  {
    id: 12,
    service: "Goods",
    status: "Open Tender",
    description: "The development of the oke-osa local government area link road and 10 overhead bridge",
    openOrAwardedDate: "22 Feb,2020",
    closeOrExpiryDate: "12 Dec, 2020",
    category: "category A",
    ministry: "Ministry of Agric",
  },
  {
    id: 13,
    service: "Goods",
    status: "Open Tender",
    description: "The development of the oke-osa local government area link road and 10 overhead bridge",
    openOrAwardedDate: "22 Feb,2020",
    closeOrExpiryDate: "12 Dec, 2020",
    category: "category A",
    ministry: "Ministry of Agric",
  },
  {
    id: 14,
    service: "Goods",
    status: "Open Tender",
    description: "The development of the oke-osa local government area link road and 10 overhead bridge",
    openOrAwardedDate: "22 Feb,2020",
    closeOrExpiryDate: "12 Dec, 2020",
    category: "category A",
    ministry: "Ministry of Agric",
  },
  {
    id: 15,
    service: "Goods",
    status: "Open Tender",
    description: "The development of the oke-osa local government area link road and 10 overhead bridge",
    openOrAwardedDate: "22 Feb,2020",
    closeOrExpiryDate: "12 Dec, 2020",
    category: "category A",
    ministry: "Ministry of Agric",
  },
  {
    id: 16,
    service: "Goods",
    status: "Open Tender",
    description: "The development of the oke-osa local government area link road and 10 overhead bridge",
    openOrAwardedDate: "22 Feb,2020",
    closeOrExpiryDate: "12 Dec, 2020",
    category: "category A",
    ministry: "Ministry of Agric",
  },
  {
    id: 17,
    service: "Goods",
    status: "Open Tender",
    description: "The development of the oke-osa local government area link road and 10 overhead bridge",
    openOrAwardedDate: "22 Feb,2020",
    closeOrExpiryDate: "12 Dec, 2020",
    category: "category A",
    ministry: "Ministry of Agric",
  },
  {
    id: 18,
    service: "Goods",
    status: "Open Tender",
    description: "The development of the oke-osa local government area link road and 10 overhead bridge",
    openOrAwardedDate: "22 Feb,2020",
    closeOrExpiryDate: "12 Dec, 2020",
    category: "category A",
    ministry: "Ministry of Agric",
  },
];
