import styled from "styled-components";
import { FormSubtitle } from "../../util/theme";

export const Wrapper = styled.div`
  padding: 25px;
  width: 485px;

  .MuiFilledInput-input {
    text-transform: none;
  }
`;

export const FormLabel = styled(FormSubtitle)`
  color: #454F5B;
  font-size: 16px !important;
  font-weight: bold !important;
  letter-spacing: 0 !important;
  line-height: 22px !important;
`;
