import Typography from "@material-ui/core/Typography";
import React from "react";
import { Filters, Header, Wrapper } from "../AdminSearchFilter/style";

const VendorSearchFilter = ({ children }) => (
  <Wrapper>
    <Header>
      <Typography>Search</Typography>
    </Header>
    <Filters>{children}</Filters>
  </Wrapper>
);

export default VendorSearchFilter;
