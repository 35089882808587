import { Box } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { format } from "date-fns";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import ListAltIcon from "@material-ui/icons/ListAlt";

import { Dropdown, InputField } from "../../../components";
import AdminSearchFilter from "../../../components/AdminSearchFilter";
import SearchTable, { StyledTableCell } from "../../../components/SearchTable";
import vendorsAPI from "../../../redux/api/vendorsAPI";
import { CardWrapper, HoverTableStyle } from "../../../util/theme";
import ToastContext from "../../../util/toastContext";
import { PageContainer, PageTitle } from "../../Common/style";
import TableEmptyContent from "../../Common/TableEmptyContent";
import {
  OverviewCardDesc,
  OverviewCardFlag,
  OverviewCardTitle,
  OverviewCardValue,
  OverviewTableCellDesc,
  OverviewTableCellId,
} from "../Overview/style";
import withVendorDashboard from "../HOC/withVendorDashboard";
import Tag from "../../../components/Tag";
import { TagWrapper } from "./style";
import { formatCurrency } from "../../../util/formatCurrency";

const statusKeys = {
  active: "grey_green",
  completed: "blue",
  inactive: "grey_dark",
};

const STATUS = {
  active: "ACTIVE",
  completed: "COMPLETED",
  inactive: "INACTIVE",
};

const capitalize = (text = "") => `${text[0]?.toUpperCase()}${text.slice(1).toLowerCase()}`;

const TableBody = ({ history, allProjects }) =>
  allProjects.map((eachProject) => (
    <HoverTableStyle key={eachProject.id} onClick={() => history.push(`/vendor/projects/${eachProject.id}`)}>
      <StyledTableCell>
        <OverviewTableCellId>{eachProject.code}</OverviewTableCellId>
        <OverviewTableCellDesc>{eachProject.title}</OverviewTableCellDesc>
      </StyledTableCell>
      <StyledTableCell>{formatCurrency(eachProject.estimatedValue, true, true)}</StyledTableCell>
      <StyledTableCell>{moment(eachProject.startDate).format("DD, MMMM, yyyy")}</StyledTableCell>
      <StyledTableCell>{moment(eachProject.endDate).format("DD, MMMM, yyyy")}</StyledTableCell>
      <StyledTableCell>
        <TagWrapper>
          <Tag text={capitalize(eachProject.status)} color={statusKeys[eachProject.status.toLowerCase()]} rounded size="small" />
        </TagWrapper>
      </StyledTableCell>
    </HoverTableStyle>
  ));

const Projects = () => {
  const {
    user: { UserId },
  } = useSelector((state) => state.user);

  const [title, setTitle] = useState(null);
  const [status, setStatus] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [expiryDate, setExpiryDate] = useState(null);
  // eslint-disable-next-line
  const [pageSize, setPageSize] = useState(10);
  // eslint-disable-next-line
  const [pageNumber, setPageNumber] = useState(1);

  const payload = {
    title,
    status,
    startDate,
    expiryDate,
    pageNumber,
    pageSize,
    UserId,
  };

  const queryKey = ["allVendorProjects", payload];

  const { data = {}, isLoading, isError, error } = useQuery(["vendorProjectSummary", UserId], vendorsAPI.getVendorProjectSummary);
  const { data: vendorData, isLoading: isLoadingProjects, isError: vendorIsError, error: vendorError } = useQuery(
    queryKey,
    vendorsAPI.getVendorProjects
  );
  
  const { showAlert } = useContext(ToastContext);

  useEffect(() => {
    if (isError) {
      showAlert({
        severity: "error",
        message: `Something went wrong while fetching project summary: ${error}`,
        durationInMs: 5000,
      });
    }

    if (vendorIsError) {
      showAlert({
        severity: "error",
        message: `Something went wrong while fetching vendor projects: ${vendorError}`,
        durationInMs: 5000,
      });
    }
    // eslint-disable-next-line
  }, [isError, error, vendorIsError, vendorError]);

  const history = useHistory();

    const mappedStatuses= Object.values(STATUS)
    .map((status) => ({
      label: status,
      value: status,
    }))
    .filter((status) => status.label && status.value);

  return (
    <PageContainer>
      <Box marginBottom="30px">
        <PageTitle>All Projects</PageTitle>
      </Box>
      {isLoading ? (
        <Skeleton width={300} height={150} variant={"rect"} />
      ) : (
        <CardWrapper>
          <OverviewCardTitle>Total Project</OverviewCardTitle>
          <OverviewCardValue>{data?.total || 0}</OverviewCardValue>
          <OverviewCardDesc>All active and completed</OverviewCardDesc>
          <Box display="flex">
            <OverviewCardFlag style={{ marginRight: "20px" }} textcolor="#00823C" bgcolor="#B0E7AE">
              {data.active || 0} Active
            </OverviewCardFlag>
            <OverviewCardFlag textcolor="#00B0F1" bgcolor="rgba(0,176,241,0.13)">
              {data.inactive || 0} Completed
            </OverviewCardFlag>
          </Box>
        </CardWrapper>
      )}

      {!!data?.total ? (
        <AdminSearchFilter normalSearch={true}>
          <Box display="flex" justifyContent="space-between">
            <InputField label="Search Id, Title" style={{ width: "100%" }} value={title} setValue={setTitle} />
            <Dropdown
              name="status"
              label="Status"
              values={[{ value: "Select00", label: "Select a status" }, ...mappedStatuses]}
              style={{ width: "100%" }}
              onChange={setStatus}
            />
            <InputField
              label="Start Date"
              type="date"
              defaultValue={format(new Date(), "yyyy-MM-dd")}
              style={{ width: "100%" }}
              value={startDate}
              setValue={setStartDate}
            />
            <InputField
              label="Expiry Date"
              type="date"
              defaultValue={format(new Date(), "yyyy-MM-dd")}
              style={{ width: "100%" }}
              value={expiryDate}
              setValue={setExpiryDate}
            />
          </Box>
        </AdminSearchFilter>
      ) : null}

      <SearchTable
        columns={["PROJECT ID / TITLE", "VALUE", "START DATE", "END DATE", "STATUS"]}
        tableHeaderText={`Showing ${vendorData?.vendorProjects?.length || 0} results`}
        page={pageNumber}
        setPage={setPageNumber}
        rowsPerPage={pageSize}
        setRowsPerPage={setPageSize}
        loading={isLoadingProjects}
        totalNumberOfPages={data?.pagination?.totalEntries}
        rows={vendorData?.vendorProjects}
      >
        {vendorData?.vendorProjects?.length > 0 && <TableBody history={history} allProjects={vendorData?.vendorProjects} />}
        {vendorData?.vendorProjects?.length === 0 && (
          <TableEmptyContent noColSpan={6} style={{ alignItems: "center" }}>
            <ListAltIcon style={{ fontSize: "60px" }} />
            <h4>Your Projects</h4>
            <p>You currently have no contract project yet</p>
            <p>
              <Link style={{ textDecoration: "none" }}>Click here</Link> to view all tenders
            </p>
          </TableEmptyContent>
        )}
        {vendorIsError && (
          <TableEmptyContent
            noColSpan={6}
            textDisplayed="Please check your network and try again"
            style={{ alignItems: "center" }}
          />
        )}
      </SearchTable>
    </PageContainer>
  );
};

export default withVendorDashboard(Projects);
