import { createSlice } from "@reduxjs/toolkit";
import { rejected } from "./baseReducer";
import baseThunkMethod from "./baseThunkMethod";

export const addComment = baseThunkMethod("addComment");
export const getComments = baseThunkMethod("getComments");

export const commentSlice = createSlice({
  name: "comment",
  initialState: {
    loading: "idle",
    loaded: [],
    completed: null,
    error: null,
    message: "",
    comments: {},
    addComment: {},
  },
  extraReducers: {
    [getComments.pending]: (state, action) => {
      state.loading = "pending";
      state.error = null;
    },
    [getComments.fulfilled]: (state, action) => {
      state.loading = "idle";
      state.error = null;
      state.comments = {
        ...state.comments,
        [action.meta.arg.id]: {
          data: action.payload.data,
          pagination: action.payload.meta.pagination,
        },
      };
      if (!state.loaded.includes("comments")) state.loaded.push("comments");
    },
    [getComments.rejected]: rejected("comments"),
    [addComment.pending]: (state, action) => {
      const { objectId } = action.meta.arg;
      const newCommentState = {
        ...state.comments,
        [objectId]: {
          ...state.comments[objectId],
          data: [...state.comments[objectId].data, action.meta.arg],
        },
      };
      state.comments = newCommentState;
      state.loading = "pendings";
      state.error = null;
    },
    [addComment.fulfilled]: (state, action) => {
      const { objectId } = action.meta.arg;
      const commentsData = state.comments[action.meta.arg.objectId].data;

      const isPendingComment = commentsData.some((el) => el.ref === action.meta.arg.ref);

      if (!!isPendingComment) {
        const isPendingIndex = commentsData.findIndex((el) => {
          return el.ref === action.meta.arg.ref;
        });
        if (isPendingComment !== -1) {
          commentsData[isPendingIndex] = action.payload.data;
        }
      }

      state.loading = "idle";
      state.error = null;
      state.comments = {
        ...state.comments,
        [objectId]: {
          ...state.comments[objectId],
          data: commentsData,
        },
      };
    },
    [addComment.rejected]: rejected("comments"),
  },
  reducer: {},
});

export default commentSlice.reducer;
