import React from "react";
import { useLocation } from "react-router-dom";
import { Wrapper } from "./styled";

// TODO: implement better 404 page
export default function NotFound() {
  const location = useLocation();

  return (
        <Wrapper>
            <h1>404</h1>
            <h3>
                No match for <code>{location.pathname}</code>
            </h3>
        </Wrapper>
  );
}
