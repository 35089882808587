import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { CardContentWrapper, StatusContainer, CardStatus } from "../../util/theme";
// import { ReactComponent as IdCardIcon } from "../../assets/id_card.svg";
import { CardLabelWrapper, CardNumberText, CardInfoText, DescriptionSection } from "./style";

const AdminCard = ({ title, count, description, statuses, isLoading }) => {
  if (isLoading)
    return (
      <>
        <Skeleton variant="text" width="60%" />
        <Skeleton variant="text" />
      </>
    );

  return (
    <>
      <CardContentWrapper>
        <DescriptionSection>
          <CardLabelWrapper>{title}</CardLabelWrapper>

          <CardNumberText>{count}</CardNumberText>
          {!!description && <CardInfoText>{description}</CardInfoText>}
        </DescriptionSection>
        {/* <CardIconContainer>
          <IdCardIcon />
        </CardIconContainer> */}
      </CardContentWrapper>

      {!!statuses && (
        <CardStatus>
          {statuses.map(({ text, bgColor, textColor }) => (
            <StatusContainer key={text} bgcolor={bgColor} textcolor={textColor}>
              {text}
            </StatusContainer>
          ))}
        </CardStatus>
      )}
    </>
  );
};

export default AdminCard;
