import PropTypes from 'prop-types';

const RenderComponent = ({ children, condition }) => {
  if(condition) {
    return children;
  }
  return null;
}

RenderComponent.propTypes = {
  children: PropTypes.node.isRequired,
  condition: PropTypes.bool,
}

RenderComponent.defaultValue = {
  condition: false
}

export default RenderComponent;