import React, { useContext, useEffect, useState } from "react";
import AdminSearchFilter from "../../../../components/AdminSearchFilter";
import DashboardDrawer from "../../../../components/DashboardDrawer";
import Dropdown from "../../../../components/DropdownField";
import MinistryCard from "./MinistryCard";
import MinistryTable from "./MinistryTable";
import SearchTable from "../../../../components/SearchTable";
import { AddButton, CirclePlusIconWrapper, PageTitle, TitleContainer } from "../../../Common/style";
import withAdminDashboard from "../../HOC/withAdminDashboard";
import { ContentWrapper } from "../../Procurement/style";
import ToastContext from "../../../../util/toastContext";
import { CardWrapper } from "../../../../util/theme";
import { useQuery } from "react-query";
import adminAPI from "../../../../redux/api/adminAPI";
import { ContractInputField } from "../../Contracts/style";
import { Roles } from "../../../../components/AccessControl/UserRoles";
import { useRoles } from "../../../../hooks/useUserDetails";
import Breadcrumb from "../../../Common/PageElements/Breadcrumb";

const Ministry = () => {
  const { role } = useRoles();
  const isAllowedRole = [Roles.DSPPC].includes(role);

  const width = "100%";
  const { Toast } = useContext(ToastContext);

  const [openModal, setOpenModal] = useState(false);

  const toggleDrawer = () => {
    setOpenModal(!openModal);
  };

  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
  });

  const updatePagination = (key) => (value) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      [key]: value,
    }));
  };

  const [filters, setFilters] = useState({
    estimatedValue: null,
    bidLowerThanId: "",
    name: "",
    code: "",
  });

  const { data, isLoading, error = {}, isError, clear } = useQuery({
    queryFn: adminAPI.getMinistriesAxios,
    queryKey: [
      "ministries",
      {
        pageSize: pagination.pageSize,
        pageNumber: pagination.pageNumber,
        estimatedValue: filters.estimatedValue,
        bidLowerThanId: filters.bidLowerThanId,
        name: filters.name,
        code: filters.code,
      },
    ],
  });

  const { showAlert } = useContext(ToastContext);

  useEffect(() => {
    if (isError) {
      showAlert({
        severity: "error",
        message: error?.message,
        durationInMs: 3000,
      });
    }
    //eslint-disable-next-line
  }, [showAlert, isError]);

  const BreadcrumbLinks = [
    { url: "/admin/dashboard/overview", title: "Home", forwardSlash: true },
    { url: "#", title: "MDAs" },
  ];

  const updateFilters = (key) => (value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  };

  const onCloseDrawer = () => {
    setOpenModal(false);
    clear();
  };

  return (
    <ContentWrapper>
      <Toast customStyles={{ zIndex: "9999", top: "65px", right: "25px" }} float />
      <TitleContainer>
        <div>
          <PageTitle>MDAs</PageTitle>
          {/* <span>Administration / MDAs</span> */}
          <Breadcrumb values={BreadcrumbLinks} />
        </div>
        {isAllowedRole && (
          <AddButton onClick={() => toggleDrawer()}>
            <CirclePlusIconWrapper /> Add New
          </AddButton>
        )}
      </TitleContainer>

      <CardWrapper>
        <MinistryCard
          title="Total MDAs"
          count={data?.pagination?.totalEntries || 0}
          description={"All MDAs and Agency"}
          statuses={[]}
        />
      </CardWrapper>

      <AdminSearchFilter normalSearch>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <ContractInputField
            type="text"
            label="Name"
            style={{ width: "100%" }}
            value={filters.name}
            setValue={updateFilters("name")}
          />
          <ContractInputField
            type="text"
            label="Code"
            style={{ width: "100%" }}
            value={filters.code}
            setValue={updateFilters("code")}
          />
          <ContractInputField
            type="number"
            name="Estimated Value"
            label="Estimated Value"
            value={filters.estimatedValue}
            style={{ width }}
            setValue={updateFilters("estimatedValue")}
          />
          <Dropdown
            name="Bid Lower Than"
            label="Bid Lower Than"
            values={[{ label: "Publisher", value: 1 }]}
            style={{ width }}
            onChange={updateFilters("bidLowerThanId")}
          />
        </div>
      </AdminSearchFilter>
      <SearchTable
        rows={data?.data}
        columns={["name", "code", "total bids", "est.value"]}
        page={pagination.pageNumber}
        setPage={updatePagination("pageNumber")}
        rowsPerPage={pagination.pageSize}
        setRowsPerPage={updatePagination("pageSize")}
        loading={isLoading}
        totalNumberOfPages={data?.pagination?.totalEntries}
      >
        <MinistryTable ministries={data?.data} />
      </SearchTable>
      {openModal && <DashboardDrawer toggleDrawer={onCloseDrawer} openModal={openModal} />}
    </ContentWrapper>
  );
};

export default withAdminDashboard(Ministry);
