import React from "react";
import { CrumbLink } from "./style";

const Breadcrumb = ({ values }) => (
    <span>
      {values.map((value, index) => (
          <CrumbLink to={value.url} key={index}>
            {value.title} {index !== (values.length - 1) ? "/ " : ""}
          </CrumbLink>
      ))}
    </span>
);

export default Breadcrumb;
