import styled from "styled-components";
import { theme } from "../../util/theme";

export const YearPickerWrapper = styled.div`
  .MuiFormControl-root {
    height: 40px;
    width: 100px;
    margin-left: 10px;
    border: 1px solid #cbd4da;
    border-radius: 3px;
    background-color: ${theme.color.white};
  }

  .MuiIconButton-root {
    flex: 0 0 auto;
    color: #898b8c;
    overflow: visible;
    font-size: 1.5rem;
    text-align: center;
    padding: 0px !important;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 50%;
  }

  .MuiInputBase-input {
    font-size: 14px;
    margin-top: 4px;
    margin-left: -3px;
    color: #637381;
  }

  .MuiFormControl-root:hover {
    border: 1px solid ${theme.color.primary};
  }
  .MuiFormLabel-root.Mui-focused {
    color: ${theme.color.primary};
  }
  .MuiFormControl-root > label {
    padding-left: 16px;
    padding-top: 8px;
  }
  .MuiFormControl-root > div {
    padding-left: 16px;
    padding-right: 12px;
  }
  .MuiInput-underline::before {
    border-bottom: none;
  }
  .MuiInput-underline::after {
    border-bottom: none;
  }
  .MuiInput-underline:hover:not(.Mui-disabled)::before {
    border-bottom: none;
  }
  .MuiPickersBasePicker-container {
    display: flex;
    flex-direction: column;
    height: 250px !important;
    width: 294px !important;
  }

  .MuiPickersBasePicker-container.MuiPickersToolbar-toolbar {
    height: 100px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    background-color: #03adeb !important;
  }
`;
