import styled from "styled-components";
import Button from "@material-ui/core/Button";

export const BlueButton = styled(Button)`
  &.MuiButtonBase-root {
    border: 1px solid #ebebeb;
    border-radius: 3px;
    background-color: ${(props) => (props.disabled ? "#c9d4db" : props.color)};
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    display: flex;
    align-items: center;
    margin-left: ${props => props.marginLeft || 'auto'};
    transition: 300ms;
    text-transform: capitalize;
  }

  &.MuiButtonBase-root:hover {
    background-color: ${(props) => (props.disabled ? "#c9d4db" : props.color)};
    filter: brightness(70%);
  }

  &.MuiButtonBase-root:disabled {
    background-color: #c9d4db;
    cursor: not-allowed;
  }
`;

export const LoadingIcon = styled.div``;
