import React from "react";
import Grid from "@material-ui/core/Grid";
import RoleCard from "../RoleCard";

export default function RolesCardSection({ cardInfo }) {
  return (
    <>
      <Grid container spacing={4}>
        {cardInfo.map((card) => (
            <Grid item xs={12} md={6} lg={3}>
              <RoleCard title={card.title} count={card.userCount} description={card.description} subTitleText={card.subTitle} />
            </Grid>
        ))}
      </Grid>
    </>
  );
}
