import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Box, TablePagination } from "@material-ui/core";
import { PageContainer, PageTitle } from "../../Common/style";
import Breadcrumb from "../../Common/PageElements/Breadcrumb";
import {
  InfoContainer,
  ProcurementInformationContainer,
  ProcurementInfoTitle,
  ProcurementInfoValue,
} from "../BidDetails/BidInfo/style";
import {
  BulletContainer,
  ReportCardTitle,
  ReportCardSubTitle,
  ReportLineSeparator,
  ReportCardContainer,
  StatusBanner,
} from "./style";
import withVendorDashboard from "../HOC/withVendorDashboard";
import { useQuery } from "react-query";
import vendorsAPI from "../../../redux/api/vendorsAPI";
import Loader from "../../../components/Common/Loader";
import Skeleton from "@material-ui/lab/Skeleton";

const BidReport = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { id, procurementId } = useParams();
  const bidEvaluationReport = useQuery(
    ["bidEvaluation", procurementId, rowsPerPage, page + 1],
    vendorsAPI.getBidEvaluationReport
  );
  const contractInfo = useQuery(["procurementContractInfo", procurementId], vendorsAPI.getProcurementContractInfo);
  const width = "100%";

  const BreadcrumbLinks = [
    { url: "/vendor/bids", title: "Bids" },
    {
      url: `/vendor/bids/${contractInfo?.data?.length > 0 && contractInfo?.data[0]?.procurementPlan?.packageNumber}/${id}`,
      title: `${contractInfo?.data?.length > 0 && contractInfo?.data[0]?.procurementPlan?.packageNumber}`,
    },
    { url: `/vendor/bids/${procurementId}/${id}/report`, title: "Report" },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getProjectPosition = (value) => {
    const lastLetter = value.toString().split("");

    switch (lastLetter[lastLetter.length - 1]) {
      case "1":
        return `${value}st`;
      case "2":
        return `${value}nd`;
      case "3":
        return `${value}rd`;
      default:
        return `${value}th`;
    }
  };

  return (
    <PageContainer>
      <Box display="flex" justifyContent="space-between" marginBottom="35px">
        {!contractInfo?.isLoading && (
          <Box>
            <PageTitle>{contractInfo?.data?.length > 0 && contractInfo?.data[0]?.procurementPlan?.packageNumber}</PageTitle>
            <Breadcrumb values={BreadcrumbLinks} />
          </Box>
        )}
        {contractInfo?.isLoading && <Skeleton width="200px" height="100px" />}
      </Box>

      <InfoContainer>
        <ProcurementInformationContainer>
          {contractInfo?.isLoading && <Loader />}
          {/* {!contractInfo?.isLoading && (<div>
          <BidInfoTitle>Contract Information</BidInfoTitle>
          <Box display="flex">
            <Box width={width}>
              <ProcurementInfoTitle >Contract Description</ProcurementInfoTitle>
              <ProcurementInfoValue>{contractInfo?.data?.length > 0 && contractInfo?.data[0]?.description}</ProcurementInfoValue>
            </Box>
            <Box width={width}>
              <ProcurementInfoTitle>Contract Duration</ProcurementInfoTitle>
              <ProcurementInfoValue>{contractInfo?.data?.length > 0 && convertDuration(contractInfo?.data[0]?.endDate, contractInfo?.data[0]?.startDate)}</ProcurementInfoValue>
            </Box>
            <Box width={width}>
              <ProcurementInfoTitle>Currency</ProcurementInfoTitle>
              <ProcurementInfoValue>Naira</ProcurementInfoValue>
            </Box>
          </Box>
        </div>)} */}
        </ProcurementInformationContainer>

        {bidEvaluationReport?.isLoading && <Loader />}

        {bidEvaluationReport?.data?.bidEvaluation?.map((eachReport) => (
          <ReportCardContainer bgcolor={(eachReport.type === 6).toString()} key={eachReport.id}>
            <Box display="flex" justifyContent="space-between">
              <Box display="flex">
                <BulletContainer className="position-content">{getProjectPosition(eachReport.position)}</BulletContainer>
                <Box>
                  <ReportCardTitle>{eachReport.vendorProfile.companyName}</ReportCardTitle>
                  <ReportCardSubTitle>Registry ID: DELTA-9938843848343</ReportCardSubTitle>
                </Box>
              </Box>
              {(eachReport.type.toUpperCase() === "RECOMMENDED") === 6 && (
                <StatusBanner className="status-banner" textcolor="#108043" bgcolor="#D6EFD2">
                  Recommended
                </StatusBanner>
              )}
              {eachReport.type.toUpperCase() === "EVALUATED" && (
                <StatusBanner className="status-banner" textcolor="#BF6712" bgcolor="#FFE2C5">
                  Evaluated
                </StatusBanner>
              )}
              {(eachReport.type.toUpperCase() === "REJECTED" || eachReport.type.toUpperCase() === "EXPIRED") && (
                <StatusBanner className="status-banner" textcolor="#DD5E47" bgcolor="#FFC8BE">
                  {eachReport.type}
                </StatusBanner>
              )}
            </Box>

            <ReportLineSeparator />

            <Box display="flex">
              <Box width={width}>
                <ProcurementInfoTitle>Amount</ProcurementInfoTitle>
                <ProcurementInfoValue>{eachReport.amount || 0}</ProcurementInfoValue>
              </Box>
              {/* <Box width={width}>
                <ProcurementInfoTitle>Bid Opening Price</ProcurementInfoTitle>
                <ProcurementInfoValue>{formatCurrency(eachReport.bidPrice || 0, true)}</ProcurementInfoValue>
              </Box>
              <Box width={width}>
                <ProcurementInfoTitle>Evaluated Price</ProcurementInfoTitle>
                <ProcurementInfoValue>{formatCurrency(eachReport.evaluatedPrice || 0, true)}</ProcurementInfoValue>
              </Box> */}
            </Box>

            <ReportLineSeparator />

            <Box width={width}>
              <ProcurementInfoTitle>Reason</ProcurementInfoTitle>
              <ProcurementInfoValue style={{ width: "465px" }}>{eachReport.reason || "N/A"}</ProcurementInfoValue>
            </Box>
          </ReportCardContainer>
        ))}

        {bidEvaluationReport?.data?.meta?.pagination?.totalEntries > rowsPerPage && (
          <TablePagination
            rowsPerPageOptions={[10, 25]}
            component="div"
            count={bidEvaluationReport?.data?.meta?.pagination?.totalEntries || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )}
      </InfoContainer>
    </PageContainer>
  );
};

export default withVendorDashboard(BidReport);
