import styled from "styled-components";

export const SecondWrapper = styled.div`
  box-sizing: border-box;
  border: 1px solid #cbd4da;
  border-radius: 3px;
  background-color: #ffffff;
  padding: 20.5px !important;
  margin-bottom: 20px;
  min-height: 200px;

  .MuiCircularProgress-colorPrimary {
    color: #03ADEB !important;
    width: 100px !important;
    height: 100px !important;
  }
`;

export const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`;
