/* eslint-disable no-nested-ternary */
/* eslint-disable no-constant-condition */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, Grid } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { confirmEmail } from "../../../redux/reducer/userReducer";
import { Footer, Navbar } from "../../../components";
import { AuthText, ContentWrapper, LoginButton, Wrapper } from "../style";
import { CheckJunk, CheckLinkWrapper } from "../PasswordResetComponent/style";

const Response = (props) => {
  const history = useHistory();
  return (
    <Wrapper>
      <AuthText>Verification {props.status}</AuthText>
      <Grid item xs={12}>
        <CheckLinkWrapper>
          <CheckJunk>
            {props.content}
          </CheckJunk>
        </CheckLinkWrapper>
      </Grid>
      <Grid item xs={12}>
        <LoginButton
          onClick={() => history.push("/login")}
          style={{ width: "393px" }}
          type="submit"
        >
          {props.direction}
        </LoginButton>
      </Grid>
    </Wrapper>
  );
};

const ConfirmEmail = (props) => {
  const dispatch = useDispatch();
  const token = new URLSearchParams(props.location.search).get("token");
  const email = new URLSearchParams(props.location.search).get("email");
  const [valid, setValid] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(confirmEmail({
      token, email,
    })).then((res) => {
      setLoading(false);
      if (res.payload.success) setValid(true);
    });
  }, [dispatch, email, token]);

  return (
    <div>
    <Navbar />

    <Container>
      <ContentWrapper>
        {loading
          ? <AuthText>Please wait!!!</AuthText>
          : valid
            ? <Response status="Successful" content="Done. You can now proceed!. Click the button below to login" direction="Go to Login" />
            : <Response status="Fail" content="Verification failed, please go back to signup" direction="Go to Signup" />
        }
      </ContentWrapper>
    </Container>
    <Footer />
  </div>
  );
};

export default ConfirmEmail;
