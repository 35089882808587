import React, { useState } from "react";
import { Drawer, Box, Checkbox } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useQuery } from "react-query";

import { ReactComponent as SearchIcon } from "../../../../../assets/search.svg";
import { CustomInputBase, CustomSearch, IconWrapper } from "../../../../../components/AdminDashboardNav/style";
import { Title } from "../../../../Vendor/BidDetails/style";
import adminAPI from "../../../../../redux/api/adminAPI";
import { VendorList } from "./style";
import Loader from "../../../../../components/Common/Loader";
import { SaveButton } from "../../../../../components/Forms/Common/style";

export default function VendorListDrawer({ onChange, onClose, open, selectedVendor, setSelectedVendor }) {
  const { paper } = useStyles();
  const [searchValue, setSearchValue] = useState("");

  const { data: vendors = {}, isLoading } = useQuery({
    queryFn: adminAPI.getVendors,
    queryKey: [
      "vendors",
      {
        pageSize: 10,
        Search: searchValue,
        SearchBy: "CompanyName",
      },
    ],
  });

  const handleChange = (vendor) => {
    onChange(vendor);
  };

  return (
    <Drawer style={{ width: 500 }} anchor="right" open={open} onClose={onClose} classes={{ paper }}>
      <Box>
        <Title>Select Vendor</Title>
        <div style={{ margin: "30px 0" }}>
          <CustomSearch style={{ boxShadow: "none", border: "1px solid #C9D4DB" }}>
            <IconWrapper>
              <SearchIcon />
            </IconWrapper>
            <CustomInputBase
              onChange={(event) => setSearchValue(event.target.value)}
              placeholder="Search"
              value={searchValue}
              inputProps={{ "aria-label": "search admin dashboard" }}
            />
          </CustomSearch>
        </div>
      </Box>
      {isLoading && <Loader />}
      {!!vendors.data &&
        vendors.data.map((vendor) => (
          <VendorList key={vendor.id}>
            <Checkbox
              checked={selectedVendor.findIndex((el) => el.id === vendor.id) !== -1}
              onChange={() => handleChange(vendor)}
              inputProps={{ "aria-label": "primary checkbox" }}
            />
            <div>
              <h6>{`${vendor?.vendorProfile?.companyName}`}</h6>
              <p>{`${vendor?.vendorProfile?.registrationPlan.title}`}</p>
            </div>
          </VendorList>
        ))}
      <Box display="flex" justifyContent="flex-end">
        <SaveButton type="submit" onClick={onClose}>
          Done
        </SaveButton>
      </Box>
    </Drawer>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    width: 400,
    padding: theme.spacing(2, 3),
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
  },
}));
