import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useMutation, useQuery } from "react-query";

import { DocumentUploadContainer, GeneralCardContentWrapper } from "./style";
import FileUploadField from "./FileUploadField";
import vendorsAPI from "../../../../redux/api/vendorsAPI";
import ToastContext from "../../../../util/toastContext";
import Loader from "../../../../components/Common/Loader";

export const Status = {
  MANDATORY: 1,
  SUPPORTING: 2,
  REVIEW: 3,
  PAYMENT: 4,
  OTHER: 5
}

export const FileStatus = {
  IDLE: `idle`,
  ERROR: `error`,
  SUCCESS: `success`,
  UPLOADING: `uploading`
}

export const ObjectType = {
  VENDOR: 2,
  ADMIN: 1
}

const GridFileUpload = ({
  objectId,
  status,
  readOnly,
  objectType,
  onChange
}) => {
  let incrementalId = 0;
  const { showAlert } = useContext(ToastContext);
  const [files, setFiles] = useState([]);
  const { status: documentStatus, error, data, isLoading } = useQuery(["documents", objectId, objectType], vendorsAPI.getUploadedDocuments);
  const [uploadDocuments, uploadDocumentsStatus] = useMutation(vendorsAPI.createDocuments, {
    onSuccess: data => {
      const { documentIds, documents } = data;
      let documentIndex = -1;
      setFiles(prevState => prevState.map((doc) => {
        if (documentIds.includes(doc.id)) {
          documentIndex++;
          return {
            ...doc,
            status: FileStatus.SUCCESS,
            id: documents[documentIndex].id,
            url: documents[documentIndex].file.url,
          }
        }
        return doc
      }))
    },
    onError: (error) => {
      let _error = error.message;
      setFiles(prevState => {
        const { documentIds } = JSON.parse(_error);
        return prevState.map(doc => documentIds.includes(doc.id) ? { ...doc, status: FileStatus.ERROR } : doc)
      })
    }
  })
  const [deleteDocument] = useMutation(vendorsAPI.removeDocument)

  useEffect(() => {
    if (documentStatus === "error") {
      showAlert({
        severity: "error",
        message: uploadDocumentsStatus.message,
      })
    }
    else if (documentStatus === "success") {
      setFiles(prevState => {
        return [...prevState, ...data.map(item => ({
          file: { lastModified: item.createdAt, size: item.file.bytes, name: item.name, type: item.file.format },
          status: FileStatus.SUCCESS,
          url: item.file.url,
          id: item.id
        }))]
      })
    }
    // eslint-disable-next-line
  }, [documentStatus, error, showAlert])

  useEffect(() => {
    let uploadedDocuments = files.filter(file => !!file.url);
    onChange && onChange(uploadedDocuments);
    // eslint-disable-next-line
  }, [files]);

  const handleAddDocuments = files => {
    let documentIds = [];
    let formData = new FormData();

    setFiles(prevState => {
      incrementalId += 1;
      documentIds.push(incrementalId);
      let _files = files.map(file => {
        formData.append("Documents", file);
        return {
          id: incrementalId,
          url: null,
          status: FileStatus.UPLOADING,
          file
        }
      })

      return [
        ...prevState,
        ..._files
      ]
    });

    formData.append("Status", status);
    formData.append("ObjectId", objectId);
    formData.append("ObjectType", objectType);

    uploadDocuments({
      documentIds,
      data: formData
    })
  }

  const handleDeleteDocument = doc => {
    //@todo It shouldn't be done this way
    //we should keep the doc on the view until it has been deleted successfully
    //So take this as a silent delete for now
    setFiles(prevState => prevState.filter(_doc => _doc.id !== doc.id))
    doc.url !== null && deleteDocument(doc.id)
  }

  return isLoading ? (
    <Loader />
  ) : (
      <GeneralCardContentWrapper>
        <DocumentUploadContainer>
          <FileUploadField
            files={files}
            multiple={true}
            onAddFiles={handleAddDocuments}
            onRemoveFile={handleDeleteDocument}
            readOnly={readOnly}
          />
        </DocumentUploadContainer>
      </GeneralCardContentWrapper>
    )
}

GridFileUpload.propTypes = {
  objectId: PropTypes.string.isRequired,
  status: PropTypes.oneOf([Object.values(Status)]).isRequired,
  objectType: PropTypes.oneOf([Object.values(ObjectType)]).isRequired,
  readOnly: PropTypes.bool.isRequired,
  onChange: PropTypes.func
}

GridFileUpload.defaultProps = {
  readOnly: false
}

export default React.memo(GridFileUpload);
