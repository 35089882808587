import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { Box, Button, Grid } from "@material-ui/core";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import {
  InvoiceFilesTitle,
  InvoiceFilesWrapper,
  InvoiceTableContainer,
  MilestoneCloseIcon,
  MilestoneTitleText,
  MinistryAddress,
  MinistryAddressValue,
  ModalContent
} from "./style";
import SearchTable, { StyledTableCell, StyledTableRow } from "../../../../components/SearchTable";
import Dialog from "@material-ui/core/Dialog";
import LoadingButton from "../../../../components/LoadingButton/LoadingButton";
import FileDownload from "../../../../components/FileDownload";

const useStyles = makeStyles(() => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const InvoicePreviewModal = (props) => {
  const classes = useStyles();
  const {
    open = false,
    onRequestClose,
    milestone,
    dueDate,
    isCreatingInvoice,
    onCreateInvoice,
    milestoneTasks = [],
    documents = []
  } = props;

  const moddedDocuments = useMemo(() => {
    return documents.map(document => {
      document.url = URL.createObjectURL(document)

      return document
    })
  }, [documents])

  useEffect(() => {
    return () => {
      moddedDocuments.forEach(document => {
        URL.revokeObjectURL(document.url)
      })
    }
    }, [moddedDocuments])

  return (
    <Dialog
      open={open}
      onClose={onRequestClose}
      className={classes.modal}
    >
      <ModalContent>
        <Box display="flex" justifyContent="space-between">
          <MilestoneTitleText>Invoice Preview</MilestoneTitleText>
          <MilestoneCloseIcon onClick={onRequestClose} />
        </Box>
        <Box marginTop="40px" padding="0 15px">
          <Grid container>
            <Grid item xs={4}>
              <MinistryAddress>Milestone Name</MinistryAddress>
              <MinistryAddressValue>{milestone.title}</MinistryAddressValue>
            </Grid>
            <Grid item xs={4}>
              <MinistryAddress>Date Issued</MinistryAddress>
              <MinistryAddressValue>{moment().format("DD/MM/YYY")}</MinistryAddressValue>
            </Grid>
            <Grid item xs={4}>
              <MinistryAddress>Date Due</MinistryAddress>
              <MinistryAddressValue>{moment(dueDate).format("DD/MM/YYYY")}</MinistryAddressValue>
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: 20 }}>
            <Grid item xs={4}>
              <MinistryAddress>Description</MinistryAddress>
              <MinistryAddressValue>{milestone.description}</MinistryAddressValue>
            </Grid>
          </Grid>
        </Box>
        <InvoiceFilesWrapper>
          <InvoiceFilesTitle>
            Invoice Supporting Document
          </InvoiceFilesTitle>
          <Box mt={2}>
            <Grid container spacing={2}>
              {
                moddedDocuments.map(file => (
                  <Grid item xs={6}>
                    <FileDownload
                      name={file.name}
                      size={file.size}
                      url={file.url}
                    />
                  </Grid>
                ))
              }
            </Grid>
          </Box>
        </InvoiceFilesWrapper>
        <InvoiceTableContainer>
          <SearchTable
            rows={3}
            showTableHeader={false}
            containerStyle={{ maxHeight: 280 }}
            columns={[
              "Item",
              "Amount",
            ]}
          >
            {milestoneTasks?.map((task) => (
              <StyledTableRow key={task.id}>
                <StyledTableCell>{task.title}</StyledTableCell>
                <StyledTableCell>{task.estimatedValue?.toLocaleString()}</StyledTableCell>
              </StyledTableRow>
            ))}
          </SearchTable>
        </InvoiceTableContainer>

        <Box display="flex" justifyContent="flex-end" alignItems="center" marginTop="30px">
          <Button
            style={{ width: "86px" }}
            onClick={onRequestClose}
            variant='outlined'
          >
            Cancel
          </Button>
          <LoadingButton
            onClick={onCreateInvoice}
            loading={isCreatingInvoice}
            style={{ width: "120px" }}
          >
            Send
          </LoadingButton>
        </Box>
      </ModalContent >
    </Dialog >
  );
};

InvoicePreviewModal.propTypes = {
  open: PropTypes.bool,
  onRequestClose: PropTypes.func,
  milestone: PropTypes.object.isRequired,
  dueDate: PropTypes.string.isRequired,
  onCreateInvoice: PropTypes.func.isRequired,
  isCreatingInvoice: PropTypes.bool.isRequired,
  milestoneTasks: PropTypes.array.isRequired
}

export default React.memo(InvoicePreviewModal);
