import React, { useState, useContext, useEffect } from "react";
import { useMutation } from "react-query";
import { parseISO} from "date-fns";
import { PlaceBidFileUploadContainer } from "../../../PlaceBid/style";
import vendorsAPI from "../../../../../redux/api/vendorsAPI";
import { SubmitButton } from "./style";
import Box from "@material-ui/core/Box";
import Document from "../../../../../components/Common/HOC/withActivityLayout/Document";
import ToastContext from "../../../../../util/toastContext";


export default function PlaceBid({ bid, tenderId, expiryDate }) {
  const [documents, setDocuments] = useState({ mandatory: [], supporting: [], removed: [] });
  const [isSubmissionClosed, setisSubmissionClosed] = useState(false);
  const { showAlert } = useContext(ToastContext);
 
  const [placeBid, { isLoading, isSuccess }] = useMutation(vendorsAPI.placeBid, {
    onSuccess() {
      showAlert({
        severity: "success",
        message: "Your bid is submitted",
        durationInMs: 3000,
      });
    },
    onError(error) {
      showAlert({
        severity: "error",
        message: error.message,
        durationInMs: 3000,
      });
    },
  });

  const isDisabled = !!documents?.mandatory?.length === false || isLoading || isSubmissionClosed;

  useEffect(() => {
    const now = new Date();
    const date = parseISO(expiryDate);
    if (now >= date && !isSubmissionClosed){ setisSubmissionClosed(true);}
  }, [expiryDate, isSubmissionClosed]);

  const handlePlaceBid = () => {
    placeBid({
      procurementId: tenderId,
      documents: Object.values(documents).reduce((acc, arr) => acc.concat(arr), []),
    });
  };

  if (isSuccess) return null;

  return (
    <React.Fragment>
      <PlaceBidFileUploadContainer>
        <Document documents={documents} setDocuments={setDocuments} />
      </PlaceBidFileUploadContainer>
      <Box mt={3.75} display="flex" justifyContent="flex-start" alignItems="center">
        <SubmitButton disabled={isDisabled} onClick={handlePlaceBid}>
          {isLoading ? "Please Wait..." :
          isSubmissionClosed? "Submission Closed"
          :"Place Bid"}
        </SubmitButton>
      </Box>
    </React.Fragment>
  );
}
