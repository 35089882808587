import styled from "styled-components";
import withStyles from "@material-ui/styles/withStyles";
import ButtonBase from "@material-ui/core/ButtonBase";

export const PaymentGatewayList = styled.div``;

export const PaymentGatewayItem = withStyles({
  root: {
    boxSizing: "border-box",
    padding: "20px 20px 15px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    borderRadius: 3,
    border: "1px solid #DEE3E9",
    position: "relative",
    "&:not(:last-of-type)": {
      marginRight: 20,
    },
  },
})(ButtonBase);

export const PaymentGatewayIcon = styled.img`
  margin-bottom: 5px;
  width: 47px;
  height: 35px;
`;

export const PaymentGatewayName = styled.p`
  font-size: 16px;
  line-height: 22px;
  color: #5f7483;
`;

export const LoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(200, 200, 200, 0.6);
`;
