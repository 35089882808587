import { Box } from '@material-ui/core'
import React from 'react'
import { CuteModal, ModalWrapper, ModalHeader, MainText, ModalRejectBtn, ModalCloseBtn } from '../style'

const RejectModal = ({open, setModal}) => {
    return (
        <CuteModal open={open}>
          <ModalWrapper>
            <ModalHeader>Reject Expression of Interest</ModalHeader>
             <MainText mt="30px">Proceed to reject this contract. You cannot undo this action</MainText>
             <MainText mt="30px"></MainText>
                <Box display="flex" justifyContent="flex-start">
                    <ModalRejectBtn>Reject</ModalRejectBtn>
                    <ModalCloseBtn onClick={() => setModal(false)}>Close</ModalCloseBtn>
                </Box>
          </ModalWrapper>  
        </CuteModal>
    )
}

export default RejectModal
