import { Box, Grid } from "@material-ui/core";
import React from "react";
import Loader from "../../../../../../components/Common/Loader";
import FileDownload from "../../../../../../components/FileDownload";
import { MediaContainer, ProjectProcureTitle, ProjectProcureSubTitle } from "../style";

const MediaDocumentSection = ({ isLoading = false, MediaFilesDoc, title }) => {
  return (
    <MediaContainer>
      {isLoading && (<Loader />)}
      {!isLoading && (<Box>
        <Box mb="20px">
          <ProjectProcureTitle>{title}</ProjectProcureTitle>
        </Box>
        <Grid container spacing={3}>
          {MediaFilesDoc?.length > 0 &&
            MediaFilesDoc?.map((eachFile, index) => (
              <Grid item xs={4} key={index}>
                <FileDownload name={eachFile.name} size={eachFile.file?.bytes} lighttheme />
              </Grid>
            ))}
        </Grid>
        {MediaFilesDoc?.length === 0 && <ProjectProcureSubTitle>No documents available</ProjectProcureSubTitle>}
      </Box>)}
    </MediaContainer>
  );
};

export default MediaDocumentSection;
