import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import CheckCircleSharpIcon from "@material-ui/icons/CheckCircleSharp";
import ErrorSharpIcon from "@material-ui/icons/ErrorSharp";

export const CircularProgressTextValue = styled(Typography)`
  color: #1E2B37 !important;
  font-size: 20px !important;
  letter-spacing: 0;
  line-height: 28px;
  text-align: center;
  margin-top: -5px !important;
`;
export const CircularProgressTextLabel = styled(Typography)`
  color: #5F7483;
  font-size: 14px !important;
  letter-spacing: 0;
  line-height: 20px !important;
  text-align: center;
`;

export const CheckEntryErrorIcon = styled(ErrorSharpIcon)`
  color: #DE3618;
  margin-right: 10px;
`;

export const CheckEntryCheckCircleSharpIcon = styled(CheckCircleSharpIcon)`
  color: rgba(11,176,45,0.6);
  margin-right: 10px;
`;

export const CheckEntryText = styled(Typography)`
  color: #212B36 !important;
  font-size: 14px !important;
  letter-spacing: 0 !important;
  line-height: 20px !important;
`;

export const CheckEntryContentWrapper = styled.div`
  display: flex;
  margin-bottom: 15px !important;
  align-items: center;
`;

export const CheckEntriesContainer = styled.div`
  box-sizing: border-box;
  display: inline-table;
  min-height: 399px;
  min-width: 269px;
  border: 1px solid #C9D4DB;
  border-radius: 3px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 4px 0 #C9D4DB;
  padding: 20px;
`;
