import { Box } from "@material-ui/core";
import React, { useContext, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import CheckIcon from "@material-ui/icons/Check";

import { GeneralTitlePage } from "../../../../../../components/AddGeneralPlan/style";
import { CardNumberText } from "../../../../../../components/AdminCard/style";
import TopStatisticsBar from "../../../../../../components/TopStatisticsBar";
import Breadcrumb from "../../../../../Common/PageElements/Breadcrumb";
import { ContentWrapper } from "../../../../Administration/Staff/style";
import { CardLabel } from "../../../../PlanDetails/style";
import ApprovalModal from "./ApprovalModal";
import LoadingButton from "../../../../../../components/LoadingButton/LoadingButton";
import EvaluatedVendorsTable from "../../../../../../components/EvaluatedVendorsTable/EvaluatedVendorsTable";
import { useMutation, useQuery } from "react-query";
import receiptOfBiddersResponseApi from "../../../../../../redux/api/receiptOfBiddersResponseApi";
import ToastContext from "../../../../../../util/toastContext";
import procurementAPI from "../../../../../../redux/api/procurementAPI";

const baseVendorObject = {
  data: {
    responseSummary: {
      total: 0,
      pending: 0,
      evaluated: 0,
    },
    evaluatedVendors: [],
  },
  pagination: {
    totalEntries: 0,
  },
};

const EvaluatedVendors = () => {
  const { activityId, procurementId } = useParams();
  const [open, setOpen] = React.useState(false);
  const [uploadDocument, setUploadDocument] = React.useState([]);

  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
  });

  const biddersResponse = useQuery({
    queryKey: [
      "biddersResponse",
      {
        Status: 2,
        PageSize: pagination.pageSize,
        PageNumber: pagination.pageNumber,
        id: procurementId,
      },
    ],
    queryFn: receiptOfBiddersResponseApi.evaluatedBids,
  });

  const documents = useQuery({
    queryKey: [
      "approvalMemo",
      {
        activityId,
      },
    ],
    queryFn: receiptOfBiddersResponseApi.getApprovalDocument,
  });

  const activityDetails = useQuery({
    queryKey: [
      "activityDetails",
      {
        activityId,
      },
    ],
    queryFn: procurementAPI.getActivityById,
  });

  const [sendForRecommendation, sendForRecommendationQuery] = useMutation(receiptOfBiddersResponseApi.sendForRecommendation, {
    onSuccess: () => {
      activityDetails.refetch()
    }
  });

  const { showAlert } = useContext(ToastContext);

  const {
    data: { responseSummary, evaluatedVendors },
    pagination: apiPagination,
  } = Object.assign(baseVendorObject, biddersResponse.data);

  const updatePagination = (key) => (value) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      [key]: value,
    }));
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const renderLeftContent = () => (
    <Box>
      <CardLabel>Total Bidders</CardLabel>
      <CardNumberText>{responseSummary.total}</CardNumberText>
    </Box>
  );

  const renderCenterContent = () => {
    const { procurementPlanActivityStatus } = activityDetails.data || {};

    const isAwaiting = procurementPlanActivityStatus === "Awaiting";
    const isApproved = procurementPlanActivityStatus === "Approved";

    return (
      <>
        <Box>
          <CardLabel style={{ color: "#FF8D26" }}>Pending Review</CardLabel>
          <CardNumberText>{responseSummary.pending}</CardNumberText>
        </Box>
        <Box marginLeft="55px">
          <CardLabel style={{ color: "#219653" }}>Evaluated</CardLabel>
          <CardNumberText>{responseSummary.evaluated}</CardNumberText>
        </Box>
        {/* {(isApproved || isAwaiting) && (
          <Box ml="auto" mr="auto">
            <ApprovalDocumentLink href={documents.data?.pop()?.file?.url} target="_blank">
              View approval memo
            </ApprovalDocumentLink>
          </Box>
        )} */}
        {!isApproved && (
          <Box ml="auto" mr={2}>
            <LoadingButton
              onClick={handleClickOpen}
              endIcon={isAwaiting ? null : <CheckIcon width="10px" />}
              color="#00b0f1"
              disabled={isAwaiting || (responseSummary.pending > 0)}
            >
              {isAwaiting ? "Waiting for approval" : "Send for Recommendation"}
            </LoadingButton>
          </Box>
        )}
      </>
    );
  };

  const recommend = async () => {
    try {
      await sendForRecommendation({
        File: uploadDocument[0],
        activityId,
      });

      handleClose();

      showAlert({
        message: "Succcessfully uploaded recommend document",
        severity: "success",
        durationInMs: 3000,
      });
    } catch (e) {
      showAlert({
        message: e.message,
        severity: "error",
        durationInMs: 3000,
      });
    }
  };

  const topBarStatus = useMemo(() => {
    const { procurementPlanActivityStatus } = activityDetails.data || {};

    if (procurementPlanActivityStatus === "Awaiting") {
      return "pending";
    } else if (procurementPlanActivityStatus === "Approved") {
      return "approved";
    } else {
      return null;
    }
  }, [activityDetails.data]);

  return (
    <ContentWrapper>
      <Box mt={3}>
        <GeneralTitlePage>Evaluated Vendors</GeneralTitlePage>
        <Breadcrumb
          values={[
            {
              url: "/dashboard/overview",
              title: "Home",
            },
            {
              url: `/admin/procurement/plans`,
              title: "Plans",
            },
            {
              url: `/admin/procurement/plan-details/${procurementId}`,
              title: "Plan Details",
            },
            {
              url: `/admin/procurement/activity/receiptofbiddersresponse/${activityId}/${procurementId}`,
              title: "Receipt of bidders response",
            },
            {
              url: `#`,
              title: "Evaluated Vendors",
            },
          ]}
        />
      </Box>

      <Box marginTop="43px">
        <TopStatisticsBar
          leftContent={renderLeftContent()}
          hasApproveStatus={documents.data?.length > 0}
          centerContent={renderCenterContent()}
          isLoading={documents.isLoading}
          status={topBarStatus}
        />
      </Box>

      <EvaluatedVendorsTable
        isLoading={biddersResponse.isLoading}
        vendors={evaluatedVendors}
        pageNumber={pagination.pageNumber}
        pageSize={pagination.pageSize}
        setPageNumber={updatePagination("pageNumber")}
        setPageSize={updatePagination("pageSize")}
        totalPages={apiPagination.totalEntries}
      />
      <ApprovalModal
        btnText="Send For Recommendation"
        filesNames={uploadDocument}
        setFilesNames={setUploadDocument}
        handleClose={handleClose}
        open={open}
        onClickButton={recommend}
        isLoading={sendForRecommendationQuery.isLoading}
        cardTitle="Send For Approval"
        docInfo="Upload Evaluation document here"
      />
    </ContentWrapper>
  );
};

export default EvaluatedVendors;
