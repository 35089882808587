import Drawer from "@material-ui/core/Drawer";
import React from "react";
import { ErrorContainer, SaveButton } from "../Forms/Common/style";
import RoleDrawerLayout from "./Layout";
import {
  ButtonContainer,
  DashboardCancelButton,
  DashboardDrawerWrapper,
  DashboardFormTitle,
  DrawerContent
} from "./style";

const DashboardDrawer = ({ openModal, toggleDrawer, isChecker }) => {
  const onRenderError = (error) => <ErrorContainer>{error && error.message}</ErrorContainer>;

  const renderButton = (TextLabel) => (
    <ButtonContainer>
      <DashboardCancelButton onClick={() => toggleDrawer()}>Cancel</DashboardCancelButton>
      <SaveButton type="submit" style={{ width: "122px" }}>
        {TextLabel}
      </SaveButton>
    </ButtonContainer>
  );

  const renderContent = () => <RoleDrawerLayout renderButton={renderButton} onRenderError={onRenderError} />;

  return (
    <>
      <Drawer anchor="right" open={openModal}>
        <DashboardDrawerWrapper>
          <DashboardFormTitle> Add new {isChecker ? "checker" : "role"}</DashboardFormTitle>
          <DrawerContent>{renderContent()}</DrawerContent>
        </DashboardDrawerWrapper>
      </Drawer>
    </>
  );
};

export default DashboardDrawer;
