import styled from "styled-components";
import { FormTitle } from "../../../../util/theme";
import { SaveButton } from "../../../../components/Forms/Common/style";
import { ReactComponent as CirclePlusIcon } from "../../../../assets/plus.svg";
import ButtonBase from "@material-ui/core/ButtonBase";
import withStyles from "@material-ui/styles/withStyles";

export const ContentWrapper = styled.div`
  padding: 0 0 60px;

  .MuiTableCell-body:nth-child(2) {
    display: flex;
    align-items: center;

    div:first-child {
      margin: 0 10px 0 0;
      width: 30px;
      height: 30px;
      color: white;
      background-color: #50b83c;
    }
  }
`;

export const TitleContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
`;

export const Title = styled(FormTitle)`
  font-size: 20px !important;
  font-weight: 700 !important;
  letter-spacing: 0 !important;
  line-height: 28px !important;
  color: #151c2f !important;
`;

export const AddButton = styled(SaveButton)`
  height: 41px;
  width: fit-content;
  margin: 0 !important;
  border: 1px solid #ebebeb !important;
`;

export const CirclePlusIconWrapper = styled(CirclePlusIcon)`
  margin-right: 10.5px;
  width: 20px;
  height: 20px;
`;

export const ActionButton = withStyles(() => ({
  root: {
    background: "#5F7483",
    boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.15)",
    borderRadius: "3px",
    padding: "10px 16px",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "20px",
    textAlign: "center",
    color: "white",
  },
}))(ButtonBase);
