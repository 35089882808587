import React, { useState, useEffect } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import { ServiceList } from "./style";

const ServicesByCategory = ({ service, vendorProductsIdsPerCategory, register }) => {
  const [checkedOptions, setCheckedOptions] = useState(vendorProductsIdsPerCategory);

  useEffect(() => {
    setCheckedOptions(checkedOptions);
  }, [checkedOptions]);

  const allOptionsChecked = checkedOptions.length === service[1].length;

  const toggleAllCheckboxes = () => {
    if (allOptionsChecked) {
      return setCheckedOptions([]);
    }
    setCheckedOptions(service[1].map(({ id }) => id));
  };

  const toggleOptionCheckbox = (id) => {
    setCheckedOptions((prev) => {
      if (!checkedOptions.includes(id)) {
        return [...prev, id];
      }
      return prev.filter((item) => item !== id);
    });
  };

  return (
    <ServiceList>
      <div>
        <FormControlLabel
          control={<Checkbox name={service[0]} color="primary" checked={allOptionsChecked} onClick={toggleAllCheckboxes} />}
          name={service[0]}
          label={service[0]}
        />
      </div>
      {service[1].map(({ title, id }) => (
        <div key={id}>
          <FormControlLabel
            control={
              <Checkbox
                name={id}
                color="primary"
                checked={(Boolean(checkedOptions.length && checkedOptions.includes(id)))}
                onClick={() => toggleOptionCheckbox(id)}
              />
            }
            name={id}
            label={title}
            inputRef={register}
          />
        </div>
      ))}
    </ServiceList>
  );
};

export default ServicesByCategory;
