export const permissionListData = [
  {
    id: 1,
    name: "Agriculture Facility/Storage/Grain Silos",
  },
  {
    id: 2,
    name: "Correctional Facilities/Prison and Rehabilitation Centers",
  },
  {
    id: 3,
    name: "Electrical",
  },
  {
    id: 4,
    name: "High-Rise Buildings",
  },

];
