import React from "react";
import { useSelector } from "react-redux";
import { Container, Grid } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { Footer, Navbar } from "../../../components";

import {
  AuthSubtext,
  AuthText,
  ContentWrapper,
  LoginButton,
  Wrapper,
} from "../../Common/style";
import { CheckJunk, CheckLinkWrapper } from "../../Common/PasswordResetComponent/style";
// import { resendInvite } from "../../../redux/reducer/userReducer";

const AccountVerificationComponent = () => {
  const width = "393px";
  const textAlign = "center";

  const { user, loading, error: apiError } = useSelector((state) => state.user);
  // const dispatch = useDispatch();

  const email = (user && user.email) || localStorage.getItem("email");
  const handleResetInvite = () => {
    // NOTE: temporarily disabling this till the api field is changed.
    // dispatch(resendInvite(email));
  };

  const showToast = (type, message) => (<MuiAlert severity={type}>{message}</MuiAlert>);

  return (
    <div>
      <Navbar />

      <Container>
        {apiError && showToast("error", apiError)}
        {user && showToast("success", "Invite sent successfully")}
        <ContentWrapper>
          <Wrapper>
            <AuthText>Verfication link sent!</AuthText>
            <AuthSubtext
              style={{
                textAlign,
                marginBottom: "16px",
              }}
            >
              Done. Check your email {email && <b>{email}</b>} and follow the
              instructions.
            </AuthSubtext>
            <Grid item xs={12}>
              <CheckLinkWrapper>
                <CheckJunk>
                  If you are yet to receive the email, ensure you have checked
                  your junk folder.
                </CheckJunk>
              </CheckLinkWrapper>
            </Grid>
            <Grid item xs={12}>
              <LoginButton
                onClick={handleResetInvite}
                style={{ width }}
                type="button"
                disabled={loading === "pending"}
              >
                {loading === "pending" ? "Resending Invite..." : "Resend Invite"}
              </LoginButton>
            </Grid>
          </Wrapper>
        </ContentWrapper>
      </Container>
      <Footer />
    </div>
  );
};

export default AccountVerificationComponent;
