import React from "react";
import { Box, Grid } from "@material-ui/core";
import moment from "moment";
import {
  ProcurementInformationContainer,
  BidInfoTitle,
  ProcurementInfoTitle,
  ProcurementInfoValue,
  BiddingDocumentContainer,
} from "./style";
import Loader from "../../../../components/Common/Loader";
import FileDownload from "../../../../components/FileDownload";

const BidInfoCard = ({ procurementData, biddingDocuments = [], isDocumentLoading }) => {
  const loader = (isDocumentLoading) => {
    if (!isDocumentLoading) {
      return (
        <Grid container spacing={2}>
          {biddingDocuments.map((eachDoc) => (
            <Grid item xs={4} key={eachDoc?.file?.original_filename}>
              <FileDownload
                name={eachDoc?.file?.original_filename}
                size={eachDoc?.file?.bytes}
                url={eachDoc?.file?.secure_url}
                lightTheme
              />
            </Grid>
          ))}
        </Grid>
      );
    } else {
      return (
        <Grid item xs={4}>
          <Box>
            <Loader />
          </Box>
        </Grid>
      );
    }
  };

  return (
    <Box>
      <ProcurementInformationContainer>
        <BidInfoTitle>Procurement Information</BidInfoTitle>
        <Box display="flex">
          <Box width>
            <ProcurementInfoTitle>Procurement ID</ProcurementInfoTitle>
            <ProcurementInfoValue>{procurementData?.packageNumber || "N/A"}</ProcurementInfoValue>
          </Box>
          <Box width>
            <ProcurementInfoTitle>Procurement Type</ProcurementInfoTitle>
            <ProcurementInfoValue>{procurementData?.type || "N/A"} </ProcurementInfoValue>
          </Box>
          <Box width>
            <ProcurementInfoTitle>Procurement Category</ProcurementInfoTitle>
            <ProcurementInfoValue>{procurementData?.category || "N/A"}</ProcurementInfoValue>
          </Box>
          <Box width>
            <ProcurementInfoTitle>Bid Status</ProcurementInfoTitle>
            <ProcurementInfoValue>{procurementData?.bidStatus || "N/A"}</ProcurementInfoValue>
          </Box>
        </Box>

        <Box display="flex" marginTop="24px">
          <Box width>
            <ProcurementInfoTitle>Ministry</ProcurementInfoTitle>
            <ProcurementInfoValue>{procurementData?.ministry || "N/A"}</ProcurementInfoValue>
          </Box>
          <Box width>
            <ProcurementInfoTitle>Expiry Date</ProcurementInfoTitle>
            <ProcurementInfoValue>{moment(procurementData?.expiryDate).format("DD, MMMM, yyyy")}</ProcurementInfoValue>
          </Box>
          <Box width>
            <ProcurementInfoTitle>Value</ProcurementInfoTitle>
            <ProcurementInfoValue>N{procurementData?.value || "N/A"}</ProcurementInfoValue>
          </Box>
          <Box width />
        </Box>

        <Box display="flex" marginTop="24px">
          <Box width="466px">
            <ProcurementInfoTitle>Tender Description</ProcurementInfoTitle>
            <ProcurementInfoValue>{procurementData?.description || "N/A"}</ProcurementInfoValue>
          </Box>
        </Box>
      </ProcurementInformationContainer>
      <BiddingDocumentContainer>
        {/**loading compoennt here */}
        {loader(isDocumentLoading)}
      </BiddingDocumentContainer>
    </Box>
  );
};

export default BidInfoCard;
