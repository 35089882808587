import React, { useContext, useState, useRef, useCallback } from "react";
import { useMutation } from "react-query";
import { Tab, Tabs, Box } from "@material-ui/core";
import { useParams, useHistory } from "react-router-dom";
import TabPanel from "./TabPanel";
import DatasheetTab from "./DatasheetTab";
import ContractTab from "./ContractTab";
import { ContentWrapper, TitleContainer } from "../../style";
import { TabContainer, TabContainerWrapper, ApprovalButton } from "./style";
import withAdminDashboard from "../../../HOC/withAdminDashboard";
import FormContext from "../../../../../util/context";
import { PageTitle } from "../../../../Common/style";
import ReviewTab from "./ReviewTab";
import ToastContext from "../../../../../util/toastContext";
import { CancelButton, SaveButton } from "../../../../../components/Forms/Common/style";
import DocumentTab from "./DocumentTab";
import Breadcrumb from "../../../../Common/PageElements/Breadcrumb";
import procurementAPI from "../../../../../redux/api/procurementAPI"
import {useRoles} from "../../../../../hooks/useUserDetails"
import {Roles} from "../../../../../components/AccessControl/UserRoles"


const DOCUMENT_TAB = 0;
const DATASHEET_TAB = 1;
const CONTRACT_TAB = 2;
const REVIEW_TAB = 3;

const BidEvaluation = () => {
  const  history  = useHistory();
  const [tabIndex, setTabIndex] = useState(DOCUMENT_TAB);
  const { showAlert } = useContext(ToastContext);
  const { procurementId, activityId } = useParams();

  const documentTabRef = useRef();
  const datasheetTabRef = useRef();
  const contractTabRef = useRef();
  const reviewTab = useRef();
  
  //Access control config
  const { role } = useRoles();
  const isDisabled = role !== Roles.PROCUREMENT_OFFICER;
  const isMaker = role === Roles.PROCUREMENT_OFFICER;
  const isChecker = [Roles.COMMISSIONER, Roles.PERMANENT_SECRETARY].includes(role);

 // API call
  const [approveActivity, approveActivityQueryResponse] = useMutation(procurementAPI.approveActivity, {
    throwOnError: true
  });


  const handleSubmit = () => {
    const refObject = {
      0: documentTabRef,
      1: datasheetTabRef,
      2: contractTabRef,
      3: reviewTab,
    }
    const currentRef = refObject[tabIndex]
    currentRef.current.handleSubmit()
  }

  const handlePrevious = () => {
    const refObject = {
      0: documentTabRef,
      1: datasheetTabRef,
      2: contractTabRef,
      3: reviewTab,
    }
    const currentRef = refObject[tabIndex]
    currentRef.current.goBack()
  }

  const handleTabChange = useCallback((i) => setTabIndex(i), [])
  

  const handleApproval = async () => {
    try{
      await approveActivity({
        procurementPlanId : procurementId,
        procurementPlanActivityId: activityId
      });

      showAlert({
        severity: "success",
        message: "Successfully approved stage",
        durationInMs: 3000
      }) ;   
      setTimeout(function(){ history.push(`/admin/procurement/plan-details/${procurementId}`)}, 1500);
     
    }catch (e) {
      showAlert({
        severity: "error",
        message: e.message,
        durationInMs: 3000
      })
    }
  }

  const renderActionButtonByRoles =()=>{
    if (isMaker || isChecker){      
      return (
        <Box style={{display: "flex"}}>
          {tabIndex !== 3 && (
        <SaveButton
        onClick={handleSubmit}>{"Next"}</SaveButton>  
        )}  
        {isChecker && ( 
        <ApprovalButton
        onClick={handleApproval} isLoading={approveActivityQueryResponse.isLoading} isDisabled={approveActivityQueryResponse.isLoading}>{"Approve"}
      
        </ApprovalButton> 
         ) 
        }  
        </Box>
      )
    }
  };

  return (
    <FormContext.Provider
      value={{
        activeForm: tabIndex,
        setActiveForm: setTabIndex,
      }}
    >
      <ContentWrapper>
        <TitleContainer>
          <div>
            <PageTitle>Bid Evaluation Report And Recommendation For Award</PageTitle>
            <Breadcrumb values={
              [
                {
                  url: "/admin/procurement/plans",
                  title: "Home",
                  forwardSlash: true,
                },
                {
                  url: `/admin/procurement/plan-details/${procurementId}`,
                  title: "Activities List",
                  forwardSlash: true,
                },
                {
                  url: "#",
                  title: "Bid Evaluation Report And Recommendation For Award",
                  forwardSlash: true,
                },
              ]
            } />
          </div>
        </TitleContainer>
        <TabContainerWrapper>
          <TabContainer>
            <Tabs value={tabIndex}>
              <Tab label="Document" />
              <Tab label="Datasheet" />
              <Tab label="Contracts" />
              <Tab label="Review" />
            </Tabs>
            <TabPanel active={tabIndex} index={DOCUMENT_TAB}>
              <DocumentTab
                ref={documentTabRef}
                tabIndex={tabIndex}
                setTabIndex={handleTabChange}
                showAlert={showAlert}
                isChecker= {isChecker}
              />
            </TabPanel>
            <TabPanel active={tabIndex} index={DATASHEET_TAB}>
              <DatasheetTab
                 ref={datasheetTabRef}
                 tabIndex={tabIndex}
                 setTabIndex={handleTabChange}
                 showAlert={showAlert}
                 isChecker= {isChecker}
                 isDisabled ={isDisabled}
                 isMaker={isMaker}
              />
            </TabPanel>
            <TabPanel active={tabIndex} index={CONTRACT_TAB}>
              <ContractTab
                ref={contractTabRef}
                setTabIndex={handleTabChange}
                showAlert={showAlert}
                isChecker= {isChecker}
                isDisabled ={isDisabled}
                isMaker={isMaker}
                 />
            </TabPanel>
            <TabPanel active={tabIndex} index={REVIEW_TAB}>
              <ReviewTab
                ref={reviewTab}
                setTabIndex={handleTabChange}
              />
            </TabPanel>
            <div style={{ padding: "30px", display: "flex", justifyContent: "space-between"}}>
              <CancelButton onClick={handlePrevious}>Back</CancelButton>
              {renderActionButtonByRoles()}              
            </div>
          </TabContainer>
        </TabContainerWrapper>
      </ContentWrapper>
    </FormContext.Provider>
  );
};

export default withAdminDashboard(BidEvaluation);
