import React, { useContext, useState, useEffect } from "react";
import { Box } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useMutation, useQuery } from "react-query";
import { Skeleton } from "@material-ui/lab";

import usersAPI from "../../redux/api/usersAPI";
import ProfileFormField from "./ProfileFormField";
import ToastContext from "../../util/toastContext";
import NoImage from "../../assets/no_image.png";


export default function StaffProfile({ staff }) {
  const { data: staffProfileData, isLoading, isSuccess } = useQuery(["getStaffProfile", { userId: staff.id }], usersAPI.getStaffProfile, {
    enabled: !!staff.id,
    onError: (error) => {
      showAlert({
        message: error.message,
        severity: "error",
      });
    },
  });

  const [updateStaffProfile, updateStaffProfileQuery] = useMutation(usersAPI.updateStaffProfile, {
    onSuccess: () => {
      showAlert({
        message: "Your profile was updated successfully",
        severity: "success",
      });
    },
    onError: (error) => {
      showAlert({
        message: error.message,
        severity: "error",
      });
    },
  });

  const [selectedFile, setSelectedFile ]= useState(null);
  const [uploadedImage, setUploadedImage ]= useState(staffProfileData?.profilePicture?.url || null);
  const { profileImg, profileInfoContainer, profileImageContainer } = useStyles();
  const { showAlert } = useContext(ToastContext);


  useEffect(() => {
   if (isSuccess){
     setUploadedImage(staffProfileData?.profilePicture?.url)
   }
  }, [isSuccess, staffProfileData])


  const submitForm = async (form) => {
    form.append("profilePicture", selectedFile);
    try {
      updateStaffProfile({ userId: staff.id, data: form });
    } catch (error) {
      showAlert({
        message: error.message,
        severity: "error",
      });
    }
  };

  const handleImageUpload= (file)=>{
    const reader = new FileReader();
    reader.addEventListener("load", function () {  
      // convert image file to base64 string
      setUploadedImage( reader.result);
    }, false);

    if (file) {
      reader.readAsDataURL(file);
    } 
      setSelectedFile(file)
    }

  return (   
    <>     
      <Box display="flex" className={profileInfoContainer}>
        <div className={profileImg}>
          {isLoading ? (
            <Skeleton height="100%" width="100%" variant="rect" col />
          ) : (
            <div className={profileImageContainer}>
            <img
            className = "profileImage" 
            loading="lazy"           
              src={uploadedImage || NoImage}
              alt="User"
            />
              <input
                type="file"             
                onChange={(e) =>handleImageUpload(e.target.files[0])}             
              />
            </div>
          )}
        </div>
      </Box>

      <Box style={{ marginTop: 20 }}>
        <ProfileFormField user={staff} onSubmitForm={submitForm} isLoading={updateStaffProfileQuery.isLoading} />
      </Box>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    width: 400,
    padding: theme.spacing(2, 3),
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
  },
  profileImg: {
    maxHeight: 120,
    height: "100%",
    width: "40%",
    backgroundColor: "#E4E9EC",
    border: "1px solid #EBEBEB",
    borderRadius: 3,
  },
  profileInfo: {
    fontSize: 16,
    color: "#424F5C",
    marginLeft: 20,
    "& div": {
      width: "100%",
      "&:not(:last-child)": {
        marginBottom: 10,
      },
    },
    "& h4": {
      fontWeight: 800,
    },
  },
  profileInfoContainer: {
    marginTop: 20,
    marginBottom: 20,
    minHeight: 120,
  },
  classValue: {
    display: "inline-block",
    padding: 5,
    background: "rgba(0, 176, 241, 0.0735632)",
    borderRadius: "3px",
    fontWeight: 600,
    fontSize: 36,
    lineHeight: "36px",
    color: "#00B0F1",
  },
  profileImageContainer : {
    position: "relative",
     width: "100%",
    "& .profileImage":{
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      minHeight: 120,
      background: "rgba(3, 173, 235, 0.07)",
      borderRadius: 3,
      zIndex: 1
    },
    '& input[type="file"] ':{
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      minHeight: 120,
      borderColor: "transparent",
      opacity: 0,
      zIndex: 2
    }
  }
}));
