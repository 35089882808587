import styled from "styled-components";
import { theme } from "../../../util/theme";
import { SaveButton } from "../../../components/Forms/Common/style";
import { ReactComponent as CirclePlusIcon } from "../../../assets/plus.svg";

export const ContentWrapper = styled.div`
  padding: 10.3px 0 60px;

  .MuiTableCell-body:first-child {
    div > p {
      color: ${theme.color.primary};
      font-weight: 600;
    }
    span {
      font-size: 14px;
    }
  }
`;

export const TitleContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;

  p {
    margin-bottom: 0 !important;
  }

  div > span {
    font-size: 12px;
    color: ${theme.color.textColor1};
  }
`;

export const AddButton = styled(SaveButton)`
  height: 41px;
  margin: 0 !important;
  border: 1px solid #ebebeb !important;
  background: ${(props) => props.disabled && "#BDBDBD !important"};
`;

export const CirclePlusIconWrapper = styled(CirclePlusIcon)`
  margin-right: 10.5px;
  width: 20px;
  height: 20px;
`;

export const Breadcrumbs = styled.span`
  color: #00b0f1 !important;
  font-size: 12px !important;
  letter-spacing: 0;
  line-height: 16px !important;
`;
