import React, { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { SubmitButton, MainTitle, MainText, Pad, CardWrap } from "./style";
import { useEffect } from "react";
import { DocumentSection } from "../../../Vendor/Procurement/style";
import DatePicker from "../../../../components/DatePicker";
import procurementAPI from "../../../../redux/api/procurementAPI";
import { useMutation, useQuery } from "react-query";
import moment from "moment";
import ToastContext from "../../../../util/toastContext";
import FileUploadField from "../../../../components/FileUploadField";
import { useRoles } from "../../../../hooks/useUserDetails";
import { Roles } from "../../../../components/AccessControl/UserRoles";
import awardOfContractApi from "../../../../redux/api/awardOfContractApi";
import AccessControl from "../../../../components/AccessControl";
import { DeadlineRow } from "../Activitiies/AwardOfContract/style";

const UploadContractDocument = () => {
  const { procurementId } = useParams();
  const [files, setFiles] = useState([]);
  const [activityId, setActivityId] = useState();
  const { role } = useRoles();

  const ObjectType = role === Roles.PROCUREMENT_OFFICER ? 9 : role === Roles.MINISTRY_OF_JUSTICE ? 5 : null;

  const { showAlert } = useContext(ToastContext);
  const [deadline, setDeadline] = useState(null);
  const [uploadContract, { isSuccess, isError, error, isLoading: uploadLoading }] = useMutation(procurementAPI.MOJUploadContract);
  let btnStatus = Boolean(deadline) && Boolean(files.length);

  const [updateDeadline, updateDeadlineQuery] = useMutation(awardOfContractApi.updateDeadline, {
    throwOnError: true,
  });

  const { data: contractDraft = [] } = useQuery(["getContractDraft", procurementId], () =>
  procurementAPI.getContractDraft(procurementId)
);

  useQuery(["get-activityId"], () => procurementAPI.getActivityId(procurementId), {
    onSuccess: ({ data }) => {
      const id = data.procurementPlanActivities.find(({ title }) => title === "Contract Award")?.id;
      setActivityId(id);
    },
  });

  const handleUpdateDeadline = () => {
    updateDeadline({
      activityId,
      deadline,
    });
  };

  const handleUpload = () => {
    const newDate = new Date(deadline);
    newDate.setHours(23, 59, 0, 0);
    setDeadline(newDate);

    const formData = new FormData();
    formData.append("Deadline", deadline);
    formData.append("ObjectType", ObjectType);
    files.map((file) => formData.append("File", file));
    uploadContract({ procurementId, formData });
  };

  useEffect(() => {
    if (isSuccess) {
      showAlert({
        severity: "Success",
        durationInMs: 3000,
        message: "Document uploaded successfully",
      });
    }
    if (isError) {
      showAlert({
        severity: "error",
        durationInMs: 3000,
        message: error,
      });
    }
  }, [isSuccess, isError, error, showAlert]);

  return (
    <CardWrap mt="20px">
      <Pad>
        <MainTitle>Upload Contract Document</MainTitle>
      </Pad>
      <DocumentSection mt="20px">
        <FileUploadField filesNames={files} setFilesNames={setFiles} />
        <MainText mt="20px"></MainText>
        <DeadlineRow>
          <DatePicker
            id="date"
            datePickerWrapperStyle={{ padding: 0 }}
            style={{ margin: "10px 0 10px", width: "30%" }}
            label="Deadline"
            value={deadline}
            handleChange={(value) => setDeadline(moment(value).format("YYYY-MM-DD"))}
          />
          <AccessControl allowedRoles={[Roles.MINISTRY_OF_JUSTICE]}>
            <SubmitButton onClick={handleUpdateDeadline} disabled={contractDraft.length === 0}>
              {updateDeadlineQuery.isLoading ? "Updating" : "Update Deadline"}
            </SubmitButton>
          </AccessControl>
        </DeadlineRow>
      </DocumentSection>
      <Pad>
        <SubmitButton disabled={!btnStatus} onClick={handleUpload}>
          {uploadLoading ? "Please wait....." : "Upload Document"}
        </SubmitButton>
      </Pad>
    </CardWrap>
  );
};

export default UploadContractDocument;
