import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  margin-left: -20px;
  margin-right: -20px;
`;

export const Content = styled.div`
  filter: blur(4px);
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;

  background: rgba(0, 0, 0, 0.5);
  padding: 60px 0 53px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  box-sizing: border-box;
  backdrop-filter: blur(10px);
  z-index: 10;
`;

export const Title = styled.p`
  font-size: 24px;
  line-height: 24px;
  font-weight: 700;
  margin-bottom: 20px;
`;

export const Subtitle = styled.p`
  font-size: 14px;
  line-height: 20px;
  width: 487px;
  max-width: 100%;
  & + & {
    margin-top: 5px;
  }
`;
