import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import TableContainer from "@material-ui/core/TableContainer";
import { theme } from "../../../../../util/theme";
import { ReactComponent as DotIcon } from "../../../../../assets/dot.svg";

export const TableWrapper = styled(TableContainer)`
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 #cbd4da !important;

  .MuiTableCell-body {
    color: ${theme.color.secondary} !important;
  }

  .MuiTableHead-root {
    border-top: 1px solid #cbd4da;

    th {
      text-transform: uppercase;
      padding: 16px 10px;
    }

    tr:first-child {
      outline: 1px solid #cbd4da;
      th {
        line-height: 11px;
      }
    }
  }

  .MuiTable-stickyHeader {
    border-top: 1px solid #cbd4da;
  }

  th:first-child {
    border-left: 1px solid #cbd4da;
  }

  th,
  td {
    text-align: left;
  }

  th {
    font-size: 12px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 16px;
    color: #637381;
  }

  p,
  span {
    font-size: 14px;
  }

  span {
    font-size: 12px;
    font-weight: 400;
  }

  .MuiTableCell-body:last-child {
    div {
      width: 4px;
      background: #637381;
      height: 4px;
      border-radius: 50%;
      margin: 1px auto;
    }

    span {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;
      width: 22px;
      text-align: center;
    }
  }

  .MuiTableCell-body:first-child {
    div:nth-child(2) {
      margin-left: 10px;
    }
  }

  .MuiTablePagination-root {
    border-left: 1px solid #cbd4da;
  }

  .MuiTableBody-root .MuiTableCell-root {
    padding: 0 10px;
    height: 59px;
  }

  .MuiChip-label {
    text-transform: capitalize;
  }

  .MuiTableBody-root > .MuiBox-root {
    position: absolute;
    margin-left: 25%;
    text-transform: capitalize;
  }
`;

export const ResultDetailContainer = styled.div`
  margin-top: 25px;
  box-sizing: border-box;
  height: 61px;
  border: 1px solid #c9d4db;
  border-radius: 3px 3px 0 0;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 #c9d4db;
  display: flex;
  align-items: center;
  padding: 0 19px;
`;

export const ResultShowText = styled(Typography)`
  height: 19px;
  color: #5f7483;
  font-size: 14px !important;
  font-weight: 600 !important;
  letter-spacing: 0;
  line-height: 19px !important;
`;

export const TableContainerWrapper = styled.div`
  margin-top: 20px;
`;

export const EmptyData = styled.div`
  margin: 20px;
  width: 300px;
`;

export const HeadLabel = styled.b`
  font-weight: 601;
  color: #627685;
  text-transform: uppercase;
  font-size: 13px;
`;

export const NameLabel = styled.b`
  color: #363B3E;
  font-size: 13px;
  font-weight: 601;
`;

export const DotIconElement = styled(DotIcon)`
  height: 20px;
  width: 25.06px;
  cursor: pointer;
`;
