import { Typography } from "@material-ui/core";
import styled from "styled-components";

export const PageWrapper = styled.div`
  padding: 15px 0 35px;

  .pageLink {
    font-size: 12px;
    color: #00b0f1;
    text-decoration: none;
  }
`;

export const DetailsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-column-gap: 24px;
  margin: 25px 0 20px;
`;

export const NetworkContainer = styled.div`
  height: 300px;
  margin-top: 100px;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 1px 10px #e4e4e4;
  display: flex;
`;

export const ChartTitleText = styled(Typography)`
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: 24px;
  color: #212b36;
`;

export const ProjectCostLabel = styled(Typography)`
  font-style: normal;
  font-weight: normal;
  font-size: 13px !important;
  line-height: 16px;
  color: #5f7483;
`;

export const ProjectCostValue = styled(Typography)`
  font-style: normal;
  font-weight: 600 !important;
  font-size: 20px !important;
  line-height: 20px;
  color: #31373d;
`;

export const ProjectCostPercentageText = styled(Typography)`
  font-style: normal;
  font-weight: normal;
  font-size: 15px !important;
  line-height: 16px;
  color: ${({ textcolor }) => textcolor};
`;

export const LegendTitleText = styled(Typography)`
  font-size: 16px !important;
  line-height: 19px;
  letter-spacing: -0.066px;
  color: #485465;
  margin-bottom: 10px;
`;

export const LegendBullets = styled.div`
  border-radius: 50%;
  height: 10px;
  width: 10px;
  margin-right: 10px !important;
  background-color: ${({ color }) => color};
`;
