/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Description, Wrapper } from "../../../components/Forms/Common/style";
import { getVendorRegStage } from "../../../util/getVendorRegStage";
import { FormTitle } from "../../../util/theme";
import withLoader from "./withLoader";

const withVendorRegLoader = (WrappedComponent) => ({ title, description, ...rest }) => {
  const history = useHistory();
  const regStage = getVendorRegStage();


  useEffect(() => {
    if(regStage === 7) {
      history.push("/vendor/overview")
    }
  }, [regStage])

  return (
    <Wrapper>
      <FormTitle>{title}</FormTitle>
      {description && <Description>{description}</Description>}
      {withLoader((props) => <WrappedComponent {...props} />)({ stateName: "vendor", ...rest })}
    </Wrapper>
  );
};

export default withVendorRegLoader;
