import React from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { CircularLoader } from "../Forms/Common/style";

const Loader = ({ customStyles, feedback }) => (
  <Box position="relative" display="flex" justifyContent="center" alignItems="center" left="0 !important" {...customStyles}>
    <CircularLoader />
    <Box top={0} left={0} bottom={0} right={0} position="absolute" display="flex" alignItems="center" justifyContent="center">
      <Typography variant="caption" component="div" color="textSecondary">
        {feedback || "Please Wait..."}
      </Typography>
    </Box>
  </Box>
);

export default Loader;
