import React, { useState } from "react";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Box } from "@material-ui/core";
import { CardWrapper } from "../../util/theme";
import {
  CheckEntryCheckCircleSharpIcon,
  CheckEntryContentWrapper,
  CheckEntryErrorIcon,
  CheckEntryHeader,
  CheckEntryText,
  FormsHeader,
  FormTab,
  PageGrid,
} from "./style";

/**
 * This is the activity tabs component created to allow reusability and prevent code duplication
 * It receives the follows props
 *
 * @param tabs - The array of tabs
 * @param tabs[0].title - The name used to identify the tab
 * @param tabs[0].allowClick - This determines if clicking on the tab makes it switch to that tab
 * @param tabs[0].component - The component to be rendered
 *
 * @param entries - Array of entries rendered in the check entry progress sidebar
 *
 * @param defaultActiveKey - You can use this to set the default active key (eg you want to go to the review tab immediately)
 *
 * @param loading - This determines whether the component should show a loader or not
 */

const ActivityTabs = ({ tabs, entries, defaultActiveKey = 0, loading = false }) => {
  const [activeTab, setActiveTab] = useState(defaultActiveKey);

  const onPrev = () => {
    setActiveTab(Math.max(0, activeTab - 1));
  };

  const onNext = () => {
    setActiveTab(Math.min(activeTab + 1, tabs.length - 1));
  };

  const onClickTab = (index) => {
    switch (index) {
      // Allow go to first tab
      case 0: {
        setActiveTab(0);
        break;
      }

      // Allow go to last tab because it's always the review tab
      case tabs.length - 1: {
        setActiveTab(tabs.length - 1);
        break;
      }

      // Conditionally allow go to other tabs based on the user's props
      default: {
        if (tabs[index]?.allowClick) {
          setActiveTab(index);
        }
      }
    }
  };

  return (
    <PageGrid>
      <CardWrapper width={"100%"} padding={0}>
        <FormsHeader>
          {tabs.map(({ title }, index) => (
            <FormTab isActive={tabs[activeTab]?.title === title} style={{ cursor: "pointer" }} onClick={() => onClickTab(index)}>
              {title}
            </FormTab>
          ))}
        </FormsHeader>
        {loading ? (
          <Box display="flex" pt={10} pb={10} flexDirection="column" alignItems="center">
            <CircularProgress />
            <Box mt={5}>
              <p>Loading details</p>
            </Box>
          </Box>
        ) : (
          React.cloneElement(tabs[activeTab]?.component, {
            onPrev,
            onNext,
          })
        )}
      </CardWrapper>
      <CardWrapper>
        <CheckEntryHeader>Check Entries</CheckEntryHeader>
        {entries.map((entry, index) => (
          <CheckEntryContentWrapper>
            {activeTab > index ? <CheckEntryCheckCircleSharpIcon /> : <CheckEntryErrorIcon />}
            <CheckEntryText>{entry}</CheckEntryText>
          </CheckEntryContentWrapper>
        ))}
      </CardWrapper>
    </PageGrid>
  );
};

ActivityTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      allowClick: PropTypes.bool,
      component: PropTypes.node,
    })
  ),
  entries: PropTypes.arrayOf(PropTypes.string),
  defaultActiveKey: PropTypes.number,
};

export default ActivityTabs;
