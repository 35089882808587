import styled from "styled-components";
import Dialog from "@material-ui/core/Dialog";

export const Center = styled.div`
display: grid;
place-items: center;
padding: 60px 32px 40px;
`;

export const PrintCenter = styled.div`
display: grid;
place-items: center;
padding: 60px 32px 40px;
`;

export const TextMsg = styled.div`
font-weight: bold;
font-size: 24px;
line-height: 33px;
color: ${(props) => props.danger ? `red` : `#27AE60`};
margin: 27px 0 40px 0;
`;

export const Title = styled.div`
font-weight: normal;
font-size: 16px;
line-height: 20px;
color: #424F5C;
`;

export const Detail = styled.div`
font-weight: 800;
font-size: 16px;
line-height: 20px;
color: #424F5C;
`;

export const CustomDialog = styled(Dialog)`
  .MuiDialog-paper {
    width: 45%;
  }
`;

export const InfoPlace = styled.div`
background: #FEF1C9;
padding: 15px 20px;
margin: 25px 0 25px 0;
width: 93%;
`;

export const InfoText = styled.div`
font-weight: normal;
font-size: 14px;
line-height: 19px;
color: #424F5C;
`;

export const ContinueBtn = styled.button`
color: #ffffff;
  box-sizing: border-box;
  height: 41px;
  padding-left: 20px;
  padding-right: 20px;
  border: 1px solid #ebebeb;
  border-radius: 3px;
  background-color: #03adeb;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
  margin-right: 10px;
`;

export const PrintBtn = styled.button`
color: #03adeb;
background: #fff;
box-sizing: border-box;
height: 41px;
padding-left: 20px;
padding-right: 20px;
border: 1px solid #03adeb;
border-radius: 3px;
box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
font-size: 14px;
font-weight: 600;
letter-spacing: 0;
line-height: 20px;
text-align: center;
cursor: pointer;
margin-right: 10px;
`;

export const Horizontal = styled.hr`
border: 1px solid #E0E0E0;
margin: 12px 0 5px 0;
width: 100%;
`;