import React from "react";
import { CircularProgress } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { BlueButton } from "./style";

const LoadingIcon = () => (
  <Box ml={2}>
    <CircularProgress size={12}/>
  </Box>
)

const LoadingButton = ({
  color = "#00b0f1", loading = false, disabled, children, endIcon, ...props
}) => (
  <BlueButton
    color={color}
    disabled={loading || disabled}
    variant={"contained"}
    endIcon={loading ? <LoadingIcon/> : endIcon}
    {...props}
  >
    {children}
  </BlueButton>
);

export default LoadingButton;
