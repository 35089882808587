import { Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import styled from "styled-components";
import { ReactComponent as CloseIcon } from "../../../assets/close.svg";
import { CancelButton } from "../../../components/Forms/Common/style";
import { theme } from "../../../util/theme";

export const TenderDetailsDialog = styled(Dialog)`
  .MuiDialog-paper {
    padding: 0;
  }
`;

export const TenderDialogContent = styled(DialogContent)`
  height: 510px;
  width: 550px;
  background-color: #ffffff;
`;

export const TenderDialogTitleWrapper = styled.div`
  height: 27px;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 20px;
`;

export const TenderDetailTitle = styled(Typography)`
  height: 27px;
  color: #1c4576;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 27px;
`;

export const TenderTitle = styled.h3`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  margin-bottom: 24px;
  color: #1e2b37;
  font-size: 24px;
  font-weight: normal;
`;

export const TenderSubDetailsWrapper = styled.div`
  margin-left: 10px;
  margin-right: 20px;
  text-align: left;

  span {
    height: 16px;
    color: #637381;
    font-family: "SF Pro Text";
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
    margin-top: 15px;
  }

  p {
    height: 20px;
    color: #212b36;
    font-family: "SF Pro Text";
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
  }
`;

export const TenderDescription = styled.div`
  height: 69px;
  width: 403px;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-top: 30px;

  span {
    height: 16px;
    width: 113px;
    color: #5f7483;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 16px;
  }

  p {
    width: 396px;
    color: #5f7483;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 20px;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 40px;
`;

export const BidTenderButton = styled(CancelButton)`
  height: 41px;
  width: 151px;
  border-radius: 3px;

  border: 1px solid #ebebeb;
  background-color: ${theme.color.primary} !important;
  color: #fff !important;
`;

export const TenderCloseIcon = styled(CloseIcon)`
  height: 16px;
  width: 16px;
  cursor: pointer;
  fill: ${({ iconcolor }) => iconcolor};
`;
