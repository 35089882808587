import React from "react";
import { Box } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import {
  WarningModalButton,
  WarningModalDescription,
  WarningModalExistingVendor,
  WarningModalLink,
  WarningModalTitle
} from "./style";
import { useParams } from "react-router-dom";

const WarningModal = ({ vendorId, vendorName, open, handleClose }) => {
  const { activityId, procurementId } = useParams();

  return (
    <Dialog open={open} onClose={handleClose}>
      <Box width='450px' p={3.75}>
        <WarningModalTitle>
          Attention
        </WarningModalTitle>
        <WarningModalDescription>
          You can only have one recommended vendor
        </WarningModalDescription>
        <WarningModalExistingVendor>
          <WarningModalDescription>
            You have an existing recommended vendor
          </WarningModalDescription>
          <WarningModalLink
            to={`/admin/procurement/activity/receiptofbiddersresponse/${activityId}/${procurementId}/evaluation/${vendorId}`}
          >
            {vendorName}
          </WarningModalLink>
        </WarningModalExistingVendor>
        <WarningModalButton variant='contained' onClick={handleClose}>
          Close
        </WarningModalButton>
      </Box>
    </Dialog>
  );
};

export default WarningModal;
