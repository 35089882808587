import styled from "styled-components";

export const ChatBoxWrapper = styled.div`
  border: 1px solid #C9D4DB;
  border-radius: 3px;
  background-color: rgba(228,233,236,0.17);
  padding: 20px;
  width: 100%;
`;

export const FileLabel = styled.h4`
  color: #424F5C;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;
  margin-bottom: 10px;
`;
