import React from "react";
import { useRouteMatch, Route, Switch } from "react-router-dom";
import AwardedContract from "./AwardedContract";
import ContractDetails from "./ContractDetails";

export default function ContractSigning() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={path} component={AwardedContract} exact />
      <Route path={`${path}/:contractId`} component={ContractDetails} exact />
    </Switch>
  );
}
