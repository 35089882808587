import React, { useEffect } from "react";
import { useParams, Redirect } from "react-router-dom";
import { useQuery } from "react-query";
import withAdminDashboard from "../../../../HOC/withAdminDashboard";
import UnsignedContractDetails from "./UnsignedContractDetails";
import SignedContractDetails from "./SignedContractDetails";
import procurementAPI from "../../../../../../redux/api/procurementAPI";
import useAlert from "../../../../../../hooks/useAlert";
import Loader from "../../../../../../components/Common/Loader";
import { ContractSignatureStatus } from "../../../../../../util/constants";

function ContractDetails() {
  const { procurementId: planId, contractId, activityId: planActivityId } = useParams();
  const { status, error, data: contracts } = useQuery(["getAwardedContracts", planId], procurementAPI.getAwardedContracts);
  const { Toast, showAlert } = useAlert();
  const contract = contracts ? contracts.find((contractItem) => String(contractItem.id) === contractId) : null;

  useEffect(() => {
    if (status === "error") {
      showAlert({
        severity: "error",
        message: error.message,
      });
    }
  }, [status, showAlert, error]);

  if (status === "error") return <Toast float />;

  if (status === "loading") return <Loader />;

  if (!contract) return <Redirect to="/admin/404" />;

  if (contract.signatureStatus === ContractSignatureStatus.Unsigned) {
    return <UnsignedContractDetails contract={contract} planActivityId={planActivityId} />;
  }

  return <SignedContractDetails contract={contract} />;
}

export default withAdminDashboard(ContractDetails);
