import React, { useCallback, useEffect, useMemo, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { useParams, useHistory } from "react-router-dom";
import { queryCache, useMutation, useQuery } from "react-query";
import { formatISO } from "date-fns";
import { ContentWrapper as _ContentWrapper, TitleContainer } from "../../../../style";
import { PageTitle } from "../../../../../../Common/style";
import {
  CardBody,
  CardHeader,
  CardTitle,
  CardWrapper,
  CheckEntryCheckCircleSharpIcon,
  CheckEntryContentWrapper,
  CheckEntryErrorIcon,
  CheckEntryText,
  CheckEntryWrapper,
} from "../../style";
import {
  ContractFormCardWrapper,
  ContractStatusInfo,
  ContractStatusInfoDot,
  ContractStatusInfoText,
  SummaryCardWrapper,
} from "./style";
import { DetailRow } from "../style";
import FormContext from "../../../../../../../util/context";
import DocumentUploadForm from "./DocumentUploadForm";
import DatasheetForm from "./DatasheetForm";
import ReviewTab from "./ReviewTab";
import DetailItem from "../../../../../../../components/DetailItem";
import Breadcrumb from "../../../../../../Common/PageElements/Breadcrumb";
import { getContractStatusLabel } from "../../../../../../../util/constants";
import procurementAPI from "../../../../../../../redux/api/procurementAPI";
import Loader from "../../../../../../../components/Common/Loader";
import useAlert from "../../../../../../../hooks/useAlert";
import { transformContractSigningToFrontendModel } from "../../../../../../../util/transforms/procurement";

const ContentWrapper = React.memo(_ContentWrapper);

function UnsignedContractDetails({ contract, planActivityId }) {
  const history = useHistory()
  const { activityId = "", procurementId } = useParams();
  const [activeTab, setActiveTab] = useState(0);

  const handleApproval = async () => {
    try{
      await approveActivity({
        procurementPlanId : procurementId,
        procurementPlanActivityId: activityId
      });

      showAlert({
        severity: "success",
        message: "Successfully approved stage",
        durationInMs: 3000
      }) ;   
      setTimeout(function(){ history.push(`/admin/procurement/plan-details/${procurementId}`)}, 3000);
     
    }catch (e) {
      showAlert({
        severity: "error",
        message: e.message,
        durationInMs: 3000
      })
    }
  }
    
  //API calls   
  const [approveActivity, approveActivityQueryResponse] = useMutation(procurementAPI.approveActivity, {
    throwOnError: true
  });
  const contractQueryKey = useMemo(() => ["getContractSigningDocument", planActivityId], [planActivityId]);
  const contractQuery = useQuery(contractQueryKey, procurementAPI.getContractSigningDocument, {
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
  });
  const state = contractQuery.data;
  const [uploadContractSigningDocument, uploadQuery] = useMutation(procurementAPI.uploadContractSigningDocument, {
    onSuccess(data) {
      queryCache.setQueryData(contractQueryKey, transformContractSigningToFrontendModel(data));
    },
    throwOnError: false,
  });
  
  const tabs = [
    {
      id: "document",
      title: "Document",
      component: <DocumentUploadForm handleApproval={handleApproval} isLoadingForApproval={approveActivityQueryResponse.isLoading}/>,
    },
    {
      id: "datasheet",
      title: "Datasheet",
      component: <DatasheetForm  handleApproval={handleApproval} isLoadingForApproval={approveActivityQueryResponse.isLoading} />,
    },
    {
      id: "review",
      title: "Review",
      component: <ReviewTab />,
    },
  ];
  
  function TabPanel({ children, tabId, active, ...props }) {
    return (
      <div role="tabpanel" hidden={!active} id={`contract-tabpanel-${tabId}`} aria-labelledby={`contract-tab-${tabId}`} {...props}>
        {active ? children : null}
      </div>
    );
  }
  
  function a11yProps(tabId) {
    return {
      id: `contract-tab-${tabId}`,
      "aria-controls": `contract-tabpanel-${tabId}`,
    };
  }
  

  const { Toast, showAlert } = useAlert();

  const breadcrumbs = [
    {
      title: "Home",
      url: "/",
      forwardSlash: true,
    },
    {
      title: "Procurement plans",
      url: "/admin/procurement/plans",
      forwardSlash: true,
    },
    {
      title: contract.procurementPlan.ministry,
      forwardSlash: true,
    },
    {
      title: contract.procurementPlan.name,
      forwardSlash: true,
    },
    {
      title: "Contract",
    },
  ];

  const activeTabId = tabs[activeTab] ? tabs[activeTab].id : null;

  const handleChange = (_, tabId) => {
    if (uploadQuery.status === "loading") return;
    setActiveTab(tabId);
  };

  const renderCheckEntryItem = (status, label) => (
    <CheckEntryContentWrapper>
      {status === "complete" && <CheckEntryCheckCircleSharpIcon />}
      {status === "incomplete" && <CheckEntryErrorIcon />}
      <CheckEntryText>{label}</CheckEntryText>
    </CheckEntryContentWrapper>
  );

  const updateState = useCallback(
    (updater) => {
      queryCache.setQueryData(contractQueryKey, (prevState) => {
        const newState = updater(prevState);
        newState.sections = {
          document: newState.documents.mandatory.length > 0 ? "complete" : "incomplete",
          datasheet: Object.values(newState.datasheet).filter((x) => Boolean(x)).length === 3 ? "complete" : "incomplete",
        };
        return newState;
      });
    },
    [contractQueryKey]
  );

  const contextValue = useMemo(
    () => ({
      planActivityId,
      state,
      isUploading: uploadQuery.status === "loading",
      addDocuments(documentType, documents) {
        updateState((prev) => {
          const newData = { ...prev };
          newData.documents[documentType] = [...prev.documents[documentType], ...documents];
          return newData;
        });
      },
      removeDocument(documentType, document) {
        updateState((prev) => {
          const newData = { ...prev };
          // If the document is a previously uploaded file,
          // add it to the array of removed document IDs
          if (document.id) {
            newData.documents.removed = [...prev.documents.removed, document.id];
          }
          newData.documents[documentType] = prev.documents[documentType].filter((prevDoc) => prevDoc !== document);
          return newData;
        });
      },
      updateDocuments(documentType, documents) {
        updateState((prev) => {
          const newData = { ...prev };
          const removed = prev.documents[documentType].filter(
            (prevDocument) => !documents.some((document) => document.id === prevDocument.id)
          );
          newData.documents.removed = [...prev.documents.removed, ...removed];
          newData.documents[documentType] = documents;
          return newData;
        });
      },
      updateDatasheet(datasheet) {
        updateState((prev) => {
          const newData = { ...prev };
          newData.datasheet = datasheet;
          return newData;
        });
      },
      previousTab() {
        if (uploadQuery.status === "loading") return;
        setActiveTab((index) => Math.max(0, index - 1));
      },
      nextTab() {
        if (uploadQuery.status === "loading") return;
        setActiveTab((index) => Math.min(tabs.length - 1, index + 1));
      },
      uploadData() {
        const currentState = queryCache.getQueryData(contractQueryKey);
        if (currentState.documents.mandatory.length === 0) {
          showAlert({
            severity: "error",
            message: "Please upload at least one mandatory document",
          });
        }
        // When we're uploading to the backend, we don't add the
        // previously uploadeded files that haven't been removed
        // to the payload
        const mandatory = currentState.documents.mandatory.filter((d) => !d.id);
        const supporting = currentState.documents.supporting.filter((d) => !d.id);
        uploadContractSigningDocument({
          planActivityId,
          documents: {
            removed: currentState.documents.removed,
            mandatory,
            supporting,
          },
          datasheet: {
            ...currentState.datasheet,
            signatureDate: formatISO(currentState.datasheet.signatureDate),
          },
        });
      },
    }),
    [state, planActivityId, updateState, uploadQuery.status, uploadContractSigningDocument, contractQueryKey, showAlert, tabs.length]
  );

  useEffect(() => {
    if (contractQuery.status === "error") {
      showAlert({ severity: "error", message: contractQuery.error.message });
    }
  }, [contractQuery.status, contractQuery.error, showAlert]);

  useEffect(() => {
    if (uploadQuery.status === "error") {
      showAlert({ severity: "error", message: uploadQuery.error.message });
    }
  }, [uploadQuery.status, uploadQuery.error, showAlert]);

  useEffect(() => {
    if (uploadQuery.status === "success") {
      showAlert({ severity: "success", message: "Data uploaded successfully! Kindly await reviews from your superior(s)." });
      setActiveTab(2);
    }
  }, [uploadQuery.status, showAlert]);

  

  return (
    <FormContext.Provider value={contextValue}>
      <ContentWrapper>
        <TitleContainer>
          <div>
            <PageTitle>Awarded Contract</PageTitle>
            <Breadcrumb values={breadcrumbs} />
          </div>
        </TitleContainer>
        <SummaryCardWrapper>
          <CardWrapper>
            <CardHeader>
              <CardTitle>Status</CardTitle>
              <ContractStatusInfo status={contract.status}>
                <ContractStatusInfoDot />
                <ContractStatusInfoText>{getContractStatusLabel(contract.status)}</ContractStatusInfoText>
              </ContractStatusInfo>
            </CardHeader>
            <CardBody>
              <DetailRow container>
                <Grid item xs={4}>
                  <DetailItem label="Contract Name" text={contract.title} />
                </Grid>
                <Grid item xs={4}>
                  <DetailItem label="Reference No:" text={contract.contractNumber} />
                </Grid>
                <Grid item xs={4}>
                  <DetailItem label="Signed Date" text="N/A" />
                </Grid>
              </DetailRow>
              <DetailRow container>
                <Grid item xs={4}>
                  <DetailItem
                    label="Contractor"
                    text={`${contract?.contractor?.companyName} (${contract?.contractor?.cacRegistrationNumber})`}
                  />
                </Grid>
              </DetailRow>
            </CardBody>
          </CardWrapper>
        </SummaryCardWrapper>
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <ContractFormCardWrapper>
              <CardWrapper>
                {contractQuery.status === "loading" && (
                  <CardBody>
                    <Loader />
                  </CardBody>
                )}
                {contractQuery.status === "success" && (
                  <React.Fragment>
                    <CardHeader noPadding>
                      <Tabs value={activeTab} onChange={handleChange}>
                        {tabs.map((tab) => (
                          <Tab key={tab.id} label={tab.title} {...a11yProps(tab.id)} />
                        ))}
                      </Tabs>
                    </CardHeader>
                    {tabs.map((tab) => (
                      <TabPanel key={tab.id} tabId={tab.id} active={activeTabId === tab.id}>                       
                        {tab.component}
                      </TabPanel>
                    ))}
                  </React.Fragment>
                )}
              </CardWrapper>
            </ContractFormCardWrapper>
          </Grid>
          <Grid item xs={3}>
            {contractQuery.status === "success" && (
              <CardWrapper>
                <CheckEntryWrapper>
                  <CardBody noHeader>
                    <CardTitle noBackground>Check Entries</CardTitle>
                    {renderCheckEntryItem(state.sections.document, "Signed Contract")}
                    {renderCheckEntryItem(state.sections.datasheet, "Datasheet")}
                  </CardBody>
                </CheckEntryWrapper>
              </CardWrapper>
            )}
          </Grid>
        </Grid>
        <Toast float />
      </ContentWrapper>
    </FormContext.Provider>
  );
}

export default React.memo( UnsignedContractDetails);
