export const Roles = Object.freeze({
  VENDOR: "vendor",
  PROCUREMENT_OFFICER: "procurement_officer",
  PERMANENT_SECRETARY: "permanent_secretary",
  COMMISSIONER: "commissioner",
  GOVERNOR: "governor",
  DSPPC: "dsppc",
  MODERATOR: "moderator",
  EXECUTIVE: "executive",
  SSG: "ssg",
  MINISTRY_OF_JUSTICE: "ministry_of_justice",
  ADMIN: "admin",
  TENDER_BOARD: "tender_board",
  CONTRACTORS_REG_BOARD: "contractors_reg_board",
  STATE_MINISTERIAL_TENDER_BOARD: "state_ministerial_tender_board"
});
