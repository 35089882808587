import { Box } from "@material-ui/core";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import AdminSearchFilter from "../../../../components/AdminSearchFilter";
import ChatBox from "../../../../components/ChatBox/ChatBox";
import { SearchButton } from "../../../../components/Forms/Common/style";
import { ContentWrapper, TitleContainer } from "../../../Admin/Administration/Staff/style";
import { MainTitle } from "../../../Admin/Contracts/SingleContractDetails/style";
import Breadcrumb from "../../../Common/PageElements/Breadcrumb";
import { PageTitle } from "../../../Common/style";
import { BiddingDocumentContainer } from "../../BidDetails/BidInfo/style";
import { SecondWrapper } from "../../Contract/ContractInfo/style";
import { MainWrapper } from "../../Contract/LetterOfAcceptance/style";
import { NewCancelButton } from "../../PlaceBid/style";
import ProcurementDocSection from "../DocSection";
import ProcurementInfo from "../ProcurementInfo";
import withVendorDashboard from "../../HOC/withVendorDashboard";

const ExpressInterest = () => {
  const { id } = useParams();
  const history = useHistory();

  const [chats, setChats] = useState([
    {
      type: "message",
      author: "Admin",
      date: new Date(),
      message: "You need to make some changes to the Financial Document 1",
    },
    {
      type: "message",
      author: "Kansai Plascon",
      date: new Date(),
      message: "You need to make some changes to the Financial Document 1",
    },
  ]);

  const handleSubmit = (message) => {
    setChats((prevChats) => [
      ...prevChats,
      {
        type: "message",
        author: "Anonymous",
        date: new Date(),
        message,
      },
    ]);
    return true;
  };

  const handleFileUpload = (file) => {
    setChats((prevChats) => [
      ...prevChats,
      {
        type: "file",
        file,
        fileLabel: file.name,
      },
    ]);
  };

  const BreadcrumbLinks = [
    {
      url: "/vendor/procurement",
      title: "Procurements",
      forwardSlash: true,
    },
    {
      url: `/vendor/procurement/${id}`,
      title: "Procurement",
      forwardSlash: true,
    },
    {
      url: `/vendor/procurement/${id}/express-interest`,
      title: " Express of Interest",
      forwardSlash: false,
    },
  ];
  return (
    <ContentWrapper>
      <TitleContainer>
        <div>
          <PageTitle>{id}</PageTitle>
          <Breadcrumb values={BreadcrumbLinks}/>
        </div>
      </TitleContainer>

      <MainWrapper>
        <ProcurementInfo/>

        <BiddingDocumentContainer style={{ marginBottom: "10px" }}>
          <Box display="flex">
            <MainTitle style={{ marginBottom: "10px" }}>Download Documents</MainTitle>
          </Box>
          <ProcurementDocSection/>
        </BiddingDocumentContainer>

        <SecondWrapper style={{ marginTop: "20px" }}></SecondWrapper>

        <AdminSearchFilter title="Comments" noPadding normalSearch>
          <ChatBox messages={chats} onSubmit={handleSubmit} onUploadFile={handleFileUpload}/>
        </AdminSearchFilter>
      </MainWrapper>
      <Box display="flex" justifyContent="flex-end" alignItems="center">
        <NewCancelButton
          onClick={() => {
            history.push(`/vendor/procurement/${id}`);
          }}
        >
          Back
        </NewCancelButton>
        <SearchButton style={{
          width: "155px",
          marginLeft: "15px",
        }}>Express Interest</SearchButton>
      </Box>
    </ContentWrapper>
  );
};

export default withVendorDashboard(ExpressInterest);
