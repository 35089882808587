import styled from "styled-components";
import withStyles from "@material-ui/core/styles/withStyles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";

export const ContentWrapper = styled.div`
  padding: 10.3px 0 60px;
`;

export const CardWrapper = withStyles(() => ({
  root: {
    border: "1px solid #c9d4db",
    borderRadius: 3,
    backgroundColor: "#fff",
    padding: 20,
    boxShadow: "0 2px 4px 0 #cbd4da",
  },
}))(Box);

export const Subsection = withStyles(() => ({
  root: {
    border: "1px solid #CBD4DA",
    borderRadius: 3,
    backgroundColor: (props) => (props.colored ? "#F4FCFF" : "#fff"),
    padding: 20,
  },
}))(Box);

export const SubsectionTitle = withStyles(() => ({
  root: {
    color: "#1E2B37",
    fontFamily: "SF Pro Text, sans-serif",
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0,
    lineeight: 24,
    marginBottom: 20,
  },
}))(Typography);

export const SubmitButton = withStyles(() => ({
  root: {
    border: "1px solid #ebebeb",
    borderRadius: 3,
    backgroundColor: (props) => (props.disabled ? "#c9d4db" : "#00b0f1"),
    boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.15)",
    padding: "10px 22px",
    color: "#fff",
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 20,
    textAlign: "center",
  },
}))(ButtonBase);

export const BackButton = withStyles(() => ({
  root: {
    padding: "10px 22px",
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 20,
    textAlign: "center",
    color: "#8e9fac",
    backgroundColor: "#fff",
    borderRadius: 3,
    border: "1px solid #8e9fac",
    marginRight: 15,
  },
}))(ButtonBase);
