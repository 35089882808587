/* eslint-disable no-undef */
/* eslint-disable no-unused-expressions */
import Drawer from "@material-ui/core/Drawer";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getCountries, getStates, addDirectorDetails, editDirectorDetails } from "../../redux/reducer/vendorReducer";
import { FormTitle } from "../../util/theme";
import Dropdown from "../DropdownField";
import FileUploadField from "../FileUploadField";
import FileUploadSection from "../FileUploadSection";

import { ButtonContainer, CancelButton, ErrorContainer, IdentificationContainer, SaveButton } from "../Forms/Common/style";
import InputField from "../InputField";
import { FormLabel, Wrapper } from "./style";
import useAlert from "../../hooks/useAlert";

const DirectorDetailsDrawer = ({
  isOpen,
  toggleDrawer,
  idFilesNames,
  setidFilesNames,
  certificateFilesNames,
  setcertificateFilesNames,
  passportFileNames,
  setpassportFileNames,
  directorDetail,
  setdirectorDetail,
}) => {
  const dispatch = useDispatch();

  const { register, errors, handleSubmit } = useForm();
  const width = "100%";
  const margin = "15px 0";
  const {
    user: { UserId: userId },
  } = useSelector((state) => state.user);
  const { loading } = useSelector((state) => state.vendor);

  const identificationTypes = [
    { label: "National Identity Card", value: 1 },
    { label: "Permanent Voter Card", value: 2 },
    { label: "Temporary Voter Card", value: 3 },
    { label: "Driver Licence", value: 4 },
    { label: "International Passport", value: 5 },
    { label: "National Identity Number Slip", value: 6 },
  ];

  const clearState = () => {
    setpassportFileNames([]);
    setidFilesNames([]);
    setcertificateFilesNames([]);
    setdirectorDetail(null);
  };

  const { countries, states } = useSelector((state) => state.vendor);

  useEffect(() => {
    const fetchCountriesAndStates = async () => {
      await dispatch(getCountries());
      await dispatch(getStates());
    };
    if (!Object.keys(countries[0]).length) {
      fetchCountriesAndStates();
    }
  }, [dispatch, countries]);

  const { showAlert, Toast } = useAlert();

  const onSubmit = (inputData) => {
    const formData = new FormData();
    formData.append("country", inputData.country);
    formData.append("title", inputData.title);
    formData.append("city", inputData.city);
    formData.append("firstName", inputData.name);
    formData.append("addressLine1", inputData.addressLine1);
    formData.append("addressLine2", inputData.addressLine2);
    formData.append("identificationType", inputData.identificationType);
    formData.append("phoneNumber", inputData.phoneNumber);
    formData.append("state", inputData.state);

    const checkIfNewPassportUploaded = passportFileNames[0] && passportFileNames[0].type !== "upload";
    const checkIfNewIdCardUploaded = idFilesNames[0] && idFilesNames[0].type !== "upload";

    checkIfNewPassportUploaded && formData.append("passportPhoto", passportFileNames[0] && passportFileNames[0]);

    checkIfNewIdCardUploaded && formData.append("identificationFile", idFilesNames[0] && idFilesNames[0]);
    certificateFilesNames &&
      certificateFilesNames.map((eachFile) => eachFile.type !== "upload" && formData.append("certifications", eachFile));

    if (!directorDetail) {
      dispatch(addDirectorDetails({ data: formData, userId })).then((res) => {
        if (res.error) {
          return showAlert({ severity: "error", message: res.payload });
        }
        toggleDrawer();
        clearState();
      });
    } else {
      dispatch(editDirectorDetails({ data: formData, userId, directorId: directorDetail.id })).then((res) => {
        !res.error && toggleDrawer();
        !res.error && clearState();
      });
    }
  };

  const onRenderError = (error) => <ErrorContainer>{error && error.message}</ErrorContainer>;

  return (
    <Drawer anchor="right" open={isOpen}>
      <Wrapper>
        <FormTitle>Add New Director/Partner</FormTitle>
        <Toast />
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputField
            type="text"
            label="Title"
            name="title"
            defaultValue={directorDetail && directorDetail.title ? directorDetail.title : ""}
            required
            style={{ width, margin, marginTop: "30px", padding: 0 }}
            inputRef={register({
              required: "Please supply title",
              validate: (value) => value.length > 1 || "Please supply title",
            })}
          />
          {onRenderError(errors.title)}

          <InputField
            type="text"
            label="Name"
            required
            name="name"
            defaultValue={directorDetail && directorDetail.firstName ? directorDetail.firstName : ""}
            style={{ width, margin, padding: 0 }}
            inputRef={register({
              required: "Please supply first name",
              validate: (value) => value.length > 3 || "Please supply first name",
            })}
          />
          {onRenderError(errors.name)}

          <InputField
            type="text"
            label="Address 1"
            name="addressLine1"
            defaultValue={directorDetail && directorDetail.addressLine1 ? directorDetail.addressLine1 : ""}
            required
            style={{ width, margin, padding: 0 }}
            inputRef={register({
              required: "Please supply address",
              validate: (value) => value.length > 4 || "Please supply address",
            })}
          />
          {onRenderError(errors.addressLine1)}

          <InputField
            type="text"
            label="Address 2"
            name="addressLine2"
            defaultValue={directorDetail && directorDetail.addressLine2 ? directorDetail.addressLine2 : ""}
            style={{ width, margin, padding: 0 }}
            inputRef={register()}
          />
          {onRenderError(errors.addressLine2)}

          <InputField
            type="phone"
            label="Phone Number"
            name="phoneNumber"
            defaultValue={directorDetail && directorDetail.phoneNumber ? directorDetail.phoneNumber : ""}
            style={{ width, margin, padding: 0 }}
            inputRef={register({
              required: "Please supply your phone number",
              validate: (value) => value.length >= 0 || "Please supply phone number",
            })}
          />
          {onRenderError(errors.phoneNumber)}

          <Dropdown
            name="country"
            label="Country"
            values={countries}
            style={{ width, margin }}
            inputRef={register({
              required: "Please select country",
              validate: (value) => value.length > 0 || "Please select country",
            })}
          />
          {onRenderError(errors.state)}

          <Dropdown
            name="state"
            label="State"
            values={states}
            style={{ width, margin }}
            defaultValue={directorDetail && directorDetail.state ? directorDetail.state : 1}
            inputRef={register({
              required: "Please select state",
              validate: (value) => value.length > 0 || "Please select state",
            })}
          />
          {onRenderError(errors.state)}

          <InputField
            name="city"
            type="text"
            label="City"
            defaultValue={directorDetail && directorDetail.city ? directorDetail.city : ""}
            style={{ width, margin, padding: 0 }}
            inputRef={register({
              required: "Please select city",
              validate: (value) => value.length > 0 || "Please select city",
            })}
          />
          {onRenderError(errors.city)}
          <IdentificationContainer>
            <FormLabel>Passport Photo</FormLabel>
            <FileUploadField filesNames={passportFileNames} setFilesNames={setpassportFileNames} />
          </IdentificationContainer>
          <IdentificationContainer>
            <FormLabel>Upload Identification</FormLabel>
            <Dropdown
              name="identificationType"
              label="Choose Identification Document"
              values={identificationTypes}
              defaultValue={directorDetail && directorDetail.identificationType ? directorDetail.identificationType : 1}
              style={{ width, margin, backgroundColor: "#fff" }}
              inputRef={register({
                required: "Please select ID Option",
                validate: (value) => value.length !== "Please select ID Option" || "Please select ID Option",
              })}
            />
            {onRenderError(errors.state)}
            <FileUploadField filesNames={idFilesNames} setFilesNames={setidFilesNames} />
          </IdentificationContainer>
          <FileUploadSection
            title="Upload Certificates"
            singleFileUpload={false}
            filesNames={certificateFilesNames}
            setfilesNames={setcertificateFilesNames}
          />

          <ButtonContainer>
            <CancelButton
              type="button"
              onClick={() => {
                clearState();
                return toggleDrawer();
              }}
            >
              Cancel
            </CancelButton>
            <SaveButton style={{ minWidth: "100px" }} type="submit" disabled={loading === "pending"}>
              {loading === "pending" ? "Please Wait..." : "Save"}
            </SaveButton>
          </ButtonContainer>
        </form>
      </Wrapper>
    </Drawer>
  );
};

export default DirectorDetailsDrawer;
