import styled from "styled-components";

export const TopContent = styled.div`
  box-sizing: border-box;
  height: 38px;
  border: 1px solid #c9d4db;
  background-color: #e4e9ec;
  padding-top: 10px;
  padding-left: 10px;

  p {
    height: 16px;
    color: #5f7483;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 16px;
    align-items: center;
  }
`;

export const BottomContentWrapper = styled.div`
  height: 401px;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 #c9d4db;
`;
