export const cardInfoList = [
  {
    id: 1,
    title: "Commissioner",
    name: "Amaka Chibueze",
    description: "Prunedge’s Style Guide can be easily included in applications or one-off projects.",
  },
  {
    id: 2,
    title: "Permanent Secretary",
    name: "Kalu Nnamani",
    description: "Prunedge’s Style Guide can be easily included in applications or one-off projects.",
  },
];
