import React from "react";
import Grid from "@material-ui/core/Grid";
import FileDownload from "../../../../../components/FileDownload";
import { Wrapper, Container } from "./style";
import { Title } from "../style";
import Tag from "../../../../../components/Tag";
import Box from "@material-ui/core/Box";

export default function DocumentList({ variant = "provided", documents = [], ...props }) {
  return (
    <Wrapper variant={variant} {...props}>
      <Container variant={variant}>
        <Title bottomSpacing="20px">
          <Box display="inline-flex" alignItems="center">
            <span>{variant === "vendor" ? "Your" : "Bidding"} Documents</span>
            {variant === "vendor" && (
              <Box ml={4.25}>
                <Tag color="yellow" text="Under Review" rounded size="small" />
              </Box>
            )}
          </Box>
        </Title>
        <Grid container spacing={2}>
          {documents?.documents?.map((document) => (
            <Grid item xs={4} key={document?.name}>
              <FileDownload name={document?.name} size={document?.file?.bytes} url={document?.file?.secure_url} lightTheme />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Wrapper>
  );
}
