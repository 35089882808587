import { Container, Box, Button, SvgIcon } from "@material-ui/core";
import React, { useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import { TenderTitle } from "./style";
import { BreadcrumbActive, BreadcrumbLabel } from "../TenderList/style";
import HomepageNavBar from "../../../components/HomepageHeader";
import Loader from "../../../components/Common/Loader";
import { getAdminContractDetails } from "../../../redux/reducer/adminReducer";
import ContractDetailsCardTable from "./ContractDetailsCardTable";

import { ReactComponent as CodeSvg } from "../../../assets/code.svg";
import { ReactComponent as PrintPdf } from "../../../assets/print.svg";

const AwardedContractDetails = () => {
  const classes = useStyles();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { adminContractDetails } = useSelector((state) => state.admin);
  const linkStyle = { textDecoration: "none", color: "inherit" };
  const contractInfo = adminContractDetails.contractDetails;

  useEffect(() => {
    dispatch(getAdminContractDetails(id));
  }, [dispatch, id]);

  const handleDownloadJson = () => {
    var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(adminContractDetails));
    var downloadAnchorNode = document.createElement("a");
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", `awarded-contract-detail-${Date.now()}.json`);
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  };

  return (
    <div>
      <HomepageNavBar />
      <Container style={{ marginBottom: 120 }}>
        <BreadcrumbLabel>
          <Link style={linkStyle} to="/">
            Home
          </Link>
          <BreadcrumbActive>
            /
            <Link style={linkStyle} to="/ocds-portal/awarded-contracts">
              Awarded Contracts
            </Link>
            / {contractInfo?.referenceId}
          </BreadcrumbActive>
        </BreadcrumbLabel>

        {Object.keys(adminContractDetails).length < 1 ? (
          <Loader />
        ) : (
          <>
            <TenderTitle>{contractInfo?.contractName}</TenderTitle>
            <Box my={3}>
              <Box display="flex">
                <Box mr={2}>
                  <Button
                    classes={{ startIcon: classes.btnStartIcon, root: classes.downLoadBtn }}
                    startIcon={<SvgIcon component={CodeSvg} style={{ color: "#fff", height: 30, width: 30, margin: 0 }} />}
                    onClick={handleDownloadJson}
                  >
                    Download JSON
                  </Button>
                </Box>
                <Box>
                  <Button
                    classes={{ startIcon: classes.btnStartIcon, root: classes.downLoadBtn }}
                    startIcon={<SvgIcon component={PrintPdf} style={{ color: "#fff", height: 30, width: 30, margin: 0 }} />}
                    onClick={() => window.print()}
                  >
                    Print
                  </Button>
                </Box>
              </Box>
            </Box>
            <ContractDetailsCardTable />
          </>
        )}
      </Container>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  btnStartIcon: {
    margin: 0,
  },
  downLoadBtn: {
    border: "1px solid #E5E5EA",
    boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
    textTransform: "none",
  },
}));

export default AwardedContractDetails;
