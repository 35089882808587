import { useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import ToastContext from "../../../../../../util/toastContext";
import { queryCache, useMutation } from "react-query";
import receiptOfResponseApi from "../../../../../../redux/api/receiptOfResponseApi";

const useRejectVendor = ({ queryKey }) => {
  const history = useHistory();
  const { activityId, procurementId } = useParams();

  const { showAlert } = useContext(ToastContext)

  const [rejectVendors, rejectVendorsQuery] = useMutation(receiptOfResponseApi.rejectVendor)

  const reject = async (vendors, onSuccess) => {
    try{
      await rejectVendors({
        activityId,
        vendors
      })

      onSuccess && onSuccess()

      queryCache.removeQueries(queryKey)

      showAlert({
        severity: 'success',
        message: 'Successfully rejected vendor(s)',
        durationInMs: 3000
      })
      setTimeout(function(){ history.push(`/admin/procurement/activity/receiptofresponses/${activityId}/${procurementId}`)}, 2000);
    }catch (e) {
      showAlert({
        severity: 'error',
        message: e.message,
        durationInMs: 3000
      })
    }
  }

  return {
    isLoading: rejectVendorsQuery.isLoading,
    reject
  }
};

export default useRejectVendor;
