import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { ContentWrapper, TitleContainer } from "../../../Admin/Administration/Staff/style";
import Breadcrumb from "../../../Common/PageElements/Breadcrumb";
import { PageTitle } from "../../../Common/style";
import ContractInfo from "../ContractInfo";
import { MainWrapper } from "../LetterOfAcceptance/style";
import withVendorDashboard from "../../HOC/withVendorDashboard";
import vendorsAPI from "../../../../redux/api/vendorsAPI";
import {
  MainText,
  MainTitle,
  CardWrapper,
  Pad,
  FileBoard,
  CuteGrid,
  FileCard,
  FileText,
  InterestExpressedPane,
} from "../../Procurement/style";
import { ReactComponent as FolderIcon } from "../../../../assets/folder.svg";
import { BiddingDocumentContainer } from "../../BidDetails/BidInfo/style";
import FileDownload from "../../../../components/FileDownload";
import { ReactComponent as CheckIcon } from "../../../../assets/check_mark.svg";
import procurementAPI from "../../../../redux/api/procurementAPI";
import moment from "moment";
import DocumentModal from "../../BidDetails/NewBidInfo/DocumentCategories/DocumentModal";

const SignedContractDoc = () => {
  const { contractId } = useParams();
  const [selectedDocumentFolder, setSelectedDocumentFolder] = useState(null);

  const contractInfo = useQuery(["signedContractDoc", contractId], vendorsAPI.getVendorContractById);
  const procurementId = contractInfo?.data?.procurementPlan?.id;

  const { data: documents = [] } = useQuery(["exchangedDocuments", procurementId], procurementAPI.getExchangedDocuments);

  const { data: letter = [] } = useQuery(["getSignedContract", procurementId], vendorsAPI.getVendorSignedContract);
  const file = letter[0]?.file;
  const datePublished = moment(letter[0]?.createdAt).format("DD/MM/YYYY");

  const BreadcrumbLinks = [
    { url: "/vendor/contracts", title: "Contract", forwardSlash: true },
    { url: "/vendor/contracts", title: " Details", forwardSlash: true },
    { url: "/vendor/contracts", title: " Contract Signed", forwardSlash: false },
  ];

  return (
    <ContentWrapper>
      <TitleContainer>
        <div>
          <PageTitle>{contractInfo?.data?.procurementPlan?.packageNumber}</PageTitle>
          <Breadcrumb values={BreadcrumbLinks} />
        </div>
      </TitleContainer>
      <InterestExpressedPane>
        <CuteGrid>
          <div>
            <MainText color="#212B36" fw="bold" fontSize="14px">
              Signed Contract Document Uploaded
            </MainText>
            <MainText color="#212B36" mt="10px" fontSize="14px">
              You have successfully uploaded Signed Contract Document
            </MainText>
          </div>
          <div>
            <CheckIcon />
          </div>
        </CuteGrid>
      </InterestExpressedPane>
      <CardWrapper>
        <Pad>
          <ContractInfo id={procurementId} publishDate={datePublished} />
          <MainTitle mt="40px">Documents</MainTitle>
          <MainText mt="15px" fontSize="14px">
            List of all documents exchanged
          </MainText>
        </Pad>
        {documents.length ? (
          <FileBoard>
            <CuteGrid start>
              {documents.map((doc) => (
                <div style={{ cursor: "pointer" }} key={doc.id} onClick={() => setSelectedDocumentFolder(doc)}>
                  <FileCard>
                    <FolderIcon />
                    <FileText mt="15px" color="#00B0F1" fontSize="13px">
                      {doc.count} files
                    </FileText>
                  </FileCard>
                  <FileText mt="20px" fontSize="14px" wd="77%">
                    {doc.title}
                  </FileText>
                </div>
              ))}
            </CuteGrid>
          </FileBoard>
        ) : (
          <Pad>
            <MainText mt="10px">No documents yet</MainText>{" "}
          </Pad>
        )}
      </CardWrapper>
      <MainWrapper mt="20px">
        <MainTitle mt="10px" mb="20px">
          {" "}
          Signed contract Document
        </MainTitle>
        <BiddingDocumentContainer>
        <div style={{width:"25%"}}>
             {file ? 
             letter.map((item)=>(
              <FileDownload 
              url={item.file?.url} 
              lighttheme 
              name={item.file?.original_filename} 
              size={item.file?.bytes} />
             ))
             :<Pad> <MainText mt="10px">No document yet</MainText> </Pad> }
           </div>
        </BiddingDocumentContainer>
      </MainWrapper>
      <DocumentModal selectedCategory={selectedDocumentFolder} onClose={() => setSelectedDocumentFolder(null)} />
    </ContentWrapper>
  );
};

export default withVendorDashboard(SignedContractDoc);
