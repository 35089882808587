import React, { useContext, useState, Fragment } from "react";
import { SectionContainer, SectionContent, Title } from "../style";
import { useQuery } from "react-query";
import Loader from "../../../../components/Common/Loader";
import ToastContext from "../../../../util/toastContext";
import vendorsAPI from "../../../../redux/api/vendorsAPI";
import Grid from "@material-ui/core/Grid";
import DetailItem from "../../../../components/DetailItem";
import { parseISO, formatDistanceStrict } from "date-fns";
import ReportItem from "./ReportItem";
import TablePagination from "@material-ui/core/TablePagination";

const safeParseISO = (value) => (typeof value === "string" ? parseISO(value) : new Date());

export default function BidReport({ bid, tenderId }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { showAlert } = useContext(ToastContext);
  const onError = (error) => {
    showAlert({
      severity: "error",
      message: error.message,
      durationInMs: 3000,
    });
  };
  const reportQuery = useQuery(["bidEvaluation", tenderId, rowsPerPage, page + 1], vendorsAPI.getBidEvaluationReport, {
    enabled: !!tenderId,
    onError,
  });
  const contractQuery = useQuery(["procurementContractInfo", tenderId], vendorsAPI.getProcurementContractInfo, {
    enabled: !!tenderId,
    onError,
  });

  const isLoading = reportQuery.isLoading || contractQuery.isLoading;
  const isError = reportQuery.isError || contractQuery.isError;

  const { bidEvaluation: evaluationReports = [], meta = {} } = reportQuery.data ?? [];
  const contract = contractQuery.data?.[0] ?? null;

  const renderDetail = (label, text, span = 3) => (
    <Grid item xs={span}>
      <DetailItem label={label} text={text} />
    </Grid>
  );

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (isLoading) {
    return (
      <SectionContainer>
        <Loader />
      </SectionContainer>
    );
  }

  return (
    <SectionContainer>
      {!isError && (
        <Fragment>
          <SectionContent>
            <Title bottomSpacing="20px">Contract Information</Title>
            <Grid container spacing={2}>
              {renderDetail("Contract Description", contract?.description, 6)}
              {renderDetail(
                "Contract Duration",
                formatDistanceStrict(safeParseISO(contract?.startDate), safeParseISO(contract?.endDate))
              )}
              {renderDetail("Currency", contract?.currency ?? "Naira")}
            </Grid>
          </SectionContent>
          {evaluationReports.map((report) => (
            <ReportItem key={report.id} report={report} />
          ))}
          {meta?.pagination?.totalEntries > rowsPerPage && (
            <TablePagination
              rowsPerPageOptions={[10, 25]}
              component="div"
              count={meta?.pagination?.totalEntries || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          )}
        </Fragment>
      )}
    </SectionContainer>
  );
}
