import React from "react";
import { Box, Grid, Typography, Dialog } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import format from "date-fns/format";
import { useForm } from "react-hook-form";
import moment from "moment";

import { MilestoneCloseIcon, MilestoneTitleText, ModalContent } from "./style";
import { Dropdown, InputField } from "../../../../components";
import { ErrorContainer, SearchButton } from "../../../../components/Forms/Common/style";
import { CancelButton } from "../../ProjectInfo/MilestoneModal/style";

const useStyles = makeStyles(() => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const MilestoneModal = ({ open = false, handleClose, onSubmit, addTaskStatus }) => {
  const { register, handleSubmit, errors, getValues } = useForm();
  const classes = useStyles();

  const onRenderError = (error) => <ErrorContainer> {error && error.message} </ErrorContainer>;

  return (
    <Dialog open={open} onClose={handleClose} className={classes.modal}>
      <ModalContent>
        <Box display="flex" justifyContent="space-between">
          <MilestoneTitleText>Create Task</MilestoneTitleText>
          <MilestoneCloseIcon onClick={handleClose} />
        </Box>
        <Typography style={{ color: "red" }}>{addTaskStatus.status === "error" && addTaskStatus?.error?.message}</Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputField
            label="Task title"
            defaultValue="adaroooma"
            name="title"
            style={{ width: "100%", margin: 0, marginTop: "24px" }}
            inputRef={register({
              required: "Please supply task title",
              validate: (value) => value.length > 3 || "Please supply task title",
            })}
          />
          {onRenderError(errors.title)}
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <InputField
                label="Start date"
                defaultValue={format(new Date(), "yyyy-MM-dd")}
                type="date"
                name="startDate"
                style={{ width: "100%", margin: 0, marginTop: "24px" }}
                inputRef={register({
                  required: "Your date must be greater than today",
                  validate: (value) => {
                    let now = moment();
                    const isBeforeDate = moment(value).isBefore(now, "day");
                    return !isBeforeDate || "Date must be today, or a future date";
                  },
                })}
              />
              {onRenderError(errors.startDate)}
            </Grid>
            <Grid item xs={6}>
              <InputField
                label="End date"
                type="date"
                name="endDate"
                defaultValue={format(new Date(), "yyyy-MM-dd")}
                style={{ width: "100%", margin: 0, marginTop: "24px" }}
                inputRef={register({
                  required: "Please supply task title",
                  validate: (value) => {
                    let now = moment(getValues("startDate"));
                    const isAfterDate = moment(value).isAfter(now, "day");
                    return isAfterDate || "Date should be future date to start date";
                  },
                })}
              />
              {onRenderError(errors.endDate)}
            </Grid>
            <Grid item xs={6}>
              <Dropdown
                values={[{ label: "Naira", value: 0 }]}
                label="Evaluation Currency"
                style={{ width: "100%", margin: 0 }}
              />
            </Grid>
            <Grid item xs={6}>
              <InputField
                label="Value"
                defaultValue={"234,994,0000.00"}
                style={{ width: "100%", margin: 0 }}
                name="estimatedValue"
                inputRef={register({
                  required: "Please supply a number",
                  min: 0,
                  validate: (value) => value > 3 || "Please supply a number",
                })}
              />
              {onRenderError(errors.estimatedValue)}
            </Grid>
          </Grid>
          <InputField
            label="Task Description"
            multiline={true}
            name="description"
            style={{ width: "100%", margin: 0, marginTop: "20px" }}
            inputRef={register({
              required: "Please supply task description",
              validate: (value) => value.length > 3 || "Please supply task description",
            })}
          />
          {onRenderError(errors.description)}
          <Box display="flex" justifyContent="flex-end" alignItems="center" marginTop="30px">
            <CancelButton onClick={handleClose} disabled={addTaskStatus.isLoading} variant="outlined">
              Cancel
            </CancelButton>
            <SearchButton type="submit" loading={addTaskStatus.isLoading} noBottom>
              Create Task
            </SearchButton>
          </Box>
        </form>
      </ModalContent>
    </Dialog>
  );
};

export default MilestoneModal;
