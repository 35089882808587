import React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { formatDistanceStrict, format, parseISO } from "date-fns";
import DetailItem from "../../../../../../../components/DetailItem";
import { PageTitle, PageBreadcrumbs } from "../../../../../../Common/style";
import { ContentWrapper, TitleContainer } from "../../../../style";
import { CardWrapper, CardBody, CardTitle } from "../../style";
import { DetailRow } from "../style";
import { ContractStatusBadge, Subsection, FirmName, Subtitle, Divider } from "./style";
import FileDownload from "../../../../../../../components/FileDownload";
import { ReactComponent as MedalIcon } from "../../../../../../../assets/medal.svg";
import { formatCurrency } from "../../../../../../../util/formatCurrency";

function SignedContractDetails({ contract }) {
  const renderDetail = (label, text, span = 3) => (
    <Grid item xs={span}>
      <DetailItem label={label} text={text} />
    </Grid>
  );
  const renderWideDetail = (label, text) => <DetailRow container>{renderDetail(label, text, 6)}</DetailRow>;
  return (
    <ContentWrapper>
      <TitleContainer>
        <div>
          <PageTitle>{contract?.ref}</PageTitle>
          <PageBreadcrumbs>
            Home / Procurement plans / {contract?.procurementPlan.ministry} / {contract?.procurementPlan.name} / Contract
          </PageBreadcrumbs>
        </div>
      </TitleContainer>
      <CardWrapper>
        <CardBody>
          <Box mb={2.75} display="flex" alignItems="center">
            <ContractStatusBadge>Signed</ContractStatusBadge>
            <CardTitle noBackground noMargin>
              General Information
            </CardTitle>
          </Box>
          <Box mb={3.75}>
            <DetailRow container>
              {renderDetail("Notice Type", "Contract Award")}
              {renderDetail("Contract Ref. No.", contract?.ref)}
              {renderDetail("Language of Notice", "English")}
              {renderDetail("Procurement Method", contract?.procurementPlan.procurementMethod?.name)}
            </DetailRow>
            <DetailRow container>
              {renderDetail("Contract Duration", formatDistanceStrict(parseISO(contract?.startDate), parseISO(contract?.endDate)))}
              {renderDetail("Signature Date", format(parseISO(contract?.signedDate), "dd MMM, yyyy"))}
              {renderDetail("Minimum Qualiying Score", contract?.minimumScore)}
            </DetailRow>
            {renderWideDetail("Description", contract?.description)}
          </Box>
          <Subsection>
            <Box display="flex" alignItems="flex-start" justifyContent="space-between">
              <CardTitle noBackground noPadding>
                Contract Award
              </CardTitle>
              <MedalIcon />
            </Box>
            <Box mb={2.5}>
              {renderWideDetail("Project", contract?.procurementPlan.name)}
              {renderWideDetail("Scope of Contract", contract?.scope)}
              {renderWideDetail("Notice Version No", contract?.noticeVersionNo)}
              {renderWideDetail("Signature Date", format(parseISO(contract.signedDate), "dd MMM, yyyy"))}
              {renderWideDetail(
                "Duration of Contract",
                formatDistanceStrict(parseISO(contract?.startDate), parseISO(contract.endDate))
              )}
              {renderWideDetail("Minimum Qualiying Score", contract?.minimumScore)}
            </Box>
            <Subsection mb={3.75}>
              {renderWideDetail("Awarded Firm", <FirmName>{contract?.contractor?.companyName}</FirmName>)}
              {renderWideDetail("Score", contract?.vendor?.score)}
              {renderWideDetail("Signed Contract Price", formatCurrency(contract?.vendor?.signedPrice, true))}
            </Subsection>
            <CardTitle noBackground>Documents</CardTitle>
            <Subtitle>Mandatory Files</Subtitle>
            <Grid container spacing={3}>
              {contract?.documents?.mandatory.map((document) => (
                <Grid key={document.id} item xs={4}>
                  <FileDownload name={document.name} size={document.size} url={document.url} />
                </Grid>
              ))}
            </Grid>
            <Divider />
            <Subtitle>Supporting Files</Subtitle>
            <Grid container spacing={3}>
              {contract?.documents?.supporting.map((document) => (
                <Grid key={document.id} item xs={4}>
                  <FileDownload name={document.name} size={document.size} url={document.url} />
                </Grid>
              ))}
            </Grid>
          </Subsection>
        </CardBody>
      </CardWrapper>
    </ContentWrapper>
  );
}

export default SignedContractDetails;
