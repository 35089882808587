import React, { useContext } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { useForm, Controller } from "react-hook-form";
import FormContext from "../../../../../../../util/context";
import { ErrorContainer } from "../../../../../../../components/Forms/Common/style";
import { FormSectionTitle, FormSectionContent, TabActions, TabBackButton, ApproveButton } from "./style";
import InputField from "../../../../../../../components/InputField";
import LoadingButton from "../../../../../../../components/LoadingButton/LoadingButton";
import ControlledDatePicker from "../../../../../../../components/ControlledDatePicker";
import { Roles } from "../../../../../../../components/AccessControl/UserRoles";
import { useRoles } from "../../../../../../../hooks/useUserDetails";

const inputStyleOverrides = {
  width: "100%",
  margin: 0,
};

function DatasheetForm({handleApproval, isLoadingForApproval}) {
  const {
    state: { datasheet },
    isUploading,
    updateDatasheet,
    previousTab,
    uploadData,
  } = useContext(FormContext);
  const { handleSubmit, errors, register, getValues, control } = useForm({
    defaultValues: datasheet,
  });

  //Access control config
  const { role } = useRoles();  
  const isDisabled = role !== Roles.PROCUREMENT_OFFICER;
  const isMaker = role === Roles.PROCUREMENT_OFFICER;
  const isChecker = [Roles.COMMISSIONER, Roles.PERMANENT_SECRETARY].includes(role);

  const renderError = (error) => <ErrorContainer>{error ? error.message : null}</ErrorContainer>;

  const onSubmit = (data) => {
    updateDatasheet(data);
    // TODO: API integration
    uploadData();
  };

  const goBack = () => {
    // Store the values so the user doesn't lose their progress
    updateDatasheet(getValues());
    previousTab();
  };

  const renderActionButtonByRoles =()=>{
    if (isMaker){  
      return(   
        <LoadingButton loading={isUploading} type="submit">
        Send for Approval
      </LoadingButton>
      )
      };
    if (isChecker){
      return (      
      <ApproveButton onClick={handleApproval} loading={isLoadingForApproval}>
        Approve
      </ApproveButton>    
      )
    }
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormSectionTitle>Datasheet</FormSectionTitle>
      <FormSectionContent>
        <Box marginBottom={2.5}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Controller
                name="signatureDate"
                control={control}
                rules={{
                  required: "Please supply a signature date",
                }}
                render={(props) => (
                  <ControlledDatePicker
                    label="Signature Date"
                    style={{ ...inputStyleOverrides }}
                    inputProps={{
                      fullWidth: true,
                    }}
                    {...props}
                    disabled={isDisabled}
                  />
                )}
              />
              {renderError(errors.signatureDate)}
            </Grid>
            <Grid item xs={6}>
              <InputField
                fullWidth
                type="text"
                label="Reference"
                name="reference"
                style={{ ...inputStyleOverrides }}
                inputRef={register({
                  required: "Please supply a reference",
                })}
                disabled={isDisabled}
              />
              {renderError(errors.reference)}
            </Grid>
          </Grid>
        </Box>
        <Grid container>
          <Grid item xs={12}>
            <InputField
              fullWidth
              type="text"
              label="Description"
              name="description"
              multiline
              rows={4}
              style={{ ...inputStyleOverrides }}
              inputRef={register({
                required: "Please supply a description",
              })}
              disabled={isDisabled}
            />
            {renderError(errors.description)}
          </Grid>
        </Grid>
      </FormSectionContent>
      <TabActions>
      
        <TabBackButton disabled={isUploading} onClick={goBack}>
          Back
        </TabBackButton>
        {renderActionButtonByRoles()} 
       
      </TabActions>
    </form>
  );
}

export default React.memo(DatasheetForm);
