import styled from "styled-components";
import { Grid } from "@material-ui/core";

export const SectionWrapper = styled.section`
  box-sizing: border-box;
  margin: 0 auto;
`;
export const Section1 = styled.div`
  box-sizing: border-box;
  // height: 488px;
  display: flex;
  position: relative;
  margin-top: 100px;

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

export const QuoteWrapper = styled.div`
  box-sizing: border-box;
  // height: 446px;
  width: 50rem;
  background-color: rgba(50, 197, 255, 0.06);
  margin-top: 42px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;
export const OpenQuoteIcon = styled.p`
  height: 160px;
  width: 54px;
  color: #03adeb;
  font-family: "Nunito Sans";
  font-size: 130px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 160px;
  position: absolute;
  top: 15px;
  left: 32px;
  @media screen and (max-width: 600px) {
    top: 0;
    left: 0;
  }
`;
export const CloseQuoteIcon = styled.p`
  // height: 160px;
  // width: 54px;
  transform: rotate(180deg);
  color: #03adeb;
  font-family: "Nunito Sans";
  font-size: 130px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 160px;
  position: absolute;
  bottom: 18px;
  right: 32px;

  @media screen and (max-width: 600px) {
    height: auto;
    bottom: 0;
    right: 0;
  }
`;
export const Quote = styled.p`
  box-sizing: border-box;
  // height: 288px;
  max-width: 482px;
  color: #034962;
  font-family: "Nunito Sans";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  // margin-left: 138px;
  // margin-top: 77px;
  // margin-bottom: 77px;
  margin: 32px;

  @media screen and (max-width: 600px) {
    width: auto;
    margin: 77px 24px;
  }
`;
export const Image = styled.div`
  box-sizing: border-box;
  height: 446px;
  width: 461px;
  background-image: url("https://prunedge.com/wp-content/uploads/2022/03/Rectangle-74-2.png");
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 42px;
  // position: absolute;
  z-index: 1;
  right: 0;

  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const VisionOrMissionIcon = styled.div`
  box-sizing: border-box;
  height: 30px;
  width: 32.73px;
  margin-bottom: 20px;
  outline: 1px solid white;
`;
export const VisionOrMissionTitle = styled.h4`
  box-sizing: border-box;
  height: 24px;
  width: 51px;
  color: #ffffff;
  font-family: "Nunito Sans";
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 15px;
`;
export const VisionOrMissionDescription = styled.p`
  box-sizing: border-box;
  max-width: 382px;
  color: #ffffff;
  font-family: "Nunito Sans";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 21px;
`;
export const VisionAndMission = styled.div`
  box-sizing: border-box;
  min-height: 242px;
  border-radius: 3px;
  background-color: #274472;
  margin-top: 100px;
`;
export const VisionOrMission = styled.div`
  box-sizing: border-box;
  padding: 40px 60px;

  @media screen and (max-width: 750px) {
    padding: 3rem;
  }

  @media screen and (max-width: 600px) {
    padding-left: 24px;
    padding-bottom: 40px;
  }
`;

export const MemberCard = styled(Grid)`
  box-sizing: border-box;
  max-height: 324px;
  width: 267px;
  margin-top: 33px;
`;
export const MemberImage = styled.div`
  box-sizing: border-box;
  width: 100%;
  min-height: 157px;
  border-radius: 3px;
  background-color: #e5e9ec;
`;
export const MemberInfo = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
`;
export const MemberName = styled.div`
  box-sizing: border-box;
  color: #034962;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;

  @media screen and (max-width: 600px) {
    text-align: center;
  }
`;
export const MemberRole = styled.div`
  text-align: center;
  box-sizing: border-box;
  color: #adb4bb;
  font-family: "Nunito Sans";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 21px;
`;

export const SectionTitle = styled.h2`
  box-sizing: border-box;
  height: 32px;
  color: #034962;
  font-family: "Nunito Sans";
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
`;
export const SectionSubtitle = styled.h3`
  box-sizing: border-box;
  height: 32px;
  color: #034962;
  font-family: "Nunito Sans";
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
`;
export const PeopleSection = styled.div`
  box-sizing: border-box;
`;
export const Members = styled(Grid)`
  box-sizing: border-box;
  margin-top: -3px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;
export const PeopleSections = styled.div`
  box-sizing: border-box;
  margin-top: 100px;
  hr {
    box-sizing: border-box;
    height: 1px;
    width: 72px;
    border: 4px solid #034962;
    margin-top: 20px;
    margin-bottom: 40px;
  }
`;
export const PeopleSectionsContent = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-gap: 73px;
`;
