import styled from "styled-components";

export const DrawerHeader = styled.h1`
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  color: #3F5673;
  margin-bottom: 30px;
`

export const DrawerAmountText = styled.p`
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #1C2125;
`
