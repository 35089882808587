import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import styled from "styled-components";
import AdminCard from "../../../components/AdminCard";
import AdminSearchFilter from "../../../components/AdminSearchFilter";
import DatePicker from "../../../components/DatePicker";
import SearchTable from "../../../components/SearchTable";
import contractsAPI from "../../../redux/api/contractAPI";
import { getContractsSummary } from "../../../redux/reducer/adminReducer";
import { CardWrapper } from "../../../util/theme";
import ToastContext from "../../../util/toastContext";
import withLoader from "../../Common/HOC/withLoader";
import Breadcrumb from "../../Common/PageElements/Breadcrumb";
import { PageTitle } from "../../Common/style";
import { TitleContainer } from "../Administration/Staff/style";
import withAdminDashboard from "../HOC/withAdminDashboard";
import ContractTable from "./ContractTable";
import { ClearButton, ContractDropDownField, ContractInputField } from "./style";
import MinistryDropdown from "../../../components/MinistryDropdown";
import { useRoles } from "../../../hooks/useUserDetails";

export const ContentWrapper = styled.div`
  padding: 0 0 60px;

  .MuiTableCell-body:nth-child(2) {
    display: table-cell;
    align-items: center;

    div:first-child {
      margin: 0 10px 0 0;
      width: 30px;
      height: 30px;
      color: white;
      background-color: #50b83c;
    }
  }
`;

const Contracts = () => {
  const [advanceSearch, setAdvanceSearch] = useState(true);
  const { user } = useRoles();

  const { contractsSummary } = useSelector((state) => state.admin);

  const toggleSearch = () => {
    setAdvanceSearch(!advanceSearch);
  };

  const AdminCardSummary = () => (
    <AdminCard
      title={"Total Contracts"}
      count={contractsSummary.total}
      description={"All active and inactive"}
      statuses={[
        { text: `${contractsSummary.signed} Signed`, bgColor: "#BBE5B3", textColor: "#108043" },
        { text: `${contractsSummary.unsigned} Unsigned`, bgColor: "#FEF1C9", textColor: "#DE3618" },
        { text: `${contractsSummary.awarded} Accepted`, bgColor: "#10615a", textColor: "#e1f0e9" },
        { text: `${contractsSummary.pending} Pending`, bgColor: "#ff9efc", textColor: "#DE3618" },
        { text: `${contractsSummary.rejected} Rejected`, bgColor: "#bf5278", textColor: "#ffcfdb" },
        { text: `${contractsSummary.expired} Expired`, bgColor: "#e5e9ec", textColor: "#445571" },
      ]}
    />
  );

  const BreadLinks = [
    { url: "/admin/dashboard/overview", title: "Home" },
    { url: "#", title: "Contracts" },
  ];

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [filters, setFilters] = useState({
    SearchBy: "companyName",
    Search: "",
    ministryId: !!user.ministry ? user.ministry.id : "",
    Date: null,
    Status: null,
  });

  const filterParams = {
    pageSize: rowsPerPage,
    pageNumber: page,
    Search: filters.Search,
    SearchBy: filters.SearchBy,
    Date: filters.Date,
    Status: filters.Status,
    MinistryId: filters.ministryId,
  };

  const { data, isLoading, error = {}, isError } = useQuery({
    queryFn: contractsAPI.getContracts,
    queryKey: ["contracts", filterParams],
  });

  const { showAlert } = useContext(ToastContext);

  useEffect(() => {
    if (isError) {
      showAlert({
        severity: "error",
        message: error?.message,
        durationInMs: 3000,
      });
    }
    //eslint-disable-next-line
  }, [showAlert, isError]);

  const updateFilters = (key) => (value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  };

  return (
    <ContentWrapper>
      <TitleContainer>
        <div>
          <PageTitle>Contracts</PageTitle>
          <Breadcrumb values={BreadLinks} />
        </div>
      </TitleContainer>

      <CardWrapper>
        {withLoader(AdminCardSummary)({
          apis: [getContractsSummary(filterParams)],
          conditioners: ["contractsSummary"],
          stateName: "admin",
        })}
      </CardWrapper>

      <AdminSearchFilter normalSearch toggleSearch={toggleSearch} title="Filter">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <ContractDropDownField
            name="search-by"
            label="Search by"
            style={{ width: "100%", height: "48px" }}
            values={[
              { label: "Company Name", value: "companyName" },
              { label: "Description", value: "title" },
              { label: "Ministry", value: "ministry" },
            ]}
            onChange={updateFilters("SearchBy")}
          />
          <ContractInputField
            type="text"
            label="Search"
            style={{ width: "100%" }}
            value={filters.Search}
            setValue={updateFilters("Search")}
          />

          <ContractDropDownField
            name="status"
            label="Status"
            style={{ width: "100%", height: "48px" }}
            values={[
              { label: "All", value: "All" },
              { label: "Accepted", value: "Accepted" },
              { label: "Rejected", value: "Rejected" },
              { label: "Pending", value: "Pending" },
              { label: "Expired", value: "Expired" },
              { label: "Closed", value: "Closed" },
              // { label: "Assigned", value: "Assigned" },
              { label: "Unsigned", value: "Unsigned" },
              { label: "Signed", value: "Signed" },
            ]}
            onChange={updateFilters("Status")}
          />
          {!user.ministry && <MinistryDropdown onChange={updateFilters("ministryId")} />}
          <div>
            <DatePicker label="Date" value={filters.Date} style={{ margin: 0 }} handleChange={updateFilters("Date")} />
            {filters.Date && <ClearButton onClick={() => updateFilters("Date")(null)}>Clear</ClearButton>}
          </div>
        </div>
      </AdminSearchFilter>
      <SearchTable
        rows={data?.data?.length}
        columns={["Ref ID", "description", "ministry", "contractor", "status", "start date", "end date", "value"]}
        loading={isLoading}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
        totalNumberOfPages={data?.pagination.totalEntries}
      >
        <ContractTable contracts={data?.data} />
      </SearchTable>
    </ContentWrapper>
  );
};

export default withAdminDashboard(Contracts);
