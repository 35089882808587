import styled from "styled-components";
import { theme } from "../../../util/theme";

export const ServiceList = styled.div`
  box-sizing: border-box;
  width: 551px;
  border: 1px solid #EBEBEB;
  border-radius: 3px;
  background-color: #FFFFFF;
  padding: 20.5px;
  margin-top: 29.5px;

  > div:first-child {
    .MuiTypography-body1 {
      font-size: 16px;
      font-weight: 600;
    }
  }

  > div:not(:first-child) {
    margin-left: 10px;
  }
  
  span {
    font-size: 14px;
  }

  .MuiFormControlLabel-root {
    display: block;
  }

  .MuiSvgIcon-root {
    width: 20px;
    height: 20px;
  }

  .MuiCheckbox-colorSecondary.Mui-checked,
  .MuiIconButton-root:hover,
  .MuiCheckbox-colorPrimary.Mui-checked {
    color: ${theme.color.primary};
  }
`;
