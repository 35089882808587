import { queryCache, useMutation, useQuery } from "react-query";
import notificationsApi from "../redux/api/notificationsApi";
import { useCallback } from "react";

const useNotifications = ({ classId, pageNumber, pageSize, isRead }) => {
  const notificationsQuery = useQuery(['notifications', { classId, pageNumber, pageSize, isRead }], notificationsApi.getNotifications)

  const [markAsRead] = useMutation(notificationsApi.markAsRead, {
    onSuccess: () => {
      queryCache.invalidateQueries('notifications')
    }
  })

  const markNotificationsAsRead = useCallback(() => {
    const { notifications = [] } = notificationsQuery.data || {}
    markAsRead(notifications.map(({id}) => id))
  }, [markAsRead, notificationsQuery.data])

  return {
    notificationsQuery,
    markNotificationsAsRead
  }
};

export default useNotifications;
