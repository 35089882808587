import React from "react";
import { Grid, Box, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";

import { ContractDetailTabText } from "./style";
import { formatCurrency } from "../../../util/formatCurrency";

const EvaluatorsTab = () => {
  const {
    adminContractDetails: { tenderDetails = [], contractDetails = {} },
  } = useSelector((state) => state.admin);

  const details = [
    { title: "Contract Sum", value: formatCurrency(contractDetails.contractAmount, true) || "N/A" },
    { title: "Contract Variation", value: "-" || "N/A" },
    { title: "Revised Contract amount", value: contractDetails.contractAmount || "N/A" },

    { title: "Percentage Completion	", value: contractDetails.percentageCompletion || "N/A" },
    { title: "Project Status	", value: tenderDetails.status || "N/A" },
  ];

  return (
    <>
      <Box style={{ background: "#e4e9ec", padding: 20 }}>
        <Typography variant="h6" style={{ color: "#5F7483" }}>
          Award Details
        </Typography>
        <Typography style={{ color: "#5F7483" }}>Current Procurement Stage : {tenderDetails?.procurementStage || 'Implementation Stage'}</Typography>
      </Box>
      <Box>
        {details.map((detail, i) => (
          <Grid key={i} style={{ borderBottom: "1px solid #CBD4DA" }} container>
            <Grid item xs={3}>
              <ContractDetailTabText style={{ background: "#e4e9ec", boxShadow: "0px -1px 0px #C9D4DB" }}>
                {detail.title}
              </ContractDetailTabText>
            </Grid>
            <Grid item sm style={{ borderLeft: "1px solid #CBD4DA" }}>
              <ContractDetailTabText>{detail.value}</ContractDetailTabText>
            </Grid>
          </Grid>
        ))}
      </Box>
    </>
  );
};

export default EvaluatorsTab;
