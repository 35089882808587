import { parseISO } from "date-fns";

export function transformContractSigningToFrontendModel(data) {
  const mandatory = data.documents
    .filter((documentItem) => documentItem.procurementDocumentStatus === "MANDATORY")
    .map((item) => ({
      ...item,
      size: item.file.bytes,
    }));
  const supporting = data.documents
    .filter((documentItem) => documentItem.procurementDocumentStatus === "SUPPORTING")
    .map((item) => ({
      ...item,
      size: item.file.bytes,
    }));
  const { id: datasheetId, ...datasheet } = data.datasheet || { signatureDate: null, reference: "", description: "" };
  if (datasheet.signatureDate) {
    datasheet.signatureDate = parseISO(datasheet.signatureDate);
  }
  return {
    documents: {
      removed: [],
      mandatory,
      supporting,
    },
    datasheet,
    sections: {
      document: mandatory.length > 0 ? "complete" : "incomplete",
      datasheet: Object.values(datasheet).filter((x) => Boolean(x)).length === 3 ? "complete" : "incomplete",
    },
  };
}

export const transformReviewToFrontendModel = (review) => ({
  type: "message",
  author: `${review.createdBy.firstName} ${review.createdBy.lastName}`,
  message: review.body,
  avatar: review.createdBy.profilePicture,
  date: parseISO(review.createdAt),
});
