import jwtDecode from "jwt-decode";

export function getUserDetails() {
  const token = localStorage.getItem("token");
  if (!token) throw new Error("No logged in user");

  return jwtDecode(token);
}

window.getUserDetails = getUserDetails;
