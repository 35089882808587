import React, { useContext, useState, useEffect } from "react";
import { Box } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useQuery, useMutation } from "react-query";
import moment from "moment";
import { Skeleton } from "@material-ui/lab";

import usersAPI from "../../redux/api/usersAPI";
import ProfileFormField from "./ProfileFormField";
import ToastContext from "../../util/toastContext";
import NoImage from "../../assets/no_image.png";

export default function VendorProfile({ vendor }) {
  const { data: vendorProfileData, isLoading, isSuccess } = useQuery(
    ["getVendorProfile", { userId: vendor.id }],
    usersAPI.getVendorProfile,
    {
      enabled: !!vendor.id,
      onError: (error) => {
        showAlert({
          message: error.message,
          severity: "error",
        });
      },
    }
  );

  const [updateVendorProfile, updateVendorProfileQuery] = useMutation(usersAPI.updateVendorProfile, {
    onSuccess: () => {
      showAlert({
        message: "Your profile was updated successfully",
        severity: "success",
      });
    },
    onError: (error) => {
      showAlert({
        message: error.message,
        severity: "error",
      });
    },
  });
  const [selectedFile, setSelectedFile ]= useState(null);
  const [uploadedImage, setUploadedImage ]= useState(vendorProfileData?.profilePicture?.url || null);
  const { profileImg, profileInfo, profileInfoContainer, profileImageContainer, classValue } = useStyles();

  const { showAlert } = useContext(ToastContext);



  useEffect(() => {
    if (isSuccess){
      setUploadedImage(vendorProfileData?.profilePicture?.url)
    }
   }, [isSuccess, vendorProfileData])
 

  const submitForm = async (form) => {
    form.append("profilePicture", selectedFile);
    try {
      updateVendorProfile({ userId: vendor.id, data: form });
    } catch (error) {
      showAlert({
        message: error.message,
        severity: "error",
      });
    }
  };

  const handleImageUpload= (file)=>{
    const reader = new FileReader();
    reader.addEventListener("load", function () {  
      // convert image file to base64 string
      setUploadedImage( reader.result);
    }, false);

    if (file) {
      reader.readAsDataURL(file);
    } 
      setSelectedFile(file)
    }

  return (
    <>
      <Box style={{ marginTop: 20 }}>
        <p>Class</p>
        <h4 className={classValue}>{vendorProfileData?.grade?.split(" ")[1]}</h4>
      </Box>

      <Box display="flex" className={profileInfoContainer}>
        <div className={profileImg}>
          {isLoading ? (
            <Skeleton height="100%" width="100%" variant="rect" col />
          ) : (
            <div className={profileImageContainer}>
            <img
              className = "profileImage" 
              loading="lazy"           
              src={uploadedImage|| NoImage}
              alt="User"
            />
                    <input
                type="file"             
                onChange={(e) =>handleImageUpload(e.target.files[0])}             
              />
            </div>
          )}
        </div>
        <Box display="flex" alignItems="center" flexDirection="column" className={profileInfo}>
          <div>
            <p>Contract Value and Threshold:</p>
            <h4>
              Above &#8358;{vendorProfileData?.contractMinValue?.toLocaleString()} - &#8358;
              {vendorProfileData?.contractMaxValue?.toLocaleString()}
            </h4>
          </div>
          <div>
            <p>Service Fee:</p>
            <h4>&#8358;{vendorProfileData?.fee?.toLocaleString()}</h4>
          </div>
          <div>
            <p>Date Joined:</p>
            <h4>{moment(vendorProfileData?.dateJoined).format("DD, MMMM, yyyy")}</h4>
          </div>
        </Box>
      </Box>

      <Box style={{ marginTop: 20 }}>
        <ProfileFormField user={vendor} onSubmitForm={submitForm} isLoading={updateVendorProfileQuery.isLoading} />
      </Box>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    width: 400,
    padding: theme.spacing(2, 3),
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
  },
  profileImg: {
    maxHeight: 120,
    height: "100%",
    width: "40%",
    backgroundColor: "#E4E9EC",
    border: "1px solid #EBEBEB",
    borderRadius: 3,
  },
  profileInfo: {
    fontSize: 16,
    color: "#424F5C",
    marginLeft: 20,
    "& div": {
      width: "100%",
      "&:not(:last-child)": {
        marginBottom: 10,
      },
    },
    "& h4": {
      fontWeight: 800,
    },
  },
  profileInfoContainer: {
    marginTop: 20,
    marginBottom: 20,
  },
  classValue: {
    display: "inline-block",
    padding: 5,
    background: "rgba(0, 176, 241, 0.0735632)",
    borderRadius: "3px",

    fontWeight: 600,
    fontSize: 36,
    lineHeight: "36px",

    color: "#00B0F1",
  },
  profileImageContainer : {
    position: "relative",
     width: "100%",
    "& .profileImage":{
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      minHeight: 120,
      background: "rgba(3, 173, 235, 0.07)",
      borderRadius: 3,
      zIndex: 1
    },
    '& input[type="file"] ':{
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      minHeight: 120,
      borderColor: "transparent",
      opacity: 0,
      zIndex: 2
    }
  }
}));
