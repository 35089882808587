import React from "react";
import { TableRow } from "@material-ui/core";
import { EmptyStyleCell } from "../style";

const TableEmptyContent = ({ noColSpan, textDisplayed, style, children }) => (
  <TableRow>
    <EmptyStyleCell style={style} align="center" colSpan={noColSpan || 2}>
      {!!textDisplayed && textDisplayed}
      {children}
    </EmptyStyleCell>
  </TableRow>
);

export default TableEmptyContent;
