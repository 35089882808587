import { Box, Grid } from "@material-ui/core";
import React, { useState } from "react";
import FileIcon from "../../../../../../assets/folder.svg";
import { DocumentFrame, FileText, FileTitle } from "./style";
import DocumentModal from "../../../../../Vendor/BidDetails/NewBidInfo/DocumentCategories/DocumentModal";

const DocumentList = ({ documents = [] }) => {
  const [selectedDocumentFolder, setSelectedDocumentFolder] = useState(null);
  return (
    <Grid container>
      {documents.map((eachDoc, index) => (
        <Grid key={index} item xs={3} style={{ cursor: "pointer" }} onClick={() => setSelectedDocumentFolder(eachDoc)}>
          <Box width="162px">
            <DocumentFrame>
              <img src={FileIcon} alt="file_icon" />
              <FileText>{eachDoc.count} Files</FileText>
            </DocumentFrame>
            <FileTitle>{eachDoc.title}</FileTitle>
          </Box>
        </Grid>
      ))}
      <DocumentModal selectedCategory={selectedDocumentFolder} onClose={() => setSelectedDocumentFolder(null)} />
    </Grid>
  );
};

export default DocumentList;
