import React from "react";
import { useSelector } from "react-redux";

import SecondaryTable from "./SecondaryTable";

const EvaluatorsTab = () => {
  const {
    adminContractDetails: { evaluators = [] },
  } = useSelector((state) => state.admin);

  const tableHeader = [
    { id: "s/n", title: "#", width: 50, align: "alignCenter", indexed: true },
    { id: "name", title: "Name", grid: 3 },
    { id: "role", title: "Role", grid: 3 },
    { id: "ministry", title: "Ministry", grid: 3 },
    { id: "type", title: "Type", grid: 2 },
  ];

  const tableBody = evaluators.map((evaluator) => ({
    name: evaluator.firstName + " " + evaluator.lastName,
    role: evaluator.role,
    ministry: evaluator.ministry || "N/A",
    dateOfEval: "N/A",
    type: evaluator.type,
  }));

  return <SecondaryTable tableBody={tableBody} tableHeader={tableHeader} />;
};

export default EvaluatorsTab;
