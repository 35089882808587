import React, { useContext } from "react";
import { TabActions, TabBackButton} from "./style";
import FormContext from "../../../../../../../util/context";
import ReviewCommentsSection from "../../../../../../../components/ReviewCommentsSection";

function ReviewTab() {
  const { planActivityId, previousTab } = useContext(FormContext);

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <ReviewCommentsSection objectId={planActivityId} />
      <TabActions>
        <TabBackButton onClick={previousTab}>Back</TabBackButton>     
      </TabActions>
    </form>
  );
}

export default ReviewTab;
