import styled from "styled-components";
import { styled as materialStyled } from "@material-ui/styles";
import TableHead from "@material-ui/core/TableHead";
import Button from "@material-ui/core/Button";

export const TableTop = styled.div`
  height: 60px;
  padding: 0 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;  
  background: #FFFFFF;
  border: 1px solid #C9D4DB;
  box-shadow: 0 2px 4px #C9D4DB;
  border-radius: 3px 3px 0 0;
  box-sizing: border-box;
  font-size: 14px;
  color: #5F7483;
`

export const ButtonsRow = styled.div`
  display: inline-flex;
  align-items: center;
`

export const ActionsRow = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 42px);
  grid-column-gap: 8px;
  margin-left: auto;
  width: fit-content ;
`

export const ModdedTableHead = materialStyled(TableHead)({
  background: '#E4E9EC',
  border: '1px solid #C9D4DB'
})

export const ApproveButton = materialStyled(Button)({
  background: '#219653',
  color: 'white'
})

export const RejectButton = materialStyled(Button)({
  background: '#EB5757',
  color: 'white'
})

export const TableCellEmail = styled.p`
  color: #5F7483;
`

export const ModdedAvatar = styled.div`
  background-color: ${props => props.color};
  width: ${props => `${props.size}px`};
  height: ${props => `${props.size}px`};
  border-radius: 50%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
`

export const DocumentStatusIcon = styled(ModdedAvatar)`
  margin-left: 12px;
`

export const TableWrapper = styled.div`
  background-color: white;
  border: 1px solid #E4E9EC;
`

export const EmptyState = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 85px 0 130px;
`

export const EmptyStateHeader = styled.h1`
  font-size: 17px;
  color: #1E2B37;
  margin-bottom: 16px;
`

export const EmptyStateDescription = styled.p`
  font-size: 17px;
  line-height: 24px;
  color: #1E2B37;
  width: 450px;
  text-align: center;
`
