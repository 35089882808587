import { Container, Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Footer, Navbar } from "../../../components";
import { ErrorContainer } from "../../../components/Forms/Common/style";
import InputField from "../../../components/InputField";
import {
  AuthText,
  ContentWrapper,
  LoginButton,
  TermsAndPrivacy,
  Wrapper,
} from "../../Common/style";
import {
  CheckJunk, CheckLinkWrapper,
} from "../../Common/PasswordResetComponent/style";
import usersAPI from "../../../redux/api/usersAPI";
import useAlert from "../../../hooks/useAlert";

const ProfileSetup = () => {
  const { showAlert, Toast } = useAlert();
  const { register, handleSubmit, errors } = useForm();
  const history = useHistory();
  const location = useLocation();
  const token = new URLSearchParams(location.search).get("token");
  const email = new URLSearchParams(location.search).get("email");
  const [setupProfile, postResponse] = useMutation(usersAPI.setupStaffProfile);

  const onSubmit = (data) => {
    setupProfile({ ...data, token, email })
  }

  useEffect(() => {
    if (postResponse.isSuccess) {
      showAlert({ message: "Profile Setup", severity: "success" })
      return history.push("/login")
    }

    if (postResponse.isError) {
      return showAlert({ message: postResponse.error , severity: "error" })
    }
  }, [postResponse, showAlert, history])

  const onRenderError = (error) => (
    <ErrorContainer> {error && error.message} </ErrorContainer>
  );
  const width = "393px";
  const marginTop = "30px";
  const margin = "0 0 8px 0";
  const textDecoration = "none";
  const color = "#03adeb";

  return (
    <div>
      <Navbar />
      <Container>
        <Toast float />
        <ContentWrapper>
          <Wrapper>
            <AuthText>Profile Setup</AuthText>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid item xs={12}>
                <CheckLinkWrapper style={{ marginTop }}>
                  <CheckJunk>
                    You are joining this workspace with{" "}
                    <b>{email}</b>
                  </CheckJunk>
                </CheckLinkWrapper>
              </Grid>

              <Grid item xs={12}>
                <InputField
                  type="text"
                  label="First Name"
                  name="firstName"
                  style={{ width, margin }}
                  inputRef={register({
                    required: "Please supply your first name",
                    validate: (value) => (value.length > 2 && value.length < 256)
                      || "Please supply your first name",
                  })}
                />
              </Grid>
              {onRenderError(errors.firstName)}

              <Grid item xs={12}>
                <InputField
                  type="text"
                  label="Last Name"
                  name="lastName"
                  style={{ width, margin }}
                  inputRef={register({
                    required: "Please supply your last name",
                    validate: (value) => (value.length > 2 && value.length < 256)
                      || "Please supply your last name",
                  })}
                />
              </Grid>
              {onRenderError(errors.lastName)}

              <Grid item xs={12}>
                <InputField
                  type="text"
                  label="Phone Number"
                  name="phone"
                  style={{ width, margin }}
                  inputRef={register({
                    required: "Please supply your phone number",
                    pattern: {
                      value: /^[0-9]{7,16}$/,
                      message:
                        "Phone number should be between 7 and 16 digits long",
                    },
                  })}
                />
              </Grid>
              {onRenderError(errors.phone)}

              <Grid item xs={12}>
                <InputField
                  type="password"
                  label="Password"
                  name="password"
                  style={{ width, margin }}
                  inputRef={register({
                    required:
                      "Password must contain at least (1) lower case, (1) capital letter, (1) numeric number and a minimum of 8 characters",
                    pattern: {
                      value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                      message:
                        "Password must contain at least (1) lower case, (1) capital letter, (1) numeric number and a minimum of 8 characters",
                    },
                  })}
                />
              </Grid>
              {onRenderError(errors.password)}

              <Grid item xs={12}>
                <LoginButton
                  type="submit"
                  style={{ width }}
                  disabled={postResponse.isLoading}
                >
                  {!postResponse.isLoading ? "Save and Continue" : "Please Wait...."}
                </LoginButton>
              </Grid>
            </form>
            <TermsAndPrivacy>
              Logging in indicates that you've read and agree to our{" "}
              <Link to="#s" style={{ textDecoration, color }}>
                Terms
              </Link>{" "}
              and have read our{" "}
              <Link to="#s" style={{ textDecoration, color }}>
                Privacy Policy
              </Link>
            </TermsAndPrivacy>
          </Wrapper>
        </ContentWrapper>
      </Container>
      <Footer />
    </div>
  );
};

export default ProfileSetup;
