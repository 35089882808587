export const handleAxiosError = (error) => {
  if (process.env.NODE_ENV !== "production") {
    // eslint-disable-next-line
    console.warn("[Axios Error]", error, error.response);
  }
  if (!error.response) return "Something went wrong";
  const { response } = error;
  const { data } = response;

  switch (response.status) {
    case 400:
      return data.message || "Something went wrong";
    case 404:
      return data.message || "Resource not found";
    case 409:
      return "This account already eists";
    default:
      return "Something went wrong";
  }
};

export const handleValidationError = (error) => {
  if (process.env.NODE_ENV !== "production") {
    // eslint-disable-next-line
    console.warn("[Axios Error]", error, error);
  }
  console.log(error)
  if (!error.response) return "Something went wrong";
  const { response } = error;
  const { data } = response;
 
  switch (response.status) {
    case 400:
      console.log(data.errors)
      return JSON.stringify(data.errors, null, 2) || "Something went wrong";
    case 401:
      return data.message || "You are unauthorized to perform this request";
    case 404:
      return data.message || "Resource not found";
    case 409:
      return "This account already eists";
    default:
      return "Something went wrong";
  }
};

const handleApiError = (response, statusCode) => {
  switch (statusCode) {
    case 400:
      return response.message;
    case 404:
      return response.message;
    case 409:
      return "This account already exists";
    default:
      return "Something went wrong";
  }
};

export const handleApiErrorModified = (response, statusCode) => {
  switch (statusCode) {
    case 400:
      return {
        message: response.message,
        errors: response.errors,
      };
    case 404:
      return {
        message: response.message,
        errors: response.errors,
        statusCode,
      };
    case 409:
      return {
        message: response.message,
        errors: response.errors,
      };
    default:
      return "Something went wrong";
  }
};

export default handleApiError;
