import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { ReactComponent as CheckIcon } from "../../assets/checkIcon.svg";
import {
  FilterCheckbox, FilterInput, FilterRows, FilterTitle, FilterWrapper, ToggleAllButtons, ToggleAllDivider,
} from "./style";

const Filter = ({
  filterOptions, onToggleFilter, onSelectAll, onUnselectAll,
}) => (
    <FilterWrapper>
      <FilterTitle>
        Filter
      </FilterTitle>
      <FilterRows>
        {
          filterOptions.map(({ id, title, checked = false }) => (
            <FilterInput
              onClick={() => onToggleFilter(id)}
            >
              <FilterCheckbox
                checked={checked}
              >
                {
                  checked && (
                    <CheckIcon
                      fill={"#5C6AC4"}
                      width={10}
                    />
                  )
                }
              </FilterCheckbox>
              <span>
                {title}
              </span>
            </FilterInput>
          ))
        }
      </FilterRows>
      <Grid
        container
        alignContent="center"
      >
        <ToggleAllButtons onClick={onSelectAll}>
          Select all
        </ToggleAllButtons>
        <ToggleAllDivider/>
        <ToggleAllButtons onClick={onUnselectAll}>
          Unselect all
        </ToggleAllButtons>
      </Grid>
    </FilterWrapper>
);

Filter.propTypes = {
  filterOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      checked: PropTypes.bool.isRequired,
    }),
  ),
  onToggleFilter: PropTypes.func.isRequired,
  onSelectAll: PropTypes.func.isRequired,
  onUnselectAll: PropTypes.func.isRequired,
};

export default Filter;
