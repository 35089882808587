import React from "react";
import { Wrapper, Content, Overlay, Title, Subtitle, EyeNotAllowedIcon } from "./style";
import DocumentList from "../DocumentsList";
import { fakeDocuments } from "./mock";
import { formatCurrency } from "../../../../../util/formatCurrency";

export default function PaymentRequiredNotice({ price = 25000 }) {
  return (
    <Wrapper>
      <Content>
        <DocumentList noOffset documents={fakeDocuments} />
      </Content>
      <Overlay>
        <EyeNotAllowedIcon />
        <Title>Payment Required!</Title>
        <Subtitle>
          To access the bidding document, you must pay a fee of <strong>{formatCurrency(price, true)}</strong>.
        </Subtitle>
        <Subtitle>
          Kindly make your payment using the section above. After a successful payment the bid documents will be accessible.
        </Subtitle>
      </Overlay>
    </Wrapper>
  );
}
