import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import AdminSearchFilter from "../../../../components/AdminSearchFilter";
import RolePageDrawer from "../../../../components/RolePageDrawer";
import InputField from "../../../../components/InputField";
import { PageTitle, TitleContainer } from "../../../Common/style";
import withAdminDashboard from "../../HOC/withAdminDashboard";
import { ContentWrapper } from "../../Procurement/style";
import Dropdown from "../../../../components/DropdownField";
import Breadcrumb from "../../../Common/PageElements/Breadcrumb";
import { SearchButton } from "../../../../components/Forms/Common/style";
import { tableRows, tableColumns, listData } from "./mock";
import SimpleCard from "../../../../components/SimpleCard";
import EnhancedTable from "./EnhancedTable";

const Users = () => {
  const [openModal, setOpenModal] = useState(false);

  const toggleDrawer = () => {
    setOpenModal(!openModal);
  };

  const breadCrumbValues = [
    { title: "Home", url: "#", forwardSlash: true },
    { title: "Settings", url: "#", forwardSlash: true },
    { title: "Users", url: "#" },
  ];

  const searchPermissionList = [{ label: "Ministry of Agriculture", value: 1 }];

  return (
    <ContentWrapper>
      <TitleContainer>
        <div>
          <PageTitle>Users</PageTitle>
          <Breadcrumb values={breadCrumbValues} />
        </div>
      </TitleContainer>

      <Grid container spacing={2}>
        <Grid item xs={12} lg={8} md={8}>
          <SimpleCard
            title={"Procurement Officers"}
            statuses={[]}
            description={`Prunedge’s Style Guide can be easily included in applications 
            or one-off projects. We recommend pulling the latest version of Style Guide's 
            CSS from our CDN. You may also clone this repository and pull in the component SCSS files 
            if you wish to only include a portion of Style Guide in your project.
            `}
            descriptionTitle={"Description"}
            showIcon={true}
          />

          <AdminSearchFilter normalSearch>
            <div
              style={{
                display: "flex",
              }}
            >
              <InputField type="text" label="Search name" defaultValue="Completion of settlement scheme" />
              <Dropdown name="ministry" label="Ministry" values={searchPermissionList} />
              <SearchButton>Search</SearchButton>
            </div>
          </AdminSearchFilter>
          <br />
          <EnhancedTable rows={tableRows} columns={tableColumns} headerText={"Showing 10 results"} />
        </Grid>
        <Grid item xs={12} lg={4} md={4}>
          <SimpleCard
            title={"Permissions"}
            listData={listData}
          />
        </Grid>
      </Grid>
      <RolePageDrawer toggleDrawer={toggleDrawer} openModal={openModal} />
    </ContentWrapper>
  );
};

export default withAdminDashboard(Users);
