import Grid from "@material-ui/core/Grid";
import React from "react";
import { useHistory } from "react-router-dom";
import CheckerCard from "../CheckerCard";

const CheckersCardSection = ({ checkersCardInfo }) => {
  const history = useHistory();

  return (
    <>
      <Grid container>
        {checkersCardInfo.map((card) => (
          <Grid
            item
            xs={12}
            md={6}
            lg={3}
            key={card.id}
            onClick={() => {
              history.push("/admin/settings/checkers/single");
            }}
          >
            <CheckerCard title={card.title} name={card.name} description={card.description} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default CheckersCardSection;
