/**
 * This hook makes a request to the backend to get the vendor's registration status, if the details isn't already in the cache.
 *  A role, user and isSucess can be destructed from this hook.
 **/
import { useQuery } from "react-query";
import { useSelector } from "react-redux";

import vendorsAPI from "../redux/api/vendorsAPI";

const useVendorDetailsRegistrationStatus = () => {
  const { user } = useSelector((state) => state.user);
  const vendorDetailsQuery = useQuery({
    queryKey: ["vendorDetailsRegistrationStatus", user.UserId],
    queryFn: vendorsAPI.getVendor,
    config: {
      cacheTime: 3600 * 100,
    },
  });

  return {
    isRegistrationComplete: vendorDetailsQuery?.data?.isRegistrationComplete,
    isSuccess: vendorDetailsQuery.isSuccess,
    isError: vendorDetailsQuery.isError,
  };
};

export default useVendorDetailsRegistrationStatus;
