import React, { useContext } from "react";
import { queryCache, useMutation } from "react-query";
import { Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import format from "date-fns/format";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";

import { CancelButton, MilestoneCloseIcon, MilestoneTitleText, ModalContent } from "./style";
import { Dropdown, InputField } from "../../../../components";
import { ErrorContainer, SearchButton } from "../../../../components/Forms/Common/style";
import vendorsAPI from "../../../../redux/api/vendorsAPI";
import ToastContext from "../../../../util/toastContext";
import Dialog from "@material-ui/core/Dialog";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const MilestoneModal = ({ open = false, handleClose }) => {
  const classes = useStyles();
  const { showAlert } = useContext(ToastContext);
  const { projectId } = useParams();
  const { register, errors, handleSubmit } = useForm();
  const queryKey = ["projectMileStones", projectId];
  const [createMileStone, {
    error, status, isLoading, reset,
  }] = useMutation(vendorsAPI.createProjectMilestone, {
    onMutate: () => queryCache.cancelQueries(queryKey),
    onSuccess: (data) => {
      showAlert({
        severity: "success",
        message: "Milestone created successfully",
      });
      queryCache.setQueryData(queryKey, (prevMilestones) => prevMilestones.concat(data));
      reset();
      handleClose();
    },
  });

  const handleCreateMilestone = (data) => {
    createMileStone({
      projectId,
      data,
    });
  };

  const renderError = (e) => (
    <ErrorContainer>{e && e.message}</ErrorContainer>
  );

  return (
    <Dialog
      open={open}
      className={classes.modal}
      onClose={handleClose}
      onEscapeKeyDown={handleClose}
    >
      <ModalContent>
        <Box display="flex" justifyContent="space-between">
          <MilestoneTitleText>Create Milestone</MilestoneTitleText>
          <MilestoneCloseIcon onClick={handleClose}/>
        </Box>
        {status === "error" && <center style={{ color: "red" }}>{error.message}</center>}
        <form onSubmit={handleSubmit(handleCreateMilestone)}>
          <InputField
            label="Milestone title"
            name="title"
            style={{ width: "100%", margin: 0, marginTop: "24px" }}
            required
            inputRef={register({
              required: "Please supply title",
              validate: (value) => value.length > 1 || "Please supply title",
            })}
          />
          {renderError(errors.title)}

          <Grid container spacing={4}>
            <Grid item xs={6}>
              <InputField
                label="Start date"
                name="startDate"
                defaultValue={format(new Date(), "yyyy-MM-dd")}
                type="date"
                style={{ width: "100%", margin: 0, marginTop: "24px" }}
                required
                inputRef={register({
                  required: "Please a start date",
                })}
              />
              {renderError(errors.startDate)}
            </Grid>
            <Grid item xs={6}>
              <InputField
                label="End date"
                type="date"
                name="endDate"
                defaultValue={format(new Date(), "yyyy-MM-dd")}
                style={{ width: "100%", margin: 0, marginTop: "24px" }}
                inputRef={register({
                  required: "Please select an end date",
                })}
              />
              {renderError(errors.endDate)}
            </Grid>
            <Grid item xs={6}>
              <Dropdown
                values={[{ label: "Naira", value: "naira" }]}
                label="Evaluation Currency"
                style={{ width: "100%", margin: 0 }}
                name="currency"
                required
                inputRef={register({
                  required: "Please select a currency",
                })}
              />
            </Grid>
            <Grid item xs={6}>
              <InputField
                label="Value"
                name="estimatedValue"
                style={{ width: "100%", margin: 0 }}
                required
                inputRef={register({
                  required: "Please select an amount",
                })}
              />
              {renderError(errors.amount)}
            </Grid>
          </Grid>
          <InputField
            label="Milestone Description"
            multiline={true}
            style={{ width: "100%", margin: 0, marginTop: "20px" }}
            required
            name="description"
            inputRef={register({
              required: "Please select a description",
            })}
          />
          {renderError(errors.description)}
          <Box display="flex" justifyContent="flex-end" alignItems="center" marginTop="30px">
            <CancelButton
              variant='outlined'
              onClick={handleClose}
            >
              Cancel
            </CancelButton>
            <SearchButton noBottom type="submit" loading={isLoading}>
              Create Milestone
            </SearchButton>
          </Box>
        </form>
      </ModalContent>
    </Dialog>
  );
};

export default React.memo(MilestoneModal);
