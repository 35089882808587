import { Box, Grid } from "@material-ui/core";
import React, { useContext, useState } from "react";
import { ContentWrapper, TitleContainer } from "../../../Admin/Administration/Staff/style";
import { MainTitle } from "../../../Admin/Contracts/SingleContractDetails/style";
import Breadcrumb from "../../../Common/PageElements/Breadcrumb";
import { PageTitle } from "../../../Common/style";
import { BiddingDocumentContainer } from "../../BidDetails/BidInfo/style";
import ContractInfo from "../ContractInfo";
import { SecondWrapper } from "../ContractInfo/style";
import { MainWrapper } from "../LetterOfAcceptance/style";
import { UploadButton } from "./style";
import withVendorDashboard from "../../HOC/withVendorDashboard";
import { useQuery, useMutation } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import vendorsAPI from "../../../../redux/api/vendorsAPI";
import AcceptanceDocSection from "../AcceptanceDocSection";
import { MainText, InterestExpressedPane, CuteGrid, Pad } from "../../Procurement/style";
import { ReactComponent as DisabledCheckIcon } from "../../../../assets/disabled_check_mark.svg";
import moment from "moment";
import FileDownload from "../../../../components/FileDownload";
import FileUploadField from "../../../../components/FileUploadField";
import ToastContext from "../../../../util/toastContext";
import { sortDate } from "../../../../util/sortDate";

const ContractSigning = () => {
  const { showAlert } = useContext(ToastContext);
  const { contractId } = useParams();
  const [mandatoryFileName, setMandatoryFileName] = useState([]);
  const contractInfo = useQuery(["contractSigning", contractId], vendorsAPI.getVendorContractById, { enabled: !!contractId });

  const procurementId = contractInfo?.data?.procurementPlan?.id;
  const history = useHistory();

  const [uploadSignedDocument, uploadedSignedDocResp] = useMutation(vendorsAPI.uploadSignedDocument, {
    onSuccess: () => {
      showAlert({
        severity: "Success",
        durationInMs: 3000,
        message: "Document uploaded successfully",
      });
      history.push("/vendor/contracts");
    },
    onError: () => {
      showAlert({
        severity: "Error",
        durationInMs: 3000,
        message: "Upload failed please try again",
      });
    },
  });

  const { data: letter = [] } = useQuery(["getIntentLetter", procurementId], vendorsAPI.getVendorLetterOfIntent, {
    enabled: !!procurementId,
  });
  const datePublished = moment(letter[0]?.createdAt).format("DD/MM/YYYY");

  const {
    data: unsignedContractDraft = {},
    isLoading: getUnsignedContractLoading,
    isSuccess: getUnsignedContractSuccess,
  } = useQuery(["getUnsignedContractDraft", procurementId], vendorsAPI.getUnsignedContractDraft, { enabled: !!procurementId });

  const ministryOfJustice = unsignedContractDraft.ministryOfJustice || [];
  const procurmentOfficer = unsignedContractDraft.procurmentOfficer || [];

  const disableStatus =
    uploadedSignedDocResp?.isLoading ||
    mandatoryFileName?.length === 0 ||
    uploadedSignedDocResp?.data ||
    ministryOfJustice.length === 0 ||
    procurmentOfficer.length === 0;

  const BreadcrumbLinks = [
    { url: "/vendor/contracts", title: "Contract", forwardSlash: true },
    { url: "/vendor/contracts", title: " Details", forwardSlash: true },
    { url: "/vendor/contracts", title: " Contract Signing", forwardSlash: false },
  ];

  const handleSignedDocUploaded = () => {
    const formData = new FormData();
    mandatoryFileName.map((eachFile) => formData.append("File", eachFile));
    uploadSignedDocument({ formData, procurementId });
  };

  const deadlines = [...ministryOfJustice, ...procurmentOfficer].map((file) => file.deadline);

  return (
    <ContentWrapper>
      <TitleContainer>
        <div>
          <PageTitle>{contractInfo?.data?.procurementPlan?.packageNumber}</PageTitle>
          <Breadcrumb values={BreadcrumbLinks} />
        </div>
      </TitleContainer>
      <InterestExpressedPane state="pending">
        <CuteGrid>
          <div>
            <MainText color="#212B36" fw="bold" fontSize="14px">
              Upload signed contract document
            </MainText>
            <MainText color="#212B36" mt="10px" fontSize="14px">
              Please download the draft contract document, sign and upload
            </MainText>
          </div>
          <div>
            <DisabledCheckIcon />
          </div>
        </CuteGrid>
      </InterestExpressedPane>

      <MainWrapper mt="20px">
        <ContractInfo id={procurementId} publishDate={datePublished} deadline={sortDate(deadlines)} />
        <MainTitle mt="40px">Documents</MainTitle>
        <MainText mt="15px" fontSize="14px">
          List of all documents exchanged
        </MainText>
        <AcceptanceDocSection procurementId={contractInfo?.data?.procurementPlan?.id} />
      </MainWrapper>

      <MainWrapper mt="20px">
        <MainTitle mt="10px" mb="20px">
          Awaiting contract Document
        </MainTitle>
        <BiddingDocumentContainer>
          <Grid container spacing={2}>
            {ministryOfJustice.length > 0 &&
              procurmentOfficer.length > 0 &&
              [...ministryOfJustice, ...procurmentOfficer].map((contractDraft) => {
                const file = contractDraft.file;
                return (
                  <Grid item style={{ minWidth: "25%" }}>
                    <FileDownload url={file?.url} lighttheme name={file?.original_filename} size={file?.bytes} />
                  </Grid>
                );
              })}
          </Grid>
          {!getUnsignedContractLoading &&
            !!getUnsignedContractSuccess &&
            (ministryOfJustice.length === 0 || procurmentOfficer.length === 0) && (
              <Pad>
                <MainTitle style={{ fontWeight: "normal", textAlign: "center" }} mt="10px">
                  No contract document yet
                </MainTitle>
              </Pad>
            )}
        </BiddingDocumentContainer>

        <SecondWrapper style={{ marginTop: "20px" }}>
          <Box>
            <MainTitle mb="10px">Upload Signed Documents</MainTitle>
            <FileUploadField filesNames={mandatoryFileName} setFilesNames={setMandatoryFileName} multiple={true} />
          </Box>
        </SecondWrapper>
        <Box>
          <UploadButton onClick={handleSignedDocUploaded} disabled={disableStatus} style={{ marginLeft: "15px" }}>
            {uploadedSignedDocResp?.isLoading ? "Please wait..." : "Upload Signed Contract"}
          </UploadButton>
        </Box>
      </MainWrapper>
    </ContentWrapper>
  );
};

export default withVendorDashboard(ContractSigning);
