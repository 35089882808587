export const awardedContracts = [
  {
    service: "Goods",
    status: "Awarded Contract",
    description: "The development of the oke-osa local government area link road and 10 overhead bridge",
    openOrAwardedDate: "22 Feb,2020",
    closeOrExpiryDate: "12 Dec, 2020",
    category: "category A",
    ministry: "Ministry of Agric",
    vendor: "Ayoaba construction",
    contractAmount: "N25,000,000.00",
  },
  {
    service: "Goods",
    status: "Awarded Contract",
    description: "The development of the oke-osa local government area link road and 10 overhead bridge",
    openOrAwardedDate: "22 Feb,2020",
    closeOrExpiryDate: "12 Dec, 2020",
    category: "category A",
    ministry: "Ministry of Agric",
    vendor: "Ayoaba construction",
    contractAmount: "N25,000,000.00",
  },
  {
    service: "Goods",
    status: "Awarded Contract",
    description: "The development of the oke-osa local government area link road and 10 overhead bridge",
    openOrAwardedDate: "22 Feb,2020",
    closeOrExpiryDate: "12 Dec, 2020",
    category: "category A",
    ministry: "Ministry of Agric",
    vendor: "Ayoaba construction",
    contractAmount: "N25,000,000.00",
  },
  {
    service: "Goods",
    status: "Awarded Contract",
    description: "The development of the oke-osa local government area link road and 10 overhead bridge",
    openOrAwardedDate: "22 Feb,2020",
    closeOrExpiryDate: "12 Dec, 2020",
    category: "category A",
    ministry: "Ministry of Agric",
    vendor: "Ayoaba construction",
    contractAmount: "N25,000,000.00",
  },
  {
    service: "Goods",
    status: "Awarded Contract",
    description: "The development of the oke-osa local government area link road and 10 overhead bridge",
    openOrAwardedDate: "22 Feb,2020",
    closeOrExpiryDate: "12 Dec, 2020",
    category: "category A",
    ministry: "Ministry of Agric",
    vendor: "Ayoaba construction",
    contractAmount: "N25,000,000.00",
  },
  {
    service: "Goods",
    status: "Awarded Contract",
    description: "The development of the oke-osa local government area link road and 10 overhead bridge",
    openOrAwardedDate: "22 Feb,2020",
    closeOrExpiryDate: "12 Dec, 2020",
    category: "category A",
    ministry: "Ministry of Agric",
    vendor: "Ayoaba construction",
    contractAmount: "N25,000,000.00",
  },
  {
    service: "Goods",
    status: "Awarded Contract",
    description: "The development of the oke-osa local government area link road and 10 overhead bridge",
    openOrAwardedDate: "22 Feb,2020",
    closeOrExpiryDate: "12 Dec, 2020",
    category: "category A",
    ministry: "Ministry of Agric",
    vendor: "Ayoaba construction",
    contractAmount: "N25,000,000.00",
  },
  {
    service: "Goods",
    status: "Awarded Contract",
    description: "The development of the oke-osa local government area link road and 10 overhead bridge",
    openOrAwardedDate: "22 Feb,2020",
    closeOrExpiryDate: "12 Dec, 2020",
    category: "category A",
    ministry: "Ministry of Agric",
    vendor: "Ayoaba construction",
    contractAmount: "N25,000,000.00",
  },
  {
    service: "Goods",
    status: "Awarded Contract",
    description: "The development of the oke-osa local government area link road and 10 overhead bridge",
    openOrAwardedDate: "22 Feb,2020",
    closeOrExpiryDate: "12 Dec, 2020",
    category: "category A",
    ministry: "Ministry of Agric",
    vendor: "Ayoaba construction",
    contractAmount: "N25,000,000.00",
  },
  {
    service: "Goods",
    status: "Awarded Contract",
    description: "The development of the oke-osa local government area link road and 10 overhead bridge",
    openOrAwardedDate: "22 Feb,2020",
    closeOrExpiryDate: "12 Dec, 2020",
    category: "category A",
    ministry: "Ministry of Agric",
    vendor: "Ayoaba construction",
    contractAmount: "N25,000,000.00",
  },
  {
    service: "Goods",
    status: "Awarded Contract",
    description: "The development of the oke-osa local government area link road and 10 overhead bridge",
    openOrAwardedDate: "22 Feb,2020",
    closeOrExpiryDate: "12 Dec, 2020",
    category: "category A",
    ministry: "Ministry of Agric",
    vendor: "Ayoaba construction",
    contractAmount: "N25,000,000.00",
  },
  {
    service: "Goods",
    status: "Awarded Contract",
    description: "The development of the oke-osa local government area link road and 10 overhead bridge",
    openOrAwardedDate: "22 Feb,2020",
    closeOrExpiryDate: "12 Dec, 2020",
    category: "category A",
    ministry: "Ministry of Agric",
    vendor: "Ayoaba construction",
    contractAmount: "N25,000,000.00",
  },
  {
    service: "Goods",
    status: "Awarded Contract",
    description: "The development of the oke-osa local government area link road and 10 overhead bridge",
    openOrAwardedDate: "22 Feb,2020",
    closeOrExpiryDate: "12 Dec, 2020",
    category: "category A",
    ministry: "Ministry of Agric",
    vendor: "Ayoaba construction",
    contractAmount: "N25,000,000.00",
  },
  {
    service: "Goods",
    status: "Awarded Contract",
    description: "The development of the oke-osa local government area link road and 10 overhead bridge",
    openOrAwardedDate: "22 Feb,2020",
    closeOrExpiryDate: "12 Dec, 2020",
    category: "category A",
    ministry: "Ministry of Agric",
    vendor: "Ayoaba construction",
    contractAmount: "N25,000,000.00",
  },
  {
    service: "Goods",
    status: "Awarded Contract",
    description: "The development of the oke-osa local government area link road and 10 overhead bridge",
    openOrAwardedDate: "22 Feb,2020",
    closeOrExpiryDate: "12 Dec, 2020",
    category: "category A",
    ministry: "Ministry of Agric",
    vendor: "Ayoaba construction",
    contractAmount: "N25,000,000.00",
  },
  {
    service: "Goods",
    status: "Awarded Contract",
    description: "The development of the oke-osa local government area link road and 10 overhead bridge",
    openOrAwardedDate: "22 Feb,2020",
    closeOrExpiryDate: "12 Dec, 2020",
    category: "category A",
    ministry: "Ministry of Agric",
    vendor: "Ayoaba construction",
    contractAmount: "N25,000,000.00",
  },
  {
    service: "Goods",
    status: "Awarded Contract",
    description: "The development of the oke-osa local government area link road and 10 overhead bridge",
    openOrAwardedDate: "22 Feb,2020",
    closeOrExpiryDate: "12 Dec, 2020",
    category: "category A",
    ministry: "Ministry of Agric",
    vendor: "Ayoaba construction",
    contractAmount: "N25,000,000.00",
  },
  {
    service: "Goods",
    status: "Awarded Contract",
    description: "The development of the oke-osa local government area link road and 10 overhead bridge",
    openOrAwardedDate: "22 Feb,2020",
    closeOrExpiryDate: "12 Dec, 2020",
    category: "category A",
    ministry: "Ministry of Agric",
    vendor: "Ayoaba construction",
    contractAmount: "N25,000,000.00",
  },
];
