import React, { useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import { useForm } from "react-hook-form";
import format from "date-fns/format";
import InputField from "../../InputField";
import { Wrapper } from "../Common/style";
import { ErrorContainer } from "./style";
import NavigationButtons from "../../NavigationButtons";
import { postAttestation, getAttestation } from "../../../redux/reducer/vendorReducer";
import withVendorRegLoader from "../../../pages/Common/HOC/withVendorRegLoader";
import ToastContext from "../../../util/toastContext";

const AttestationPage = () => {
  const { user: { UserId } } = useSelector((state) => state.user);
  const { attestation, loading } = useSelector((state) => state.vendor);
  const { showAlert } = useContext(ToastContext);

  const {
    register, handleSubmit, errors, reset,
  } = useForm();
  const dispatch = useDispatch();

  const width = "100%";
  const margin = "15px 0 0 0";

  useEffect(() => {
    reset(attestation[0]);
  }, [dispatch, attestation, reset]);

  const onSubmit = (cb) => (data) => {
    if (data === attestation[0]) return cb();
    data.userId = UserId;

    dispatch(postAttestation(data))
      .then((res) => {
        if (res.error) {
          return showAlert({ severity: "error", message: res.payload });
        }
        showAlert({ severity: "success", message: "Attestation saved successfully" });
        cb();
      });
  };

  const onRenderError = (error) => <ErrorContainer> {error && error.message} </ErrorContainer>;
  return (
    <Wrapper>
      {withVendorRegLoader(() => (
        <form>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <InputField
                type="text"
                label="First Name"
                defaultValue={attestation.firstName}
                name="firstName"
                style={{ width, margin }}
                inputRef={register({
                  required: "Please supply your first name",
                  validate: (value) => (value.length > 2 && value.length < 256)
                    || "Please supply your first name",
                })}
              />
              {onRenderError(errors.firstName)}
            </Grid>
            <Grid item xs={6}>
              <InputField
                type="text"
                label="Last Name"
                defaultValue={attestation.lastName}
                name="lastName"
                style={{ width, margin }}
                inputRef={register({
                  required: "Please supply your last name",
                  validate: (value) => (value.length > 2 && value.length < 256)
                    || "Please supply your last name",
                })}
              />
              {onRenderError(errors.lastName)}
            </Grid>
          </Grid>
          <InputField
            type="date"
            label="Registration Date"
            name="attestedAt"
            disabled={true}
            style={{ width, margin }}
            value={format(new Date(), "yyyy-MM-dd")}
            inputRef={register({
              required: "Please supply a valid date",
              validate: (value) => value === format(new Date(), "yyyy-MM-dd") || "Please supply a valid date",
            })}
          />
          {onRenderError(errors.attestedAt)}
          <NavigationButtons
            value={loading === "pending" ? "Please wait..." : "Save And Next"}
            nextActionHandler={(cb) => handleSubmit(onSubmit(cb))} />
        </form>
      ))({
        title: "Attestation",
        description: "I certify that all the information provided above is true and accurate",
        apis: [getAttestation({ userId: UserId })],
        userId: UserId,
        conditioners: ["attestation"],
      })}
    </Wrapper>
  );
};

export default AttestationPage;
