import baseAxiosMethod from "./baseAxiosMethod";
import handleApiError, { handleAxiosError, handleApiErrorModified } from "./handleApiError";
import { sectionedDocs } from "../../util/sectionedDocs";

const addGeneralPlanApi = {
  getMinistries: async () => {
    //TODO: Ask for get all ministries endpoint
    try {
      const response = await baseAxiosMethod("ministries?PageSize=20");
      return response.data.data;
    } catch (e) {
      throw handleApiError(e.response?.data, e.response?.statusCode);
    }
  },

  postPlan: async (values) => {
    try {
      const response = await baseAxiosMethod.post("generalPlans", values);
      return response.data.data;
    } catch (e) {
      throw handleApiErrorModified(e.response?.data, e.response.status);
    }
  },

  getPlan: async (key, planId) => {
    try {
      const response = await baseAxiosMethod(`generalPlans/${planId}`);
      return response.data.data;
    } catch (e) {
      throw handleApiError(e.response?.data, e.response?.statusCode);
    }
  },

  editPlan: async (values) => {
    const { id } = values;

    try {
      await baseAxiosMethod.put(`generalPlans/${id}`, values);
    } catch (e) {
      throw handleApiError(e.response?.data, e.response?.statusCode);
    }
  },

  needsAmendment: async (values) => {
    const { id } = values;

    try {
      await baseAxiosMethod.post(`generalPlans/${id}/needAmendment`, values);
    } catch (e) {
      throw handleApiError(e.response?.data, e.response?.statusCode);
    }
  },

  approvePlan: async (values) => {
    const { id } = values;

    try {
      await baseAxiosMethod.post(`generalPlans/${id}/approve`);
    } catch (e) {
      throw handleApiError(e.response?.data, e.response?.statusCode);
    }
  },

  getPlanDocuments: async (key, planId) => {
    try {
      const response = await baseAxiosMethod(`generalPlans/${planId}/document`);
      return response.data.data;
    } catch (e) {
      throw new Error(handleAxiosError(e));
    }
  },

  savePlanDocuments: async ({ mandatory, supporting, removed, planId }) => {
    try {
      const formData = sectionedDocs({ mandatory, supporting, removed });

      const response = await baseAxiosMethod.post(`generalPlans/${planId}/document`, formData);
      return response.data.data;
    } catch (error) {
      throw new Error(handleAxiosError(error));
    }
  },
};

export default addGeneralPlanApi;
