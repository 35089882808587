import { Chip } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { Delete as DeleteIcon, Archive as ArchiveIcon } from "@material-ui/icons";
import React from "react";
import { useDispatch } from "react-redux";

import { Roles } from "../../../../components/AccessControl/UserRoles";
import EmptyTableBody from "../../../../components/EmptyTableBody";
import { EmptyTableSubTitle } from "../../../../components/EmptyTableBody/style";
import { StyledTableCell } from "../../../../components/SearchTable";
import { useRoles } from "../../../../hooks/useUserDetails";
import { setProcurementPlan } from "../../../../redux/reducer/adminReducer";
import { formatCurrency } from "../../../../util/formatCurrency";
import { HoverTableStyle } from "../../../../util/theme";
import { AddButton, CirclePlusIconWrapper } from "../style";

const TableBody = ({ filtered, history, id, planDetails, setTenderToRemove, urlLink }) => {
  const { role } = useRoles();
  const dispatch = useDispatch();
  const handleRedirect = (route, row) => {
    dispatch(setProcurementPlan(row));
    if (role === Roles.MINISTRY_OF_JUSTICE) {
      history.push(`/admin/procurement/${row.id}/documents`);
    } else {
      switch (route) {
        case "approved":
          return history.push(`/admin/procurement/plan-details/${row.id}`);
        default:
          return history.push(`/admin/procurement/plans/${id}/create/keydetails/${row.id}`);
      }
    }
  };

  const pickStatus = (status) => {
    switch (status) {
      case "Completed":
        return <Chip label={status} style={{ minWidth: "100px", height: "24px", color: "#414F3E", background: "#BBE5B3" }} />;
      case "In Progress":
        return <Chip label={status} style={{ minWidth: "100px", height: "24px", color: "#FF8D26", background: "#FCEBDB" }} />;
      case "Under Review":
        return <Chip label={status} style={{ minWidth: "100px", height: "24px", color: "#FF8D26", background: "#FCEBDB" }} />;
      case "Approved":
        return <Chip label={status} style={{ minWidth: "100px", height: "24px", color: "white", background: "#00AFF0" }} />;
      default:
        return <Chip label={status} style={{ minWidth: "100px", height: "24px", color: "#C9D4DB", background: "#414F3E" }} />;
    }
  };

  const pickStage = (stage) => {
    switch (stage) {
      case "Completed":
        return <Chip label={stage} style={{ minWidth: "100px", height: "24px", color: "#50B83C", background: "#50B83C" }} />;
      case "In Progress":
        return <Chip label={stage} style={{ minWidth: "100px", height: "24px", color: "#BF6712", background: "#FFE2C5" }} />;
      default:
        return <Chip label={stage} style={{ minWidth: "100px", height: "24px", color: " #454F5B", background: "#DFE3E8" }} />;
    }
  };

  const handleSelectTenderToRemove = (event, tender, isArchive = false) => {
    event.stopPropagation();
    isArchive ? setTenderToRemove({ data: tender, type: "Archive" }) : setTenderToRemove({ data: tender, type: "Delete" });
  };

  return filtered?.length ? (
    filtered?.map((row, index) => (
      <HoverTableStyle
        key={`${row.id}_${index}`}
        onClick={() => {
          if (!!urlLink) {
            return history.push(`${urlLink}/${row.id}`);
          }
          handleRedirect(row.status.toLowerCase(), row);
        }}
      >
        <StyledTableCell>
          <div>
            <p>{row.packageNumber}</p>
            <span>{row.name}</span>
          </div>
        </StyledTableCell>
        <StyledTableCell>{row.category.name}</StyledTableCell>
        <StyledTableCell>{row.method.code}</StyledTableCell>
        <StyledTableCell>{pickStatus(row?.status)}</StyledTableCell>
        <StyledTableCell>{pickStage(row.stage)}</StyledTableCell>
        <StyledTableCell>N{formatCurrency(row.budget)}</StyledTableCell>
        <StyledTableCell>
          {role === Roles.ADMIN && (
            <>
              {["UNDERREVIEW", "DRAFT", "INPROGRESS"].includes(planDetails?.status) &&
                ["Under Review", "Draft"].includes(row.status) && (
                  <IconButton onClick={(event) => handleSelectTenderToRemove(event, row)}>
                    <DeleteIcon style={{ color: "#999" }} />
                  </IconButton>
                )}
            </>
          )}
          {role === Roles.DSPPC && (
            <>
              {row.status === "Approved" && (
                <IconButton onClick={(event) => handleSelectTenderToRemove(event, row, true)}>
                  <ArchiveIcon style={{ color: "#999" }} />
                </IconButton>
              )}
            </>
          )}
        </StyledTableCell>
      </HoverTableStyle>
    ))
  ) : (
    <EmptyTableBody>
      <EmptyTableSubTitle>No Tender</EmptyTableSubTitle>
      <span style={{ width: "60%", marginBottom: "12px", fontSize: "17px" }}>
        You currently have no activity added to this plan. Click the button below to start.
      </span>
      <AddButton onClick={() => history.push(`/admin/procurement/plans/${id}/create/keydetails/new`)}>
        <CirclePlusIconWrapper /> Add Tender
      </AddButton>
    </EmptyTableBody>
  );
};

export default TableBody;
