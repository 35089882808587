import React from "react";
import { Box } from "@material-ui/core";
import {
  CheckEntryCheckCircleSharpIcon,
  CheckEntryContentWrapper,
  CheckEntryErrorIcon,
  CheckEntryText,
  CheckEntriesContainer,
  CheckEntryHeader,
} from "./style";

const CheckEntryTab = ({ completedForm = [], textValues = [], cardTitle = "" }) => {
  const CheckEntryContent = (entryText, index) => (
    <CheckEntryContentWrapper key={index}>
      {completedForm.includes(index) ? <CheckEntryCheckCircleSharpIcon /> : <CheckEntryErrorIcon />}
      <CheckEntryText>{entryText}</CheckEntryText>
    </CheckEntryContentWrapper>
  );
  return (
    <CheckEntriesContainer>
      <CheckEntryHeader>{cardTitle}</CheckEntryHeader>
      <Box>
        {Boolean(textValues.length) && textValues.map((eachText, index) => CheckEntryContent(eachText, index))}
      </Box>
    </CheckEntriesContainer>
  );
};

export default React.memo(CheckEntryTab);
