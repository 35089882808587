import React from "react";
import Chart from "react-apexcharts";
import { Box, Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

import { CardWrapper } from "../../../util/theme";
import { ChartTitleText, ProjectCostLabel, LegendTitleText, LegendBullets } from "./style";
import { pieOption } from "../../../util/chart";

const OverviewPiechart = ({ title, label, labels, legends, isLoading }) => {
  const ChartLegend = ({ title, color, percent, count }) => {
    return (
      <Box display="flex" alignItems="baseline" style={{ marginBottom: 15 }}>
        <LegendBullets color={color} />
        <Box style={{ width: "100%" }}>
          <LegendTitleText>{title}</LegendTitleText>
          <Box>
            <Grid container spacing={4}>
              <Grid item xs={6} style={{ width: "100%" }}>
                {isLoading ? (
                  <Skeleton />
                ) : (
                  <>
                    <ProjectCostLabel>Percentage</ProjectCostLabel>
                    <ProjectCostLabel>{percent?.toFixed(1)}%</ProjectCostLabel>
                  </>
                )}
              </Grid>
              <Grid item xs={6}>
                {isLoading ? (
                  <Skeleton />
                ) : (
                  <>
                    <ProjectCostLabel>Count</ProjectCostLabel>
                    <ProjectCostLabel>{count?.toFixed(1)}</ProjectCostLabel>
                  </>
                )}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Grid item xs={6}>
      <CardWrapper style={{ width: "100%", height: "100%" }}>
        <ChartTitleText>{title}</ChartTitleText>
        <Box mt="20px" style={{ height: "100%" }}>
          <Grid container spacing={2}>
            <Grid item xs={7}>
              {!isLoading ? (
                <Chart
                  options={pieOption({
                    label,
                    labels,
                    colors: Object.values(legends).map((legend) => legend.color),
                  })}
                  series={Object.values(legends).map((legend) => Number(legend.count?.toFixed(1)) || 0)}
                  type="donut"
                  height="270px"
                />
              ) : (
                <Skeleton height={200} width={200} variant="circle" />
              )}
            </Grid>
            <Grid item xs={5}>
              {Object.keys(legends).map((legend) => (
                <ChartLegend
                  key={legend}
                  title={legend}
                  color={legends[legend].color}
                  percent={legends[legend].percent}
                  count={legends[legend].count}
                />
              ))}
            </Grid>
          </Grid>
        </Box>
      </CardWrapper>
    </Grid>
  );
};

export default OverviewPiechart;
