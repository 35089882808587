import React, { useState } from "react";
import { Box } from "@material-ui/core";
import { useQuery, useMutation } from "react-query";
import { useParams } from "react-router-dom";
import { PageContainer, PageTitle } from "../../Common/style";
import Breadcrumb from "../../Common/PageElements/Breadcrumb";
import BidInfoCard from "../BidDetails/BidInfo";
import vendorsAPI from "../../../redux/api/vendorsAPI";
import Document from "../../../components/Common/HOC/withActivityLayout/Document";
import {
  PlaceBidFileUploadContainer,
  // PlaceBidFileUploadTitle,
  NewCancelButton,
  PlaceBidInfoContainer,
} from "./style";
// import GridFileUpload from "../../../components/GridFileUpload";
// import { fileUploads } from "./mock";
import { SearchButton } from "../../../components/Forms/Common/style";
import withVendorDashboard from "../HOC/withVendorDashboard";
import useAlert from "../../../hooks/useAlert";

const VendorPlaceBid = () => {
  const { id } = useParams();
  // const [mandatoryFileName, setMandatoryFileName] = useState(fileUploads);
  // const [supportingDocFileName, setSupportingDocFileName] = useState(fileUploads);
  const [documents, setDocuments] = useState({ mandatory: [], supporting: [], removed: [] });

  const { showAlert } = useAlert();
  const {
    status: errorStatus, error, data = [],
  } = useQuery(['getSingleBid', id], vendorsAPI?.getSingleBid, {
    enabled: !!id,
  })

// eslint-disable-next-line
  const [postDocumentDataSheet, postResponse] = useMutation(vendorsAPI.saveBidsDocument);
  const {
    status: ProcDocumentErrorStatus, data: procurementDocuments = [], isLoading: isProcDocumentIsLoading,
  } = useQuery(['getVendorDocuments', data.procurementId], vendorsAPI?.getBidInvitationDocuments, {
    enabled: !!data.procurementId,
  })

  const handleSendForApproval = async () => {
    const postParams = {
      ...documents,
      // object id
      activityId: data.id,
    }

    // if(dataSheet){
    //   const dateobject = new Date(`${formValues.deadlineForSubmission}`);
    //   postParams.submissionDeadline = dateobject.toISOString()
    // }

    await postDocumentDataSheet(postParams);
  };

  React.useEffect(() => {
    if (errorStatus === "error" || ProcDocumentErrorStatus === 'error') {
      showAlert({
        severity: "error",
        message: error?.message,
        durationInMs: 3000,
      });
    }
  }, [errorStatus, showAlert, error, ProcDocumentErrorStatus]);


  const BreadcrumbLinks = [
    { url: "/vendor/bids", title: "Bids", forwardSlash: true },
    { url: `/vendor/bids/${id}`, title: `${id}`, forwardSlash: false },
    { url: `/vendor/bids/${id}/place`, title: "Place Bid", forwardSlash: false },
  ];

  return (<PageContainer>
    <Box marginBottom="35px">
      <Box>
        <PageTitle>{data?.packageNumber}</PageTitle>
        <Breadcrumb values={BreadcrumbLinks} />
      </Box>
    </Box>

    <PlaceBidInfoContainer>
      <BidInfoCard
        procurementData={data}
        biddingDocuments={procurementDocuments}
        isDocumentLoading={isProcDocumentIsLoading}
      />
      <PlaceBidFileUploadContainer>

        <Document documents={documents}
          setDocuments={setDocuments}
          loading={isProcDocumentIsLoading}
        />

   
      </PlaceBidFileUploadContainer>
    </PlaceBidInfoContainer>
    <Box display="flex" justifyContent="flex-end" alignItems="center">
      <NewCancelButton>Back</NewCancelButton>
      <SearchButton style={{ marginLeft: "15px" }}
        onClick={() => {
          handleSendForApproval()
      }}
      >Submit Bid</SearchButton>
    </Box>
  </PageContainer>);
};

export default withVendorDashboard(VendorPlaceBid);
