import React from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import withAdminDashboard from "../../pages/Admin/HOC/withAdminDashboard";
import { GeneralContentWrapper, GeneralTitlePage } from "../AddGeneralPlan/style";
import ActivityTabs from "../ActivityTabs/ActivityTabs";
import CustomStageDocumentTab from "./CustomStageDocumentTab";
import CustomStageReviewSection from "./CustomStageReviewSection";
import Breadcrumb from "../../pages/Common/PageElements/Breadcrumb";

const CustomStage = (props) => {
  const { pageTitle, generalPlanLinks=[] } = props;
  const { activityId } = useParams()
  
  return (
    <GeneralContentWrapper>
      <GeneralTitlePage>{pageTitle}</GeneralTitlePage>
      <Breadcrumb values={generalPlanLinks}/>
      <ActivityTabs
        tabs={[
          {
            title: "Document",
            component: <CustomStageDocumentTab activityId={activityId} />
          },
          {
            title: "Review",
            component: <CustomStageReviewSection/>
          }
        ]}
        entries={[
          "Draft Bidding Documents"
        ]}
      />
    </GeneralContentWrapper>
  );
};

CustomStage.propTypes = {
  pageTitle: PropTypes.string.isRequired
};

export default withAdminDashboard(CustomStage);
