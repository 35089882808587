/* eslint-disable no-unused-expressions */
import React from "react";
import { SaveButton, Wrapper, DoubleWrapper, GenButton } from "./style";

export const ButtonGroup = ({
  firstBtnText,
  handleFirstBtn,
  secondBtnText,
  handleSecondBtn,
  thirdBtnText,
  handleThirdBtn,
  isReviewMode,
}) => (
    <Wrapper container justify="space-between">
      <GenButton onClick={handleFirstBtn}>{firstBtnText}</GenButton>
      <DoubleWrapper>
        {secondBtnText ? <GenButton
          marginRight="20px"
          onClick={(e) => {
            e.preventDefault();
            handleSecondBtn();
          }}
        >
          {secondBtnText}
        </GenButton > : null}
        {isReviewMode ? (
          <GenButton
            marginRight="20px"
            backgroundColor="#3BD278"
            onClick={(e) => {
              e.preventDefault();
              handleThirdBtn();
            }}
          >
            {thirdBtnText}
          </GenButton>
        ) : (
          <SaveButton
            onClick={(e) => {
              e.preventDefault();
              handleThirdBtn();
            }}
          >
            {thirdBtnText}
          </SaveButton>
        )}
      </DoubleWrapper>
    </Wrapper>
);
