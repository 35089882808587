import styled from "styled-components";
import { Typography, Grid, Button } from "@material-ui/core";
import { theme } from "../../../util/theme";
import { StyledTableCell } from "../../../components/SearchTable";

export const VendorDetailsBreadcrumbs = styled(Typography)`
  height: 16px;
  color: #00b0f1;
  font-size: 12px !important;
  letter-spacing: 0;
  line-height: 16px !important;
`;

export const VendorDetailsContainer = styled(Grid)`
  margin-top: 25px !important;
`;

export const CommonCardStyled = styled.div`
  box-sizing: border-box;
  border: 1px solid #c9d4db !important;
  border-radius: 3px !important;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 #c9d4db !important;
  padding: 20px;
`;

export const VendorCardContainer = styled(CommonCardStyled)``;

export const VendorInfoCard = styled(CommonCardStyled)`
`;

export const ActivitiesCard = styled(VendorInfoCard)`
  min-height: 643px;
  margin-top: 20px !important;
  padding: 25px 20px 16px;
`;

export const StatusButton = styled(Button)`
  border-radius: 3px !important;
  background-color: ${({ status }) =>
    status === "APPROVED" ? "#B0E7AE !important" : status === "DISAPPROVED" ? "#FFC8BE !important" : "#FCF1CD !important"};
  margin-right: 15px !important;
  color: ${({ status }) =>
    status === "APPROVED" ? "#00823C !important" : status === "DISAPPROVED" ? "#DD5E47 !important" : "#F49342 !important"};
  text-transform: none !important;
`;

export const PendingContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px !important;
`;

export const RCText = styled(Typography)`
  color: #1e2b37;
  font-size: 16px !important;
  font-weight: 600 !important;
  letter-spacing: 0;
  line-height: 24px !important;
`;

export const TextLabel = styled(Typography)`
  color: #5f7483 !important;
  font-size: 13px !important;
  letter-spacing: 0;
  line-height: 16px !important;
`;

export const ValueText = styled(Typography)`
  color: #5f7483 !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  letter-spacing: 0;
  line-height: 20px !important;
`;

export const InfoText = styled(ValueText)`
  font-size: 12px !important;
`;

export const BusinessAddressContainer = styled.div`
  margin-top: 15px !important;
`;

export const ApproveButton = styled(Button)`
  height: 40px;
  width: auto;
  border-radius: 3px;
  background-color: #6ccf81 !important;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
  margin-right: 15px !important;
  text-transform: none !important;
  color: #fff !important;
`;

export const RejectButton = styled(ApproveButton)`
  background-color: #de3618 !important;
`;

export const ButtonContainer = styled.div`
  display: flex;
  margin-top: 15px !important;
`;

export const CountText = styled(Typography)`
  margin-top: 10px !important;
  color: #1e2b37;
  font-size: 26px !important;
  font-weight: 600 !important;
  letter-spacing: 0;
  line-height: 35px !important;
`;

export const ActiveText = styled(Typography)`
  height: 22px;
  border-radius: 3px;
  background-color: #b0e7ae;
  color: #00823c;
  padding: 0 10px;
  margin-right: 10px !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  letter-spacing: 0;
  line-height: 16px !important;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CompletedText = styled(ActiveText)`
  background-color: rgba(0, 176, 241, 0.13);
  color: #00b0f1;
`;

export const RejectText = styled(ActiveText)`
  background-color: #ffc8be;
  color: #dd5e47;
`;

export const SignedText = styled(ActiveText)`
  background-color: #c1eeff;
  color: #03adeb;
`;
export const UnsignedText = styled(ActiveText)`
  background-color: #cbd4da;
  color: #637381;
`;

export const TextFlexContainer = styled.div`
  display: flex;
  margin-top: 10px !important;
`;

export const CardHeaderText = styled(Typography)`
  color: #1e2b37;
  font-size: 16px !important;
  font-weight: 600 !important;
  letter-spacing: 0;
  line-height: 24px !important;
  margin-bottom: 20px !important;
`;

export const SubscriptionTextContainer = styled(TextFlexContainer)`
  margin-top: 0 !important;
`;

export const FeeContainer = styled.div`
  margin-left: 20px !important;
`;

export const TextGroupContainer = styled.div`
  margin-top: 20px !important;
`;

export const SubscriptionIcon = styled(Typography)`
  padding: 10px;
  text-transform: capitalize;
  border-radius: 3px;
  background-color: rgba(0, 176, 241, 0.07);
  color: #00b0f1;
  font-size: 36px !important;
  font-weight: 600 !important;
  letter-spacing: 0;
  line-height: 36px !important;
  text-align: center !important;
`;

export const IdText = styled(Typography)`
  color: ${theme.color.primary} !important;
  font-size: 14px;
  font-weight: 600 !important;
  letter-spacing: 0;
  line-height: 19px !important;
`;

export const TableFirstCell = styled(StyledTableCell)`
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  padding-top: 10px !important;
`;

export const ActivityText = styled(Typography)`
  color: #5f7483;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
`;

export const ActivityTextContainer = styled.div`
  margin-bottom: 15px !important;
`;

export const ViewAllText = styled(Typography)`
  color: #212b36;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: 25px !important;
  text-align: center;
  cursor: pointer;
`;

export const ActivityIdText = styled.span`
  color: ${theme.color.primary};
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
`;

export const ActivityTimeText = styled(Typography)`
  height: 20px;
  color: #8e9fac;
  font-size: 14px !important;
  letter-spacing: 0;
  line-height: 20px;
`;
