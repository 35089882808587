import styled from "styled-components";
import { SecondaryWrapper } from "../Common/style";
import { theme } from "../../../util/theme";
import { ReactComponent as FileSVG } from "../../../assets/file.svg";

export const DocumentWrapper = styled.div`
  border: 1px solid #EBEBEB;
  border-radius: 3px;
  box-sizing: border-box;
`;

export const DocumentDetails = styled(SecondaryWrapper)`
  margin: 10px;
  padding: 15.5px;
  display: flex;

  div:nth-child(2) {
    margin-left: 15px;
  }

  p:first-child {
    font-weight: 800;
  }

  p:nth-child(2), span {
    font-size: 14px !important;
  }

  span:nth-child(2) {
    color: ${theme.color.danger};
    margin-left: 40px;
    cursor: pointer;
  }
`;

export const FileIcon = styled(FileSVG)`
  width: 18px;
  height: 18px;
  margin-top: 2px;
`;

export const Wrapper = styled.div`
  padding: 25px;
  width: 485px;

  .MuiFilledInput-input {
    text-transform: none;
  }

  p:nth-child(2) {
    font-size: 14px !important;
  }
`;

export const Divider = styled.div`
  margin: 32px 0;
  border-bottom: 1px solid #E3D0FF;
`;

export const EmptyState = styled.div`
  display: flex;
  justify-content: center;
  margin: 14px auto;
  flex-direction: column;
  align-items: center;

  svg {
    width: 48px;
    fill: ${theme.color.secondary};
  }
`;
