import React, { useContext, useState } from "react";
import { useMutation } from "react-query";
import DocumentTab from "../../DocumentTab";
import ToastContext from "../../../util/toastContext";
import procurementAPI from "../../../redux/api/procurementAPI";
import { CardContentWrapper } from "../../../util/theme";
import { CancelButton } from "../../Forms/Common/style";
import LoadingButton from "../../LoadingButton/LoadingButton";

const CustomStageDocumentTab = ({ onNext, activityId }) => {
  const { showAlert } = useContext(ToastContext);
  const [documents, setDocuments] = useState({ mandatory: [], supporting: [], removed: [] });
  const [saveDocument, saveDocumentResponse] = useMutation(procurementAPI.savePlanDocument);
  const addFiles = (documentType) => (newDocuments) => {
    const uploads = {
      ...documents,
      [documentType]: newDocuments,
    };
    setDocuments(uploads);
  };

  const removeFile = (documentType) => (file) => {
    let uploads;
    if (file.inDb) {
      uploads = {
        ...documents,
        [documentType]: documents[documentType].filter((document) => document !== file),
        removed: documents.removed.concat(file.id),
      };
    } else {
      uploads = {
        ...documents,
        [documentType]: documents[documentType].filter((document) => document !== file),
      };
    }
    setDocuments(uploads);
  };

  const handleSendForApproval = async () => {
    await saveDocument({
      ...documents,
      activityId,
    });

    if (saveDocumentResponse.isSuccess) {
      showAlert({ severity: "success", message: "Document Uploaded"})
    } else {
      showAlert({ severity: "error", message: "Error while uploading document"})
    }
  };

  return (
    <div>
      <DocumentTab
        mandatory={documents.mandatory}
        supporting={documents.supporting}
        addMandatoryFiles={addFiles("mandatory")}
        addSupportingFiles={addFiles("supporting")}
        removeMandatoryFiles={removeFile("mandatory")}
        removeSupportingFiles={removeFile("supporting")}
      />
      <CardContentWrapper style={{ padding: "35px 25px" }}>
        <CancelButton disabled>Back</CancelButton>
        <LoadingButton onClick={() => handleSendForApproval()}>Send
          For Approval</LoadingButton>
      </CardContentWrapper>
    </div>
  );
};

export default CustomStageDocumentTab;
