import React from "react";
import {
  CardInfoText, CardLabelWrapper, CardNumberText, DescriptionSection,
} from "../../../../../components/AdminCard/style";
import { CardContentWrapper } from "../../../../../util/theme";

const MinistryCard = ({ title, count, description }) => (
    <CardContentWrapper>
      <DescriptionSection>
        <CardLabelWrapper>{title}</CardLabelWrapper>
        <CardNumberText>{count}</CardNumberText>
        <CardInfoText>{description}</CardInfoText>
      </DescriptionSection>
    </CardContentWrapper>
);

export default MinistryCard;
