import styled from "styled-components";
import { theme } from "../../../util/theme";
import { AddNew, CancelButton } from "../../Forms/Common/style";

export const FormWrapper = styled.div`
  min-height: 74vh;
`;

export const FormContent = styled.div`
  box-sizing: border-box;
  border: 1px solid #ebebeb;
  border-radius: 3px;
  background-color: #f8f8f9;
  margin: 23.5px;
  padding: 8px;
`;

export const MinistryAddNew = styled(AddNew)`
  width: 73px !important;
  margin-left: 20px;
`;

export const MinistryDelete = styled(AddNew)`
  width: 43px;
  color: #f21500 !important;
  font-size: 14px;
  margin-right: 8px;
  margin-top: auto !important;
  padding: 10px 0 !important;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 25.5px !important;
  margin-bottom: 31px;
`;

export const StaffCancelButton = styled(CancelButton)`
  box-sizing: border-box;
  height: 41px;
  width: 94px;
  border: 1px solid #637381 !important;
  background-color: #FFFFFF !important;
  color: ${theme.color.secondary} !important;
`;
