import React from "react";
import { DefaultWidthCardWrapper, CardContentWrapper } from "../../util/theme";
import {
  CardLabelWrapper, CardNumberText, CardInfoText, DescriptionSection, CardTitleText,
} from "./style";

const RoleCard = ({
  title, count, description, subTitleText,
}) => (
  <DefaultWidthCardWrapper>
    <CardContentWrapper>
      <DescriptionSection>
        <CardLabelWrapper>{title}</CardLabelWrapper>
        <CardTitleText>{subTitleText}</CardTitleText>
        <CardInfoText>{description}</CardInfoText>
        <CardNumberText>{count}</CardNumberText>
      </DescriptionSection>
    </CardContentWrapper>
  </DefaultWidthCardWrapper>
);

export default RoleCard;
