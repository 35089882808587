import baseAxiosMethod from "./baseAxiosMethod";
import { handleAxiosError } from "./handleApiError";

const homepageAPI = {};

homepageAPI.addComment = async function ({
  type, fullName, email, phoneNumber, body, objectId = null,
}) {
  try {
    await baseAxiosMethod.post("/comments", {
      type,
      fullName,
      email,
      phoneNumber,
      body,
      objectId,
    });
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

export default homepageAPI;
