import styled from "styled-components";
import { Typography } from "@material-ui/core";

export const ActiveStatus = styled(Typography)`
  border: 2px solid #ffffff;
  border-radius: 100px;
  background-color: ${({ isstatusbg }) => (isstatusbg === "true" ? "#D6EFD2" : "#FFC8BE")};
  color: ${({ isstatus }) => (isstatus === "true" ? "#118043" : "#DD5E47")};
  font-size: 13px !important;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  padding: 0 10px;
  display: inline-block;
`;

export const MinistryDetailsWrapper = styled.div`
  height: 90px;
  background-color: #e4e9ec;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center !important;
`;

export const RightWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between !important;
  width: 200px;
`;

export const ContentText = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #3f5673;
  margin-bottom: 10px;
`;

export const InvoiceItemsHeading = styled.div`
  height: 56px;
  background-color: #e4e9ec;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
`;

export const InvoiceItemsHeadingText = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  color: #3f5673;
  margin: 15px;
`;

export const InvoiceItemsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const InvoiceItemsText = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #3f5673;
  margin: 15px;
`;
