import React from "react";
import { Grid, Box, Typography, Tooltip } from "@material-ui/core";
import { useSelector } from "react-redux";
import moment from "moment";

import { ContractDetailTabText } from "./style";

const ContactBiddersTab = () => {
  const {
    adminContractDetails: { contractDetails = {}, awardedCompany = {}, tenderDetails = {} },
  } = useSelector((state) => state.admin);

  const details = [
    { title: "Project Title", value: contractDetails.contractName || "Not available", maxLength: 120 },
    { title: "Project Description", value: tenderDetails.description || "Not available", maxLength: 120 },
    { title: "Contractors Name", value: awardedCompany.companyName, maxLength: 120 },
    { title: "Contractors Phone	", value: awardedCompany.phone || "Not available" },
    { title: "Contractors Address	", value: awardedCompany.address || "Not available", maxLength: 120 },
    { title: "Contractors email	", value: awardedCompany.email || "Not available" },
    { title: "Contract Status	", value: contractDetails.status || "Not available" },
    // { title: "Contract award signing date	", value: contractDetails.signatureDate ? moment(contractDetails.signatureDate).format("DD, MMMM, yyyy") : 'Pending' },
    { title: "Contract duration", value: contractDetails.contractDuration + " " + contractDetails.durationType || "Not available" },
    { title: "Start date", value: moment(contractDetails.startdate).format("DD, MMMM, yyyy") || "Not available" },
    { title: "End date", value: moment(contractDetails.endDate).format("DD, MMMM, yyyy") || "Not available" },
  ];

  return (
    <>
      <Box style={{ background: "#e4e9ec", padding: 20 }}>
        <Typography variant="h6" style={{ color: "#5F7483" }}>
          Award Details
        </Typography>
        <Typography style={{ color: "#5F7483" }}>Current Procurement Stage : {tenderDetails?.procurementStage || 'Implementation Stage'}</Typography>
      </Box>
      {details.map((detail, i) => (
        <Grid key={i} style={{ borderBottom: "1px solid #CBD4DA" }} container>
          <Grid item xs={3}>
            <ContractDetailTabText style={{ background: "#e4e9ec", boxShadow: "0px -1px 0px #C9D4DB" }}>
              {detail.title}
            </ContractDetailTabText>
          </Grid>
          <Grid item sm style={{ borderLeft: "1px solid #CBD4DA" }}>
            {detail.maxLength && detail.length > detail.maxLength ? (
              <Tooltip title={detail.value} aria-label="add">
                <ContractDetailTabText>
                  {detail.value.length > detail.maxLength ? `${detail.value.substr(0, detail.maxLength)}...` : detail.value}
                </ContractDetailTabText>
              </Tooltip>
            ) : (
              <ContractDetailTabText>{detail.value}</ContractDetailTabText>
            )}
          </Grid>
        </Grid>
      ))}
    </>
  );
};

export default ContactBiddersTab;
