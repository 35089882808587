import { Box, Grid, TableCell, TableRow } from "@material-ui/core";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import moment from "moment";
import { useHistory, useParams } from "react-router-dom";
import { GeneralTitlePage } from "../../../../../../components/AddGeneralPlan/style";
import { SaveButton } from "../../../../../../components/Forms/Common/style";
import SearchTable, { StyledTableCell, StyledTableRow } from "../../../../../../components/SearchTable";
import procurementAPI from "../../../../../../redux/api/procurementAPI";
import Breadcrumb from "../../../../../Common/PageElements/Breadcrumb";
import { PageWrapper } from "../../../../../Common/style";
import { NewCancelButton } from "../../../../../Vendor/PlaceBid/style";
import ApprovalModal from "../../BidderResponseReceipt/EvaluatedVendors/ApprovalModal";
import MediaDocumentSection from "../MediaDocSection";
import {
  BorderFrame,
  InvoiceCardTitle,
  InvoiceStatusText,
  InvoiceText,
  ProjectMonitorCardContainer,
  ProjectProcureSubTitle
} from "../style";
import Loader from "../../../../../../components/Common/Loader";
import { formatCurrency } from "../../../../../../util/formatCurrency";

const TableBody = ({ tasks }) =>
  tasks?.map((eachItem, index) => (
    <StyledTableRow key={index}>
      <StyledTableCell>{eachItem.description || "N /A"}</StyledTableCell>
      <StyledTableCell>{(eachItem.estimatedValue && formatCurrency(eachItem.estimatedValue, true))|| 0}</StyledTableCell>
    </StyledTableRow>
  ));

const ProjectInvoice = () => {
  const { activityId, procurementId, milestoneId, projectId } = useParams();
  const [open, setOpen] = useState(false);
  const [uploadDocument, setUploadDocument] = useState([]);
  const history = useHistory();
  const { data: milestoneInvoice, isLoading: milestoneInvoiceIsLoading } = useQuery(
    ["milestoneInvoice", { milestoneId }],
    procurementAPI.getProjectMilestoneInvoice
  );

  const { data: invoicePaymentProof } = useQuery(
    ["milestoneInvoicePaymentProof", { invoiceId: milestoneInvoice?.id }],
    procurementAPI.getAdminPaymentProof, {
      enabled: milestoneInvoice?.id 
    }
  );

  const [uploadPaymentProof, uploadPaymentProofResp] = useMutation(procurementAPI.uploadInvoicePaymentProof, {
    onSuccess(){
      setOpen(false)
    }
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmitButton =() => {
    const formData = new FormData();

    uploadDocument.map((eachFile) => formData.append('Documents', eachFile))
    uploadPaymentProof({ formData, invoiceId: milestoneInvoice?.id })
  }

  return (
    <PageWrapper>
      <GeneralTitlePage>Invoice</GeneralTitlePage>
      <Breadcrumb
        values={[
          {
            url: "/admin/procurement/plans",
            title: "Home",
          },
          {
            url: `/admin/procurement/plans/${activityId}`,
            title: "Plan",
          },
          {
            url: `/admin/procurement/plan-details/${activityId}`,
            title: "Delta Irrigation Agricultural Improvement",
          },
          {
            url: `/admin/procurement/activity/contractsupervisionandmanagement/${activityId}/${procurementId}/`,
            title: "Monitoring",
          },
          {
            url: `/admin/procurement/activity/contractsupervisionandmanagement/${activityId}/${procurementId}/milestone/${projectId}/${milestoneId}`,
            title: "Milestone",
          },
        ]}
      />

      <Box pt="20px">
        <ProjectMonitorCardContainer>
          {milestoneInvoiceIsLoading && (<Loader />)}
          {!milestoneInvoiceIsLoading && (<BorderFrame>
            <Box p="20px">
              <Box pb="15px">
                <InvoiceText>Invoice</InvoiceText>
              </Box>
              <Box mt="10px" width="100%">
                <Grid container>
                  <Grid item xs={3}>
                    <InvoiceCardTitle>Milestone Name</InvoiceCardTitle>
                    <ProjectProcureSubTitle>{milestoneInvoice?.projectMileStone?.title || "N/A"}</ProjectProcureSubTitle>
                  </Grid>
                  <Grid item xs={3}>
                    <InvoiceCardTitle>Date Issued</InvoiceCardTitle>
                    <ProjectProcureSubTitle>{(milestoneInvoice?.createdAt && moment(milestoneInvoice?.createdAt).format("DD/MM/YYYY")) || "N/A"}</ProjectProcureSubTitle>
                  </Grid>
                  <Grid item xs={3}>
                    <InvoiceCardTitle>Date Due</InvoiceCardTitle>
                    <ProjectProcureSubTitle>{(milestoneInvoice?.dueDate && moment(milestoneInvoice?.dueDate).format("DD/MM/YYYY")) || "N/A"}</ProjectProcureSubTitle>
                  </Grid>
                  <Grid item xs={3}>
                    <InvoiceCardTitle>Invoice no</InvoiceCardTitle>
                    <ProjectProcureSubTitle>{milestoneInvoice?.invoiceNumber || "N/A"}</ProjectProcureSubTitle>
                  </Grid>
                </Grid>
                <Box pt="20px">
                  <Grid container>
                    <Grid item xs={3}>
                      <InvoiceCardTitle>Description</InvoiceCardTitle>
                      <ProjectProcureSubTitle>{milestoneInvoice?.description || "N/A"}</ProjectProcureSubTitle>
                    </Grid>
                    <Grid item xs={3}>
                      <InvoiceCardTitle>Invoice Status</InvoiceCardTitle>
                      <InvoiceStatusText paid={(milestoneInvoice?.paymentStatus.toLowerCase() === "paid").toString()}>{milestoneInvoice?.paymentStatus.toLowerCase() || "N/A"}</InvoiceStatusText>
                    </Grid>
                    <Grid item xs={3}>
                      <InvoiceCardTitle>Approved by</InvoiceCardTitle>
                      <ProjectProcureSubTitle>Isiyaku Ismaila</ProjectProcureSubTitle>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              <Box mt="20px">
                <MediaDocumentSection MediaFilesDoc={milestoneInvoice?.milestoneInvoiceDocuments} title="Invoice Supporting Document" />
              </Box>
              <Box>
                <SearchTable columnBold={true} showTableHeader={false} columns={["Item", "Amount"]}>
                  {
                    (milestoneInvoice?.projectMileStone?.milestoneTasks?.length > 0) && (<TableBody tasks={milestoneInvoice?.projectMileStone?.milestoneTasks} />)
                  }
                  {
                    ((milestoneInvoice?.projectMileStone?.milestoneTasks?.length === 0)) && (<TableRow><TableCell colSpan={2}>
                      <ProjectProcureSubTitle>There is no tasks available</ProjectProcureSubTitle>
                      </TableCell></TableRow>)
                  }
                  
                </SearchTable>
              </Box>
              { (milestoneInvoice?.paymentStatus?.toLowerCase() === "paid" && invoicePaymentProof?.length > 0) && (<Box mt="20px">
                <MediaDocumentSection MediaFilesDoc={invoicePaymentProof} title="Proof of Payment" />
              </Box>)}
            </Box>
          </BorderFrame>)}
          <Box width="100%" mt="30px" display="flex" justifyContent="flex-end">
            <NewCancelButton onClick={() => history.goBack()}>Back</NewCancelButton>
            <SaveButton onClick={handleClickOpen}>Confirm Payment</SaveButton>
          </Box>
        </ProjectMonitorCardContainer>
        <ApprovalModal
          filesNames={uploadDocument}
          setFilesNames={setUploadDocument}
          btnText="Confirm"
          cardTitle="Proof of Payment"
          docInfo="Upload Payment Receipt"
          open={open}
          onClickButton={handleSubmitButton}
          handleClose={handleClose}
          isLoading ={uploadPaymentProofResp.isLoading}
        />
      </Box>
    </PageWrapper>
  );
};

export default ProjectInvoice;
