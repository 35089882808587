/**
 * Converts a given file size in bytes to a human readable form
 * @example toHumanReadableFileSize(1024) // 1MiB
 * @example toHumanReadableFileSize(1000, false) // 1MB
 * @param {number} bytes The file size in bytes
 * @param {boolean} si Use SI (1000) or binary (1024)
 * @param {number} dp Number of decimal places
 * @see https://stackoverflow.com/a/14919494/8359475
 */
export function toHumanReadableFileSize(bytes, si = false, dp = 1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return `${bytes} B`;
  }

  const units = si ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"] : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    u += 1;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  return `${bytes.toFixed(dp)} ${units[u]}`;
}
