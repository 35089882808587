import React, { useContext } from "react";
import FormContext from "../../util/context";
import DirectorsDetails from "../Forms/DirectorsDetails";
import RegistrationCategories from "../Forms/RegistrationCategories";
import ProductsNServices from "../Forms/ProductsNServices";
import GeneralCompanyDetails from "../Forms/GeneralCompanyDetails";
import AttestationPage from "../Forms/AttestationPage";
import DocumentSubmission from "../Forms/DocumentSubmission";
import Verification from "../Forms/Verification/index";

export const Forms = {
  0: <GeneralCompanyDetails />,
  1: <DirectorsDetails />,
  2: <ProductsNServices />,
  3: <RegistrationCategories />,
  4: <DocumentSubmission />,
  5: <AttestationPage />,
  6: <Verification />,
};

const FormsSection = () => {
  const { activeForm } = useContext(FormContext);

  return Forms[activeForm];
};

export default FormsSection;
