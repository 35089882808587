/* eslint-disable no-unused-expressions */
import { createSlice } from "@reduxjs/toolkit";
import { fulfilled, fulfilledWithMeta, rejected } from "./baseReducer";
import baseThunkMethod, { modifiedBaseThunkMethod } from "./baseThunkMethod";

export const getStaffSummary = baseThunkMethod("getStaffSummary");
export const getStaff = baseThunkMethod("getStaff");
export const getPendingStaffInvite = baseThunkMethod("getPendingStaffInvite");
export const resendInvite = baseThunkMethod("resendInvite");
export const revokeInvite = baseThunkMethod("revokeInvite");

export const getMinistries = baseThunkMethod("getMinistries");
export const inviteStaffs = modifiedBaseThunkMethod("inviteStaffs");
export const getVendors = baseThunkMethod("getVendors");
export const getPendingVendors = baseThunkMethod("getPendingVendors");
export const getVendorsSummary = baseThunkMethod("getVendorsSummary");
export const updateStaffStatus = baseThunkMethod("updateStaffStatus");

export const getContracts = baseThunkMethod("getContracts");
export const getContractsSummary = baseThunkMethod("getContractsSummary");
export const getSingleContract = baseThunkMethod("getSingleContract");
export const getAdminContractDetails = baseThunkMethod("getAdminContractDetails");

export const savePlanDocument = baseThunkMethod("savePlanDocument");
export const getGeneralPlans = baseThunkMethod("getGeneralPlans");
export const getGeneralPlansSummary = baseThunkMethod("getGeneralPlansSummary");
export const getMinistryGeneralPlan = baseThunkMethod("getMinistryGeneralPlan");

export const createProcurementPlan = baseThunkMethod("createProcurementPlan");
export const getProcurementPlan = baseThunkMethod("getProcurementPlan");
export const getProcurementCategories = baseThunkMethod("getProcurementCategories");
export const getProcessTypes = baseThunkMethod("getProcessTypes");
export const getProcurementMethods = baseThunkMethod("getProcurementMethods");
export const getQualificationMethods = baseThunkMethod("getQualificationMethods");
export const getReviewMethods = baseThunkMethod("getReviewMethods");
export const getPackageNumber = baseThunkMethod("getPackageNumber");

export const createActivities = baseThunkMethod("createActivities");
export const getPresetStages = baseThunkMethod("getPresetStages");

export const adminSlice = createSlice({
  name: "admin",
  initialState: {
    loading: "idle",
    loaded: [],
    ministries: {},
    staffSummary: {},
    staff: {
      data: [],
      meta: {},
    },
    message: "",
    error: null,
    singleInvite: {},
    pendingStaff: {},
    pendingVendor: [],
    vendors: [],
    vendorsSummary: {},
    vendorCorrespondence: {},
    contracts: [],
    contractsSummary: {},
    contractsPagination: {},
    singleContract: {},
    adminContractDetails: {},
    generalPlans: {},
    generalPlansSummary: {},
    ministryGeneralPlan: {},
    procurementPlan: {},
    procurementCategories: [],
    processTypes: [],
    procurementMethods: [],
    qualificationMethods: [],
    reviewMethods: [],
    packageNumber: {},
    procurementPlanActivities: [],
    presetStages: {},
  },

  extraReducers: {
    [getStaffSummary.fulfilled]: fulfilled("staffSummary"),
    [getStaffSummary.rejected]: rejected("staffSummary"),
    [getStaff.fulfilled]: fulfilledWithMeta("staff"),
    [getStaff.rejected]: rejected("staff"),
    [getMinistries.fulfilled]: fulfilledWithMeta("ministries"),
    [getMinistries.rejected]: rejected("ministries"),
    [getPendingStaffInvite.fulfilled]: (state, action) => {
      state.loading = "idle";
      state.error = null;
      state.pendingStaff.pendingList = action.payload.data;
      state.pendingStaff.totalPendingList = action.payload.meta.pagination.totalEntries;
      !state.loaded.includes("pendingStaff") && state.loaded.push("pendingStaff");
    },
    [getPendingStaffInvite.rejected]: rejected("pendingStaff"),
    [getVendors.fulfilled]: fulfilled("vendors"),
    [getVendors.rejected]: rejected("vendors"),

    [getVendorsSummary.fulfilled]: fulfilled("vendorsSummary"),
    [getVendorsSummary.rejected]: rejected("vendorsSummary"),
    [getPendingVendors.fulfilled]: fulfilled("pendingVendor"),
    [getPendingVendors.rejected]: rejected("pendingVendor"),
    [revokeInvite.fulfilled]: (state, action) => {
      state.loading = "idle";
      state.error = null;
      state.pendingStaff = {
        pendingList: [...state.pendingStaff.pendingList.filter((item) => !action.meta.arg.emails.includes(item.email))],
        totalPendingList: state.pendingStaff.totalPendingList - action.meta.arg.emails.length,
      };
    },
    [getContracts.fulfilled]: (state, action) => {
      state.loading = "idle";
      state.error = null;
      state.contracts = action.payload.data;
      state.contractsPagination = action.payload.meta.pagination;
      if (!state.loaded.includes("contracts")) state.loaded.push("contracts");
    },
    [getContracts.rejected]: rejected("contracts"),
    [getContractsSummary.fulfilled]: fulfilled("contractsSummary"),
    [getContractsSummary.rejected]: rejected("contractsSummary"),
    [getContracts.rejected]: rejected("contracts"),
    [getSingleContract.fulfilled]: fulfilled("singleContract"),
    [getSingleContract.rejected]: rejected("singleContract"),
    [getAdminContractDetails.fulfilled]: fulfilled("adminContractDetails"),
    [getAdminContractDetails.rejected]: rejected("adminContractDetails"),
    [updateStaffStatus.fulfilled]: (state, action) => {
      state.loading = "idle";
      state.error = null;
      state.staff = {
        ...state.staff,
        data: [
          ...state.staff.data.find((item) => {
            item.id === action.meta.arg.id;
            return {
              ...item,
              status: action.meta.arg.status === 1 ? "DISABLED" : "ENABLED",
            };
          }),
        ],
      };
    },
    [getGeneralPlans.fulfilled]: fulfilledWithMeta("generalPlans"),
    [getGeneralPlansSummary.fulfilled]: fulfilled("generalPlansSummary"),
    [getMinistryGeneralPlan.fulfilled]: fulfilled("ministryGeneralPlan"),

    [createProcurementPlan.fulfilled]: (state, action) => {
      state.loading = "idle";
      state.error = null;
      state.procurementPlan = action.payload.data;
    },
    [createProcurementPlan.rejected]: rejected("procurementPlan"),

    [getProcurementPlan.fulfilled]: (state, action) => {
      state.loading = "idle";
      state.error = null;
      state.procurementPlan = {
        id: action.payload.data.id,
        name: action.payload.data.name,
        procurementCategoryId: action.payload.data.procurementCategory.id,
        procurementMethodId: action.payload.data.procurementMethod.id,
        estimatedAmountInNaira: action.payload.data.estimatedAmountInNaira,
        estimatedAmountInDollars: action.payload.data.estimatedAmountInDollars,
        reviewMethodId: action.payload.data.reviewMethod.id,
        ministryCode: action.payload.data.ministryCode,
        description: action.payload.data.description,
        packageNumber: action.payload.data.packageNumber,
        status: action.payload.data.status,
        sectionOne: action.payload.data.sectionOne,
        sectionTwo: action.payload.data.sectionTwo,
        sectionThree: action.payload.data.sectionThree,
        tenderingStage: action.payload.data.tenderingStage.name,
      };

      state.procurementPlanActivities = action.payload.data.procurementPlanActivities;

      !state.loaded.includes("procurementPlan") && state.loaded.push("procurementPlan");
    },
    [getProcurementPlan.rejected]: rejected("procurementPlan"),

    [getProcurementCategories.fulfilled]: fulfilled("procurementCategories"),
    [getProcurementCategories.rejected]: rejected("procurementCategories"),

    [getProcessTypes.fulfilled]: fulfilled("processTypes"),
    [getProcessTypes.rejected]: rejected("processTypes"),

    [getProcurementMethods.fulfilled]: fulfilled("procurementMethods"),
    [getProcurementMethods.rejected]: rejected("procurementMethods"),

    [getQualificationMethods.fulfilled]: fulfilled("qualificationMethods"),
    [getQualificationMethods.rejected]: rejected("qualificationMethods"),

    [getReviewMethods.fulfilled]: fulfilled("reviewMethods"),
    [getReviewMethods.rejected]: rejected("reviewMethods"),

    [getPackageNumber.fulfilled]: fulfilled("packageNumber"),
    [getPackageNumber.rejected]: rejected("packageNumber"),

    [createActivities.fulfilled]: fulfilled("procurementPlanActivities"),
    [createActivities.rejected]: rejected("procurementPlanActivities"),

    [getPresetStages.fulfilled]: (state, action) => {
      state.loading = "idle";
      state.error = null;
      state.presetStages[action.meta.arg.type] = action.payload.data.filter(
        (stage) => stage.procurementPlanTask.toUpperCase() === action.meta.arg.type
      );
      !state.loaded.includes(`presetStages.${action.meta.arg.type}`) && state.loaded.push(`presetStages.${action.meta.arg.type}`);
    },
    [getPresetStages.rejected]: rejected("presetStages"),
  },
  reducers: {
    updateGeneralMinistryPlan: (state, action) => {
      state.ministryGeneralPlan = action.payload;
      if (!state.loaded.includes("ministryGeneralPlan")) state.loaded.push("ministryGeneralPlan");
    },
    updateProcurementSectionStatus: (state, action) => {
      state.procurementPlan[action.payload.section] = action.payload.status;
    },
    setProcurementPlan: (state, action) => {
      state.procurementPlan = action.payload;
    },
    clearGeneralPlanSummary: (state, action) => {
      state.generalPlansSummary = {};
      state.loaded = state.loaded.filter((entry) => entry !== "generalPlansSummary");
    },
  },
});

export const {
  updateGeneralMinistryPlan,
  updateProcurementSectionStatus,
  setProcurementPlan,
  clearGeneralPlanSummary,
} = adminSlice.actions;

export default adminSlice.reducer;
