export const tableRows = [
  {
    id: 1,
    name: "Olayinka David",
    email: "minagric@gov.ng",
    ministry: "Ministry of Works",
    imageUrl: "",
  },
  {
    id: 2,
    name: "Yomi Olaoye",
    email: "yomi@gov.ng",
    ministry: "Ministry of Works",
    imageUrl: "https://res.cloudinary.com/kugoo/image/upload/v1552832316/fo.jpg",
  },
  {
    id: 3,
    name: "Yomi Olaoye2",
    email: "yomi2@gov.ng",
    ministry: "Ministry of Works",
    imageUrl: "https://res.cloudinary.com/kugoo/image/upload/v1552832316/fo.jpg",
  },
];

export const tableColumns = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "avatar",
    numeric: false,
    disablePadding: true,
    label: "",
  },
  {
    id: "ministry",
    numeric: false,
    disablePadding: false,
    label: "Ministry",
  },
  {
    id: "action",
    numeric: true,
    disablePadding: false,
    label: "Action",
  },
];

export const listData = [
  {
    id: 1,
    name: "Create procurement plan",
  },
  {
    id: 2,
    name: "Create procurement plan",
  },
  {
    id: 2,
    name: "Create procurement plan",
  },
  {
    id: 2,
    name: "Create procurement plan",
  },
  {
    id: 2,
    name: "Create procurement plan",
  },
  {
    id: 2,
    name: "Create procurement plan",
  },
  {
    id: 2,
    name: "Create procurement plan",
  },
  {
    id: 2,
    name: "Create procurement plan",
  },
  {
    id: 2,
    name: "Create procurement plan",
  },
  {
    id: 2,
    name: "Create procurement plan",
  },
  {
    id: 2,
    name: "Create procurement plan",
  },
  {
    id: 2,
    name: "Create procurement plan",
  },
  {
    id: 2,
    name: "Create procurement plan",
  },
  {
    id: 2,
    name: "Create procurement plan",
  },
];
