import React, { useEffect, useState } from "react";
import { ContentState, convertFromHTML, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Box, IconButton } from "@material-ui/core";

import {
  ActionButton,
  AddNewDocumentButton,
  ButtonsRow,
  Editor,
  RequiredDocumentGrid,
  RequiredDocuments,
  SectionTitle,
  SectionWrapper,
} from "../style";
import DraftEditor from "../../../../../../components/DraftEditor";
import InputField from "../../../../../../components/InputField";
import { ReactComponent as CancelIcon } from "../../../../../../assets/cancel.svg";
import ControlledDatePicker from "../../../../../../components/ControlledDatePicker";

const RequiredDocument = ({ title, setTitle, description, setDescription, activityIsApproved, onDelete }) => (
  <RequiredDocumentGrid>
    <div>
      <Box mb={2}>
        <InputField value={title} setValue={setTitle} label="Document Title" />
      </Box>
      <InputField value={description} setValue={setDescription} multiline={true} label="Description" />
    </div>
    {!activityIsApproved && (
      <IconButton onClick={onDelete} className="iconButton">
        <CancelIcon fill="#EB5757" width={12} height={12} />
      </IconButton>
    )}
  </RequiredDocumentGrid>
);

const Requirements = ({ saveState, activityIsApproved, onNext, initialState = {} }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const [documents, setDocuments] = useState([
    {
      title: "",
      description: "",
    },
  ]);

  const [deadline, setDeadline] = useState(null);

  useEffect(() => {
    if (initialState?.requiredDocuments) {
      setDocuments(initialState.requiredDocuments);
    }

    if (initialState?.deadline) {
      setDeadline(initialState?.deadline);
    }

    if (initialState.editorState) {
      const blocks = convertFromHTML(initialState.editorState);
      const state = ContentState.createFromBlockArray(blocks.contentBlocks, blocks.entityMap);

      setEditorState(EditorState.createWithContent(state));
    }
  }, [initialState]);

  const updateDocument = (index, key) => (value) => {
    const newDocuments = [...documents];

    newDocuments[index][key] = value;

    setDocuments(newDocuments);
  };

  const onDeleteDocument = (index) => () => {
    setDocuments(documents.filter((_, idx) => idx !== index));
  };

  const addDocument = () => {
    setDocuments(
      documents.concat({
        title: "",
        description: "",
      })
    );
  };

  const onClickNext = () => {
    const editorStateHTML = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    saveState({ requiredDocuments: documents, editorState: editorStateHTML, deadline });
    onNext();
  };

  return (
    <>
      <SectionTitle>Note</SectionTitle>
      <SectionWrapper>
        <Editor>
          <DraftEditor editorState={editorState} setEditorState={setEditorState} />
        </Editor>
      </SectionWrapper>

      <SectionTitle>Required Documents</SectionTitle>
      <SectionWrapper>
        <Box mb={3}>
          <ControlledDatePicker label="Deadline" value={deadline} onChange={setDeadline} />
        </Box>
        <RequiredDocuments>
          {documents.map(({ title, description }, index) => (
            <RequiredDocument
              title={title}
              activityIsApproved={activityIsApproved}
              setTitle={updateDocument(index, "title")}
              description={description}
              setDescription={updateDocument(index, "description")}
              onDelete={onDeleteDocument(index)}
            />
          ))}
        </RequiredDocuments>
        {!activityIsApproved && <AddNewDocumentButton onClick={addDocument}>Add New Document</AddNewDocumentButton>}
      </SectionWrapper>
      <ButtonsRow>
        <div />
        <ActionButton disabled={activityIsApproved} variant="contained" onClick={onClickNext}>
          Next
        </ActionButton>
      </ButtonsRow>
    </>
  );
};

export default Requirements;
