import styled from "styled-components";
import { Box } from "@material-ui/core";

export const DocumentUploadContainer = styled(Box)`
  display: grid;
  grid-template-columns: auto auto;

  > div:first-child {
    grid-column: 1/ span 3;
  }

  > div {
    margin-right: 19px;
    margin-left: 19px;
  }
`;

export const GeneralCardContentWrapper = styled.div`
  box-sizing: border-box;
  border: 1px solid #C9D4DB !important;
  background-color: #FCFCFD !important;
  padding: 19.5px 20.5px 13.5px !important;
`;
