import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { Link, useParams } from "react-router-dom";
import { format, parseISO } from "date-fns";
import { PageTitle, PageTitleContainer } from "../../../Common/style";
import Breadcrumb from "../../../Common/PageElements/Breadcrumb";
import { BackButton, CardWrapper, ContentWrapper, SubmitButton, Subsection, SubsectionTitle } from "./style";
import DetailItem from "../../../../components/DetailItem";
import { formatCurrency } from "../../../../util/formatCurrency";
import FileDownload from "../../../../components/FileDownload";
import withVendorDashboard from "../../HOC/withVendorDashboard";
import { useQuery, useMutation, queryCache } from "react-query";
import vendorsAPI from "../../../../redux/api/vendorsAPI";
import Loader from "../../../../components/Common/Loader";
import Skeleton from "@material-ui/lab/Skeleton";
import GridFileUpload from "../TransactionGridFileUpload/index";
import { Typography } from "@material-ui/core";

function TransactionDetails() {
  const [fileNames, setFilenames] = useState([]);
  const { milestoneId, invoiceId } = useParams();
  const queryKeys = ["vendorTransactionDetail", milestoneId];
  const queryUploadedKeys = ["vendorUploadedDoc", invoiceId];

  const transactionDetailsInfo = useQuery(queryKeys, vendorsAPI.getVendorTransactionInfoByMilestoneId);
  const uploadedTransactionInvoice = useQuery(queryUploadedKeys, vendorsAPI.getUploadedApprovedInvoice);
  const [uploadPaymentDocument, uploadPaymentDocResp] = useMutation(vendorsAPI.uploadApprovedInvoice, {
    onSuccess: (data) => {
      queryCache.setQueryData(queryUploadedKeys, (prevData) => [...data, ...prevData]);
      setFilenames([]);
    },
  });

  const transformDocument = uploadedTransactionInvoice?.data?.map((eachDoc) => ({
    name: eachDoc.name,
    id: eachDoc.id,
    size: eachDoc.file.bytes,
    url: eachDoc.file.secure_url,
  }));

  const handleSubmitDoc = async () => {
    const formData = new FormData();
    formData.append("ObjectId", invoiceId);
    formData.append("Status", 4);
    fileNames && fileNames.map((eachFile) => formData.append("Documents", eachFile));
    return await uploadPaymentDocument({ formData });
  };

  const breadcrumbs = [
    {
      title: "Transactions",
      href: "/vendor/transactions",
    },
    {
      title: transactionDetailsInfo?.data?.milestoneInvoice?.invoiceNumber,
    },
  ];
  const renderDetail = (label, text, span = 4) => (
    <Grid item xs={span}>
      <DetailItem label={label} text={text} />
    </Grid>
  );

  return (
    <ContentWrapper>
      <PageTitleContainer>
        {!transactionDetailsInfo.isLoading && (
          <div>
            <PageTitle>{transactionDetailsInfo?.data?.milestoneInvoice?.invoiceNumber}</PageTitle>
            <Breadcrumb values={breadcrumbs} />
          </div>
        )}
        {transactionDetailsInfo?.isLoading && <Skeleton style={{ width: "200px", height: "100px" }} />}
      </PageTitleContainer>
      <CardWrapper mb={3.75}>
        <Subsection mb={2.5}>
          <SubsectionTitle>Transaction Information</SubsectionTitle>
          {transactionDetailsInfo?.isLoading && <Loader />}
          {!transactionDetailsInfo?.isLoading && (
            <Grid container spacing={2}>
              {renderDetail("Transaction ID", transactionDetailsInfo?.data?.milestoneInvoice?.invoiceNumber)}
              {renderDetail(
                  "Transaction Date",
                  transactionDetailsInfo?.data?.milestoneInvoice?.paymentDate && format(parseISO(transactionDetailsInfo?.data?.milestoneInvoice?.paymentDate), "dd MMM, yyyy")
                )}
              {renderDetail("Value", formatCurrency(transactionDetailsInfo?.data?.milestoneInvoice?.price, true))}
              {renderDetail("Milestone Title", transactionDetailsInfo?.data?.milestoneInvoice?.name)}
              {renderDetail("Project Title", transactionDetailsInfo?.data?.project?.title)}
            </Grid>
          )}
        </Subsection>
        <Subsection colored mb={2.5}>
          {uploadedTransactionInvoice?.data?.length > 0 ? (
            <SubsectionTitle>Approval of Payment Document(s)</SubsectionTitle>
          ) : null}
          {uploadedTransactionInvoice?.isLoading && <Loader />}
          {uploadedTransactionInvoice?.data?.length === 0 && (
            <Box width="100%" height="100px" display="flex" justifyContent="center" alignItems="center">
              <Typography>No Documents Available </Typography>
            </Box>
          )}
          {!uploadedTransactionInvoice?.isLoading && (
            <Grid container spacing={2}>
              {transformDocument?.map((item) => (
                <Grid key={item.id} item xs={4}>
                  <a style={{ textDecoration: "none" }} href={item.url} target="_blank" rel="noopener noreferrer">
                    <FileDownload lightTheme name={item.name} size={item.size} url={item.url} />
                  </a>
                </Grid>
              ))}
            </Grid>
          )}
        </Subsection>
        <Subsection>
          <SubsectionTitle>Upload Payment Confirmation Documents</SubsectionTitle>
          <GridFileUpload filesNames={fileNames} setFilesNames={setFilenames} multiple />
        </Subsection>
      </CardWrapper>
      <Box display="flex" height={50} justifyContent="flex-end">
        <BackButton disabled={uploadPaymentDocResp?.isLoading} component={Link} to="/transactions">
          Back
        </BackButton>
        <SubmitButton disabled={uploadPaymentDocResp?.isLoading} onClick={handleSubmitDoc}>
          {" "}
          {uploadPaymentDocResp?.isLoading ? "Please wait..." : "Submit documents"}
        </SubmitButton>
      </Box>
    </ContentWrapper>
  );
}

export default withVendorDashboard(TransactionDetails);
