import React from "react";
import { Box } from "@material-ui/core";
import PropTypes from "prop-types";
import { GeneralCardContentWrapper, GeneralTitleLabel } from "../AddGeneralPlan/style";
import DocumentUploadField from "../DocumentUploadField";

const DocumentTab = ({
  mandatory,
  supporting,
  loading = false,
  addMandatoryFiles,
  addSupportingFiles,
  removeMandatoryFiles,
  removeSupportingFiles,
}) => (
  <Box>
    <GeneralTitleLabel>Mandatory Documents</GeneralTitleLabel>
    <GeneralCardContentWrapper>
      {loading
        ? "Please Wait..."
        : <DocumentUploadField
          files={mandatory}
          addFiles={addMandatoryFiles}
          removeFile={removeMandatoryFiles}
          loading={loading}
        />
      }
    </GeneralCardContentWrapper>

    <GeneralTitleLabel>Supporting Documents</GeneralTitleLabel>
    <GeneralCardContentWrapper>
      {loading
        ? "Please Wait..."
        : <DocumentUploadField
          files={supporting}
          addFiles={addSupportingFiles}
          removeFile={removeSupportingFiles}
          loading={loading}
        />
      }
    </GeneralCardContentWrapper>
  </Box>
);

DocumentTab.propTypes = {
  mandatory: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      error: PropTypes.bool,
      toBeUploaded: PropTypes.bool,
    }),
  ),
  supporting: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      error: PropTypes.bool,
    }),
  ),
  loading: PropTypes.bool,
  addMandatoryFiles: PropTypes.func.isRequired,
  addSupportingFiles: PropTypes.func.isRequired,
  removeMandatoryFiles: PropTypes.func.isRequired,
  removeSupportingFiles: PropTypes.func.isRequired,
};

export default DocumentTab;
