import { useQuery } from "react-query";
import procurementAPI from "../../redux/api/procurementAPI"

export const useProcurementPlanDetails = ({ procurementId }) => {
  return useQuery(
    ["generalPlanDetails", procurementId], () => procurementAPI.getPlanDetails("", procurementId),
    {
      enabled: !!procurementId,
      cacheTime: 3600 * 100,
      staleTime: 3600 * 100,
    }
  )
}
