import React, { useContext } from "react";
import PropTypes from "prop-types";

import CustomTimeline from "../CustomTimeline";
import { FormTitle, FormSubtitle } from "../../util/theme";
import FormContext from "../../util/context";

const propTypes = {
  completedForm: PropTypes.arrayOf(PropTypes.number).isRequired,
  activeForm: PropTypes.number.isRequired,
};

const FormsProgress = ({ completedForm, activeForm }) => (
  <>
    <FormTitle>Registration progress</FormTitle>
    <FormSubtitle variant="subtitle1" gutterBottom>
      Fill in the fields below
    </FormSubtitle>
    <CustomTimeline
      timelineValues={[
        { title: "Section 1", subtitle: "General company details" },
        { title: "Section 2", subtitle: "Directors/Partners Details" },
        { title: "Section 3", subtitle: "Area of Core Competence" },
        { title: "Section 4", subtitle: "Registration Categories" },
        { title: "Section 5", subtitle: "Documents Submitted" },
        { title: "Section 6", subtitle: "Attestation" },
        { title: "Verification", subtitle: "Attestation" },
      ]}
      completed={completedForm}
      activeTimeline={activeForm}
    />
  </>
);

const ProgressSection = () => {
  const { activeForm, completedForm } = useContext(FormContext);

  return (
    <>
      <FormsProgress
        activeForm={activeForm}
        completedForm={completedForm}
      />
    </>
  );
};

FormsProgress.propTypes = propTypes;

export default ProgressSection;
