import styled from "styled-components";
import Tab from "@material-ui/core/Tab";
import { theme } from "../../../util/theme";

export const TabContainer = styled.section`
  height: 60px;
  background-color: #F1F5F7;

  .MuiTabs-indicator{
    background-color: #F1F5F7;
  }

  .MuiTab-wrapper {
    text-transform: none;
    align-items: flex-start;
  }

  .MuiContainer-root {
    height: 100%
  }

  .MuiTabs-root {
    height: 100%;
  }

  .MuiTabs-flexContainer {
    height: 100%
  }
`;
export const NewsUpdateSection = styled.section`
  margin-top: 40px !important;
`;

export const NewsUpdateCardListContainer = styled.div`
  margin-top: 23px;
`;

export const PageTab = styled(Tab)`
  color: ${({ activetab = "false" }) => (activetab === "false" ? "#000" : theme.color.primary)};
  padding: 0;
`;
