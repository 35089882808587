import React from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { Link } from "react-router-dom";
import {
  FooterSection,
  FooterTitleText,
  FooterDescriptionText,
  FooterGroupText,
  FooterCopyRightContainer,
  TwitterIcon,
  GroupedIcon,
  FacebookIcon,
} from "./style";

const HomepageFooter = () => {
  const linkStyle = { color: "#fff", textDecoration: "none" };
  return (
    <FooterSection>
      <Container>
        <Grid container justify="space-between">
          <Grid item>
            <FooterTitleText>About iProkure</FooterTitleText>
            <FooterDescriptionText>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              Expedita tempora accusamus reiciendis et earum fugiat vero illum a placeat dicta.
              <br />
              <br />
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptate atque voluptatibus
              fuga assumenda corrupti aliquam similique veritatis.
              Voluptas quo, eligendi nihil sit minus, aperiam et magni at, tempore voluptatum vel.…

            </FooterDescriptionText>
          </Grid>
          <Grid item>
            <FooterTitleText>Important Links</FooterTitleText>
            <FooterDescriptionText>
              <Link to="/login" style={linkStyle}>
                Vendor Registration
              </Link>
            </FooterDescriptionText>
            <FooterDescriptionText>
              <Link to="/resources" style={linkStyle}>
                Resources
              </Link>
            </FooterDescriptionText>
            <FooterDescriptionText>
              <Link to="/news-and-bulletins" style={linkStyle}>
                News & Bulletin
              </Link>
            </FooterDescriptionText>
            <FooterDescriptionText>
              <Link to="/ocds-portal/awarded-contracts" style={linkStyle}>
                Awarded Contracts
              </Link>
            </FooterDescriptionText>
            <FooterDescriptionText>Frequently asked questions</FooterDescriptionText>
          </Grid>
          <Grid item>
            <FooterTitleText>Contact Us</FooterTitleText>
            <FooterDescriptionText>Address</FooterDescriptionText>
            <FooterGroupText>32 Ade Ajayi Ogudu Lagos state</FooterGroupText>
            <br />
            <FooterDescriptionText>Telephone</FooterDescriptionText>
            <FooterGroupText>08114344724</FooterGroupText>
            <FooterGroupText>08115446568</FooterGroupText>
            <br />
            <FooterGroupText>Email</FooterGroupText>
            <FooterGroupText>info@prunedge.com</FooterGroupText>
          </Grid>
        </Grid>
        <FooterCopyRightContainer>
          <GroupedIcon>
            <TwitterIcon />
            <FacebookIcon />
          </GroupedIcon>
          <FooterGroupText>Prunedge Technologies E-procurment</FooterGroupText>
          <FooterGroupText>All rights reserved | (C) Copyright 2020</FooterGroupText>
        </FooterCopyRightContainer>
      </Container>
    </FooterSection>
  );
};

export default HomepageFooter;
