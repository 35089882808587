import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Container, Grid } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { Footer, InputField, Navbar } from "../../../components";
import { resetPassword } from "../../../redux/reducer/userReducer";

import { ErrorContainer } from "../../../components/Forms/Common/style";
import { AuthText, ContentWrapper, LoginButton, Wrapper } from "../style";
import useAlert from "../../../hooks/useAlert";

const NewPasswordComponent = () => {
  const {
    register, handleSubmit, errors, getValues,
  } = useForm();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { showAlert, Toast } = useAlert();
  const { loading } = useSelector((state) => state.user);

  const onRenderError = (error) => <ErrorContainer> {error && error.message} </ErrorContainer>;

  const onSubmit = (data) => {
    const { password } = data;
    const token = new URLSearchParams(location.search).get("token");
    const email = new URLSearchParams(location.search).get("email");

    dispatch(resetPassword({ password, email, token })).then((res) => {
      if (res.error) {
        showAlert({ severity: "error", message: res.payload });
        return;
      }
      history.push("/login");
    });
  };

  const width = "100%";
  const margin = "0 0 8px 0";
  const textAlign = "center";

  return (
    <div>
      <Navbar />
      <Toast float />
      <Container>
        <ContentWrapper>
          <Wrapper>
            <AuthText>Enter new password</AuthText>
            <form onSubmit={handleSubmit(onSubmit)} style={{ textAlign, marginTop: "30px" }}>
              <InputField
                type="password"
                label="Password"
                name="password"
                style={{ width, margin }}
                inputRef={register({
                  required:
                    "Password must contain at least (1) lower case, (1) capital letter, (1) numeric number and a minimum of 8 characters",
                  pattern: {
                    value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                    message:
                      "Password must contain at least (1) lower case, (1) capital letter, (1) numeric number and a minimum of 8 characters",
                  },
                })}
              />
              {onRenderError(errors.password1)}
              <InputField
                type="password"
                label="Confirm Password"
                name="password2"
                style={{ width, margin }}
                inputRef={register({
                  validate: (value) => {
                    if (value === getValues().password) {
                      return true;
                    }
                    return "The passwords do not match";
                  },
                })}
              />
              {onRenderError(errors.password2)}
              <Grid item xs={12}>
                <LoginButton disabled={loading === "pending"} type="submit" style={{ width, marginTop: "30px" }}>
                  {loading === "pending" ? "Please Wait..." : "Save New Password"}
                </LoginButton>
              </Grid>
            </form>
          </Wrapper>
        </ContentWrapper>
      </Container>
      <Footer />
    </div>
  );
};
export default NewPasswordComponent;
