import styled from "styled-components";

const IMG_SIZE = 40;

export const NotificationWrapper = styled.div`
   //display: grid;
   //grid-template-columns: ${IMG_SIZE}px auto;
   //grid-column-gap: 15px;
`;

export const NotificationAvatar = styled.img`
  width: ${IMG_SIZE}px;
  height: ${IMG_SIZE}px;
  border-radius: 50%;
`;

export const NotificationBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const NotificationTitle = styled.h3`
  color: #1E2B37;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
`;

export const NotificationDescription = styled.span`
  color: #5F7483;
  font-size: 14px;
  letter-spacing: 0;
  margin-right: 5px;
  display: inline-block;
  
  a {
    color: #00B0F1
  }
`;

export const NotificationTime = styled.span`
  color: #8E9FAC;
  font-size: 14px;
  letter-spacing: 0;
`;
