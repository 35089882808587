import React from "react";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { Box } from "@material-ui/core";
import {
  CircularProgressTextLabel,
  CircularProgressTextValue,
  CheckEntryCheckCircleSharpIcon,
  CheckEntryContentWrapper,
  CheckEntryErrorIcon,
  CheckEntryText,
  CheckEntriesContainer,
} from "./style";

const CircularProgressBar = ({ completedForm = [], textValues, totalItems = 1 }) => {
  console.log("(completedForm.length / totalItems) * 100 ", completedForm, (completedForm.length / totalItems) * 100);
  const CheckEntryContent = (entryText, index) => (
    <CheckEntryContentWrapper key={index}>
      {completedForm.includes(index) ? <CheckEntryCheckCircleSharpIcon /> : <CheckEntryErrorIcon />}
      <CheckEntryText>{entryText}</CheckEntryText>
    </CheckEntryContentWrapper>
  );
  return (
    <CheckEntriesContainer>
      <Box>
        <CircularProgressbarWithChildren value={(completedForm.length / totalItems) * 100}>
          <CircularProgressTextValue>{`${((completedForm.length / totalItems) * 100).toFixed(2)}%`}</CircularProgressTextValue>
          <CircularProgressTextLabel>Completed</CircularProgressTextLabel>
        </CircularProgressbarWithChildren>
      </Box>
      <Box style={{ marginTop: "50px" }}>
        {Boolean(textValues.length) && textValues.map((eachText, index) => CheckEntryContent(eachText, index))}
      </Box>
    </CheckEntriesContainer>
  );
};

export default CircularProgressBar;
