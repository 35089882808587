import styled from "styled-components";
import { Carousel } from "react-responsive-carousel";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { RiHandCoinLine } from "react-icons/ri";
import { FaUserAlt } from "react-icons/fa";

import { OpenQuoteIcon, CloseQuoteIcon } from "../About/style";
import { SaveButton } from "../../../components/Forms/Common/style";
import { theme, FormTitle } from "../../../util/theme";
import { Link } from "react-router-dom";

export const VendorLoginButton = styled(SaveButton)`
  height: 41px;
  width: 151px;
  margin: 0 !important;
`;

export const BackgroundOverlay = styled.div`
  text-align: left;
  background-color: rgba(0, 0, 0, 0.71);
  display: flex;
  align-items: center;
  height: 100%;
`;

export const BackgroundCarousel = styled(Carousel)`
  .carousel .slide {
    background: transparent;
  }

  .carousel .carousel-status {
    display: none;
  }

  .carousel .control-dots {
    padding: 0 7%;
    text-align: ${(props) => props.alignDots || "left"};
    margin-bottom: ${(props) => props.mb || "50px"};
  }

  @media screen and (min-width: 1024px) {
    .carousel .control-dots {
      padding: 0 2%;
    }
  }

  @media screen and (min-width: 1224px) {
    .carousel .control-dots {
      padding: 0 6.8%;
    }
  }

  @media screen and (min-width: 1500px) {
    .carousel .control-dots {
      padding: 0 17.5%;
    }
  }

  @media screen and (min-width: 2500px) {
    .carousel .control-dots {
      padding: 0 25.8%;
    }
  }

  .carousel .control-dots .dot {
    width: 11px;
    height: 11px;
    opacity: 1;
  }

  .carousel.carousel-slider .control-arrow {
    top: 42%;
    color: #fff;
    font-size: 26px;
    margin-top: 0;
    padding: 5px 1px;
    border-radius: 50%;
    height: 30p;
    height: 40px;
    width: 40px;
    background-color: #fff;
    opacity: 1;
  }

  .carousel .control-next.control-arrow:before {
    border-left: 8px solid #000;
    margin: 3px 4px;
  }

  .carousel .control-prev.control-arrow:before {
    border-right: 8px solid #000;
    margin: 3px 4px;
  }

  .carousel .control-dots .dot.selected {
    background-color: ${theme.color.primary};
  }

  .carousel .control-prev.control-arrow {
    left: 2%;
  }

  .carousel .control-next.control-arrow {
    right: 2%;
  }
`;

export const SliderContent = styled.div`
  height: 540px;
`;

export const NewsSectionSliderContent = styled.div`
  min-height: 100px;
  margin: 20px 0;
  text-align: left;
`;

export const RolesAndResponsibilitySectionSliderContent = styled.div`
  min-height: calc(100% + 50px);
  text-align: left;
`;

export const Hero = styled.div`
  background-image: url("https://cdn8.dissolve.com/p/D2115_213_592/D2115_213_592_1200.jpg");
  background-repeat: no-repeat;
  background-size: cover;
`;

export const WelcomeText = styled(Typography)`
  color: #ffffff;
  font-size: 40px !important;
  font-weight: bold !important;
  letter-spacing: 0;
  line-height: 54px !important;
`;

export const LandingDividerLine = styled.hr`
  margin: 30px 0;
  box-sizing: border-box;
  width: 72px;
  border: 2px solid #ffffff;
`;

export const LandingInfo = styled(Typography)`
  color: #ffffff;
  width: 482px;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
  margin-bottom: 30px !important;
`;

export const VendorRegistrationButton = styled(SaveButton)`
  margin: 0 !important;
`;

export const NewsSection = styled.section`
  background-color: #f2f5f7;
  margin-bottom: 70px;
`;

export const NewsContainer = styled(Grid)`
  height: 100%;
  display: flex;
  align-items: center;
`;

export const LatestUpdateContainer = styled(Grid)`
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const UpdateText = styled(Typography)`
  color: #274472;
  font-size: 16px !important;
  font-weight: bold !important;
  letter-spacing: 0;
  line-height: 22px !important;
`;

export const NewsContent = styled(LatestUpdateContainer)`
  cursor: pointer;

  &:hover {
    background-color: ${theme.color.primary};
  }

  &:hover p,
  &:hover span {
    color: #fff !important;
  }

  p,
  span {
    color: #212b36;
    font-size: 14px !important;
    font-weight: 600 !important;
    letter-spacing: 0;
    line-height: 19px !important;
    margin-bottom: 10px;
  }

  span {
    margin: 0;
  }
`;

export const WelcomeSpeechSection = styled.section`
  display: flex;
  position: relative;
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

export const WelcomeSpeechImage = styled.div`
  position: absolute;
  left: 0;
  width: 45%;

  @media screen and (max-width: 600px) {
    width: 100%;
    position: relative;
  }

  img {
    width: 100%;
    height: 460px;

    @media screen and (max-width: 600px) {
      flex-direction: column;
      height: auto;
    }
  }
`;

export const WelcomeSpeechContainerContent = styled.div`
  background-color: rgba(50, 197, 255, 0.06);
  width: 65%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 80px 0;
  margin: 42px 0px 42px auto;

  @media screen and (max-width: 600px) {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  > div {
    > p,
    span {
      color: #274472;
      font-size: 18px !important;
      font-weight: 600 !important;
      letter-spacing: 0;
      line-height: 25px !important;
      margin-left: 52px;
    }
    span {
      color: #637381;
      font-size: 14px !important;
    }
  }
`;

export const OpenQuote = styled(OpenQuoteIcon)`
  height: 150px;
  width: 54px;
  color: #03adeb;
  font-size: 130px !important;
  font-weight: 600 !important;
  letter-spacing: 0 !important;
  position: relative !important;
  top: 0;
  left: -10px;
`;

export const CloseQuote = styled(CloseQuoteIcon)`
  position: relative !important;
  margin-top: 7rem;
  bottom: -15px;
  right: 0;
`;

export const WelcomeSpeechText = styled(Typography)`
  width: 482px;
  color: #274472;
  font-size: 20px !important;
  font-weight: 600 !important;
  letter-spacing: 0 !important;
  line-height: 30px !important;
  margin-top: 4rem !important;

  @media screen and (max-width: 1140px) {
    width: 382px;
  }
  @media screen and (max-width: 783px) {
    width: 282px;
  }

  @media screen and (max-width: 600px) {
    width: 100%;
    margin-bottom: 4rem !important;
  }
`;

export const AboutUsSection = styled.section`
  margin: 28px 0 70px;
`;

export const AboutUsContainer = styled(Grid)`
  height: 100%;
`;

export const AboutUsContent = styled(Grid)``;

export const AboutUsContentContainer = styled(Grid)`
  padding: 50px 100px 50px 0;
  @media screen and (max-width: 600px) {
    padding: 24px 12px !important;
  }
`;

export const CompanyDetailsContainer = styled(Grid)`
  color: #ffffff;
  background-color: #274472;
  padding: 50px;

  @media screen and (max-width: 600px) {
    padding: 24px 12px !important;
  }

  div > div {
    width: 73%;
    @media screen and (max-width: 600px) {
      width: 100%;
    }

    :first-child {
      margin-bottom: 40px;
      width: 60%;
      @media screen and (max-width: 600px) {
        width: 100%;
      }
    }
  }

  p {
    margin: 20px 0 15px;
  }

  p,
  span {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
  }

  span {
    font-size: 14px;
    font-weight: 400;
  }

  svg {
    height: 30px;
    width: 32.73px;
  }
`;

export const LandingTitleText = styled(FormTitle)`
  color: #034962;
  margin: 0 !important;
`;

export const SeparatorLine = styled(LandingDividerLine)`
  box-sizing: border-box;
  width: 72px;
  border: 2px solid #034962;
  margin: 20px 0 35px;
`;

export const LandingComment = styled(Typography)`
  color: #212b36;
  font-size: 14px !important;
  letter-spacing: 0;
  line-height: 24px !important;
  margin: 0 15px 20px 0 !important;
`;

export const WelcomeTextContainer = styled.div`
  display: flex;
`;

export const HandIcon = styled(RiHandCoinLine)`
  color: #fff;
`;

export const RoleContentCard = styled.div`
  box-sizing: border-box;
  border: 1px solid #cbd4da;
  border-radius: 3px;
  background-color: #ffffff;
  cursor: pointer;
  padding: 30.5px 38px 30.5px 20px;

  & svg {
    color: #274472;
    height: 30px;
    width: 32.73px;
  }

  &:hover {
    background-color: ${theme.color.primary};
    color: ${theme.color.white};
  }

  &:hover svg {
    color: ${theme.color.white} !important;
  }

  &:hover p {
    color: ${theme.color.white} !important;
  }
`;

export const RoleContentCardTitle = styled(Typography)`
  color: #274472 !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  letter-spacing: 0;
  line-height: 24px !important;
  margin-top: 20px !important;
  margin-bottom: 15px !important;
`;

export const RoleContentCardFooter = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  color: #212b36;
  font-size: 14px !important;
  font-weight: 600 !important;
  letter-spacing: 0;
  line-height: 21px;
`;

export const ProjectInfoSectionWrapper = styled.section`
  // margin-bottom: 70px !important;
`;

export const ProjectInfoSection = styled.div`
  background-image: url("https://upload.wikimedia.org/wikipedia/commons/4/4f/Inle-Yawnghwe.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  margin: 5rem 0;
`;

export const ProjectInfoContainer = styled.div`
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const ProjectContent = styled(Grid)`
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const ProjectContentValue = styled(Typography)`
  color: #ffffff;
  font-size: 30px !important;
  font-weight: bold !important;
  letter-spacing: 0 !important;
  line-height: 40px !important;
`;

export const ProjectContentLabel = styled(ProjectContentValue)`
  font-size: 16px !important;
  line-height: 25px !important;
`;

export const RequestTenderWrapper = styled.div`
  margin-bottom: 70px !important;
`;

export const BlankSection = styled.section`
  margin-bottom: 70px !important;
`;

export const BlankSectionWrapper = styled.section`
  height: 100%;
  background-color: #03adeb;
  text-align: center;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-weight: 600;
  p {
    font-size: 18px;
  }
  a {
    color: #03adeb !important;
    background: #fff;
    font-size: 14px;
    display: inline-block;
    width: max-content;
    margin-top: 20px;
    padding: 10px 20px;
    border-radius: 3px;
  }
`;

export const NewsUpdateSection = styled.section`
  margin-bottom: 70px !important;
`;

export const FlexContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ViewAllText = styled(Typography)`
  color: #03adeb;
  font-size: 16px !important;
  font-weight: bold !important;
  letter-spacing: 0 !important;
  line-height: 22px !important;
  cursor: pointer;
`;

export const NewsUpdateImageContentLarge = styled.div`
  background-image: url(${(props) => props.url});
  background-repeat: no-repeat;
  background-size: cover;
  height: 350px;
  border: 1px solid #cbd4da;
  border-radius: 3px;
  margin-bottom: 15px !important;
`;

export const NewsUpdateImageContentSmall = styled.div`
  background-image: url(${({
  bgimg = "https://i0.wp.com/media.premiumtimesng.com/wp-content/files/2020/06/Chiedu-Ebie.jpg?fit=860%2C573&ssl=1",
}) => bgimg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  min-height: 100px;
  max-height: 200px;
  border: 1px solid #cbd4da !important;
  border-radius: 3px 0 0 3px !important;
  flex: 0.35;

  @media screen and (max-width: 600px) {
    min-height: 200px;
    border: none;
  }
`;

export const NewsDateLabel = styled(Typography)`
  color: #99a2aa;
  font-size: 12px !important;
  letter-spacing: 0;
  line-height: 18px !important;
`;

export const NewsCaptionLarge = styled(Typography)`
  color: #274472;
  font-size: 18px !important;
  font-weight: 600 !important;
  letter-spacing: 0;
  line-height: 24px !important;
  margin-bottom: 10px !important;
`;

export const NewsCaptionSmall = styled(NewsCaptionLarge)`
  font-size: 16px !important;
`;

export const NewsClickCard = styled.div`
  text-decoration: none;
  box-sizing: border-box;
  height: 100%;
  border: 1px solid #cbd4da !important;
  border-radius: 3px;
  background-color: #ffffff !important;
  margin-bottom: 28px !important;
  display: flex;
  cursor: pointer;
`;

export const NewsCard = styled(Link)`
  text-decoration: none;
  box-sizing: border-box;
  height: 100%;
  border: 1px solid #cbd4da;
  border-radius: 3px;
  background-color: #ffffff !important;
  margin-bottom: 28px !important;
  display: flex;
  @media screen and (max-width: 600px) {
    border: none;
  }
`;

export const NewsCardWrapper = styled.div`
  padding-left: 23px;
  @media screen and (max-width: 600px) {
    padding-left: 0;
  }
`;

export const NewsSmallCardContent = styled.div`
  padding: 15px 26px;
  max-height: 150px;
  overflow: auto;
  flex: 0.65;

  @media screen and (max-width: 600px) {
    padding: 15px 0;
  }
`;

export const NewsComment = styled(LandingComment)`
  margin-bottom: 0 !important;
`;

export const CompletedProjectSection = styled.section`
  margin-bottom: 70px !important;
`;

export const CompletedProjectContainer = styled.div``;

export const CompletedProjectCards = styled.div`
  box-sizing: border-box;
  border: 1px solid #cbd4da;
  border-radius: 3px;
  background-color: #ffffff;
`;
export const CompletedProjectCardsContent = styled.div`
  padding: 16px;

  > p:last-child {
    width: 92%;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 14px;
  }
`;

export const CompletedProjectAvatar = styled(FaUserAlt)`
  margin-right: 5px;
  font-size: 10px;
`;

export const CompletedProjectAvatarText = styled(Typography)`
  color: #32c5ff;
  font-size: 12px !important;
  letter-spacing: 0 !important;
  line-height: 16px !important;
`;

export const CompletedProjectCaptionText = styled(NewsCaptionSmall)`
  margin-top: 13px !important;
  font-weight: bold !important;
`;

export const CompletedProjectImages = styled.div`
  background-image: url("https://prunedge.com/wp-content/uploads/2022/03/Rectangle-74-2.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 203px;
  width: 100%;
`;
