import styled, { css } from "styled-components";
import withStyles from "@material-ui/styles/withStyles";
import { SectionContent, Title as BaseTitle } from "../../style";
import MaterialDivider from "@material-ui/core/Divider";
import BaseDetailItem from "../../../../../components/DetailItem";

export const Wrapper = styled(SectionContent)`
  --primary: #1e2b37;
  --secondary: #637381;
  --divider: #cbd4da;
  ${({ type }) => {
    if (type === "RECOMMENDED")
      return css`
        --primary: #fff;
        --secondary: var(--primary);
        --divider: var(--primary);
        border-color: #03adeb;
        background-color: #03adeb;
      `;
  }};
`;

export const Divider = withStyles({
  root: {
    backgroundColor: "var(--divider)",
    marginTop: 20,
    marginBottom: 20,
  },
})(MaterialDivider);

export const Title = styled(BaseTitle)`
  color: var(--primary);
`;

export const Subtitle = styled.p`
  color: var(--primary);
  font-size: 14px;
  line-height: 19px;
`;

export const DetailItem = styled(BaseDetailItem)`
  .detail-item-label {
    color: var(--secondary);
  }
  .detail-item-text {
    color: var(--secondary);
  }
`;

const tagColors = {
  RECOMMENDED: { fg: "#108043", bg: "#D6EFD2" },
  REJECTED: { fg: "#BF6712", bg: "#FFE2C5" },
};

export const Tag = styled.span`
  padding: 3px 11px;
  border-radius: 11px;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;

  ${({ type }) => {
    const colors = tagColors[type];
    return css`
      background-color: ${colors?.bg ?? "#ccc"};
      color: ${colors?.fg ?? "#1E2B37"};
    `;
  }}
`;
