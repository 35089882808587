/**
 * This hook makes a request to the backend to get the user details, if the details isn't already in the cache and transorms it to match the role format defined in the Roles object.
 *  A role, user and isSucess can be destructed from this hook.
 **/
import { useQuery } from "react-query";
import userAPI from "../redux/api/usersAPI";
import { Roles } from "../components/AccessControl/UserRoles";

const useUserDetails = () => {
  const userDetailsQuery = useQuery({
    queryKey: "userDetails",
    queryFn: userAPI.getUser,
    config: {
      cacheTime: 3600 * 100,
      staleTime: 3600 * 100
    },
  });

  return {
    user: userDetailsQuery.data,
    isSuccess: userDetailsQuery.isSuccess,
    isError: userDetailsQuery.isError,
  };
};

export const useRoles = () => {
  const { user, isSuccess, isError } = useUserDetails();

  return {
    role: Roles[user?.role?.trim().toUpperCase().replace(/\s/g, "_")],
    user,
    isSuccess,
    isError,
  };
};

export default useUserDetails;
