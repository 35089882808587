import styled from "styled-components";
import Box from "@material-ui/core/Box";
import withStyles from "@material-ui/core/styles/withStyles";
import { CardTitle } from "../../style";

export const ContractStatusBadge = styled.div`
  border-radius: 3px;
  background-color: rgba(108, 207, 129, 0.2);
  padding: 5px 15px;
  color: #6ccf81;
  font-family: "SF Pro Text", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin-right: 10px;
`;

export const Subsection = withStyles(() => ({
  root: {
    border: "1px solid #C9D4DB",
    borderRadius: 3,
    backgroundColor: "#fff",
    padding: 20,
  },
}))(Box);

export const FirmName = styled.span`
  color: #03adeb;
`;

export const Subtitle = styled(CardTitle)`
  color: #637381;
  margin-bottom: 10px;
`;

export const Divider = styled.div`
  margin: 20px 0;
  width: 100%;
  height: 1px;
  background-color: #cbd4da;
`;
