import baseAxiosMethod from "./baseAxiosMethod";
import { handleAxiosError } from "./handleApiError";

const receiptOfBiddersResponseApi = {}

receiptOfBiddersResponseApi.evaluatedBids = async (key, {Status, PageSize, PageNumber, id, Name}) => {
  try{
    const response = await baseAxiosMethod(
      `procurements/${id}/evaluatedBids`,
      {
        params: {
          Status,
          PageNumber,
          PageSize,
          Name
        }
      }
    )

    const { data, meta: { pagination } } = response.data

    return { data, pagination }
  }catch (e) {
    throw new handleAxiosError(e)
  }
}


receiptOfBiddersResponseApi.getFormDetails = async (key, { vendorId, procurementId }) => {
  try{
    const [bidDocumentsResponse, formDetails] = await Promise.all([
      baseAxiosMethod(`procurements/${procurementId}/bidDocuments/${vendorId}`),
      baseAxiosMethod(`procurements/${procurementId}/evaluatedVendor/${vendorId}`)
    ])

    return {
      bidDocuments: bidDocumentsResponse.data.data,
      formData: formDetails.data.data
    }
  }catch (e) {
    throw new Error(handleAxiosError(e))
  }
}

receiptOfBiddersResponseApi.submitForm = async ({ formData, procurementId, vendorId }) => {
  try{
    await baseAxiosMethod.post(`procurements/${procurementId}/evaluateBid/${vendorId}`, formData)
  }catch (e) {
    throw new Error(handleAxiosError(e))
  }
}

receiptOfBiddersResponseApi.addRecommendation = async ({ remark, recommendation, procurementId, vendorId }) => {
  try {
    await baseAxiosMethod.post(`procurements/${procurementId}/addRecommendation/${vendorId}`, {
      remark,
      recommendation: Number(recommendation)
    })
  }catch (e) {
    const error = new Error(handleAxiosError(e))

    if (error.message === "A vendor has already been recommended") {
      error.message = JSON.stringify({
        message: error.message,
        data: e.response.data?.errors
      });
    } else {
      error.message = JSON.stringify({
        message: error.message
      });
    }

    throw error
  }
}

receiptOfBiddersResponseApi.sendForRecommendation = async ({ File, activityId }) => {
  try{
    const fd = new FormData()
    fd.set('File', File)
    fd.set('ObjectType', 1)

    await baseAxiosMethod.post(`procurements/${activityId}/SendBidForRecommendation`, fd)
  }catch (e) {
    throw new Error(handleAxiosError(e))
  }
}

receiptOfBiddersResponseApi.getApprovalDocument = async (key, { activityId }) => {
  try{
    const response = await baseAxiosMethod(`procurements/${activityId}/procurementPlanDocument`, {
      params: {
        objectType: 1
      }
    })  
    return response.data.data
  }catch (e) {
    throw new Error(handleAxiosError(e))
  }
}

receiptOfBiddersResponseApi.evaluationNeedsAmendment = async ({procurementId}) => {
  try{
    await baseAxiosMethod(`procurements/${procurementId}/ammendEvaluation`)
  }catch (e) {
    throw new Error(handleAxiosError(e))
  }
}

receiptOfBiddersResponseApi.approveEvaluations = async ({procurementId}) => {
  try{
    await baseAxiosMethod.post(`procurements/${procurementId}/approveEvaluation`)
  }catch (e) {
    throw new Error(handleAxiosError(e))
  }
}

receiptOfBiddersResponseApi.getExpiryDate = async (key, {TenderId}) => {
  try{
    const response = await baseAxiosMethod(`procurements/${TenderId}/bidExpiryDate`)
    return response.data.data.expiryDate
  }catch (e){
    throw new Error(handleAxiosError(e))
  }
}

receiptOfBiddersResponseApi.getPaymentStats = async (key, {TenderId}) => {
  try{
    const response = await baseAxiosMethod(`vendors/paymentproofs/stat`,{
        params: {    
        TenderId
      }
    })

    return response.data.data
  }catch (e){
    throw new Error(handleAxiosError(e))
  }
}

receiptOfBiddersResponseApi.getPayments = async (key, { PageNumber, PageSize, TenderId }) => {
  try{
    const response = await baseAxiosMethod(`vendors/paymentproofs`, {
      params: {
        PaymentType: 2,
        PageNumber,
        PageSize,
        TenderId
      }
    })

    const { data, meta: { pagination } } = response.data

    return { vendors: data, pagination }
  }catch (e){
    throw new Error(handleAxiosError(e))
  }
}

receiptOfBiddersResponseApi.approvePayment = async ({id}) => {
  try{
    await baseAxiosMethod.patch(`vendors/paymentproofs/${id}/approve`)
  }catch (e) {
    throw new Error(handleAxiosError(e))
  }
}

receiptOfBiddersResponseApi.updateSubmissionDeadline = async ({ id, data }) => {
  try {
    await baseAxiosMethod.patch(`procurements/${id}/submission-deadline`, data)
  } catch (e) {
    throw new Error(handleAxiosError(e))
  }
}

export default receiptOfBiddersResponseApi
