import React, { forwardRef, useImperativeHandle } from "react";
import { useParams } from "react-router-dom";
import ReviewCommentsSection from "../../../../../components/ReviewCommentsSection";

const ReviewTab = forwardRef(({ setTabIndex }, ref) => {
  const { activityId } = useParams();

  useImperativeHandle(ref, () => ({
    goBack() {
      setTabIndex(2);
    },
    handleSubmit() {
      return
    }
  }));

  return <ReviewCommentsSection planActivityId={activityId} />;
});

export default ReviewTab;
