import styled from "styled-components";

export const ApproveStatusWrapper = styled.div`
  background: ${(props) => props.pending === "true" ? '#FF8D26' : '#50B83C'};
  border: none;
  border-left: 1px solid #C9D4DB;
  border-radius: 0 3px 3px 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
