import React, { useState } from "react";
import { Controller } from "react-hook-form";
import DatePicker from "../../../DatePicker";
import InputField from "../../../InputField";
import { CloseIcon, TaskWrapper } from "../style";
import { ErrorContainer } from "../../Common/style";

const PlanningTask = ({ setStages, stages, isCustom, isMaker, control, errors, clearErrors, item, index }) => {
  const backgroundColor = "#FFFFFF";
  const [endDate, setEndDate] = useState(item.endDate ? new Date(item.endDate) : new Date());
  const [startDate, setStartDate] = useState(item.startDate ? new Date(item.startDate) : new Date());
  const onRenderError = (error) => <ErrorContainer>{error && error.message}</ErrorContainer>;

  return (
    <TaskWrapper key={item.id}>
      <div>
        <Controller
          as={InputField}
          name={`field${index}.title`}
          control={control}
          label="Stage Name"
          defaultValue={item.title}
          style={{
            backgroundColor,
            width: "376px",
            padding: "0",
          }}
          rules={{ required: "Please choose a process type" }}
          disabled={!isMaker}
        />
        {onRenderError(errors[`field${index}`]?.title)}
      </div>
      <div>
        <Controller
          autoOk
          name={`field${index}.startDate`}
          render={({ onChange }) => {
            const handleChange = (value) => {
              setStartDate(value);
              onChange(value);
            };
            return (
              <DatePicker
                label="Start Date"
                value={startDate}
                handleChange={handleChange}
                style={{ width: "100%", marginTop: 0, marginBottom: 0 }}
              />
            );
          }}
          control={control}
          defaultValue={startDate}
          rules={{
            required: "Please enter start date",
            validate: {
              endDateGreaterThanStartDate: (value) => {
                if (value < endDate) {
                  clearErrors(`field${index}.endDate`);
                } else {
                  return "Start date should be less than end date";
                }
              },
            },
          }}
          disabled={!isMaker}
        />
        {onRenderError(errors[`field${index}`]?.startDate)}
      </div>
      <div>
        <Controller
          autoOk
          name={`field${index}.endDate`}
          render={({ onChange }) => {
            const handleChange = (value) => {
              setEndDate(value);
              onChange(value);
            };
            return (
              <DatePicker
                label="End Date"
                handleChange={handleChange}
                value={endDate}
                style={{ width: "100%", marginTop: 0, marginBottom: 0 }}
              />
            );
          }}
          control={control}
          defaultValue={endDate}
          rules={{
            required: "Please enter end date",
            validate: {
              endDateGreaterThanStartDate: (value) => {
                if (startDate < value) {
                  clearErrors(`field${index}.startDate`);
                }
                if (value > startDate) {
                  clearErrors(`field${index}.endDate`);
                  return true;
                }
                return "End date should be greater than start date";
              },
            },
          }}
          disabled={!isMaker}
        />
        {onRenderError(errors[`field${index}`]?.endDate)}
      </div>
      {isCustom ? (
        <CloseIcon
          iconcolor="#637381"
          onClick={() => {
            if (!isCustom) return;
            setStages([...stages.slice(0, index), ...stages.slice(index + 1)]);
          }}
        />
      ) : null}
    </TaskWrapper>
  );
};

export default PlanningTask;
