/* eslint-disable no-undef */
/* eslint-disable no-alert */
import React, { useState } from "react";

import { ReactComponent as ErrorIcon } from "../../assets/feedback-error.svg";
import { ReactComponent as WarningIcon } from "../../assets/feedback-warning.svg";
import { ReactComponent as SuccessIcon } from "../../assets/feedback-success.svg";
import uploadTemplate from "../../assets/ministry_upload_template.csv"

import {
  Wrapper,
  FeedbackWrapper,
  FeedbackIconWrapper,
  FeedbackTitle,
  FeedbackBody,
  ErrorText,
  MessageContents,
  MessageContent,
  MessageContentTitle,
  MessageContentBody,
  SamplefileDownloadButton,
} from "./style";
import FileUploadField from "../FileUploadField";

const feedbackVariants = {
  error: {
    icon: <ErrorIcon />,
    style: {
      backgroundColor: "#fbeae5",
      paddingBottom: "22px",
    },
  },
  warning: {
    icon: <WarningIcon />,
    style: {
      backgroundColor: "#fcf1cd",
      paddingBottom: "30px",
    },
  },
  success: {
    icon: <SuccessIcon />,
    style: {
      backgroundColor: "#e3f1df",
      paddingBottom: "18px",
    },
  },
};

const BulkUpload = ({ RenderButton, isMinistry }) => {
  const [files, setFile] = useState([]);

  return (
    <form style={{ height: "100%" }}>
      <Wrapper>
        <div>
          <FileUploadField filesNames={files} setFilesNames={setFile} />
          <SamplefileDownloadButton >           
            <a
            href={uploadTemplate}
            download="ministry_upload_template.csv"           
          >
            Click here to download sample file
          </a>
          </SamplefileDownloadButton>

          <Feedback
            variant="error"
            title="Wrong format"
            content={<ErrorText>Kindly download and use the format provided</ErrorText>}
          />
          <Feedback
            variant="warning"
            title="Cells with errors"
            content={
              <MessageContents>
                <MessageContent>
                  <MessageContentTitle>Rows</MessageContentTitle>
                  <MessageContentBody>5,6,8,10,34</MessageContentBody>
                </MessageContent>
                <MessageContent>
                  <MessageContentTitle>Insight</MessageContentTitle>
                  <MessageContentBody>Invalid serial, Invalid amount</MessageContentBody>
                </MessageContent>
              </MessageContents>
            }
          />
          <Feedback
            variant="success"
            title="File uploaded successfully"
            content={
              <MessageContents>
                <MessageContent>
                  <MessageContentTitle>Number of entries</MessageContentTitle>
                  <MessageContentBody>25</MessageContentBody>
                </MessageContent>
                <MessageContent>
                  <MessageContentTitle>Uploaded</MessageContentTitle>
                  <MessageContentBody>20</MessageContentBody>
                </MessageContent>
              </MessageContents>
            }
          />
        </div>
        {!isMinistry ? <RenderButton TextLabel="Send Invite" /> : <RenderButton TextLabel="Done" />}
      </Wrapper>
    </form>
  );
};

const Feedback = ({ variant, title, content }) => (
  <FeedbackWrapper
    paddingBottom={feedbackVariants[variant].style.paddingBottom}
    backgroundColor={feedbackVariants[variant].style.backgroundColor}
  >
    <FeedbackIconWrapper>{feedbackVariants[variant].icon}</FeedbackIconWrapper>
    <FeedbackBody>
      <FeedbackTitle>{title}</FeedbackTitle>
      {content}
    </FeedbackBody>
  </FeedbackWrapper>
);

export default BulkUpload;
