import React, { useState, forwardRef, useImperativeHandle, Fragment } from "react";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { AddButton, CirclePlusIconWrapper } from "../../../../Common/style";
import InputField from "../../../../../components/InputField";
import DropdownField from "../../../../../components/DropdownField";
import {
  TabContent,
  TabContentTitle,
  TabContentBox,
  EmptyContractState,
  ContractListContainer,
  ContractListItem,
  EvaluatedContractContainer,
  VendorInfo,
  VendorInput,
  VendorOptions,
  InputDivider,
} from "./style";
import { SaveButton } from "../../../../../components/NavigationButtons/style";
import AddContractModal from "./EmptyTableBody/AddContractModal";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import contractsAPI from "../../../../../redux/api/contractAPI";
import procurementAPI from "../../../../../redux/api/procurementAPI";
import Loader from "../../../../../components/Common/Loader";
import { Grid, Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import { useEffect } from "react";



const currency = ["Naira", "Dollar", "Euro"];
const options = ["Recommended", "Evaluated Firm(s)", "Rejected Firm(s)"];
const reasons = ["recommendation", "evaluation", "rejection"];
const bidPriceList = [20, 50, 100, 200, 500, 1000, 2000];

const ContractTab = forwardRef(({ showAlert, setTabIndex, isDisabled, isMaker }, ref) => {
  const [evaluatedIndex, setEvaluatedIndex] = useState(null);
  const [contracts, setContracts] = useState([]);
  const [radioValue, setRadioValue] = useState(1)
  const [openModal, setOpenModal] = useState(false);
  const [contractId, setContractId] = useState("");
  const [currentContract, setCurrentContract] = useState({});
  const [contractDetails, setcontractDetails] = useState({});
  const [vendorReview, setVendorReview] = useState({
    vendorId: "",
    reason: "",
    position: "",
    currency: "",
    amount: "",
    bidPrice: "",
    evaluatedPrice: "",
    type: "",
  });
  const { procurementId } = useParams();
  const [reviewVendor, { isLoading }] = useMutation(contractsAPI.reviewVendor, {
    onSuccess: () => {
      showAlert({
        severity: "success",
        message: "Review sent Successfully",
      });
    },
      onError: () => {
      showAlert({ severity: "error", message: "something went wrong" });
    },
  });

  const handleRadioChange = (input) => (event) => {
    setRadioValue(event.target.value);
    setVendorReview({
      ...vendorReview,
      [input]: event.target.value,
    });
  };

  const { data: vendors = []} = useQuery(
    ["getVendors", procurementId],
    procurementAPI.getAllInterestedVendors
  );
  const updateFormValue = (input) => (e) => {
    setVendorReview({
      ...vendorReview,
      [input]: e.target?.value || e,
    });
  };

  const { data: awardedContracts, isLoading: contractsLoading, status } = useQuery(
    ["getContracts", procurementId],
    procurementAPI.getAwardedContracts
  );

  useEffect(() => {
     if(status === "success"){
      const selectedContract = awardedContracts.filter((x) => x.id === contractId)
      setCurrentContract(selectedContract);
     }
    
  }, [status, contractId, awardedContracts])

  const handleEvaluatedIndex = (index) => () => {
    setEvaluatedIndex(index);
  };

  const handleContractClick = (index, id) => {
    setEvaluatedIndex(index);
    setContractId(id)
  };

  const handleContractInput = name => (value) => {
    const list = [...contracts];
    if(list[evaluatedIndex]){
      list[evaluatedIndex][name] = value;
    }
    setContracts(list);
  };

  const handleSubmitVendorReport = (id) => {
   reviewVendor({ ...vendorReview, procurementId, vendorId : id });
  };


  useImperativeHandle(ref, () => ({
    async handleSubmit() {
      setTabIndex(3);
    },
    goBack() {
      setTabIndex(1);
    }
  }));

  return (
    <div>
      <AddContractModal showAlert={showAlert} open={openModal} setModal={setOpenModal} setContracts={setcontractDetails}/>
      {contractsLoading ? (
        <Loader />
      ) : (
        <Fragment>
          {awardedContracts.length || contractDetails.length ? (
            <Fragment>
              {(!!awardedContracts.length ||  !!contractDetails.length) && evaluatedIndex === null && (
                <ContractListContainer>
                  <TabContentTitle>List</TabContentTitle>
                  {awardedContracts.map((contract, contractIndex) => (
                    <ContractListItem key={contractIndex} onClick={() => handleContractClick(contractIndex, contract.id)}>
                      <FiberManualRecordIcon className="contract-indicator" />
                      <div className="contract-box">
                        <div className="contract-info">
                          <div>{contract.title}</div>
                          <div className="light-grey">{contract.description}</div>
                          <div className="contract-meta">
                            <div>
                              <DateRangeIcon className="duration-icon" />
                              <span className="light-grey">Duration: </span>
                              {contract.duration} {contract.type}
                              {+contract.duration > 1 && "s"} |
                            </div>
                            <div>
                              <span className="light-grey">Currency: </span>
                              {contract.currency}
                            </div>
                          </div>
                        </div>
                        <ArrowForwardIosIcon className="arrow-forward" />
                      </div>
                    </ContractListItem>
                  ))}
                </ContractListContainer>
              )}
            </Fragment>
          ) : (
            <EmptyContractState>
              <div className="wrapper">
                <div className="main-text">No available Contract</div>
                <div className="sub-text">Currently there are NO contracts created. Please ADD a Contract to get startetd.</div>
                <div className="button-box">
                  <AddButton
                    onClick={() => setOpenModal(true)}
                    style={{
                      width: "250px",
                      justifyContent: "center",
                    }}
                  >
                    <CirclePlusIconWrapper />
                    <span> Create contract</span>
                  </AddButton>
                </div>
              </div>
            </EmptyContractState>
          )}
        </Fragment>
      )}



      <TabContent>
        {contractsLoading ? (
          <></>
        ) : (
          <Fragment>
            {!!currentContract.length && evaluatedIndex !== null && (
              <EvaluatedContractContainer>
                {currentContract.map((current,index) => (
                  <Fragment key={index}>
                  <TabContentTitle>
                  <span onClick={handleEvaluatedIndex(null)}>List</span>
                  <span className="light-grey"> / {current.title}</span>
                </TabContentTitle>
                <TabContentBox>
                  <div>
                    <InputField
                      disabled
                      label="Description"
                      name="description"
                      value={current.description}
                      defaultValue={current.description}
                      onChange={handleContractInput}
                      multiline
                      rowsMax={4}
                      style={{ width: "100%", background: "#ffffff" }}
                    />
                  </div>
                  <div className="contract-meta-input">
                  <InputField
                  disabled
                  label="Duration"
                  name="duration"
                  value={current.duration}
                  defaultValue={current.duration}
                  onChange={handleContractInput}
                  style={{ maxWidth: "150px", background: "#ffffff" }}
                />
                <InputField
                  disabled
                  name="type"
                  label="Type"
                  value={current.type}
                  defaultValue={current.type}
                  onChange={handleContractInput}
                  style={{ maxWidth: "150px", background: "#ffffff" }}
                />
                <InputField
                  disabled
                  name="currency"
                  label="currency"
                  value={current.evaluationCurrency}
                  defaultValue={current.evaluationCurrency}
                  onChange={handleContractInput}
                  style={{ maxWidth: "150px", background: "#ffffff" }}
                />
                  </div>
                </TabContentBox>
                  </Fragment>
                ))}
               
                {vendors ? (
                  <Fragment>
                  {vendors.map((vendor, vendorIndex) => (
                    <div key={vendorIndex}>
                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                      <VendorInfo>
                        <FiberManualRecordIcon className="vendor-indicator"/>
                        <div className="vendor-detail">
                          <div>
                            <div className="vendor-name">{vendor.companyName}</div>
                            <div className="vendor-registry-id">{`Registry ID: ${vendor.cacRegistrationNumber}`}</div>
                          </div>
                          <div className="vendor-info-icons">
                          </div>
                        </div>
                      </VendorInfo>
                      </AccordionSummary>
                      <AccordionDetails>
                        <VendorInput>
                        <Grid container justify="space-between" alignItems="flex-end">
                          <Grid item xs={10}>
                          <VendorOptions>
                          <FormLabel>Type</FormLabel>
                          <RadioGroup
                            aria-label="vendor-options"
                            name="type"
                            value={radioValue}
                            onChange={handleRadioChange("type")}
                            disabled={isDisabled}
                          >
                            {options.map((option, i) => (
                              <FormControlLabel key={i} value={`${i + 1}`} control={<Radio size="small" />} label={option}  disabled={isDisabled} />
                            ))}
                          </RadioGroup>
                          </VendorOptions>
                          </Grid>
                          <Grid item xs={2}>
                          <InputField
                           type="number"
                           label="Postion"
                           name="position"
                           value={vendor.position}
                           defaultValue={undefined}
                           style={{ maxWidth: "110px", background: "#ffffff" }}
                           onChange={updateFormValue("position")}
                           disabled={isDisabled}
                          />
                          </Grid>
                        </Grid>
                        <div>
                          <div className="vendor-meta-input">
                            <InputField
                              label={`Reason for ${reasons[radioValue - 1]}`}
                              name="reason"
                              multiline
                              rowsMax={4}
                              value={vendor.reason}
                              defaultValue={undefined}
                              style={{ width: "100%" }}
                              onChange={updateFormValue("reason")}
                              disabled={isDisabled}
                            />
                          </div>
                          <div className="vendor-meta-input">
                            <DropdownField
                              label="Currency"
                              name="currency"
                              onChange={(v) => updateFormValue("currency")(v)}
                              value={vendor.currency}
                              defaultValue={undefined}
                              style={{ width: "50%" }}
                              values={["Select a currency", ...currency].map((c) => ({ label: c, value: c }))}
                              disabled={isDisabled}
                            />
                            <InputField
                              type="number"
                              label="Amount"
                              name="amount"
                              value={vendor.amount}
                              defaultValue={undefined}
                              style={{ width: "50%" }}
                              onChange={updateFormValue("amount")}
                              disabled={isDisabled}
                            />
                          </div>
                          <InputDivider />
                          <div className="vendor-meta-input">
                            <DropdownField
                              label="Bid price at open"
                              name="bidPrice"
                              onChange={(v) => updateFormValue("bidPrice")(v)}
                              value={vendor.bidPrice}
                              defaultValue={undefined}
                              style={{ width: "50%" }}
                              values={["Select bid opening price", ...bidPriceList].map((b) => ({ label: b, value: b }))}
                              disabled={isDisabled}
                            />
                            <InputField
                              type="number"
                              label="Evaluated price"
                              name="evaluatedPrice"
                              value={vendor.evaluatedPrice}
                              defaultValue={undefined}
                              style={{ width: "50%" }}
                              onChange={updateFormValue("evaluatedPrice")}
                              disabled={isDisabled}
                            />
                          </div>
                          {isMaker && (
                          <SaveButton onClick={() => handleSubmitVendorReport(`${vendor.userId}`)}>{isLoading ? "Saving..." : "Save"}</SaveButton>
                          )}
                        </div>
                      </VendorInput>
                      </AccordionDetails>
                      </Accordion>
                    </div>
                  ))}
                </Fragment>
                ) : (
                  <Loader />
                )}
              </EvaluatedContractContainer>
            )}
          </Fragment>
        )}
      </TabContent>
    </div>
  );
});

export default ContractTab;
