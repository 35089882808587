import withAdminDashboard from "../../../HOC/withAdminDashboard";
import contractAwardDecisionApi from "../../../../../redux/api/contractAwardDecisionApi";
import withActivityLayout from "../../../../../components/Common/HOC/withActivityLayout";

const ContractAwardDecision = () => {
  return (
    withActivityLayout()({
      dataSheet: true,
      datasheetConfig: {
        label: "Date Issued",
        itemKey: "issuedDate"
      },
      title: "Contract Award Decision",
      extraCrumbs: [
        {
          url: "#",
          title: "Contract Award Decision"
        }
      ],
      getAPI: contractAwardDecisionApi.getDecisionDetails,
      postAPI: contractAwardDecisionApi.submitContract
    })
  );
};

export default withAdminDashboard(ContractAwardDecision);
