import styled from "styled-components";
import { Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

export const ModalContent = styled.div`
  box-sizing: border-box;
  border: 1px solid #979797;
  border-radius: 3px;
  background-color: #FFFFFF;
  padding: 30px;
`;

export const MilestoneTitleText = styled(Typography)`
  color: #5F7383;
  font-size: 20px !important;
  font-weight: bold !important;
  letter-spacing: 0;
  line-height: 27px !important;
`;

export const MilestoneCloseIcon = styled(CloseIcon)`
  cursor: pointer;
`;

export const InvoiceInfo = styled(Typography)`
  color: #3F5673;
  font-size: 16px !important;
  letter-spacing: 0;
  line-height: 22px !important;
  max-width: 391px;
`;

export const InvoiceInfoContainer = styled.div`
  background-color: #E4E9EC;
  padding: 18px;
  margin-top: 25px;
`;

export const MinistryAddress = styled(Typography)`
  color: #3F5673;
  font-size: 14px !important;
  font-weight: 600 !important;
  letter-spacing: 0;
  line-height: 19px !important;
`;

export const MinistryAddressValue = styled(MinistryAddress)`
  font-size: 14px !important;
  font-weight: 400 !important;
  margin-bottom: 5px !important;
`;

export const InvoiceTableContainer = styled.div`
  margin-top: 30px !important;
  .MuiTable-root {
    min-width: 500px !important;
  }
`;

export const InvoiceFilesWrapper = styled.div`
  background: #F4FCFF;
  border: 1px solid #CBD4DA;
  border-radius: 3px;
  margin: 20px 0;
  padding: 20px
`

export const InvoiceFilesTitle = styled.p`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #1E2B37;
  margin-bottom: 16px;
`
