import styled from "styled-components";

export const CardWrapper = styled.div`
  box-sizing: border-box;
  height: 170px;
  width: 268px;
  border: 1px solid #c9d4db;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 #c9d4db;
  padding: 20px;
  cursor: pointer;
`;

export const CheckerDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CheckerTitle = styled.p`
  height: 24px;
  color: #212b36;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
`;

export const CheckerName = styled.p`
  height: 20px;
  color: #637381;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
`;

export const CheckerDescription = styled.div`
  height: 60px;
  width: 227px;
  color: #637381;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: 15px;
`;
