import styled from "styled-components";
import { Box } from "@material-ui/core";

export const Banner = styled(Box)`
    background: #FFEAD9;
    border-radius: 3px; 
    height: 59px; 
    width: 100%;
    display: flex;
    align-items: center; 
    margin-bottom: 20px;   
}
`;

export const BannerText = styled.div`    
    padding: 0px 20px;
    font-size: 16px;   
}
`;