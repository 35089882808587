import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { useLocation, Link, useParams } from "react-router-dom";
import { format, parseISO } from "date-fns";
import { useQuery } from "react-query";
import { ContentWrapper, TitleContainer } from "../../../style";
import { PageTitle, PageBreadcrumbs } from "../../../../../Common/style";
import withAdminDashboard from "../../../../HOC/withAdminDashboard";
import {
  ContractListItem,
  ContractListItemDetails,
  ContractStatusDot,
  ContractPrimaryText,
  ContractSecondaryText,
  ContractStatusBadge,
  TextLine,
  ContractListWrapper,
  ForwardIcon,
  CalendarIcon,
} from "./style";
import {
  CardWrapper,
  CardHeader,
  CardTitle,
  CardBody,
  CheckEntryContentWrapper,
  CheckEntryErrorIcon,
  CheckEntryCheckCircleSharpIcon,
  CheckEntryText,
  CheckEntryWrapper,
} from "../style";
import { planDetails } from "../../../Plans/mock";
import procurementAPI from "../../../../../../redux/api/procurementAPI";
import { getContractStatusLabel, getContractSignatureStatusLabel, isContractSigned } from "../../../../../../util/constants";
import Loader from "../../../../../../components/Common/Loader";
import useAlert from "../../../../../../hooks/useAlert";

function ProcurementAwardedContract() {
  const { pathname } = useLocation();
  const { procurementId: planId } = useParams();
  const { status, error, data: contracts } = useQuery(["getAwardedContracts", planId], procurementAPI.getAwardedContracts);
  const { Toast, showAlert } = useAlert();

  useEffect(() => {
    if (status === "error") {
      showAlert({
        severity: "error",
        message: error.message,
      });
    }
  }, [status, showAlert, error]);

  return (
    <ContentWrapper>
      <Toast float />
      <TitleContainer>
        <div>
          <PageTitle>Awarded Contract</PageTitle>
          <PageBreadcrumbs>
            Home / Proccurement plans / {planDetails.ministry} / {planDetails.name}
          </PageBreadcrumbs>
        </div>
      </TitleContainer>
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <CardWrapper>
            <ContractListWrapper>
              <CardHeader>
                <CardTitle>Contract</CardTitle>
              </CardHeader>
              <CardBody>
                {status === "loading" && <Loader />}
                {status === "success" &&
                  contracts.map((contract) => (
                    <ContractListItem component={Link} to={`${pathname}/${contract.id}`} key={contract.id}>
                      <ContractListItemDetails>
                        <TextLine spaced>
                          <ContractStatusDot aria-label={getContractStatusLabel(contract.status)} status={contract.status} />
                          <ContractPrimaryText>{contract.title}</ContractPrimaryText>
                          <ContractStatusBadge signatureStatus={contract.signatureStatus}>
                            {getContractSignatureStatusLabel(contract.signatureStatus)}
                          </ContractStatusBadge>
                        </TextLine>
                        <TextLine>
                          <ContractSecondaryText>{contract.procurementPlan.name}</ContractSecondaryText>
                        </TextLine>
                        <TextLine>
                          <ContractSecondaryText>Reference No:&nbsp;</ContractSecondaryText>
                          <ContractPrimaryText>{contract.contractNumber}</ContractPrimaryText>
                          <ContractSecondaryText>&nbsp;&nbsp;|&nbsp;&nbsp;</ContractSecondaryText>
                          <ContractSecondaryText>
                            <CalendarIcon />
                            <Box component="span" marginLeft={0.5}>
                              Signed Date:&nbsp;
                            </Box>
                          </ContractSecondaryText>
                          <ContractPrimaryText>
                            {isContractSigned(contract) ? format(parseISO(contract.signatureDate), "dd MMM, yyyy") : "N/A"}
                          </ContractPrimaryText>
                        </TextLine>
                        <TextLine>
                          <ContractPrimaryText>
                            {contract?.contractor?.companyName} ({contract?.contractor?.cacRegistrationNumber})
                          </ContractPrimaryText>
                        </TextLine>
                      </ContractListItemDetails>
                      <ForwardIcon />
                    </ContractListItem>
                  ))}
                {status === "success" && contracts.length === 0 && <p>No contracts found for the specified procurement.</p>}
              </CardBody>
            </ContractListWrapper>
          </CardWrapper>
        </Grid>
        <Grid item xs={3}>
          <CardWrapper>
            <CheckEntryWrapper>
              <CardBody noHeader>
                <CardTitle noBackground>Check Entries</CardTitle>
                <CheckEntryContentWrapper>
                  <CheckEntryCheckCircleSharpIcon />
                  <CheckEntryText>Signed Contract</CheckEntryText>
                </CheckEntryContentWrapper>
                <CheckEntryContentWrapper>
                  <CheckEntryErrorIcon />
                  <CheckEntryText>Datasheet</CheckEntryText>
                </CheckEntryContentWrapper>
              </CardBody>
            </CheckEntryWrapper>
          </CardWrapper>
        </Grid>
      </Grid>
    </ContentWrapper>
  );
}

export default withAdminDashboard(ProcurementAwardedContract);
