import React from "react";
import { Grid, Box } from "@material-ui/core";
import FileDownload from "../../../../components/FileDownload";
import { transformFileDownload } from "../../../../util/transforms/contract";
import Loader from "../../../../components/Common/Loader";
import { MainTitle, TopItems } from "../../../Admin/Contracts/SingleContractDetails/style";

const ContractDocumentSection = ({ contractInfo, title = "Contract Document(s)" }) => {
  return (
    <div>
      {!contractInfo?.isLoading && (
        <div>
          <Box display="flex">
            <MainTitle style={{ marginBottom: "10px" }}>{title}</MainTitle>
          </Box>
          {!contractInfo?.data?.documents?.length && (
            <Box display="flex" width="100%" height="100px">
              <TopItems>No Documents Available</TopItems>
            </Box>
          )}
          <Grid container spacing={2}>
            {transformFileDownload(contractInfo?.data && contractInfo?.data?.documents)?.map((item) => (
              <Grid key={item.id} item xs={4}>
                <FileDownload lightTheme name={item.name} size={item.size} url={item.url} />
              </Grid>
            ))}
          </Grid>
        </div>
      )}
      {contractInfo?.isLoading && <Loader />}
    </div>
  );
};

export default ContractDocumentSection;
