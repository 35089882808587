import React from "react";
import { Chip, Box, IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { useHistory } from "react-router-dom";
import { EmptyTableSubTitle } from "../../../../components/EmptyTableBody/style";
import { StyledTableCell } from "../../../../components/SearchTable";
import { Url, VendorTableStyle } from "../style";
import { theme } from "../../../../util/theme";
import EmptyTableBody from "../../../../components/EmptyTableBody";

const statusColors = {
  PENDING: theme.color.tags.pending,
  APPROVED: theme.color.tags.approved,
  REJECTED: theme.color.tags.rejected,
  BLACKLIST: theme.color.tags.blacklist,
};

const VendorTable = ({ toggleDrawer, vendors, setVendorToRemove, isDSPPC }) => {
  const history = useHistory();

  const deleteVendor = (event, vendor) => {
    event.stopPropagation();
    setVendorToRemove(vendor);
  };
  return vendors.length ? (
    vendors.map((row, index) => (
      <VendorTableStyle key={`${row.id}`} onClick={() => history.push(`/admin/vendorsList/${row.id}`)}>
        <StyledTableCell component="th" scope="row">
          <Box display="flex" alignItems="center">
            <p>{row?.vendorProfile?.companyName}</p>
            {row.isSeeded && (
              <Chip
                label="Seeded"
                style={{
                  background: theme.color.primaryLight, // statusColors[row.vendorProfile?.status].background || statusColors.BLACKLIST.background,
                  color: theme.color.primary,
                  fontWeight: 400,
                }}
              />
            )}
          </Box>
        </StyledTableCell>
        <StyledTableCell>
          <Chip
            label={row.vendorProfile?.status || "UNKNOWN"}
            style={{
              background: statusColors[row.vendorProfile?.status].background || statusColors.BLACKLIST.background,
              height: "24px",
              color: statusColors[row.vendorProfile?.status].text,
              minWidth: "100px",
            }}
          />
        </StyledTableCell>
        <StyledTableCell>{row.vendorProfile?.registrationPlan?.title}</StyledTableCell>
        <StyledTableCell>
          {row.vendorProfile?.state}, {row.vendorProfile?.country || "Nigeria"}
        </StyledTableCell>
        <StyledTableCell>
          <Url href="#">{row.vendorProfile?.website}</Url>
        </StyledTableCell>
        {isDSPPC && (
          <StyledTableCell>
            <IconButton aria-label="Delete vendor" onClick={(event) => deleteVendor(event, row)}>
              <DeleteIcon
                style={{
                  color: "#ccc",
                  cursor: "pointer",
                }}
              />
            </IconButton>
          </StyledTableCell>
        )}
      </VendorTableStyle>
    ))
  ) : (
    <EmptyTableBody>
      <EmptyTableSubTitle>No vendor registered yet</EmptyTableSubTitle>
    </EmptyTableBody>
  );
};

export default VendorTable;
