import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import { Menu } from "@material-ui/core";

import { FormSubtitle, theme } from "../../util/theme";
import { SaveButton } from "../Forms/Common/style";
import { ReactComponent as DotIcon } from "../../assets/dot.svg";

export const PendingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 9px;
  padding: 0 25.5px !important;
  border-bottom: 1px solid #DFE3E8;
  padding-bottom: 7px !important;
`;

export const PendingInfo = styled(FormSubtitle)`
  font-size: 14px !important;
  letter-spacing: 0;
  line-height: 20px;
`;

export const PendingValue = styled(FormSubtitle)`
  font-size: 20px !important;
  letter-spacing: 0;
  line-height: 28px;
  font-weight: 600 !important;
`;

export const PendingInfoContainer = styled.div`
  height: 48px;
`;

export const PendingButtonContainer = styled.div`
  display: flex;
  height: 48px;
`;

export const PendingButton = styled(SaveButton)`
  border: 1px solid #EBEBEB !important;
  border-radius: 3px;
  background-color: #637381 !important;
`;

export const PendingList = styled.div`
  padding: 20px;
  min-height: 600px;
  margin-bottom: 71px;
`;

export const PendingListItemContainer = styled.div`
  background-color: #F4F6F8;
  height: 70px;
  padding: 5px 13px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
  position: relative;

  .MuiCheckbox-colorSecondary.Mui-checked {
    color: ${theme.color.primary};
  }

  .MuiMenu-paper {
    box-shadow: 0 0 5px #e4e4e4;
  }
`;

export const CircularShape = styled.div`
  border-radius: 50%;
`;

export const InviteName = styled(Typography)`
  font-size: 14px !important;
  letter-spacing: 0 !important;
  line-height: 20px !important;
  color: #212B36 !important;
`;

export const InviteRole = styled(Typography)`
  font-size: 12px !important;
  line-height: 16px !important;
  color: #637381 !important;
`;

export const PendingSelectionContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const PendingCheckBox = styled(Checkbox)`
  margin-right: 10px !important;
  border-radius: 50%;
`;

export const DotIconElement = styled(DotIcon)`
  height: 20px;
  width: 25.06px;
  cursor: pointer;
`;

export const MenuList = styled(Menu)`
  .MuiMenu-paper {
    box-shadow: 0 0 5px #e4e4e4;
  }
`;

export const LoadMoreText = styled(Typography)`
  cursor: pointer;
  text-align: center;
  font-size: 14px;
`;