const cardInfoList = [
  {
    title: "Procurement Officer",
    userCount: "23 Users",
    description: "Prunedge’s Style Guide can be easily included in applications or one-off projects.",
    subTitle: "23 Permissions",
  },
  {
    title: "Permanent Secretary",
    userCount: "23 Users",
    description: "Prunedge’s Style Guide can be easily included in applications or one-off projects.",
    subTitle: "23 Permissions",
  },
  {
    title: "Commissioners",
    userCount: "23 Users",
    description: "Prunedge’s Style Guide can be easily included in applications or one-off projects.",
    subTitle: "23 Permissions",
  },
  {
    title: "Department Directors",
    userCount: "23 Users",
    description: "Prunedge’s Style Guide can be easily included in applications or one-off projects.",
    subTitle: "23 Permissions",
  },
  {
    title: "Others",
    userCount: "23 Users",
    description: "Prunedge’s Style Guide can be easily included in applications or one-off projects.",
    subTitle: "23 Permissions",
  },
];

export { cardInfoList };
