import React from "react";
import { Wrapper, IconWrapper } from "./style";
import Box from "@material-ui/core/Box";
import { formatCurrency } from "../../../../../util/formatCurrency";
import { parseISO, format } from "date-fns";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";

const formatDate = (date) => (date && format(parseISO(date), "dd MMM, yyyy")) || "N/A";

export default function PaymentStatus({ paymentInfo, ...props }) {
  const { amount, paymentDate } = paymentInfo;

  return (
    <Wrapper {...props}>
      <IconWrapper>
        <CheckRoundedIcon />
      </IconWrapper>
      <Box>
        <p>
          Amount Paid: <strong>{formatCurrency(amount, true)}</strong>
        </p>
        <p>Payment Date: {!!paymentDate && formatDate(paymentDate)}</p>
      </Box>
    </Wrapper>
  );
}
