import styled from "styled-components";
import { Typography } from "@material-ui/core";

export const ReviewModeWrapper = styled.div`
  height: 120px;
  border-radius: 3px;
  background-color: #FFEAD9;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;

  h3 {
    height: 24px;
    color: #1E2B37;
    font-family: "SF Pro Text";
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
    margin-bottom: 10px
  }
`;

export const CustomizedCardWrapper = styled.div`
  padding: 5px;
  border-bottom: 3px solid #e4e4e4;
  border-bottom-color: ${({ activeForm, completedForm }) => ((activeForm === "true") || (completedForm === "true") ? "rgba(11,176,45,0.6)" : "#E5E9EC ")};
  height: 43px !important;
  min-width: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
`;

export const SectionTimeLineWrapper = styled.div`
  display: flex;
  margin-bottom: ${({ marginBottom }) => `${marginBottom} !important`};
`;

export const SectionCardTitle = styled(Typography)`
  color: #212B36 !important;
  font-size: 11px !important;
  font-weight: 600 !important;
  letter-spacing: 0 !important;
  line-height: 15px !important;
`;

export const SectionCardLabel = styled(Typography)`
  color: #212B36 !important;
  font-size: 11px !important;
  font-weight: 300 !important;
  letter-spacing: 0 !important;
  line-height: 15px !important;
`;
