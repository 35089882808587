import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";

import { FormTitle, TaskGroupWrapper } from "../style";
import PROCUREMENT_PLAN_TYPES from "../../../../util/procurementPlanType";
import useAlert from "../../../../hooks/useAlert";
import FormContext from "../../../../util/context";
import ReviewCommentsSection from "../../../ReviewCommentsSection";
import { ButtonGroup } from "../../../NavigationButtons/ButtonGroup";
import { Roles } from "../../../AccessControl/UserRoles";
import procurementAPI from "../../../../redux/api/procurementAPI";
import Loader from "../../../Common/Loader";
import PlanningTask from "./PlanningTask";

const tenderingStages = {
  "Single Stage tendering": "SINGLESTAGE",
  "Two Stage tendering": "DOUBLESTAGE",
};

const Planning = ({ section, planUrl, completedForm, addCompletedForm, completedFormIndex }) => {
  const [submitting, setSubmitting] = useState(false);
  const [stages, setStages] = useState([]);
  const [afterAction, setAfterAction] = useState("");
  const { id: generalPlanId, procurementId } = useParams();
  const history = useHistory();
  const { userRole, isReviewMode, setActiveForm, handleSendForApproval } = useContext(FormContext);


  const generalPlanDetails = useQuery({
    queryKey: ["generalPlanDetails", procurementId],
    queryFn: procurementAPI.getPlanDetails,
    config: {
      staleTime: 900 * 1000,
    },
  });

  const presetStages = useQuery({
    queryKey: [
      "presetStages",
      {
        type: section.type,
        tenderStage: tenderingStages[generalPlanDetails.data?.tenderingStage.name],
        reviewMethod: generalPlanDetails.data?.reviewMethod?.name?.split(" ")[0],
      },
    ],
    queryFn: procurementAPI.getPresetStagesAxios,
    config: {
      enabled: generalPlanDetails.isSuccess,
    },
  });

  // console.log("presetStages presetStages presetStages ", presetStages);

  const [createActivities] = useMutation(procurementAPI.createActivitiesAxios, {
    throwOnError: true,
    onSuccess: () => {
      if (!completedForm.includes(completedFormIndex)) {
        addCompletedForm((state) => [...state, completedFormIndex]);
      }
    },
  });

  const isMaker = userRole === Roles.PROCUREMENT_OFFICER;

  useEffect(() => {
    if (section.type === PROCUREMENT_PLAN_TYPES.PLANNING) {
      setActiveForm(1);
    } else {
      setActiveForm(2);
    }
  }, [section.type, setActiveForm]);

  useEffect(() => {
    if (presetStages.isSuccess && generalPlanDetails.isSuccess) {
      const { procurementPlanActivities } = generalPlanDetails.data || {};

      const filteredActivities = procurementPlanActivities?.filter((v) => v.procurementPlanType === section.type);

      const presetStagesArray = presetStages.data;

      if (filteredActivities.length === presetStagesArray.length && filteredActivities) {
        setStages(filteredActivities);
      } else {
        setStages(presetStagesArray);
      }
    }

    return () => setStages([]);
  }, [presetStages.data, section.type, generalPlanDetails.data, generalPlanDetails.isSuccess, presetStages.isSuccess]);

  const goToKeydetails = () => {
    history.push(`/admin/procurement/plans/${generalPlanId}/create/keydetails/${procurementId}`);
  };

  const goToPlanning = () => {
    history.push(`/admin/procurement/plans/${generalPlanId}/create/planning/${procurementId}`);
  };

  const { handleSubmit, control, errors, getValues, clearErrors } = useForm({ mode: "all" });
  const { showAlert, Toast } = useAlert();

  const composeActivity = (formData) => {
    const { procurementPlanActivities } = generalPlanDetails.data || {};


    let activityCount = 0;
    if (section.type === PROCUREMENT_PLAN_TYPES.PLANNING) {
      activityCount = procurementPlanActivities.filter(
        (activity) => activity.procurementPlanType === PROCUREMENT_PLAN_TYPES.PLANNING
      ).length;
    } else {
      activityCount = procurementPlanActivities.filter(
        (activity) => activity.procurementPlanType === PROCUREMENT_PLAN_TYPES.EXECUTION
      ).length;
    }

    // console.log("presetStages presetStages stages ", stages);

    return stages.map((stage, index) => {
      let existingActivity = null;

      if (activityCount > 0) {
        existingActivity = procurementPlanActivities.find((activity) => activity.title === stage.title);
      }

      const newDate = new Date(formData[`field${index}`].endDate)
      newDate.setHours(23,59,0,0)
      const _endDate = new Date(newDate.getTime() - newDate.getTimezoneOffset()*60000);

      return {
        id: existingActivity ? existingActivity.id : null,
        index: index + 1,
        title: formData[`field${index}`].title,
        startDate: formData[`field${index}`].startDate.toISOString(),
        endDate: _endDate.toISOString(),
        procurementPlanType: section.type,
      };
    });
  };

  const onSubmit = async (formData, action) => {
    const activities = composeActivity(formData);
    setAfterAction(action);

    setSubmitting(true);

    try {
      await createActivities({
        activities,
        id: procurementId,
        replacementType: section.type,
      });

      if (action === "exit") {
        return history.push(planUrl);
      }

      if (section.type === PROCUREMENT_PLAN_TYPES.PLANNING) {
        history.push(`/admin/procurement/plans/${generalPlanId}/create/execution/${procurementId}`);
      } else {
        handleSendForApproval(procurementId, generalPlanId, () => history.push(`/admin/procurement/plans/${generalPlanId}`));
      }
    } catch (e) {
      showAlert({
        severity: "error",
        message: e.message,
      });
    }

    setSubmitting(false);
  };

  const getThirdButtonText = () => {
    if (submitting && afterAction !== "exit") {
      return "Please wait...";
    }

    if (section.type === PROCUREMENT_PLAN_TYPES.PLANNING) {
      return "Continue";
    } else {
      return "Send For Approval";
    }
  };

  if (generalPlanDetails.isLoading || presetStages.isLoading) {
    return <Loader />;
  }

  return (
    <>
      <FormTitle>Procurement {section.title}</FormTitle>
      <Toast float />
      <form>
        <TaskGroupWrapper style={{ paddingTop: "20px" }}>
          {stages?.length
            ? stages.map((item, index) => (
                <PlanningTask
                  isMaker={isMaker}
                  control={control}
                  errors={errors}
                  getValues={getValues}
                  clearErrors={clearErrors}
                  key={item.id}
                  item={item}
                  index={index}
                  isCustom={stages[index].isCustom}
                  setStages={setStages}
                  stages={stages}
                />
              ))
            : null}
        </TaskGroupWrapper>

        {/* {isReviewMode ?  */}
        <ReviewCommentsSection objectId={procurementId} />
        {/* : null} */}

        {!isReviewMode && isMaker ? (
          <ButtonGroup
            firstBtnText="Previous"
            secondBtnText={afterAction === "exit" ? "Please wait..." : "Save And Exit"}
            thirdBtnText={getThirdButtonText()}
            handleFirstBtn={section.type === PROCUREMENT_PLAN_TYPES.PLANNING ? goToKeydetails : goToPlanning}
            handleSecondBtn={handleSubmit((formValue) => onSubmit(formValue, "exit"))}
            handleThirdBtn={handleSubmit((formValue) => onSubmit(formValue, "continue"))}
            isReviewMode={isReviewMode}
          />
        ) : null}

        {section.type === PROCUREMENT_PLAN_TYPES.PLANNING && isReviewMode && isMaker ? (
          <ButtonGroup
            firstBtnText="Previous"
            secondBtnText={afterAction === "exit" ? "Please wait..." : "Save And Exit"}
            thirdBtnText="Continue"
            handleFirstBtn={goToKeydetails}
            handleSecondBtn={handleSubmit((formValue) => onSubmit(formValue, "exit"))}
            handleThirdBtn={handleSubmit((formValue) => onSubmit(formValue, "continue"))}
            isReviewMode={isReviewMode}
          />
        ) : null}

        {section.type === PROCUREMENT_PLAN_TYPES.EXECUTION && isReviewMode && isMaker ? (
          <ButtonGroup
            firstBtnText="Previous"
            secondBtnText={afterAction === "exit" ? "Please wait..." : "Save And Exit"}
            thirdBtnText="Send For Approval"
            handleFirstBtn={goToPlanning}
            handleSecondBtn={handleSubmit((formValue) => onSubmit(formValue, "exit"))}
            handleThirdBtn={handleSubmit((formValue) => onSubmit(formValue, "continue"))}
            isReviewMode={isReviewMode}
          />
        ) : null}
      </form>
    </>
  );
};

export default Planning;
