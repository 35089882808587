import styled from "styled-components";
import { FormTitle } from "../../../util/theme";

export const SectionHead = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 100px;
  margin-bottom: 40px;
`;

export const SectionHeadAddendum = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SectionTitle = styled(FormTitle)`
  color: #274472;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
`;

export const TitleBorder2 = styled.div`
  box-sizing: border-box;
  height: 1px;
  width: 72px;
  border: 2px solid #274472;
  margin-top: 20px;
`;

export const ViewAllButton = styled.p`
  height: 22px;
  color: #03adeb;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
  margin-top: 31px;
  margin-bottom: 0px;
  cursor: pointer;
`;
