import React, { useState } from "react";
import AdminSearchFilter from "../../../../components/AdminSearchFilter";
import RolePageDrawer from "../../../../components/RolePageDrawer";
import InputField from "../../../../components/InputField";
import {
  AddButton, CirclePlusIconWrapper, PageTitle, TitleContainer,
} from "../../../Common/style";
import withAdminDashboard from "../../HOC/withAdminDashboard";
import { ContentWrapper } from "../../Procurement/style";
import Dropdown from "../../../../components/DropdownField";
import Breadcrumb from "../../../Common/PageElements/Breadcrumb";
import { SearchButton } from "../../../../components/Forms/Common/style";
import RolesCardSection from "../../../../components/RolesCardSection/index";
import { cardInfoList } from "./mock";

const Roles = () => {
  const [openModal, setOpenModal] = useState(false);

  const toggleDrawer = () => {
    setOpenModal(!openModal);
  };

  const breadCrumbValues = [
    { title: "Home", url: "#", forwardSlash: true },
    { title: "Settings", url: "#" },
  ];

  const searchPermissionList = [{ label: "Select", value: 1 }];

  return (
    <ContentWrapper>
      <TitleContainer>
        <div>
          <PageTitle>Roles</PageTitle>
          <Breadcrumb values={breadCrumbValues} />
        </div>
        <AddButton onClick={() => toggleDrawer()}>
          <CirclePlusIconWrapper /> Add New
        </AddButton>
      </TitleContainer>
      <AdminSearchFilter normalSearch>
        <div
          style={{
            display: "flex",
          }}
        >
          <Dropdown name="Search Permission" label="Search Permission" values={searchPermissionList} />
          <InputField type="text" label="Search" defaultValue="Completion of settlement scheme" required />
          <SearchButton>Search</SearchButton>
        </div>
      </AdminSearchFilter>
      <br />

      <RolesCardSection cardInfo={cardInfoList} />

      <RolePageDrawer toggleDrawer={toggleDrawer} openModal={openModal} />
    </ContentWrapper>
  );
};

export default withAdminDashboard(Roles);
