import styled from "styled-components";
import InputField from "../InputField";

export const MinistryInputField = styled(InputField)`
  border: 1px solid #c9d4db;
  border-radius: 3px;
  background-color: #ffffff !important;
  height: 49px !important;
`;

export const FormWrapper = styled.div`
  width: 100%%;

  span {
    color: #f21500;
    font-size: 11px;
    margin-left: 14px;
  }
`;
