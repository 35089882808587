import React from "react";

import { Container } from "@material-ui/core";
import {
  HeroContentWrapper,
  HeroCover,
  HeroImage,
  TitleBorder1,
  WrapperDescription,
  WrapperTitle,
} from "./style";

const Hero = ({ title, desc, width, size }) => (
  <HeroCover>
    <HeroImage>
      <Container>
        <HeroContentWrapper>
          <WrapperTitle width={width}>{title}</WrapperTitle>
          <TitleBorder1 size={size}/>
          <WrapperDescription>{desc}</WrapperDescription>
        </HeroContentWrapper>
      </Container>
    </HeroImage>
  </HeroCover>
);

export default Hero;
