import { useQuery, useMutation } from "react-query";
import certificateOfNoObjectionApi from "../../redux/api/certificateOfNoObjectionApi";
import procurementAPI from "../../redux/api/procurementAPI";
import usersAPI from "../../redux/api/usersAPI";

export const useFecthCertificate = ({ procurementId }) => {
  return useQuery(['certificateOfObjection', procurementId], () => certificateOfNoObjectionApi.getCertificate('', { procurementId }),
    {
      enabled: !!procurementId
    })
}

export const useFetchCertificateDetails = ({ id }) => {
  return useQuery(['cert-details', id], () => usersAPI.getCertificateDetails(id),
    {
      enabled: !!id,
    })
}

export const useFetchCertificateData = ({ procurementId, showAlert, setIsEdit }) => {
  return useQuery(['getCertificateData', procurementId], () => procurementAPI.getCertificateData('getCertificateData', procurementId),
    {
      enabled: true,
      onError: (error) => {
        showAlert && showAlert({
          message: error?.message,
          severity: "error",
        });
      },
      onSuccess: (data) => {
        if (data.data.subHeadCode) {
          setIsEdit && setIsEdit(true)
        }
      }
    })
}


export const useFetchAuthoriserSignature = ({ showAlert }) => {
  return useQuery(["getAuthoriserSignature"], () => procurementAPI.getAuthorizerSignature(), {
    enabled: true,
    onError: (error) => {
      showAlert && showAlert({
        message: error.message,
        severity: "error",
      })
    }
  })
}


export const useCreateCertificate = ({ showAlert, setModal, queryCache, activityId, history, path }) => {
  return useMutation(({ payload }) => procurementAPI.postCertificateInformation({ activityId, data: payload }),
    {
      onSuccess: () => {
        showAlert && showAlert({
          message: "Certificate data created successfully",
          severity: "success",
        })
        setModal && setModal()
        queryCache && queryCache.invalidateQueries('activityDetails');
        history && history.push(path)
      },
      onError: (err) => {
        showAlert && showAlert({
          message: err?.message,
          severity: "error"
        })
      },
      onSettled: () => {
        queryCache && queryCache.invalidateQueries('getCertificateData');
      }
    })
}

export const useUpdateCertificate = ({ showAlert, setModal, queryCache, procurementId, history, path }) => {
  return useMutation(({ payload }) => procurementAPI.patchCertificateInformation({ procurementId, data: payload }),
    {
      onSuccess: () => {
        showAlert && showAlert({
          message: "Certificate data updated successfully",
          severity: "success",
        })
        setModal && setModal()
        queryCache && queryCache.invalidateQueries('activityDetails');
        history && history.push(path)
      },
      onError: (err) => {
        showAlert && showAlert({
          message: err?.message,
          severity: "error"
        })
      },
      onSettled: () => {
        queryCache && queryCache.invalidateQueries('getCertificateData');
      }
    })

}

export const useRequestCertificateAmendment = ({ showAlert, setModal, setDrawer, activityId }) => {
  return useMutation(({ payload }) => procurementAPI.requestAdmendmentCNO({ activityId, payload }), {
    onSuccess: () => {
      showAlert && showAlert({
        message: "Amendment requested successfully",
        severity: "success",
      });
      setModal && setModal(false)
      setDrawer && setDrawer()
    },
    onError: (error) => {
      showAlert && showAlert({
        message: error?.message,
        severity: "error",
      });
    },

  })
}
