/* eslint-disable no-unused-expressions */
import { createSlice } from "@reduxjs/toolkit";
import baseThunkMethod, { modifiedBaseThunkMethod } from "./baseThunkMethod";
import { fulfilled, pending, rejected } from "./baseReducer";

export const loginUser = baseThunkMethod("users/login");
export const getUserByToken = baseThunkMethod("getUserByToken");
export const signupVendor = baseThunkMethod("users/signup");
export const resendInvite = baseThunkMethod("users/resendInvite");
export const confirmEmail = baseThunkMethod("confirmEmail");
export const forgotPassword = baseThunkMethod("forgotPassword");
export const resetPassword = baseThunkMethod("resetPassword");
export const userRoles = baseThunkMethod("userRoles");
export const getMinistries = baseThunkMethod("getMinistries");
export const postMinistries = modifiedBaseThunkMethod("postMinistries");

export const userSlice = createSlice({
  name: "user",
  initialState: {
    loading: "idle",
    user: null,
    error: null,
    userRoles: [],
    ministries: [],
    loaded: [],
  },
  extraReducers: {
    [signupVendor.pending]: pending(),
    [signupVendor.fulfilled]: (state, action) => {
      state.loading = "idle";
      state.error = null;
      state.user = action.payload.data;
      localStorage.setItem("email", action.payload.data.email);
    },
    [signupVendor.rejected]: rejected(),
    [loginUser.pending]: pending(),
    [loginUser.fulfilled]: (state, action) => {
      state.loading = "idle";
      state.error = null;
      state.user = action.payload.data.user;
      const { userType, firstName, lastName, vendorRegStage, vendorProfileId } = action.payload.data.user;
      const { token, refreshToken } = action.payload.data;
      localStorage.setItem("token", token);
      localStorage.setItem("refreshToken", refreshToken );
      localStorage.setItem("isAdmin", userType === 2);
      localStorage.setItem("vendorId", vendorProfileId);
      localStorage.setItem("firstName", action.payload.data.user.firstName);
      localStorage.setItem("userName", `${firstName} ${lastName}`);
      if (userType === 1) localStorage.setItem("vendorRegStage", vendorRegStage);
    },
    [loginUser.rejected]: rejected(),

    [getUserByToken.pending]: pending(),
    [getUserByToken.fulfilled]: (state, action) => {
      state.loading = "idle";
      state.error = null;
      state.user = action.payload.data;
      const { userType } = action.payload.data;
      localStorage.setItem("isAdmin", userType === 2);
    },
    [getUserByToken.rejected]: rejected("user"),

    [resendInvite.pending]: pending(),
    [resendInvite.fulfilled]: fulfilled("user"),
    [resendInvite.rejected]: rejected("user"),
    [forgotPassword.pending]: pending(),
    [forgotPassword.fulfilled]: fulfilled("user"),
    [forgotPassword.rejected]: rejected("user"),
    [resetPassword.pending]: pending(),
    [resetPassword.fulfilled]: fulfilled("user"),
    [resetPassword.rejected]: rejected("user"),
    [userRoles.pending]: pending("userRoles"),
    [userRoles.fulfilled]: fulfilled("userRoles"),
    [userRoles.rejected]: rejected("userRoles"),
    [getMinistries.pending]: pending("ministries"),
    [getMinistries.fulfilled]: fulfilled("ministries"),
    [getMinistries.rejected]: rejected("ministries"),
    [postMinistries.pending]: pending("ministries"),
    [postMinistries.fulfilled]: (state, action) => {
      state.loading = "idle";
      state.error = null;

      state.ministries.push(action.meta.arg);
      !state.loaded.includes("ministries") && state.loaded.push("ministries");
    },
    [postMinistries.rejected]: rejected("ministries"),
  },
  reducers: {
    updateUser: (state, action) => {
      state.user = {
        ...state.user,
        ...action.payload,
      };
    },
  },
});

export const { updateUser } = userSlice.actions;

export default userSlice.reducer;
