import React from "react";
import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";

import { ContractDetailTabText } from "./style";

const AwardedCompanyTab = () => {
  const {
    adminContractDetails: { awardedCompany = {} },
  } = useSelector((state) => state.admin);

  const details = [
    { title: "Company Name", value: awardedCompany.companyName || "Not available" },
    { title: "Business reg number", value: awardedCompany.registrationNumber || "Not available" },
    { title: "Business type/ Type of organization", value: awardedCompany.organizationType || "Not available" },
    { title: "Address", value: awardedCompany.address || "Not available" },
    { title: "Registration ID", value: awardedCompany.registerationID || "Not available" },

    { title: "Registration class", value: awardedCompany.registrationClass || "Not available" },
    { title: "Status", value: awardedCompany.status || "Not available" },
    { title: "Reason for award", value: awardedCompany.reasonForAward || "Not available" },
  ];

  return details.map((detail, i) => (
    <Grid key={i} style={{ borderBottom: "1px solid #CBD4DA" }} container>
      <Grid item xs={5}>
        <ContractDetailTabText>{detail.title}</ContractDetailTabText>
      </Grid>
      <Grid item sm style={{ borderLeft: "1px solid #CBD4DA" }}>
        <ContractDetailTabText>{detail.value}</ContractDetailTabText>
      </Grid>
    </Grid>
  ));
};

export default AwardedCompanyTab;
