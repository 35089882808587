import React from "react";
import { ReactComponent as ProcurementIcon } from "../../../assets/speaker.svg";
import { ReactComponent as OverviewIcon } from "../../../assets/dashboard.svg";
import { ReactComponent as BidsIcon } from "../../../assets/auction-2.svg";
import { ReactComponent as PolicyIcon } from "../../../assets/policy.svg";
import { ReactComponent as PresentationIcon } from "../../../assets/presentation-2.svg";
import { ReactComponent as WalletIcon } from "../../../assets/wallet.svg";

export const menus = [
  {
    title: "overview",
    to: "/vendor/overview",
    icon: <OverviewIcon />,
  },
  {
    title: "procurement",
    to: "/vendor/procurement",
    icon: <ProcurementIcon />,
  },
  {
    title: "bids",
    to: "/vendor/bids",
    icon: <BidsIcon />,
  },
  {
    title: "contracts",
    to: "/vendor/contracts",
    icon: <PolicyIcon />,
  },
  {
    title: "projects",
    to: "/vendor/projects",
    icon: <PresentationIcon />,
  },
  {
    title: "transactions",
    to: "/vendor/transactions",
    icon: <WalletIcon />,
  },
];
