import React, { useEffect, useContext } from "react";
import { useQuery, useMutation } from "react-query";
import { useParams, useHistory } from "react-router-dom";
import generalPlansAPI from "../../../../redux/api/generalPlansAPI";
import { formatCurrency } from "../../../../util/formatCurrency";
import ToastContext from "../../../../util/toastContext";
import {
  ApprovalActionButtonWrapper,
  CancelButton,
  FinalApprovalDetails,
  FinalApprovalDetailsText,
  FinalApprovalDialog,
  FinalApprovalDialogContent,
  FinalApprovalDialogDesc,
  FinalApprovalDialogTitle,
  SendForApprovalButton,
} from "./style";

const SendForApprovalDialog = ({ toggleModal, isOpen = true }) => {
  const { id } = useParams();
  const history = useHistory();
  const { showAlert } = useContext(ToastContext);

  const GeneralPlansDetailsQuery = useQuery({
    queryKey: ["getGeneralPlansDetails", id],
    queryFn: generalPlansAPI.getGeneralPlansDetails,
  });

  const procurementPlansQuery = useQuery({
    queryKey: ["getProcurementPlans", id],
    queryFn: generalPlansAPI.getProcurementPlans,
  });

  const [sendApprovePlan, sendApprovePlanQueryResponse] = useMutation(generalPlansAPI.sendForApproval);

  useEffect(() => {
    if (sendApprovePlanQueryResponse.isSuccess) {
      showAlert({
        severity: "success",
        message: "Plan Sent for approval",
      });

      setTimeout(function () {
        history.push(`/admin/procurement/plans`);
      }, 1500);
    }
    if (sendApprovePlanQueryResponse.isError) {
      showAlert({
        severity: "error",
        message: sendApprovePlanQueryResponse.error,
      });
    }
  }, [
    sendApprovePlanQueryResponse.isSuccess,
    sendApprovePlanQueryResponse.isError,
    sendApprovePlanQueryResponse.error,
    showAlert,
    history,
  ]);

  const handleSendForApproval = async () => {
    await sendApprovePlan(id);
  };

  return (
    <FinalApprovalDialog onClose={() => toggleModal(false)} open={isOpen}>
      <FinalApprovalDialogContent>
        <FinalApprovalDialogTitle>Send For Approval</FinalApprovalDialogTitle>

        <FinalApprovalDialogDesc>
          You are about sending all (all the tender packaged under the application for approval)
        </FinalApprovalDialogDesc>

        <FinalApprovalDetails>
          <FinalApprovalDetailsText>
            Total Budget: <b>N{formatCurrency(GeneralPlansDetailsQuery.data?.budget) || "N/A"}</b>
          </FinalApprovalDetailsText>

          <FinalApprovalDetailsText>
            Total Tender Packages: <b>{!procurementPlansQuery.isLoading && procurementPlansQuery.data?.data?.length}</b>
          </FinalApprovalDetailsText>
        </FinalApprovalDetails>

        <ApprovalActionButtonWrapper>
          <SendForApprovalButton
            onClick={handleSendForApproval}
            loading={sendApprovePlanQueryResponse.isLoading}
            style={{ width: "230px" }}
          >
            Send For Approvals
          </SendForApprovalButton>
          <CancelButton onClick={() => toggleModal(false)}>Cancel</CancelButton>
        </ApprovalActionButtonWrapper>
      </FinalApprovalDialogContent>
    </FinalApprovalDialog>
  );
};

export default SendForApprovalDialog;
