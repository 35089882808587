import styled from "styled-components";
import { Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

export const ModalContent = styled.div`
  box-sizing: border-box;
  min-height: 530px;
  border: 1px solid #979797;
  border-radius: 3px;
  background-color: #FFFFFF;
  padding: 30px;
`;

export const MilestoneTitleText = styled(Typography)`
  color: #5F7383;
  font-size: 20px !important;
  font-weight: bold !important;
  letter-spacing: 0;
  line-height: 27px !important;
`;

export const MilestoneCloseIcon = styled(CloseIcon)`
  cursor: pointer;
`;
