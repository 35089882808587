import { Grid } from "@material-ui/core";
import React from "react";
import { useForm } from "react-hook-form";
import {
  FormWrapper,
  RoleInputField,
  PermissionList,
  PermissionListItemContainer,
  PermissionName,
  PermissionCheckBox,
  PermissionListItemTitle,
  PermissionContainerHolder,
  ErrorContainer,
} from "./style";
import { permissionListData } from "./mock";

const RoleDrawerLayout = ({ onRenderError, renderButton }) => {
  const { register, handleSubmit, errors } = useForm();

  const margin = "20px";

  const onSubmit = (data) => console.log(data);

  const PermissionListItem = (pendingInfo) => (
    <PermissionListItemContainer key={`${pendingInfo.name}_${pendingInfo.id}`}>
      <PermissionCheckBox name={`${pendingInfo.name}_${pendingInfo.id}`} />
      <div>
        <PermissionName>{pendingInfo.name}</PermissionName>
      </div>
    </PermissionListItemContainer>
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormWrapper>
        <Grid container>
          <Grid item xs={12}>
            <RoleInputField
              type="text"
              label="Role name"
              name="name"
              style={{ width: "auto", margin }}
              inputRef={register({
                required: "Please supply a role name",
                validate: (value) => (value.length > 2 && value.length < 256) || "Please supply a role name",
              })}
            />
            <ErrorContainer> {onRenderError(errors.name)}</ErrorContainer>
          </Grid>
          <Grid item xs={12}>
            <RoleInputField
              type="text"
              label="Role description"
              name="description"
              style={{ width: "auto", margin }}
              inputRef={register({
                required: "Please supply role description",
                validate: (value) => (value.length > 2 && value.length < 256) || "Please supply role description",
              })}
              multiline
              variant="outlined"
            />
            <br />
            <br />
            <ErrorContainer>{onRenderError(errors.description)}</ErrorContainer>
          </Grid>

          <Grid>
            <PermissionContainerHolder>
              <PermissionList>
                <PermissionListItemTitle>
                  <PermissionCheckBox name={"all"} disabled />
                  <div>
                    <PermissionName>Create</PermissionName>
                  </div>
                </PermissionListItemTitle>
                {permissionListData.map((elem) => PermissionListItem(elem))}
              </PermissionList>

              <PermissionList>
                <PermissionListItemTitle>
                  <PermissionCheckBox name={"all"} disabled />
                  <div>
                    <PermissionName>View</PermissionName>
                  </div>
                </PermissionListItemTitle>
                {permissionListData.map((elem) => PermissionListItem(elem))}
              </PermissionList>
            </PermissionContainerHolder>
          </Grid>
        </Grid>
        <br />
        <br />
      </FormWrapper>
      {renderButton("Create")}
    </form>
  );
};

export default RoleDrawerLayout;
