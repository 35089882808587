/**
 * formatCurrency takes an amount and returns a string that contains
 * the provided amount in currency form
 * @param {number} amount The amount to be formatted as a string
 * @param {boolean} withSymbol Keep the currency symbol as part of the returned string
 * @param {boolean} stripDecimals Remove decimals after the conversion
 */
export function formatCurrency(amount, withSymbol = false, stripDecimals = false) {
  let formatted = new Intl.NumberFormat("en-NG", {
    currency: "NGN",
    style: "currency",
  }).format(amount);
  if (!withSymbol) formatted = formatted.substr(withSymbol ? 0 : 1);
  if (stripDecimals) formatted = formatted.substring(0, formatted.indexOf("."));
  return formatted;
}
