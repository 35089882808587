import styled from "styled-components";
import { BiddingDocumentContainer } from "../../BidDetails/BidInfo/style";
import { SearchButton } from "../../../../components/Forms/Common/style";

export const AcceptanceLetterWrapper = styled(BiddingDocumentContainer)`
  background-color: #ffffff;
`;

export const UploadButton = styled(SearchButton)`
  min-width: 180px !important;
`;
