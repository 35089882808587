import LoadingButton from "../../../../../../components/LoadingButton/LoadingButton";
import styled from "styled-components";
import { LinearProgress } from "@material-ui/core";

export const ApproveButton = styled(LoadingButton)`
  &.MuiButtonBase-root{
    background-color: #219653;
    transition: 300ms;
  }
  
  &.MuiButtonBase-root:hover{
    background-color: #c9d4db;
  }
`

export const RejectButton = styled(LoadingButton)`
  &.MuiButtonBase-root{
    background-color: #EB5757;
    transition: 300ms;
  }
  
  &.MuiButtonBase-root:hover{
    background-color: #c9d4db;
  }
`

export const TopButtons = styled.div`
  display: inline-flex;
  align-items: center;
`

export const ContentWrapper = styled.div`
  background: #FFFFFF;
  border: 1px solid #C9D4DB;
  box-shadow: 0 2px 4px #C9D4DB;
  border-radius: 3px;
`

export const SectionTitle = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: #1E2B37;
  margin-bottom: 15px;
`

export const SectionDescription = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: #637381;
  margin-bottom: ${props => props.noMargin ? '0' : '25px'};
`

export const ProgressBar = styled(LinearProgress)`
  .MuiLinearProgress-bar{
    background-color: #03ADEB;
  }
  
  &.MuiLinearProgress-root{
    height: 7px;
    border-radius: 3px;
  }
`

export const SectionWrapper = styled.div`
  background: #FCFCFD;
  border: 1px solid #C9D4DB;
  border-left: none;
  border-right: none;
  margin-bottom: 20px;
  padding: 20px 75px 20px 20px;
  position: relative;
`

export const FilesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
`

export const StatusIcon = styled.div`
  position: absolute;
  top: 30px;
  right: 20px;
`
