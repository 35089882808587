import { clearGeneralPlanSummary } from "../redux/reducer/adminReducer";
import { queryCache } from "react-query";

export const logoutFn = (dispatch) => {
  localStorage.removeItem("token");
  localStorage.removeItem("email");
  localStorage.removeItem("isAdmin");
  localStorage.removeItem("userName");
  queryCache.removeQueries('userDetails')
  dispatch && dispatch(clearGeneralPlanSummary());
};
