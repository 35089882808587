import React from "react";
import { useHistory } from "react-router-dom";
import { Container } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import useAlert from "../../hooks/useAlert";
import { forgotPassword } from "../../redux/reducer/userReducer";

import { Footer, InputField, Navbar } from "..";
import {
  AuthSubtext,
  AuthText,
  ButtonLabel,
  ContentWrapper,
  LoginButton,
  LoginInfoWrapper,
  PasswordResetButton,
  Wrapper,
} from "../../pages/Common/style";
import { ErrorContainer } from "../Forms/Common/style";

const ForgotPassword = ({ passwordSentLink, loginLink }) => {
  const { register, handleSubmit, errors } = useForm();
  const history = useHistory();
  const onRenderError = (error) => (
    <ErrorContainer> {error && error.message} </ErrorContainer>
  );

  const width = "100%";
  const margin = "0 0 8px 0";
  const textAlign = "center";

  const dispatch = useDispatch();
  const { showAlert, Toast } = useAlert();
  const { loading } = useSelector((state) => state.user);

  const onSubmit = (data) => {
    dispatch(forgotPassword(data))
      .then((res) => {
        if (res.error) {
          showAlert({ severity: "error", message: res.payload });
          return;
        }
        localStorage.setItem("email", data.email);
        history.push(passwordSentLink);
      });
  };

  return (
    <div>
      <Toast float />
      <Navbar />
      <Container>
        <ContentWrapper>
          <Wrapper>
            <AuthText>Forgot password?</AuthText>
            <AuthSubtext style={{ textAlign, marginBottom: "30px" }}>
              Don't worry, just enter the email address you registered with and
              we will send you a link to reset your password.
            </AuthSubtext>
            <form onSubmit={handleSubmit(onSubmit)}>
              <InputField
                type="email"
                label="Email address"
                name="email"
                style={{ width, margin }}
                inputRef={register({
                  required: "Invalid email address",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "Invalid email address",
                  },
                })}
              />
              {onRenderError(errors.email)}

              <LoginInfoWrapper>
                <PasswordResetButton onClick={() => history.push(loginLink)}>
                  <ButtonLabel>Login</ButtonLabel>
                </PasswordResetButton>
                <LoginButton type="submit" disabled={loading === "pending"}>
                  {loading === "pending" ? "Please Wait..." : "Send Link"}
                </LoginButton>
              </LoginInfoWrapper>
            </form>
          </Wrapper>
        </ContentWrapper>
      </Container>
      <Footer />
    </div>
  );
};

export default ForgotPassword;
