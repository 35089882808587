import React from "react";
import DocumentTab from "../../../DocumentTab";

const UploadDocumentTab = ({
  documents, setDocuments, loading,
}) => {
  const addFiles = (documentType) => (newDocuments) => {
    setDocuments(prevDocument => ({
      ...prevDocument,
      [documentType]: prevDocument[documentType]?.concat(newDocuments),    
    }));
  };

  const removeFile = (documentType) => (file) => {
    let uploads;
    if (file.inDb) {
      uploads = {
        ...documents,
        [documentType]: documents[documentType].filter((document) => document !== file),
        removed: documents.removed.concat(file.id),
      };
    } else {
      uploads = {
        ...documents,
        [documentType]: documents[documentType].filter((document) => document !== file),
      };
    }
    setDocuments(uploads);
  };

  return (
    <DocumentTab
      addMandatoryFiles={addFiles("mandatory")}
      addSupportingFiles={addFiles("supporting")}
      removeMandatoryFiles={removeFile("mandatory")}
      removeSupportingFiles={removeFile("supporting")}
      mandatory={documents.mandatory}
      supporting={documents.supporting}
      loading={loading}
    />
  );
};

export default UploadDocumentTab;
