import { styled as materialStyled } from "@material-ui/styles";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";

export const AcceptedTableHeader = materialStyled(TableHead)({
  backgroundColor: '#219653',
  border: '1px solid #C9D4DB'
})

export const RejectedTableHeader = materialStyled(TableHead)({
  backgroundColor: '#EB5757',
  border: '1px solid #C9D4DB'
})

export const StyledTableCell = materialStyled(TableCell)({
  color: 'white'
})


