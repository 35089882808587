import styled from "styled-components";
import GridListTile from "@material-ui/core/GridListTile";
import { Description } from "../Common/style";
import { FormSubtitle } from "../../../util/theme";
import FileUploadField from "../../FileUploadField";

export const VerificationDescription = styled(Description)`
  margin-bottom: 20px;
`;

export const VerificationLabel = styled(FormSubtitle)`
  font-weight: 700 !important;
`;

export const VerificationFormTitle = styled(FormSubtitle)`
  margin-bottom: 10px !important;
  font-weight: 600 !important;
  font-size: 16px !important;
`;

export const VerificationText = styled(FormSubtitle)`
  margin-bottom: 10px !important;
`;

export const VerificationDivider = styled.hr`
  border: 1px solid #c4cdd5 !important;
  margin: 20px 0;
`;

export const VerificationFileUploadField = styled(FileUploadField)`
  margin-top: 0;
`;

export const VerificationDocumentList = styled(GridListTile)`
  height: 70px !important;
  padding: 0 12px !important;
`;

export const UserProfileImage = styled.div`
  margin: 20px 0;
  img {
    height: 150px;
    width: 150px;
    background: rgba(3, 173, 235, 0.07);
    border-radius: 3px;
  }
`;
