import styled from "styled-components";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";

export const TimelineWrapper = styled(Timeline)`
  padding: 6px 0 !important;
  color: #637381;

  .MuiTimelineItem-missingOppositeContent:before {
    display: none;
  }

  .MuiTimelineDot-root {
    margin: 0;
  }

  .MuiTimelineContent-root {
    padding: 0 16px;
  }

  .MuiTypography-h6 {
    line-height: 1;
  }

  .MuiTimelineItem-root {
    min-height: 32px;
  }
`;

export const Item = styled(TimelineItem)`
  .MuiTypography-body1 {
    font-weight: 800;
    color: ${({ active, completed }) => (Boolean(completed) || Boolean(active) ? "#03ADEB" : "#637381")}
  }

  .MuiTimelineContent-root > p:nth-child(2) {
    margin-bottom: 15px;
    font-size: 13px;
  }

  .MuiTimelineDot-outlinedGrey {
    border: 2px solid ${({ active, completed }) => (Boolean(completed) || Boolean(active) ? "#03ADEB" : "#bdbdbd")};
    padding: 2px;
    height: 14px;
    width: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .MuiTimelineConnector-root {
    background-color: ${({ completed }) => (completed ? "#03ADEB" : "#bdbdbd")};
  }

  svg {
    width: 7px;
    height: 7px;
    stroke: ${({ active, completed }) => (Boolean(completed) || Boolean(active) ? "#03ADEB" : "#bdbdbd")};
    stroke-width: 16;
  }
`;
