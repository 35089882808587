import { useContext } from "react";
import { useQuery } from "react-query";
import ToastContext from "../../../util/toastContext";
import paymentAPI from "../../../redux/api/paymentAPI";

export default function useBidPaymentStatus({ tenderId, enabled, queryProps = {} }) {
  const { showAlert } = useContext(ToastContext);
  const paymentStatusQuery = useQuery(["getBidPaymentStatus", tenderId], paymentAPI.getBidPaymentStatus, {
    enabled: enabled ?? !!tenderId,
    onError:
      queryProps?.onError ??
      function onError(error) {
        showAlert({
          severity: "error",
          message: error.message,
          durationInMs: 3000,
        });
      },
    ...queryProps,
  });
  const paymentInfo = paymentStatusQuery.data ?? {};
  const hasAttemptedPayment = paymentInfo.paymentStatus !== "PENDING";
  const hasUserPaid = paymentInfo.paymentStatus === "PAID";
  const isAwaitingVerification = paymentInfo.paymentStatus === "AWAITING VERIFICATION";
 
  return {
    query: paymentStatusQuery,
    paymentInfo,
    hasAttemptedPayment,
    hasUserPaid,
    isAwaitingVerification,
  };
}
