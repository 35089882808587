import styled from "styled-components";

export const PageGrid = styled.div`
   display: grid;
   grid-template-columns: 2fr 1fr;
   grid-column-gap: 120px;
   min-height: 100vh;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #E4E9EC;
  margin: 15px 0;
`;

export const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 0;
  width: 100%;
`
