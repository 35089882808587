import { Container, Grid } from "@material-ui/core";
import React, { useState } from "react";

import MainAccordion from "../../../components/Accordion";
import GridCard from "../../../components/GridCard";
import { accordionContent, registeredAwards, annualProcurementPlans, relatedCircular, solutionGuide } from "./mock";
import {
  SectionHead,
  SectionTitle,
  TitleBorder2,
  // ViewAllButton,
  SectionHeadAddendum,
} from "./style";
import withHeaderFooterHOC from "../HOC/withHeaderFooterHOC";
import Hero from "../../../components/Hero";

const trimText = (text) => {
  if (text.length > 20) {
    return text.substring(0, 40) + "...";
  } else {
    return text;
  }
};

const AccordionBody = () => {
  const [expanded, setExpanded] = useState(0);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return accordionContent.map((row, index) => (
    <MainAccordion
      key={`${row.title}_${index}`}
      title={row.title}
      description={row.description}
      panelId={`${row.title}_${index}`}
      onChange={handleChange(`${row.title}_${index}`)}
      expanded={expanded === `${row.title}_${index}`}
    />
  ));
};

const RegisteredAwards = () =>
  registeredAwards.map((row, index) => (
    <Grid item style={{ marginBottom: "23.5px" }} xs={6} sm={4} md={3} key={`${index}`}>
      <a style={{ textDecoration: "none" }} href={row.url} target="_blank" rel="noopener noreferrer">
        <GridCard title={trimText(row.title)} />
      </a>
    </Grid>
  ));


const AnnualProcurementPlans = () =>
  annualProcurementPlans.map((row, index) => (
    <Grid item style={{ marginBottom: "23.5px" }} xs={6} sm={4} md={3} key={`${index}`}>
      <a style={{ textDecoration: "none" }} href={row.url} target="_blank" rel="noopener noreferrer">
        <GridCard title={trimText(row.title)} />
      </a>
    </Grid>
  ));

const RelatedCircular = () =>
  relatedCircular.map((row, index) => (
    <Grid item style={{ marginBottom: "23.5px" }} xs={6} sm={4} md={3} key={`${index}`}>
      <a style={{ textDecoration: "none" }} href={row.url} target="_blank" rel="noopener noreferrer">
        <GridCard title={trimText(row.title)} style={{ height: "183px" }} />
      </a>
    </Grid>
  ));

const SolutionGuide = () =>
  solutionGuide.map((row, index) => (
    <Grid item style={{ marginBottom: "23.5px" }} xs={6} sm={4} md={3} key={`${index}`}>
      <a style={{ textDecoration: "none" }} href={row.url} target="_blank" rel="noopener noreferrer">
        <GridCard title={trimText(row.title)} style={{ height: "183px" }} />
      </a>
    </Grid>
  ));

const Resources = () => {
  return (
    <div>
      <Hero
        title="Resources"
        desc=" Are you interested in bidding for a contract for your organization or for an individual? Click the link below to
      register"
      />
      <Container>
        <SectionHead
          style={{
            flexDirection: "column",
          }}
        >
          <SectionTitle>Registered Award</SectionTitle>

          <SectionHeadAddendum>
            <TitleBorder2 style={{ marginTop: 0 }} />
            {/* <ViewAllButton style={{ marginTop: 0 }} onClick={() => {}}>
              View All
            </ViewAllButton> */}
          </SectionHeadAddendum>
        </SectionHead>
        <Grid container spacing={3}>
          <RegisteredAwards />
        </Grid>
      </Container>

      <Container>
        <SectionHead
          style={{
            flexDirection: "column",
          }}
        >
          <SectionTitle>Download Prunedge Techologies Procurement - Related Circular</SectionTitle>

          <SectionHeadAddendum>
            <TitleBorder2 style={{ marginTop: 0 }} />
            {/* <ViewAllButton style={{ marginTop: 0 }} onClick={() => {}}>
              View All
            </ViewAllButton> */}
          </SectionHeadAddendum>
        </SectionHead>
        <Grid container spacing={3}>
          <RelatedCircular />
        </Grid>
      </Container>

      <Container>
        <SectionHead
          style={{
            flexDirection: "column",
          }}
        >
          <SectionTitle>Solution Guide</SectionTitle>

          <SectionHeadAddendum>
            <TitleBorder2 style={{ marginTop: 0 }} />
          </SectionHeadAddendum>
        </SectionHead>
        <Grid container spacing={3}>
          <SolutionGuide />
        </Grid>
      </Container>

      <Container>
        <SectionHead style={{ flexDirection: "column" }}>
          <SectionTitle>Annual procurement Plans</SectionTitle>

          <SectionHeadAddendum>
            <TitleBorder2 style={{ marginTop: 0 }} />
            {/* <ViewAllButton style={{ marginTop: 0 }} onClick={() => {}}>
              View All
            </ViewAllButton> */}
          </SectionHeadAddendum>
        </SectionHead>
        <Grid container spacing={3}>
          <AnnualProcurementPlans />
        </Grid>
      </Container>

      <Container>
        <SectionHead
          style={{
            flexDirection: "column",
          }}
        >
          <SectionTitle>Frequently asked Questions</SectionTitle>

          <SectionHeadAddendum>
            <TitleBorder2 style={{ marginTop: 0 }} />
          </SectionHeadAddendum>
        </SectionHead>
        <AccordionBody />
      </Container>
    </div>
  );
};

export default withHeaderFooterHOC(Resources);
