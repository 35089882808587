import React from "react";
import { useHistory } from "react-router-dom";
import {
  ContractVendor,
  RegisterButton,
  RegisterNowText,
  RegisterNowWrapper,
} from "./style";
import {
  ForwardIcon,
} from "../../../components/Forms/Common/style";
import withLogin from "../../Common/HOC/withLogin";

const Component = withLogin(() => {
  const history = useHistory();

  return (
    <RegisterButton onClick={() => history.push("/create-account")}>
      <RegisterNowWrapper>
        <RegisterNowText>Register Now</RegisterNowText>
        <ContractVendor>Contractor/Vendor Registration</ContractVendor>
      </RegisterNowWrapper>
      <ForwardIcon iconcolor="#03adeb" />
    </RegisterButton>
  );
});

const VendorLogin = () => <Component
    forgotPasswordLink='/vendor/forgot-password'
    title="Vendor's Login"
    dashboardLink="/vendor/overview"
    loginRoute="/login"
  />;

export default VendorLogin;
