import styled from "styled-components";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { TableCell } from "@material-ui/core";

import { FormSubtitle, FormTitle, theme } from "../../util/theme";
import { SaveButton } from "../../components/Forms/Common/style";
import { ReactComponent as CirclePlusIcon } from "../../assets/plus.svg";

export const EmptyStyleCell = styled(TableCell)`
  text-align: center !important;
  height: 200px !important;
  justify-content: flex-end;
`;

export const PageTitle = styled(FormTitle)`
  font-size: 28px !important;
  font-weight: 400 !important;
  letter-spacing: 0 !important;
  line-height: 32px !important;
  color: #212b36 !important;
`;

export const PageBreadcrumbs = styled.p`
  color: #00b0f1;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: ${(props) => `${props.marginBottom || 0}px` || 0} !important;
`;

export const PageWrapper = styled.main`
  padding: 20px 20px 50px;
`;

export const Wrapper = styled.div`
  width: 380px;

  button {
    text-transform: capitalize !important;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 88vh;
`;

export const PasswordResetButton = styled(Button)`
  box-sizing: border-box;
  border: none;
  border-radius: 3px;
  background-color: #ffffff;
  cursor: pointer;
  color: #03adeb; !important;
  font-size: 14px !important;
  font-weight: 600;
  letter-spacing: 0;
  text-align: left !important;

`;

export const ButtonLabel = styled(Typography)`
  color: #03adeb;
  font-size: 14px !important;
  text-align: start !important;
`;

export const LoginButton = styled(Button)`
  box-sizing: border-box !important;
  height: 41px;
  min-width: 151px !important;
  border: 1px solid #ebebeb !important;
  border-radius: 3px !important;
  background-color: ${theme.color.primary} !important;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15) !important;
  color: #ffffff !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  letter-spacing: 0 !important;
`;

export const TermsAndPrivacy = styled.div`
  color: #919eab;
  font-size: 14px;
  letter-spacing: 0;
  width: 346px;
  text-align: center;
  margin: 30px auto;
`;

export const RegisterButton = styled(Button)`
  box-sizing: border-box;
  height: 75px;
  width: 100%;
  border-radius: 3px;
  background-color: #ffffff;
  cursor: pointer;
  text-align: start;
  border: 1px solid ${theme.color.primary} !important;
  padding: 15.5px !important;

  .MuiButton-label {
    display: flex;
    justify-content: space-between;
  }
`;

export const RegisterNowText = styled(Typography)`
  color: #445571 !important;
  font-size: 16px !important;
  font-weight: bold !important;
  letter-spacing: 0;
  line-height: 22px;
`;

export const RegisterNowWrapper = styled.div`
  color: #445571;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
`;

export const ContractVendor = styled.div`
  color: #637381 !important;
  font-size: 16px !important;
  letter-spacing: 0;
  line-height: 22px !important;
  font-weight: 400;
`;

export const AuthText = styled(FormTitle)`
  margin-bottom: 0 !important;
  text-align: center;
`;

export const LoginInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px !important;
  align-items: center;
`;

export const AuthSubtext = styled(FormSubtitle)`
  font-size: 14px !important;
  letter-spacing: 0;
  line-height: 22px;
  color: #919eab;
`;
export const PageContainer = styled.div`
  width: 100%;
  padding: 15px 0 30px;
`;

export const TitleContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;

  p {
    margin-bottom: 0 !important;
  }

  div > span {
    font-size: 12px;
    color: ${theme.color.textColor1};
  }
`;

export const AddButton = styled(SaveButton)`
  height: 41px;
  margin: 0 !important;
  border: 1px solid #ebebeb !important;
`;

export const CirclePlusIconWrapper = styled(CirclePlusIcon)`
  margin-right: 10.5px;
  width: 20px;
  height: 20px;
`;

export const PageTitleContainer = styled.div`
  margin-bottom: 35px;
`;

export const CardContentTitle = styled(Typography)`
  color: #5f7483 !important;
  font-size: 14px !important;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px !important;
  margin-bottom: 10px !important;
`;

export const CardContentValue = styled(Typography)`
  color: #1e2b37;
  font-size: 26px !important;
  font-weight: 600 !important;
  letter-spacing: 0;
  line-height: 35px !important;
`;

export const CardContentValueFlag = styled(Typography)`
  padding: 3px 11px;
  background-color: ${({ bgcolor }) => bgcolor};
  color: ${({ textcolor }) => textcolor};
  font-size: 12px !important;
  font-weight: 600 !important;
  border-radius: 3px !important;
  letter-spacing: 0;
  line-height: 16px !important;
  margin-right: 10px !important;
`;

export const CardContentValueDesc = styled(Typography)`
  color: #5f7483;
  font-size: 12px !important;
  letter-spacing: 0;
  line-height: 16px !important;
  margin-bottom: 7px !important;
`;

export const SearchButton = styled(SaveButton)`
  margin-left: 10px !important;
  min-width: 151px !important;
`;

export const StyleTableCellId = styled(Typography)`
  color: #03adeb;
  font-size: 14px !important;
  font-weight: 600 !important;
  letter-spacing: 0;
  line-height: 19px !important;
`;

export const StyleTableCellDesc = styled(Typography)`
  color: #1d2125;
  font-size: 14px !important;
  letter-spacing: 0;
  line-height: 19px !important;
`;

export const TableStatusContainer = styled(Typography)`
  border-radius: 20px;
  background-color: ${({ bgcolor }) => bgcolor};
  color: ${({ textcolor }) => textcolor} !important;
  min-width: 100px;
  text-align: center;
  font-size: 13px !important;
  display: table;
`;
