import { Grid, Typography } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import moment from "moment";
import React, { useContext, useEffect } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import SearchTable, { StyledTableCell, StyledTableRow } from "../../../components/SearchTable";
import adminAPI from "../../../redux/api/adminAPI";
import { getGeneralCompanyDetails, getVendorRegistrationPlan } from "../../../redux/reducer/vendorReducer";
import ToastContext from "../../../util/toastContext";
import withLoader from "../../Common/HOC/withLoader";
import Breadcrumb from "../../Common/PageElements/Breadcrumb";
import { PageTitle } from "../../Common/style";
import TableEmptyContent from "../../Common/TableEmptyContent";
import { FlexContainer } from "../../Homepage/Landing/style";
import { ContentWrapper } from "../Administration/Staff/style";
import withAdminDashboard from "../HOC/withAdminDashboard";
import ActivitiesDetails from "./Activities";
import {
  ActiveText,
  ActivitiesCard,
  CardHeaderText,
  CompletedText,
  CountText,
  IdText,
  InfoText,
  RejectText,
  SignedText,
  TableFirstCell,
  TextFlexContainer,
  UnsignedText,
  ValueText,
  VendorCardContainer,
  VendorDetailsContainer,
  VendorInfoCard,
} from "./style";
import VendorCompanyDetails from "./VendorCompanyDetails";
import VendorRegistrationCategory from "./VendorRegistrationCategory";

const TableBody = ({ allVendorProjects }) =>
  allVendorProjects.map((eachProject, index) => (
    <StyledTableRow key={`${index}_${eachProject.id}`}>
      <TableFirstCell>
        <IdText>{eachProject.title}</IdText>
        <Typography>{eachProject.description}</Typography>
      </TableFirstCell>
      <StyledTableCell>
        <Typography>{moment(eachProject.startDate).format("DD, MMMM, yyyy")}</Typography>
      </StyledTableCell>
      <StyledTableCell>
        <Typography>{moment(eachProject.endDate).format("DD, MMMM, yyyy")}</Typography>
      </StyledTableCell>
    </StyledTableRow>
  ));

const VendorDetails = () => {
  const { vendorId } = useParams();
  const { companyDetails } = useSelector((state) => state.vendor);
  const BreadLinks = [
    { url: "/admin/vendorsList/vendors", title: "Administration" },
    { url: "#", title: "Vendors" },
  ];

  const { vendorId: id } = useParams();
  const { data: projectSummary = {}, isLoading, error } = useQuery(
    ["vendorProjectSummary", id],
    adminAPI.getVendorProjectSummary
  );
  const { data: projectDetails = [], isLoading: isProjectLoading, error: isProjectError } = useQuery(
    ["vendorProjects", id],
    adminAPI.getVendorProjects
  );
  const { data: vendorActivities = [], isLoading: isActivityLoading } = useQuery(
    ["vendorActivities", id],
    adminAPI.getVendorActivities
  );

  const { data: contractSummary = {}, isLoading: isContractSummaryLoading, error: isContractError } = useQuery(
    ["vendorContractSummary", id],
    adminAPI.getVendorContractsSummary
  );

  const { data: bidSummary = {}, isLoading: isBidSummaryLoading } = useQuery(
    ["vendorBidSummary", id],
    adminAPI.getVendorBidsSummary
  );

  const { showAlert } = useContext(ToastContext);

  useEffect(() => {
    if (error) {
      showAlert({
        severity: "error",
        message: `Something went wrong while fetching project summary: ${error}`,
        durationInMs: 5000,
      });
    }

    if (isProjectError) {
      showAlert({
        severity: "error",
        message: `Something went wrong while fetching project details: ${isProjectError}`,
        durationInMs: 5000,
      });
    }

    if (isContractError) {
      showAlert({
        severity: "error",
        message: `Something went wrong while fetching contracts summary: ${isContractError}`,
        durationInMs: 5000,
      });
    }
  }, [error, isContractError, isProjectError, showAlert]);

  return (
    <ContentWrapper>
      <PageTitle>{companyDetails.companyName || null}</PageTitle>
      <Breadcrumb values={BreadLinks} />
      <VendorDetailsContainer container spacing={4}>
        <Grid item xs={12}>
          <VendorCardContainer>
            {withLoader(VendorCompanyDetails)({
              apis: [getGeneralCompanyDetails(vendorId), getVendorRegistrationPlan(vendorId)],
              conditioners: ["companyDetails", "selectedRegPlan"],
              stateName: "vendor",
              reload: true,
            })}
          </VendorCardContainer>
        </Grid>
        <Grid item xs={8}>
          <VendorInfoCard style={{ margin: 0 }}>
            <FlexContainer>
              {isLoading ? (
                <Skeleton width={270} height={150} variant={"rect"} />
              ) : (
                <div>
                  <ValueText>Total Projects</ValueText>
                  <CountText>{(!isLoading && projectSummary?.total) || 0}</CountText>
                  <InfoText>All active and completed projects</InfoText>
                  <TextFlexContainer>
                    <ActiveText>{projectSummary?.active} Active</ActiveText>
                    <CompletedText>{projectSummary?.completed} Completed</CompletedText>
                  </TextFlexContainer>
                </div>
              )}
              {isBidSummaryLoading ? (
                <Skeleton width={270} height={150} variant={"rect"} />
              ) : (
                <div>
                  <ValueText>Total Bids</ValueText>
                  <CountText>{!isBidSummaryLoading && bidSummary?.total}</CountText>
                  <InfoText>All approved and rejected bids</InfoText>
                  <TextFlexContainer>
                    <ActiveText>{bidSummary?.approved} Approved</ActiveText>
                    <RejectText>{bidSummary?.rejected} Rejected</RejectText>
                  </TextFlexContainer>
                </div>
              )}
              {isContractSummaryLoading ? (
                <Skeleton width={270} height={150} variant={"rect"} />
              ) : (
                <div>
                  <ValueText>Total Contracts</ValueText>
                  <CountText>{!isContractSummaryLoading && contractSummary?.total}</CountText>
                  <InfoText>All signed and unsigned contracts</InfoText>
                  <TextFlexContainer>
                    <SignedText>{contractSummary?.signed} Signed</SignedText>
                    <UnsignedText>{contractSummary?.unsigned} Unsigned</UnsignedText>
                  </TextFlexContainer>
                </div>
              )}
            </FlexContainer>
          </VendorInfoCard>
          <SearchTable
            rows={projectDetails?.length}
            columns={["NAME", "START DATE", "END DATE"]}
            tableHeaderText="Projects"
            isLoading={isProjectLoading}
          >
            {!isProjectLoading && projectDetails?.length > 0 && <TableBody allVendorProjects={projectDetails} />}
            {!isProjectLoading && projectDetails?.length === 0 && (
              <TableEmptyContent noColSpan={3} textDisplayed="This vendor has no project yet" />
            )}
          </SearchTable>
        </Grid>
        <Grid item xs={4}>
          <VendorCardContainer>
            {withLoader(VendorRegistrationCategory)({
              apis: [getVendorRegistrationPlan(vendorId)],
              conditioners: ["selectedRegPlan"],
              stateName: "vendor",
              reload: true,
            })}
          </VendorCardContainer>

          <ActivitiesCard>
            {isActivityLoading ? (
              <Skeleton width={400} height={630} variant={"rect"} />
            ) : (
              <>
                <CardHeaderText>Activities</CardHeaderText>
                <ActivitiesDetails allActivities={vendorActivities} />
              </>
            )}
          </ActivitiesCard>
        </Grid>
      </VendorDetailsContainer>
    </ContentWrapper>
  );
};

export default withAdminDashboard(VendorDetails);
