export const tenderBidders = [
  {
    id: "EPHC_01", name: "Kansai Plascon", dateBid: "03/10/2020", bidStatus: "Pending Approval",
  },
  {
    id: "EPHC_01", name: "Boneline Generals Traders Limited", dateBid: "03/10/2020", bidStatus: "Pending Approval",
  },
  {
    id: "EPHC_01", name: "Kansai Plascon", dateBid: "03/10/2020", bidStatus: "Pending Approval",
  },
  {
    id: "EPHC_01", name: "Boneline Generals Traders Limited", dateBid: "03/10/2020", bidStatus: "Pending Approval",
  },
  {
    id: "EPHC_01", name: "Kansai Plascon", dateBid: "03/10/2020", bidStatus: "Pending Approval",
  },
];
