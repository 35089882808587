import React from "react";
import {
  Accordion,
  AccordionDescription,
  AccordionDetails,
  AccordionExpandMoreIcon,
  AccordionSummary,
} from "./style";

const MainAccordion = ({ title, description }) => (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<AccordionExpandMoreIcon style={{ color: "#03adeb" }} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          {title}
        </AccordionSummary>
        <AccordionDetails>
          <AccordionDescription>{description}</AccordionDescription>
        </AccordionDetails>
      </Accordion>
    </div>
);

export default MainAccordion;
