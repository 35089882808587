import styled from "styled-components";
import { FormTitle } from "../../../../util/theme";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 89px 0;
`;

export const EmptyTableTitle = styled(FormTitle)`
  font-size: 17px !important;
  color: #1e2b37;
  font-weight: 600;
`;

export const EmptyTableSubTitle = styled(FormTitle)`
  font-size: 17px !important;
  color: #1e2b37;
`;
