import React, { useState } from "react";
import Drawer from "@material-ui/core/Drawer";

import {
  Wrapper,
} from "./style";
import { FormTitle, FormSubtitle } from "../../../util/theme";

import { ButtonContainer, CancelButton, SaveButton } from "../Common/style";
import FileUploadSection from "../../FileUploadSection";

const AddNewDocuments = ({
  isOpen,
  toggleDrawer,
  activeGroup,
  setDocuments,
  documents,
  description,
  makeRequest,
}) => {
  const [filesNames, setfilesNames] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleDescription = (e) => {
    const fileIndex = filesNames.findIndex((file) => file.name === e.target.name);
    filesNames[fileIndex].description = e.target.value;
    setfilesNames(filesNames);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    makeRequest(filesNames, activeGroup);
  };

  const handleCancel = () => {
    setfilesNames([]);
    toggleDrawer(activeGroup);
  };

  return (
    <Drawer anchor="right" open={isOpen} >
      <Wrapper >
        <FormTitle>Upload Document</FormTitle>
        <FormSubtitle>{description}</FormSubtitle>
        <form onSubmit={onSubmit} style={{ marginTop: "29.5px" }}>
          <FileUploadSection
            title="Documents"
            setfilesNames={setfilesNames}
            filesNames={filesNames}
            singleFileUpload={false}
            setDescription={handleDescription}
            description
          />
          <ButtonContainer>
            <CancelButton type="button" onClick={handleCancel}>Cancel</CancelButton>
            <SaveButton disabled={loading} type="submit">{loading ? "Please Wait..." : "Save"}</SaveButton>
          </ButtonContainer>
        </form>
      </Wrapper>
    </Drawer>
  );
};

export default AddNewDocuments;
