import React from "react";
import { useSelector } from "react-redux";
import { DocumentDetails, FileIcon } from "./style";
import { FormSubtitle } from "../../../util/theme";

const UploadedDocuments = ({ documents, title, handleDelete }) => {
  const { loading } = useSelector((state) => state.vendor);

  return (
    documents[title].map(({ name, description, id }, i) => (
    <DocumentDetails key={`${name}-${i}`}>
    <div>
        <FileIcon />
      </div>
      <div>
        <FormSubtitle>
          {name}
        </FormSubtitle>
        <FormSubtitle>
          {description}
        </FormSubtitle>
        <FormSubtitle>
          <span>{new Date().toDateString().slice(4)}</span>
          <span onClick={() => handleDelete(id)}>{loading === `deleting ${id}` ? "Please Wait..." : "Delete"}</span>
        </FormSubtitle>
      </div>
    </DocumentDetails>
    ))
  );
};

export default UploadedDocuments;
