export const tableRows = [
  {
    id: "PR-0129",
    desc: "Completion Of Resettlement Scheme At Agbowa….",
    category: "Works",
    method: "Request for Bids",
    status: "Approved",
    process: "One Envelop",
    amount: "N238,923.928.00",
  },
  {
    id: "PR-0129",
    desc: "Completion Of Resettlement Scheme At Agbowa….",
    category: "Works",
    method: "Request for Bids",
    status: "Approved",
    process: "One Envelop",
    amount: "N238,923.928.00",
  },
  {
    id: "PR-0129",
    desc: "Completion Of Resettlement Scheme At Agbowa….",
    category: "Works",
    method: "Request for Bids",
    status: "Approved",
    process: "One Envelop",
    amount: "N238,923.928.00",
  },
  {
    id: "PR-0129",
    desc: "Completion Of Resettlement Scheme At Agbowa….",
    category: "Works",
    method: "Request for Bids",
    status: "Approved",
    process: "One Envelop",
    amount: "N238,923.928.00",
  },
  {
    id: "PR-0129",
    desc: "Completion Of Resettlement Scheme At Agbowa….",
    category: "Works",
    method: "Request for Bids",
    status: "Approved",
    process: "One Envelop",
    amount: "N238,923.928.00",
  },
  {
    id: "PR-0129",
    desc: "Completion Of Resettlement Scheme At Agbowa….",
    category: "Works",
    method: "Request for Bids",
    status: "Approved",
    process: "One Envelop",
    amount: "N238,923.928.00",
  },
  {
    id: "PR-0129",
    desc: "Completion Of Resettlement Scheme At Agbowa….",
    category: "Works",
    method: "Request for Bids",
    status: "Approved",
    process: "One Envelop",
    amount: "N238,923.928.00",
  },
  {
    id: "PR-0129",
    desc: "Completion Of Resettlement Scheme At Agbowa….",
    category: "Works",
    method: "Request for Bids",
    status: "Approved",
    process: "One Envelop",
    amount: "N238,923.928.00",
  },
  {
    id: "PR-0129",
    desc: "Completion Of Resettlement Scheme At Agbowa….",
    category: "Works",
    method: "Request for Bids",
    status: "Approved",
    process: "One Envelop",
    amount: "N238,923.928.00",
  },
];

export const tenderTableRows = [
  {
    id: "PR-0129",
    desc: "Completion Of Resettlement Scheme At Agbowa….",
    status: "Approved",
    process: "One Envelop",
    name: "National Irrigation Board",
    closing_date: "Jul 23, 2020",
  },
  {
    id: "PR-0129",
    desc: "Completion Of Resettlement Scheme At Agbowa….",
    status: "Approved",
    process: "One Envelop",
    name: "National Irrigation Board",
    closing_date: "Jul 23, 2020",
  },
  {
    id: "PR-0129",
    desc: "Completion Of Resettlement Scheme At Agbowa….",
    status: "Approved",
    process: "One Envelop",
    name: "National Irrigation Board",
    closing_date: "Jul 23, 2020",
  },
  {
    id: "PR-0129",
    desc: "Completion Of Resettlement Scheme At Agbowa….",
    status: "Approved",
    process: "One Envelop",
    name: "National Irrigation Board",
    closing_date: "Jul 23, 2020",
  },
  {
    id: "PR-0129",
    desc: "Completion Of Resettlement Scheme At Agbowa….",
    status: "Approved",
    process: "One Envelop",
    name: "National Irrigation Board",
    closing_date: "Jul 23, 2020",
  },
  {
    id: "PR-0129",
    desc: "Completion Of Resettlement Scheme At Agbowa….",
    status: "Approved",
    process: "One Envelop",
    name: "National Irrigation Board",
    closing_date: "Jul 23, 2020",
  },
  {
    id: "PR-0129",
    desc: "Completion Of Resettlement Scheme At Agbowa….",
    status: "Approved",
    process: "One Envelop",
    name: "National Irrigation Board",
    closing_date: "Jul 23, 2020",
  },
  {
    id: "PR-0129",
    desc: "Completion Of Resettlement Scheme At Agbowa….",
    status: "Approved",
    process: "One Envelop",
    name: "National Irrigation Board",
    closing_date: "Jul 23, 2020",
  },
];

export const generalPlans = [
  {
    id: "DS-MOA-PW-ICB-001-2020",
    desc: "Delta Irrigated Agriculture Improvement",
    category: "Works",
    method: "ICB",
    status: "Approved",
    review: "Prior",
    amount: "N238,923.928.00",
  },
  {
    id: "DS-MOA-PW-ICB-001-2020",
    desc: "Completion Of Resettlement Scheme At Agbowa….",
    category: "Consultancy",
    method: "ICB",
    status: "Approved",
    review: "Post",
    amount: "N238,923.928.00",
  },
  {
    id: "DS-MOA-PW-ICB-001-2020",
    desc: "Completion Of Resettlement Scheme At Agbowa….",
    category: "Works",
    method: "ICB",
    status: "Approved",
    review: "Post",
    amount: "N238,923.928.00",
  },
  {
    id: "DS-MOA-PW-ICB-001-2020",
    desc: "Completion Of Resettlement Scheme At Agbowa….",
    category: "Goods",
    method: "ICB",
    status: "Approved",
    review: "Post",
    amount: "N238,923.928.00",
  },
  {
    id: "DS-MOA-PW-ICB-001-2020",
    desc: "Completion Of Resettlement Scheme At Agbowa….",
    category: "Non-Consutancy",
    method: "ICB",
    status: "Incomplete",
    review: "Post",
    amount: "N238,923.928.00",
  },
  {
    id: "DS-MOA-PW-ICB-001-2020",
    desc: "Completion Of Resettlement Scheme At Agbowa….",
    category: "Goods",
    method: "ICB",
    status: "Approved",
    review: "Post",
    amount: "N238,923.928.00",
  },
  {
    id: "DS-MOA-PW-ICB-001-2020",
    desc: "Completion Of Resettlement Scheme At Agbowa….",
    category: "Goods",
    method: "ICB",
    status: "Approved",
    review: "Post",
    amount: "N238,923.928.00",
  },
  {
    id: "DS-MOA-PW-ICB-001-2020",
    desc: "Completion Of Resettlement Scheme At Agbowa….",
    category: "Works",
    method: "ICB",
    status: "Incomplete",
    review: "Post",
    amount: "N238,923.928.00",
  },
  {
    id: "DS-MOA-PW-ICB-001-2020",
    desc: "Completion Of Resettlement Scheme At Agbowa….",
    category: "Works",
    method: "ICB",
    status: "Incomplete",
    review: "Post",
    amount: "N238,923.928.00",
  },
];

export const generalPlansTableRows = [
  {
    id: "PR-0129",
    desc: "Ministry of Agriculture And Rural Development",
    acronym: "MOA",
    status: "Approved",
    noPlan: 2,
    amount: "N238,923.928.00",
  },
  {
    id: "PR-0129",
    desc: "Ministry of Public FInacial Management",
    acronym: "MPF",
    status: "Pending",
    noPlan: 3,
    amount: "N238,923.928.00",
  },
  {
    id: "PR-0129",
    desc: "Ministry of Public FInacial Management",
    acronym: "MPF",
    status: "Pending",
    noPlan: 3,
    amount: "N238,923.928.00",
  },
  {
    id: "PR-0129",
    desc: "Ministry of Public FInacial Management",
    acronym: "MPF",
    status: "Pending",
    noPlan: 3,
    amount: "N238,923.928.00",
  },
  {
    id: "PR-0129",
    desc: "Ministry of Public FInacial Management",
    acronym: "MPF",
    status: "Pending",
    noPlan: 3,
    amount: "N238,923.928.00",
  },
  {
    id: "PR-0129",
    desc: "Ministry of Public FInacial Management",
    acronym: "MPF",
    status: "Pending",
    noPlan: 3,
    amount: "N238,923.928.00",
  },
  {
    id: "PR-0129",
    desc: "Ministry of Public FInacial Management",
    acronym: "MPF",
    status: "Pending",
    noPlan: 3,
    amount: "N238,923.928.00",
  },
  {
    id: "PR-0129",
    desc: "Ministry of Public FInacial Management",
    acronym: "MPF",
    status: "Pending",
    noPlan: 3,
    amount: "N238,923.928.00",
  },
  {
    id: "PR-0129",
    desc: "Ministry of Public FInacial Management",
    acronym: "MPF",
    status: "Pending",
    noPlan: 3,
    amount: "N238,923.928.00",
  },
];
