/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/Common/Loader";
import ToastContext from "../../../util/toastContext";

const withLoader = (WrappedComponent) => ({
  stateName, title, description, apis, conditioners, reload, styles, load=true, ...rest
}) => {
  const dispatch = useDispatch();
  const { showAlert } = useContext(ToastContext);
 

  if (!load) return null

  let apisloaded = [];
  if (Array.isArray(stateName)) {
    stateName.forEach((s) => {
      const { loaded } = useSelector((state) => state[s]);
      apisloaded = apisloaded.concat(loaded);
    });
  } else {
    const { loaded } = useSelector((state) => state[stateName]);
    apisloaded = loaded;
  }
  const request = async () => {
    await apis.forEach((api) => {
      dispatch(api).then((res) => {
        if (res.error) {
          showAlert({ severity: "error", message: res.payload });
        }
      });
    });
  };

  const allLoaded = conditioners.every((val) => apisloaded.indexOf(val) !== -1);
  useEffect(() => {
    if (!allLoaded || reload) request();
  }, [allLoaded]);

  const props = {
    dispatch,
    ...rest,
  };

  return !allLoaded
    ? <Loader customStyles={styles} />
    : <WrappedComponent {...props}/>;
};

export default withLoader;
