import React, { useContext, useState } from "react";
import { queryCache, useMutation, useQuery } from "react-query";
import { Grid } from "@material-ui/core";
import format from "date-fns/format";
import { TitleContainer } from "../../style";
import { PageTitle, PageWrapper } from "../../../../Common/style";
import Breadcrumb from "../../../../Common/PageElements/Breadcrumb";
import TopStatisticsBar from "../../../../../components/TopStatisticsBar";
import {
  CertificateOfNoObjectionWidgetLeft,
  CertificateOfNoObjectionWidgetMiddle,
  InstructionDescription,
  InstructionHeader,
  UploadButton,
} from "./style";
import FileProgress from "../../../../../components/FileProgress/FileProgress";
import LoadingButton from "../../../../../components/LoadingButton/LoadingButton";
import Box from "@material-ui/core/Box";
import EvaluatedVendorsTable from "../../../../../components/EvaluatedVendorsTable/EvaluatedVendorsTable";
import { useParams } from "react-router-dom";
import certificateOfNoObjectionApi from "../../../../../redux/api/certificateOfNoObjectionApi";
import ToastContext from "../../../../../util/toastContext";
import Paper from "@material-ui/core/Paper";
import { ReactComponent as SaveIcon } from "../../../../../assets/save.svg";
import { ReactComponent as SendIcon } from "../../../../../assets/send-2.svg";
import { openFileUrlInNewWindow } from "../../../../Common/util";
import AccessControl from "../../../../../components/AccessControl";
import { Roles } from "../../../../../components/AccessControl/UserRoles";
import { useRoles } from "../../../../../hooks/useUserDetails";
import FormDrawer from "./FormDrawer";
import procurementAPI from "../../../../../redux/api/procurementAPI";
import { useFecthCertificate } from "../../../../../hooks/queries/useCertificate";


const baseVendorObject = {
  data: {
    responseSummary: {
      total: 0,
      recommended: 0,
      notRecommended: 0,
    },
    bidRecommendations: [],
  },
  pagination: {
    totalEntries: 0,
  },
};

const CertificateOfNoObjection = () => {
  const { procurementId, activityId } = useParams();
  const [openDrawer, setOpenDrawer] = useState(false)

  const generalPlanLinks = [
    {
      url: "/dashboard/overview",
      title: "Home",
    },
    {
      url: `/admin/procurement/plans`,
      title: "Plans",
    },
    {
      url: `/admin/procurement/plan-details/${procurementId}`,
      title: "Plan Details",
    },
  ];

  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
  });
  const [uploadFile, setUploadFile] = useState(null);

  const bidRecommendationsQuery = useQuery({
    queryKey: [
      "bidRecommendations",
      {
        PageSize: pagination.pageSize,
        PageNumber: pagination.pageNumber,
        procurementId,
      },
    ],
    queryFn: certificateOfNoObjectionApi.getBidRecommendations,
  });

  // const certificate = useQuery({
  //   queryFn: certificateOfNoObjectionApi.getCertificate,
  //   queryKey: [
  //     "certificateOfObjection",
  //     {
  //       procurementId,
  //     },
  //   ],
  // });

  const certificate = useFecthCertificate({ procurementId })

  const { data: activityDetails } = useQuery({
    queryKey: [
      "activityDetails",
      {
        activityId,
      },
    ],
    queryFn: procurementAPI.getActivityById,
  });

  const [uploadCertificate, uploadCertificateQuery] = useMutation(certificateOfNoObjectionApi.uploadCertificateOfObjection, {
    throwOnError: true,
  });

  const { showAlert } = useContext(ToastContext);

  const onClickUpload = async () => {
    try {
      await uploadCertificate({
        procurementId,
        File: uploadFile,
      });

      queryCache.removeQueries("certificateOfObjection");

      showAlert({
        message: "Successfully uploaded documents",
        severity: "success",
        durationInMs: 3000,
      });
    } catch (e) {
      showAlert({
        message: e.message,
        severity: "error",
      });
    }
  };


  const updatePagination = (key) => (value) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      [key]: value,
    }));
  };

  const {
    data: { bidRecommendations, responseSummary },
    pagination: apiPagination,
  } = Object.assign(baseVendorObject, bidRecommendationsQuery.data);
  const { data: certificateData } = certificate;

  const { role } = useRoles();

  const renderLeftContent = () => {
    if (certificateData?.length > 0 && role === Roles.PROCUREMENT_OFFICER) {
      const file = certificateData[0];

      return (
        <CertificateOfNoObjectionWidgetLeft>
          <p>Date Recieved</p>
          <p>{format(new Date(file.createdAt), "dd MMM, yyyy")}</p>
        </CertificateOfNoObjectionWidgetLeft>
      );
    }

    return (
      <CertificateOfNoObjectionWidgetLeft>
        <p>Total Vendors</p>
        <p>{responseSummary.total}</p>
      </CertificateOfNoObjectionWidgetLeft>
    );
  };

  const renderCenterContent = () => {
    const hasFile = certificateData?.length > 0;

    if (hasFile && role === Roles.PROCUREMENT_OFFICER) {
      const file = certificateData[0];

      return (
        <CertificateOfNoObjectionWidgetLeft style={{ width: "73%" }}>
          <p>Certificate of No Objection</p>
          <Box mt={1.2} container>
            <Grid item xs={6}>
              <FileProgress
                progress={100}
                hasEyes
                onClickEyes={() => openFileUrlInNewWindow(file.file.url)}
                fileName='CNO-award-contract'
                fileSize={file.file.bytes}
              />
            </Grid>
          </Box>
        </CertificateOfNoObjectionWidgetLeft>
      );
    }

    return (
      <CertificateOfNoObjectionWidgetMiddle>
        <div>
          <p>Recommended</p>
          <p>{responseSummary.recommended}</p>
        </div>
        <div>
          <p>Not Recommended</p>
          <p>{responseSummary.notRecommended}</p>
        </div>
        <div>
          <AccessControl allowedRoles={[Roles.PROCUREMENT_OFFICER]}>
            <LoadingButton
              disabled={activityDetails?.procurementPlanActivityStatus?.toLowerCase() === 'awaiting'}
              onClick={() => setOpenDrawer(true)}
              color="#00b0f1"
            >
              {activityDetails?.procurementPlanActivityStatus?.toLowerCase() === 'awaiting'
                ? "Waiting for Certificate of No Objection"
                : activityDetails?.procurementPlanActivityStatus?.toLowerCase() === 'amendment'
                  ? "Amend CNO Request"
                  : 'Request for certificate of no objection'
              }
            </LoadingButton>
          </AccessControl>
        </div>
      </CertificateOfNoObjectionWidgetMiddle>
    );
  };


  const hasFile = certificateData?.length > 0;

  const renderActionSection = () => {
    let bottomSection;

    if (hasFile) {
      const file = certificateData[0];
      bottomSection = (
        <Box width="400px" mb={2.5}>
          <FileProgress
            progress={100}
            fileName='CNO-award-contract'
            fileSize={file.file.bytes}
            hasEyes
            onClickEyes={() => openFileUrlInNewWindow(file.file.url)}
          />
        </Box>
      );
    } else {
      if (uploadFile) {
        bottomSection = (
          <>
            <Box width="400px" mb={2.5}>
              <FileProgress
                progress={100}
                fileName={uploadFile.name}
                fileSize={uploadFile.size}
                hasCancel
                onCancel={() => setUploadFile(null)}
              />
            </Box>
            <LoadingButton
              color="#219653"
              endIcon={<SendIcon width="19px" />}
              style={{
                marginLeft: "0px",
              }}
              onClick={onClickUpload}
              loading={uploadCertificateQuery.isLoading}
            >
              Send Certificate
            </LoadingButton>
          </>
        );
      } else {
        bottomSection = (
          <>
            <UploadButton
              variant="contained"
              onClick={() => setOpenDrawer(true)}
              endIcon={
                <SaveIcon
                  width="19px"
                  fill="white"
                  style={{
                    transform: "rotate(180deg)",
                  }}
                />
              }
            >
              Review and generate certificate
            </UploadButton>
          </>
        );
      }
    }

    return (
      <Paper elevation={2}>
        <Box p={3.75}>
          <InstructionHeader>Instruction</InstructionHeader>
          <InstructionDescription>
            Telling the user about uploading certificate of no objection Telling the user about uploading certificate of no
            objection Telling the user about uploading certificate of no objection Telling the user about uploading certificate of
            no objection
          </InstructionDescription>
          {bottomSection}
        </Box>
      </Paper>
    );
  };

  const onCloseDrawer = () => {
    setOpenDrawer(false);
  };

  return (
    <>
      <PageWrapper>
        <TitleContainer>
          <div>
            <PageTitle>Certificate of No Objection</PageTitle>
            <Breadcrumb values={generalPlanLinks} />
          </div>
        </TitleContainer>
        <Box>
          <TopStatisticsBar
            leftContent={renderLeftContent()}
            centerContent={renderCenterContent()}
            status={hasFile ? "approved" : "pending"}
            isLoading={certificate.isLoading || bidRecommendationsQuery.isLoading}
            hasMargin={false}
          />
          {certificate.isSuccess && <AccessControl allowedRoles={[Roles.DSPPC]}>{renderActionSection()}</AccessControl>}
        </Box>
        <EvaluatedVendorsTable
          isLoading={bidRecommendationsQuery.isLoading}
          vendors={bidRecommendations}
          showRecommended
          pageNumber={pagination.pageNumber}
          pageSize={pagination.pageSize}
          setPageNumber={updatePagination("pageNumber")}
          setPageSize={updatePagination("pageSize")}
          totalPages={apiPagination.totalEntries}
          type="certificate"
        />
      </PageWrapper>
      <FormDrawer toggleDrawer={onCloseDrawer} openDrawer={openDrawer} />
    </>
  );
};

export default CertificateOfNoObjection;
