import MuiAlert from "@material-ui/lab/Alert";
import styled from "styled-components";

export const CustomAlert = styled(MuiAlert)`
  display: flex;
  align-items: center;
  max-width: 400px;

  .MuiAlert-message {
    display: flex;
    align-items: center;
  }

  svg {
    width: 16px;
    margin-left: 20px;
    cursor: pointer;
  }
`;
