import Drawer from "@material-ui/core/Drawer";
import React, { useContext, useEffect, useState } from 'react'
import { useForm } from "react-hook-form";
import { InputField } from "../../../../../components";
import { Roles } from "../../../../../components/AccessControl/UserRoles";
import { ErrorContainer } from "../../../../../components/Forms/Common/style";
import { useRoles } from "../../../../../hooks/useUserDetails";
import ToastContext from "../../../../../util/toastContext";
import { BtnBox, CancelButton, UpdateButton } from "../../../Administration/Settings/style";
import { DrawerWrapper, DrawerFormTitle, InputFieldBox, ModalBody, ModalTitle, ModalDescription } from "./style";
import Modal from "@material-ui/core/Modal";
import { Box, TextField } from "@material-ui/core";
import { ReactComponent as CancelIcon } from "../../../../../assets/cancel.svg";
import { useHistory, useParams } from "react-router-dom";
import { queryCache } from "react-query";
import {
  useCreateCertificate,
  useFetchCertificateData,
  useRequestCertificateAmendment,
  useUpdateCertificate
} from "../../../../../hooks/queries/useCertificate";

const cnoDefaultValues = {
  serialNumber: 0,
  headCode: "",
  subHeadCode: "",
  appropriationCode: "",
  reviewedQuotation: 0,
  costReduction: 0,
}
function FormDrawer({ openDrawer, toggleDrawer }) {
  const { register, handleSubmit, errors, reset, setValue, watch } = useForm({
    defaultValues: cnoDefaultValues
  });
  const onRenderError = (error) => <ErrorContainer>{error && error.message}</ErrorContainer>;
  const { showAlert } = useContext(ToastContext);
  const [openModal, setCloseModal] = useState(false)
  const { activityId, procurementId } = useParams();
  const history = useHistory();
  const [isEdit, setIsEdit] = useState(false);
  const path = `/admin/procurement/activity/certificateofnoobjection/${activityId}/${procurementId}/certificate`

  //Access control config
  const { role, isSuccess: getUserRolesSuccess } = useRoles();
  const isAdmin = role === Roles.DSPPC;

  const { data: certificateData } = useFetchCertificateData({ procurementId, showAlert, setIsEdit })

  const [createCerticate, { isLoading: creatingCertificate }] = useCreateCertificate({
    showAlert, queryCache, activityId, setModal: toggleDrawer, history, path
  });

  const [updateCertificate, { isLoading: updatingCertificate }] = useUpdateCertificate({
    procurementId, showAlert, queryCache, setModal: toggleDrawer, history, path
  });


  useEffect(() => {
    if (watch("costReduction") && watch("reviewedQuotation") && certificateData?.data?.quotation) {
      setValue("reviewedQuotation", certificateData?.data?.quotation - watch("costReduction"))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("costReduction"), certificateData?.data])

  useEffect(() => {
    if (watch("costReduction") && watch("reviewedQuotation") && certificateData?.data?.quotation) {
      setValue("costReduction", certificateData?.data?.quotation - watch("reviewedQuotation"))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("reviewedQuotation"), certificateData?.data])

  const [requestAmendmentMuation, { isLoading: isrequestAmendmentLoading }] = useRequestCertificateAmendment({
    showAlert, activityId, setModal: setCloseModal, setDrawer: toggleDrawer
  });

  const onSubmit = (data) => {

    const payload = {
      headCode: data.headCode,
      subHeadCode: data.subHeadCode,
      appropriationCode: data.appropriationCode,
      reviewedQuotation: parseInt(data.reviewedQuotation, 10),
      costReduction: parseInt(data.costReduction),

    }

    isEdit ? updateCertificate({ payload }) : createCerticate({ payload })
  }

  const OnGenerateCertificate = (data) => {
    const payload = { ...data }
    updateCertificate({ payload })
    // history.push(`/admin/procurement/activity/certificateofnoobjection/${activityId}/${procurementId}/certificate`)
  }

  useEffect(() => {
    reset({
      serialNumber: certificateData?.data?.serialNumber,
      headCode: certificateData?.data?.headCode,
      subHeadCode: certificateData?.data?.subHeadCode,
      appropriationCode: certificateData?.data?.appropriationCode,
      reviewedQuotation: certificateData?.data?.reviewedQuotation && certificateData?.data?.costReduction && certificateData?.data?.quotation
        ? certificateData?.data?.quotation - certificateData?.data?.costReduction : certificateData?.data?.reviewedQuotation,
      costReduction: certificateData?.data?.reviewedQuotation && certificateData?.data?.costReduction && certificateData?.data?.quotation
        ? certificateData?.data?.quotation - certificateData?.data?.reviewedQuotation : certificateData?.data?.costReduction,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [certificateData])

  useEffect(() => {
    if (!getUserRolesSuccess) {
      return showAlert({
        severity: "error",
        message: "Network Error. Kindly Refresh",
      });
    }
  }, [getUserRolesSuccess, showAlert]);

  return (
    <div>
      <Drawer anchor="right" open={openDrawer}>
        <DrawerWrapper>
          <div style={{
            width: '20px',
            height: '20px',
            display: 'flex',
            float: 'right',
            marginTop: '1rem',
            cursor: 'pointer'
          }}
            onClick={toggleDrawer}
          >
            <CancelIcon />
          </div>
          <DrawerFormTitle style={{ clear: 'right' }}>
            Certificate of No Objection for Award of Contract
          </DrawerFormTitle>
          {
            certificateData?.data?.message &&
            <div style={{ background: "#ffffff" }}>
              <TextField
                id="outlined-textarea"
                label='Message from DSPPC'
                multiline
                disabled
                InputProps={{
                  disableUnderline: true,
                }}
                value={certificateData?.data?.message}
                variant="outlined"
                rows={4}
                style={{ marginBottom: 20 }}
              />
            </div>
          }
          <div style={{ background: "#ffffff" }}>
            <InputField
              label="Serial Number"
              name="serialNumber"
              style={{ background: "#ffffff" }}
              disabled
              value={certificateData?.data?.serialNumber || ""}
            />
            {onRenderError(errors.serialNumber)}
          </div>
          <div style={{ background: "#ffffff" }}>
            <InputField
              label="Contract Ref. No."
              style={{ background: "#ffffff" }}
              value={certificateData?.data?.packageNumber}
              disabled
            />
          </div>
          <div style={{ background: "#ffffff" }}>
            <InputField
              label="Project Description"
              style={{ background: "#ffffff" }}
              value={certificateData?.data?.projectDescription}
              disabled
            />
          </div>
          <div style={{ background: "#ffffff" }}>
            <InputField
              label="Project Contractor"
              style={{ background: "#ffffff" }}
              value={certificateData?.data?.contractor}
              disabled
            />
          </div>
          <div style={{ background: "#ffffff" }}>
            <InputField
              label="Project Cost as Requested"
              style={{ background: "#fff" }}
              value={certificateData?.data?.quotation}
              disabled
            />
          </div>
          <div style={{ background: "#ffffff" }}>
            <InputField
              label="DSPPC Reviewed Total Project Cost"
              name="reviewedQuotation"
              type='number'
              style={{ background: "#ffffff" }}
              // disabled={isProcurementOfficer}
              min={0}
              max={certificateData?.data?.quotation || 0}
              inputRef={register({
                required: "Enter total project cost",
                min: 0,
                max: certificateData?.data?.quotation || 0
              })}
            />
            {onRenderError(errors.reviewedQuotation)}
          </div>
          <div style={{ background: "#ffffff" }}>
            <InputField
              label="Cost Reduction"
              name="costReduction"
              type="number"
              min={0}
              disabled
              max={certificateData?.data?.quotation || 0}
              style={{ background: "#ffffff" }}
              inputRef={register({
                required: "Enter Head Code",
                min: 0,
                max: certificateData?.data?.quotation || 0
              })}
            />
            {onRenderError(errors.costReduction)}
          </div>

          <InputFieldBox>
            <div style={{ background: "#ffffff" }}>
              <InputField
                label="Head Code	"
                name="headCode"
                style={{ background: "#ffffff" }}
                // disabled={isProcurementOfficer}
                inputRef={register({
                  required: "Enter Head Code",
                })}
              />
              {onRenderError(errors.headCode)}
            </div>
            <div style={{ background: "#ffffff" }}>
              <InputField
                label="Sub-Head Code"
                name="subHeadCode"
                style={{ background: "#ffffff" }}
                // disabled={isProcurementOfficer}
                inputRef={register({
                  required: "Enter Sub-head code",
                })}
              />
              {onRenderError(errors.subHeadCode)}
            </div>
            <div style={{ background: "#ffffff" }}>
              <InputField
                label="Appropriation Code"
                name="appropriationCode"
                style={{ background: "#ffffff" }}
                // disabled={isProcurementOfficer}
                inputRef={register({
                  required: "Enter Appropriation Code",
                })}
              />
              {onRenderError(errors.appropriationCode)}
            </div>
          </InputFieldBox>
          <div style={{ background: "#ffffff" }}>
            <InputField
              label="Client Ministry/ Department/ Agency	"
              style={{ background: "#ffffff" }}
              disabled
              value={certificateData?.data?.ministry}
            />
          </div>
          {
            isAdmin ?
              <BtnBox>
                <CancelButton onClick={() => setCloseModal(true)}>Request Amendment</CancelButton>
                <UpdateButton
                  style={{ marginLeft: '1rem' }}
                  onClick={handleSubmit(OnGenerateCertificate)}
                  disabled={creatingCertificate || updatingCertificate}>
                  Preview Certificate of No Objection
                </UpdateButton>
              </BtnBox>
              :
              <BtnBox>
                <CancelButton onClick={toggleDrawer}>Cancel</CancelButton>
                <UpdateButton
                  style={{ marginLeft: '1rem' }}
                  onClick={handleSubmit(onSubmit)}
                  disabled={updatingCertificate}>
                  {updatingCertificate ? 'Sending...' : 'Send Request'}
                </UpdateButton>
              </BtnBox>
          }
        </DrawerWrapper>
      </Drawer>
      <AdminModal openModal={openModal} setCloseModal={setCloseModal} requestAmendmentMuation={requestAmendmentMuation} isrequestAmendmentLoading={isrequestAmendmentLoading} activityId={activityId} />
    </div>
  )
}

export default FormDrawer;


const AdminModal = ({ openModal, setCloseModal, requestAmendmentMuation, isrequestAmendmentLoading, activityId }) => {
  const [reasonForAmendment, setReasonForAmendment] = useState("");

  const handleClick = () => {
    let payload = {
      message: reasonForAmendment
    }
    requestAmendmentMuation({ payload, activityId })
  }

  return (
    <Modal open={openModal} onClose={() => setCloseModal(false)}>
      <ModalBody >
        <ModalTitle>Request Amendment</ModalTitle>
        <ModalDescription>Send a follow-up message to the procurement officer</ModalDescription>
        <TextField
          id="outlined-textarea"
          multiline
          InputProps={{
            disableUnderline: true,
          }}
          onChange={(e) => setReasonForAmendment(e.target.value)}
          value={reasonForAmendment}
          variant="outlined"
          placeholder="Type reason here"
          rows={4}
          style={{ marginBottom: 20 }}
        />
        <Box sx={{ display: 'flex' }}>
          <UpdateButton
            disabled={isrequestAmendmentLoading}
            onClick={handleClick}
          >
            {isrequestAmendmentLoading ? 'Requesting...' : 'Request Amendment'}
          </UpdateButton>
          <CancelButton onClick={() => setCloseModal(false)} style={{
            marginLeft: "1rem",
          }}>
            Cancel
          </CancelButton>
        </Box>
      </ModalBody>
    </Modal>
  )
}
