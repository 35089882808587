export const tableRows = [
  {
    refId: "PR-0129",
    title: "Completion Of Resettlement Scheme At Agbowa...",
    category: "Awarded",
    ministry: "Ministry of Works",
    expiryDate: "Jul 23,2020",
    value: "N23,000,000.00",
  },
  {
    refId: "PR-0129",
    title: "Completion Of Resettlement Scheme At Agbowa...",
    category: "Awarded",
    ministry: "Ministry of Works",
    expiryDate: "Jul 23,2020",
    value: "N23,000,000.00",
  },
  {
    refId: "PR-0129",
    title: "Completion Of Resettlement Scheme At Agbowa...",
    category: "Awarded",
    ministry: "Ministry of Works",
    expiryDate: "Jul 23,2020",
    value: "N23,000,000.00",
  },
  {
    refId: "PR-0129",
    title: "Completion Of Resettlement Scheme At Agbowa...",
    category: "Awarded",
    ministry: "Ministry of Works",
    expiryDate: "Jul 23,2020",
    value: "N23,000,000.00",
  },
  {
    refId: "PR-0129",
    title: "Completion Of Resettlement Scheme At Agbowa...",
    category: "Awarded",
    ministry: "Ministry of Works",
    expiryDate: "Jul 23,2020",
    value: "N23,000,000.00",
  },
  {
    refId: "PR-0129",
    title: "Completion Of Resettlement Scheme At Agbowa...",
    category: "Awarded",
    ministry: "Ministry of Works",
    expiryDate: "Jul 23,2020",
    value: "N23,000,000.00",
  },
  {
    refId: "PR-0129",
    title: "Completion Of Resettlement Scheme At Agbowa...",
    category: "Awarded",
    ministry: "Ministry of Works",
    expiryDate: "Jul 23,2020",
    value: "N23,000,000.00",
  },
  {
    refId: "PR-0129",
    title: "Completion Of Resettlement Scheme At Agbowa...",
    category: "Awarded",
    ministry: "Ministry of Works",
    expiryDate: "Jul 23,2020",
    value: "N23,000,000.00",
  },
  {
    refId: "PR-0129",
    title: "Completion Of Resettlement Scheme At Agbowa...",
    category: "Awarded",
    ministry: "Ministry of Works",
    expiryDate: "Jul 23,2020",
    value: "N23,000,000.00",
  },
  {
    refId: "PR-0129",
    title: "Completion Of Resettlement Scheme At Agbowa...",
    category: "Awarded",
    ministry: "Ministry of Works",
    expiryDate: "Jul 23,2020",
    value: "N23,000,000.00",
  },
  {
    refId: "PR-0129",
    title: "Completion Of Resettlement Scheme At Agbowa...",
    category: "Awarded",
    ministry: "Ministry of Works",
    expiryDate: "Jul 23,2020",
    value: "N23,000,000.00",
  },
  {
    refId: "PR-0129",
    title: "Completion Of Resettlement Scheme At Agbowa...",
    category: "Awarded",
    ministry: "Ministry of Works",
    expiryDate: "Jul 23,2020",
    value: "N23,000,000.00",
  },
];
