import styled from "styled-components";
import { Menu } from "@material-ui/core";
import { Link } from "react-router-dom";

export const CrumbLink = styled(Link)`
  text-decoration: none;
  color: #47C3F3;
  font-weight: 500;
  font-size: 12px !important;
`;

export const MenuList = styled(Menu)`
  .MuiMenu-paper {
    box-shadow: 0 0 5px #e4e4e4;
  }
`;
