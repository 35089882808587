import React from "react";
import ForgotPassword from "../../../components/ForgotPassword";

const AdminForgotPassword = () => (
    <ForgotPassword
      loginLink={"/login"}
      passwordSentLink={"/admin/password-sent"}
    />
);

export default AdminForgotPassword;
