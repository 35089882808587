import { Grid } from "@material-ui/core";
import React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { ContentWrapper, TitleContainer } from "../../../Admin/Administration/Staff/style";
import { MainTitle, TopItems } from "../../../Admin/Contracts/SingleContractDetails/style";
import Breadcrumb from "../../../Common/PageElements/Breadcrumb";
import { PageTitle } from "../../../Common/style";
import { BiddingDocumentContainer } from "../../BidDetails/BidInfo/style";
import ContractDocumentSection from "../ContractDocSection";
import { ItemsWrapper, SecondWrapper } from "../ContractInfo/style";
import { AcceptanceWrapper, MainWrapper } from "../LetterOfAcceptance/style";
import withVendorDashboard from "../../HOC/withVendorDashboard";
import vendorsAPI from "../../../../redux/api/vendorsAPI";
import { convertDuration } from "../../../../util/transforms/contract";
import Loader from "../../../../components/Common/Loader";

const RejectedContract = () => {
  const { contractId } = useParams();
  const BreadcrumbLinks = [{ url: "/vendor/contracts", title: "Notification", forwardSlash: false }];
  const contractInfo = useQuery(["rejectedContract", contractId], vendorsAPI.getVendorContractById);

  return (
    <ContentWrapper>
      <TitleContainer>
        <div>
          <PageTitle>{contractInfo?.data?.procurementPlan?.packageNumber}</PageTitle>
          <Breadcrumb values={BreadcrumbLinks} />
        </div>
      </TitleContainer>
      <AcceptanceWrapper style={{ backgroundColor: "#FFC6BC", fontSize: "14px" }}>
        Unfortunately, this contract has been awarded to someone else.
      </AcceptanceWrapper>
      <MainWrapper>
        <SecondWrapper>
          {contractInfo?.isLoading && <Loader />}
          {!contractInfo?.isLoading && (<div>
          <MainTitle>Contract Information</MainTitle>

          <ItemsWrapper>
            <Grid item xs={3}>
              <TopItems>
                <span>Contract Name</span>
                <p>{contractInfo?.data?.title || "N/A"}</p>
              </TopItems>
            </Grid>
            <Grid item xs={3}>
              <TopItems>
                <span>Notice Type</span>
                <p>Contract Rejection</p>
              </TopItems>
            </Grid>

            <Grid item xs={3}>
              <TopItems>
                <span>Contract Reference ID</span>
                <p>{contractInfo?.data?.referenceId || "N/A"}</p>
              </TopItems>
            </Grid>

            <Grid item xs={3}>
              <TopItems>
                <span>Procurement Method</span>
                <p>{contractInfo?.data?.procurementPlan?.procurementMethod || "N/A"}</p>
              </TopItems>
            </Grid>

            <Grid item xs={3}>
              <TopItems>
                <span>Contractor</span>
                <p>{contractInfo?.data?.contractor?.companyName || "N/A" }</p>
              </TopItems>
            </Grid>

            <Grid item xs={3}>
              <TopItems>
                <span>Contract Duration</span>
                <p>{convertDuration(contractInfo?.data?.endDate, contractInfo?.data?.startDate)} </p>
              </TopItems>
            </Grid>

            <Grid item xs={3}>
              <TopItems>
                <span>Amount</span>
                <p>N{contractInfo?.data?.procurementPlan?.estimatedAmountInNaira || "/A"}</p>
              </TopItems>
            </Grid>
            <Grid item xs={3}></Grid>
            <Grid item>
              <TopItems>
                <span>Description</span>
                <p>{contractInfo?.data?.description || "N/A"}</p>
              </TopItems>
            </Grid>
          </ItemsWrapper>
          </div>)}
        </SecondWrapper>
        <BiddingDocumentContainer>
          <ContractDocumentSection contractInfo={contractInfo}  />
        </BiddingDocumentContainer>
      </MainWrapper>
    </ContentWrapper>
  );
};

export default withVendorDashboard(RejectedContract);
